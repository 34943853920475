import moment from "moment";
import React, { useEffect, useState } from "react";
import { Col, Row } from "react-bootstrap";
import AddPlusIcon from "../../../Assets/icon/AddPlusIcon";
import CustomeButton from "../../Common/CustomButton/CustomButton";
import AddCostRecordPopup from "../../Popups/AddCostRecordPopup/AddCostRecordPopup";
import FemaleIcon from "../../../Assets/img/Female.svg";
import MaleIcon from "../../../Assets/img/Male.svg";
import PregnantIcon from "../../../Assets/img/Pregnant.svg";
import Pagination from "../../Layouts/Pagination/Pagination";
import { useSelector } from "react-redux";
import IncomeExpenseFilter from "../IncomeExpenseFilter/IncomeExpenseFilter";
import { horseWiseCostDetails } from "../../../Redux/NonRedux/incomeExpense";
import { SUCCESS_RESPONSE_CODE } from "../../../Constant/Status";
import Loader from "../../Loader/Loader";
import NoDataFound from "../../Common/NoDataFound/NoDataFound";
import { useLoading } from "../../../context/LoadingContext";
import { DATATABLE, DEFAULT_HORSE_PROFILE } from "../../../Constant/General";
import { t } from "i18next";
import TableHead from "../../Common/DataTable/TableHead/TableHead";
import { getDefaultSorting } from "../../../Utility/General";
import { useOutletContext } from "react-router-dom";

export default function HorseIAEPage() {
  const defaultSorting : any = getDefaultSorting(DATATABLE.COST_HORSE_DETAILS);
  const INTIAL_FILTER_OPTION = {
    category_id: null,
    horse_id: null,
    from: moment().startOf('month').format('YYYY-MM-DD'),
    to: moment().format("YYYY-MM-DD"),
    owner_id: null,
  };

  const getIconbyType = (type: any) => {
    if (type == "male") {
      return MaleIcon;
    } else if (type == "female") {
      return FemaleIcon;
    } else if (type == "pregnant") {
      return PregnantIcon;
    }
  };

  const tableHead = [
    {
      isOnlyTh: true,
      headClass: "first-img-wrapper",
    },
    {
      label: t("Horse"),
      className: "tm icon-text",
      key: "horse",
    },
    {
      label: t("Expense"),
      className: "tm",
      key: "expense",
      headClass:"text-right"
    },
    {
      label: t("Income"),
      className: "tm",
      key: "income",
      headClass:"text-right"
    },
  ];

  const [AddCostRecordModalShow, setAddCostRecordModalShow] =
    React.useState(false);
  const [data, setData] = useState<any>({});
  const { setLoading } = useLoading();
  const [refreshList, setRefreshList] = useState(false);
  const [filterOption, setFilterOption] = useState<any>(INTIAL_FILTER_OPTION);
  const [recordsTotal, setRecordsTotal] = useState(1);
  const [currentPage, setCurrentPage] = useState(1);
  const [currentPageSize, setCurrentPageSize] = useState(10);
  const [sortByField, setSortByField] = useState(defaultSorting?.key);
  const [sortType, setSortType] = useState(defaultSorting?.type);
  const [orderList, setOrderList] = useState<any>([]);
  const {metaData} : any = useOutletContext();
  const prefrence = useSelector(
    (state: any) => state?.masterReducer?.data?.component?.preferance
  );

  const listApiCall = () => {
    setLoading(true);
    let params: any = {
      page: currentPage,
      limit: currentPageSize,
    };
    Object.keys(filterOption).forEach((item: any) => {
      if (filterOption[item] && filterOption[item] != "") {
        params[item] = filterOption[item];
      }
    });

   
    if (sortByField && sortType) {
      params["sort"] = {
        column: orderList[sortByField] ? orderList[sortByField] : defaultSorting?.value ,
        type: sortType,
      };
    }

    horseWiseCostDetails(params)
      .then((response: any) => {
        if (response?.statusCode === SUCCESS_RESPONSE_CODE) {
          setData(response?.component);
          setRecordsTotal(response?.component?.recordsFiltered);
          setOrderList(response?.component?.orderlist);
        }
      })
      .catch(() => {})
      .finally(() => {
        setLoading(false);
      });
  };

  useEffect(() => {
    if (refreshList) {
      listApiCall();
      setRefreshList(false);
    }
  }, [refreshList]);

  useEffect(() => {
    listApiCall();
  }, [currentPage, currentPageSize, filterOption, sortByField, sortType]);

  return (
    <>
      <div className="horse-IAE-page">
        <div className="summary-head">
          <Row>
            <Col sm="12" md="6" lg="4">
              <div className="summary-box green-text-bg">
                <p className="tl blur-color">{t("Income")}</p>
                <div className="right-details">
                  <h5>
                    {data?.totalincome} {prefrence?.currency_value}
                  </h5>
                </div>
              </div>
            </Col>
            <Col sm="12" md="6" lg="4">
              <div className="summary-box red-text-bg">
                <p className="tl blur-color">{t("Expenses")}</p>
                <div className="right-details">
                  <h5>
                    {data?.totalExpence} {prefrence?.currency_value}
                  </h5>
                </div>
              </div>
            </Col>
            <Col sm="12" md="6" lg="4">
              <div className="summary-box blur-bg-black-text">
                <p className="tl blur-color">{t("Balance")}</p>
                <div className="right-details">
                  <h5>
                    {data?.totalBalance} {prefrence?.currency_value}
                  </h5>
                </div>
              </div>
            </Col>
          </Row>
        </div>
        <IncomeExpenseFilter
          filterOption={filterOption}
          setFilterOption={setFilterOption}
        />
        <div className="data-wrapper">
          <div className="data-header">
            <p className="tl">{t("Income & Expenses by Horse")}</p>
            <div className="right-section">
              <div className="btn-wrapper fb">
                <CustomeButton
                  bg="fill"
                  onClick={() => {
                    setAddCostRecordModalShow(true);
                  }}
                >
                  <AddPlusIcon />
                  {t("Add Cost")}
                </CustomeButton>
              </div>
            </div>
          </div>
          <div className="data-body">
            <div className="img-responsive-table last-width-148">
              <table>
                <TableHead
                  data={tableHead}
                  orderList={orderList}
                  sortByField={sortByField}
                  sortType={sortType}
                  setSortType={setSortType}
                  setSortByField={setSortByField}
                />
                <tbody>
                  {data?.getIncomeRecords?.map((items: any, index: number) => (
                    <tr key={index + "myhorse-tbl"}>
                      <td className="first-img-wrapper">
                        <img
                          src={
                            items?.profile
                              ? items?.profile
                              : DEFAULT_HORSE_PROFILE
                          }
                          alt=""
                          className="horse-img"
                        />
                      </td>
                      <td>
                        <div className="icon-text">
                          <img src={getIconbyType(items?.type_slug)} alt="" />
                          {/* <Link to={HORSE} className="a-bl"> */}
                          {items?.horse_name}
                          {/* </Link> */}
                        </div>
                      </td>
                      <td>
                        <p className="bl error-color text-right">
                          {items?.expense_total} {prefrence?.currency_value}
                        </p>
                      </td>
                      <td>
                        <p className="bl success-color text-right">
                          {items?.income_total} {prefrence?.currency_value}
                        </p>
                      </td>
                      {/* <td>
                        <div className="icon-wrapper">
                          <a>
                            <img src={pencilIcon} alt="" />
                          </a>
                          <a>
                            <img src={binIcon} alt="" />
                          </a>
                        </div>
                      </td> */}
                    </tr>
                  ))}
                  {data?.getIncomeRecords?.length ? (
                    <tr className="total-footer">
                      <td colSpan={2}>
                        <p className="tm primaryM">{t("Total")}</p>
                      </td>
                      <td>
                        <p className="tm error-color text-right">
                          {data?.totalExpence} {prefrence?.currency_value}
                        </p>
                      </td>
                      <td>
                        <p className="tm success-color text-right">
                          {data?.totalincome} {prefrence?.currency_value}
                        </p>
                      </td>
                    </tr>
                  ) : null}
                </tbody>
              </table>
            </div>
            {data?.getIncomeRecords?.length ? (
              <Pagination
                results={recordsTotal}
                currentPage={currentPage}
                setCurrentPage={setCurrentPage}
                currentLength={currentPageSize}
                setCurrentPageSize={setCurrentPageSize}
              />
            ) : (
              <NoDataFound />
            )}
          </div>
        </div>
      </div>

      <AddCostRecordPopup
        show={AddCostRecordModalShow}
        onHide={() => setAddCostRecordModalShow(false)}
        setRefreshList={setRefreshList}
        metaData={metaData}
      />
    </>
  );
}
