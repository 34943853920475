import React from "react";

export default function Incomeexpensesicon() {
  return (
    <svg
      width="32"
      height="32"
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M9.20001 16.3H4.60001V27.3H9.20001V16.3Z"
        stroke="#E8E5E2"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M9.20001 18.1C9.20001 18.1 10.7 18.4 12 19.2C13.1 20 16.3 19.8 17.1 19.9C17.9 19.9 19.8 19.7 19.5 22.8"
        stroke="#E8E5E2"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M15.4 23.1C15.4 23.1 19.5 23.1 22.4 21.9C25.3 20.7 26.3 20.7 26.6 20.9C26.9 21.1 28.7 22.5 23.9 24.7C19.1 26.9 17.2 26.5 15.1 26.4C13 26.3 9.60001 26.8 9.10001 25.3"
        stroke="#E8E5E2"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M21.9 9.2C21.6 8.9 21.3 8.8 20.9 8.8C19.9 8.8 19.4 9.4 19.4 9.8C19.4 10.8 20.3 10.9 20.9 11.2C21.4 11.3 22.4 11.6 22.4 12.3C22.4 12.8 21.9 13.3 20.9 13.3C20.3 13.3 19.8 13 19.4 12.7"
        stroke="#E8E5E2"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M20.7 8.8V7.5"
        stroke="#E8E5E2"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M20.7 14.8V13.5"
        stroke="#E8E5E2"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M20.8 17.2C24.1689 17.2 26.9 14.4689 26.9 11.1C26.9 7.73106 24.1689 5 20.8 5C17.4311 5 14.7 7.73106 14.7 11.1C14.7 14.4689 17.4311 17.2 20.8 17.2Z"
        stroke="#E8E5E2"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}
