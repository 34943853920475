import React from "react";

export default function PencilIcon() {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M18.8919 8.89182L15.1081 5.10803"
        stroke="#212B36"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M16.4054 11.3784L16.1892 11.5946"
        stroke="#212B36"
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M20.5135 5.10811L18.8919 3.48649C18.2432 2.83784 17.3784 2.83784 16.7297 3.48649L15.1081 5.10811L4.2973 15.9189L3 21L8.08108 19.7027L18.8919 8.89189L20.5135 7.27027C21.1622 6.62162 21.1622 5.75676 20.5135 5.10811Z"
        stroke="#212B36"
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M6.78378 17.2162L12.8378 11.1621"
        stroke="#212B36"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M4.29727 15.9188L6.78376 17.2161L8.08106 19.7026"
        stroke="#212B36"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  );
}
