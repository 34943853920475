import { globalGetAction, globalPostAction } from "../../actions/globalActions";
import { END_POINTS } from "../../Constant/Api";
import { SHOW_MESSAGE } from "../../Constant/General";
import { LANGUAGE } from "../../Constant/LocalStorage";

const lang = localStorage.getItem(LANGUAGE)
  ? localStorage.getItem(LANGUAGE)
  : "en";
  
export function addEditTask(params: any) {
  let apiUrl = END_POINTS.taskAddEdit;
  return globalPostAction(params, apiUrl , SHOW_MESSAGE);
}

export function getTaskList(params: any) {
  let apiUrl = END_POINTS.taskList;
  return globalPostAction(params, apiUrl);
}

export function getSingleTask(params: any) {
  let apiUrl = END_POINTS.viewSingleTask;
  return globalPostAction(params, apiUrl);
}

export function destroyTask(params: any) {
  let apiUrl = END_POINTS.destroyTask;
  return globalPostAction(params, apiUrl , SHOW_MESSAGE);
}

export function getMetaTaskType() {
  let apiUrl = END_POINTS.metaTaskType + lang;
  return globalGetAction(apiUrl);
}
