import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useOutletContext, useParams } from "react-router-dom";
import AddPlusIcon from "../../../../Assets/icon/AddPlusIcon";
import binIcon from "../../../../Assets/img/bin.svg";
import PaperClipIcon from "../../../../Assets/img/Common-icon/PaperClipIcon";
import pencilIcon from "../../../../Assets/img/pencil.svg";
import { API_STATUS, END_POINTS } from "../../../../Constant/Api";
import { DATATABLE } from "../../../../Constant/General";
import { useLoading } from "../../../../context/LoadingContext";
import { getSingleDentalHealth } from "../../../../Redux/Horse/Health/SingleHealthAction";
import { getListMetaDataApi } from "../../../../Redux/NonRedux/general";
import {
  destroySingleHealthAction,
  getSingleHelthList,
  viewSingleHealthAction,
} from "../../../../Redux/NonRedux/health";
import { encrDecrStr, getDefaultSorting } from "../../../../Utility/General";
import CustomeButton from "../../../Common/CustomButton/CustomButton";
import TableHead from "../../../Common/DataTable/TableHead/TableHead";
import NoDataFound from "../../../Common/NoDataFound/NoDataFound";
import Pagination from "../../../Layouts/Pagination/Pagination";
import AddLabTestPopup from "../../../Popups/AddLabTestPopup/AddLabTestPopup";
import ConfirmPopup from "../../../Popups/ConfirmPopup/ConfirmPopup";

export default function LabTestPage() {
  const defaultSorting: any = getDefaultSorting(DATATABLE.HORSRE_HELTH);
  const { t } = useTranslation();
  const tableHead = [
    {
      label: t("Date"),
      className: "tm",
      key: "date",
    },
    {
      label: t("Vet/Clinic"),
      className: "tm",
      key: "vet_clinic",
    },
    {
      label: t("Test Name"),
      className: "tm",
      key: "test_name",
    },
    {
      isIcon: true,
      icon: <PaperClipIcon />,
    },
    {
      label: t("Amount"),
      className: "tm",
      key: "amount",
      headClass: "text-right",
    },
    {
      label: t("Action"),
      className: "tm",
      key: "",
      headClass: "text-right",
    },
  ];
  const param = useParams();
  const dispatch = useDispatch();
  const [horseId, setHorseId] = useState<any>(0);
  const [refreshList, setRefreshList] = useState(false);
  const [confirmPopupShow, setConfirmPopupShow] = useState(false);
  const [isEdit, setIsEdit] = useState<boolean>(false);
  const [apiStatus, setApiStatus] = useState(API_STATUS.SUCCESS);
  const [deletableRecords, setDeletableRecords] = useState<any>([]);
  const [editData, setEditData] = useState<any>({});
  const [recordsTotal, setRecordsTotal] = useState(1);
  const [currentPage, setCurrentPage] = useState(1);
  const [currentPageSize, setCurrentPageSize] = useState(10);
  const { setLoading } = useLoading();
  const [healthList, setHelthList] = useState<any>([]);
  const [AddLabTestModalShow, setAddLabTestModalShow] = useState(false);
  const [sortByField, setSortByField] = useState(defaultSorting?.key);
  const [sortType, setSortType] = useState(defaultSorting?.type);
  const [orderList, setOrderList] = useState<any>([]);
  const { setExportParams }: any = useOutletContext();
  const [metaData, setMetaData] = useState({});

  const prefrence = useSelector(
    (state: any) => state?.masterReducer?.data?.component?.preferance
  );

  const getMetaData = () => {
    setLoading(true);
    getListMetaDataApi(END_POINTS.metaHelthLabtest)
      .then((response: any) => {
        setMetaData(response?.component);
      })
      .catch((error: any) => {})
      .finally(() => {
        setLoading(false);
      });
  };

  useEffect(() => {
    getMetaData();
  }, []);

  useEffect(() => {
    listApiCall();
  }, [currentPage, currentPageSize, sortByField, sortType]);

  useEffect(() => {
    if (refreshList) {
      listApiCall();
      setRefreshList(false);
    }
  }, [refreshList]);

  const listApiCall = () => {
    let id = encrDecrStr(param?.slug, "decr");
    setHorseId(id);
    let params: any = {
      page: currentPage,
      limit: currentPageSize,
      form_type: "labTest",
      horse_id: id,
    };
    if (sortByField && sortType) {
      params["sort"] = {
        column: orderList[sortByField]
          ? orderList[sortByField]
          : defaultSorting?.value,
        type: sortType,
      };
    }
    setExportParams(params);
    setLoading(true);
    getSingleHelthList(params)
      .then((res: any) => {
        setHelthList(res?.component?.listing);
        setRecordsTotal(res?.component?.recordsTotal);
        setOrderList(res?.component?.orderlist);
      })
      .catch(() => {})
      .finally(() => {
        setLoading(false);
      });
  };

  const editHandler = (id: any) => {
    let params = {
      form_type: "labTest",
      id: id,
    };
    viewSingleHealthAction(params)
      .then((res: any) => {
        if (res?.component?.form_type == "labTest") {
          setIsEdit(true);
          setEditData(res?.component);
          setAddLabTestModalShow(true);
        }
      })
      .catch((err) => {});
  };
  const deleteHandler = () => {
    let params = {
      form_type: "labTest",
      id: deletableRecords,
    };
    setLoading(true);
    destroySingleHealthAction(params)
      .then((res: any) => {
        setRefreshList(true);
        setConfirmPopupShow(false);
      })
      .catch((err: any) => {})
      .finally(() => {
        setLoading(false);
      });
  };

  const deleteNow = (id: any) => {
    let ids = deletableRecords;
    ids.push(id);
    setDeletableRecords(ids);
    setConfirmPopupShow(true);
  };

  const openDocument = (doc: any) => {
    window.open(doc, "_blank");
  };

  return (
    <>
      <div className="vet-visit-page">
        <div className="data-tbl-wrapper">
          <div className="data-tbl-header">
            <p className="tl">{`${t("Lab Tests")}`}</p>
            <div className="btn-wrapper single-btn">
              <CustomeButton
                bg="fill"
                onClick={() => {
                  setEditData({});
                  setIsEdit(false);
                  setAddLabTestModalShow(true);
                }}
              >
                <AddPlusIcon />
                {`${t("Add")}`}
              </CustomeButton>
            </div>
          </div>
          <div className="data-tbl">
            <table>
              <TableHead
                data={tableHead}
                orderList={orderList}
                sortByField={sortByField}
                sortType={sortType}
                setSortType={setSortType}
                setSortByField={setSortByField}
              />

              <tbody>
                {healthList?.map((items: any, index: number) => (
                  <tr key={index + "vaccination-data"}>
                    <td>
                      <p className="bl">{items.dates}</p>
                    </td>
                    <td>
                      <p className="bl">{items.vat_clinic}</p>
                    </td>
                    <td>
                      <p className="bl">{items.testName}</p>
                    </td>
                    <td>
                      {items.document ? (
                        <p
                          className="bl"
                          onClick={() => openDocument(items.document)}
                        >
                          <PaperClipIcon />
                        </p>
                      ) : null}
                    </td>
                    <td>
                      <p className="bl text-right">
                        {items.amount} {prefrence?.currency_value}
                      </p>
                    </td>
                    <td>
                      <div className="icon-wrapper">
                        <button onClick={() => editHandler(items.id)}>
                          <img src={pencilIcon} alt="" />
                        </button>
                        <button onClick={() => deleteNow(items.id)}>
                          <img src={binIcon} alt="" />
                        </button>
                      </div>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
          {healthList?.length ? (
            <Pagination
              results={recordsTotal}
              currentPage={currentPage}
              setCurrentPage={setCurrentPage}
              currentLength={currentPageSize}
              setCurrentPageSize={setCurrentPageSize}
            />
          ) : (
            <NoDataFound />
          )}
        </div>
      </div>

      <AddLabTestPopup
        show={AddLabTestModalShow}
        horseid={isEdit ? horseId : [horseId]}
        isMultiple={0}
        isedit={isEdit.toString()}
        healthdata={editData}
        metaData={metaData}
        setrefreshlist={() => {
          setRefreshList(true);
          getMetaData();
        }}
        onHide={() => setAddLabTestModalShow(false)}
      />
      <ConfirmPopup
        heading={t("Delete")}
        message={t("Are you sure to delete this record?")}
        show={confirmPopupShow}
        onHide={() => {
          setConfirmPopupShow(false);
          setDeletableRecords([]);
        }}
        confirm={deleteHandler}
      />
    </>
  );
}
