import React from "react";

export default function CalenderIcon() {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M18.4 4.7998H5.8C4.80589 4.7998 4 5.60569 4 6.59981V19.1998C4 20.194 4.80589 20.9998 5.8 20.9998H18.4C19.3941 20.9998 20.2 20.194 20.2 19.1998V6.59981C20.2 5.60569 19.3941 4.7998 18.4 4.7998Z"
        stroke="#919EAB"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M15.7 3V6.60001"
        stroke="#919EAB"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M8.5 3V6.60001"
        stroke="#919EAB"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M4 10.1997H20.2"
        stroke="#919EAB"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}
