import React from "react";
import Modal from "react-bootstrap/Modal";
import { t } from "i18next";
export default function HorseNextActivitiesPopup(props: any) {
  const {data , ...rest} = props;
  return (
    <Modal
      {...props}
      centered
      className="horse-next-activities-popup modal-784"
    >
      <Modal.Header closeButton>
        <p className="tl">{t("Horse Next Activities")}</p>
      </Modal.Header>
      <Modal.Body>
        <div className="vertical-flow small">
          {data?.map((items: any, index: number) => (
            <div className="VF-box" key={index + "HNA"}>
              <label className="bl">{items?.date}</label>
              <div className="dots">
                <div className="green"></div>
              </div>
              <div className="box">
                <p className="bl">{items?.category} {items?.type ? `(${items?.type})` : ""} </p>
              </div>
            </div>
          ))}
            {!data?.length ? (
            <p className="no-data-wrapper ts blur-color">
              {t("No Data Found")}
            </p>
          ) : null}
        </div>
      </Modal.Body>
    </Modal>
  );
}
