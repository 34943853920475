import { LANGUAGE } from "../Constant/LocalStorage";
import { getSessionItem, onlyNumbersLength } from "./General";

const validate = (schema: any) => {
  const emailRegex =
    /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  var numberRegex = /^\d+$/;
  let data = schema;
  let isValidated = true;
  let obj: any = {};
  for (const d in data) {
    if (data[d].validation) {
      //   for (let i = 0; i < data[d].validation.length; i++) {
      //     //
      //     if (
      //       data[d].validation[i] === "required" &&
      //       (data[d].value === null || data[d].value === "")
      //     ) {
      //       obj[d] = data[d].errors[i] ? data[d].errors[i] : "Error";
      //       isValidated = false;
      //     } else if (
      //       data[d].validation[i] === "email" &&
      //       !emailRegex.test(data[d].value)
      //     ) {
      //       obj[d] = data[d].errors[i] ? data[d].errors[i] : "Error";

      //       isValidated = false;
      //     } else if (
      //       data[d].validation[i] === "number" &&
      //       !numberRegex.test(data[d].value)
      //     ) {
      //       obj[d] = data[d].errors[i] ? data[d].errors[i] : "Error";
      //       isValidated = false;
      //     } else if (data[d].validation[i].includes(":")) {
      //       let type = data[d].validation[i].split(":")[0];
      //       let range = data[d].validation[i].split(":")[1];
      //       let exactVal = data[d].validation[i].split(":")[2];

      //       if (type === "min" && data[d].value?.length < range) {
      //         obj[d] = data[d].errors[i] ? data[d].errors[i] : "Error";
      //         isValidated = false;
      //       } else if (type === "max" && data[d].value?.length > range) {
      //         obj[d] = data[d].errors[i] ? data[d].errors[i] : "Error";
      //         isValidated = false;
      //       } else if (
      //         type === "len" &&
      //         Number(data[d].value?.length) !== Number(range)
      //       ) {
      //         obj[d] = data[d].errors[i] ? data[d].errors[i] : "Error";
      //         isValidated = false;
      //       } else if (
      //         type === "requiredIf" &&
      //         (data[d].value === null ||
      //           (data[d].value === "" &&
      //             ((exactVal && data[range].value == exactVal) ||
      //               (!exactVal && data[range].value == 1))))
      //       ) {
      //         obj[d] = data[d].errors[i] ? data[d].errors[i] : "Error";
      //         isValidated = false;
      //       } else if (type === "sameAs") {
      //         let sameAs = range;
      //         let check = 1;
      //         if (range.includes(",")) {
      //           sameAs = range.split(",")[0];
      //           let checkKey = range.split(",")[1];
      //           check = data[checkKey].value;
      //         }
      //         if (check == 1) {
      //           if (data[d].value != data[sameAs].value) {
      //             obj[d] = data[d].errors[i] ? data[d].errors[i] : "Error";
      //             isValidated = false;
      //           }
      //         }
      //       }
      //     }
      //   }
      var dataVal = forValidate(data[d], data);
      if (typeof dataVal.error == "string") {
        obj[d] = dataVal.error;
        isValidated = false;
      }
    } else {
      if (Array.isArray(data[d])) {
        let loopData = data[d];
        let obj2: any = {};
        loopData.forEach((element: any, key: any) => {
          let obj3: any = {};
          for (const d2 in element) {
            if (element[d2].validation) {
              var dataVal = forValidate(element[d2], data);
              if (typeof dataVal.error == "string") {
                obj3[d2] = dataVal.error;
                isValidated = false;
              }
            }
          }
          if (Object.keys(obj3).length) {
            obj2[key] = obj3;
          }
        });
        if (Object.keys(obj2).length) {
          obj[d] = obj2;
        }
      }
    }
  }
  return { errors: obj, isValidated: isValidated };
};
const forValidate = (data: any, originalData: any) => {
  let isValidated = true;
  let obj: any = {};
  const emailRegex =
    /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  var numberRegex = /^[0-9]+(?:\.[0-9]{1,4})?$/;
  const youtubeRegex = /^(http(s)?:\/\/)?((w){3}.)?youtu(be|.be)?(\.com)?\/.+/;
  var letters = /^[A-Za-z\s]*$/;
  const lettersNum = /^[a-zA-Z0-9]*$/;
  const instaGramRegex = /^(https:\/\/www\.instagram\.com\/[a-zA-Z0-9._-]+\/?)$/;
  const facebookRegex = /^(https:\/\/www\.facebook\.com\/)([a-zA-Z0-9\.]{1,})(\/?)$/;
  const websiteRegex = /^(https?:\/\/)?([\da-z\.-]+)\.([a-z\.]{2,6})([\/\w \.-]*)*\/?$/;
  const googleMapRegex = /^(?:https?:\/\/)?(?:www\.)?(?:maps\.google\.com|goo\.gl)\/maps\/(?:[^\/]+\/)*([0-9.-]+,[0-9.-]+)(?:[^\s]*)/;
  const mobileNumberRegex = /^[^A-Za-z]+$/
  for (let i = 0; i < data.validation.length; i++) {
    //
    if (
      data.validation[i] === "required" &&
      (data.value === null || data.value === "")
    ) {
      obj = data.errors[i] ? data.errors[i] : "Error";
      isValidated = false;
      break;
    } else if (
      data.validation[i] === "youtube_link" &&
      (data.value != null || data.value != "") &&
      !youtubeRegex.test(data.value)
    ) {
      obj = data.errors[i] ? data.errors[i] : "Error";
      isValidated = false;
    } else if (
      data.validation[i] === "instagram_link" &&
      (data.value) &&
      !instaGramRegex.test(data.value)
    ) {

      obj = data.errors[i] ? data.errors[i] : "Error";
      isValidated = false;
    }

    else if (
      data.validation[i] === "google_map_link" &&
      (data.value) &&
      !googleMapRegex.test(data.value)
    ) {

      obj = data.errors[i] ? data.errors[i] : "Error";
      isValidated = false;
    } 

    else if (
      data.validation[i] === "facebook_link" &&
      (data.value) &&
      !facebookRegex.test(data.value)
    ) {
      obj = data.errors[i] ? data.errors[i] : "Error";
      isValidated = false;
    } 
    else if (
      data.validation[i] === "website_link" &&
      (data.value) &&
      !websiteRegex.test(data.value)
    ) {
      obj = data.errors[i] ? data.errors[i] : "Error";
      isValidated = false;
    } 
    else if (
      data.validation[i] === "mobile_number" &&
      (data.value) &&
      !mobileNumberRegex.test(data.value)
    ) {
      obj = data.errors[i] ? data.errors[i] : "Error";
      isValidated = false;
    } 

    else if (
      data.validation[i] === "validatePedigreeLink" &&
      (data.value != null || data.value != "")
    ) {
      let link = data.value;
      if (link && !link.includes("https://www.allbreedpedigree.com/")) {
        obj = data.errors[i] ? data.errors[i] : "Error";
        isValidated = false;
      }
    } else if (
      data.validation[i] == "requiredArray" &&
      data.value &&
      data.value?.length === 0
    ) {
      obj = data.errors[i] ? data.errors[i] : "Error";
      isValidated = false;
    } else if ( data.value != null &&
      data.value != "" &&
      data.value != undefined && data.validation[i] === "email" && !emailRegex.test(data.value)) {
      obj = data.errors[i] ? data.errors[i] : "Error";

      isValidated = false;
    } else if (data.validation[i] === "letters" && !letters.test(data.value) && localStorage.getItem(LANGUAGE) === "en") {
      obj = data.errors[i] ? data.errors[i] : "Error";
      isValidated = false;
    }else if (data.validation[i] === "lettersNumber" && !lettersNum.test(data.value)) {
      obj = data.errors[i] ? data.errors[i] : "Error";
      isValidated = false;
    } 
    
    else if (
      data.value != null &&
      data.value != "" &&
      data.value != undefined &&
      data.validation[i] === "number" &&
      !numberRegex.test(data.value)
    ) {
      obj = data.errors[i] ? data.errors[i] : "Error";
      isValidated = false;
    } else if (data.validation[i].includes(":")) {
      let type = data.validation[i].split(":")[0];
      let range = data.validation[i].split(":")[1];
      let exactVal = data.validation[i].split(":")[2];    
      if (type === "min" && data.value?.length < range) {        
        obj = data.errors[i] ? data.errors[i] : "Error";
        isValidated = false;
      } else if (type === "minNumber") {
        let tempVal = data.value;
        let intRange = range;
        tempVal = parseInt(tempVal);
        intRange = parseInt(range);
        if(tempVal < intRange){
          obj = data.errors[i] ? data.errors[i] : "Error";
          isValidated = false;
        }
      } else if (
        type === "sameAsField" &&
        data.value !== originalData[range].value
      ) {
        obj = data.errors[i] ? data.errors[i] : "Error";
        isValidated = false;
      } else if (
        data.value != null &&
        data.value != "" &&
        data.value != undefined &&
        type === "beteween" &&
        !(
          parseInt(range) <= parseInt(data.value) &&
          parseInt(exactVal) >= parseInt(data.value)
        )
      ) {
        obj = data.errors[i] ? data.errors[i] : "Error";
        isValidated = false;
      } else if (
        type === "dateLessThan" &&
        originalData[range].value > data?.value
      ) {
        obj = data.errors[i] ? data.errors[i] : "Error";
        isValidated = false;
      } else if (type === "formatTime" && range === "hhmmss") {
        let value = data?.value;
        value = value.split(":");
        let check = onlyNumbersLength(value, 2);

        if (
          value.length != 3 ||
          !(value.length == 3 && onlyNumbersLength(value, 2))
        ) {
          obj = data.errors[i] ? data.errors[i] : "Error";
          isValidated = false;
        }
      } else if (
        type === "dateGreaterThan" &&
        originalData[range].value < data?.value
      ) {
        obj = data.errors[i] ? data.errors[i] : "Error";
        isValidated = false;
      } else if (type === "max" && data.value?.length > range) {
        obj = data.errors[i] ? data.errors[i] : "Error";
        isValidated = false;
      } else if (
        type === "len" &&
        Number(data.value?.length) !== Number(range)
      ) {
        obj = data.errors[i] ? data.errors[i] : "Error";
        isValidated = false;
      } else if (
        type === "requiredIf" &&
        (data.value === "" || data.value === null) &&
        ((exactVal && originalData[range].value == exactVal) ||
          (!exactVal && originalData[range].value == 1))
      ) {
        obj = data.errors[i] ? data.errors[i] : "Error";
        isValidated = false;
      } else if (
        type === "requiredArrayIf" &&
        data.value &&
        data.value?.length === 0 &&
        ((exactVal && originalData[range].value == exactVal) ||
          (!exactVal && originalData[range].value == 1))
      ) {
        obj = data.errors[i] ? data.errors[i] : "Error";
        isValidated = false;
      } else if (type === "sameAs") {
        let sameAs = range;
        let check = 1;
        if (range.includes(",")) {
          sameAs = range.split(",")[0];
          let checkKey = range.split(",")[1];
          check = data[checkKey].value;
        }
        if (check == 1) {
          if (data.value != data[sameAs].value) {
            obj = data.errors[i] ? data.errors[i] : "Error";
            isValidated = false;
          }
        }
      }
    }
  }
  return { error: obj, isValidated: isValidated };
};
export default validate;
