import { API_STATUS } from "../Constant/Api";
import { LANGUAGE, USER } from "../Constant/LocalStorage";
import { checkCookie, setCookie } from "./Cookie";
// import BlankCupcake from "../Assets/img/blank-cupcake.svg";
// import Cupcake from "../Assets/img/cupcake.svg";
import moment from "moment";
import { toast } from "react-toastify";
import {
  DEFAULT_SORTING,
  ERROR,
  MEASUREMENT,
  MESSAGE,
  PERMISSION,
} from "../Constant/General";
import { InvoiceTotals, PriceCal } from "../interface/invoice";

import i18n from 'i18next';
// import 'moment/locale/ar';

const arabicMonths = [
  "يناير",
  "فبراير",
  "مارس",
  "إبريل",
  "مايو",
  "يونيو",
  "يوليو",
  "أغسطس",
  "سبتمبر",
  "أكتوبر",
  "نوفمبر",
  "ديسمبر",
];


const SECRET: any = process.env.REACT_APP_CRYPTO_SECRET_KEY;

export const getTypeFromFilter = (key: string, filter: any) => {
  if (key && filter && filter?.length) {
    let tempObj = filter.find((item: any) => item?.key == key);
    return tempObj;
  }
};

export function changeUserProfile(data: any) {
  const userInfo = getSessionItemDecrypt(USER);
  setSessionItemEncrypt(USER, {
    ...userInfo,
    component: { ...userInfo.component, ...data },
  });
}

export function buildFormData(formData: any, data: any, parentKey: any = "") {
  if (
    data &&
    typeof data === "object" &&
    !(data instanceof Date) &&
    !(data instanceof File)
  ) {
    Object.keys(data).forEach((key) => {
      buildFormData(
        formData,
        data[key],
        parentKey ? `${parentKey}[${key}]` : key
      );
    });
  } else {
    const value = data == null ? "" : data;
    formData.append(parentKey, value);
  }
}

export function jsonToFormData(data: any) {
  const formData = new FormData();
  buildFormData(formData, data);
  return formData;
}

export function checkRoutePermission(permission: string, moduleAccessOb: any) {
  const modules: any = moduleAccessOb;
  let returnVal = 1;
  if (modules?.hasOwnProperty(permission)) {
    const permissionOb = modules?.[permission];
    returnVal = permissionOb?.is_enabled;
  }
  return returnVal;
}

export const calculatePercent = (num: number, per: number): number => {
  return (num / 100) * per;
};

export const caluculateTotal = (pricingCal: PriceCal[]): InvoiceTotals => {
  let total = 0;
  let subTotal = 0;
  let totalTax = 0;
  pricingCal?.forEach((item: PriceCal) => {
    let currentSubTotal = item.qty * item?.unit_price;
    let currentTax = calculatePercent(currentSubTotal, item?.tax);
    subTotal += currentSubTotal;
    totalTax += currentTax;
    total += currentSubTotal + currentTax;
  });
  return {
    tax: totalTax.toFixed(2),
    subTotal: subTotal.toFixed(2),
    total: total.toFixed(2),
  };
};

export function getGraphDataSet(config: any, graphData: any) {
  let dataSetArr: any = [];
  let datasetsOb = {
    labels: graphData?.data?.labels,
    datasets: dataSetArr,
  };
  if (graphData?.data?.values?.length) {
    graphData?.data?.values?.forEach((item: any, index: number) => {
      let dataSetOb = { ...config[index], ...item };
      dataSetArr.push(dataSetOb);
    });
    datasetsOb.datasets = dataSetArr;
  }
  return datasetsOb;
}

export function getComponentData(cid: string, cdata: any) {
  return cdata?.find((c: any) => c.component === cid);
}

export function getUrl(query: string, params: any) {
  let queryString = query;
  let isQuestionMark = false;
  if (query && params) {
    if (query.includes("?")) {
      for (let param in params) {
        queryString += "&" + param + "=" + params[param];
      }
    } else {
      for (let param in params) {
        if (!isQuestionMark) {
          queryString += "?" + param + "=" + params[param];
          isQuestionMark = true;
        } else {
          queryString += "&" + param + "=" + params[param];
        }
      }
    }
  }
  return queryString;
}

export function setRememberMe(cname: string, user: string) {
  if (!checkCookie(cname)) {
    setCookie(cname, user, 30);
  }
}

export function removeRememberMe(cname: string) {
  if (checkCookie(cname)) {
    setCookie(cname, "", -30);
  }
}

export function setSessionItem(key: string, value: any) {
  value = JSON.stringify(value);
  localStorage.setItem(key, value);
}
export function setSessionItemEncrypt(key: string, value: any) {
  value = JSON.stringify(value);
  const encryptedData: any = CryptoJS.AES.encrypt(value, SECRET);
  localStorage.setItem(key, encryptedData);
}

export function getSessionItem(key: string) {
  const item: any = localStorage.getItem(key);
  return item !== "undefined" ? JSON.parse(item) : null;
}

export function getSessionItemDecrypt(key: string) {
  const item: any = localStorage.getItem(key);
  let data = "{}";
  try {
    const decryptedData: any = CryptoJS.AES.decrypt(item, SECRET);
    data = decryptedData?.toString(CryptoJS.enc.Utf8);
  } catch (error) {}
  return item !== "undefined" ? JSON.parse(data) : null;
}

export function getDefaultSorting(name: string) {
  const sortingOb: any = getSessionItemDecrypt(DEFAULT_SORTING);
  const isDefaultSorting = sortingOb?.hasOwnProperty(name);
  if (isDefaultSorting) {
    return sortingOb[name];
  }
  return {};
}

export function removeSessionItem(key: string) {
  return localStorage.removeItem(key);
}

export function lastLogin() {
  const userData = getSessionItemDecrypt(USER);
  var today: any = moment().utc().format("YYYY-MM-DD hh:mm:ss");
  var lastLogin: any = userData?.dateTime;
  return moment(today).diff(moment(lastLogin), "minutes");
}

export function isLoading(apiStatus: string) {
  return apiStatus === API_STATUS.LOADING || apiStatus === API_STATUS.LONG
    ? true
    : false;
}

export function showToast(type: string, messge: string) {
  if (type === "success") {
    toast.success(messge, {
      position: "top-center",
      autoClose: 2000,
      hideProgressBar: true,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });
  } else if (type === "error") {
    toast.error(messge, {
      position: "top-center",
      autoClose: 2000,
      hideProgressBar: true,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });
  } else if (type === "info") {
    toast.info(messge, {
      position: "top-center",
      autoClose: 2000,
      hideProgressBar: true,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });
  } else if (type === "warning") {
    toast.warning(messge, {
      position: "top-center",
      autoClose: 2000,
      hideProgressBar: true,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });
  } else if (type === "default") {
    toast(messge, {
      position: "top-center",
      autoClose: 2000,
      hideProgressBar: true,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });
  }
}

// export function getStarRating (number: number) {
//     var elements = [];
//     for (let i = 0; i < 5; i++) {
//       if (i >= number) {
//         elements.push(<img src={BlankCupcake} alt="" />);
//       } else {
//         elements.push(<img src={Cupcake} alt="" />);
//       }
//     }
//     return elements;
//   }

export function generateQuery(params: any) {
  let queryString = "";
  let isQuestionMark = false;
  if (params) {
    for (let param in params) {
      if (!isQuestionMark) {
        queryString += "?" + param + "=" + params[param];
        isQuestionMark = true;
      } else {
        queryString += "&" + param + "=" + params[param];
      }
    }
  }
  return queryString;
}

export function encrDecrStr(string: any, type: any) {
  if (type == "encr") {
    return btoa(string);
  } else {
    return atob(string);
  }
}

export const openDocument = (doc: any) => {
  let url = doc;
  let doc_type = doc.split(".");
  doc_type = doc_type[doc_type.length - 1];
  if (doc_type === "docx" || doc_type === "doc") {
    url = `https://docs.google.com/gview?url=${doc}&embedded=true`;
  }
  window.open(url, "_blank");
};

export function calculateWeight(girth: number, length: number, prefrence: any) {
  // calculate weight ->  pound  , girth , length ->  inches
  const weight_pre = prefrence.weightUnit || "kg";
  const height_pre = prefrence.heightUnit || "in";
  girth = convertHeight(girth, height_pre, "in");
  length = convertHeight(length, height_pre, "in");
  let weight = (girth * girth * length) / MEASUREMENT.WEIGHT_INCHES_CONVERSION;
  weight = convertWeight(weight, "lb", weight_pre);
  return weight ? weight.toFixed(2) : null;
}

export function convertWeight(value: number, fromUnit: string, toUnit: string) {
  if (fromUnit === "kg" && toUnit === "lb") {
    value = value * 2.205;
  } else if (fromUnit === "lb" && toUnit === "kg") {
    value = value / 2.205;
  }
  return value;
}

export function convertHeight(value: number, fromUnit: string, toUnit: string) {
  if (fromUnit === "cm" && toUnit === "in") {
    value = value / 2.54;
  } else if (fromUnit === "in" && toUnit === "cm") {
    value = value * 2.54;
  }
  return value;
}

export function calculateHandFromHeight(height: number, prefrence: any) {
  const height_pre = prefrence?.heightUnit || "in";
  height = convertHeight(height, height_pre, "in");
  let hand = height / MEASUREMENT.HEIGHT_CONVERSION;
  let num = Math.floor(hand);
  let decimal = hand - num;
  decimal = checkRange(decimal);
  hand = num + decimal;
  return hand ? hand : "";
}

export function calculateHeightFromHand(hand: number, prefrence: any) {
  // calculate height in inches
  const height_pre = prefrence?.heightUnit || "in";
  let decimal = hand - Math.floor(hand);
  decimal = parseFloat(decimal.toFixed(2));
  let finalHand = hand - decimal + reverseHandRange(decimal);
  let height = finalHand * MEASUREMENT.HEIGHT_CONVERSION;
  height = convertHeight(height, "in", height_pre);
  return height;
}

const reverseHandRange = (decimal: number) => {
  if (decimal > 0 && decimal <= 0.1) {
    return 0.25;
  } else if (decimal > 0.1 && decimal <= 0.2) {
    return 0.5;
  } else if (decimal > 0.2) {
    return 0.75;
  } else {
    return 0;
  }
};

const checkRange = (decimalNum: number) => {
  if (decimalNum > 0 && decimalNum <= 0.25) {
    return 0.1;
  } else if (decimalNum > 0.25 && decimalNum <= 0.5) {
    return 0.2;
  } else if (decimalNum > 0.5 && decimalNum <= 0.75) {
    return 0.3;
  } else if (decimalNum > 0.75 && decimalNum < 1) {
    return 0.4;
  } else {
    return 0;
  }
};

export const getListMonthCol = () => {
  let content: any = [];
  const currentMonth = moment().format("MM");
  let currentMonthInt = parseInt(currentMonth);
  for (let i = 5; i >= 0; i--) {
    let geyMonth: any =
      currentMonthInt > i ? currentMonthInt - i : currentMonthInt + 12 - i;
    let geyYear: any = moment().format("YYYY");
    if (currentMonthInt <= i) {
      geyYear = geyYear - 1;
    }
    geyMonth = "0" + geyMonth;
    geyMonth = geyMonth.slice(-2);
    let getMonthName = geyYear + "-" + geyMonth + "-01";
    content.push(getMonthName);
  }
  content.reverse();
  return content;
};

const toArabicNumerals = (number: number) => {
  const arabicNumerals = ['٠', '١', '٢', '٣', '٤', '٥', '٦', '٧', '٨', '٩'];
  return number.toString().replace(/\d/g, (digit) => arabicNumerals[+digit]);
};

export const getMonthListDropdown = () => {
  let content: any = [];
  const currentMonth = moment().format("MM");
  let currentMonthInt = parseInt(currentMonth);


  for (let i = 12; i > 0; i--) {
    let ob: any = {};
    let geyMonth: any =
      currentMonthInt > i ? currentMonthInt - i : currentMonthInt + 12 - i;
    let geyYear: any = moment().format("YYYY");
    if (currentMonthInt <= i) {
      geyYear = geyYear - 1;
    }
    geyMonth = "0" + geyMonth;
    geyMonth = geyMonth.slice(-2);
    let getMonthName = geyYear + "-" + geyMonth;

    let monthVal = moment(getMonthName, "YYYY-MM").format("MMM (YYYY)");

    const lang = localStorage.getItem(LANGUAGE)
  ? localStorage.getItem(LANGUAGE)
  : "en";
    if(lang === "ar"){
     monthVal =`${arabicMonths[geyMonth - 1]} (${toArabicNumerals(geyYear)})`;
    }
    
   
    ob["key"] = getMonthName;
    ob["value"] = monthVal;
    // getMonthName = moment(getMonthName).format("MMM");
    content.push(ob);
  }
  return content;
};

export const isFileValidated = (
  file: any,
  validatedFormate: any,
  allowedFileSizeInMb: any
) => {
  let fileName: any = file.name;
  var extension = fileName
    .substring(fileName.lastIndexOf(".") + 1)
    ?.toLowerCase();
  const fileSize = file.size / 1024 / 1024;
  if (!validatedFormate.includes(extension)) {
    showToast(ERROR, i18n.t(MESSAGE.VALID_FILE_FORMATE) );
    return false;
  } else if (fileSize > allowedFileSizeInMb) {
    showToast(ERROR, i18n.t(MESSAGE.TO_BIG) );
    return false;
  } else {
    return true;
  }
};

export const getFileNamefromPath = (filePath: string) => {
  let fileName: any = filePath.split("\\");
  fileName = fileName[fileName.length - 1];
  fileName = getFileNamefromLink(fileName);
  return fileName;
};

export const getFileNamefromLink = (filePath: string) => {
  let fileName = filePath.split("/");
  return fileName[fileName.length - 1];
};

export const getClassNameFromStatus = (status: any) => {
  if (status == "1") {
    return "active";
  } else {
    return "inactive";
  }
};

export const getSlugFromCostCategory = (category: string) => {
  let val = category;
  val = val.replace("cat_", "");
  return val;
};

export function onlyNumbersLength(array: any, length: number) {
  return array.every((element: any) => {
    return !isNaN(element) && element.length == length;
  });
}

export const getSlugNamefromId = (obj: any, id: number) => {
  let slug = "";
  if (id && obj && Object.keys(obj)?.length) {
    let tempObj = obj.find((item: any) => item?.id == id);
    slug = tempObj?.slug;
  }
  return slug;
};

export const getIdfromSlug = (obj: any, slug: string) => {
  let id = "";
  if (obj && obj && Object.keys(obj)?.length) {
    let tempObj = obj.find((item: any) => item?.slug === slug);

    id = tempObj?.id;
  }
  return id;
};

export const getValFromKey = (obj: any, val: any) => {
  if (obj && Object.keys(obj)?.length && val) {
    return obj.find((element: any) => element.key == val)?.value;
  }
};

export const getTogglelabel = (toggle: any, value: any) => {
  if (toggle.length) {
    const tempObj = toggle.find((element: any) => element?.key == value);
    return tempObj?.label;
  }
};

export const formateTimeForTimePicker = (time: string) => {
  let formatedTime = moment(time, "hh:mm A").format("YYYY-MM-DD HH:mm");
  return formatedTime;
};

// export const handleFileChange = (
//   e: any,
//   params: any
// ) => {
//   let file = e.target.files[0];
//   // validate file make empty if not validated
//   if (
//     isFileValidated(
//       file,
//       params?.validatedFormate
//         ? params?.validatedFormate
//         : ["jpg", "png", "pdf", "docx"],
//         params?.maxSize ? params?.maxSize : 4
//     )
//   ) {
//     let fileName = getFileNamefromPath(e.target.value);
//     params?.setFileDataSelected(fileName);
//     params?.setFileData(e.target.files[0]);
//     if (params?.isedit) {
//       params?.setRemoveDoc(1);
//     }
//   } else {
//     e.target.value = "";
//   }
// };
