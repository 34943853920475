import React, { useContext, useEffect, useState } from "react";
import AddPlusIcon from "../../Assets/icon/AddPlusIcon";
import binIcon from "../../Assets/img/bin.svg";
import InventoryIcon from "../../Assets/img/Inventory-primary.svg";
import pencilIcon from "../../Assets/img/pencil.svg";
import CustomeButton from "../Common/CustomButton/CustomButton";
import OnlyCk from "../Common/OnlyCk/OnlyCk";
import SearchBox from "../Common/SearchBox/SearchBox";
import Pagination from "../Layouts/Pagination/Pagination";
import AddInventoryPopup from "../Popups/AddInventoryPopup/AddInventoryPopup";
import {
  destroyInventory,
  exportInventory,
  listOfInventory,
  viewInventory,
} from "../../Redux/NonRedux/inventoryApi";
import ConfirmPopup from "../Popups/ConfirmPopup/ConfirmPopup";
import { useTranslation } from "react-i18next";
import { useLoading } from "../../context/LoadingContext";
import { SidebarContext } from "../../context/SideBarContext";
import { DATATABLE, PERMISSIONS, SIDEBAR } from "../../Constant/General";
import { useDispatch, useSelector } from "react-redux";
import NoDataFound from "../Common/NoDataFound/NoDataFound";
import ExportIcon from "../../Assets/img/export.svg";
import TableHead from "../Common/DataTable/TableHead/TableHead";
import { Dropdown } from "react-bootstrap";
import Filtericon from "../../Assets/icon/Filtericon";
import InputGroups from "../Common/InputGroups/InputGroups";
import SelectGropus from "../Common/SelectGroups/SelectGroups";
import { END_POINTS } from "../../Constant/Api";
import { exportDocument } from "../../Redux/globallActions";
import {  checkRoutePermission, getDefaultSorting } from "../../Utility/General";
import Filter from "../Filter/Filter";
import { upgradePopupAction } from "../../Redux/General/GeneralAction";
import { getListMetaDataApi } from "../../Redux/NonRedux/general";

export default function Inventory() {
  const defaultSorting : any = getDefaultSorting(DATATABLE.INVENTORY);
  const { t, i18n } = useTranslation();
  const [exportParams, setExportParams] = useState<any>({});
  const tableHead = [
    {
      label: t("Date"),
      className: "tm",
      key: "date",
    },
    {
      label: t("Category"),
      className: "tm",
      key: "category",
    },
    {
      label: t("Product"),
      className: "tm",
      key: "product",
    },

    {
      label: t("Qty"),
      className: "tm",
      key: "qty",
    },
    {
      label: t("Unit"),
      className: "tm",
      key: "unit",
    },
    {
      label: t("Amount"),
      className: "tm",
      key: "amount",
    },
   
    {
      label: t("Action"),
      className: "tm",
      key: "",
      headClass: "text-right",
    },
  ];

  const [refreshList, setRefreshList] = useState<boolean>(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [currentPageSize, setCurrentPageSize] = useState(10);
  const [recordsTotal, setRecordsTotal] = useState(1);
  const [searchText, setSearchText] = useState<any>();
  const [listData, setListData] = useState([]);
  const [isEdit, setIsEdit] = useState<boolean>(false);
  const [checkedRec, setCheckedRec] = useState<number[]>([]);
  const [confirmPopupShow, setConfirmPopupShow] = useState(false);
  const [deletableRecords, setDeletableRecords] = useState<any>([]);
  const [editData, setEditData] = useState<any>({});
  const [sortByField, setSortByField] = useState(defaultSorting?.key);
  const [sortType, setSortType] = useState(defaultSorting?.type);
  const [orderList, setOrderList] = useState<any>([]);
  const [filterList,setFilterList] = useState<any>([]);
  const [metaData, setMetaData] = useState({});
  const [filteredValue , setFilteredValue] = useState<any>({});
  const prefrence = useSelector(
    (state: any) => state?.masterReducer?.data?.component?.preferance
  );
  const { setLoading } = useLoading();
  const { setSidebar }: any = useContext(SidebarContext);
  const [AddInventoryModalShow, setAddInventoryModalShow] =
    React.useState(false);
  const dispatch = useDispatch();
    const modules = useSelector(
     (state: any) => state?.masterReducer?.data?.component?.modules
   );

   const getMetaData = () => {
    setLoading(true);  
    getListMetaDataApi(END_POINTS.metaInventory)
      .then((response: any) => {
        setMetaData(response?.component);
      })
      .catch((error: any) => {})
      .finally(() => {
        setLoading(false);
      });
  };

  

  useEffect(() => {
    getMetaData();
    setSidebar(SIDEBAR.INVENTORY);
  }, []);

  useEffect(() => {
    listApiCall();
  }, [currentPage, currentPageSize, sortByField, sortType , filteredValue]);

  useEffect(() => {
    if (refreshList) {
      listApiCall();
      setRefreshList(false);
    }
  }, [refreshList]);

  useEffect(() => {
    if (searchText != null) {
      const searchTimeOut = setTimeout(() => {
        if (currentPage === 1) {
          listApiCall();
        } else {
          setCurrentPage(1);
        }
      }, 500);
      return () => clearTimeout(searchTimeOut);
    }
  }, [searchText]);

  const listApiCall = () => {
    let params: any = {
      page: currentPage,
      limit: currentPageSize,
      search: searchText,
    };
    if (sortByField && sortType) {
      params["sort"] = {
        column: orderList[sortByField] ? orderList[sortByField] : defaultSorting?.value ,
        type: sortType,
      };
    }
    if(filteredValue && Object.keys(filteredValue)?.length){
      params["filter"] = filteredValue;
    }
    setExportParams(params);
    setLoading(true);
    listOfInventory(params)
      .then((response: any) => {
        setListData(response?.component?.listing);
        setRecordsTotal(response?.component?.recordsFiltered);
        setOrderList(response?.component?.orderlist);
        setFilterList(response?.component?.filterlist)
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const editHandler = (id: any) => {
    let params = {
      id: id,
    };
    setLoading(true);
    // dispatch(viewSingleHealthAction(params));
    viewInventory(params)
      .then((res: any) => {
        setIsEdit(true);
        setEditData(res?.component);
        setAddInventoryModalShow(true);
      })
      .catch((err) => {})
      .finally(() => {
        setLoading(false);
      });
  };

  const deleteHandler = () => {
    let params = {
      form_type: "vaccination",
      id: deletableRecords,
    };
    setLoading(true);
    destroyInventory(params)
      .then((res: any) => {
        setRefreshList(true);
        setConfirmPopupShow(false);
      })
      .catch((err: any) => {})
      .finally(() => {
        setLoading(false);
      });
  };

  const deleteNow = (id: any) => {
    // let ids = deletableRecords;
    let ids = [];
    ids.push(id);
    setDeletableRecords(ids);
    setConfirmPopupShow(true);
  };

  

  const exportHandler = () => {
    const formData = new FormData();
    formData.append("search", searchText ? searchText : "");
    exportInventory(formData, "inventory");
  };

  const handleExport = () => {
    if(!checkRoutePermission(PERMISSIONS?.EXPORT_FEATURE , modules)){
      upgradePopupAction({show:true});
      return;
    }
    setLoading(true);
    exportDocument(END_POINTS.inventoryExport, exportParams, "inventory")
      .then(() => {})
      .finally(() => {
        setLoading(false);
      });
  };

  
  return (
    <>
      <div className="inventory-page">
        {/* Page Title Start */}
        <div className="page-title-wrapper">
          <div className="title-first-menu">
            <div className="title-icon-text">
              <img src={InventoryIcon} alt="" />
              <h5>{t("Inventory")}</h5>
            </div>
            <div className="title-right-icons">
         
                <a className="unset-links" onClick={handleExport}>
                  <img src={ExportIcon} alt="" />
                </a>
              
            </div>
          </div>
        </div>
        {/* Page Title End */}
        <div className="page-content">
          <div className="data-tbl-wrapper">
            <div className="data-tbl-header">
              <SearchBox
               value={searchText}
                placeholder={`${t("Search...")}`}
                onChange={(e: any) => setSearchText(e.target.value)}
              />
              <div className="btn-wrapper f-fb">
                <Filter filterList={filterList} setCurrentPage={setCurrentPage} setFilteredValue={setFilteredValue}/>
                <CustomeButton
                  bg="fill"
                  onClick={() => {
                    setEditData({});
                    setIsEdit(false);
                    setAddInventoryModalShow(true);
                  }}
                >
                  <AddPlusIcon />
                  {t("Add")} 
                </CustomeButton>
              </div>
            </div>
            <div className="data-tbl">
              <table>
                <TableHead
                  data={tableHead}
                  orderList={orderList}
                  sortByField={sortByField}
                  sortType={sortType}
                  setSortType={setSortType}
                  setSortByField={setSortByField}
                />
                <tbody>
                  {listData?.map((items: any, index: number) => (
                    <tr key={index + "vaccination-data"}>
                      {/* <td className="ck-wrapper">
                      <OnlyCk
                            checked={checkedRec.includes(items?.id)}
                            onChange={(e: any) => checkSingleRec(e, items?.id)}
                          />

                      </td> */}
                      <td>
                        <p className="bl">{items.dates}</p>
                      </td>
                      <td>
                        <p className="bl">{items.category_value}</p>
                      </td>
                      <td>
                        <p className="bl">{items.product_name}</p>
                      </td>
                      <td>
                        <p className="bl">{items.qty}</p>
                      </td>
                      <td>
                        <p className="bl">{items.unit_value}</p>
                      </td>
                      <td>
                        <p className="bl">
                          {items.amount + " " + prefrence?.currency_value}
                        </p>
                      </td>
                      {/* <td>
                        <label
                          className={`bl status-badge ${
                            items.status.trim().toLowerCase() == "active"
                              ? "active"
                              : "inactive"
                          }`}
                        >
                          {items.status}
                        </label>
                      </td> */}
                      {/* <td>
                        <p className="bl">{items.contactType}</p>
                      </td> */}
                      <td>
                        <div className="icon-wrapper">
                          <button onClick={() => editHandler(items.id)}>
                            <img src={pencilIcon} alt="" />
                          </button>
                          <button onClick={() => deleteNow(items.id)}>
                            <img src={binIcon} alt="" />
                          </button>
                        </div>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
            {listData?.length ? (
              <Pagination
                results={recordsTotal}
                currentPage={currentPage}
                setCurrentPage={setCurrentPage}
                currentLength={currentPageSize}
                setCurrentPageSize={setCurrentPageSize}
              />
            ) : (
              <NoDataFound />
            )}
          </div>
        </div>
      </div>
      <AddInventoryPopup
        show={AddInventoryModalShow}
        isedit={isEdit.toString()}
        editdata={editData}
        metaData={metaData}
        setrefreshlist={() => {
          setRefreshList(true);
          getMetaData();
        }}
        onHide={() => setAddInventoryModalShow(false)}
      />

      <ConfirmPopup
        heading={t("Delete")}
        message={t("Are you sure to delete this record?")}
        show={confirmPopupShow}
        onHide={() => {
          setConfirmPopupShow(false);
          setDeletableRecords([]);
        }}
        confirm={deleteHandler}
      />
    </>
  );
}
