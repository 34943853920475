import React, { useEffect } from "react";
import Modal from "react-bootstrap/Modal";
import GoldIcon from "../../../Assets/img/gold.svg";
import SilverIcon from "../../../Assets/img/silver.svg";
import BronzeIcon from "../../../Assets/img/bronze.svg";
import Medal1Icon from "../../../Assets/img/Medal1.svg";
import Medal2Icon from "../../../Assets/img/Medal2.svg";
import Medal3Icon from "../../../Assets/img/Medal3.svg";
import Medal4Icon from "../../../Assets/img/Medal4.svg";
import Medal5Icon from "../../../Assets/img/Medal5.svg";
import { t } from "i18next";

export default function MedalRankingPopup(props: any) {
  const { rankingdata } = props;
  return (
    <Modal {...props} centered className="medal-ranking-popup modal-416">
      <Modal.Header closeButton>
        <p className="tl">{`${t("Medals & Ranking")}`}</p>
      </Modal.Header>
      <Modal.Body>
        <div className="medal-wrapper">
          <div className="medal-box">
            <p className="tm">{`${t("Arabian Horse Shows")}`}</p>
            <div className="medal-row">
              <div className="medal-column">
                <img src={GoldIcon} alt="" />
                <span className="tm">{rankingdata?.arabian?.gold}</span>
              </div>
              <div className="medal-column">
                <img src={SilverIcon} alt="" />
                <span className="tm">{rankingdata?.arabian?.silver}</span>
              </div>
              <div className="medal-column">
                <img src={BronzeIcon} alt="" />
                <span className="tm">{rankingdata?.arabian?.bronze}</span>
              </div>
            </div>
            <div className="medal-row">
              <div className="medal-column">
                <img src={Medal1Icon} alt="" />
                <span className="tm">{rankingdata?.arabian?.rank_1}</span>
              </div>
              <div className="medal-column">
                <img src={Medal2Icon} alt="" />
                <span className="tm">{rankingdata?.arabian?.rank_2}</span>
              </div>
              <div className="medal-column">
                <img src={Medal3Icon} alt="" />
                <span className="tm">{rankingdata?.arabian?.rank_3}</span>
              </div>
              <div className="medal-column">
                <img src={Medal4Icon} alt="" />
                <span className="tm">{rankingdata?.arabian?.rank_4}</span>
              </div>
              <div className="medal-column">
                <img src={Medal5Icon} alt="" />
                <span className="tm">{rankingdata?.arabian?.rank_5}</span>
              </div>
            </div>
          </div>
          <div className="medal-box">
            <p className="tm">{`${t("Endurance")}`}</p>
            <div className="medal-row">
              <div className="medal-column">
                <img src={Medal1Icon} alt="" />
                <span className="tm">{rankingdata?.endurance?.rank_1}</span>
              </div>
              <div className="medal-column">
                <img src={Medal2Icon} alt="" />
                <span className="tm">{rankingdata?.endurance?.rank_2}</span>
              </div>
              <div className="medal-column">
                <img src={Medal3Icon} alt="" />
                <span className="tm">{rankingdata?.endurance?.rank_3}</span>
              </div>
              <div className="medal-column">
                <img src={Medal4Icon} alt="" />
                <span className="tm">{rankingdata?.endurance?.rank_4}</span>
              </div>
              <div className="medal-column">
                <img src={Medal5Icon} alt="" />
                <span className="tm">{rankingdata?.endurance?.rank_5}</span>
              </div>
            </div>
          </div>
          <div className="medal-box">
            <p className="tm">{`${t("Flat Racing")}`}</p>
            <div className="medal-row">
              <div className="medal-column">
                <img src={Medal1Icon} alt="" />
                <span className="tm">{rankingdata?.flat_racing?.rank_1}</span>
              </div>
              <div className="medal-column">
                <img src={Medal2Icon} alt="" />
                <span className="tm">{rankingdata?.flat_racing?.rank_2}</span>
              </div>
              <div className="medal-column">
                <img src={Medal3Icon} alt="" />
                <span className="tm">{rankingdata?.flat_racing?.rank_3}</span>
              </div>
              <div className="medal-column">
                <img src={Medal4Icon} alt="" />
                <span className="tm">{rankingdata?.flat_racing?.rank_4}</span>
              </div>
              <div className="medal-column">
                <img src={Medal5Icon} alt="" />
                <span className="tm">{rankingdata?.flat_racing?.rank_5}</span>
              </div>
            </div>
          </div>
        </div>
      </Modal.Body>
    </Modal>
  );
}
