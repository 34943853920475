import React from "react";

export default function MarketplaceMobileIcon() {
  return (
    <svg
      width="32"
      height="32"
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M4 10.3H12H16M4 10.3H16M4 10.3L7.8 5C8.3 4.4 9.2 4 10 4H21.9C22.8 4 23.6 4.3 24.2 5L28 10.4V11.7C28 12.7 27.6 13.7 26.8 14.5C26.1 15.3 25 15.7 24 15.7C23 15.7 22 15.3 21.2 14.5C20.4 13.8 20 12.8 20 11.7M4 10.3V11.6C4 12.7 4.4 13.7 5.2 14.4C6 15.2 7 15.6 8 15.6C9 15.6 10.1 15.2 10.8 14.4C11.6 13.6 12 12.6 12 11.6M16 10.3H28M12 10.4V11.7C12 12.8 12.4 13.8 13.2 14.5C14 15.3 15 15.7 16 15.7C17 15.7 18.1 15.3 18.8 14.5C19.6 13.7 20 12.7 20 11.7M20 11.7V10.4"
        stroke="#E8E5E2"
        strokeWidth="1.5"
      />
      <path
        d="M25.3 15.5V25C25.3 26.7 24 28 22.3 28H9.70001C8.00001 28 6.70001 26.7 6.70001 25V15.5"
        stroke="#E8E5E2"
        strokeWidth="1.5"
      />
      <path
        d="M13 28V22.7C13 22 13.3 21.3 13.8 20.8C14.3 20.3 15 20 15.7 20H16.4C17.1 20 17.8 20.3 18.3 20.8C18.8 21.3 19.1 22 19.1 22.7V28"
        stroke="#E8E5E2"
        strokeWidth="1.5"
      />
    </svg>
  );
}
