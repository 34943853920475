import React, { useEffect, useState } from "react";

const InputGroups = (props: any) => {
  return (
    <div className="input-groups">
      {
        props.label && <label>{props.label}</label>
      }
      <input
        type={props.type}
        inputMode={props.inputmode}
        defaultValue={props.defaultValue}
        value={props.value}
        placeholder={props.placeholder}
        readOnly={props.readOnly}
        autoComplete={props.autoComplete}
        name={props.name}
        id={props.id}
        className={props.error && "error"}
        onChange={props.onChange}
        onKeyPress={props?.onKeyPress}
      />
      {props.error && <span className="error">{props.error}</span>}
    </div>
  );
};

export default InputGroups;
