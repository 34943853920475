import type { DatePickerProps } from "antd";
import moment from "moment";
import React, { useState, useEffect } from "react";
import Modal from "react-bootstrap/Modal";
import { useDispatch, useSelector } from "react-redux";
import CustomeButton from "../../Common/CustomButton/CustomButton";
import Datepicker from "../../Common/Datepicker/Datepicker";
import FileUpload from "../../Common/FileUpload/FileUpload";
import InputSelectGroup from "../../Common/InputSelectGroup/InputSelectGroup";
import InputSelectMix from "../../Common/InputSelectMix/InputSelectMix";
import SelectGropus from "../../Common/SelectGroups/SelectGroups";
import TextButton from "../../Common/TextButton/TextButton";
import {
  farrierContactAction,
  metaShoeingTypesAction,
  metaShoeMaterialAction,
} from "../../../Redux/General/GeneralAction";
import validate from "../../../Utility/Validation";
import { addEditFarrierRecordAction } from "../../../Redux/Horse/Farrier/FarrierAction";
import {
  ADD_FARRIER_RECORD_REQUEST,
  ADD_FARRIER_RECORD_FAILD,
  ADD_FARRIER_RECORD_SUCCESS,
} from "../../../Redux/Horse/Farrier/FarrierTypes";
import { API_STATUS } from "../../../Constant/Api";
import { SUCCESS_RESPONSE_CODE } from "../../../Constant/Status";
import {
  getFileNamefromLink,
  getFileNamefromPath,
  isFileValidated,
} from "../../../Utility/General";
import { useLoading } from "../../../context/LoadingContext";
import { useLocation, useNavigate } from "react-router-dom";
import { t } from "i18next";
import { FORMLABEL } from "../../../Constant/General";

export default function AddFarrierPopup(props: any) {
  const INTIAL_FARRIER_RECORD_DATA: any = {
    form_type: {
      value: "farrier",
      validation: ["required"],
      errors: [t("Form Type is required.")],
    },
    id: {
      value: "",
    },
    date: {
      value: moment().format("YYYY-MM-DD"),
      validation: ["required"],
      errors: [t("Date is required.")],
    },
    select_farrier: {
      value: "farrier_id",
      // validation: ["requiredIf:vet_clinic_id"],
      errors: [t("Farrier is required.")],
    },
    farrier: {
      value: "",
      validation: ["requiredIf:select_farrier:farrier", "letters"],
      errors: [t("Farrier is required."), t("Enter valid farrier name.")],
    },
    farrier_id: {
      value: "",
      validation: ["requiredIf:select_farrier:farrier_id"],
      errors: [t("Farrier is required.")],
    },
    shoeing_type: {
      value: "",
      validation: ["required"],
      errors: [t("Shoeing Type is required.")],
    },
    shoe_material: {
      value: "",
      validation: [""],
      errors: [t("Shoeing Material is required.")],
    },
    amount: {
      value: "",
      validation: [""],
      errors: [t("Amount Type is required.")],
    },
    document_file: {
      value: "",
    },
  };

  const dispatch = useDispatch();
  const FarrierValue = [""];
  const SHOEING_ITEMS = ["44", "45", "46"];

  const [fileData, setFileData] = useState<any>(t("Upload Documents"));
  const [fileDataName, setFileDataName] = useState<any>(t("Upload Documents"));
  const [FarrierToggle, setFarrierToggle] = useState("select");
  const [farrierrRecordData, setFarrierRecordData] = useState(
    INTIAL_FARRIER_RECORD_DATA
  );
  const [isFormValidated, setIsFormValidated] = useState(false);
  const [farrierRecordError, setFarrierRecordError] = useState<any>({});
  const [apiStatus, setApiStatus] = useState(API_STATUS.SUCCESS);
  const [removeDoc, setRemoveDoc] = useState<any>(0);
  const [fileDataSelected, setFileDataSelected] = useState("");
  const allowedFormate = ["jpg", "png", "pdf", "docx" , "heic" , "heif", "jpeg"];
  const allowedFileSizeInMb = 3;
  const location:any = useLocation();
  const navigate = useNavigate();
  const { setLoading } = useLoading();


  const addEditFarrierRecordResponse = useSelector(
    (state: any) => state?.addEditFarrierRecordReducer
  );
  const prefrence = useSelector(
    (state: any) => state?.masterReducer?.data?.component?.preferance
  );

  const dateChange: DatePickerProps["onChange"] = (date, dateString) => {
    let dateIs = date?.format("YYYY-MM-DD");
    setData("date", dateIs);
  };
  const setData = (field: string, value: any) => {
    const farrierRecord = Object.assign({}, farrierrRecordData);
    farrierRecord[field].value = value;
    setFarrierRecordData({ ...farrierRecord });
  };
  const fileChange = (e: any) => {
    let file = e.target.files[0];
    if (isFileValidated(file, allowedFormate, allowedFileSizeInMb)) {
      let fileName = getFileNamefromPath(e.target.value);
      setFileDataSelected(fileName);
      setFileData(e.target.files[0]);
      if (props.isedit) {
        setRemoveDoc(1);
      }
    } else {
      e.target.value = "";
    }
  };

  const checkExistFile = () => {
    if (props.isedit) {
      setRemoveDoc(1);
    }
    setFileData("");
    setFileDataSelected("");
  };

  useEffect(() => {
    const farrierRecord = Object.assign({}, farrierrRecordData);
    if (SHOEING_ITEMS.includes(farrierrRecordData?.shoeing_type?.value)) {
      farrierRecord["shoe_material"].validation[0] = "required";
    } else {
      farrierRecord["shoe_material"].validation[0] = "";
    }
    setFarrierRecordData({ ...farrierRecord });
  }, [farrierrRecordData?.shoeing_type?.value]);

  const submitHandler = () => {
    if (validate(farrierrRecordData).isValidated === true) {
      const horseId = props.horseid;
      const formData = new FormData();
      Object.keys(farrierrRecordData).forEach((element) => {
        if (farrierrRecordData[element].value) {
          formData.append(element, farrierrRecordData[element].value);
        }
      });
      if (farrierrRecordData.farrier.value) {
        formData.delete("farrier_id");
      }
      if (props?.isedit == false) {
        formData.delete("id");
      }
      formData.append("horse_id", horseId);
      formData.append("document", fileData);
      formData.append("remove_doc", removeDoc);

      addEditFarrierRecordAction(formData);
    } else {
      setIsFormValidated(true);
      setFarrierRecordError({ ...validate(farrierrRecordData).errors });
    }
  };



  useEffect(() => {
    setFarrierRecordError({ ...validate(farrierrRecordData).errors });
  }, [farrierrRecordData]);

  useEffect(() => {
    if (farrierrRecordData?.farrier?.value) {
      setData("farrier_id", "");
    }
  }, [farrierrRecordData?.farrier?.value]);

  useEffect(() => {
    if (addEditFarrierRecordResponse?.type === ADD_FARRIER_RECORD_REQUEST) {
      if (apiStatus !== API_STATUS.LOADING) {
        setLoading(true);
        setApiStatus(API_STATUS.LOADING);
      }
    } else if (
      addEditFarrierRecordResponse?.type === ADD_FARRIER_RECORD_FAILD
    ) {
      if (apiStatus !== API_STATUS.FAILED) {
        setLoading(false);
        setApiStatus(API_STATUS.FAILED);
      }
    } else if (
      addEditFarrierRecordResponse?.type === ADD_FARRIER_RECORD_SUCCESS &&
      addEditFarrierRecordResponse?.data.statusCode === SUCCESS_RESPONSE_CODE
    ) {
      if (apiStatus !== API_STATUS.SUCCESS) {
        setApiStatus(API_STATUS.SUCCESS);
        setLoading(false);
        if (location?.state?.reminder_id && location?.state?.path) {
          let reminder_id = location?.state?.reminder_id;
          navigate(location?.state?.path, {
            state: { reminder_id: reminder_id, status: "1" },
          });
        } else {
          props.onHide();
          props.setrefreshlist();
        }
      }
    }
  }, [addEditFarrierRecordResponse]);

  useEffect(() => {
    if (props.isedit) {
      if (props?.farrierdata) {
        setData("farrier_id", props?.farrierdata?.farrier_id);
        setData("shoeing_type", props?.farrierdata?.shoeing_type);
        setData("shoe_material", props?.farrierdata?.shoe_material);
        setData("amount", props?.farrierdata?.amount);
        setData("document_file", props?.farrierdata?.document_file);
        setData("date", props?.farrierdata?.dates);
        setData("id", props?.farrierdata?.id);

        if (props?.farrierdata?.document) {
          let document = props?.farrierdata?.document;
          document = getFileNamefromLink(document);
          setFileDataSelected(document);
        }
      }
    } else {
      setRemoveDoc(0);
      setFileDataSelected("");
      setFileData("");
      setFarrierRecordData(INTIAL_FARRIER_RECORD_DATA);
    }
  }, [props?.farrierdata]);

  useEffect(() => {
    if (props?.show === false) {
      setRemoveDoc(0);
      setFileDataSelected("");
      setFarrierToggle("select")
      setFileData("");
      setFarrierRecordData(INTIAL_FARRIER_RECORD_DATA);
      setFarrierRecordError({});
      setIsFormValidated(false);
    }
  }, [props?.show]);

  useEffect(() => {
    if (FarrierToggle == "select") {
      setData("select_farrier", "farrier_id");
      setData("farrier", "");
    } else {
      setData("select_farrier", "farrier");
      setData("farrier_id", "");
    }
  }, [FarrierToggle]);

  const { setrefreshlist, farrierdata, horseid , metaData , ...rest } = props;

  return (
    <Modal {...rest} centered className="add-farrier-popup modal-416">
      <Modal.Header closeButton>
        <p className="tl">
          {props.isedit ? t("Update Farrier Record") : t("Add Farrier Record")}{" "}
        </p>
      </Modal.Header>
      <Modal.Body>
        <div>
          <div>
            <Datepicker
              label={`${t("Date")}*`}
              endDate={moment().format("YYYY-MM-DD")}
              onChange={dateChange}
              value={moment(farrierrRecordData?.date?.value)}
              error={
                isFormValidated && farrierRecordError["date"]
                  ? farrierRecordError["date"]
                  : null
              }
            />
            <InputSelectGroup
              label={`${t("Farrier")}*`}
              selectValue={metaData?.farrier} 
              onSelectChange={(value: any) => {
                setData("farrier_id", value);
              }}
              defaultSelect={farrierrRecordData?.farrier_id?.value}
              dropKey="id"
              dropVal="first_name"
              inputSelectToggle={FarrierToggle}
              setInputSelectToggle={setFarrierToggle}
              onInputChange={(e: any) => {
                setData("farrier", e.target.value);
              }}
              error={
                isFormValidated &&
                (farrierRecordError["farrier"] ||
                  farrierRecordError["farrier_id"])
                  ? farrierRecordError["farrier"] ||
                    farrierRecordError["farrier_id"]
                  : null
              }
            />
            <SelectGropus
              label={`${t("Shoeing Type")}*`}
              value={metaData?.shoeing_type}
              defaultSelect={farrierrRecordData?.shoeing_type.value}
              onChange={(e: any) => setData("shoeing_type", e.target.value)}
              dropKey="id"
              dropVal="metadata"
              error={
                isFormValidated && farrierRecordError["shoeing_type"]
                  ? farrierRecordError["shoeing_type"]
                  : null
              }
            />
            {SHOEING_ITEMS.includes(farrierrRecordData?.shoeing_type.value) && (
              <SelectGropus
                label={`${t("Shoe Material")}*`}
                value={metaData?.shoeing_material}
                defaultSelect={farrierrRecordData?.shoe_material.value}
                onChange={(e: any) => setData("shoe_material", e.target.value)}
                dropKey="id"
                dropVal="metadata"
                error={
                  isFormValidated && farrierRecordError["shoe_material"]
                    ? farrierRecordError["shoe_material"]
                    : null
                }
              />
            )}

            <InputSelectMix
              label={`${t("Amount")}`}
              inputType="number"
              onInputChange={(e: any) => setData("amount", e.target.value)}
              inputSecondValue={prefrence?.currency_value}
              inputValue={farrierrRecordData?.amount?.value}
              inputError={
                isFormValidated && farrierRecordError["amount"]
                  ? farrierRecordError["amount"]
                  : null
              }
            />
          </div>
          <FileUpload
            onChange={(e: any) => {
              fileChange(e);
            }}
            value={fileDataName}
            removeFile={() => checkExistFile()}
            selectedValue={fileDataSelected}
            fileType={t(FORMLABEL.USE_FILE_TYPE)}
          />
        </div>
      </Modal.Body>
      <Modal.Footer>
        <div className="footer-2-btn">
          <TextButton onClick={props.onHide}> {t("Cancel")}</TextButton>
          <CustomeButton bg="fill" onClick={() => submitHandler()}>
            {props.isedit ? t("Update") : t("Add")}
          </CustomeButton>
        </div>
      </Modal.Footer>
    </Modal>
  );
}
