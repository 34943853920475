import Button from "@mui/material/Button";
import React, { useEffect, useRef } from "react";
import CloseIcon from "../../../Assets/img/plus.svg";
import Tooltip from "@mui/material/Tooltip";
import CustomeButton from "../CustomButton/CustomButton";
import { getFileNamefromPath } from "../../../Utility/General";
import { t } from "i18next";

export default function FileUpload(props: any) {
  const [open, setOpen] = React.useState(false);
  const inputRef : any = useRef();

  const handleTooltipClose = () => {
    setOpen(false);
  };

  const handleTooltipOpen = () => {
    setOpen(true);
  };
  const handleTooltipOkay = () => {
    inputRef.current.value = "";
    props.removeFile();
    setOpen(false);
  };

  useEffect(() => {
    if(props?.resetInput){
      inputRef.current.value = "";
      props?.setResetInput(false);
    }
  }, [props?.resetInput])
  

  const tooltipValue = () => {
    return (
      <div className="tooltip-confirm">
        <p className="bs">{t("Are you Sure")} ?</p>
        <div className="tlp-btns">
          <CustomeButton
            onClick={() => {
              handleTooltipClose();
            }}
          >
            {t("Cancel")} 
          </CustomeButton>
          <CustomeButton onClick={() => handleTooltipOkay()} bg="fill">
          {t("Ok")} 
          </CustomeButton>
        </div>
      </div>
    );
  };

  return (
    <div className="font-0 file-upload-wrapper">
      <div className="file-upload">
        <Button variant="contained" className="upload-btn" component="label">
          <input
            hidden
            ref={inputRef}
            accept={props.accept?props.accept:"image/*"}
            onChange={props.onChange}
            type="file"
          />
        </Button>
        <div className="upload-content">
          {props.selectedValue ? (
            <div className="bm">
              {getFileNamefromPath(props.selectedValue)}

              {/* <ClickAwayListener onClickAway={handleTooltipClose}> */}
              <div>
                <Tooltip
                  PopperProps={{
                    disablePortal: true,
                  }}
                  arrow={true}
                  onClose={handleTooltipClose}
                  open={open}
                  disableFocusListener
                  disableHoverListener
                  disableTouchListener
                  title={tooltipValue()}
                >
                  <img src={CloseIcon} onClick={handleTooltipOpen} />
                </Tooltip>
              </div>
              {/* </ClickAwayListener> */}
            </div>
          ) : (
            <p className="bm">{props?.value ? props?.value  : t("Upload Documents")}</p>
          )}
          <p className="bs blur-color">{props.fileType}</p>
        </div>
      </div>
      {props.error && <span className="error">{props.error}</span>}
    </div>
  );
}
