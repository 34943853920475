
import React from 'react'
import UploadIcon from "../../../Assets/img/Uploadicon.svg";

export default function FileUploadImage(props:any) {
  return (
         <label className='file-upload-label'> 
         <img src={UploadIcon} alt="Upload" />
         <input hidden accept="/*" onChange={props.onChange}  type="file" />
        </label>
  )
}
