import React from "react";
import Modal from "react-bootstrap/Modal";
import CustomeButton from "../../Common/CustomButton/CustomButton";
import { useNavigate } from "react-router-dom";
import TextButton from "../../Common/TextButton/TextButton";
import { t } from "i18next";

export default function ConfirmPopup(props: any) {
  const {confirm , ...rest} = props 
  return (
    <Modal {...rest} centered className="logout-popup modal-416">
      <Modal.Body>
        <p className="tl">{props.heading}</p>
        <span className="bl">{props.message}</span>
      </Modal.Body>
      <Modal.Footer>
        <div className="footer-2-btn">
          <TextButton onClick={props.onHide}>{t("Cancel")}</TextButton>
          <CustomeButton onClick={props.confirm} bg="fill">
            {t("Confirm")}
          </CustomeButton>
        </div>
      </Modal.Footer>
    </Modal>
  );
}
