import type { DatePickerProps } from "antd";
import { t } from "i18next";
import type { Moment } from "moment";
import moment from "moment";
import React, { useState, useEffect } from "react";
import { Col, Row } from "react-bootstrap";
import Modal from "react-bootstrap/Modal";
import { useDispatch, useSelector } from "react-redux";
import { useLoading } from "../../../context/LoadingContext";
import { addEditBreedingFlush } from "../../../Redux/NonRedux/breeding";
import { formateTimeForTimePicker } from "../../../Utility/General";
import validate from "../../../Utility/Validation";
import CustomeButton from "../../Common/CustomButton/CustomButton";
import Datepicker from "../../Common/Datepicker/Datepicker";
import InputSelectGroup from "../../Common/InputSelectGroup/InputSelectGroup";
import InputSelectMix from "../../Common/InputSelectMix/InputSelectMix";
import SelectGropus from "../../Common/SelectGroups/SelectGroups";
import TextButton from "../../Common/TextButton/TextButton";
import Timepicker from "../../Common/Timepicker/Timepicker";
import ToggleButton from "../../Common/ToggleButton/ToggleButton";

export default function AddFlushPopup(props: any) {
  const INTIAL_DATA = {
    id: {
      value: "",
    },
    dates: {
      value: moment().format("YYYY-MM-DD"),
      validation: ["required"],
      errors: [t("Date is required.")],
    },
    amount: {
      value: "",
      validation: [""],
      errors: [t("Amount is required.")],
    },
    select_vet_clinic: {
      value: "vet_clinic_id",
      // validation: ["requiredIf:vet_clinic_id"],
      errors: [t("Vet Clinic is required.")],
    },
    vet_clinic: {
      value: "",
      validation: ["requiredIf:select_vet_clinic:vet_clinic", "letters"],
      errors: [t("Vet Clinic is required."), t("Incorrect format")],
    },
    vet_clinic_id: {
      value: "",
      validation: ["requiredIf:select_vet_clinic:vet_clinic_id"],
      errors: [t("Vet Clinic is required.")],
    },
    flush_status: {
      value: "2",
      validation: ["required"],
      errors: [t("Flush Status is required.")],
    },

    time: {
      value: moment().format("YYYY-MM-DD HH:mm"),
      validation: ["required"],
      errors: [t("Time is required.")],
    },
  };

  const [formData, setFormData] = useState<any>(INTIAL_DATA);
  const { setLoading } = useLoading();
  const [formDataError, setFormDataError] = useState<any>({});
  const [isFormValidated, setIsFormValidated] = useState(false);
  const {
    metaData,
    editData,
    serviceId,
    setRefreshList,
    breedingServiceDate,
    isedit,
    ...rest
  } = props;

  const dispatch = useDispatch();

  const prefrence = useSelector(
    (state: any) => state?.masterReducer?.data?.component?.preferance
  );

  const setData = (field: string, value: any) => {
    const tempOb = Object.assign({}, formData);
    tempOb[field].value = value;
    setFormData({ ...tempOb });
  };

  useEffect(() => {
    setFormDataError({ ...validate(formData).errors });
  }, [formData]);

  const dateChange: DatePickerProps["onChange"] = (date, dateString) => {
    let dateIs = date?.format("YYYY-MM-DD");
    setData("dates", dateIs);
  };

  const timeChange = (time: Moment, timeString: string) => {
    setData("time", time.format("YYYY-MM-DD HH:mm"));
  };

  const [inputSelectToggle, setInputSelectToggle] = useState("select");

  useEffect(() => {
    if (props?.show === false) {
      setFormDataError({});
      setFormData(INTIAL_DATA);
      setIsFormValidated(false);
      setInputSelectToggle("select");
    }
  }, [props?.show]);

  useEffect(() => {
    if (props.isedit && Object.keys(props?.editData)?.length) {
      Object.keys(INTIAL_DATA).forEach((element) => {
        if (props?.editData?.[element]) {
          setData(element, `${props?.editData?.[element]}`);
        }
      });
      setData("time", formateTimeForTimePicker(props?.editData?.["time"]));
    } else {
      setFormData(INTIAL_DATA);
      setData(
        "vet_clinic_id",
        props?.metaData?.vet_clinic_id ? props?.metaData?.vet_clinic_id : ""
      );
    }
  }, [props?.editData, props.isedit]);

  const submitHandler = () => {
    if (validate(formData).isValidated === true) {
      let params: any = {};
      Object.keys(formData).forEach((element) => {
        params[element] = formData[element].value;
      });
      params["service_id"] = props?.serviceId;
      setLoading(true);
      addEditBreedingFlush(params)
        .then((response: any) => {
          props.onHide();
          props.setRefreshList();
        })
        .finally(() => {
          setLoading(false);
        });
    } else {
      setFormDataError({ ...validate(formData)?.errors });
      setIsFormValidated(true);
    }
  };

  const toggleChange = (selectType: string, input: string) => {
    if (selectType === "select") {
      setData(`select_${input}`, `${input}_id`);
      setData(input, "");
    } else {
      setData(`select_${input}`, input);
      setData(`${input}_id`, "");
    }
  };

  return (
    <Modal {...rest} centered className="add-flush-popup modal-784">
      <Modal.Header closeButton>
        <p className="tl">{props.isedit ? t("Edit Flush") : t("Add Flush")} </p>
      </Modal.Header>
      <Modal.Body>
        <Row>
          <Col sm={6} md={6}>
            <Datepicker
              startDate={
                breedingServiceDate
                  ? moment(breedingServiceDate).format("YYYY-MM-DD")
                  : null
              }
              endDate={moment().format("YYYY-MM-DD")}
              label={`${t("Date")}*`}
              onChange={dateChange}
              value={moment(formData?.dates?.value)}
              error={
                isFormValidated && formDataError["dates"]
                  ? formDataError["dates"]
                  : null
              }
            />
          </Col>
          <Col sm={6} md={6}>
            <Timepicker
              label={`${t("Time")}*`}
              onChange={timeChange}
              value={moment(formData?.time?.value)}
              error={
                isFormValidated && formDataError["time"]
                  ? formDataError["time"]
                  : null
              }
            />
          </Col>
          <Col sm={6} md={6}>
            <InputSelectGroup
              label={`${t("Vet / Clinic")}*`}
              selectValue={metaData?.vet_clinic}
              dropKey="id"
              dropVal="first_name"
              defaultSelect={formData?.vet_clinic_id?.value}
              value={formData?.vet_clinic?.value}
              inputSelectToggle={inputSelectToggle}
              setInputSelectToggle={(val: any) => {
                setInputSelectToggle(val);
                toggleChange(val, "vet_clinic");
              }}
              error={
                isFormValidated &&
                (formDataError["vet_clinic"] || formDataError["vet_clinic_id"])
                  ? formDataError["vet_clinic"] ||
                    formDataError["vet_clinic_id"]
                  : null
              }
              onSelectChange={(e: any) => {
                setData("vet_clinic_id", e);
              }}
              onInputChange={(e: any) => {
                setData("vet_clinic", e.target.value);
              }}
            />
          </Col>
        </Row>
        <Row>
          <Col sm={6} md={6}>
            <ToggleButton
              value={metaData?.flush_status}
              label={`${t("Flush Status Success")}*`}
              toggleKey="key"
              toggleVal="value"
              selected={formData?.flush_status?.value}
              setSelected={(val: any) => {
                setData("flush_status", val);
              }}
            ></ToggleButton>
          </Col>

          <Col sm={6} md={6}>
            <InputSelectMix
              label={`${t("Amount")}`}
              inputValue={formData?.amount?.value}
              inputType="number"
              inputAutoComplete="off"
              onInputChange={(e: any) => setData("amount", e.target.value)}
              inputError={
                isFormValidated && formDataError["amount"]
                  ? formDataError["amount"]
                  : null
              }
              inputSecondValue={prefrence?.currency_value}
            />
          </Col>
        </Row>
      </Modal.Body>
      <Modal.Footer>
        <div className="footer-2-btn">
          <TextButton onClick={props?.onHide}> {t("Cancel")}</TextButton>
          <CustomeButton bg="fill" onClick={submitHandler}>
            {props.isedit ? t("Update") : t("Add")}
          </CustomeButton>
        </div>
      </Modal.Footer>
    </Modal>
  );
}
