import type { DatePickerProps } from "antd";
import { t } from "i18next";
import type { Moment } from "moment";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { Col, Row } from "react-bootstrap";
import Modal from "react-bootstrap/Modal";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import { API_STATUS } from "../../../Constant/Api";
import { FORMLABEL } from "../../../Constant/General";
import { SUCCESS_RESPONSE_CODE } from "../../../Constant/Status";
import { useLoading } from "../../../context/LoadingContext";
import { REMINDER_ADDED } from "../../../Redux/General/GeneralType";
import { addEditHealthAction } from "../../../Redux/Horse/Health/SingleHealthAction";
import {
  ADD_HORSE_HEALTH_FAILD,
  ADD_HORSE_HEALTH_LONG,
  ADD_HORSE_HEALTH_REQUEST,
  ADD_HORSE_HEALTH_SUCCESS,
} from "../../../Redux/Horse/Health/SingleHorseType";
import {
  isFileValidated,
  getFileNamefromPath,
  getFileNamefromLink,
} from "../../../Utility/General";
import validate from "../../../Utility/Validation";
import CustomeButton from "../../Common/CustomButton/CustomButton";
import Datepicker from "../../Common/Datepicker/Datepicker";
import FileUpload from "../../Common/FileUpload/FileUpload";
import InputGroups from "../../Common/InputGroups/InputGroups";
import InputSelectGroup from "../../Common/InputSelectGroup/InputSelectGroup";
import InputSelectMix from "../../Common/InputSelectMix/InputSelectMix";
import MultiSelectGroup from "../../Common/MultiSelectGroup/MultiSelectGroup";
import SelectGropus from "../../Common/SelectGroups/SelectGroups";
import TextButton from "../../Common/TextButton/TextButton";
import Timepicker from "../../Common/Timepicker/Timepicker";

export default function AddVaccinationPopup(props: any) {
  const INITIAL_VITAL_HEALTH_DATA: any = {
    form_type: {
      value: "vaccination",
      validation: ["required"],
      errors: ["Form Type is required."],
    },
    horse_ids: {
      value: [],
      validation: [""],
      errors: [t("Horse is required.")],
    },
    vaccine: {
      value: "",
      validation: ["required"],
      errors: [t("Vaccine  is required.")],
    },
    product_name: {
      value: "",
      validation: [""],
      errors: [t("Product Name is required.")],
    },
    amount: {
      value: "",
      validation: [""],
      errors: [t("Amount is required.")],
    },
    document_file: {
      value: "",
      // validation: ["required"],
      // errors: [t("Document File is required.")],
    },
    date: {
      value: moment().format("YYYY-MM-DD"),
      validation: ["required"],
      errors: [t("Date is required.")],
    },
    time: {
      value: moment().format("YYYY-MM-DD HH:mm"),
      validation: ["required"],
      errors: [t("Time is required.")],
    },
    id: {
      value: "",
    },
  };
  const [healthData, setHealthData] = useState(INITIAL_VITAL_HEALTH_DATA);
  const [apiStatus, setApiStatus] = useState(API_STATUS.SUCCESS);
  const [isFormValidated, setIsFormValidated] = useState(false);
  let demo: any = {};
  const [healthDataError, setHealthDataError] = useState(demo);
  const { setLoading } = useLoading();
  const dateChange: DatePickerProps["onChange"] = (date, dateString) => {
    let dateIs = date?.format("YYYY-MM-DD");
    setData("date", dateIs);
  };

  const timeChange = (time: Moment, timeString: string) => {
    setData("time", time.format("YYYY-MM-DD HH:mm"));
  };

  const [fileDataName, setFileDataName] = useState<any>(t("Upload Documents"));
  const [fileData, setFileData] = useState<any>(t("Upload Documents"));
  const [removeDoc, setRemoveDoc] = useState<any>(0);
  const [fileDataSelected, setFileDataSelected] = useState("");
  const allowedFormate = ["jpg", "png", "pdf", "docx" , "heic" , "heif", "jpeg"];
  const allowedFileSizeInMb = 3;


  const location:any = useLocation();
  const navigate = useNavigate();
  const fileChange = (e: any) => {
    let file = e.target.files[0];
    if (isFileValidated(file, allowedFormate, allowedFileSizeInMb)) {
      let fileName = getFileNamefromPath(e.target.value);
      setFileDataSelected(fileName);
      setFileData(e.target.files[0]);
      if (props.isedit) {
        setRemoveDoc(1);
      }
    } else {
      e.target.value = "";
    }
  };

  const checkExistFile = () => {
    if (props.isedit) {
      setRemoveDoc(1);
      setFileDataSelected("");
    } else {
      setFileData("");
      setFileDataSelected("");
    }
  };

  const setData = (field: string, value: any) => {
    let horseDetails = Object.assign({}, healthData);
    horseDetails[field].value = value;
    setHealthData({ ...horseDetails });
  };

  useEffect(() => {
    setHealthDataError({ ...validate(healthData)?.errors });
  }, [healthData]);
  const dispatch = useDispatch();
  const submitHandler = () => {
    if (validate(healthData).isValidated === true) {
      let horseId = props.horseid;
      const formData = new FormData();
      if (props.isedit == "false" && props?.isMultiple) {
        healthData?.horse_ids?.value?.map((val: any) => {
          formData.append("horse_id[]", val);
        });
      } else {
        formData.append("horse_id", horseId);
      }

      Object.keys(healthData).forEach((element) => {
        if (element === "horse_ids") {
          return;
        }
        if (healthData[element].value) {
          formData.append(element, healthData[element].value);
        }
      });
     
      formData.append("document", fileData);
      formData.append("remove_doc", removeDoc);
      addEditHealthAction(formData);
    } else {
      setHealthDataError({ ...validate(healthData)?.errors });
      setIsFormValidated(true);
    }
  };

  useSelector((state: any) => {
    if (state?.singleHealthAddEditReducer?.type === ADD_HORSE_HEALTH_REQUEST) {
      if (apiStatus !== API_STATUS.LOADING) {
        setApiStatus(API_STATUS.LOADING);
        setLoading(true);
      }
    } else if (
      state?.singleHealthAddEditReducer?.data?.statusCode ===
        SUCCESS_RESPONSE_CODE &&
      state?.singleHealthAddEditReducer?.type === ADD_HORSE_HEALTH_SUCCESS
    ) {
      if (apiStatus !== API_STATUS.SUCCESS) {
        setApiStatus(API_STATUS.SUCCESS);
        setLoading(false);
        if (location?.state?.reminder_id && location?.state?.path) {
          let reminder_id = location?.state?.reminder_id;
          navigate(location?.state?.path, {
            state: { reminder_id: reminder_id, status: "1" },
          });
        } else {
          props.onHide();
          props.setrefreshlist();
        }
        
      }
    } else if (
      state?.singleHealthAddEditReducer?.type === ADD_HORSE_HEALTH_LONG
    ) {
      if (apiStatus !== API_STATUS.LONG) {
        setLoading(false);
        setApiStatus(API_STATUS.LONG);
      }
    } else if (
      state?.singleHealthAddEditReducer?.type === ADD_HORSE_HEALTH_FAILD
    ) {
      if (apiStatus !== API_STATUS.FAILED) {
        setApiStatus(API_STATUS.FAILED);
        setLoading(false);
      }
    }
  });

  useEffect(() => {
    if (props.isedit == "true") {
      if (props.healthdata) {
        setData("vaccine", props?.healthdata?.vaccine);
        setData("product_name", props?.healthdata?.product_name);
        setData("amount", props?.healthdata?.amount);
        setData("document_file", props?.healthdata?.document_file);
        setData("date", props?.healthdata?.withoutFormatedDate);
        setData("time", props?.healthdata?.withoutFormatedTime);
        setData("id", props?.healthdata?.id);

        if (props?.healthdata?.document) {
          let document = props?.healthdata?.document;
          document = getFileNamefromLink(document);
          setFileDataSelected(document);
        }
      }
    }
  }, [props?.healthdata]);

  useEffect(() => {
    if (props?.show === false) {
      setHealthDataError({});
      setHealthData(INITIAL_VITAL_HEALTH_DATA);
      setIsFormValidated(false);
      setRemoveDoc(0);
      setFileDataSelected("");
      setFileData("");
    } else if (props?.isMultiple && props.isedit == "false") {
      const ob: any = Object.assign({}, healthData);
      ob["horse_ids"].validation[0] = "requiredArray";
      setHealthData({ ...ob });
    }
  }, [props?.show]);



  const prefrence = useSelector(
    (state: any) => state?.masterReducer?.data?.component?.preferance
  );
  const { setrefreshlist, isMultiple, healthdata, metaData , horseid, ...rest } = props;
  
  return (
    <Modal {...rest} centered className="add-vaccination-popup modal-784">
      <Modal.Header closeButton>
        <p className="tl">
          {props.isedit == "true"
            ? t("Edit Vaccination")
            : t("Add Vaccination")}
        </p>
      </Modal.Header>
      <Modal.Body>
        {props.isedit == "false" && props?.isMultiple ? (
          <Row>
            <Col sm={6} md={6}>
              <MultiSelectGroup
                label={`${t("Horse")}*`}
                value={metaData?.horse_list}
                defaultSelect={healthData?.horse_ids?.value}
                dropKey="id"
                dropVal="name"
                onChange={(e: any) => {
                  setData("horse_ids", e);
                }}
                error={
                  isFormValidated && healthDataError["horse_ids"]
                    ? healthDataError["horse_ids"]
                    : null
                }
              />
            </Col>
          </Row>
        ) : null}

        <Row>
          <Col sm={6} md={6}>
            <Datepicker
              label={`${t("Date")}*`}
              endDate={moment().format("YYYY-MM-DD")}
              onChange={dateChange}
              value={moment(healthData?.date?.value)}
              error={
                isFormValidated && healthDataError["date"]
                  ? healthDataError["date"]
                  : null
              }
            />
          </Col>
          <Col sm={6} md={6}>
            <Timepicker
              label={`${t("Time")}*`}
              onChange={timeChange}
              value={moment(healthData?.time?.value)}
              error={
                isFormValidated && healthDataError["time"]
                  ? healthDataError["time"]
                  : null
              }
            />
          </Col>
          <Col sm={6} md={6}>
            <SelectGropus
              label={`${t("Vaccine")}*`}
              defaultSelect={healthData?.vaccine?.value}
              value={metaData?.vaccine}
              dropKey="id"
              dropVal="metadata"
              onChange={(e: any) => {
                setData("vaccine", e.target.value);
              }}
              error={
                isFormValidated && healthDataError["vaccine"]
                  ? healthDataError["vaccine"]
                  : null
              }
            />
          </Col>
          <Col sm={6} md={6}>
            <InputGroups
              label={`${t("Product Name")}`}
              value={healthData?.product_name?.value}
              type="text"
              autoComplete="off"
              onChange={(e: any) => setData("product_name", e.target.value)}
              error={
                isFormValidated && healthDataError["product_name"]
                  ? healthDataError["product_name"]
                  : null
              }
            />
          </Col>
          <Col sm={6} md={6}>
            <InputSelectMix
              label={`${t("Amount")}`}
              inputValue={healthData?.amount?.value}
              inputType="number"
              inputAutoComplete="off"
              onInputChange={(e: any) => setData("amount", e.target.value)}
              inputError={
                isFormValidated && healthDataError["amount"]
                  ? healthDataError["amount"]
                  : null
              }
              error={
                isFormValidated && healthDataError["amount"]
                  ? healthDataError["amount"]
                  : null
              }
              inputSecondValue={prefrence?.currency_value}
            />
          </Col>
          <Col sm={12} md={12}>
            <FileUpload
              onChange={(e: any) => {
                fileChange(e);
              }}
              removeFile={() => checkExistFile()}
              selectedValue={fileDataSelected}
              value={fileDataName}
              fileType={t(FORMLABEL.USE_FILE_TYPE)}
            />
          </Col>
        </Row>
      </Modal.Body>
      <Modal.Footer>
        <div className="footer-2-btn">
          <TextButton onClick={props.onHide}> {t("Cancel")}</TextButton>
          <CustomeButton bg="fill" onClick={() => submitHandler()}>
            {props.isedit == "true" ? t("Update") : t("Add")}
          </CustomeButton>
        </div>
      </Modal.Footer>
    </Modal>
  );
}
