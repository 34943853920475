import React, { useEffect } from "react";
import OnlyCk from "../../OnlyCk/OnlyCk";

export default function TableHead({
  orderList,
  data,
  sortByField,
  sortType,
  setSortType,
  setSortByField,
  handleSelectAll,
  isChecked,
}: any) {
  
  const sortrecords = (fieldName: any) => {
    if (orderList?.[fieldName]) {
      if (sortByField == fieldName) {
        let sortTypeNow = sortType == "asc" ? "desc" : "asc";
        setSortType(sortTypeNow);
      } else {
        setSortByField(fieldName);
        setSortType("asc");
      }
    }
  };

  const sortableClass = (fieldName: string): string => {
    if (orderList?.[fieldName]) {
      return "sortable";
    } else {
      return "";
    }
  };

  const sortingTypeClass = (fieldName: string): string => {
    if (sortByField === fieldName) {
      return sortType == "asc" ? "arrow-up" : "arrow-down";
    } else {
      return "";
    }
  };

  const renderHeader = (item: any) => {
    if (item?.isIcon) {
      return (
        <>
          {item?.includePara ? <p className="tm">{item?.icon}</p> : item?.icon}
        </>
      );
    }
    else if (item?.isOnlyTh){
     return null;
    }
    else if (item?.checkbox){
   return (<OnlyCk checked={isChecked} onChange={handleSelectAll} />)
    }
    else {
      return (
        <p
        className={`${item?.className} ${sortableClass(
          item?.key
        )} ${sortingTypeClass(item?.key)}`}
        onClick={() => sortrecords(item?.key)}
      >
        {item?.label}
      </p>
      )
    }
  };

  return (
    <thead>
      <tr>
        {data?.map((item: any, index: number) => {
          return (
            <th
              key={index + "data"}
              className={item?.headClass ? item?.headClass : null}
            >
              {renderHeader(item)}
            </th>
          );
        })}
      </tr>
    </thead>
  );
}
