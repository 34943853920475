import React, { useEffect, useState } from "react";
import { Col, Row } from "react-bootstrap";
import AddPlusIcon from "../../../Assets/icon/AddPlusIcon";
import AmountIcon from "../../../Assets/img/Amount.svg";
import binIcon from "../../../Assets/img/bin.svg";
import GrainIcon from "../../../Assets/img/Grain.svg";
import HayIcon from "../../../Assets/img/Hay.svg";
import pencilIcon from "../../../Assets/img/pencil.svg";
import QtyIcon from "../../../Assets/img/Qty.svg";
import SupplementsIcon from "../../../Assets/img/Supplements.svg";
import CustomeButton from "../../Common/CustomButton/CustomButton";
import AddDietPopup from "../../Popups/AddDietPopup/AddDietPopup";
import { useDispatch } from "react-redux";
import {
  destroyDietAction,
  getDietListAction,
  viewDietAction,
} from "../../../Redux/Horse/Diet/DietAction";
import { encrDecrStr } from "../../../Utility/General";
import { useLocation, useNavigate, useOutletContext, useParams } from "react-router-dom";
import { useSelector } from "react-redux";
import {
  DESTROY_DIET_CLEAR,
  DESTROY_DIET_FAILD,
  DESTROY_DIET_LONG,
  DESTROY_DIET_REQUEST,
  DESTROY_DIET_SUCCESS,
  GET_DIET_LIST_CLEAR,
  GET_DIET_LIST_FAILD,
  GET_DIET_LIST_LONG,
  GET_DIET_LIST_REQUEST,
  GET_DIET_LIST_SUCCESS,
  VIEW_DIET_CLEAR,
  VIEW_DIET_FAILD,
  VIEW_DIET_LONG,
  VIEW_DIET_REQUEST,
  VIEW_DIET_SUCCESS,
} from "../../../Redux/Horse/Diet/DietType";
import { SUCCESS_RESPONSE_CODE } from "../../../Constant/Status";
import { API_STATUS, END_POINTS } from "../../../Constant/Api";
import ConfirmPopup from "../../Popups/ConfirmPopup/ConfirmPopup";
import Loader from "../../Loader/Loader";
import NoDataFound from "../../Common/NoDataFound/NoDataFound";
import { useTranslation } from "react-i18next";
import { useLoading } from "../../../context/LoadingContext";
import { REMINDER_STATUS_CHANGE_SUCCESS, REMINDER_STATUS_CHANGE_CLEAR } from "../../../Redux/General/GeneralType";
import CalculatorPopup from "../../Popups/CalculatorPopup/CalculatorPopup";
import { getListMetaDataApi } from "../../../Redux/NonRedux/general";

export default function DietPage() {
  const { t, i18n } = useTranslation();
  const { setExportParams }: any = useOutletContext();
  const param = useParams();
  const dispatch = useDispatch();
  const [horseId, setHorseId] = React.useState<any>(0);
  const [apiStatus, setApiStatus] = useState(API_STATUS.SUCCESS);
  const [dietList, setDietList] = useState([]);
  const [deletableRecords, setDeletableRecords] = React.useState<any>([]);
  const [confirmPopupShow, setConfirmPopupShow] = React.useState(false);
  const [editData, setEditData] = React.useState<any>({});
  const { loading, setLoading } = useLoading();
  const [isEdit, setIsEdit] = React.useState<boolean>(false);
  const [refreshList, setRefreshList] = React.useState(false);
  const [AddDietModalShow, setAddDietModalShow] = React.useState(false);
  const [summery, setSummery] = useState([]);
  const dietListResponse = useSelector((state: any) => state?.dietListReducer);
  const dietEdit = useSelector((state: any) => state?.viewDietReducer);
  const [calculatorModalShow , setCalCulatorModalShow] = useState(false);
  const [metaData, setMetaData] = useState({});
  const navigate = useNavigate()
  const location:any = useLocation();
  const deleteDietResponse = useSelector(
    (state: any) => state?.destroyDietReducer
  );
  const prefrence = useSelector(
    (state: any) => state?.masterReducer?.data?.component?.preferance
  );

  useEffect(() => {  
    if(location?.state?.reminder_id && location?.state?.path){
      setIsEdit(false);
      setEditData({});
      setAddDietModalShow(true)
    }
  }, [location?.state]);

  const getMetaData = () => {
    setLoading(true);  
    getListMetaDataApi(END_POINTS.metaDiet)
      .then((response: any) => {
        setMetaData(response?.component);
      })
      .catch((error: any) => {})
      .finally(() => {
        setLoading(false);
      });
  };

  const dietCategory = new Map([
    ["hay", { icon: HayIcon, cardColor: "light-marron-2"}],
    ["grain", { icon: GrainIcon, cardColor: "light-green-2" }],
    ["supplements", { icon: SupplementsIcon, cardColor: "light-blue" }],
  ]);

  const listApiCall = () => {
    let id = encrDecrStr(param?.slug, "decr");
    setHorseId(id);
    let params = {
      form_type: "diet",
      horse_id: id,
    };
    setExportParams(params);
    getDietListAction(params);
  };

  useEffect(() => {
    listApiCall();
    getMetaData();
    return () => {
      dispatch({
        type: GET_DIET_LIST_CLEAR,
        data: {
          type: GET_DIET_LIST_CLEAR,
        },
      });
      dispatch({
        type: VIEW_DIET_CLEAR,
        data: {
          type: VIEW_DIET_CLEAR,
        },
      });
      dispatch({
        type: DESTROY_DIET_CLEAR,
        data: {
          type: DESTROY_DIET_CLEAR,
        },
      });
    };
  }, []);

  useEffect(() => {
    if (refreshList) {
      listApiCall();
      setRefreshList(false);
    }
  }, [refreshList]);

  useEffect(() => {
    if (deleteDietResponse?.type === DESTROY_DIET_REQUEST) {
      if (apiStatus !== API_STATUS.LOADING) {
        setApiStatus(API_STATUS.LOADING);
        setLoading(true);
      }
    } else if (
      deleteDietResponse?.data?.statusCode === SUCCESS_RESPONSE_CODE &&
      deleteDietResponse?.type === DESTROY_DIET_SUCCESS
    ) {
      if (apiStatus !== API_STATUS.SUCCESS) {
        setApiStatus(API_STATUS.SUCCESS);
        setLoading(false);
        setRefreshList(true);
        setConfirmPopupShow(false);
      }
    } else if (deleteDietResponse?.type === DESTROY_DIET_LONG) {
      if (apiStatus !== API_STATUS.LONG) {
        setApiStatus(API_STATUS.LONG);
        setLoading(false);
      }
    } else if (deleteDietResponse?.type === DESTROY_DIET_FAILD) {
      if (apiStatus !== API_STATUS.FAILED) {
        setApiStatus(API_STATUS.FAILED);
        setLoading(false);
      }
    }
  }, [deleteDietResponse]);

  const deleteHandler = (id: number) => {
    let params = {
      id: deletableRecords,
    };
    destroyDietAction(params);
  };

  const editHandler = (id: number) => {
    let params = {
      id: id,
    };
    viewDietAction(params);
  };

  const deleteNow = (id: number) => {
    let ids = deletableRecords;
    ids.push(id);
    setDeletableRecords(ids);
    setConfirmPopupShow(true);
  };

  useEffect(() => {
    if (dietEdit?.type === VIEW_DIET_REQUEST) {
      setLoading(true);
    } else if (
      dietEdit?.data?.component?.healthData?.form_type === "diet" &&
      dietEdit.type === VIEW_DIET_SUCCESS
    ) {
      setLoading(false);
      setIsEdit(true);
      setEditData(dietEdit?.data?.component);
      setAddDietModalShow(true);
    } else if (dietEdit?.type === VIEW_DIET_FAILD) {
      setLoading(false);
    } else if (dietEdit?.type === VIEW_DIET_LONG) {
      setLoading(false);
    }
  }, [dietEdit]);

  useEffect(() => {
    if (dietListResponse?.type === GET_DIET_LIST_REQUEST) {
      if (apiStatus !== API_STATUS.LOADING) {
        setApiStatus(API_STATUS.LOADING);
        setLoading(true);
      }
    } else if (
      dietListResponse?.data?.statusCode === SUCCESS_RESPONSE_CODE &&
      dietListResponse?.type === GET_DIET_LIST_SUCCESS
    ) {
      if (apiStatus !== API_STATUS.SUCCESS) {
        setApiStatus(API_STATUS.SUCCESS);
        setLoading(false);
        setSummery(dietListResponse?.data?.component?.counts);
        setDietList(dietListResponse?.data?.component?.listing);
      }
    } else if (dietListResponse?.type === GET_DIET_LIST_LONG) {
      if (apiStatus !== API_STATUS.LONG) {
        setApiStatus(API_STATUS.LONG);
        setLoading(false);
      }
    } else if (dietListResponse?.type === GET_DIET_LIST_FAILD) {
      if (apiStatus !== API_STATUS.FAILED) {
        setApiStatus(API_STATUS.FAILED);
        setLoading(false);
      }
    }
  }, [dietListResponse]);

  const handleRedirectBack = () => {
    if(location?.state?.reminder_id && location?.state?.path){
      navigate(location?.state?.path ,{ 
        state: {reminder_id:"" }
       });
    }
  }

  return (
    <>
      
      <div className="diet-horse-page">
        <div className="summary-head">
          <Row>
            {summery &&
              summery.length > 0 &&
              summery.map((item: any , index) => (
                <Col sm="12" md="6" lg="4" key={index + "card-colour"}>
                  <div className="summary-box">
                    <p className="tl">{item?.category_value}</p>
                    <div className="right-details">
                      <div className="icon-bold-small-text">
                        <img src={QtyIcon} alt="" />
                        <div className="bold-small-text">
                          <p className="tm">
                            {item?.totalKg} {prefrence?.weightUnit_value}{" "}
                          </p>
                          <span className="bm blur-color">{t("/ Month")}</span>
                        </div>
                      </div>
                      <div className="icon-bold-small-text">
                        <img src={AmountIcon} alt="" />
                        <div className="bold-small-text">
                          <p className="tm">
                            {item?.amount_per_unit} {prefrence?.currency_value}
                          </p>
                          <span className="bm blur-color">{t("/ Month")}</span>
                        </div>
                      </div>
                    </div>
                  </div>
                </Col>
              ))}
          </Row>
        </div>

        <div className="data-tbl-wrapper">
          <div className="data-tbl-header">
            <p className="tl">{t("Diet")}</p>
            <div className="btn-wrapper single-btn">
              <CustomeButton
                bg="fill"
                onClick={() => {
                  setEditData({});
                  setIsEdit(false);
                  setAddDietModalShow(true);
                }}
              >
                <AddPlusIcon />
                {t("Add")}
              </CustomeButton>
            </div>
          </div>
          <div className="data-tbl-double-row">
            <table>
              <thead>
                <tr>
                  <th>
                    <p className="tm">{t("Time")}</p>
                  </th>
                  <th>
                    <p className="tm icon-text">{t("Category")}</p>
                  </th>
                  <th>
                    <p className="tm">{t("Product")}</p>
                  </th>
                  <th>
                    <p className="tm">{t("Qty")}</p>
                  </th>
                  <th>
                    <p className="tm">{t("Unit")}</p>
                  </th>
                  <th className="text-right">
                    <p className="tm">{t("Action")}</p>
                  </th>
                </tr>
              </thead>
              <tbody>
                {dietList.map((items: any, index: number) => {
                  return (
                    <tr key={ items?.id + index + "diet"}>
                      <td className="v-top">
                        <p className="bl">{items.time}</p>
                      </td>
                      <td>
                        {items.dietDetails
                          .map((item: any) => {
                            return {
                              value: item.category_value,
                              slug: item.category_slug,
                            };
                          })
                          .map(
                            (item2: any, index2: number) =>
                              item2 && (
                                <p
                                  className="bl icon-text p-mb-16"
                                  key={ items?.id + index2 + "diet-icon-text"}
                                >
                                  <img
                                    src={dietCategory.get(item2.slug)?.icon}
                                    alt=""
                                  />{" "}
                                  {item2.value}
                                </p>
                              )
                          )}
                      </td>
                      <td>
                        {items.dietDetails
                          .map((item: any) => item.product)
                          .map((value: any, index: number) => (
                            <p className="bl p-mb-16" key={ items?.id + index + "product"}>
                              {value}
                            </p>
                          ))}
                      </td>
                      <td>
                        {items.dietDetails
                          .map((item: any) => item.qty)
                          .map((value: any, index: number) => (
                            <p className="bl p-mb-16" key={items?.id + index + "qty"}>
                              {value}
                            </p>
                          ))}
                      </td>
                      <td>
                        {items.dietDetails
                          .map((item: any) => item?.unit_value)
                          .map((value: any, index: number) => (
                            <p className="bl p-mb-16" key={items?.id + index + "unit"}>
                              {value}
                            </p>
                          ))}
                      </td>
                      <td>
                        <div className="icon-wrapper">
                          <a>
                            <img
                              src={pencilIcon}
                              alt=""
                              onClick={() => editHandler(items.id)}
                            />
                          </a>
                          <a>
                            <img
                              src={binIcon}
                              onClick={() => deleteNow(items.id)}
                              alt=""
                            />
                          </a>
                        </div>
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </div>
          {!loading && (dietList.length > 0 ? "" : <NoDataFound />)}
        </div>
      </div>

      <AddDietPopup
        show={AddDietModalShow}
        onHide={() => {
          setAddDietModalShow(false);
          handleRedirectBack();
        }}
        metaData={metaData}
        calculatorClick={() => {
          setCalCulatorModalShow(true);
        }}
        horseid={horseId}
        isedit={isEdit ? 1 : 0}
        ismultiple={0}
        dietdata={editData}
        setrefreshlist={() => {
          setRefreshList(true);
        }}
      />
      
      <CalculatorPopup
        show={calculatorModalShow}
        onHide={() => {
          setCalCulatorModalShow(false);
        }}
      />

      <ConfirmPopup
        heading={t("Delete")}
        message={t("Are you sure to delete this record?")}
        show={confirmPopupShow}
        ismultiple={0}
        onHide={() => {
          setConfirmPopupShow(false);
          setDeletableRecords([]);
        }}
        confirm={deleteHandler}
      />
    </>
  );
}
