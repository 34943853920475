import { Switch } from "antd";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import pencilIcon from "../../../Assets/img/pencil.svg";
import validate from "../../../Utility/Validation";
import InputGroups from "../../Common/InputGroups/InputGroups";
import PlusIcon from "../../../Assets/img/plus-circle.svg";
import rightIcon from "../../../Assets/img/Right.svg";
import crossIcon from "../../../Assets/img/cross.svg";
import { t } from "i18next";
export default function SingleBoardingPlan({
  item,
  index,
  handleEdit,
  editId,
  handleEditSubmit
}: any) {
  const EDIT_BOARDING_DATA = {
    type: {
      value: "",
      validation: ["required"],
      errors: [t("Boarding type is required.")],
    },
    amount: {
      value: "",
      validation: ["required"],
      errors: [t("Amount is required.")],
    },
    id: {
      value: "",
    },
    status: {
      value: "",
      validation: ["required"],
      errors: [t("status is required.")],
    },
    is_editable:{
      value: "1",
    }
  };

  const [editObj, setEditObj] = useState<any>(EDIT_BOARDING_DATA);
  const [isFormValidated, setIsFormValidated] = useState(false);
  const [planDataError, setPlanDataError] = useState<any>({});
  const prefrence = useSelector(
    (state: any) => state?.masterReducer?.data?.component?.preferance
  );

  const setData = (field: string, value: any) => {
    const ob: any = Object.assign({}, editObj);
    ob[field].value = value;
    setEditObj({ ...ob });
  };

  useEffect(() => {
    setPlanDataError({ ...validate(editObj)?.errors });
  }, [editObj])
  

  const handleSubmit = () => {
   
    if (validate(editObj).isValidated === true) {
      let params: any = {};
      Object.keys(editObj).forEach((element: any) => {
        params[element] = editObj[element]["value"];
      });
      handleEditSubmit(params);
    } else {
      setIsFormValidated(true);
      setPlanDataError({ ...validate(editObj)?.errors });
    }
  };

  useEffect(() => {
    if (item?.id == editId) {
      const ob: any = Object.assign({}, editObj);
      Object.keys(editObj).forEach((element: any) => {
        ob[element].value = item[element];
      });
      setEditObj({ ...ob});
    }
  }, [editId]);


  const renderEditableField = (element: any, editableAll: boolean) => {
    if (editableAll) {
      return (
        <>
          <td>
            <InputGroups
              type="text"
              autoComplete="off"
              value={editObj?.type?.value}
              onChange={(e: any) => {
                setData("type", e.target.value)
              }}
              error={
                isFormValidated && planDataError["type"]
                  ? planDataError["type"]
                  : null
              }
            />
          </td>
          <td>
            <Switch
              size="small"
              // defaultChecked={editObj.status.value == 1 ? true : false}
              checked={editObj.status.value == 1 ? true : false}
              onChange={(checked: boolean) => setData("status", checked)}
            />
          </td>
          <td>
            <InputGroups
              type="number"
              autoComplete="off"
              value={editObj?.amount?.value}
              onChange={(e: any) => {
                setData("amount", e.target.value)
              }}
              error={
                isFormValidated && planDataError["amount"]
                  ? planDataError["amount"]
                  : null
              }
            />
          </td>
        </>
      )
    }
    else {
      return (
        <>
          <td>
            <p className="bl">{element.type}</p>
          </td>
          <td>
            <label
              className={`bl status-badge ${element.status ? "active" : "inactive"
                }  `}
            >
              {element.status ? "active" : "inactive"}
            </label>
          </td>
          <td>
            <InputGroups
              type="number"
              autoComplete="off"
              value={editObj?.amount?.value}
              onChange={(e: any) => {
                setData("amount", e.target.value)
              }}
              error={
                isFormValidated && planDataError["amount"]
                  ? planDataError["amount"]
                  : null
              }
            />
          </td>
        </>
      )
    }

  }

  return (
    <tr>
      {item?.id != editId ? (
        <>
          <td>
            <p className="bl">{index + 1}</p>
          </td>
          <td>
            <p className="bl">{item.type}</p>
          </td>
          <td>
            <label
              className={`bl status-badge ${item.status ? "active" : "inactive"
                }  `}
            >
              {item.status ? "active" : "inactive"}
            </label>
          </td>
          <td>
            <p className="bl text-right">
              {item.amount} {prefrence?.currency_value}
            </p>
          </td>
          <td>
            <div className="icon-wrapper">
              <a>
                <img
                  src={pencilIcon}
                  onClick={() => handleEdit(item?.id)}
                  alt=""
                />
              </a>
            </div>
          </td>
        </>
      ) : (
        <>
          <td>
            <p className="bl">{index + 1}</p>
          </td>
          {renderEditableField(item , item?.is_editable == "1" ? false : true)}
          <td>
            <div className="icon-wrapper">
              <a>
                <img src={rightIcon} onClick={handleSubmit} alt="" />
              </a>
              <a>
                <img src={crossIcon} onClick={() => handleEdit()} alt="" />
              </a>
            </div>
          </td>
        </>
      )}
    </tr>
  );
}
