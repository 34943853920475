import { t } from "i18next";
import moment from "moment";
import React, { useContext, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import AddPlusIcon from "../../Assets/icon/AddPlusIcon";
import binIcon from "../../Assets/img/bin.svg";
import ExportIcon from "../../Assets/img/export.svg";
import FemaleIcon from "../../Assets/img/Female.svg";
import MaleIcon from "../../Assets/img/Male.svg";
import HandIcon from "../../Assets/img/hand.svg";
import pencilIcon from "../../Assets/img/pencil.svg";
import TrainingIcon from "../../Assets/img/Training-primary.svg";
import TrainingCalIcon from "../../Assets/img/TrainingSchedule.svg";
import { API_STATUS, END_POINTS } from "../../Constant/Api";
import {
  DATATABLE,
  DATE_FORMATE,
  MALE,
  PERMISSIONS,
  SIDEBAR,
} from "../../Constant/General";
import { TRAINING_CALENDER } from "../../Constant/Route";
import { SUCCESS_RESPONSE_CODE } from "../../Constant/Status";
import { useLoading } from "../../context/LoadingContext";
import { SidebarContext } from "../../context/SideBarContext";
import {
  destroyTraining,
  endTraining,
  getSingleTraining,
  getTrainingList,
} from "../../Redux/NonRedux/training";
import CustomeButton from "../Common/CustomButton/CustomButton";
import NoDataFound from "../Common/NoDataFound/NoDataFound";
import SearchBox from "../Common/SearchBox/SearchBox";
import Pagination from "../Layouts/Pagination/Pagination";
import AddTrainingPopup from "../Popups/AddTrainingPopup/AddTrainingPopup";
import ConfirmPopup from "../Popups/ConfirmPopup/ConfirmPopup";
import EditTrainingPopup from "../Popups/EditTrainingPopup/EditTrainingPopup";
import TableHead from "../Common/DataTable/TableHead/TableHead";
import { exportDocument } from "../../Redux/globallActions";
import {  checkRoutePermission, getDefaultSorting } from "../../Utility/General";
import Filter from "../Filter/Filter";
import { upgradePopupAction } from "../../Redux/General/GeneralAction";
import { useDispatch, useSelector } from "react-redux";
import { getListMetaDataApi } from "../../Redux/NonRedux/general";
import PregnantIcon from "../../Assets/img/Pregnant.svg";

export default function Training() {
  const defaultSorting : any = getDefaultSorting(DATATABLE.TRAINING);
  const tableHead = [
    {
      isOnlyTh: true,
      headClass: "text-right",
    },
    {
      label: t("Horse"),
      className: "tm icon-text",
      key: "horse",
    },
    {
      label: t("Start Date"),
      className: "tm",
      key: "start_date",
    },
    {
      label: t("Training Type"),
      className: "tm ",
      key: "training_type",
    },
    {
      label: t("Training Days"),
      className: "tm ",
      key: "days",
    },
    {
      label: t("Trainer"),
      className: "tm ",
      key: "trainer",
    },
    {
      label: t("Action"),
      className: "tm",
      key: "",
      headClass: "text-right",
    },
  ];

  const [AddTrainingPopupModalShow, setAddTrainingPopupModalShow] =
    useState(false);
  const [editTrainingPopupModalShow, setEditTrainingPopupModalShow] =
    useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [currentPageSize, setCurrentPageSize] = useState(10);
  const [apiStatus, setApiStatus] = useState(API_STATUS.SUCCESS);
  const [editData, setEditData] = useState<any>({});
  const [deletableRecords, setDeletableRecords] = useState<any>([]);
  const [confirmPopupShow, setConfirmPopupShow] = useState(false);
  const [isEdit, setIsEdit] = useState<boolean>(false);
  const [refreshList, setRefreshList] = useState(false);
  const { setLoading } = useLoading();
  const [trainingList, setTrainingList] = useState([]);
  const [recordsTotal, setRecordsTotal] = React.useState(1);
  const [searchText, setSearchText] = useState();
  const [checkedRec, setCheckedRec] = useState<number[]>([]);
  const { setSidebar }: any = useContext(SidebarContext);
  const [isDeleteConfirm, setIsDeleteConfirm] = useState(true);
  const [horseIds, setHorseIds] = useState<number[]>([]);
  const [sortByField, setSortByField] = useState(defaultSorting?.key);
  const [sortType, setSortType] = useState(defaultSorting?.type);
  const [orderList, setOrderList] = useState<any>([]);
  const [exportParams, setExportParams] = useState<any>({});
  const [filterList, setFilterList] = useState<any>([]);
  const [filteredValue, setFilteredValue] = useState<any>({});
  const [metaData, setMetaData] = useState({});
  const dispatch = useDispatch();
   const modules = useSelector(
    (state: any) => state?.masterReducer?.data?.component?.modules
  );

  const listApiCall = () => {
    let params: any = {
      page: currentPage,
      limit: currentPageSize,
      search: searchText,
    };
    if (sortByField && sortType) {
      params["sort"] = {
        column: orderList[sortByField] ? orderList[sortByField] : defaultSorting?.value ,
        type: sortType,
      };
    }
    if (filteredValue && Object.keys(filteredValue)?.length) {
      params["filter"] = filteredValue;
    }
    setExportParams(params);
    setLoading(true);
    getTrainingList(params)
      .then((response: any) => {
        if (response.statusCode === SUCCESS_RESPONSE_CODE) {
          setTrainingList(response?.component?.listing);
          setRecordsTotal(response?.component?.recordsFiltered);
          setOrderList(response?.component?.orderlist);
          setFilterList(response?.component?.filterlist);
        }
        setLoading(false);
      })
      .catch((error) => {
        setLoading(false);
      });
  };

  const getMetaData = () => {
    setLoading(true);  
    getListMetaDataApi(END_POINTS.metaTraining)
      .then((response: any) => {
        setMetaData(response?.component);
      })
      .catch((error: any) => {})
      .finally(() => {
        setLoading(false);
      });
  };
  

  useEffect(() => {
    getMetaData();
    setSidebar(SIDEBAR.TRAINING);
  }, []);

  useEffect(() => {
    listApiCall();
  }, [currentPage, currentPageSize, sortByField, sortType, filteredValue]);

  useEffect(() => {
    if (searchText != null) {
      const searchTimeOut = setTimeout(() => {
        if (currentPage === 1) {
          listApiCall();
        } else {
          setCurrentPage(1);
        }
      }, 500);
      return () => clearTimeout(searchTimeOut);
    }
  }, [searchText]);

  useEffect(() => {
    if (refreshList) {
      listApiCall();
      setRefreshList(false);
    }
  }, [refreshList]);

  const editHandler = (id: number) => {
    let params = {
      id: id,
    };
    setApiStatus(API_STATUS.LOADING);
    setLoading(true);
    getSingleTraining(params)
      .then((response: any) => {
        if (response?.statusCode === SUCCESS_RESPONSE_CODE) {
          setApiStatus(API_STATUS.SUCCESS);
          setLoading(false);
          setIsEdit(true);
          setEditData(response?.component);
          setEditTrainingPopupModalShow(true);
        } else {
          setApiStatus(API_STATUS.FAILED);
          setLoading(false);
        }
      })
      .catch((error) => {
        setApiStatus(API_STATUS.FAILED);
        setLoading(false);
      });
  };

  const deleteHandler = (id: number) => {
    let params = {
      id: deletableRecords,
    };

    setApiStatus(API_STATUS.LOADING);
    setLoading(true);
    destroyTraining(params)
      .then((response: any) => {
        if (response.statusCode === SUCCESS_RESPONSE_CODE) {
          setApiStatus(API_STATUS.SUCCESS);
          setLoading(false);
          setRefreshList(true);
          setConfirmPopupShow(false);
        } else {
          setApiStatus(API_STATUS.FAILED);
          setLoading(false);
        }
      })
      .catch((error) => {
        setApiStatus(API_STATUS.FAILED);
        setLoading(false);
      });
  };

  const confirmNow = (id: number) => {
    let ids = deletableRecords;
    ids.push(id);
    setDeletableRecords(ids);
    setConfirmPopupShow(true);
  };

  useEffect(() => {
    let records = trainingList?.filter((item: any) =>
      checkedRec.includes(item?.id)
    );
    let horse_ids: any = records.map((list: any) => list.horse_id);
    setHorseIds(
      horse_ids.filter((v: any, i: any, a: any) => a.indexOf(v) === i)
    );
  }, [checkedRec]);

  const trainingEnd = async () => {
    try {
      const data = await endTraining({ id: deletableRecords[0] });
      setConfirmPopupShow(false);
      listApiCall();
    } catch (error) {}
  };

  const showCalendar = (date: string) => {};

  const handleExport = () => {
    if(!checkRoutePermission(PERMISSIONS?.EXPORT_FEATURE , modules)){
      upgradePopupAction({show:true});
      return;
    }
    setLoading(true);
    exportDocument(END_POINTS.trainingExport, exportParams, "training")
      .then(() => {})
      .finally(() => {
        setLoading(false);
      });
  };

  const getIconbyType = (type: any) => {
    if (type === "male") {
      return MaleIcon;
    } else if (type === "female") {
      return FemaleIcon;
    } else if (type === "pregnant") {
      return PregnantIcon;
    }
  };


  return (
    <>
      <div className="training-page">
        {/* Page Title Start */}
        <div className="page-title-wrapper">
          <div className="title-first-menu">
            <div className="title-icon-text">
              <img src={TrainingIcon} alt="" />
              <h5>{t("Training")}</h5>
            </div>
            <div className="title-right-icons">
              <Link className="unset-links" to={TRAINING_CALENDER}>
                <img src={TrainingCalIcon} alt="" />
              </Link>
              
                <a className="unset-links" onClick={handleExport}>
                  <img src={ExportIcon} alt="" />
                </a>
             
            </div>
          </div>
        </div>
        {/* Page Title End */}

        <div className="page-content">
          <div className="data-wrapper">
            <div className="data-header">
              <SearchBox
               value={searchText}
                placeholder={t("Search Horse...")}
                onChange={(e: any) => setSearchText(e.target.value)}
              />

              <div className="right-section">
                <div className="btn-wrapper f-fb">
                  {/* <CustomeButton>
                    <Filtericon />
                    <p>Filter</p>
                  </CustomeButton> */}
                  <Filter
                    filterList={filterList}
                    setFilteredValue={setFilteredValue}
                    setCurrentPage={setCurrentPage}
                  />
                  <CustomeButton
                    bg="fill"
                    onClick={() => setAddTrainingPopupModalShow(true)}
                  >
                    <AddPlusIcon />
                    {t("Add")}
                  </CustomeButton>
                </div>
              </div>
            </div>
            <div className="data-body">
              <div className="img-responsive-table">
                <table>
                  <TableHead
                    data={tableHead}
                    orderList={orderList}
                    sortByField={sortByField}
                    sortType={sortType}
                    setSortType={setSortType}
                    setSortByField={setSortByField}
                  />
                  <tbody>
                    {trainingList.map((items: any, index: number) => {
                      return (
                        <tr key={items.id}>
                          <td className="first-img-wrapper">
                            <img
                              src={items.profile}
                              alt=""
                              className="horse-img"
                            />
                          </td>
                          <td>
                            <div className="icon-text">
                              <img
                                 src={getIconbyType(items?.type_slug)}
                                alt=""
                              />
                              {/* <Link to={HORSE} className="a-bl"> */}
                              {items.horse_name}
                              {/* </Link> */}
                            </div>
                          </td>
                          <td>
                            <p className="bl">
                              {moment(items.startDate).format(DATE_FORMATE)}
                            </p>
                          </td>
                          <td>
                            <div className="multi-icons">
                              <p className="bl"> {items.training_type_value}</p>
                              {/* {items?.trainingType.map(
                                (value: any, index: number) => (
                                  <img
                                    src={value}
                                    alt=""
                                    key={index + "multi-img"}
                                  />
                                )
                              )} */}
                            </div>
                          </td>
                          <td>
                            <div className="week-wrapper">
                              {items.days.map((value: any, index1: number) => (
                                <p
                                  className={`bl ${
                                    value.match == "0" ? "blur-low" : ""
                                  }`}
                                  key={index + index1 + "week-days"}
                                >
                                  {value.name}
                                </p>
                              ))}
                            </div>
                          </td>
                          <td>
                            <p className="bl">{items.trainer}</p>
                          </td>
                          <td>
                            <div className="icon-wrapper">
                              {moment().isBetween(
                                moment(items?.startDate),
                                moment(items?.endDate)
                              ) && (
                                <a>
                                  <img
                                    src={HandIcon}
                                    alt=""
                                    onClick={() => {
                                      setIsDeleteConfirm(false);
                                      confirmNow(items?.id);
                                    }}
                                  />
                                </a>
                              )}
                              {/* <Link
                                to={TRAINING_CALENDER}
                                state={{ date: items?.startDate }}
                              >
                                <img
                                  src={CalendarIcon}
                                  alt=""
                                  onClick={() => showCalendar(items?.startDate)}
                                />
                              </Link> */}

                              <a>
                                <img
                                  src={pencilIcon}
                                  alt=""
                                  onClick={() => editHandler(items?.id)}
                                />
                              </a>
                              <a>
                                <img
                                  src={binIcon}
                                  onClick={() => {
                                    setIsDeleteConfirm(true);
                                    confirmNow(items?.id);
                                  }}
                                  alt=""
                                />
                              </a>
                            </div>
                          </td>
                        </tr>
                      );
                    })}
                  </tbody>
                </table>
              </div>
              {trainingList?.length ? (
                <Pagination
                  results={recordsTotal}
                  currentPage={currentPage}
                  setCurrentPage={setCurrentPage}
                  currentLength={currentPageSize}
                  setCurrentPageSize={setCurrentPageSize}
                />
              ) : (
                <NoDataFound />
              )}
            </div>
          </div>
        </div>
      </div>

      <AddTrainingPopup
        show={AddTrainingPopupModalShow}
        onHide={() => setAddTrainingPopupModalShow(false)}
        setrefreshlist={() => setRefreshList(true)}
        horseIds={horseIds}
        metaData={metaData}
      />

      <EditTrainingPopup
        show={editTrainingPopupModalShow}
        onHide={() => {
          setEditTrainingPopupModalShow(false);
        }}
        isedit={isEdit ? 1 : 0}
        setrefreshlist={() => setRefreshList(true)}
        editdata={editData}
        metaData={metaData}
      />

      <ConfirmPopup
        heading={isDeleteConfirm ? t("Delete") : t("End Training")}
        message={
          isDeleteConfirm
            ? t("Are you sure to delete this record?")
            : t("Are you sure you want to end training")
        }
        show={confirmPopupShow}
        onHide={() => {
          setConfirmPopupShow(false);
          setDeletableRecords([]);
        }}
        confirm={isDeleteConfirm ? deleteHandler : trainingEnd}
      />
    </>
  );
}
