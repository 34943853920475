import axios from "axios";
import { USER } from "../Constant/LocalStorage";
import { getSessionItemDecrypt, getUrl } from "../Utility/General";
// For GET requests

export function getTokan() {
  // return store.getState()?.master?.data?.authToken;
  let userData = getSessionItemDecrypt(USER) ;
  userData = userData ? userData : "";
  let token = "";
  let config = {};
  if (userData?.component?.token) {
    token = userData.component.token;
    return "Bearer " + token;
  }
  return "";
}

export function getDefaultParameters() {
  // return store.getState()?.defaultParameter;
}

axios.interceptors.request.use(
  (req: any) => {
    // Add configurations here

    req.headers = { Authorization: getTokan() , "Content-Type": "application/json", "Accept": "application/json" };

    if (req.method !== "get") {
      req.data["defaultParameters"] = getDefaultParameters();
    } else if (req.method === "get") {
      if (!req.url.includes(".json")) {
        req.url = getUrl(req.url, getDefaultParameters());
      }
    }

    return req;
  },
  (err) => {
    // Add configurations here
    return Promise.reject(err);
  }
);

// For POST requests
axios.interceptors.response.use(
  (res) => {
   
    // Add configurations here
    return res;
  },
  (err) => {
    if(err?.response?.status == "401"){
       localStorage.removeItem(USER)
       window.location.replace("https://aljuman-backend.magnetoinfotech.com/login");
    }
    // Add configurations here
    return Promise.reject(err);
  }
);
