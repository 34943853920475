import { t } from "i18next";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import Filtericon from "../../../Assets/icon/Filtericon";
import binIcon from "../../../Assets/img/bin.svg";
import CheckCircleIcon from "../../../Assets/img/Common-icon/CheckCircleIcon";
import PencilIcon from "../../../Assets/img/Common-icon/PencilIcon";
import pencilIcon from "../../../Assets/img/pencil.svg";
import { DATATABLE, REMINDER_REDIRECT } from "../../../Constant/General";
import { useLoading } from "../../../context/LoadingContext";
import { reminderStatusAction } from "../../../Redux/General/GeneralAction";
import { REMINDER_STATUS_CHANGE_SUCCESS } from "../../../Redux/General/GeneralType";
import {
  changeReminderStatus,
  deleteReminder,
  getreminderList,
} from "../../../Redux/NonRedux/reminderApi";
import { encrDecrStr, getDefaultSorting } from "../../../Utility/General";
import CustomeButton from "../../Common/CustomButton/CustomButton";
import TableHead from "../../Common/DataTable/TableHead/TableHead";
import NoDataFound from "../../Common/NoDataFound/NoDataFound";
import SquareButton from "../../Common/SquareButton/SquareButton";
import Filter from "../../Filter/Filter";
import Pagination from "../../Layouts/Pagination/Pagination";
import ConfirmPopup from "../../Popups/ConfirmPopup/ConfirmPopup";
import ConfirmPopupWithCheckBox from "../../Popups/ConfirmPopupWithCheckBox/ConfirmPopupWithCheckBox";
import EditPreferencePopup from "../../Popups/EditPreferencePopup/EditPreferencePopup";

export default function FarrierReminderPage() {
  const defaultSorting : any = getDefaultSorting(DATATABLE.REMINDER);
  const [EditPreferenceShow, setEditPreferenceShow] = React.useState(false);
  const [listData, setListData] = useState<any>([]);
  const [refreshList, setRefreshList] = useState(false);
  const [recordsTotal, setRecordsTotal] = React.useState(1);
  const [currentPage, setCurrentPage] = React.useState(1);
  const [currentPageSize, setCurrentPageSize] = React.useState(10);
  const [deleteId, setDeleteId] = useState<number>();
  const [confirmPopupShow, setConfirmPopupShow] = useState(false);
  const { setLoading } = useLoading();
  const [reminderStatus, setReminderStatus] = useState<any>({});
  const [reminderPopupShow, setReminderPopupShow] = useState(false);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [isReminderAdded, setIsreminderAdded] = useState(true);
  const [sortByField, setSortByField] = useState(defaultSorting?.key);
  const [sortType, setSortType] = useState(defaultSorting?.type);
  const [orderList, setOrderList] = useState<any>([]);
  const [filterList,setFilterList] = useState<any>([]);
  const [filteredValue , setFilteredValue] = useState<any>({});
  const [isRedirect , setIsRedirect] = useState(true);
  const location:any = useLocation();
  const tableHead = [
    {
      label: t("Due Date"),
      className: "tm",
      key: "date",
    },
    {
      label: t("Type"),
      className: "tm",
      key: "type",
    },
    {
      label: t("Horse"),
      className: "tm",
      key: "name",
    },
    {
      label: t("Action"),
      className: "tm",
      key: "",
      headClass: "text-right",
    },
  ];

  const changeStatus = (id: number, completion: string) => {
    setLoading(true);
    changeReminderStatus({
      id,
      completion,
    })
      .then(() => {
        listApiCall();
        setReminderPopupShow(false);
      })
      .finally(() => {
        setLoading(false);
      });
    navigate(location.pathname, { replace: true });
  };

  useEffect(() => {
    if (location?.state?.reminder_id && location?.state?.status === "1") {
      const reminder_id: number = location?.state?.reminder_id;
      const status: string = location?.state?.status;
      changeStatus(reminder_id, status);
    }
  }, [location?.state]);

  const listApiCall = async () => {
    try {
      setLoading(true);
      let params: any = {
        category: "farrier",
        page: currentPage,
        limit: currentPageSize,
      };
      if (sortByField && sortType) {
        params["sort"] = {
          column: orderList[sortByField] ? orderList[sortByField] : defaultSorting?.value ,
          type: sortType,
        };
      }
      if (filteredValue && Object.keys(filteredValue)?.length) {
        params["filter"] = filteredValue;
      }
      const response: any = await getreminderList(params);
      setListData(response?.component?.listing);
      setRecordsTotal(response?.component?.recordsFiltered);
      setOrderList(response?.component?.orderlist);
      setFilterList(response?.component?.filterlist);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (refreshList) {
      listApiCall();
      setRefreshList(false);
    }
  }, [refreshList]);

  useEffect(() => {
    listApiCall();
  }, [currentPage, currentPageSize, sortByField, sortType , filteredValue]);

  const deleteHandler = () => {
    setLoading(true);
    deleteReminder({ id: deleteId })
      .then(() => {
        listApiCall();
        setConfirmPopupShow(false);
        setDeleteId(0);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const reminderStatusApiCall = () => {
    if (reminderStatus?.redirect_status && !isReminderAdded) {
      // dispatch(reminderStatusAction(reminderStatus));
      navigate(
        REMINDER_REDIRECT?.[reminderStatus?.rediract_to] +
          "/" +
          encrDecrStr(reminderStatus?.horse_id, "encr"),
        {
          state: {
            reminder_id: reminderStatus?.id,
            path: window.location.pathname,
          },
        }
      );
    } else {
      changeStatus(reminderStatus?.id, reminderStatus?.completion);
    }
  };

  const deleteNow = (id: number) => {
    setDeleteId(id);
    setConfirmPopupShow(true);
  };


  return (
    <>
      <div className="farrier-reminder-page">
        <div className="data-tbl-wrapper">
          <div className="data-tbl-header">
            <p className="tl">{t("Farrier")}</p>
            <div className="btn-wrapper f-s">
              {/* <CustomeButton>
                <Filtericon />
                <p>{t("Filter")}</p>
              </CustomeButton> */}
              <Filter filterList={filterList} setCurrentPage={setCurrentPage}  setFilteredValue={setFilteredValue}/>
              <SquareButton onClick={() => setEditPreferenceShow(true)}>
                <PencilIcon />
              </SquareButton>
            </div>
          </div>
          <div className="data-tbl">
            <table>
              <TableHead
                data={tableHead}
                orderList={orderList}
                sortByField={sortByField}
                sortType={sortType}
                setSortType={setSortType}
                setSortByField={setSortByField}
              />
              <tbody>
                {listData?.map((items: any, index: number) => (
                  <tr key={index + "preg-data"}>
                    <td>
                      <p className="bl">{items.date}</p>
                    </td>
                    <td>
                      <p className="bl">{items.type}</p>
                    </td>
                    <td>
                      <p className="bl">{items.horse}</p>
                    </td>
                    <td>
                      <div className="icon-wrapper">
                        <a
                          className={
                            items?.completion ? "green-icon" : "grey-icon"
                          }
                          onClick={() => {
                            setReminderStatus({
                              id: items?.id,
                              completion: items?.completion,
                              redirect_status: items?.redirect_status,
                              rediract_to: items?.rediract_to,
                              horse_id: items?.horse_id,
                            });
                            setIsRedirect(items?.redirect_status)
                            setReminderPopupShow(true);
                          }}
                        >
                          <CheckCircleIcon />
                        </a>

                        {/* <a>
                          <img src={pencilIcon} alt="" />
                        </a> */}
                        {
                          (moment(items?.date , "DD/MM/YYYY").isSameOrBefore(moment()))  ? <a>
                          <img
                            src={binIcon}
                            onClick={() => deleteNow(items?.id)}
                            alt=""
                          />
                        </a> : null
                        }
                      </div>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
          {listData?.length ? (
            <Pagination
              results={recordsTotal}
              currentPage={currentPage}
              setCurrentPage={setCurrentPage}
              currentLength={currentPageSize}
              setCurrentPageSize={setCurrentPageSize}
            />
          ) : (
            <NoDataFound />
          )}
        </div>
      </div>

      <ConfirmPopup
        heading={t("Delete")}
        message={t("Are you sure to delete this record?")}
        show={confirmPopupShow}
        onHide={() => {
          setConfirmPopupShow(false);
          setDeleteId(0);
        }}
        confirm={deleteHandler}
      />

      <ConfirmPopupWithCheckBox
       isRedirect ={isRedirect}
        heading={t("Status Change")}
        message={t("Are you sure to Change status of Reminder")}
        show={reminderPopupShow}
        isReminderAdded={isReminderAdded}
        setIsreminderAdded={setIsreminderAdded}
        onHide={() => {
          setReminderPopupShow(false);
          setReminderStatus({});
        }}
        confirm={reminderStatusApiCall}
      />

      <EditPreferencePopup
        show={EditPreferenceShow}
        reminderType="farrier"
        onHide={() => setEditPreferenceShow(false)}
      />
    </>
  );
}
