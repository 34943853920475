import { t } from "i18next";
import React, { useState } from "react";
import { useSelector } from "react-redux";
import { useOutletContext } from "react-router-dom";
import binIcon from "../../../Assets/img/bin.svg";
import pencilIcon from "../../../Assets/img/pencil.svg";
import { useLoading } from "../../../context/LoadingContext";
import {
  destroyBreedingFlush,
  viewBreedingFlush,
} from "../../../Redux/NonRedux/breeding";
import { getValFromKey } from "../../../Utility/General";
import ConfirmPopup from "../../Popups/ConfirmPopup/ConfirmPopup";

export default function FlushList({ listData, setRefreshList , metaData , editHandler , serviceId}: any) {

  const [deletableRecords, setDeletableRecords] = useState<any>([]);
  const [confirmPopupShow, setConfirmPopupShow] = useState(false);
  const { setLoading } = useLoading();
  const prefrence = useSelector(
    (state: any) => state?.masterReducer?.data?.component?.preferance
  );


  const deleteHandler = () => {
    setLoading(true);
    let params = {
      id: deletableRecords,
      service_id: metaData?.activeService,
    };
    destroyBreedingFlush(params)
      .then((res: any) => {
        setConfirmPopupShow(false);
        setDeletableRecords([]);
        setRefreshList();
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const deleteNow = (id: number) => {
    let ids = deletableRecords;
    ids.push(id);
    setDeletableRecords(ids);
    setConfirmPopupShow(true);
  };

  return (
    <React.Fragment>
      <div className="data-tbl-wrapper">
        <div className="data-tbl-header">
          <p className="tl">{t("Flush Details")}</p>
        </div>
        <div className="data-tbl">
          <table>
            <thead>
              <tr>
                <th>
                  <p className="tm">{t("Date")}</p>
                </th>

                <th>
                  <p className="tm">{t("Vet/Clinic")}</p>
                </th>
                <th>
                  <p className="tm">{t("Flush Status")}</p>
                </th>
                <th>
                  <p className="tm">{t("Amount")}</p>
                </th>
                <th className="text-right">
                  <p className="tm">{t("Action")}</p>
                </th>
              </tr>
            </thead>
            <tbody>
              {listData.map((items: any, index: number) => (
                <tr key={index + "breeding-conteol-mare"}>
                  <td>
                    <p className="bl">{items?.dates}</p>
                  </td>

                  <td>
                    <p className="bl">{items?.vat_clinic}</p>
                  </td>
                  <td>
                    <p className="bl">
                      {getValFromKey(
                        metaData?.flush_status,
                        items?.flush_status
                      )}
                    </p>
                  </td>
                  <td>
                    <p className="bl">
                      {items?.amount} {prefrence?.currency_value}
                    </p>
                  </td>
                  <td>
                    <div className="icon-wrapper">
                      {metaData?.activeService == serviceId && (
                        <>
                          <a>
                            <img
                              src={pencilIcon}
                              onClick={() => editHandler(items?.id)}
                              alt=""
                            />
                          </a>
                          <a>
                            <img
                              src={binIcon}
                              onClick={() => deleteNow(items?.id)}
                              alt=""
                            />
                          </a>
                        </>
                      )}
                    </div>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
      <ConfirmPopup
        heading={t("Delete")}
        message={t("Are you sure to delete this record?")}
        show={confirmPopupShow}
        onHide={() => {
          setConfirmPopupShow(false);
          setDeletableRecords([]);
        }}
        confirm={deleteHandler}
      />
    </React.Fragment>
  );
}
