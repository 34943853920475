
export const ADD_FARRIER_RECORD_REQUEST = "ADD_FARRIER_RECORD_REQUEST";
export const ADD_FARRIER_RECORD_SUCCESS = "ADD_FARRIER_RECORD_SUCCESS";
export const ADD_FARRIER_RECORD_FAILD = "ADD_FARRIER_RECORD_FAILD";
export const ADD_FARRIER_RECORD_LONG = "ADD_FARRIER_RECORD_LONG";
export const ADD_FARRIER_RECORD_CLEAR = "ADD_FARRIER_RECORD_CLEAR";

export const GET_FARRIER_RECORD_LIST_REQUEST = "GET_FARRIER_RECORD_LIST_REQUEST";
export const GET_FARRIER_RECORD_LIST_SUCCESS = "GET_FARRIER_RECORD_LIST_SUCCESS";
export const GET_FARRIER_RECORD_LIST_FAILD = "GET_FARRIER_RECORD_LIST_FAILD";
export const GET_FARRIER_RECORD_LIST_LONG = "GET_FARRIER_RECORD_LIST_LONG";
export const GET_FARRIER_RECORD_LIST_CLEAR = "GET_FARRIER_RECORD_LIST_CLEAR";

export const DESTROY_FARRIER_RECORD_REQUEST = "DESTROY_FARRIER_RECORD_REQUEST";
export const DESTROY_FARRIER_RECORD_SUCCESS = "DESTROY_FARRIER_RECORD_SUCCESS";
export const DESTROY_FARRIER_RECORD_FAILD = "DESTROY_FARRIER_RECORD_FAILD";
export const DESTROY_FARRIER_RECORD_LONG = "DESTROY_FARRIER_RECORD_LONG";
export const DESTROY_FARRIER_RECORD_CLEAR = "DESTROY_FARRIER_RECORD_CLEAR";

export const VIEW_FARRIER_RECORD_REQUEST = "VIEW_FARRIER_RECORD_REQUEST";
export const VIEW_FARRIER_RECORD_SUCCESS = "VIEW_FARRIER_RECORD_SUCCESS";
export const VIEW_FARRIER_RECORD_FAILD = "VIEW_FARRIER_RECORD_FAILD";
export const VIEW_FARRIER_RECORD_LONG = "VIEW_FARRIER_RECORD_LONG";
export const VIEW_FARRIER_RECORD_CLEAR = "VIEW_FARRIER_RECORD_CLEAR";

