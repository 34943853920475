import { t } from "i18next";
import React, { useContext, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Outlet, useLocation, useNavigate } from "react-router-dom";
import BanknoteIcon from "../../../Assets/img/Common-icon/BanknoteIcon";
import ContactIcon from "../../../Assets/img/Common-icon/ContactIcon";
import UserIcon from "../../../Assets/img/Common-icon/UserIcon";
import UsersIcon from "../../../Assets/img/users.svg";
import {
  USER_CONTACT,
  USER_PAYROLL,
  USER_STAFF,
} from "../../../Constant/Route";
import { SidebarContext } from "../../../context/SideBarContext";
import { upgradePopupAction } from "../../../Redux/General/GeneralAction";
import TabWrapper from "../../Layouts/TabWrapper/TabWrapper";

export default function UsersPage() {
  const [parentTab, setParentTab] = React.useState(0);

  const modules = useSelector(
    (state: any) => state?.masterReducer?.data?.component?.modules
  );

  const dispatch = useDispatch();

  const parentTabHandleChange = (
    event: React.SyntheticEvent,
    newValue: number
  ) => {
    if (newValue == 2 && !modules?.payroll?.is_enabled) {
      return;
    }
    setParentTab(newValue);
  };
  const parentTabData = [
    {
      index: 0,
      img: <ContactIcon />,
      label: t("Contacts"),
      path: USER_CONTACT,
    },
    {
      index: 0,
      img: <UserIcon />,
      label: t("Staff"),
      path: USER_STAFF,
    },
    {
      index: 0,
      img: <BanknoteIcon />,
      label: t("Payroll"),
      path: USER_PAYROLL,
    },
  ];

  let navigate = useNavigate();
  const userRouteChange = (pathName: any) => {
    if (pathName === "/user/userpayroll" && !modules?.payroll?.is_enabled) {
      upgradePopupAction({ show: true });
      return;
    }
    navigate(pathName);
  };
  const { setSidebar }: any = useContext(SidebarContext);

  const location: any = useLocation();

  useEffect(() => {
    setSidebar("");
  }, []);

  useEffect(() => {
    if (location.pathname.includes(USER_CONTACT)) {
      setParentTab(0);
    } else if (location.pathname.includes(USER_STAFF)) {
      setParentTab(1);
    } else if (location.pathname.includes(USER_PAYROLL)) {
      setParentTab(2);
    }
    // location.pathname == DASHBOARD ? "active" : "";
    // setInnerTab(1);
  }, [location?.pathname]);

  return (
    <div className="user-page-wrapper">
      {/* Page Title Start */}
      <div className="page-title-wrapper">
        <div className="title-first-menu">
          <div className="title-icon-text">
            <img src={UsersIcon} alt="" />
            <h5>{t("Users")}</h5>
          </div>
        </div>
        <TabWrapper
          value={parentTab}
          horseRouteChange={userRouteChange}
          handleChange={parentTabHandleChange}
          parentTabData={parentTabData}
        />
      </div>

      {/* Page Title End */}

      <div>
        <Outlet />
      </div>
    </div>
  );
}
