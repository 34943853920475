import type { Moment } from "moment";
import moment from "moment";
import React, { useState, useEffect } from "react";
import { Col, Row } from "react-bootstrap";
import Modal from "react-bootstrap/Modal";
import MinusIcon from "../../../Assets/img/minus-circle.svg";
import PlusIcon from "../../../Assets/img/plus-circle.svg";
import CustomeButton from "../../Common/CustomButton/CustomButton";
import InputGroups from "../../Common/InputGroups/InputGroups";
import InputSelectMix from "../../Common/InputSelectMix/InputSelectMix";
import InputSelectWithLabel from "../../Common/InputSelectWithTitle/InputSelectWithTitle";
import SelectGropus from "../../Common/SelectGroups/SelectGroups";
import TextButton from "../../Common/TextButton/TextButton";
import Timepicker from "../../Common/Timepicker/Timepicker";
import { useDispatch, useSelector } from "react-redux";
import {
  metaUnitAction,
  metaDietCategoryAction,
  metaHorseListAction,
} from "../../../Redux/General/GeneralAction";
import validate from "../../../Utility/Validation";
import { addEditDietAction } from "../../../Redux/Horse/Diet/DietAction";
import {
  ADD_DIET_REQUEST,
  ADD_DIET_FAILD,
  ADD_DIET_SUCCESS,
  ADD_DIET_CLEAR,
  ADD_DIET_LONG,
  VIEW_DIET_CLEAR,
} from "../../../Redux/Horse/Diet/DietType";
import { API_STATUS } from "../../../Constant/Api";
import {
  SUCCESS_RESPONSE_STATUS,
  SUCCESS_RESPONSE_CODE,
} from "../../../Constant/Status";
import { useLoading } from "../../../context/LoadingContext";
import MultiSelectGroup from "../../Common/MultiSelectGroup/MultiSelectGroup";
import { t } from "i18next";
import { useLocation, useNavigate } from "react-router-dom";
import InputCalcuGroups from "../../Common/InputCalcuGroups/InputCalcuGroups";
import Calculator from "../../Calculator/Calculator";


export default function AddDietPopup(props: any) {
  const INITIAL_DIET_ITEMS: any = [
    {
      category: {
        value: "",
        validation: ["required"],
        errors: [t("Category is required.")],
      },
      product: {
        value: "",
        validation: [""],
        errors: [t("Product is required.")],
      },
      qty: {
        value: "",
        validation: ["required"],
        errors: [t("Qty is required.")],
      },
      unit: {
        value: "",
        validation: ["required"],
        errors: [t("Unit is required.")],
      },
      amount: {
        value: "",
        validation: [""],
        errors: [t("Amount is required.")],
      },

      id: {
        value: "",
      },
    },
  ];

  const INTIAL_DIAT_DATA = {
    id: {
      value: "",
    },
    horse_id: {
      value: [],
      validation: [""],
      errors: [t("Horse is required")],
    },
    time: {
      value: moment().format("YYYY-MM-DD HH:mm"),
      validation: ["required"],
      errors: [t("Time is required.")],
    },
    dietItems: [...INITIAL_DIET_ITEMS],
  };

  const timeChange = (time: Moment, timeString: string) => {
    setData("time", time.format("YYYY-MM-DD HH:mm"));
  };

  const [dietData, setDietData] = useState(INTIAL_DIAT_DATA);
  const [isFormValidated, setIsFormValidated] = useState(false);
  const [dietDataError, setDietDataError] = useState<any>({});
  const [apiStatus, setApiStatus] = useState(API_STATUS.SUCCESS);
  const { setLoading } = useLoading();
  const dispatch = useDispatch();
  const location:any = useLocation();
  const [currentFocus , setCurrentFocus] = useState("left");

  const navigate = useNavigate();

  const addEditDietResponse = useSelector(
    (state: any) => state?.addEditDietReducer
  );
  const prefrence = useSelector(
    (state: any) => state?.masterReducer?.data?.component?.preferance
  );

  const reminderStatus = useSelector(
    (state: any) => state?.reminderStatusReducer
  );

  useEffect(() => {
    if (addEditDietResponse?.type === ADD_DIET_REQUEST) {
      if (apiStatus !== API_STATUS.LOADING) {
        setLoading(true);
        setApiStatus(API_STATUS.LOADING);
      }
    } else if (
      addEditDietResponse?.data?.statusCode === SUCCESS_RESPONSE_CODE &&
      addEditDietResponse?.type === ADD_DIET_SUCCESS
    ) {
      if (apiStatus !== API_STATUS.SUCCESS) {
        setApiStatus(API_STATUS.SUCCESS);
        // navigate("/");
        setLoading(false);
        if (location?.state?.reminder_id && location?.state?.path) {
          let reminder_id = location?.state?.reminder_id;
          navigate(location?.state?.path, {
            state: { reminder_id: reminder_id, status: "1" },
          });
        } else {
          props.onHide();
          props.setrefreshlist();
        }
      }
    } else if (addEditDietResponse?.type === ADD_DIET_LONG) {
      if (apiStatus !== API_STATUS.LONG) {
        setLoading(false);
        setApiStatus(API_STATUS.LONG);
      }
    } else if (addEditDietResponse?.type === ADD_DIET_FAILD) {
      if (apiStatus !== API_STATUS.FAILED) {
        setLoading(false);
        setApiStatus(API_STATUS.FAILED);
      }
    }
  }, [addEditDietResponse]);

  const setData = (field: string, value: any) => {
    let dietDetails: any = Object.assign({}, dietData);
    dietDetails[field].value = value;
    setDietData({ ...dietDetails });
  };

  useEffect(() => {
    if (props?.isedit && props?.dietdata) {
      const items = props?.dietdata?.dietDetails;
      setData("id", props?.dietdata?.healthData?.id);
      setData(
        "time",
        moment(props?.dietdata?.healthData?.time, "hh:mm A").format(
          "YYYY-MM-DD HH:mm"
        )
      );
      let dietList: any = [];
      items?.map((item: any) => {
        const a = JSON.parse(JSON.stringify(INITIAL_DIET_ITEMS[0]));
        let ob: any = Object.assign({}, a);
        ob["product"].value = item?.product;
        ob["category"].value = item?.category;
        ob["id"].value = item?.id;
        ob["qty"].value = item?.qty;
        ob["amount"].value = item?.amount_per_unit;
        ob["unit"].value = item?.unit;
        dietList.push(ob);
      });
      setDietData({ ...dietData, dietItems: dietList });
    }
  }, [props?.dietdata, props?.isedit, props?.ismultiple]);

  const addDietItem = () => {
    let dietItems = Object.assign([], dietData?.dietItems);
    dietItems.push(INITIAL_DIET_ITEMS[0]);
    setDietData({ ...dietData, dietItems: dietItems });
  };

  const removeDietItem = (index: number) => {
    let dietItems = Object.assign([], dietData?.dietItems);
    if (dietItems.length <= 1) {
      return;
    }
    dietItems.splice(index, 1);
    setDietData({ ...dietData, dietItems: dietItems });
  };

  const handleChange = (index: number, e: any, fieldName: string) => {
    let dietItems: any = Object.assign([], dietData?.dietItems);
    dietItems[index][fieldName].value = e.target.value;
    setDietData({ ...dietData, dietItems: dietItems });
  };

  const handleSubmit = () => {
    if (validate(dietData).isValidated === true) {
      let dietItems: any = [];
      dietData?.dietItems.map((item: any) => {
        let ob: any = {};
        if (props?.isedit) {
          ob["id"] = item.id?.value ? item.id?.value : "";
        }
        ob["category"] = item.category.value;
        ob["product"] = item.product.value;
        ob["qty"] = item.qty.value;
        ob["unit"] = item.unit.value;
        ob["amount_per_unit"] = item.amount.value;
        dietItems.push(ob);
      });

      let params: any = {
        horse_id: props?.ismultiple ? dietData?.horse_id?.value : props.horseid,
        time: dietData.time.value,
        items: dietItems,
      };
      if (props.isedit) {
        params.id = dietData.id.value;
      }

      if (
        reminderStatus?.data?.key === "diet" &&
        reminderStatus?.data?.reminder_id
      ) {
        params["reminder_id"] = reminderStatus?.data?.reminder_id;
      }

      addEditDietAction(params);
    } else {
      setIsFormValidated(true);
      setDietDataError({ ...validate(dietData)?.errors });
    }
  };

  useEffect(() => {
    setDietDataError({ ...validate(dietData)?.errors });
  }, [dietData]);

  useEffect(() => {
    if (props?.show === false) {
      setDietDataError({});
      setDietData(INTIAL_DIAT_DATA);
      setIsFormValidated(false);
      setCalcuActive(false)
    } else if (props?.ismultiple) {
      const ob: any = Object.assign({}, dietData);
      ob["horse_id"].validation[0] = ["requiredArray"];
      setDietData({ ...ob });
    }
  }, [props?.show]);

  const { setrefreshlist, metaData, ...rest } = props;

  const [calcuActive, setCalcuActive] = useState(false);

  
  return (
    <Modal {...rest} centered className={`add-diet-popup ${calcuActive ? 'modal-1016' : 'modal-824'}`}>
      <Modal.Header closeButton>
        <p className="tl">{props.isedit ? t("Edit Diet") : t("Add Diet")} </p>
      </Modal.Header>
      <Modal.Body>
        {/* use this label only on selected items */}
        {/* <label className="tm">
          {props.ismultiple
            ? `Add diet for selected ${props.horseid?.length} horses.`
            : ""}
        </label> */}
        {/* use this label only on selected items */}
        <div className="add-diet-content-wrapper" >
          <div className="add-diet-content" onClick={() => {
            setCurrentFocus("left")
          }} >
            <div className="rm-pr-40">
              <Row>
                {props?.ismultiple ? (
                  <Col sm={6} md={6}>
                    <MultiSelectGroup
                      label={`${t("Horse")}*`}
                      value={metaData?.horse_list}
                      defaultSelect={dietData?.horse_id?.value}
                      dropKey="id"
                      dropVal="name"
                      onChange={(e: any) => {
                        setData("horse_id", e);
                      }}
                      error={
                        isFormValidated && dietDataError["horse_id"]
                          ? dietDataError["horse_id"]
                          : null
                      }
                    />
                  </Col>
                ) : null}

                <Col sm={6} md={6}>
                  <Timepicker
                    label={`${t("Time")}*`}
                    onChange={timeChange}
                    value={moment(dietData?.time?.value)}
                    error={
                      isFormValidated && dietDataError["time"]
                        ? dietDataError["time"]
                        : null
                    }
                  />
                </Col>
              </Row>
              <hr />
            </div>
            <p className="tm">{t("Diet's Items")}</p>

            {dietData?.dietItems.map((item: any, index: any) => {
              return (
                <React.Fragment key={index + "repeate-box"}>
                  <div className="repeate-box">
                    <div className="rm-pr-40">
                      <hr />
                      <Row>
                        <Col sm={6} md={6}>
                          <SelectGropus
                            label={`${t("Category")}*`}
                            value={metaData?.diet_category}
                            defaultSelect={item?.category?.value}
                            dropKey="id"
                            dropVal="metadata"
                            onChange={(e: any) =>
                              handleChange(index, e, "category")
                            }
                            error={
                              isFormValidated &&
                              dietDataError?.["dietItems"]?.[index]?.["category"]
                                ? dietDataError["dietItems"][index]["category"]
                                : null
                            }
                          />
                        </Col>
                        <Col sm={6} md={6}>
                          <InputGroups
                            label={`${t("Product")}`}
                            type="text"
                            autoComplete="off"
                            value={item?.product?.value}
                            onChange={(e: any) => handleChange(index, e, "product")}
                            error={
                              isFormValidated &&
                              dietDataError["dietItems"]?.[index]?.["product"]
                                ? dietDataError["dietItems"]?.[index]?.["product"]
                                : null
                            }
                          />
                        </Col>
                      </Row>
                    </div>
                    <div className="nrm-pr-40">
                      <Row>
                        <Col sm={6} md={6}>
                          <InputSelectWithLabel
                            inputLabel={`${t("Qty")}*`}
                            selectLabel={`${t("Unit")}*`}
                            dropKey="id"
                            dropVal="metadata"
                            inputValue={item?.qty?.value}
                            selectValue={metaData?.qty_units}
                            selectDefaultSelect={item?.unit?.value}
                            onInputChange={(e: any) =>
                              handleChange(index, e, "qty")
                            }
                            onSelectChange={(e: any) =>
                              handleChange(index, e, "unit")
                            }
                            selectError={
                              isFormValidated &&
                              dietDataError["dietItems"]?.[index]?.["unit"]
                                ? dietDataError["dietItems"]?.[index]?.["unit"]
                                : null
                            }
                            inputError={
                              isFormValidated &&
                              dietDataError["dietItems"]?.[index]?.["qty"]
                                ? dietDataError["dietItems"]?.[index]?.["qty"]
                                : null
                            }
                          />
                        </Col>
                        <Col sm={6} md={6}>
                          <div className="remove-btn-parent">
                            <InputCalcuGroups
                              onBtnClick={() => {
                                if(window.innerWidth > 992){
                                  setCalcuActive(!calcuActive)
                                }
                              }}
                              label={`${t("Amount/Unit Per Day")}`}
                              inputType="number"
                              value={item?.amount?.value}
                              onChange={(e: any) =>
                                handleChange(index, e, "amount")
                              }
                              inputError={
                                isFormValidated &&
                                dietDataError["dietItems"]?.[index]?.["amount"]
                                  ? dietDataError["dietItems"]?.[index]?.["amount"]
                                  : null
                              }
                              inputSecondValue={prefrence?.currency_value}
                            />
                            {dietData.dietItems?.length > 1 && (
                              <div className="remove-btn">
                                <img
                                  src={MinusIcon}
                                  onClick={() => removeDietItem(index)}
                                  alt=""
                                />
                              </div>
                            )}
                          </div>
                        </Col>
                      </Row>
                    </div>
                  </div>
                </React.Fragment>
              );
            })}

            <div className="add-row-btn">
              <img src={PlusIcon} alt="" />
              <p className="bm" onClick={addDietItem}>
                {t("Add Product")}
              </p>
            </div>
          </div>
         <Calculator currentFocus={currentFocus} setCurrentFocus={setCurrentFocus}/>
        </div>
      </Modal.Body>
      <Modal.Footer>
        <div className="rm-pr-40">
          <div className="footer-2-btn">
            <TextButton onClick={props.onHide}> {t("Cancel")}</TextButton>
            <CustomeButton bg="fill" onClick={handleSubmit}>
              {" "}
              {props.isedit ? t("Update") : t("Add")}
            </CustomeButton>
          </div>
        </div>
      </Modal.Footer>
    </Modal>
  );
}
