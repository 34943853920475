import { getRoles } from "@testing-library/react";
import { t } from "i18next";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { API_STATUS } from "../../Constant/Api";
import { DEFAULT_SORTING, ERROR, PERMISSION } from "../../Constant/General";
import { LANGUAGE } from "../../Constant/LocalStorage";
import { LOGOUT } from "../../Constant/Route";
import { SUCCESS_RESPONSE_CODE } from "../../Constant/Status";
import { loginAction } from "../../Redux/Auth/AuthAction";
import {
  LOGIN_FAILD,
  LOGIN_LONG,
  LOGIN_REQUEST,
  LOGIN_SUCCESS,
} from "../../Redux/Auth/AuthType";
import { changeLangAction, masterAction } from "../../Redux/General/GeneralAction";
import {
  MASTER_REQUEST,
  MASTER_FAILD,
  MASTER_SUCCESS,
} from "../../Redux/General/GeneralType";
import { setSessionItem, showToast } from "../../Utility/General";
import Loader from "../Loader/Loader";

export default function Login() {
  const { i18n } = useTranslation();
  const param = useParams();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [apiStatus, setApiStatus] = useState(API_STATUS.SUCCESS);

  const loginReducer = useSelector((state: any) => state?.login);
  const masterApiResponse = useSelector((state: any) => state?.masterReducer);
  useEffect(() => {
    let token = param.token;
    loginAction({ token: token });
  }, []);

  const getRoles = () => {
    masterAction();
  };

  useEffect(() => {
    if (masterApiResponse?.type === MASTER_REQUEST) {
      if (apiStatus !== API_STATUS.LOADING) {
        setApiStatus(API_STATUS.LOADING);
      }
    } else if (masterApiResponse?.type === MASTER_FAILD) {
      if (apiStatus !== API_STATUS.FAILED) {
        setApiStatus(API_STATUS.FAILED);
      }
    } else if (masterApiResponse?.type === MASTER_SUCCESS) {
      if (apiStatus !== API_STATUS.SUCCESS) {
        let isSubscribed = masterApiResponse?.data?.component?.is_subcribe;

        if (!isSubscribed) {
          navigate(LOGOUT);
          showToast(ERROR, t("You are not subscriber."));
        } else {
          navigate("/");
        }
      }
    }
  }, [masterApiResponse]);


  useEffect(() => {
    if (loginReducer?.type === LOGIN_REQUEST) {
      if (apiStatus !== API_STATUS.LOADING) {
        setApiStatus(API_STATUS.LOADING);
      }
    } else if (
      loginReducer?.data?.statusCode === SUCCESS_RESPONSE_CODE &&
      loginReducer?.type === LOGIN_SUCCESS
    ) {
      if (apiStatus !== API_STATUS.SUCCESS) {
        setApiStatus(API_STATUS.SUCCESS);
        let lang: any = loginReducer?.data?.component?.ajm_language;
        if (lang) {
          if(lang !== localStorage.getItem(LANGUAGE)){
            localStorage.setItem(LANGUAGE, lang);
            i18n.changeLanguage(lang);
            window.location.reload();
          }
        }
        getRoles();

      }
    } else if (loginReducer?.type === LOGIN_LONG) {
      if (apiStatus !== API_STATUS.LONG) {
        setApiStatus(API_STATUS.LONG);
      }
    } else if (loginReducer?.type === LOGIN_FAILD) {
      if (apiStatus !== API_STATUS.FAILED) {
        showToast(ERROR, "Something went wrong in login. please try again");
        setTimeout(() => {
          navigate("/logout");
        }, 2000);
        setApiStatus(API_STATUS.FAILED);
      }
    }
  }, [loginReducer]);

  return <Loader />;
}
