import { END_POINTS, MAX_CALLS } from "../../Constant/Api";
import { MASTER_API } from "../../Constant/Config";
import { SUCCESS_RESPONSE_CODE } from "../../Constant/Status";
import api from "../../Service/Axios";
import { showToast } from "../../Utility/General";
import { globalPostActionWithReducer } from "../globallActions";
// import { showToast } from "../../Utility/General";
import {
  HORSE_FAILD,
  HORSE_LONG,
  HORSE_REQUEST,
  HORSE_SUCCESS,
  HORSEDETAIL_REQUEST,
  HORSEDETAIL_SUCCESS,
  HORSEDETAIL_FAILD,
  HORSEDETAIL_LONG,
  HORSEDETAIL_CLEAR,
  HORSEINFO_FAILD,
  HORSEINFO_LONG,
  HORSEINFO_REQUEST,
  HORSEINFO_SUCCESS,
} from "./HorseType";

// export const showMinicart = {
//     type: MINI_CART_TRUE,
//     payload: true
// };

// export const hideMinicart = {
//     type: MINI_CART_FALSE,
//     payload: false
// };

// export function defaultParameterAction(param: any) {
//   return (dispatch: any) => {
//     dispatch({
//       type: DEFAULT_PARAMETER,
//       payload: { data: param, type: DEFAULT_PARAMETER },
//     });
//   };
// }

export function horseInfoAction(params: any){
  let apiUrl = END_POINTS.horseInformation;
  return globalPostActionWithReducer(
    params,
    apiUrl,
    HORSEINFO_REQUEST,
    HORSEINFO_SUCCESS,
    HORSEINFO_LONG,
    HORSEINFO_FAILD
  );
}


export function horseListAction(param: any) {
    let recallCount = 0;
    return (dispatch: any) => {
        dispatch({
            type: HORSE_REQUEST,
            payload: { type: HORSE_REQUEST },
        });
        function recursiveCall() {
            api
              .post(END_POINTS.horselist, param)
              .then((result) => {
                if (result.data.statusCode === SUCCESS_RESPONSE_CODE) {
                  // showToast("success", result.data.message);
                  dispatch({
                    type: HORSE_SUCCESS,
                    payload: {
                      data: result.data,
                      type: HORSE_SUCCESS,
                    },
                  });
                } else if (recallCount < MAX_CALLS) {
                  recursiveCall();
                  recallCount++;
                  dispatch({
                    type: HORSE_LONG,
                    payload: { data: {}, type: HORSE_LONG },
                  });
                } else {
                  showToast("error", result.data.message);
                  dispatch({
                    type: HORSE_FAILD,
                    payload: { data: {}, type: HORSE_FAILD },
                  });
                }
              })
              .catch((error) => {
                showToast("error", error);
                if (recallCount < MAX_CALLS) {
                  recursiveCall();
                  recallCount++;
                  dispatch({
                    type: HORSE_LONG,
                    payload: { data: {}, type: HORSE_LONG },
                  });
                } else {
                  dispatch({
                    type: HORSE_FAILD,
                    payload: { data: {}, type: HORSE_FAILD },
                  });
                }
              });
        }
        recursiveCall()
    };
}

export function horseDetailAction(param: any) {
  let recallCount = 0;
  return (dispatch: any) => {
    dispatch({
      type: HORSEDETAIL_REQUEST,
      payload: { type: HORSEDETAIL_REQUEST },
    });
    function recursiveCall() {
      api
        .post(END_POINTS.horseDetail, param)
        .then((result) => {
          if (result.data.statusCode === SUCCESS_RESPONSE_CODE) {
            // showToast("success", result.data.message);
            dispatch({
              type: HORSEDETAIL_SUCCESS,
              payload: {
                data: result.data,
                type: HORSEDETAIL_SUCCESS,
              },
            });
          } else if (recallCount < MAX_CALLS) {
            recursiveCall();
            recallCount++;
            dispatch({
              type: HORSEDETAIL_LONG,
              payload: { data: {}, type: HORSEDETAIL_LONG },
            });
          } else {
            showToast("error", result.data.message);
            dispatch({
              type: HORSEDETAIL_FAILD,
              payload: { data: {}, type: HORSEDETAIL_FAILD },
            });
          }
        })
        .catch((error) => {
          showToast("error", error);
          if (recallCount < MAX_CALLS) {
            recursiveCall();
            recallCount++;
            dispatch({
              type: HORSEDETAIL_LONG,
              payload: { data: {}, type: HORSEDETAIL_LONG },
            });
          } else {
            dispatch({
              type: HORSEDETAIL_FAILD,
              payload: { data: {}, type: HORSEDETAIL_FAILD },
            });
          }
        });
    }
    recursiveCall();
  };
}