import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Navigate, useLocation, useParams } from "react-router-dom";
import AcessDenied from "../Component/Errors/AcessDenied/AcessDenied";
import Loader from "../Component/Loader/Loader";
import SomethingWrong from "../Component/SomethingWrong/SomethingWrong";
import { PERMISSION_ROUTES } from "../Constant/General";
import {  checkRoutePermission } from "../Utility/General";

export default function ProtecetdRoleRoutes({ children }: any) {
  const modules = useSelector(
    (state: any) => state?.masterReducer?.data?.component?.modules
  );
  let route = window.location?.pathname;
  route = route.split("/")?.[1];
  let permission: any = PERMISSION_ROUTES.get(route)?.permission;
  if(!permission){
    permission = PERMISSION_ROUTES.get(window.location.pathname)?.permission;
  }
  return (
    <>
      {(() => {
        if (!modules) return (<Loader />);
        else if (checkRoutePermission(permission, modules)) return children;
        // else if (!userRole) return <Loader />;
        else return <AcessDenied />;
      })()}
    </>
  );
}
