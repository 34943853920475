import React from "react";

export default function Invoicingicon() {
  return (
    <svg
      width="32"
      height="32"
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M24 21.5V23.7C24 24.7 23 25.3 22.2 24.9L20.2 24C19.8 23.9 19.4 23.9 19 24.1L16.4 25.7C16 25.9 15.4 25.9 15.1 25.7L12.5 24.1C12.1 23.9 11.7 23.9 11.3 24L9.40001 24.8C8.60001 25.2 7.60001 24.6 7.60001 23.6V7.40001C7.60001 6.70001 8.20001 6.10001 8.90001 6.10001H22.9C23.6 6.10001 24.2 6.70001 24.2 7.40001V11.4"
        stroke="#E8E5E2"
        strokeWidth="1.5"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M12 10.6H19.3"
        stroke="#E8E5E2"
        strokeWidth="1.5"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M12 14.8H14.6"
        stroke="#E8E5E2"
        strokeWidth="1.5"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M15.5 21.1L17.1 17.6L21.7 14.5C22.5 13.9 23.6 14.1 24.2 15C24.8 15.8 24.6 16.9 23.7 17.5L19.2 20.6L15.5 21.1Z"
        stroke="#E8E5E2"
        strokeWidth="1.5"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}
