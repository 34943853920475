import React from "react";

export default function CategoryIcon() {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M17.3182 10.3636C19.3636 10.3636 21 8.72727 21 6.68182C21 4.63636 19.3636 3 17.3182 3C15.2727 3 13.6364 4.63636 13.6364 6.68182C13.6364 8.72727 15.2727 10.3636 17.3182 10.3636Z"
        stroke="#919EAB"
        strokeWidth="1.5"
      />
      <path
        d="M8.11364 3H5.14773C3.92045 3 3 3.92045 3 5.14773V8.11364C3 9.34091 3.92045 10.2614 5.14773 10.2614H8.11364C9.34091 10.2614 10.2614 9.34091 10.2614 8.11364V5.14773C10.2614 3.92045 9.34091 3 8.11364 3Z"
        stroke="#919EAB"
        strokeWidth="1.5"
      />
      <path
        d="M6.68182 13.6364C4.63636 13.6364 3 15.2727 3 17.3182C3 19.3636 4.63636 21 6.68182 21C8.72727 21 10.3636 19.3636 10.3636 17.3182C10.3636 15.2727 8.72727 13.6364 6.68182 13.6364Z"
        stroke="#919EAB"
        strokeWidth="1.5"
      />
      <path
        d="M15.8864 21H18.8523C20.0796 21 21 20.0796 21 18.8523V15.8864C21 14.6591 20.0796 13.7386 18.8523 13.7386H15.8864C14.6591 13.7386 13.7386 14.6591 13.7386 15.8864V18.8523C13.7386 20.0796 14.6591 21 15.8864 21Z"
        stroke="#919EAB"
        strokeWidth="1.5"
      />
    </svg>
  );
}
