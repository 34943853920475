import React, { useCallback, useContext, useEffect, useState } from "react";
import Accordion from "react-bootstrap/Accordion";
import Boardingicon from "../../Assets/icon/New/Boardingicon";
import BookMobileIcon from "../../Assets/icon/New/BookMobileIcon";
import Breedingicon from "../../Assets/icon/New/Breedingicon";
import Dashboardicon from "../../Assets/icon/New/Dashboardicon";
import Dieticon from "../../Assets/icon/New/Dieticon";
import Farriericon from "../../Assets/icon/New/Farriericon";
import GlobeMobileIcon from "../../Assets/icon/New/GlobeMobileIcon";
import Healthicon from "../../Assets/icon/New/Healthicon";
import Hoursesicon from "../../Assets/icon/New/Hoursesicon";
import Incomeexpensesicon from "../../Assets/icon/New/Incomeexpensesicon";
import Inventoryicon from "../../Assets/icon/New/Inventoryicon";
import Invoicingicon from "../../Assets/icon/New/Invoicingicon";
import ManagementMobileIcon from "../../Assets/icon/New/ManagementMobileIcon";
import MarketplaceMobileIcon from "../../Assets/icon/New/MarketplaceMobileIcon";
import Movementicon from "../../Assets/icon/New/Movementicon";
import Remindersicon from "../../Assets/icon/New/Remindersicon";
import Tasksicon from "../../Assets/icon/New/Tasksicon";
import Trainingicon from "../../Assets/icon/New/Trainingicon";
import ChevronLeft from "../../Assets/img/chevron-left.svg";
import CloseSidebarIcon from "../../Assets/img/close-sidebar-icon.svg";
import Logo from "../../Assets/img/Logo.svg"
import IconLogo from "../../Assets/img/Icon-logo.svg"
import CalenderIcon from "../../Assets/img/Common-icon/CalenderIcon";
import { Link, useLocation, useNavigate } from "react-router-dom";
import {
  BOARDING,
  BREEDING,
  DASHBOARD,
  DIET,
  FARM_DIRECTORY_DETAILS,
  FARM_DIRECTORY_LISTING,
  FARRIER,
  HEALTH_VITALSIGNS,
  INCOME_EXPENSE_CATEGORY,
  INVENTORY,
  INVOICING_SERVICE_TO_INVOICE,
  LOGOUT,
  MYHORSES,
  REMINDERS_PREGNANCY,
  TASK,
  TRAINING,
  TRANSPORTATION,
} from "../../Constant/Route";
import { useTranslation } from "react-i18next";
import { SidebarContext } from "../../context/SideBarContext";
import {
  DEFAULT_SORTING,
  ERROR,
  PERMISSION,
  PERMISSIONS,
  SIDEBAR,
} from "../../Constant/General";
import { useDispatch, useSelector } from "react-redux";
import {
  checkRoutePermission,
  getSessionItem,
  getSessionItemDecrypt,
  setSessionItem,
  setSessionItemEncrypt,
  showToast,
} from "../../Utility/General";
import {
  changeLangAction,
  masterAction,
  upgradePopupAction,
} from "../../Redux/General/GeneralAction";
import { MASTER_SUCCESS } from "../../Redux/General/GeneralType";
import { useLoading } from "../../context/LoadingContext";
import { LANGUAGE, USER } from "../../Constant/LocalStorage";

export default function Sidebar(props: any) {
  const [isLoading, setIsLoading] = useState(false);
  const sideToggle = () => {
    props.setSidebarToggle(!props.sidebarToggle);
  };
  const [farmName, setFarmName] = useState("");
  const { t, i18n } = useTranslation();
  const location: any = useLocation();

  const modules = useSelector(
    (state: any) => state?.masterReducer?.data?.component?.modules
  );

  let navigate = useNavigate();
  const dispatch = useDispatch();
  const { sidebar }: any = useContext(SidebarContext);
  const { setLoading } = useLoading();
  const [windowSize, setWindowSize] = useState(window.innerWidth);
  const masterApiResponse = useSelector((state: any) => state?.masterReducer);

  useEffect(() => {
    window.addEventListener("load", (event: any) => {
      setWindowSize(event?.currentTarget?.innerWidth);
    });
    window.addEventListener("resize", (event: any) => {
      setWindowSize(event?.currentTarget?.innerWidth);
    });
    return () => {
      window.removeEventListener("load", () => {});
      window.removeEventListener("resize", () => {});
    };
  }, [windowSize]);

  const sideMenuHandler = (e: any) => {
    navigate(e);
    if (windowSize < 1200) {
      sideToggle();
    }
  };

  useEffect(() => {
    if (masterApiResponse?.type === MASTER_SUCCESS) {
      let isSubscribed = masterApiResponse?.data?.component?.is_subcribe;
      if (!isSubscribed) {
        navigate(LOGOUT);
        showToast(ERROR, t("You are not subscriber."));
      } else {
        if (masterApiResponse?.data?.component?.user_info?.farm_name) {
          setFarmName(masterApiResponse?.data?.component?.user_info?.farm_name);
        }
        else{
          setFarmName("Al Jawaher Stud");
        }
        setSessionItemEncrypt(
          DEFAULT_SORTING,
          masterApiResponse?.data?.component?.default_sorting
        );
        if(localStorage.getItem(LANGUAGE) !=  masterApiResponse?.data?.component?.preferance?.language){
          localStorage.setItem(
            LANGUAGE,
            masterApiResponse?.data?.component?.preferance?.language
          );
          window.location.reload()
        }
      }
    }
  }, [masterApiResponse]);

  useEffect(() => {
    if (!Object.keys(masterApiResponse)?.length) {
      masterAction();
    }
  }, []);

  const sideMenuData = [
    {
      name: t("Dashboard"),
      group: SIDEBAR.DASHBOARD,
      icon: <Dashboardicon />,
      link: DASHBOARD,
    },
    {
      name: t("My Horses"),
      group: SIDEBAR.MY_HORSE,
      icon: <Hoursesicon />,
      link: MYHORSES,
    },
    {
      name: t("Boarding"),
      group: SIDEBAR.BOARDING,
      icon: <Boardingicon />,
      link: BOARDING,
      permission: PERMISSIONS.BOARDING,
    },
    {
      name: t("Breeding"),
      group: SIDEBAR.BREEDING,
      icon: <Breedingicon />,
      link: BREEDING,
    },
    {
      name: t("Health"),
      group: SIDEBAR.HEALTH,
      icon: <Healthicon />,
      link: HEALTH_VITALSIGNS,
    },
    {
      name: t("Diet"),
      group: SIDEBAR.DIET,
      icon: <Dieticon />,
      link: DIET,
    },
    {
      name: t("Farrier"),
      group: SIDEBAR.FARRIER,
      icon: <Farriericon />,
      link: FARRIER,
    },
    {
      name: t("Training"),
      group: SIDEBAR.TRAINING,
      icon: <Trainingicon />,
      link: TRAINING,
    },
    {
      name: t("Transportation"),
      group: SIDEBAR.TRANSPORATATION,
      icon: <Movementicon />,
      link: TRANSPORTATION,
    },
    {
      name: t("Inventory"),
      group: SIDEBAR.INVENTORY,
      icon: <Inventoryicon />,
      link: INVENTORY,
      permission: PERMISSIONS.INVENTORY,
    },
    {
      name: t("Tasks"),
      group: SIDEBAR.TASK,
      icon: <Tasksicon />,
      link: TASK,
    },
    {
      name: t("Reminders"),
      group: SIDEBAR.REMINDER,
      icon: <Remindersicon />,
      link: REMINDERS_PREGNANCY,
    },
    {
      name: t("Income & Expenses"),
      group: SIDEBAR.INCOME_EXPENSES,
      icon: <Incomeexpensesicon />,
      link: INCOME_EXPENSE_CATEGORY,
      permission: PERMISSIONS.INCOME_EXPENSE,
    },
    {
      name: t("Invoicing"),
      group: SIDEBAR.INVOICING,
      icon: <Invoicingicon />,
      link: INVOICING_SERVICE_TO_INVOICE,
      permission: PERMISSIONS.INVOICING,
    },
  ];

  const changeLanguage = (lang: any) => {
    sideToggle();
    localStorage.setItem(LANGUAGE, lang);
    i18n.changeLanguage(lang);
    changeLangAction({ language: lang })
      .then((response) => {
        window.location.reload();
      })
      .catch((error) => {});
  };

  const EXCEPT_HORSE_MANAGEMENT_ROUTE = [
    FARM_DIRECTORY_DETAILS,
    FARM_DIRECTORY_LISTING,
  ];

  const redirectToMarketPlace = useCallback((key: any) => {
    const userData = getSessionItemDecrypt(USER);
    let token = userData.component.refresh_token;
    const time = Date.now();
    token = atob(token);
    let data: any = {
      token,
      time,
      module: key,
    };

    data = btoa(JSON.stringify(data));
    let url: any = `${process.env.REACT_APP_MARKETPLACE_URL}/check-auth/${data}`;
    window.location.href = encodeURI(url);

  }, []);

  return (
    <React.Fragment>
      <div className="sidebar">
        <div className="sidebar-title">
          <div className="close-sidebar-icon" onClick={sideToggle}>
            <img src={CloseSidebarIcon} alt="" />
          </div>
          <div className="round-title">
            {/* <p className="tm"> {farmName.match(/\b(\w)/g)?.slice(0,2)?.join('') || "AJ"}</p> */}
            <img src={IconLogo} className="icon-logo" alt="icon-logo" />
          </div>
          <div className="full-title">
            {/* <h6> {farmName} </h6> */}
            <img src={Logo} className="full-logo" alt="logo" />
          </div>
        </div>
        <div className="title-shadow"></div>
        <div className="sidebar-menu-wrapper">
          <ul className="header-menu">
            <li
              onClick={() => {
                sideMenuHandler("/");
              }}
              className={`${
                EXCEPT_HORSE_MANAGEMENT_ROUTE.includes(window.location.pathname)
                  ? ""
                  : "active"
              }`}
            >
              <a className="tm">
                <ManagementMobileIcon />
                {t("Horse Management")}
              </a>
            </li>
           
            <li
              onClick={() => {
                sideMenuHandler(FARM_DIRECTORY_LISTING);
              }}
              className={
                window.location.pathname === FARM_DIRECTORY_LISTING ||
                window.location.pathname === FARM_DIRECTORY_DETAILS
                  ? "active"
                  : ""
              }
            >
              <a className="tm">
                <BookMobileIcon />
                {t("Farm Directory")}
              </a>
            </li>
            <li>
              <a className="tm"  onClick={() => redirectToMarketPlace("marketplace")}>
                <MarketplaceMobileIcon />
                {t("Marketplace")}
              </a>
            </li>
            <li>
            <a
              className="tm"
              onClick={() => redirectToMarketPlace("eventNews")}
            >
              <CalenderIcon />
              {t("News & Events")}
            </a>
          </li>
          </ul>
          <hr className="hr-1" />

          <div className="mobile-language-wrapper">
            <Accordion defaultActiveKey="2">
              <Accordion.Item eventKey="3">
                <Accordion.Header>
                  <GlobeMobileIcon />
                  <p className="tm">{t("Language")}</p>
                </Accordion.Header>
                <Accordion.Body>
                  <a
                    onClick={() => changeLanguage("en")}
                    className="tm inner-link "
                  >
                    English
                  </a>
                  <a
                    onClick={() => changeLanguage("ar")}
                    className="tm inner-link">
                    عربي
                  </a>
                </Accordion.Body>
              </Accordion.Item>
            </Accordion>
          </div>
          <hr className="hr-2" />
          <ul>
            {sideMenuData?.map((item: any) => {
              return (
                <React.Fragment key={item?.name}>
                  {item.hasOwnProperty("permission") &&
                  !checkRoutePermission(item.permission, modules) ? (
                    <li
                      className={sidebar === item.group ? "active" : ""}
                      onClick={() =>
                        upgradePopupAction({ show: true })
                      }
                    >
                      {item?.icon}
                      <p className="tm">{item?.name}</p>
                    </li>
                  ) : (
                    <li
                      className={sidebar === item.group ? "active" : ""}
                      onClick={() => {
                        sideMenuHandler(item?.link);
                      }}
                    >
                      {item?.icon}
                      <p className="tm">{item?.name}</p>
                    </li>
                  )}
                </React.Fragment>
              );
            })}
          </ul>
        </div>
        <div className="footer-shadow"></div>
        <div className="sidebar-footer">
          <div className="left-arrow" onClick={sideToggle}>
            <img src={ChevronLeft} alt="" />
          </div>
        </div>
      </div>
    </React.Fragment>
  );
}
