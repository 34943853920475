import React from "react";

export default function MicroscopeIcon() {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M14.1972 13.3605L8.86 8.02328C8.23209 7.39538 8.23209 6.45352 8.86 5.82561L9.90651 4.7791C10.5344 4.15119 11.4763 4.15119 12.1042 4.7791L17.4414 10.1163"
        stroke="#919EAB"
        strokeWidth="1.5"
        strokeMiterlimit="10.0394"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M7.18558 4.04651L8.12744 3"
        stroke="#919EAB"
        strokeWidth="1.5"
        strokeMiterlimit="10.0394"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M17.0228 13.8837L17.9647 12.8372"
        stroke="#919EAB"
        strokeWidth="1.5"
        strokeMiterlimit="10.0394"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M19.6391 18.1744H16.6042"
        stroke="#919EAB"
        strokeWidth="1.5"
        strokeMiterlimit="10.0394"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M13.5693 21V17.1279H8.96463V21"
        stroke="#919EAB"
        strokeWidth="1.5"
        strokeMiterlimit="10.0394"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M4.35999 21H19.6391"
        stroke="#919EAB"
        strokeWidth="1.5"
        strokeMiterlimit="10.0394"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M10.4297 9.59302H8.23207C6.13905 9.59302 4.46463 11.2674 4.46463 13.3605C4.46463 15.4535 6.13905 17.1279 8.23207 17.1279H8.96463"
        stroke="#919EAB"
        strokeWidth="1.5"
        strokeMiterlimit="10.0394"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M13.1507 14.407L18.4879 9.06976"
        stroke="#919EAB"
        strokeWidth="1.5"
        strokeMiterlimit="10.0394"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M7.60419 3.52325L9.38326 5.30232"
        stroke="#919EAB"
        strokeWidth="1.5"
        strokeMiterlimit="10.0394"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}
