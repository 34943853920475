import React from "react";
import "./App.scss";
import { LoadingProvider } from "./context/LoadingContext";
import { SidebarProvider } from "./context/SideBarContext";
import Routesconfig from "./Routes/Routesconfig";

function App() {
  return (
    <div className="App">
      <SidebarProvider>
        <LoadingProvider>
          <Routesconfig></Routesconfig>
        </LoadingProvider>
      </SidebarProvider>
      {/* <Loader /> */}
    </div>
  );
}

export default App;
