import type { DatePickerProps } from "antd";
import moment from "moment";
import React, { useState, useEffect } from "react";
import { Col, Row } from "react-bootstrap";
import Modal from "react-bootstrap/Modal";
import CustomeButton from "../../Common/CustomButton/CustomButton";
import Datepicker from "../../Common/Datepicker/Datepicker";
import InputGroups from "../../Common/InputGroups/InputGroups";
import InputSelectMix from "../../Common/InputSelectMix/InputSelectMix";
import TextButton from "../../Common/TextButton/TextButton";
import ToggleButton from "../../Common/ToggleButton/ToggleButton";
import { useTranslation } from "react-i18next";
import validate from "../../../Utility/Validation";
import { addEditStaff } from "../../../Redux/NonRedux/users";
import { SUCCESS_RESPONSE_CODE } from "../../../Constant/Status";
import { useDispatch, useSelector } from "react-redux";
import { useLoading } from "../../../context/LoadingContext";
import { ERROR, UPGRADE_MESSAGE } from "../../../Constant/General";
import { showToast } from "../../../Utility/General";
import PasswordGroups from "../../Common/PasswordGroups/PasswordGroups";
import CheckboxLabel from "../../Common/CheckboxLabel/CheckboxLabel";

export default function AddStaffPopup(props: any) {
  const { t } = useTranslation();
  const INTIAL_STAFF_DATA = {
    id: {
      value: "",
    },
    first_name: {
      value: "",
      validation: ["required", "letters"],
      errors: [t("First Name is required."), t("Incorrect format.")],
    },
    last_name: {
      value: "",
      validation: ["required", "letters"],
      errors: [t("Last Name is required."), t("Incorrect format.")],
    },
    designation: {
      value: "",
      validation: ["required", "letters"],
      errors: [t("Designation is required."), t("Incorrect format.")],
    },
    email: {
      value: "",
      validation: ["required", "email"],
      errors: [t("Email is required."), t("Please enter valid email.")],
    },
    civil_id: {
      value: "",
      validation: ["required"],
      errors: [t("Civil ID is required.")],
    },
    civil_id_exp_date: {
      value: moment().format("YYYY-MM-DD"),
      validation: ["required"],
      errors: [t("Civil ID exp date is required.")],
    },
    salary: {
      value: "",
      validation: ["required"],
      errors: [t("Salary is required.")],
    },
    grant_user_access: {
      value: "2",
      validation: ["required"],
      errors: [t("Grant user access is required.")],
    },
    status: {
      value: "1",
      validation: ["required"],
      errors: [t("Status is required.")],
    },
    passport_number: {
      value: "",
      validation: [""],
      errors: [t("Passport Number is required.")],
    },
    passport_exp_date: {
      value: '',
      validation: [""],
      errors: [t("Passport exp date is required.")],
    },
    password: {
      value: "",
      validation: [],
      errors: [
        t("Password is required."),
        t("Password must be atleast 6 Characters."),
      ],
    },
    confirm_password: {
      value: "",
      validation: [],
      errors: [
        t("Confirm Password is required."),
        t("must be same as password"),
      ],
    },
  };

  const [statusSelected, setStatusSelected] = useState("Active");
  const [staffData, setStaffData] = useState<any>(INTIAL_STAFF_DATA);
  const [isFormValidated, setIsFormValidated] = useState(false);
  const [staffDataError, setStaffDataError] = useState<any>({});
  const [isChangePassword, setIsChangePassword] = useState(0);

  const { setLoading } = useLoading();
  const dispatch = useDispatch();
  const prefrence = useSelector(
    (state: any) => state?.masterReducer?.data?.component?.preferance
  );

  const setData = (field: string, value: any) => {
    const staffObject: any = Object.assign({}, staffData);
    staffObject[field].value = value;
    setStaffData({ ...staffObject });
  };

  useEffect(() => {
    setStaffDataError({ ...validate(staffData).errors });
  }, [staffData]);

  const submitHandler = () => {
    if (validate(staffData).isValidated === true) {
      let params: any = {};
      Object.keys(staffData).forEach((element) => {
        if (staffData[element].value) {
          params[element] = staffData[element].value;
        }
      });
      if(staffData["password"].value && staffData["password"].value != "" && props.isedit){
        params["change_password"] = staffData["password"].value;
      }
      setLoading(true);
      addEditStaff(params)
        .then((response: any) => {
          if (response.statusCode === SUCCESS_RESPONSE_CODE) {
            props.onHide();
            props.setrefreshlist();
          }
        })
        .catch((error: any) => { })
        .finally(() => {
          setLoading(false);
        });
    } else {
      setLoading(false);
      setStaffDataError({ ...validate(staffData)?.errors });
      setIsFormValidated(true);
    }
  };

  useEffect(() => {
    if (props?.show === false) {
      setStaffDataError({});
      setStaffData(INTIAL_STAFF_DATA);
      setIsFormValidated(false);
      setIsChangePassword(0)
    }
  }, [props?.show]);

  const handleAccessPermission = (val: any) => {
    if (val == "1" && !canAdd) {
      showToast(ERROR, t(UPGRADE_MESSAGE.GRANT_USER))
      return;
    }
    const updatedStaffValidation: any = Object.assign({}, staffData);
    if ( (val == "1" && !props.isedit) ||(props.isedit && isChangePassword)) {
      updatedStaffValidation["password"] = { ...updatedStaffValidation["password"], validation: ["required", "min:6"] }
      updatedStaffValidation["confirm_password"] = { ...updatedStaffValidation["confirm_password"], validation: ["required", "sameAsField:password"] }
    } else {
      updatedStaffValidation["password"] = { ...updatedStaffValidation["password"], validation: [] }
      updatedStaffValidation["confirm_password"] = { ...updatedStaffValidation["confirm_password"], validation: [] }
    }
    updatedStaffValidation["grant_user_access"].value = val
    setStaffData(updatedStaffValidation);
  }

  useEffect(() => {
    if(isChangePassword){
      handleAccessPermission("1")
    }
  },[isChangePassword])

  useEffect(() => {
    if (props.isedit && props?.editdata) {
      Object.keys(staffData).forEach((element) => {
        if (props?.editdata?.[element]) {
          setData(element, props?.editdata?.[element]);
        }
      });
      if (props?.editdata?.['civil_id_exp_date']) {
        setData('civil_id_exp_date', props?.editdata?.['civil_id_exp_date']);
      }
      if (props?.editdata?.['passport_exp_date']) {
        setData('passport_exp_date', props?.editdata?.['passport_exp_date']);
      }
    }
  }, [props?.editdata]);



  const { setrefreshlist, editdata, canAdd, metaData, ...rest } = props;
  return (
    <Modal {...rest} centered className="add-staff-popup modal-784">
      <Modal.Header closeButton>
        <p className="tl">{props?.isedit ? t("Edit Staff") : t("Add Staff")} </p>
      </Modal.Header>
      <Modal.Body>
        <Row>
          <Col sm={6} md={6}>
            <InputGroups
              label={`${t("First Name")}*`}
              autoComplete="off"
              value={staffData?.first_name?.value}
              onChange={(e: any) => setData("first_name", e.target.value)}
              error={
                isFormValidated && staffDataError["first_name"]
                  ? staffDataError["first_name"]
                  : null
              }
            />
          </Col>
          <Col sm={6} md={6}>
            <InputGroups
              label={`${t("Last Name")}*`}
              autoComplete="off"
              value={staffData?.last_name?.value}
              onChange={(e: any) => setData("last_name", e.target.value)}
              error={
                isFormValidated && staffDataError["last_name"]
                  ? staffDataError["last_name"]
                  : null
              }
            />
          </Col>
          <Col sm={6} md={6}>
            <InputGroups
              label={`${t("Designation")}*`}
              autoComplete="off"
              value={staffData?.designation?.value}
              onChange={(e: any) => setData("designation", e.target.value)}
              error={
                isFormValidated && staffDataError["designation"]
                  ? staffDataError["designation"]
                  : null
              }
            />
          </Col>
          <Col sm={6} md={6}>
            <InputGroups
              label={`${t("Email")}*`}
              autoComplete="off"
              value={staffData?.email?.value}
              onChange={(e: any) => setData("email", e.target.value)}
              error={
                isFormValidated && staffDataError["email"]
                  ? staffDataError["email"]
                  : null
              }
            />
          </Col>
          <Col sm={6} md={6}>
            <InputGroups
              label={`${t("Passport Number")}`}
              autoComplete="off"
              value={staffData?.passport_number?.value}
              onChange={(e: any) => setData("passport_number", e.target.value)}
              error={
                isFormValidated && staffDataError["passport_number"]
                  ? staffDataError["passport_number"]
                  : null
              }
            />
          </Col>
          <Col sm={6} md={6}>
            <Datepicker
              label={`${t("Passport Exp. Date")}`}
              onChange={(date: any) => {
                let dateIs = date?.format("YYYY-MM-DD");
                setData("passport_exp_date", dateIs);
              }}
              value={
                staffData?.passport_exp_date?.value
                  ? moment(staffData?.passport_exp_date?.value)
                  : undefined
              }
              error={
                isFormValidated && staffDataError["passport_exp_date"]
                  ? staffDataError["passport_exp_date"]
                  : null
              }
            />
          </Col>
          <Col sm={6} md={6}>
            <InputGroups
              label={`${t("Civil ID")}*`}
              autoComplete="off"
              value={staffData?.civil_id?.value}
              onChange={(e: any) => setData("civil_id", e.target.value)}
              error={
                isFormValidated && staffDataError["civil_id"]
                  ? staffDataError["civil_id"]
                  : null
              }
            />
          </Col>
          <Col sm={6} md={6}>
            <Datepicker
              label={`${t("Civil ID Exp. Date")}*`}
              onChange={(date: any) => {
                let dateIs = date?.format("YYYY-MM-DD");
                setData("civil_id_exp_date", dateIs);
              }}
              value={
                staffData?.civil_id_exp_date?.value
                  ? moment(staffData?.civil_id_exp_date?.value)
                  : undefined
              }
              error={
                isFormValidated && staffDataError["civil_id_exp_date"]
                  ? staffDataError["civil_id_exp_date"]
                  : null
              }
            />
          </Col>
          <Col sm={6} md={6}>
            <InputSelectMix
              label={`${t("Salary")}*`}
              onInputChange={(e: any) => setData("salary", e.target.value)}
              inputValue={staffData?.salary?.value}
              inputError={
                isFormValidated && staffDataError["salary"]
                  ? staffDataError["salary"]
                  : null
              }
              inputSecondValue={prefrence?.currency_value}
            />
          </Col>
        </Row>
        <Row>
          <Col sm={6} md={6} className="big-toggle">
            <ToggleButton
              value={metaData?.status_list}
              toggleVal="value"
              toggleKey="key"
              label={`${t("Status")}`}
              selected={staffData?.status?.value}
              setSelected={(val: string) => {
                setData("status", val);
              }}
            />
          </Col>
          <Col sm={6} md={6} className="small-toggle">
            <ToggleButton
              value={metaData?.staff_access}
              toggleVal="value"
              toggleKey="key"
              label={`${t("Grant User Access")}`}
              selected={staffData?.grant_user_access?.value}
              setSelected={(val: string) => {
                handleAccessPermission(val);
              }}
            />
          </Col>
          {
            (staffData?.grant_user_access?.value == 1 && props.isedit) ? <div className="change-pass-checkbox">
              <Col sm={12} md={12}>
                <CheckboxLabel
                  checked={isChangePassword}
                  onChange={(e: any) => {
                    setIsChangePassword(e.target.checked ? 1 : 0)

                  }}
                >
                  {t("Change Password")}
                </CheckboxLabel>
              </Col>
            </div> : null
          }

{
            staffData?.grant_user_access?.value == 1 && ((props.isedit && isChangePassword) || !props.isedit) ? <>
              <Col sm={6} md={6}>
                <PasswordGroups
                  label={props.isedit ? t("Change Password") : t("Password")}
                  type="password"
                  autoComplete="off"
                  value={staffData?.password?.value}
                  onChange={(e: any) => setData("password", e.target.value)}
                  error={
                    isFormValidated && staffDataError["password"]
                      ? staffDataError["password"]
                      : null
                  }
                />
              </Col>
              <Col sm={6} md={6}>
                <PasswordGroups
                  label={t("Confirm Password")}
                  type="password"
                  autoComplete="off"
                  value={staffData?.confirm_password?.value}
                  onChange={(e: any) =>
                    setData("confirm_password", e.target.value)
                  }
                  error={
                    isFormValidated && staffDataError["confirm_password"]
                      ? staffDataError["confirm_password"]
                      : null
                  }
                />
              </Col>
            </> : null
          }
         

        </Row>
        



          


      </Modal.Body>
      <Modal.Footer>
        <div className="footer-2-btn">
          <TextButton onClick={props.onHide}> {t("Cancel")}</TextButton>
          <CustomeButton bg="fill" onClick={submitHandler}>
            {props?.isedit ? t("Update") : t("Add")}
          </CustomeButton>
        </div>
      </Modal.Footer>
    </Modal>
  );
}
