export const ADD_HORSE_HEALTH_REQUEST = "ADD_HORSE_HEALTH_REQUEST";
export const ADD_HORSE_HEALTH_SUCCESS = "ADD_HORSE_HEALTH_SUCCESS";
export const ADD_HORSE_HEALTH_FAILD = "ADD_HORSE_HEALTH_FAILD";
export const ADD_HORSE_HEALTH_LONG = "ADD_HORSE_HEALTH_LONG";
export const ADD_HORSE_HEALTH_CLEAR = "ADD_HORSE_HEALTH_CLEAR";

export const VIEW_HEALTH_REQUEST = "VIEW_HEALTH_REQUEST";
export const VIEW_HEALTH_SUCCESS = "VIEW_HEALTH_SUCCESS";
export const VIEW_HEALTH_FAILD = "VIEW_HEALTH_FAILD";
export const VIEW_HEALTH_LONG = "VIEW_HEALTH_LONG";
export const VIEW_HEALTH_CLEAR = "VIEW_HEALTH_CLEAR";

export const DESTROY_HEALTH_REQUEST = "DESTROY_HEALTH_REQUEST";
export const DESTROY_HEALTH_SUCCESS = "DESTROY_HEALTH_SUCCESS";
export const DESTROY_HEALTH_FAILD = "DESTROY_HEALTH_FAILD";
export const DESTROY_HEALTH_LONG = "DESTROY_HEALTH_LONG";
export const DESTROY_HEALTH_CLEAR = "DESTROY_HEALTH_CLEAR";

export const HORSE_VITALSIGN_REQUEST = "HORSE_VITALSIGN_REQUEST";
export const HORSE_VITALSIGN_SUCCESS = "HORSE_VITALSIGN_SUCCESS";
export const HORSE_VITALSIGN_FAILD = "HORSE_VITALSIGN_FAILD";
export const HORSE_VITALSIGN_LONG = "HORSE_VITALSIGN_LONG";
export const HORSE_VITALSIGN_CLEAR = "HORSE_VITALSIGN_CLEAR";

export const HORSE_VACCINE_REQUEST = "HORSE_VACCINE_REQUEST";
export const HORSE_VACCINE_SUCCESS = "HORSE_VACCINE_SUCCESS";
export const HORSE_VACCINE_FAILD = "HORSE_VACCINE_FAILD";
export const HORSE_VACCINE_LONG = "HORSE_VACCINE_LONG";
export const HORSE_VACCINE_CLEAR = "HORSE_VACCINE_CLEAR";

export const HORSE_PARASITE_REQUEST = "HORSE_PARASITE_REQUEST";
export const HORSE_PARASITE_SUCCESS = "HORSE_PARASITE_SUCCESS";
export const HORSE_PARASITE_FAILD = "HORSE_PARASITE_FAILD";
export const HORSE_PARASITE_LONG = "HORSE_PARASITE_LONG";
export const HORSE_PARASITE_CLEAR = "HORSE_PARASITE_CLEAR";

export const HORSE_DENTAL_REQUEST = "HORSE_DENTAL_REQUEST";
export const HORSE_DENTAL_SUCCESS = "HORSE_DENTAL_SUCCESS";
export const HORSE_DENTAL_FAILD = "HORSE_DENTAL_FAILD";
export const HORSE_DENTAL_LONG = "HORSE_DENTAL_LONG";
export const HORSE_DENTAL_CLEAR = "HORSE_DENTAL_CLEAR";

export const HORSE_VET_CLINIC_REQUEST = "HORSE_VET_CLINIC_REQUEST";
export const HORSE_VET_CLINIC_SUCCESS = "HORSE_VET_CLINIC_SUCCESS";
export const HORSE_VET_CLINIC_FAILD = "HORSE_VET_CLINIC_FAILD";
export const HORSE_VET_CLINIC_LONG = "HORSE_VET_CLINIC_LONG";
export const HORSE_VET_CLINIC_CLEAR = "HORSE_VET_CLINIC_CLEAR";

export const HORSE_TREATMENTS_REQUEST = "HORSE_TREATMENTS_REQUEST";
export const HORSE_TREATMENTS_SUCCESS = "HORSE_TREATMENTS_SUCCESS";
export const HORSE_TREATMENTS_FAILD = "HORSE_TREATMENTS_FAILD";
export const HORSE_TREATMENTS_LONG = "HORSE_TREATMENTS_LONG";
export const HORSE_TREATMENTS_CLEAR = "HORSE_TREATMENTS_CLEAR";

export const HORSE_LABTESTS_REQUEST = "HORSE_LABTESTS_REQUEST";
export const HORSE_LABTESTS_SUCCESS = "HORSE_LABTESTS_SUCCESS";
export const HORSE_LABTESTS_FAILD = "HORSE_LABTESTS_FAILD";
export const HORSE_LABTESTS_LONG = "HORSE_LABTESTS_LONG";
export const HORSE_LABTESTS_CLEAR = "HORSE_LABTESTS_CLEAR";

export const HORSE_XRAYS_REQUEST = "HORSE_XRAYS_REQUEST";
export const HORSE_XRAYS_SUCCESS = "HORSE_XRAYS_SUCCESS";
export const HORSE_XRAYS_FAILD = "HORSE_XRAYS_FAILD";
export const HORSE_XRAYS_LONG = "HORSE_XRAYS_LONG";
export const HORSE_XRAYS_CLEAR = "HORSE_XRAYS_CLEAR";