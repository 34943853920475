import type { DatePickerProps } from "antd";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { Col, Row } from "react-bootstrap";
import Modal from "react-bootstrap/Modal";
import MinusIcon from "../../../Assets/img/minus-circle.svg";
import PlusIcon from "../../../Assets/img/plus-circle.svg";
import CustomeButton from "../../Common/CustomButton/CustomButton";
import Datepicker from "../../Common/Datepicker/Datepicker";
import InputSelectGroup from "../../Common/InputSelectGroup/InputSelectGroup";
import InputSelectMix from "../../Common/InputSelectMix/InputSelectMix";
import MultiSelectGroup from "../../Common/MultiSelectGroup/MultiSelectGroup";
import SelectGropus from "../../Common/SelectGroups/SelectGroups";
import TextButton from "../../Common/TextButton/TextButton";
import { useDispatch, useSelector } from "react-redux";
import {
  farrierContactAction,
  metaShoeingTypesAction,
  metaShoeMaterialAction,
  metaHorseListAction,
} from "../../../Redux/General/GeneralAction";
import { API_STATUS } from "../../../Constant/Api";
import validate from "../../../Utility/Validation";
import { addBulkFarrier } from "../../../Redux/NonRedux/horse";
import { SUCCESS_RESPONSE_CODE } from "../../../Constant/Status";
import { useLoading } from "../../../context/LoadingContext";
import { t } from "i18next";

export default function AddBulkFarrierPopup(props: any) {
  const INITIAL_FARRIER_ITEMS: any = [
    {
      horse_id: {
        value: [],
        validation: ["requiredArray"],
        errors: [t("Horse is required.")],
      },
      shoeing_type: {
        value: "",
        validation: ["required"],
        errors: [t("Shoeing Type is required.")],
      },
      shoe_material: {
        value: "",
        validation: [""],
        errors: [t("Shoeing Material is required.")],
      },
      amount: {
        value: "",
        validation: [""],
        errors: [t("Amount is required.")],
      },

      id: {
        value: "",
      },
    },
  ];

  const INTIAL_FARRIER_RECORD_DATA = {
    id: {
      value: "",
    },
    select_farrier: {
      value: "farrier_id",
      validation: ["required"],
      errors: [t("Farrier is required.")],
    },
    farrier_id: {
      value: "",
      validation: ["requiredIf:select_farrier:farrier_id"],
      errors: [t("Farrier is required.")],
    },
    farrier: {
      value: "",
      validation: ["requiredIf:select_farrier:farrier", "letters"],
      errors: [t("Farrier is required."), t("Incorrect format")],
    },
    date: {
      value: moment().format("YYYY-MM-DD"),
      validation: ["required"],
      errors: [t("Date is required.")],
    },
    farrierItems: [...INITIAL_FARRIER_ITEMS],
  };

  const [FarrierToggle, setFarrierToggle] = useState("select");
  const [farrierrRecordData, setFarrierRecordData] = useState(
    INTIAL_FARRIER_RECORD_DATA
  );
  const [isFormValidated, setIsFormValidated] = useState(false);
  const [farrierRecordError, setFarrierRecordError] = useState<any>({});
  const [apiStatus, setApiStatus] = useState(API_STATUS.SUCCESS);
  const {setLoading} = useLoading();
  const farrierList = useSelector((state: any) =>
    state?.farrierContactReducer?.data?.component?.map((item: any) => ({
      ...item,
      full_name: `${item?.first_name} ${item.last_name ? item.last_name : ""}`,
    }))
  );
  const { setrefreshlist ,  metaData , ...rest } = props;

  const dispatch = useDispatch();


  const prefrence = useSelector(
    (state: any) => state?.masterReducer?.data?.component?.preferance
  );

  useEffect(() => {
    setFarrierRecordError({ ...validate(farrierrRecordData).errors });
  }, [farrierrRecordData]);

  useEffect(() => {
    if (props?.show === false) {
      setFarrierRecordError({});
      setFarrierRecordData(INTIAL_FARRIER_RECORD_DATA);
      setIsFormValidated(false);
    } else {
      let params = {
        type: "farrier",
        search: "",
      };
      setFarrierToggle("select");
    }
  }, [props?.show]);




  const dateChange: DatePickerProps["onChange"] = (date, dateString) => {
    let dateIs = date?.format("YYYY-MM-DD");
    setData("date", dateIs);
  };

  const setData = (field: string, value: any) => {
    let farrierOb: any = Object.assign({}, farrierrRecordData);
    farrierOb[field].value = value;
    setFarrierRecordData({ ...farrierOb });
  };


  const addListItem = () => {
    let farrierItem = Object.assign([], farrierrRecordData?.farrierItems);
    farrierItem.push(INITIAL_FARRIER_ITEMS[0]);
    setFarrierRecordData({ ...farrierrRecordData, farrierItems: farrierItem });
  };

  const removeListItem = (index: number) => {
    let farrierItem = Object.assign([], farrierrRecordData?.farrierItems);
    if (farrierItem.length <= 1) {
      return;
    }
    farrierItem.splice(index, 1);
    setFarrierRecordData({ ...farrierrRecordData, farrierItems: farrierItem });
  };

  const handleChange = (index: number, e: any, fieldName: string) => {
    let farrierItems: any = Object.assign([], farrierrRecordData?.farrierItems);
    farrierItems[index][fieldName].value = e.target.value;
    setFarrierRecordData({ ...farrierrRecordData, farrierItems: farrierItems });
  };

  const handleMultiChange = (index: number, val: any, fieldName: string) => {
    let farrierItems: any = Object.assign([], farrierrRecordData?.farrierItems);
    farrierItems[index][fieldName].value = val;
    setFarrierRecordData({ ...farrierrRecordData, farrierItems: farrierItems });
  }

  const checkShoeingVal = (id: number) => {
    let ob = metaData?.shoeing_type?.find((element: any) => element.id == id);
    if (ob?.slug.includes("shoeing")) {
      return true;
    } else {
      return false;
    }
  };

  const changevalidation = (
    fieldName: string,
    index: number,
    validations: string
  ) => {
    let farrierItems: any = Object.assign([], farrierrRecordData?.farrierItems);
    farrierItems[index][fieldName].validation[0] = validations;
    setFarrierRecordData({ ...farrierrRecordData, farrierItems: farrierItems });
  };

  const handleSubmit = () => {
    if (validate(farrierrRecordData).isValidated === true) {
      let farrierItems: any = [];
      farrierrRecordData?.farrierItems.map((item: any) => {
        let ob: any = {};
        ob["horse_id"] = item.horse_id.value;
        ob["shoeing_type"] = item.shoeing_type.value;
        ob["shoeing_material"] = item.shoe_material.value;
        ob["amount"] = item.amount.value;

        farrierItems.push(ob);
      });

      let params: any = {
        farrier_id: farrierrRecordData.farrier_id.value,
        farrier: farrierrRecordData.farrier.value,
        date: farrierrRecordData.date.value,
        items: farrierItems,
      };

      setLoading(true);
      addBulkFarrier(params)
        .then((response: any) => {
          if (response.statusCode === SUCCESS_RESPONSE_CODE) {
            props.onHide();
            props.setrefreshlist();
          }
        })
        .catch((error: any) => { }).finally(() =>{
          setLoading(false);
        });
    } else {
      setIsFormValidated(true);
      setFarrierRecordError({ ...validate(farrierrRecordData)?.errors });
    }
  };

  const toggleChange = (selectType: string, input: string) => {
    if (selectType === 'select') {
      setData(`select_${input}`, `${input}_id`)
      setData(input, "");
    } else {
      setData(`select_${input}`, input)
      setData(`${input}_id`, "");
    }
  }
 
  return (
    <Modal {...rest} centered className="add-bulk-farrier-popup modal-824">
      <Modal.Header closeButton>
        <p className="tl">{t("Add Farrier Record")}</p>
      </Modal.Header>
      <Modal.Body>
        <div className="rm-pr-40">
          <Row>
            <Col sm={6} md={6}>
              <Datepicker
                label={`${t("Date")}*`}
                endDate={moment().format("YYYY-MM-DD")}
                onChange={dateChange}
                value={moment(farrierrRecordData?.date?.value)}
                error={
                  isFormValidated && farrierRecordError["date"]
                    ? farrierRecordError["date"]
                    : null
                }
              />
            </Col>
            <Col sm={6} md={6}>
              <InputSelectGroup
                label={`${t("Farrier")}*`}
                selectValue={metaData?.farrier}
                onSelectChange={(value: any) => {
                  setData("farrier_id", value);
                }}
                defaultSelect={farrierrRecordData?.farrier_id?.value}
                dropKey="id"
                dropVal="first_name"
                inputSelectToggle={FarrierToggle}
                setInputSelectToggle={(val: any) => {
                  setFarrierToggle(val);
                  toggleChange(val, "farrier")
                }}
                onInputChange={(e: any) => setData("farrier", e.target.value)}
                error={
                  isFormValidated &&
                    (farrierRecordError["farrier_id"] ||
                      farrierRecordError["farrier"])
                    ? farrierRecordError["farrier_id"] ||
                    farrierRecordError["farrier"]
                    : null
                }
              />
            </Col>
          </Row>
        </div>
        {farrierrRecordData.farrierItems?.map((item: any, index: number) => {
          return (
            <div className="repeate-box" key={index + "repeate-box"}>
              <div className="rm-pr-40">
                <hr />
                <Row>
                  <Col sm={6} md={6}>
                    <MultiSelectGroup
                      label={`${t("Horse")}*`}
                      value={metaData?.horse_list}
                      defaultSelect={item?.horse_id?.value}
                      dropKey="id"
                      dropVal="name"
                      onChange={(e: any) => {
                        handleMultiChange(index, e, "horse_id");
                      }}
                      error={
                        isFormValidated &&
                          farrierRecordError?.["farrierItems"]?.[index]?.[
                          "horse_id"
                          ]
                          ? farrierRecordError["farrierItems"][index][
                          "horse_id"
                          ]
                          : null
                      }
                    />
                  </Col>
                  <Col sm={6} md={6}>
                    <SelectGropus
                      label={`${t("Shoeing Type")}*`}
                      value={metaData?.shoeing_type}
                      defaultSelect={item?.shoeing_type.value}
                      onChange={(e: any) => {
                        handleChange(index, e, "shoeing_type");
                        if (checkShoeingVal(e.target.value)) {
                          changevalidation("shoe_material", index, "required");
                        } else {
                          changevalidation("shoe_material", index, "");
                        }
                      }}
                      dropKey="id"
                      dropVal="metadata"
                      error={
                        isFormValidated &&
                          farrierRecordError?.["farrierItems"]?.[index]?.[
                          "shoeing_type"
                          ]
                          ? farrierRecordError["farrierItems"][index][
                          "shoeing_type"
                          ]
                          : null
                      }
                    />
                  </Col>
                  {checkShoeingVal(item?.shoeing_type.value) && (
                    <Col sm={6} md={6}>
                      <SelectGropus
                        label={`${t("Shoe Material")}*`}
                        value={metaData?.shoeing_material}
                        defaultSelect={item?.shoe_material.value}
                        onChange={(e: any) =>
                          handleChange(index, e, "shoe_material")
                        }
                        dropKey="id"
                        dropVal="metadata"
                        error={
                          isFormValidated &&
                            farrierRecordError?.["farrierItems"]?.[index]?.[
                            "shoe_material"
                            ]
                            ? farrierRecordError["farrierItems"][index][
                            "shoe_material"
                            ]
                            : null
                        }
                      />
                    </Col>
                  )}
                </Row>
              </div>
              <div className="nrm-pr-40">
                <Row>
                  <Col sm={12} md={12}>
                    <div className="remove-btn-parent">
                      <Row>
                        <Col sm={6} md={6}>
                          <InputSelectMix
                            label={`${t("Amount")}`}
                            inputType="number"
                            inputValue={item?.amount?.value}

                            onInputChange={(e: any) =>
                              handleChange(index, e, "amount")
                            }
                            inputSecondValue={prefrence?.currency_value}
                            inputError={
                              isFormValidated &&
                                farrierRecordError?.["farrierItems"]?.[index]?.[
                                "amount"
                                ]
                                ? farrierRecordError["farrierItems"][index][
                                "amount"
                                ]
                                : null
                            }

                          />
                        </Col>
                      </Row>
                      {farrierrRecordData.farrierItems?.length > 1 && (
                        <div className="remove-btn">
                          <img
                            src={MinusIcon}
                            onClick={() => removeListItem(index)}
                            alt=""
                          />
                        </div>
                      )}
                    </div>
                  </Col>
                </Row>
              </div>
            </div>
          );
        })}

        <div className="add-row-btn" onClick={addListItem}>
          <img src={PlusIcon} alt="" />
          <p className="bm">{t("Add Record")}</p>
        </div>
      </Modal.Body>
      <Modal.Footer>
        <div className="rm-pr-40">
          <div className="footer-2-btn">
            <TextButton onClick={props.onHide}> {t("Cancel")}</TextButton>
            <CustomeButton bg="fill" onClick={handleSubmit}>
            {t("Add")} 
            </CustomeButton>
          </div>
        </div>
      </Modal.Footer>
    </Modal>
  );
}
