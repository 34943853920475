import type { DatePickerProps } from "antd";
import { t } from "i18next";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { Col } from "react-bootstrap";
import Modal from "react-bootstrap/Modal";
import { useDispatch, useSelector } from "react-redux";
import { API_STATUS } from "../../../Constant/Api";
import { SUCCESS_RESPONSE_CODE } from "../../../Constant/Status";
import { useLoading } from "../../../context/LoadingContext";
import { metaHorseListAction } from "../../../Redux/General/GeneralAction";
import { addEditHealthAction } from "../../../Redux/Horse/Health/SingleHealthAction";
import {
  ADD_HORSE_HEALTH_FAILD,
  ADD_HORSE_HEALTH_LONG,
  ADD_HORSE_HEALTH_REQUEST,
  ADD_HORSE_HEALTH_SUCCESS,
} from "../../../Redux/Horse/Health/SingleHorseType";
import validate from "../../../Utility/Validation";
import CustomeButton from "../../Common/CustomButton/CustomButton";
import Datepicker from "../../Common/Datepicker/Datepicker";
import InputGroups from "../../Common/InputGroups/InputGroups";
import MultiSelectGroup from "../../Common/MultiSelectGroup/MultiSelectGroup";
import SelectGropus from "../../Common/SelectGroups/SelectGroups";
import TextButton from "../../Common/TextButton/TextButton";

export default function AddVitalSignsPopup(props: any) {
  const INITIAL_VITAL_HEALTH_DATA: any = {
    form_type: {
      value: "vital_sign",
      validation: ["required"],
      errors: [t("Form Type is required.")],
    },
    horse_ids: {
      value: "",
      validation: [""],
      errors: [t("Horse is required.")],
    },
    temperature: {
      value: "",
      validation: ["required", "minNumber:-100"],
      errors: [t("Temperature is required."), t("Please enter Valid value")],
    },
    heartRate: {
      value: "",
      validation: ["required", "minNumber:0"],
      errors: [t("Heart Rate is required."), t("Please enter Valid value")],
    },
    respirationRate: {
      value: "",
      validation: ["required", "minNumber:0"],
      errors: [t("Respiration Rate is required."), t("Please enter Valid value")],
    },
    date: {
      value: moment().format("YYYY-MM-DD"),
      validation: ["required"],
      errors: [t("Date is required.")],
    },
    id: {
      value: "",
    },
  };
  const [healthData, setHealthData] = useState(INITIAL_VITAL_HEALTH_DATA);
  const [apiStatus, setApiStatus] = useState(API_STATUS.SUCCESS);
  const { setLoading } = useLoading();
  const [isFormValidated, setIsFormValidated] = useState(false);
  let demo: any = {};
  const [healthDataError, setHealthDataError] = useState(demo);
  const dispatch = useDispatch();



  const horseList = useSelector(
    (state: any) => state?.metaHorseListReducer?.data?.component
  );

  const dateChange: DatePickerProps["onChange"] = (date, dateString) => {
    let dateIs = date?.format("YYYY-MM-DD");
    setData("date", dateIs);
  };

  const prefrence = useSelector(
    (state: any) => state?.masterReducer?.data?.component?.preferance
  );

  const setData = (field: string, value: any) => {
    let horseDetails = Object.assign({}, healthData);
    horseDetails[field].value = value;
    setHealthData({ ...horseDetails });
  };

  useEffect(() => {
    setHealthDataError({ ...validate(healthData)?.errors });
  }, [healthData]);

  const submitHandler = () => {
    if (validate(healthData).isValidated === true) {
      let horseId = props.horseid;
      if (props.isedit == "false" && props?.isMultiple) {
        horseId = healthData?.horse_ids?.value;
      }
      let params = {
        form_type: healthData.form_type.value,
        horse_id: horseId,
        temperature: healthData.temperature.value,
        heartRate: healthData.heartRate.value,
        respirationRate: healthData.respirationRate.value,
        date: healthData.date.value,
        id: healthData.id.value,
      };
      addEditHealthAction(params);
    } else {
      setHealthDataError({ ...validate(healthData)?.errors });
      setIsFormValidated(true);
    }
  };

  useSelector((state: any) => {
    if (state?.singleHealthAddEditReducer?.type === ADD_HORSE_HEALTH_REQUEST) {
      if (apiStatus !== API_STATUS.LOADING) {
        setLoading(true);
        setApiStatus(API_STATUS.LOADING);
      }
    } else if (
      state?.singleHealthAddEditReducer?.data?.statusCode ===
        SUCCESS_RESPONSE_CODE &&
      state?.singleHealthAddEditReducer?.type === ADD_HORSE_HEALTH_SUCCESS
    ) {
      if (apiStatus !== API_STATUS.SUCCESS) {
        setLoading(false);
        setApiStatus(API_STATUS.SUCCESS);
        // navigate("/");
        props.onHide();
        props.setrefreshlist();
      }
    } else if (
      state?.singleHealthAddEditReducer?.type === ADD_HORSE_HEALTH_LONG
    ) {
      if (apiStatus !== API_STATUS.LONG) {
        setLoading(false);
        setApiStatus(API_STATUS.LONG);
      }
    } else if (
      state?.singleHealthAddEditReducer?.type === ADD_HORSE_HEALTH_FAILD
    ) {
      if (apiStatus !== API_STATUS.FAILED) {
        setLoading(false);
        setApiStatus(API_STATUS.FAILED);
      }
    }
  });

  useEffect(() => {
    if (props.isedit == "true") {
      if (props.healthdata) {
        setData("temperature", props?.healthdata?.temperature?.toString());
        setData("heartRate", props?.healthdata?.heartRate?.toString());
        setData(
          "respirationRate",
          props?.healthdata?.respirationRate?.toString()
        );
        setData("date", props?.healthdata?.withoutFormatedDate);
        setData("id", props?.healthdata?.id);
      }
    }
  }, [props?.healthdata]);

  useEffect(() => {
    if (props?.show === false) {
      setHealthDataError({});
      setHealthData(INITIAL_VITAL_HEALTH_DATA);
      setIsFormValidated(false);
    } else if (props?.isMultiple && props.isedit == "false") {
      const ob: any = Object.assign({}, healthData);
      ob["horse_ids"].validation[0] = "required";
      setHealthData({ ...ob });
    }
  }, [props?.show]);

  const { setrefreshlist ,isMultiple,healthdata, horseid, metaData , ...rest } = props;

  return (
    <Modal {...rest} centered className="add-vital-signs-popup modal-416">
      <Modal.Header closeButton>
        <p className="tl">
          {props.isedit == "true" ? t("Edit Vital Signs") : t("Add Vital Signs")} 
        </p>
      </Modal.Header>
      <Modal.Body>
        <div>
          {props.isedit == "false" && props?.isMultiple ? (
            <SelectGropus
              label={`${t("Horse")}*`}
              defaultSelect={healthData?.horse_ids?.value}
              value={metaData?.horse_list}
              dropKey="id"
              dropVal="name"
              onChange={(e: any) => {
                setData("horse_ids", e.target.value);
              }}
              error={
                isFormValidated && healthDataError["horse_ids"]
                  ? healthDataError["horse_ids"]
                  : null
              }
            />
          ) : null}

          <Datepicker
            label={`${t("Date")}*`}
            endDate={moment().format("YYYY-MM-DD")}
            onChange={dateChange}
            value={moment(healthData?.date?.value)}
          />
          <InputGroups
            label={`${t("Temperature")} (${prefrence?.temperatureUnit_value})*`}
            value={healthData?.temperature?.value}
            type="number"
            autoComplete="off"
            onChange={(e: any) => setData("temperature", e.target.value)}
            error={
              isFormValidated && healthDataError["temperature"]
                ? healthDataError["temperature"]
                : null
            }
          />
          <InputGroups
            label={`${t("Heart Rate (bpm)")}*`}
            value={healthData?.heartRate?.value}
            type="number"
            autoComplete="off"
            onChange={(e: any) => setData("heartRate", e.target.value)}
            error={
              isFormValidated && healthDataError["heartRate"]
                ? healthDataError["heartRate"]
                : null
            }
          />
          <InputGroups
            label={`${t("Respiration Rate (bpm)")}*`}
            value={healthData?.respirationRate?.value}
            type="number"
            autoComplete="off"
            // defaultValue={props.horseId}
            onChange={(e: any) => setData("respirationRate", e.target.value)}
            error={
              isFormValidated && healthDataError["respirationRate"]
                ? healthDataError["respirationRate"]
                : null
            }
          />
        </div>
      </Modal.Body>
      <Modal.Footer>
        <div className="footer-2-btn">
          <TextButton onClick={props.onHide}> {t("Cancel")}</TextButton>
          <CustomeButton bg="fill" onClick={() => submitHandler()}>
            {props.isedit == "true" ? t("Update") : t("Add")}
          </CustomeButton>
        </div>
      </Modal.Footer>
    </Modal>
  );
}
