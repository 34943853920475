import React from "react";

export default function WeaningIcon() {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M13.4265 12.913C13.4265 12.913 11.6004 13.9565 10.0352 15C8.46997 15.913 7.94823 17.0869 8.07867 17.6087C8.2091 18.1304 9.64389 18 10.5569 18.1304C14.2091 18.5217 14.6004 16.8261 14.2091 15.913"
        stroke="#919EAB"
        strokeWidth="1.5"
        strokeMiterlimit="13.3333"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M10.6874 6C10.6874 6 4.68735 7.95652 3.64387 8.47826C2.60039 8.86957 2.86126 10.6957 4.16561 11.087C5.46996 11.4783 7.81779 10.6957 9.51344 11.7391"
        stroke="#919EAB"
        strokeWidth="1.5"
        strokeMiterlimit="13.3333"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M9.51343 3C9.51343 3 18.5134 4.17391 20.9917 13.3043"
        stroke="#919EAB"
        strokeWidth="1.5"
        strokeMiterlimit="13.3333"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M13.4265 9.78265C13.4265 9.78265 19.8178 14.2174 16.296 21"
        stroke="#919EAB"
        strokeWidth="1.5"
        strokeMiterlimit="13.3333"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}
