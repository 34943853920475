import React from "react";

export default function Dieticon() {
  return (
    <svg
      width="32"
      height="32"
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M7.6 26.5333C7.6 26.5333 8.4 23.7333 5.86667 19.7333C5.86667 19.7333 9.86667 19.2 10.8 26.5333C10.8 26.5333 11.7333 19.2 8 13.2C8 13.2 13.3333 12.1333 14.5333 26.5333C14.5333 26.5333 12.2667 9.59999 20.4 5.33333C20.4 5.33333 17.2 10.4 17.8667 26.5333C17.8667 26.5333 15.7333 17.6 23.6 12.8C23.6 12.8 20.2667 18.9333 20.5333 26.5333C20.5333 26.5333 21.7333 20.1333 26.1333 18.6667C26.1333 18.6667 22.9333 22.8 23.8667 26.5333"
        stroke="#E8E5E2"
        strokeWidth="1.5"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}
