import type { DatePickerProps } from "antd";
import { t } from "i18next";
import moment from "moment";
import React, { useEffect, useState } from "react";
import Modal from "react-bootstrap/Modal";
import { useSelector } from "react-redux";
import { DIET_ACTIVITY } from "../../../Constant/General";
import { calculatePercent } from "../../../Utility/General";
import InputGroups from "../../Common/InputGroups/InputGroups";
import SelectGropus from "../../Common/SelectGroups/SelectGroups";

export default function DietCalculatorPopup(props: any) {

  type singleActivity = {
    id:string,
    name:string,
    concentrate:number,
    forage:number,

  }

  const [horseWeight, setHorseWeight] = useState();
  const [selectedActivity, setSelectedActivity] = useState('');
  const [resultValue, setResultValue] : any = useState({
    feed: "0.00",
    concentrate: "0.00",
    forage: "0.00",
  });

  const preferance: any = useSelector(
    (state: any) => state?.masterReducer?.data?.component?.preferance
  );

  const activities :singleActivity[] = [
    {
      id: "1",
      name: t("Maintenance"),
      concentrate: DIET_ACTIVITY.maintainace.concentrate,
      forage: DIET_ACTIVITY.maintainace.forage,
    },
    {
      id: "2",
      name: t("Breed/Growth"),
      concentrate: DIET_ACTIVITY.breed_groth.concentrate,
      forage: DIET_ACTIVITY.breed_groth.forage,
    },
    {
      id: "3",
      name: t("Conditioning"),
      concentrate: DIET_ACTIVITY.conditioning.concentrate,
      forage: DIET_ACTIVITY.conditioning.forage,
    },
    {
      id: "4",
      name: t("Light Work"),
      concentrate: DIET_ACTIVITY.light_work.concentrate,
      forage: DIET_ACTIVITY.light_work.forage,
    },
    {
      id: "5",
      name: t("Medium Work"),
      concentrate: DIET_ACTIVITY.medium_work.concentrate,
      forage: DIET_ACTIVITY.medium_work.forage,
    },
    {
      id: "6",
      name: t("Heavy Work"),
      concentrate: DIET_ACTIVITY.heavy_work.concentrate,
      forage: DIET_ACTIVITY.heavy_work.forage,
    },
  ];

  useEffect(() => {
    if(horseWeight && selectedActivity){
    const feed = calculatePercent(horseWeight , DIET_ACTIVITY.feed_percentage);
    const currentActivity :any = activities.find((item:any) => item?.id === selectedActivity );
    const concentrate = calculatePercent(feed , currentActivity?.concentrate);
    const forage =  calculatePercent(feed , currentActivity?.forage);
    setResultValue({
      feed,
      concentrate,
      forage
    })
    }else{
      setResultValue({
        feed:"0.00",
        concentrate:"0.00",
        forage:"0.00"
      })
    }
  }, [horseWeight, selectedActivity]);

  return (
    <Modal {...props} centered className="diet-calculator-popup modal-416">
      <Modal.Header closeButton>
        <p className="tl">{t("Diet Calculator")}</p>
      </Modal.Header>
      <Modal.Body>
        <div>
          <InputGroups
            label={`${t("Horse Weight")} (${preferance?.weightUnit_value})*`}
            value={horseWeight}
            onChange={(e: any) => setHorseWeight(e.target.value)}
          />
          <SelectGropus
            label={`${t("Activity")}*`}
            value={activities}
            defaultSelect={selectedActivity}
            dropKey="id"
            dropVal="name"
            onChange={(e: any) => setSelectedActivity(e.target.value)}
          />
        </div>
        <p className="tm">
          {t("Total feed needed per day")}: {resultValue.feed}{" "}
          {preferance?.weightUnit_value}
        </p>
        <p className="tm">
          {t("Concentrate Required per day")}: {resultValue.concentrate}{" "}
          {preferance?.weightUnit_value}
        </p>
        <p className="tm">
          {t("Forage Required per day")}: {resultValue.forage} {preferance?.weightUnit_value}
        </p>
        <span className="bl">
          {/* {t("Select a breeding date to see the estimated foaling date range.")} */}
        </span>
      </Modal.Body>
      {/* <Modal.Footer>
        <div className="footer-2-btn">
          <TextButton> {t("Cancel")}</TextButton>
          <CustomeButton bg="fill">Add</CustomeButton>
        </div>
      </Modal.Footer> */}
    </Modal>
  );
}
