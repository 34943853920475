import { useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import ImportDataIcon from "../../../Assets/img/import-data.svg";
import { useLoading } from "../../../context/LoadingContext";
import { SidebarContext } from "../../../context/SideBarContext";
import {
  sampleHorseImport,
  uploadHorseImport,
} from "../../../Redux/NonRedux/importData";
import { isFileValidated } from "../../../Utility/General";
import CustomeButton from "../../Common/CustomButton/CustomButton";
import FileUpload from "../../Common/FileUpload/FileUpload";
import errorIcon from "../../../Assets/img/error-icon.svg";
import {t} from 'i18next'



export default function ImportDataPage() {
  
  const [selectedValue, setSelectedValue] = useState("");
  const [fileDataUpload, setFileDataUpload] = useState("");
  const [errorLink, setErrorLink] = useState("");
  const [resetInput, setResetInput] = useState(false);
  const { setSidebar }: any = useContext(SidebarContext);
  const allowedFormate = ["xlsx", "xls", "csv" , "heic" , "heif"];
  const allowedFileSizeInMb = 4;

  const { setLoading } = useLoading();
  const fileChange = (e: any) => {
    let file = e.target.files[0];
    if (isFileValidated(file, allowedFormate, allowedFileSizeInMb)) {
      setSelectedValue(e.target.value);
      setFileDataUpload(e.target.files[0]);
    } else {
      e.target.value = "";
    }
  };

  const { t } = useTranslation();
  const handleSampleDowload = () => {
    const formData = new FormData();
    sampleHorseImport(formData, "sampleHorseImport");
  };

  const uploadFile = async () => {
    setLoading(true);
    const formData = new FormData();
    formData.append("file_input", fileDataUpload);

    uploadHorseImport(formData)
      .then((data: any) => {
        if (data.contentType != "application/json") {
          setErrorLink(URL.createObjectURL(data.raw));
        } else {
          setErrorLink("");
        }
        setSelectedValue("");
        setFileDataUpload("");
        setResetInput(true);
      })
      .finally(() => {
        setLoading(false);
      });
  };
  useEffect(() => {
    setSidebar("");
  }, []);

  return (
    <div className="import-data-page">
      {/* Page Title Start */}
      <div className="page-title-wrapper">
        <div className="title-first-menu">
          <div className="title-icon-text">
            <img src={ImportDataIcon} alt="" />
            <h5>{t("Import Data")}</h5>
          </div>
        </div>
      </div>
      {/* Page Title End */}
      <div className="page-content">
        <div className="id-wrapper">
          <label className="bl">
            {t("Import horses from Excel spreadsheets.")}
          </label>
          <FileUpload
            onChange={(e: any) => {
              fileChange(e);
            }}
            accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
            value={t("Import from Excel")}
            removeFile={() => {
              setFileDataUpload("");
              setSelectedValue("");
            }}
            resetInput={resetInput}
            setResetInput={setResetInput}
            selectedValue={selectedValue}
            fileType={`${t("Use an .xls, .xlsx or csv")}`}
          />
          <CustomeButton
            bg="fill"
            onClick={() => uploadFile()}
            disabled={!fileDataUpload}
          >
            {t("Import Data")}
          </CustomeButton>

          <div className="bottom-msgs">
            {errorLink ? (
              <div className="templete-error-box">
                <img src={errorIcon} alt="" />
                <div className="te-content">
                  <p className="tm error-color"> {t("Error in Uploaded File")} </p>
                  <span className="bm">
                    {t("dowload_error_message")}
                    &nbsp;
                    <a
                      className="a-bm"
                      href={errorLink}
                      download="ErrorData.xlsx"
                    >
                      {t("Download")}
                    </a>
                  </span>
                </div>
              </div>
            ) : (
              ""
            )}
            <div className="download-exl-box">
              <span className="bl">{t("import_horse_template")}</span>
              <a className="a-ll" download onClick={handleSampleDowload}>
                {t("Download Template")}
              </a>
              {/* <CustomeButton download onClick={handleSampleDowload}>{t("Download Template")}</CustomeButton> */}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
