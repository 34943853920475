import React, { useContext, useEffect, useState } from "react";
//import { Col, Dropdown, Row } from "react-bootstrap";
import AddPlusIcon from "../../Assets/icon/AddPlusIcon";
import BoardingPlansIcon from "../../Assets/img/Boarding-Plans.svg";
import BoardingIcon from "../../Assets/img/Boarding-primary.svg";
import ExportIcon from "../../Assets/img/export.svg";
import binIcon from "../../Assets/img/bin.svg";
import pencilIcon from "../../Assets/img/pencil.svg";
// import { HORSE } from "../../Constant/Route";
import CustomeButton from "../Common/CustomButton/CustomButton";
import SearchBox from "../Common/SearchBox/SearchBox";
import Numbernamecard from "../Layouts/Numbernamecard/Numbernamecard";
import Pagination from "../Layouts/Pagination/Pagination";
import AddBoardingHorsePopup from "../Popups/AddBoardingHorsePopup/AddBoardingHorsePopup";
import BoardingPlanPopup from "../Popups/BoardingPlanPopup/BoardingPlanPopup";
import { useDispatch, useSelector } from "react-redux";
import {
  boardingEnd,
  destroyBoarding,
  getBoardingList,
  getSingleBoarding,
} from "../../Redux/NonRedux/boarding";
import { getBoardingPlanList } from "../../Redux/Boarding/BoardingAction";
import { SUCCESS_RESPONSE_CODE } from "../../Constant/Status";
import ConfirmPopup from "../Popups/ConfirmPopup/ConfirmPopup";
import { t } from "i18next";
import NoDataFound from "../Common/NoDataFound/NoDataFound";
import { useLoading } from "../../context/LoadingContext";
import { SidebarContext } from "../../context/SideBarContext";
import { DATATABLE, PERMISSIONS, SIDEBAR } from "../../Constant/General";
import TableHead from "../Common/DataTable/TableHead/TableHead";
import { exportDocument } from "../../Redux/globallActions";
import { END_POINTS } from "../../Constant/Api";
import { checkRoutePermission, getDefaultSorting, getSessionItem } from "../../Utility/General";
//import Filtericon from "../../Assets/icon/Filtericon";
//import InputGroups from "../Common/InputGroups/InputGroups";
//import SelectGropus from "../Common/SelectGroups/SelectGroups";
import Filter from "../Filter/Filter";
import moment from "moment";
import HandIcon from "../../Assets/img/hand.svg";
import { upgradePopupAction } from "../../Redux/General/GeneralAction";
import { getListMetaDataApi } from "../../Redux/NonRedux/general";
import { Col, Row } from "antd";

export default function Boarding(props:any) {
  const defaultSorting : any = getDefaultSorting(DATATABLE.BOARDING);
  const tabValue: any = {
    boarding_horse: "",
    num_of_owner: "",
    boarding_income: "",
  };

  const tableHead = [
    {
      label: t("Horse"),
      className: "tm",
      key: "name",
    },
    {
      label: t("Microchip Number"),
      className: "tm",
      key: "micro_chip_number",
    },
    {
      label: t("Date of Entry"),
      className: "tm",
      key: "date_entry",
    },
    {
      label: t("Boarding Type"),
      className: "tm",
      key: "boarding_type",
    },

    {
      label: t("Owner"),
      className: "tm",
      key: "owner",
    },
    {
      label: t("Contact Number"),
      className: "tm",
      key: "contact_number",
    },
    {
      label: t("Amount"),
      className: "tm",
      key: "amount",
    },
    {
      label: t("Action"),
      className: "tm",
      key: "",
      headClass: "text-right",
    },
  ];

  const [BoardingPlanModalShow, setBoardingPlanModalShow] =
    React.useState(false);
  const [AddBoardingHorseModalShow, setAddBoardingHorseModalShow] =
    React.useState(false);

  const [editData, setEditData] = React.useState<any>({});

  const [isEdit, setIsEdit] = React.useState<boolean>(false);
  const [refreshList, setRefreshList] = React.useState(false);
  const [confirmPopupShow, setConfirmPopupShow] = useState(false);
  const [confirmBoardingEnd, setConfirmBoardingEnd] = useState(false);
  const [endBoardingId, setEndBoardingId] = useState(0);
  const [deletableRecords, setDeletableRecords] = useState<any>([]);

  const [boardingList, setBoardingList] = useState<any>([]);
  const [recordsTotal, setRecordsTotal] = React.useState(1);
  const [currentPage, setCurrentPage] = React.useState(1);
  const [currentPageSize, setCurrentPageSize] = React.useState(10);
  const [tabValueData, setTabValueData] = useState(tabValue);
  const { setLoading } = useLoading();
  //const [checkedRec, setCheckedRec] = useState<number[]>([]);
  const [searchText, setSearchText] = React.useState();
  const [sortByField, setSortByField] = useState(defaultSorting?.key);
  const [sortType, setSortType] = useState(defaultSorting?.type);
  const [orderList, setOrderList] = useState<any>([]);
  const [filterList, setFilterList] = useState<any>([]);
  const [filteredValue, setFilteredValue] = useState<any>({});
  const [exportParams, setExportParams] = useState<any>({});
  const { setSidebar }: any = useContext(SidebarContext);
  const dispatch = useDispatch();
  const [metaData, setMetaData] = useState({});

  const prefrence = useSelector(
    (state: any) => state?.masterReducer?.data?.component?.preferance
  );
  const modules = useSelector(
    (state: any) => state?.masterReducer?.data?.component?.modules
  );

  const listApiCall = () => {
    setLoading(true);
    let params: any = {
      search: searchText,
      page: currentPage,
      limit: currentPageSize,
    };
    if (sortByField && sortType) {
      params["sort"] = {
        column: orderList[sortByField] ? orderList[sortByField] : defaultSorting?.value ,
        type: sortType,
      };
    }
    if (filteredValue && Object.keys(filteredValue)?.length) {
      params["filter"] = filteredValue;
    }
    setExportParams(params);
    getBoardingList(params)
      .then((response: any) => {
        if (response?.statusCode === SUCCESS_RESPONSE_CODE) {
          setBoardingList(response?.component?.listing);
          let tabVal: any = tabValueData;
          tabVal.boarding_horse = response?.component?.total?.BoardingHorses;
          tabVal.num_of_owner = response?.component?.total?.BoardingOwner;
          tabVal.boarding_income = response?.component?.total?.BoardingIncome;
          setTabValueData(tabVal);
          setRecordsTotal(response?.component?.recordsFiltered);
          setOrderList(response?.component?.orderlist);
          setFilterList(response?.component?.filterlist);
        }
      })
      .catch(() => {})
      .finally(() => {
        setLoading(false);
      });
  };

  const editHandler = (id: number) => {
    let params = {
      id: id,
    };
    setLoading(true);
    getSingleBoarding(params)
      .then((response: any) => {
        if (response?.statusCode === SUCCESS_RESPONSE_CODE) {
          setIsEdit(true);
          setEditData(response?.component);
          setAddBoardingHorseModalShow(true);
        }
      })
      .catch((error) => {})
      .finally(() => {
        setLoading(false);
      });
  };

  const deleteHandler = (id: number) => {
    let params = {
      id: deletableRecords,
    };

    setLoading(true);
    destroyBoarding(params)
      .then((response: any) => {
        if (response.statusCode === SUCCESS_RESPONSE_CODE) {
          setRefreshList(true);
          setConfirmPopupShow(false);
        }
      })
      .catch((error) => {})
      .finally(() => {
        setLoading(false);
      });
  };

  const deleteNow = (id: number) => {
    let ids = deletableRecords;
    ids.push(id);
    setDeletableRecords(ids);
    setConfirmPopupShow(true);
  };

  const boardingPlanList = useSelector(
    (state: any) => state?.boardingPlanListReducer?.data?.component?.listing
  );

  const getMetaData = () => {
    setLoading(true);  
    getListMetaDataApi(END_POINTS.metaBoarding)
      .then((response: any) => {
        setMetaData(response?.component);
      })
      .catch((error: any) => {})
      .finally(() => {
        setLoading(false);
      });
  };

  useEffect(() => {
    setSidebar(SIDEBAR.BOARDING);
    let params = {};
    getBoardingPlanList(params);
    getMetaData();
  }, []);

  useEffect(() => {
    listApiCall();
  }, [currentPage, currentPageSize, sortByField, sortType, filteredValue]);

  useEffect(() => {
    if (searchText != null) {
      const searchTimeOut = setTimeout(() => {
        if (currentPage === 1) {
          listApiCall();
        } else {
          setCurrentPage(1);
        }
      }, 500);
      return () => clearTimeout(searchTimeOut);
    }
  }, [searchText]);

  useEffect(() => {
    if (refreshList) {
      listApiCall();
      setRefreshList(false);
    }
  }, [refreshList]);

  const handleExport = () => {
    if(!checkRoutePermission(PERMISSIONS?.EXPORT_FEATURE , modules)){
      (upgradePopupAction({show:true}));
      return;
    }
    setLoading(true);
    exportDocument(END_POINTS.boardingExport, exportParams, "boarding")
      .then(() => {})
      .finally(() => {
        setLoading(false);
      });
  };

  const endBoardingNow = (id: number) => {
    setEndBoardingId(id);
    setConfirmBoardingEnd(true);
  };

  const endBoardingHandler = () => {
    setLoading(true);
    boardingEnd({ id: endBoardingId })
      .then((response: any) => {
        setConfirmBoardingEnd(false);
        listApiCall();
      })
      .catch((error) => {})
      .finally(() => {
        setLoading(false);
      });
  };

  // const handleSelectAll = (e: any) => {
  //   if (e.target.checked) {
  //     var checkedRecData: any[] = Object.assign([], checkedRec);
  //     boardingList?.map((li: any) => {
  //       if (!checkedRecData.includes(li.id)) {
  //         checkedRecData.push(li.id);
  //       }
  //     });
  //     setCheckedRec(checkedRecData);
  //   } else {
  //     setCheckedRec([]);
  //   }
  // };

  // const checkSingleRec = (e: any, val: number) => {
  //   //
  //   var checkedRecData: number[] = Object.assign([], checkedRec);
  //   if (e.target.checked) {
  //     checkedRecData.push(val);
  //   } else {
  //     checkedRecData = checkedRecData.filter((c: number) => c !== val);
  //   }
  //   setCheckedRec(checkedRecData);
  // };

  return (
    <>
      <div className="boarding-page">
        {/* Page Title Start */}
        <div className="page-title-wrapper">
          <div className="title-first-menu">
            <div className="title-icon-text">
              <img src={BoardingIcon} alt="" />
              <h5>{t("Boarding")}</h5>
            </div>
            <div className="title-right-icons">
              <a
                className="unset-links"
                onClick={() => {
                  setIsEdit(false);
                  setEditData({});
                  setBoardingPlanModalShow(true);
                }}
              >
                <img src={BoardingPlansIcon} alt="" />
              </a>
           
                <a className="unset-links" onClick={handleExport}>
                  <img src={ExportIcon} alt="" />
                </a>
            
            </div>
          </div>
        </div>
        {/* Page Title End */}

        <div className="page-content">
          <div className="page-tab">
            <Row>
              <Col sm="6" md="4">
                <Numbernamecard
                  number={tabValueData?.boarding_horse}
                  name={t("Total Boarding Horses")}
                />
              </Col>
              <Col sm="6" md="4">
                <Numbernamecard
                  number={tabValueData?.num_of_owner}
                  name={t("Owners")}
                />
              </Col>
              <Col sm="6" md="4">
                <Numbernamecard
                  number={`${tabValueData?.boarding_income} ${prefrence?.currency_value}`}
                  name={t("Total Boarding Income")}
                />
              </Col>
            </Row>
          </div>
          <div className="data-wrapper">
            <div className="data-header">
              <SearchBox
                placeholder={t("Search Horse...")}
                onChange={(e: any) => setSearchText(e.target.value)}
                value={searchText}
              />

              <div className="btn-wrapper f-fb">
                {/* <CustomeButton>
                    <Filtericon />
                    <p>Filter</p>
                  </CustomeButton> */}
                <Filter
                  filterList={filterList}
                  setFilteredValue={setFilteredValue}
                  setCurrentPage={setCurrentPage}
                />
                <CustomeButton
                  bg="fill"
                  onClick={() => {
                    setIsEdit(false);
                    setEditData({});
                    setAddBoardingHorseModalShow(true);
                  }}
                >
                  <AddPlusIcon />
                  <p>{t("Add")}</p>
                </CustomeButton>
              </div>
            </div>
            <div className="data-body">
              <div className="img-responsive-table">
                <table>
                  <TableHead
                    data={tableHead}
                    orderList={orderList}
                    sortByField={sortByField}
                    sortType={sortType}
                    setSortType={setSortType}
                    setSortByField={setSortByField}
                  />
                  <tbody>
                    {boardingList &&
                      boardingList.length > 0 &&
                      boardingList.map((item: any, index: number) => (
                        <tr key={index + "myhorse-tbl"}>
                          {/* <td className="ck-wrapper"> */}
                          {/* <OnlyCk
                              checked={checkedRec.includes(item?.id)}
                              onChange={(e: any) => checkSingleRec(e, item?.id)}
                            /> */}
                          {/* </td> */}
                          {/* <td className="img-wrapper"> */}
                          {/* <img src={item.img} alt="" className="horse-img" /> */}
                          {/* </td> */}
                          <td>
                            <div className="bl">
                              {/* <img src={item.icon} alt="" /> */}
                              {/* <Link to={HORSE} className="a-bl"> */}
                              {item.horse}
                              {/* </Link> */}
                            </div>
                          </td>
                          <td>
                            <p className="bl">{item?.micro_chip_number}</p>
                          </td>
                          <td>
                            <p className="bl">{item?.date_entry_formated}</p>
                          </td>
                          <td>
                            <p className="bl">{item?.boarding_type}</p>
                          </td>
                          <td>
                            <p className="bl">{item?.owner}</p>
                          </td>
                          <td>
                            <p className="bl">{item?.contact_number}</p>
                          </td>
                          <td>
                            <p className="bl">
                              {item?.amount} {prefrence?.currency_value}{" "}
                            </p>
                          </td>
                          <td>
                            <div className="icon-wrapper">
                              {(item?.end_entry
                                ? moment().isBetween(
                                    moment(item?.date_entry),
                                    moment(item?.end_entry)
                                  )
                                : moment(item?.date_entry).isBefore()) && (
                                <a>
                                  <img
                                    src={HandIcon}
                                    alt=""
                                    onClick={() => {
                                      endBoardingNow(item?.id);
                                    }}
                                  />
                                </a>
                              )}
                              <a>
                                <img
                                  src={pencilIcon}
                                  onClick={() => editHandler(item?.id)}
                                  alt=""
                                />
                              </a>
                              <a>
                                <img
                                  src={binIcon}
                                  onClick={() => deleteNow(item?.id)}
                                  alt=""
                                />
                              </a>
                            </div>
                          </td>
                        </tr>
                      ))}
                  </tbody>
                </table>
              </div>
              {boardingList?.length > 0 ? (
                <Pagination
                  results={recordsTotal}
                  currentPage={currentPage}
                  setCurrentPage={setCurrentPage}
                  currentLength={currentPageSize}
                  setCurrentPageSize={setCurrentPageSize}
                />
              ) : (
                <NoDataFound />
              )}
            </div>
          </div>
        </div>
      </div>

      <BoardingPlanPopup
        show={BoardingPlanModalShow}
        onHide={() => {
          setBoardingPlanModalShow(false);
        }}
        boardingplanlist={boardingPlanList}
      />

      <AddBoardingHorsePopup
        show={AddBoardingHorseModalShow}
        onHide={() => {
          setAddBoardingHorseModalShow(false);
        }}
        boardingplanlist={boardingPlanList?.filter((item:any) => item?.status == "1")}
        isedit={isEdit ? 1 : 0}
        editdata={editData}
        metaData={metaData}
        setrefreshlist={() => {
          setRefreshList(true);
          getMetaData();
        }}
      />

      <ConfirmPopup
        heading={t("End Boarding")}
        message={t("Are you sure you want to End boarding for this horse?")}
        show={confirmBoardingEnd}
        onHide={() => {
          setEndBoardingId(0);
          setConfirmBoardingEnd(false);
        }}
        confirm={endBoardingHandler}
      />

      <ConfirmPopup
        heading={t("Delete")}
        message={t("Are you sure to delete this record?")}
        show={confirmPopupShow}
        onHide={() => {
          setConfirmPopupShow(false);
          setDeletableRecords([]);
        }}
        confirm={deleteHandler}
      />
    </>
  );
}
