import type { DatePickerProps } from "antd";
import { t } from "i18next";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { Col, Row } from "react-bootstrap";
import Modal from "react-bootstrap/Modal";
import { useDispatch, useSelector } from "react-redux";
import { useLoading } from "../../../context/LoadingContext";
import {
  locationListAction,
  metaHorseListAction,
} from "../../../Redux/General/GeneralAction";
import {
  getInstantContact,
  getInstantMeta,
} from "../../../Redux/globallActions";
import { addEditTransport } from "../../../Redux/NonRedux/transportApi";
import {
  getFileNamefromLink,
  getFileNamefromPath,
  isFileValidated,
} from "../../../Utility/General";
import validate from "../../../Utility/Validation";
import CustomeButton from "../../Common/CustomButton/CustomButton";
import Datepicker from "../../Common/Datepicker/Datepicker";
import FileUpload from "../../Common/FileUpload/FileUpload";
import InputGroups from "../../Common/InputGroups/InputGroups";
import InputSelectGroup from "../../Common/InputSelectGroup/InputSelectGroup";
import InputSelectMix from "../../Common/InputSelectMix/InputSelectMix";
import MultiSelectGroup from "../../Common/MultiSelectGroup/MultiSelectGroup";
import SelectGropus from "../../Common/SelectGroups/SelectGroups";
import TextareaGroups from "../../Common/TextareaGroups/TextareaGroups";
import TextButton from "../../Common/TextButton/TextButton";
import ToggleButton from "../../Common/ToggleButton/ToggleButton";

export default function AddTransportationPopup(props: any) {
  const INITIAL_TRANSPORT_DATA: any = {
    from_id: {
      value: "",
      validation: ["requiredIf:toggle_from:from_id"],
      errors: [t("From Location is required.")],
    },
    boarding: {
      value: "",
      validation: ["requiredIf:toggle_from:boarding", "letters"],
      errors: [t("From Location is required."), t("Incorrect format")],
    },
    to_id: {
      value: "",
      validation: ["requiredIf:toggle_to:to_id"],
      errors: [t("To Location is required.")],
    },
    dropping: {
      value: "",
      validation: ["requiredIf:toggle_to:dropping", "letters"],
      errors: [t("To Location is required."),t("Incorrect format")],
    },
    departure_date: {
      value: moment().format("YYYY-MM-DD"),
      validation: ["required"],
      errors: [t("Departure date is required.")],
    },
    return_date: {
      value: moment().format("YYYY-MM-DD"),
      validation: ["requiredIf:round_trip:1", "dateLessThan:departure_date"],
      errors: [t(
        "Return date is required."),
        t("Return date should be greater than departure date."),
      ],
    },
    round_trip: {
      value: "1",
      validation: ["required"],
      errors: [t("Trip Type is required.")],
    },
    transport_type: {
      value: "",
      validation: ["required"],
      errors: [t("Transport Type is required.")],
    },
    comment: {
      value: "",
      validation: [""],
      errors: [t("Comment is required.")],
    },
    amount: {
      value: "",
      validation: [""],
      errors: [t("Amount is required.")],
    },
    transporter_id: {
      value: "",
      validation: [],
      errors: [],
    },
    transporter_name: {
      value: "",
      validation: ["letters"],
      errors: [t("Incorrect format")],
    },
    toggle_from: {
      value: "from_id",
    },
    toggle_to: {
      value: "to_id",
    },
    toggle_transport: {
      value: "transporter_id",
    },
    horse_id: {
      value: "",
      validation: ["requiredIf:form_type:add"],
      errors: [t("Horse is Required.")],
    },
    id: {
      value: "",
    },
    form_type: {
      value: "add",
    },
  };

  const [transportData, setTransportData] = useState(INITIAL_TRANSPORT_DATA);
  const [transportDataError, setTransportDataError] = useState<any>({});
  const [isFormValidated, setIsFormValidated] = useState(false);
 

  const dChange: DatePickerProps["onChange"] = (date, dateString) => {
    let dateIs = date?.format("YYYY-MM-DD");
    setData("departure_date", dateIs);
  };

  const rChange: DatePickerProps["onChange"] = (date, dateString) => {
    let dateIs = date?.format("YYYY-MM-DD");
    setData("return_date", dateIs);
  };

  const toggleBtnValue = [
    { name: t("Yes"), value: "1" },
    { name:t("No"), value: "0" },
  ];

  const [fileDataName, setFileDataName] = useState<any>(t("Upload Documents"));
  const [fileData, setFileData] = useState<any>(t("Upload Documents"));
  const [removeDoc, setRemoveDoc] = useState<any>(0);

  const [fileDataSelected, setFileDataSelected] = useState("");
  
  const { setLoading } = useLoading();
  const allowedFormate = ["jpg", "png", "pdf", "docx" , "heic" , "heif", "jpeg"];
  const allowedFileSizeInMb = 3;

  const fileChange = (e: any) => {
    let file = e.target.files[0];
    if (isFileValidated(file, allowedFormate, allowedFileSizeInMb)) {
      let fileName = getFileNamefromPath(e.target.value);
      setFileDataSelected(fileName);
      setFileData(e.target.files[0]);
      if (props.isedit) {
        setRemoveDoc(1);
      }
    } else {
      e.target.value = "";
    }
  };

  const checkExistFile = () => {
    if (props.isedit) {
      setRemoveDoc(1);
      setFileDataSelected("");
    } else {
      setFileData("");
      setFileDataSelected("");
    }
  };

  const [FromToggle, setFromToggle] = useState("select");
  const [ToToggle, setToToggle] = useState("select");
  const [TranspoeterToggle, setTranspoeterToggle] = useState("select");

  const prefrence = useSelector(
    (state: any) => state?.masterReducer?.data?.component?.preferance
  );


  useEffect(() => {
    setTransportDataError({ ...validate(transportData)?.errors });
  }, [transportData]);

  const setData = (field: string, value: any, key: any = "") => {
    let trpDetails = Object.assign({}, transportData);
    if (key) {
      trpDetails[field][key] = value;
    } else {
      trpDetails[field].value = value;
    }
    setTransportData({ ...trpDetails });
  };

  const setToggleData = (type: any, value: any) => {
    if (type == "from") {
      setFromToggle(value);
      if (value == "input") {
        setData("toggle_from", "boarding");
        setData("from_id", "");
      } else {
        setData("toggle_from", "from_id");
        setData("boarding", "");
      }
    } else if (type == "to") {
      setToToggle(value);
      if (value == "input") {
        setData("toggle_to", "dropping");
        setData("to_id", "");
      } else {
        setData("toggle_to", "to_id");
        setData("dropping", "");
      }
    } else if (type == "transporter") {
      setTranspoeterToggle(value);
      if (value == "input") {
        setData("toggle_transport", "transporter_name");
        setData("transporter_id", "");
      } else {
        setData("toggle_transport", "transporter_id");
        setData("transporter_name", "");
      }
    }
  };

  const dispatch = useDispatch();


  const submitHandler = () => {
    if (validate(transportData).isValidated === true) {
      // let horseId = props.horseid;
      const formData = new FormData();
      Object.keys(transportData).forEach((element) => {
        if (transportData[element].value) {
          if (element == "horse_id") {
            formData.append(element + "[]", transportData[element].value);
          } else {
            formData.append(element, transportData[element].value);
          }
        }
        else{
          formData.append(element, '');
        }
      });
      formData.append("round_trip", transportData["round_trip"].value);
      formData.append("document", fileData);
      formData.append("remove_doc", removeDoc);
      let type = "add";
      if (props.isedit == "true") {
        type = "edit";
      }
      setLoading(true);
      addEditTransport(formData, type)
        .then((response: any) => {
          props.onHide();
          props.setrefreshlist();
        })
        .finally(() => {
          setLoading(false);
        });
    } else {
      setTransportDataError({ ...validate(transportData)?.errors });
      setIsFormValidated(true);
    }
  };

  useEffect(() => {
    if (props.isedit == "true") {
      if (props.editdata) {
        setData("form_type", "edit");
        setData("from_id", props?.editdata?.from_id.toString());
        setData("to_id", props?.editdata?.to_id.toString());
        setData("transporter_id", props?.editdata?.transporter_id?.toString());
        setData(
          "amount",
          props?.editdata?.amount ? props?.editdata?.amount : ""
        );
        setData("round_trip", props?.editdata?.round_trip_id);
        setData("transport_type", props?.editdata?.transport_type);
        setData("departure_date", props?.editdata?.departure_date_unformated);
        setData("return_date", props?.editdata?.return_date_unformated);
        setData(
          "comment",
          props?.editdata?.comment ? props?.editdata?.comment : ""
        );
        setData("id", props?.editdata?.id);

        if (props?.editdata?.document) {
          let document = props?.editdata?.document;
          document = getFileNamefromLink(document);
          setFileDataSelected(document);
        }
      }
    } else {
      setFileDataSelected("");
      setFileData("");
      setTransportData(INITIAL_TRANSPORT_DATA);
    }
    setIsFormValidated(false);
  }, [props?.editdata]);

  console.log(removeDoc , "removeDoc")

  useEffect(() => {
    if (props?.show === false) {
      setRemoveDoc(0);
      setTransportDataError({});
      setTransportData(INITIAL_TRANSPORT_DATA);
      setIsFormValidated(false);
      setTranspoeterToggle("select");
      setFromToggle("select");
      setToToggle("select");
      setFileData("");
      setFileDataSelected("");
    }
  }, [props?.show]);

  const { setrefreshlist, editdata, metaData, ...rest } = props;



  const roundTripChange = (e: any) => {
    if (e == "1") {
      setData(
        "return_date",
        moment(transportData?.departure_date?.value).format("YYYY-MM-DD")
      );
      setData(
        "return_date",
        ["requiredIf:round_trip:1", "dateLessThan:departure_date"],
        "validation"
      )
    }else{
      setData(
        "return_date",
        ""
      );
      setData("return_date", ["requiredIf:round_trip:1"], "validation");
    }
  };

  return (
    <Modal {...rest} centered className="add-transportation-popup modal-784">
      <Modal.Header closeButton>
        <p className="tl">
          {" "}
          {props.isedit == "true" ? t("Edit Transportation") : t("Add Transportation")} 
        </p>
      </Modal.Header>
      <Modal.Body>
        {transportData?.form_type.value == "add" ? (
          <Row>
            <Col sm={6} md={6}>
              <SelectGropus
                label={`${t("Horse")}*`}
                defaultSelect={transportData?.horse_id?.value}
                value={metaData?.horse_list}
                dropKey="id"
                dropVal="name"
                onChange={(e: any) => {
                  setData("horse_id", e.target.value);
                }}
                error={
                  isFormValidated && transportDataError["horse_id"]
                    ? transportDataError["horse_id"]
                    : null
                }
              />
            </Col>
          </Row>
        ) : null}
        <Row>
          <Col sm={6} md={6}>
            <SelectGropus
              label={`${t("Transport Type")}*`}
              dropKey="id"
              dropVal="metadata"
              value={metaData?.transport_type}
              defaultSelect={transportData?.transport_type.value}
              onChange={(e: any) => {
                setData("transport_type", e.target.value);
              }}
              error={
                isFormValidated && transportDataError["transport_type"]
                  ? transportDataError["transport_type"]
                  : null
              }
            />
          </Col>
          <Col sm={6} md={6}>
            <ToggleButton
              label={`${t("Round Trip")}*`}
              toggleKey="key"
              toggleVal="value"
              value={metaData?.round_trip}
              selected={transportData?.round_trip.value}
              setSelected={(e: any) => {
                roundTripChange(e);
                setData("round_trip", e);
              }}
            />
          </Col>
        </Row>
        <Row>
          <Col sm={6} md={6}>
            <InputSelectGroup
              label={`${t("From")}*`}
              selectValue={metaData?.from}
              inputSelectToggle={FromToggle}
              setInputSelectToggle={(e: any) => {
                setToggleData("from", e);
              }}
              inputValue={transportData?.boarding?.value}
              onSelectChange={(value: any) => {
                setData("from_id", value);
              }}
              defaultSelect={transportData?.from_id?.value}
              dropKey="id"
              dropVal="name"
              onInputChange={(e: any) => {
                setData("boarding", e.target.value);
              }}
              error={
                isFormValidated &&
                (transportDataError["boarding"] ||
                  transportDataError["from_id"])
                  ? transportDataError["boarding"] ||
                    transportDataError["from_id"]
                  : null
              }
            />
          </Col>
          <Col sm={6} md={6}>
            <InputSelectGroup
              label={`${t("To")}*`}
              selectValue={metaData?.to}
              inputSelectToggle={ToToggle}
              setInputSelectToggle={(e: any) => {
                setToggleData("to", e);
              }}
              inputValue={transportData?.dropping?.value}
              onSelectChange={(value: any) => {
                setData("to_id", value);
              }}
              defaultSelect={transportData?.to_id?.value}
              dropKey="id"
              dropVal="name"
              onInputChange={(e: any) => {
                setData("dropping", e.target.value);
              }}
              error={
                isFormValidated &&
                (transportDataError["dropping"] || transportDataError["to_id"])
                  ? transportDataError["dropping"] ||
                    transportDataError["to_id"]
                  : null
              }
            />
          </Col>
        </Row>
        <Row>
          <Col sm={6} md={6}>
            <Datepicker
              label={`${t("Departure Date")}*`}
              onChange={dChange}
              value={moment(transportData?.departure_date?.value)}
              error={
                isFormValidated && transportDataError["departure_date"]
                  ? transportDataError["departure_date"]
                  : null
              }
            />
          </Col>
          {transportData?.round_trip?.value == "1" ? (
            <Col sm={6} md={6}>
              <Datepicker
                startDate={moment(transportData?.departure_date?.value).format(
                  "YYYY-MM-DD"
                )}
                label={`${t("Return Date")}`}
                onChange={rChange}
                value={moment(transportData?.return_date?.value)}
                error={
                  isFormValidated && transportDataError["return_date"]
                    ? transportDataError["return_date"]
                    : null
                }
              />
            </Col>
          ) : (
            ""
          )}
        </Row>
        <Row>
          <Col sm={6} md={6}>
            <InputSelectGroup
              label={`${t("Transporter")}`}
              selectValue={metaData?.transporter}
              inputSelectToggle={TranspoeterToggle}
              setInputSelectToggle={(e: any) => {
                setToggleData("transporter", e);
              }}
              dropKey="id"
              dropVal="first_name"
              defaultSelect={transportData?.transporter_id?.value}
              value={transportData?.transporter_name?.value}
              error={
                isFormValidated &&
                (transportDataError["transporter_name"] ||
                  transportDataError["transporter_id"])
                  ? transportDataError["transporter_name"] ||
                    transportDataError["transporter_id"]
                  : null
              }
              onSelectChange={(e: any) => {
                setData("transporter_id", e);
              }}
              onInputChange={(e: any) => {
                setData("transporter_name", e.target.value);
              }}
            />
          </Col>
        </Row>
        <Row>
          <Col sm={6} md={6}>
            <InputSelectMix
              label={`${t("Amount")}`}
              inputType="number"
              inputValue={transportData?.amount?.value}
              // selectValue={unitData}
              onInputChange={(e: any) => setData("amount", e.target.value)}
              error={
                isFormValidated && transportDataError["amount"]
                  ? transportDataError["amount"]
                  : null
              }
              inputSecondValue={prefrence?.currency_value}
            />
          </Col>
        </Row>
        <Row>
          <Col sm={12} md={12}>
            <TextareaGroups
              label={`${t("Comment")}`}
              type="text"
              autoComplete="off"
              value={transportData?.comment?.value}
              onChange={(e: any) => setData("comment", e.target.value)}
              error={
                isFormValidated && transportDataError["comment"]
                  ? transportDataError["comment"]
                  : null
              }
            />
          </Col>
          <Col sm={12} md={12}>
            <FileUpload
              fileType={`${t("Use a pdf, docx, jpg, png, heic")}`}
              onChange={(e: any) => {
                fileChange(e);
              }}
              accept="*"
              removeFile={() => checkExistFile()}
              selectedValue={fileDataSelected}
              value={fileDataName}
            />
          </Col>
        </Row>
      </Modal.Body>
      <Modal.Footer>
        <div className="footer-2-btn">
          <TextButton onClick={props.onHide}> {t("Cancel")}</TextButton>
          <CustomeButton bg="fill" onClick={() => submitHandler()}>
            {props.isedit == "true" ? t("Update") : t("Add")}
          </CustomeButton>
        </div>
      </Modal.Footer>
    </Modal>
  );
}
