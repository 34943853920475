import React from "react";

export default function BandageIcon() {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M17.3295 21H6.57345C5.25637 21 4.26857 19.9025 4.37833 18.5854L4.92711 11.561C5.14662 8.92686 7.34174 6.84149 10.0856 6.84149H13.9271C16.671 6.84149 18.8661 8.92686 19.0856 11.561L19.6344 18.5854C19.6344 19.9025 18.6466 21 17.3295 21Z"
        stroke="#919EAB"
        strokeWidth="1.5"
        strokeMiterlimit="30.568"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M8.87836 9.03658V6.84146V6.07317C8.87836 4.42683 10.1954 3 11.9515 3C13.5979 3 15.0247 4.31707 15.0247 6.07317V6.95122V9.14634"
        stroke="#919EAB"
        strokeWidth="1.5"
        strokeMiterlimit="30.568"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M11.9515 12V16.8293"
        stroke="#919EAB"
        strokeWidth="1.5"
        strokeMiterlimit="30.568"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M14.3661 14.4147H9.53687"
        stroke="#919EAB"
        strokeWidth="1.5"
        strokeMiterlimit="30.568"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}
