import { globalPostAction } from "../../actions/globalActions";
import { END_POINTS } from "../../Constant/Api";
import { SHOW_MESSAGE } from "../../Constant/General";

export const addEditBoarding = (params: any) => {
  let apiUrl = END_POINTS.addEditBoarding;
  return globalPostAction(params, apiUrl , SHOW_MESSAGE);
};

export const getBoardingList = (params: any) => {
  let apiUrl = END_POINTS.boardingList;
  return globalPostAction(params, apiUrl);
};

export const getSingleBoarding = (params: any) => {
  let apiUrl = END_POINTS.viewBoarding;
  return globalPostAction(params, apiUrl);
};

export const destroyBoarding = (params: any) => {
  let apiUrl = END_POINTS.destroyBoarding;
  return globalPostAction(params, apiUrl , SHOW_MESSAGE);
};

export const addBoardingPlan = (params: any) => {
  let apiUrl = END_POINTS.addBoardingplan;
  return globalPostAction(params, apiUrl , SHOW_MESSAGE);
};

export const editBoardingPlan = (params: any) => {
  let apiUrl = END_POINTS.editBoardingPlan;
  return globalPostAction(params, apiUrl , SHOW_MESSAGE);
};

export const destroyBoardingPlan = (params: any) => {
  let apiUrl = END_POINTS.destroyBoardingPlan;
  return globalPostAction(params, apiUrl , SHOW_MESSAGE);
};

export const boardingEnd = (params: any) => {
  let apiUrl = END_POINTS.endBoardigHorse;
  return globalPostAction(params, apiUrl , SHOW_MESSAGE);
};