import { useEffect, useState } from "react";
import { Col, Row } from "react-bootstrap";
import CustomeButton from "../../Common/CustomButton/CustomButton";
import AddPlusIcon from "../../../Assets/icon/AddPlusIcon";
import binIcon from "../../../Assets/img/bin.svg";
import pencilIcon from "../../../Assets/img/pencil.svg";
import { useOutletContext, useParams } from "react-router-dom";
import { useLoading } from "../../../context/LoadingContext";
import { useTranslation } from "react-i18next";
import {
  destroyBreedingStallion,
  getAllBreedingStallionList,
  getBreedingMeta,
  viewBreedingStallion,
} from "../../../Redux/NonRedux/breeding";
import {
  encrDecrStr,
  getDefaultSorting,
} from "../../../Utility/General";
import ConfirmPopup from "../../Popups/ConfirmPopup/ConfirmPopup";
import Pagination from "../../Layouts/Pagination/Pagination";
import NoDataFound from "../../Common/NoDataFound/NoDataFound";
import TableHead from "../../Common/DataTable/TableHead/TableHead";
import { DATATABLE } from "../../../Constant/General";
import AddBreedingServiceStallionPopup from "../../Popups/AddBreedingServiceStallionPopup/AddBreedingServiceStallionPopup";

export default function BreedingServiceStallion() {
  const { t } = useTranslation();
  const tableHead = [
    {
      label: t("Breeding Date"),
      className: "tm",
      key: "breeding_date",
    },
    {
      label: t("Mare"),
      className: "tm",
      key: "mare",
    },
    {
      label: t("Sire"),
      className: "tm",
      key: "sire",
    },
    {
      label: t("Dam"),
      className: "tm",
      key: "dam",
    },

    {
      label: t("Owner"),
      className: "tm",
      key: "owner",
    },

    {
      label: t("Action"),
      className: "tm",
      key: "",
      headClass: "text-right",
    },
  ];

  const defaultSorting: any = getDefaultSorting(DATATABLE.HORSE_BREEDING);
  const param = useParams();
  const [horseId, setHorseId] = useState<any>(0);

  const [listData, setListData] = useState<any>([]);
  const [recordsTotal, setRecordsTotal] = useState(1);
  const [currentPage, setCurrentPage] = useState(1);
  const [currentPageSize, setCurrentPageSize] = useState(10);
  const { setLoading } = useLoading();
  const [activeService, setActiveService] = useState<any>();
  const [mainData, setMainData] = useState<any>([]);

  const [deletableRecords, setDeletableRecords] = useState<any>([]);
  const [confirmPopupShow, setConfirmPopupShow] = useState(false);
  const [sortByField, setSortByField] = useState(defaultSorting?.key);
  const [sortType, setSortType] = useState(defaultSorting?.type);
  const [orderList, setOrderList] = useState<any>([]);
  const [
    AddBreedingServiceStallionModalShow,
    setAddBreedingServiceStallionModalShow,
  ] = useState(false);
  const [isEdit, setIsEdit] = useState<boolean>(false);
  const [refreshMeta, setRefreshMeta] = useState(false);
  const [metaData, setMetaData] = useState<any>([]);
  const [refreshList, setRefreshList] = useState(false);
  const [editData, setEditData] = useState<any>({});
  const {setExportParams} : any  = useOutletContext();
  const getMeta = () => {
    setLoading(true);
    let id = encrDecrStr(param?.slug, "decr");
    setHorseId(id);
    let params = {
      horse_id: id,
    };
    getBreedingMeta(params)
      .then((res: any) => {
        setMetaData(res?.component);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  useEffect(() => {
    if (refreshMeta) {
      getMeta();
      setRefreshMeta(false);
    }
  }, [refreshMeta]);

  useEffect(() => {
    getMeta();
  }, []);

  const listApiCall = () => {
    let id = encrDecrStr(param?.slug, "decr");
    setHorseId(id);
    let params: any = {
      horse_id: id,
      page: currentPage,
      limit: currentPageSize,
    };
    if (sortByField && sortType) {
      params["sort"] = {
        column: orderList[sortByField]
          ? orderList[sortByField]
          : defaultSorting?.value,
        type: sortType,
      };
    }
    setExportParams(params)
    setLoading(true);
    getAllBreedingStallionList(params)
      .then((response: any) => {
        setListData(response?.component?.listing);
        setRecordsTotal(response?.component?.recordsFiltered);
        setMainData(response?.component?.mainArray);
        setOrderList(response?.component?.orderlist);
      })
      .catch(() => {})
      .finally(() => {
        setLoading(false);
      });
  };

  useEffect(() => {
    listApiCall();
  }, [currentPage, currentPageSize, sortByField, sortType]);

  useEffect(() => {
    if (refreshList) {
      listApiCall();
      setRefreshList(false);
    }
  }, [refreshList]);

  const editHandler = (id: number) => {
    let params = {
      horse_id: horseId,
      id: id,
    };

    setLoading(true);
    viewBreedingStallion(params)
      .then((response: any) => {
        setIsEdit(true);
        setEditData(response?.component);
        setAddBreedingServiceStallionModalShow(true);
      })
      .catch(() => {})
      .finally(() => {
        setLoading(false);
      });
  };

  const deleteHandler = () => {
    let params = {
      id: deletableRecords,
    };

    setLoading(true);
    destroyBreedingStallion(params)
      .then(() => {
        setRefreshList(true);
        setConfirmPopupShow(false);
      })
      .catch(() => {})
      .finally(() => {
        setLoading(false);
      });
  };

  const deleteNow = (id: number) => {
    let ids = deletableRecords;
    ids.push(id);
    setDeletableRecords(ids);
    setConfirmPopupShow(true);
  };

  return (
    <>
      <div className="breeding-horse-page">
        <div className="summary-head">
          <Row>
            <Col sm="12" md="6" lg="4">
              <div className="summary-box">
                <p className="tl blur-color">{t("Status")}</p>
                <div className="right-details">
                  <h5>{mainData?.status}</h5>
                  <p className="tm"></p>
                </div>
              </div>
            </Col>
            <Col sm="12" md="6" lg="4">
              <div className="summary-box">
                <p className="tl blur-color">{t("No. of Offspring")}</p>
                <div className="right-details">
                  <h5>{mainData?.number_offspring}</h5>
                  <p className="tm"></p>
                </div>
              </div>
            </Col>
            <Col sm="12" md="6" lg="4">
              <div className="summary-box">
                <p className="tl blur-color">
                  {t("No. of Mares Covered This Year")}
                </p>
                <div className="right-details">
                  <h5>{mainData?.number_of_mare}</h5>
                </div>
              </div>
            </Col>
          </Row>
        </div>

        <div className="data-tbl-wrapper">
          <div className="data-tbl-header">
            <p className="tl">{t("Breeding List")}</p>
            <div className="btn-wrapper">
              <CustomeButton
                bg="fill"
                onClick={() => {
                  setIsEdit(false);
                  setEditData({});
                  setAddBreedingServiceStallionModalShow(true);
                }}
              >
                <AddPlusIcon />
                {t("Add")}
              </CustomeButton>
            </div>
          </div>
          <div className="data-tbl">
            <table>
              <TableHead
                data={tableHead}
                orderList={orderList}
                sortByField={sortByField}
                sortType={sortType}
                setSortType={setSortType}
                setSortByField={setSortByField}
              />

              <tbody>
                {listData.map((items: any, index: number) => (
                  <tr key={index + "breeding-service"}>
                    <td>
                      <p className="bl">{items?.dates}</p>
                    </td>
                    <td>
                      <p className="bl">{items?.mare}</p>
                    </td>
                    <td>
                      <p className="bl">{items?.sire}</p>
                    </td>
                    <td>
                      <p className="bl">{items?.dam}</p>
                    </td>
                    <td>
                      <p className="bl">{items?.owner}</p>
                    </td>

                    <td>
                      <div className="icon-wrapper">
                        {!items?.refrance_id ? (
                          <>
                            <a>
                              <img
                                src={pencilIcon}
                                onClick={() => editHandler(items?.id)}
                                alt=""
                              />
                            </a>
                            <a>
                              <img
                                src={binIcon}
                                onClick={() => deleteNow(items?.id)}
                                alt=""
                              />
                            </a>
                          </>
                        ) : null}
                      </div>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
          {listData?.length ? (
            <Pagination
              results={recordsTotal}
              currentPage={currentPage}
              setCurrentPage={setCurrentPage}
              currentLength={currentPageSize}
              setCurrentPageSize={setCurrentPageSize}
            />
          ) : (
            <NoDataFound />
          )}
        </div>
      </div>

      <AddBreedingServiceStallionPopup
        show={AddBreedingServiceStallionModalShow}
        onHide={() => setAddBreedingServiceStallionModalShow(false)}
        isedit={isEdit ? 1 : 0}
        editData={editData}
        horseId={horseId}
        metaData={metaData}
        setRefreshList={() => {
          setRefreshList(true);
          setRefreshMeta(true);
        }}
      />

      <ConfirmPopup
        heading={t("Delete")}
        message={t("Are you sure to delete this record?")}
        show={confirmPopupShow}
        onHide={() => {
          setConfirmPopupShow(false);
          setDeletableRecords([]);
        }}
        confirm={deleteHandler}
      />
    </>
  );
}
