import { t } from "i18next";
import React, { useEffect } from "react";
import { Col, Row } from "react-bootstrap";
import Modal from "react-bootstrap/Modal";
import FemaleIcon from "../../../Assets/img/Female.svg";
import MaleIcon from "../../../Assets/img/Male.svg";
import PregnantIcon from "../../../Assets/img/Pregnant.svg";
import OwlCarousel from "react-owl-carousel";
import {
  ImageVideo,
  InformationLable,
  StudFarmHorseDetails,
} from "../../../interface/FarmDirectory.interface";

export default function FarmDetailPopup(props: any) {
  const { horseDetails, ...rest } = props;

  const getIconbyType = (type: any) => {
    if (type == "male") {
      return MaleIcon;
    } else if (type == "female") {
      return FemaleIcon;
    } else if (type == "pregnant") {
      return PregnantIcon;
    }
  };

  const Information: any = {
    birthdate: t("Date of birth"),
    sex_value: t("Sex"),
    color_value: t("Colour"),
    sire: t("Sire"),
    dam: t("Dam"),
    breed_value: t("Breed"),
    strain: t("Strain"),
    owner: t("Owner"),
    breeder: t("Breeder"),
    location_value: t("Location"),
  };

  useEffect(() => {
    let extraFields: any = {};
    if (horseDetails?.type === "male") {
      extraFields = {
        stallion_status_value: t("Stallion status"),
        semen_type_value: t("Semen type"),
        stud_fee: t("Stud fee"),
        vet_clinic: t("Responsible Vet/Clinic"),
      };
    } else {
      extraFields = {
        pregancy_status: t("Pregancy status"),
      };
    }
    // let newInformation = Object.assign()
  }, [horseDetails]);

  const settings = {
    items: 1,
    nav: true,
    dots: true,
    loop: true,
    rtl: true,
  };

  return (
    <Modal {...rest} centered className="farm-detail-popup modal-1094">
      <Modal.Header closeButton>
        <div className="m-header-icon-text">
          <img src={getIconbyType(horseDetails?.type)} alt="" />
          <h5>{horseDetails?.name}</h5>
        </div>
      </Modal.Header>
      <Modal.Body>
        <div className="fd-content-wrapper">
          <div className="left-img-wrapper">
            <OwlCarousel className="owl-theme" {...settings}>
              {horseDetails?.imageVideo?.map(
                (image: ImageVideo, index: number) => (
                  <div className="item" key={index + "tiny-slider"}>
                    {image?.type == 0 ? (
                      <img src={image?.image_video} alt="" />
                    ) : (
                      <div className="load-video-wrapper">
                        <div className="video-loader-wrapper">
                          <span className="loader-20"></span>
                        </div>
                        <iframe src={image?.image_video}></iframe>
                      </div>
                    )}
                  </div>
                )
              )}
            </OwlCarousel>
          </div>

          <div className="right-table">
            <div className="info-table">
              <table>
                <tbody>
                  {Object.keys(Information).map((item: string) => {
                    return (
                      <tr key={item}>
                        <td>
                          <p className="tm">{Information[item]}</p>
                        </td>
                        <td className="pl-3">
                          <p className="tm blur-color">
                            {horseDetails?.[item] || "-"}
                          </p>
                        </td>
                      </tr>
                    );
                  })}
                  <br />
                  {horseDetails?.sex_key === "female" ? (
                    <tr>
                      <td>
                        <p className="tm">{t("Pregancy status")}</p>
                      </td>
                      <td className="pl-3">
                        <p className="tm blur-color">
                          {horseDetails?.pregancy_status || "-"}
                        </p>
                      </td>
                    </tr>
                  ) : null}

                  {horseDetails?.sex_key === "male" ? (
                   <>
                    <tr>
                      <td>
                        <p className="tm">{t("Stallion status")}</p>
                      </td>
                      <td className="pl-3">
                        <p className="tm blur-color">
                          {horseDetails?.stallion_data?.stallion_status_value || "-"}
                        </p>
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <p className="tm">{t("Semen type")}</p>
                      </td>
                      <td className="pl-3">
                        <p className="tm blur-color">
                        {horseDetails?.stallion_data?.semen_type_value || "-"}
                        </p>
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <p className="tm">{t("Stud fee")}</p>
                      </td>
                      <td className="pl-3">
                        <p className="tm blur-color">
                        {horseDetails?.stallion_data?.stud_fee || "-"}
                        </p>
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <p className="tm">{t("Responsible Vet/Clinic")}</p>
                      </td>
                      <td className="pl-3">
                        <p className="tm blur-color">
                      {`${horseDetails?.stallion_data?.vet_clinic} ${horseDetails?.stallion_data?.vet_clinic_contact}`}  {!(horseDetails?.stallion_data?.vet_clinic ||horseDetails?.stallion_data?.vet_clinic_contact) ? "-" : null}
                        </p>
                      </td>
                    </tr>
                   </>
                  ) : null}

                </tbody>
              </table>
            </div>
          </div>
        </div>
      </Modal.Body>
    </Modal>
  );
}
