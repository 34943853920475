import React, { useEffect, useRef } from "react";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import { Bar } from "react-chartjs-2";
import { faker } from "@faker-js/faker";

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
);

export function VerticalBarChart(props: any) {
  const chartRef:any = useRef();
  useEffect(() => {
    if (props.data && chartRef.current) {
      chartRef.current.update('active')
     
    }
  }, [props?.options, props.data]);
  return <Bar options={props.options} data={props.data} ref={chartRef} />;
}
