import React, { useEffect, useState } from "react";

const InputSelectMix = (props: any) => {
  return (
    <div className="input-groups">
      <label>{props.label}</label>
      <div className="input-select-mix">
        <input
          type={props.inputType}
          inputMode={props.inputmode}
          defaultValue={props.inputDefaultValue}
          value={props.inputValue}
          placeholder={props.inputPlaceholder}
          readOnly={props.inputReadOnly}
          autoComplete={props.inputAutoComplete}
          name={props.inputName}
          id={props.inputId}
          className={props.inputError && "error"}
          onChange={props.onInputChange}
        />
        {/* <select
          onChange={props.onSelectChange}
          name={props.selectName}
          id={props.selectId}
          value={props.selectDefaultSelect}
          className={props.selectError && "error"}
        >
          {props.selectOption ? (
            <option value="">{props.selectOption}</option>
          ) : (
            <option value="">Select</option>
          )}
          {props.selectValue?.map((items: any, index: number) => (
            <option
              key={index}
              value={items[props.dropKey ? props.dropKey : "value"]}
            >
              {items[props.dropVal?props.dropVal:'name']}
            </option>
          ))}
        </select> */}
        <input
          value={props.inputSecondValue ? props.inputSecondValue : ""}
          readOnly
          disabled
        />
      </div>
      {props.inputError && <span className="error">{props.inputError}</span>}
    </div>
  );
};

export default InputSelectMix;
