import { t } from "i18next";
import React, { useContext, useEffect } from "react";
import { Outlet, useLocation, useNavigate } from "react-router-dom";
import FileEditIcon from "../../Assets/img/Common-icon/FileEditIcon";
import OpenIcon from "../../Assets/img/Common-icon/OpenIcon";
import WeaningIcon from "../../Assets/img/Common-icon/WeaningIcon";
import ParasiteControlIcon from "../../Assets/img/Inner-tab-icon/ParasiteControlIcon";
import SyringeIcon from "../../Assets/img/Inner-tab-icon/SyringeIcon";
import ToothIcon from "../../Assets/img/Inner-tab-icon/ToothIcon";
import ReminderIcon from "../../Assets/img/Reminders-primary.svg";
import Farrier from "../../Assets/img/Tab-icon/Farrier";
import { SIDEBAR } from "../../Constant/General";
import { REMINDERS_DENTAL, REMINDERS_FARRIER, REMINDERS_PARASITECONTROL, REMINDERS_PREGNANCY, REMINDERS_REGISTRATION, REMINDERS_VACCINATION, REMINDERS_WEANING } from "../../Constant/Route";
import { SidebarContext } from "../../context/SideBarContext";
import TabWrapper from "../Layouts/TabWrapper/TabWrapper";




export default function Reminders() {
  const [parentTab, setParentTab] = React.useState(0);
  const parentTabHandleChange = (
    event: React.SyntheticEvent,
    newValue: number
  ) => {
    setParentTab(newValue);
  };
  const parentTabData = [
    {
      index: 0,
      img: <OpenIcon />,
      label:t("Pregnancy"),
      path: REMINDERS_PREGNANCY,
    },
    {
      index: 1,
      img: <FileEditIcon />,
      label:t("Registration"),
      path: REMINDERS_REGISTRATION,
    },
    {
      index: 2,
      img: <WeaningIcon />,
      label:t("Weaning"),
      path: REMINDERS_WEANING,
    },
    {
      index: 3,
      img: <SyringeIcon />,
      label:t("Vaccination"),
      path: REMINDERS_VACCINATION,
    },
    {
      index: 4,
      img: <ParasiteControlIcon />,
      label:t("Parasite Control"),
      path: REMINDERS_PARASITECONTROL,
    },
    {
      index: 5,
      img: <ToothIcon />,
      label:t("Dental"),
      path: REMINDERS_DENTAL,
    },

    {
      index: 6,
      img: <Farrier />,
      label:t("Farrier"),
      path: REMINDERS_FARRIER,
    },
  ];

  let navigate = useNavigate();
  const horseRouteChange = (pathName: any) => {
    navigate(pathName);
  };

  const location:any = useLocation();
  const {setSidebar} : any =  useContext(SidebarContext)

  useEffect(() => {
    setSidebar(SIDEBAR.REMINDER);
  }, [])
  
  useEffect(()=>{
    if (location.pathname.includes(REMINDERS_PREGNANCY)) {
      setParentTab(0);
    }else if (location.pathname.includes(REMINDERS_REGISTRATION)) {
      setParentTab(1);
    }else if (location.pathname.includes(REMINDERS_WEANING)) {
      setParentTab(2);
    }else if (location.pathname.includes(REMINDERS_VACCINATION)) {
      setParentTab(3);
    }else if (location.pathname.includes(REMINDERS_PARASITECONTROL)) {
      setParentTab(4);
    }else if (location.pathname.includes(REMINDERS_DENTAL)) {
      setParentTab(5);
    }else if (location.pathname.includes(REMINDERS_FARRIER)) {
      setParentTab(6);
    }
    // location.pathname == DASHBOARD ? "active" : "";
    // setInnerTab(1);
  },[location?.pathname]) 

  return (
    <>
      <div className="reminder-page">
        {/* Page Title Start */}
        <div className="page-title-wrapper">
          <div className="title-first-menu">
            <div className="title-icon-text">
              <img src={ReminderIcon} alt="" />
              <h5>{t("Reminders")}</h5>
            </div>
          </div>
          <TabWrapper
            value={parentTab}
            horseRouteChange={horseRouteChange}
            handleChange={parentTabHandleChange}
            parentTabData={parentTabData}
          />
        </div>
        {/* Page Title End */}

        <div className="page-content">
          <Outlet />
        </div>
      </div>
    </>
  );
}
