import React, { useState } from "react";
import Modal from "react-bootstrap/Modal";
import Datepicker from "../../Common/Datepicker/Datepicker";
import InputGroups from "../../Common/InputGroups/InputGroups";
import SelectGropus from "../../Common/SelectGroups/SelectGroups";
import type { DatePickerProps } from "antd";
import moment from "moment";
import ToggleButton from "../../Common/ToggleButton/ToggleButton";
import CheckboxLabel from "../../Common/CheckboxLabel/CheckboxLabel";
import TextButton from "../../Common/TextButton/TextButton";
import CustomeButton from "../../Common/CustomButton/CustomButton";
import { t } from "i18next";

export default function AddEmailPopup(props: any) {
  
  const INTIAL_HEIGHT_CAL_DATA = {
    height: {
      value: "",
      validation: ["required"],
      errors: ["Height is required."],
    },
    heightInHand: {
      value: "",
    },
  };

  const toggleBtnValue = [
    { name: t("Yes"), value: "Yes" },
    { name:t("No"), value: "No" },
  ];
  const [selected, setSelected] = useState("Yes");

  const submitHandler = () => {

  }

  return (
    <Modal {...props} centered className="add-email-popup modal-416">
      <Modal.Header closeButton>
        <p className="tl">Add Email</p>
      </Modal.Header>
      <Modal.Body>
        <div>
          <InputGroups label="Email*" />
          <ToggleButton
            value={toggleBtnValue}
            label="Share"
            selected={selected}
            setSelected={setSelected}
          />
        </div>
      </Modal.Body>
      <Modal.Footer>
        <div className="footer-2-btn">
          <TextButton> {t("Cancel")}</TextButton>
          <CustomeButton bg="fill" onClick={submitHandler}>Save</CustomeButton>
        </div>
      </Modal.Footer>
    </Modal>
  );
}
