import {
  BOARDING_PLAN_LIST_REQUEST,
  BOARDING_PLAN_LIST_SUCCESS,
  BOARDING_PLAN_LIST_FAILD,
  BOARDING_PLAN_LIST_LONG,
  BOARDING_PLAN_LIST_CLEAR,
} from "./BoardingType";

export function boardingPlanListRedux(state = {}, action: any) {
  switch (action.type) {
    case BOARDING_PLAN_LIST_REQUEST:
      return { ...action.payload };
    case BOARDING_PLAN_LIST_SUCCESS:
      return { ...action.payload };
    case BOARDING_PLAN_LIST_FAILD:
      return { ...action.payload };
    case BOARDING_PLAN_LIST_LONG:
      return { ...action.payload };
    case BOARDING_PLAN_LIST_CLEAR:
      return {};
    default:
      return state;
  }
}
