import { t } from "i18next";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { Dropdown } from "react-bootstrap";
import { useSelector } from "react-redux";
import Filtericon from "../../../../Assets/icon/Filtericon";
import binIcon from "../../../../Assets/img/bin.svg";
import pencilIcon from "../../../../Assets/img/pencil.svg";
import { END_POINTS } from "../../../../Constant/Api";
import { DATATABLE } from "../../../../Constant/General";
import { useLoading } from "../../../../context/LoadingContext";
import { getListMetaDataApi } from "../../../../Redux/NonRedux/general";
import {
  bulkPayrollUpdateApi,
  deletePayroll,
  getAllPayrollList,
  getMetaStaffList,
  updatePayroll,
  viewSinglePayroll,
} from "../../../../Redux/NonRedux/users";
import {
  getDefaultSorting,
  getListMonthCol,
  getMonthListDropdown,
} from "../../../../Utility/General";
import AllSelectLabel from "../../../Common/AllSelectLabel/AllSelectLabel";
import CustomeButton from "../../../Common/CustomButton/CustomButton";
import TableHead from "../../../Common/DataTable/TableHead/TableHead";
import NoDataFound from "../../../Common/NoDataFound/NoDataFound";
import OnlyCk from "../../../Common/OnlyCk/OnlyCk";
import SelectGropus from "../../../Common/SelectGroups/SelectGroups";
import Pagination from "../../../Layouts/Pagination/Pagination";
import Loader from "../../../Loader/Loader";
import BulkPayrollEditPopup from "../../../Popups/BulkPayrollEditPopup/BulkPayrollEditPopup";
import ConfirmPopup from "../../../Popups/ConfirmPopup/ConfirmPopup";
import EditPayrollPopup from "../../../Popups/EditPayrollPopup/EditPayrollPopup";

export default function PayrollPage() {
  const defaultSorting : any = getDefaultSorting(DATATABLE.PAYROLL);
  const tableHead = [
    {
      headClass: "ck-wrapper",
      checkbox: true,
    },
    {
      label: t("First Name"),
      className: "tm",
      key: "first_name",
    },
    {
      label: t("Last Name"),
      className: "tm",
      key: "last_name",
    },
    {
      label: t("Designation"),
      className: "tm",
      key: "designation",
    },
    {
      label: t("Salary"),
      className: "tm",
      key: "salary",
    },

    {
      label: t("Month"),
      className: "tm",
      key: "month",
    },
    {
      label: t("Status"),
      className: "tm",
      key: "status",
    },
    {
      label: t("Action"),
      className: "tm",
      key: "",
      headClass: "text-right",
    },
  ];

  const [currentPage, setCurrentPage] = useState(1);
  const [currentPageSize, setCurrentPageSize] = useState(10);
  const [recordsTotal, setRecordsTotal] = useState(1);
  const [payrollData, setPayrollData] = useState<any>([]);
  const [deletableRecords, setDeletableRecords] = useState<any>([]);
  const [confirmPopupShow, setConfirmPopupShow] = useState(false);
  const { setLoading } = useLoading();
  const [isEdit, setIsEdit] = useState(false);
  const [editData, setEditData] = useState({});
  const [payrollModalShow, setPayrollModalShow] = useState(false);
  const [bulkPayrollModalShow, setBulkPPayrollModalShow] = useState(false);
  const [selectedStaff, setSelectedStaff] = useState();
  const [selectedMonth, setSelectedMonth] = useState();
  const [selectedStatus, setSelectedStatus] = useState();
  const [monthList, setMonthList] = useState([]);
  const [refreshList, setRefreshList] = useState(false);
  const [checkedRec, setCheckedRec] = useState<number[]>([]);
  const [sortByField, setSortByField] = useState(defaultSorting?.key);
  const [sortType, setSortType] = useState(defaultSorting?.type);
  const [orderList, setOrderList] = useState<any>([]);
  const [metaData, setMetaData] = useState<any>({});
  const prefrence = useSelector(
    (state: any) => state?.masterReducer?.data?.component?.preferance
  );


  const getMetaData = () => {
    setLoading(true);  
    getListMetaDataApi(END_POINTS.metaUserPayroll)
      .then((response: any) => {
        setMetaData(response?.component);
      })
      .catch((error: any) => {})
      .finally(() => {
        setLoading(false);
      });
  };

  
  useEffect(() => {
    let headerMonth = getMonthListDropdown();
    setMonthList(headerMonth);
    getMetaData();
  }, []);

  const listApiCall = () => {
    let params: any = {
      page: currentPage,
      limit: currentPageSize,
      staff_id: selectedStaff,
      status: selectedStatus,
      month: selectedMonth,
    };
    if (sortByField && sortType) {
      params["sort"] = {
        column: orderList[sortByField] ? orderList[sortByField] : defaultSorting?.value ,
        type: sortType,
      };
    }
    setLoading(true);
    getAllPayrollList(params)
      .then((response: any) => {
        setPayrollData(response?.component);
        setRecordsTotal(response?.component?.recordsFiltered);
        setOrderList(response?.component?.orderlist);
      })
      .catch(() => {})
      .finally(() => {
        setLoading(false);
      });
  };

  useEffect(() => {
    listApiCall();
  }, [
    currentPage,
    currentPageSize,
    selectedMonth,
    selectedStaff,
    selectedStatus,
    sortByField,
    sortType,
  ]);

  useEffect(() => {
    if (refreshList) {
      listApiCall();
      setRefreshList(false);
    }
  }, [refreshList]);

  const editHandler = (id: number, month: string) => {
    let formate_month = moment(month, "MMM YYYY").format("YYYY-MM");
    let params = {
      id: id,
      month: formate_month,
    };
    setLoading(true);
    viewSinglePayroll(params)
      .then((response: any) => {
        setLoading(false);
        setIsEdit(true);
        setEditData(response?.component);
        setPayrollModalShow(true);
      })
      .catch(() => {})
      .finally(() => {
        setLoading(false);
      });
  };

  const deleteHandler = () => {
    let params = {
      id: deletableRecords[0],
    };
    setLoading(true);
    deletePayroll(params)
      .then((response: any) => {
        setConfirmPopupShow(false);
      })
      .catch((error: any) => {})
      .finally(() => {
        setLoading(false);
      });
  };

  const deleteNow = (id: number) => {
    let ids = deletableRecords;
    ids.push(id);
    setDeletableRecords(ids);
    setConfirmPopupShow(true);
  };

  const checkSingleRec = (e: any, val: number) => {
    //
    var checkedRecData: number[] = Object.assign([], checkedRec);
    if (e.target.checked) {
      checkedRecData.push(val);
    } else {
      checkedRecData = checkedRecData.filter((c: number) => c !== val);
    }
    setCheckedRec(checkedRecData);
  };

  const handleSelectAll = (e: any) => {
    if (e.target.checked) {
      var checkedRecData: any[] = Object.assign([], checkedRec);
      payrollData?.listing?.map((li: any) => {
        if (!checkedRecData.includes(li.id)) {
          checkedRecData.push(li.id);
        }
      });
      setCheckedRec(checkedRecData);
    } else {
      setCheckedRec([]);
    }
  };

  const bulkPayrolUpdate = (status:string) => {
    setLoading(true);
    bulkPayrollUpdateApi({ ids: checkedRec , status})
      .then(() => {
        setBulkPPayrollModalShow(false);
        setCheckedRec([]);
        setRefreshList(true);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  return (
    <React.Fragment>
      <div className="payroll-page">
        <div className="page-content">
          <div className="data-tbl-wrapper">
            <div className="data-tbl-header">
              <div className="select-wrapper">
                <SelectGropus
                  selectOption={t("Select Staff...")}
                  value={metaData?.staff}
                  dropKey="id"
                  dropVal="first_name"
                  defaultSelect={selectedStaff}
                  onChange={(e: any) => {
                    setSelectedStaff(e.target.value);
                  }}
                />
                <SelectGropus
                  selectOption={t("Select Month...")}
                  value={monthList}
                  dropKey="key"
                  dropVal="value"
                  defaultSelect={selectedMonth}
                  onChange={(e: any) => {
                    setSelectedMonth(e.target.value);
                  }}
                />
                <SelectGropus
                  selectOption={t("Select Status...")}
                  value={metaData?.status_list}
                  dropKey="key"
                  dropVal="value"
                  defaultSelect={selectedStatus}
                  onChange={(e: any) => {
                    setSelectedStatus(e.target.value);
                  }}
                />
              </div>
              <div className="btn-wrapper f">
                  {checkedRec.length ? (
                  <CustomeButton
                    onClick={() => {
                      setBulkPPayrollModalShow(true);
                    }}
                    bg="fill"
                  >
                    {t("Change Status")}
                  </CustomeButton>
                ) : (
                  ""
                )}
              </div>
            </div>
            <div className="data-tbl ptb-12">
              <table>
                <TableHead
                  data={tableHead}
                  orderList={orderList}
                  sortByField={sortByField}
                  sortType={sortType}
                  setSortType={setSortType}
                  setSortByField={setSortByField}
                  isChecked={
                    checkedRec?.length &&
                    checkedRec?.length == payrollData?.listing?.length
                  }
                  handleSelectAll={(e: any) => handleSelectAll(e)}
                />
                <tbody>
                  {payrollData?.listing?.map((items: any, index: number) => (
                    <tr key={index + "vaccination-data"}>
                      <td className="ck-wrapper">
                        <OnlyCk
                          checked={checkedRec.includes(items?.id)}
                          onChange={(e: any) => checkSingleRec(e, items?.id)}
                        />
                      </td>
                      <td>
                        <p className="bl">{items?.first_name}</p>
                      </td>
                      <td>
                        <p className="bl">{items?.last_name}</p>
                      </td>
                      <td>
                        <p className="bl">{items?.designation}</p>
                      </td>
                      <td>
                        <p className="bl">
                          {items?.paid_amount
                            ? items?.paid_amount
                            : items?.salary}{" "}
                          {items?.paid_amount || items?.salary
                            ? prefrence?.currency_value
                            : "-"}
                        </p>
                      </td>
                      <td>
                        <p className="bl">{items?.month}</p>
                      </td>
                      <td>
                        <label
                          className={`bl status-badge ${
                            items?.status == "1" ? "active" : "inprogress"
                          }`}
                        >
                          {items?.status == "1" ? "Paid" : "Pending"}
                        </label>
                      </td>
                      <td>
                        <div className="icon-wrapper">
                          <a>
                            <img
                              src={pencilIcon}
                              onClick={() =>
                                editHandler(items?.id, items?.month)
                              }
                              alt=""
                            />
                          </a>
                          <a>
                            <img
                              src={binIcon}
                              onClick={() => deleteNow(items?.id)}
                              alt=""
                            />
                          </a>
                        </div>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>

            <BulkPayrollEditPopup
              length={checkedRec?.length}
              setRefreshList={() => {
                setRefreshList(true);
              }}
              show={bulkPayrollModalShow}
              onHide={() => {
                setBulkPPayrollModalShow(false);
              }}
              bulkPayrolUpdate={bulkPayrolUpdate}
              statusList={metaData?.status_list}
            />

            <EditPayrollPopup
              show={payrollModalShow}
              onHide={() => {
                setPayrollModalShow(false);
              }}
              isedit={isEdit ? 1 : 0}
              editData={editData}
              setRefreshList={() => {
                setRefreshList(true);
              }}
              statusList={metaData?.status_list}
            />

            <ConfirmPopup
              heading={t("Delete")}
              message={t("Are you sure to delete this record?")}
              show={confirmPopupShow}
              onHide={() => {
                setConfirmPopupShow(false);
                setDeletableRecords([]);
              }}
              confirm={deleteHandler}
            />

            {payrollData?.listing?.length ? (
              <Pagination
                results={recordsTotal}
                currentPage={currentPage}
                setCurrentPage={setCurrentPage}
                currentLength={currentPageSize}
                setCurrentPageSize={setCurrentPageSize}
              />
            ) : (
              <NoDataFound />
            )}
          </div>
        </div>
      </div>
    </React.Fragment>
  );
}
