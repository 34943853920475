import React from "react";

export default function PaperClipIcon() {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M19.3577 9.56471V17.4C19.3577 19.4118 17.7694 21 15.7577 21H8.24001C6.22825 21 4.64001 19.4118 4.64001 17.4V6.6C4.64001 4.58824 6.22825 3 8.24001 3H12.5812"
        stroke="#919EAB"
        strokeWidth="1.5"
        strokeMiterlimit="10"
      />
      <path
        d="M19.3577 9.56471H12.6871V3L19.3577 9.56471Z"
        stroke="#919EAB"
        strokeWidth="1.5"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M8.87531 13.6941H15.2282"
        stroke="#919EAB"
        strokeWidth="1.5"
        strokeMiterlimit="10"
        strokeLinecap="round"
      />
      <path
        d="M8.87531 16.8705H15.2282"
        stroke="#919EAB"
        strokeWidth="1.5"
        strokeMiterlimit="10"
        strokeLinecap="round"
      />
    </svg>
  );
}
