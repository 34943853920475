import React from "react";

export default function Hoursesicon() {
  return (
    <svg
      width="32"
      height="32"
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M12.6667 12.6667H12.68"
        stroke="#E8E5E2"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M11.3333 19.0667C12 19.4667 12.8 19.7333 13.6 19.7333C14.2667 19.7333 14.8 19.6 15.3333 19.3333L17.0667 20.4C18.5333 21.3333 20.5333 20.5333 21.0667 18.9333C21.3333 18.1333 21.6 17.4667 21.7333 16.6667L14.4 9.33326C14.8 8.6666 15.2 7.99993 15.7333 7.33326C14.1333 7.4666 12.6667 7.59993 11.2 7.8666C11.3333 7.0666 11.4667 6.39993 11.6 5.59993C8.66667 7.0666 6.8 8.93326 5.46667 10.3999C2.4 13.8667 1.73333 18.6667 5.2 21.8667C6.93333 23.4667 8.8 24.6667 10.9333 25.6C12.2667 26.1333 13.6 26.6667 15.0667 26.9333"
        stroke="#E8E5E2"
        strokeWidth="1.5"
        strokeMiterlimit="13.3333"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M22 11.3333H22.0133"
        stroke="#E8E5E2"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M25.8667 17.2C27.0667 17.4667 28.2667 16.8 28.6667 15.6C28.8 14.9333 29.0667 14.4 29.0667 13.7333L22.8 8.00006C23.0667 7.46673 23.4667 6.93339 23.8667 6.26673C22.5333 6.40006 21.3333 6.53339 20.1333 6.93339C20.2667 6.26673 20.2667 5.73339 20.4 5.06673C18.4 5.86673 16.5333 6.93339 15.0667 8.26673"
        stroke="#E8E5E2"
        strokeWidth="1.5"
        strokeMiterlimit="13.3333"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}
