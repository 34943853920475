import { globalPostAction } from "../../actions/globalActions";
import { END_POINTS } from "../../Constant/Api";
import {  SHOW_MESSAGE } from "../../Constant/General";

export function addEditTransport(params: any,type:any) {
  let apiUrl = END_POINTS.editTransport;
  if(type=='add'){
    apiUrl = END_POINTS.addTransport;
  }
  return globalPostAction(params, apiUrl, SHOW_MESSAGE);
}

export function editTransport(params: any) {
  let apiUrl = END_POINTS.addTransport;
  return globalPostAction(params, apiUrl, SHOW_MESSAGE);
}

export function listOfTransport(params: any) {
  let apiUrl = END_POINTS.listTransport;
  return globalPostAction(params, apiUrl);
}

export function destroyTransport(params: any) {
  let apiUrl = END_POINTS.destroyTransport;
  return globalPostAction(params, apiUrl , SHOW_MESSAGE);
}

export function viewTransport(params: any) {
  let apiUrl = END_POINTS.viewTransport;
  return globalPostAction(params, apiUrl);
}
