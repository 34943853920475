import { ClassNames } from "@emotion/react";
import type { DatePickerProps } from "antd";
import { t } from "i18next";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { Col, Row } from "react-bootstrap";
import Modal from "react-bootstrap/Modal";
import { useDispatch, useSelector } from "react-redux";
import { useLoading } from "../../../context/LoadingContext";
import { addEditBreedingService } from "../../../Redux/NonRedux/breeding";
import { getSlugNamefromId } from "../../../Utility/General";
import validate from "../../../Utility/Validation";
import CustomeButton from "../../Common/CustomButton/CustomButton";
import Datepicker from "../../Common/Datepicker/Datepicker";
import InputGroups from "../../Common/InputGroups/InputGroups";
import InputSelectGroup from "../../Common/InputSelectGroup/InputSelectGroup";
import InputSelectMix from "../../Common/InputSelectMix/InputSelectMix";
import SelectGropus from "../../Common/SelectGroups/SelectGroups";
import TextButton from "../../Common/TextButton/TextButton";
import ToggleButton from "../../Common/ToggleButton/ToggleButton";
import TooltipContent from "../../Common/TooltipContent/TooltipContent";

export default function AddBreedingServiceMarePopup(props: any) {
  const INTIAL_DATA = {
    id: {
      value: "",
    },
    dates: {
      value: moment().format("YYYY-MM-DD"),
      validation: ["required"],
      errors: [t("Date is required.")],
    },
    amount: {
      value: "",
      validation: [""],
      errors: [t("Amount is required.")],
    },

    service_type: {
      value: "",
      validation: ["required"],
      errors: [t("Service Type is required.")],
    },
    flush_status: {
      value: "2",
      validation: ["required"],
      errors: [t("Flush Status is required.")],
    },
    select_vet_clinic: {
      value: "vet_clinic_id",
      // validation: ["requiredIf:vet_clinic_id"],
      errors: ["Vet Clinic is required."],
    },
    vet_clinic: {
      value: "",
      validation: ["requiredIf:select_vet_clinic:vet_clinic", "letters"],
      errors: [t("Vet Clinic is required."), t("Incorrect format")],
    },
    vet_clinic_id: {
      value: "",
      validation: ["requiredIf:select_vet_clinic:vet_clinic_id"],
      errors: [t("Vet Clinic is required.")],
    },
    select_stallion: {
      value: "stallion_id",
      // validation: ["requiredIf:vet_clinic_id"],
      errors: ["Vet Clinic is required."],
    },
    stallion_id: {
      value: "",
      validation: ["requiredIf:select_stallion:stallion_id"],
      errors: [t("Stallion is required.")],
    },
    stallion: {
      value: "",
      validation: ["requiredIf:select_stallion:stallion", "letters"],
      errors: [t("Stallion is required."), t("Incorrect format")],
    },
  };

  const dispatch = useDispatch();

  const prefrence = useSelector(
    (state: any) => state?.masterReducer?.data?.component?.preferance
  );

  const horseInfo = useSelector(
    (state: any) => state?.horseInfoReducer?.data?.component
  );

  const [isFormValidated, setIsFormValidated] = useState(false);
  const [dataError, setDataError] = useState<any>({});
  const [serviceData, setServiceData] = useState<any>(INTIAL_DATA);

  const { setLoading } = useLoading();
  const [inputSelectToggle, setInputSelectToggle] = useState("select");
  const [stallionToggle, setStallionToggle] = useState("select");
  const setData = (field: string, value: any) => {
    let ob = Object.assign({}, serviceData);
    ob[field].value = value;
    setServiceData({ ...ob });
  };

  const dateChange: DatePickerProps["onChange"] = (date, dateString) => {
    let dateIs = date?.format("YYYY-MM-DD");
    setData("dates", dateIs);
  };

  useEffect(() => {
    if (props.isedit && props?.editData) {
      Object.keys(serviceData).forEach((element) => {
        if (typeof props?.editData?.[element] !== "undefined") {
          setData(element, props?.editData?.[element]);
        }
      });
      if (props?.editData?.["stallion_id"]) {
        setStallionToggle("select");
        setData("stallion", "");
        setData("select_stallion", "stallion_id");
      } else {
        setStallionToggle("input");
        setData("stallion_id", "");
        setData("select_stallion", "stallion");
      }
    }
  }, [props?.editData, props.isedit]);

  useEffect(() => {
    setDataError({ ...validate(serviceData).errors });
  }, [serviceData]);

  const submitHandler = () => {
    if (validate(serviceData).isValidated === true) {
      setLoading(true);
      let params: any = {};
      Object.keys(serviceData).forEach((element) => {
        if (serviceData[element].value) {
          params[element] = serviceData[element].value;
        }
      });
      params["horse_id"] = props.horseId;
      addEditBreedingService(params)
        .then((response: any) => {
          props.onHide();
          props.setRefreshList();
        })
        .catch((error: any) => {})
        .finally(() => {
          setLoading(false);
        });
    } else {
      setDataError({ ...validate(serviceData)?.errors });
      setIsFormValidated(true);
    }
  };

  useEffect(() => {
    if (props?.show === false) {
      setDataError({});
      setServiceData(INTIAL_DATA);
      setIsFormValidated(false);
      setInputSelectToggle("select");
      setStallionToggle("select");
    }
  }, [props?.show]);

  useEffect(() => {
    let slug = getSlugNamefromId(
      props?.metaData?.service_type,
      serviceData?.service_type?.value
    );
    if (slug === "embryo_transfer") {
      setData("flush_status", 1);
    } else {
      setData("flush_status", 2);
    }
  }, [serviceData?.service_type?.value]);

  const toggleChange = (selectType: string, input: string) => {
    if (selectType === "select") {
      setData(`select_${input}`, `${input}_id`);
      setData(input, "");
    } else {
      setData(`select_${input}`, input);
      setData(`${input}_id`, "");
    }
  };

  const { horseId, editData, metaData, setRefreshList, ...rest } =
    props;

  return (
    <Modal {...rest} centered className="add-breeding-service-popup modal-784">
      <Modal.Header closeButton>
        <p className="tl">
          {" "}
          {props.isedit
            ? t("Edit Breeding Service")
            : t("Add Breeding Service")}{" "}
        </p>
      </Modal.Header>
      <Modal.Body>
        <Row>
          <Col sm={6} md={6}>
            <Datepicker
              endDate={moment().format("YYYY-MM-DD")}
              label={`${t("Date")}*`}
              onChange={dateChange}
              value={moment(serviceData?.dates?.value)}
              error={
                isFormValidated && dataError["dates"]
                  ? dataError["dates"]
                  : null
              }
            />
          </Col>
        </Row>
        <Row>
          <Col sm={6} md={6}>
            <InputSelectGroup
              label={`${t("Vet / Clinic")}*`}
              selectValue={metaData?.vet_clinic}
              dropKey="id"
              dropVal="first_name"
              defaultSelect={serviceData?.vet_clinic_id?.value}
              value={serviceData?.vet_clinic?.value}
              inputSelectToggle={inputSelectToggle}
              setInputSelectToggle={(val: string) => {
                setInputSelectToggle(val);
                toggleChange(val, "vet_clinic");
              }}
              error={
                isFormValidated &&
                (dataError["vet_clinic"] || dataError["vet_clinic_id"])
                  ? dataError["vet_clinic"] || dataError["vet_clinic_id"]
                  : null
              }
              onSelectChange={(e: any) => {
                setData("vet_clinic_id", e);
                setData("vet_clinic", "");
              }}
              onInputChange={(e: any) => {
                setData("vet_clinic", e.target.value);
                setData("vet_clinic_id", "");
              }}
            />
          </Col>
        </Row>
        <Row>
          <Col sm={6} md={6}>
            <SelectGropus
              label={`${t("Service Type")}*`}
              value={metaData?.service_type}
              defaultSelect={serviceData?.service_type.value}
              onChange={(e: any) => setData("service_type", e.target.value)}
              dropKey="id"
              dropVal="metadata"
              error={
                isFormValidated && dataError["service_type"]
                  ? dataError["service_type"]
                  : null
              }
            />
          </Col>
          <Col sm={6} md={6}>
            <ToggleButton
              value={metaData?.flush_status || []}
              toggleKey="key"
              disabled={true}
              toggleVal="value"
              selected={serviceData?.flush_status?.value}
              setSelected={(e: any) => {
                setData("flush_status", e);
              }}
            >
              <TooltipContent
                title={t("To be flushed?")}
                tooltipValue="this is tooltip value."
              />
            </ToggleButton>
          </Col>
        </Row>
        <Row>
          <Col sm={6} md={6}>
            <InputGroups
              label={`${t("Mare")}`}
              type="text"
              readOnly={true}
              autoComplete="off"
              value={horseInfo?.name}
            />
          </Col>
          <Col sm={6} md={6}>
            <InputSelectGroup
              label={`${t("Stallion")}*`}
              selectValue={metaData?.stallion}
              onSelectChange={(value: any) => {
                setData("stallion_id", value);
              }}
              defaultSelect={serviceData?.stallion_id.value}
              dropKey="id"
              dropVal="name"
              inputValue={serviceData?.stallion.value}
              inputSelectToggle={stallionToggle}
              setInputSelectToggle={(val: string) => {
                setStallionToggle(val);
                toggleChange(val, "stallion");
              }}
              onInputChange={(e: any) => {
                setData("stallion", e.target.value);
              }}
              error={
                isFormValidated &&
                (dataError["stallion_id"] || dataError["stallion"])
                  ? dataError["stallion_id"] || dataError["stallion"]
                  : null
              }
            />
          </Col>
          <Col sm={6} md={6}>
            <InputSelectMix
              label={`${t("Amount")}`}
              inputType="number"
              onInputChange={(e: any) => setData("amount", e.target.value)}
              inputValue={serviceData?.amount?.value}
              inputError={
                isFormValidated && dataError["amount"]
                  ? dataError["amount"]
                  : null
              }
              inputSecondValue={prefrence?.currency_value}
            />
          </Col>
        </Row>
      </Modal.Body>
      <Modal.Footer>
        <div className="footer-2-btn">
          <TextButton onClick={props?.onHide}> {t("Cancel")}</TextButton>
          <CustomeButton bg="fill" onClick={submitHandler}>
            {" "}
            {props.isedit ? t("Update") : t("Add")}
          </CustomeButton>
        </div>
      </Modal.Footer>
    </Modal>
  );
}
