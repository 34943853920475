import { END_POINTS } from "../../../Constant/Api";
import { SHOW_MESSAGE } from "../../../Constant/General";
import { globalPostActionWithReducer } from "../../globallActions";
import {
  ADD_DIET_REQUEST,
  ADD_DIET_SUCCESS,
  ADD_DIET_FAILD,
  ADD_DIET_CLEAR,
  ADD_DIET_LONG,
  GET_DIET_LIST_FAILD,
  GET_DIET_LIST_LONG,
  GET_DIET_LIST_REQUEST,
  GET_DIET_LIST_SUCCESS,
  DESTROY_DIET_FAILD,
  DESTROY_DIET_LONG,
  DESTROY_DIET_REQUEST,
  DESTROY_DIET_SUCCESS,
  VIEW_DIET_FAILD,
  VIEW_DIET_LONG,
  VIEW_DIET_REQUEST,
  VIEW_DIET_SUCCESS,
} from "./DietType";

export function addEditDietAction(params: any) {
  let apiUrl = END_POINTS.dietAddEdit;
  if ( Array.isArray(params.horse_id)) {
    apiUrl = END_POINTS.bulkDietAdd;
  }
  return globalPostActionWithReducer(
    params,
    apiUrl,
    ADD_DIET_REQUEST,
    ADD_DIET_SUCCESS,
    ADD_DIET_LONG,
    ADD_DIET_FAILD,
    SHOW_MESSAGE
  );
}

export function getDietListAction(params: any) {
  let apiUrl = END_POINTS.dietList;
  return globalPostActionWithReducer(
    params,
    apiUrl,
    GET_DIET_LIST_REQUEST,
    GET_DIET_LIST_SUCCESS,
    GET_DIET_LIST_LONG,
    GET_DIET_LIST_FAILD
  );
}

export function destroyDietAction(params: any) {
  let apiUrl = END_POINTS.destroyDiet;
  return globalPostActionWithReducer(
    params,
    apiUrl,
    DESTROY_DIET_REQUEST,
    DESTROY_DIET_SUCCESS,
    DESTROY_DIET_LONG,
    DESTROY_DIET_FAILD,
    SHOW_MESSAGE
  );
}

export function viewDietAction(params: any) {
  let apiUrl = END_POINTS.dietView;
  return globalPostActionWithReducer(
    params,
    apiUrl,
    VIEW_DIET_REQUEST,
    VIEW_DIET_SUCCESS,
    VIEW_DIET_LONG,
    VIEW_DIET_FAILD
  );
}
