import { t } from "i18next";
import React, { useState } from "react";
import { Modal, Row, Col } from "react-bootstrap";
import CustomeButton from "../../Common/CustomButton/CustomButton";
import TextButton from "../../Common/TextButton/TextButton";
import ToggleButton from "../../Common/ToggleButton/ToggleButton";
export default function BulkPayrollEditPopup(props: any) {
  const {
    length,
    setRefreshList,
    bulkPayrolUpdate,
    statusList,
    ...rest
  } = props;
  const [payrollStatus, setPayrolStatus] = useState<any>("1");
  return (
    <Modal {...rest} centered className="add-staff-popup modal-784">
      <Modal.Header closeButton>
        <p className="tl">
          {t("Update payroll for")} {length} {t("staff")}
        </p>
      </Modal.Header>
      <Modal.Body>
        <Row>
          <Col sm={6} md={6}>
            <ToggleButton
              value={statusList}
              toggleKey="key"
              toggleVal="value"
              label={`${t("Status")}`}
              selected={payrollStatus}
              setSelected={(val: string) => {
                setPayrolStatus(val);
              }}
            />
          </Col>
        </Row>
      </Modal.Body>
      <Modal.Footer>
        <div className="footer-2-btn">
          <TextButton onClick={props.onHide}> {t("Cancel")}</TextButton>
          <CustomeButton bg="fill" onClick={() => bulkPayrolUpdate(payrollStatus)}>
            {t("Update")}
          </CustomeButton>
        </div>
      </Modal.Footer>
    </Modal>
  );
}
