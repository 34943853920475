import React, { useEffect, useState } from "react";
import { Col, Row } from "react-bootstrap";
import Modal from "react-bootstrap/Modal";
import CustomeButton from "../../Common/CustomButton/CustomButton";
import InputGroups from "../../Common/InputGroups/InputGroups";
import TextButton from "../../Common/TextButton/TextButton";
import HorseWidth from "../../../Assets/img/horse-width.png";
import validate from "../../../Utility/Validation";
import { calculateWeight } from "../../../Utility/General";
import { MEASUREMENT } from "../../../Constant/General";
import { useSelector } from "react-redux";
import { t } from "i18next";

export default function BodyWeightCalcuPopup(props: any) {
  const INTIAL_WEIGHT_CAL_DATA = {
    girth: {
      value: "",
      validation: ["required"],
      errors: [t("Girth is required.")],
    },
    length: {
      value: "",
      validation: ["required"],
      errors: [t("Length is required.")],
    },
  };

  const [weightCalData, setWeightCalData] = useState<any>(
    INTIAL_WEIGHT_CAL_DATA
  );
  const [weightCalError, setWeightCalError] = useState<any>({});
  const [isFormValidated, setIsFormValidated] = useState(false);

  const prefrence = useSelector(
    (state: any) => state?.masterReducer?.data?.component?.preferance
  );
  
  const setData = (field: string, value: any) => {
    let weightData: any = Object.assign({}, weightCalData);
    weightData[field].value = value;
    setWeightCalData({ ...weightData });
  };

  const handleCalWeight = () => {
    if (validate(weightCalData).isValidated === true) {
      props.calculate(weightCalData?.girth?.value , weightCalData?.length?.value);  
    } else {
      setIsFormValidated(true);
      setWeightCalError({ ...validate(weightCalData).errors });
    }
  };

  useEffect(() => {
    if (isFormValidated) {
      setWeightCalError({...validate(setWeightCalData).errors})
    }
  }, [weightCalData]);

  useEffect(() => {
    if (props?.show === false) {
      setWeightCalError({});
      setWeightCalData(INTIAL_WEIGHT_CAL_DATA);
      setIsFormValidated(false);
    }
  }, [props?.show]);

  const { calculate , ...rest } = props;

  return (
    <Modal {...rest} centered className="body-weight-calcu-popup modal-784">
      <Modal.Header closeButton>
        <p className="tl">{t("Body Weight Calculator")}</p>
      </Modal.Header>
      <Modal.Body>
        <Row>
          <Col sm={12} md={6}>
            <img src={HorseWidth} alt="" className="horse-img" />
          </Col>
          <Col sm={12} md={6}>
            <p className="bl">
            {t("weight_description")}
            </p>
            <InputGroups
              label={`${t("Girth")} (${prefrence?.heightUnit_value})*`}
              type="number"
              value={weightCalData?.girth?.value}
              onChange={(e: any) => setData("girth", e.target.value)}
              error={
                isFormValidated && weightCalError["girth"]
                  ? weightCalError["girth"]
                  : null
              }
            />
            <InputGroups
              label={`${t("Length")} (${prefrence?.heightUnit_value})*`}
              type="number"
              value={weightCalData?.length?.value}
              onChange={(e: any) => setData("length", e.target.value)}
              error={
                isFormValidated && weightCalError["length"]
                  ? weightCalError["length"]
                  : null
              }
            />
            <div className="footer-2-btn">
              <TextButton onClick={props.onHide}> {t("Cancel")}</TextButton>
              <CustomeButton bg="fill" onClick={handleCalWeight}>
              {t("Calculate")} 
              </CustomeButton>
            </div>
          </Col>
        </Row>
      </Modal.Body>
    </Modal>
  );
}
