import { t } from "i18next";
import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useOutletContext, useParams } from "react-router-dom";
import AddPlusIcon from "../../../Assets/icon/AddPlusIcon";
import binIcon from "../../../Assets/img/bin.svg";
import PaperClipIcon from "../../../Assets/img/Common-icon/PaperClipIcon";
import pencilIcon from "../../../Assets/img/pencil.svg";
import { useLoading } from "../../../context/LoadingContext";
import { horseInfoAction } from "../../../Redux/Horse/HorseAction";
import {
  destroyBreedingControl,
  viewBreedingControl,
} from "../../../Redux/NonRedux/breeding";
import { encrDecrStr, openDocument } from "../../../Utility/General";
import CustomeButton from "../../Common/CustomButton/CustomButton";
import NoDataFound from "../../Common/NoDataFound/NoDataFound";
import ConfirmPopup from "../../Popups/ConfirmPopup/ConfirmPopup";

export default function ControllList({
  listData,
  setRefreshList,
  canEditService,
  canAddControll,
  editHandler,
  serviceId,
  metaData,
  handleAddBreedingControl
}: any) {
  const [deletableRecords, setDeletableRecords] = useState<any>([]);
  const [confirmPopupShow, setConfirmPopupShow] = useState(false);
  const { setLoading } = useLoading();


  const param = useParams();
  const dispatch = useDispatch();
  const query = new URLSearchParams(window.location.search);
  const prefrence = useSelector(
    (state: any) => state?.masterReducer?.data?.component?.preferance
  );

  const deleteHandler = () => {
    setLoading(true);
    let params = {
      id: deletableRecords,
      service_id: encrDecrStr(query.get("service_id"), "decr"),
    };
    destroyBreedingControl(params)
      .then((res: any) => {
        setConfirmPopupShow(false);
        setDeletableRecords([]);
        setRefreshList();
        horseInfoAction({ id: encrDecrStr(param?.slug, "decr") });
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const deleteNow = (id: number) => {
    let ids = deletableRecords;
    ids.push(id);
    setDeletableRecords(ids);
    setConfirmPopupShow(true);
  };

  return (
    <React.Fragment>
      <div className="data-tbl-wrapper">
        <div className="data-tbl-header">
          <p className="tl">{t("Breeding Control")}</p>
          <div className="btn-wrapper">
            {canAddControll && (
              <CustomeButton
                bg="fill"
                onClick={handleAddBreedingControl}
              >
                <AddPlusIcon />
                {t("Add Control")}
              </CustomeButton>
            )}
          </div>
        </div>
        <div className="data-tbl">
          <table>
            <thead>
              <tr>
                <th>
                  <p className="tm">{t("Date")}</p>
                </th>
                <th>
                  <p className="tm">{t("Vet/Clinic")}</p>
                </th>
                <th>
                  <p className="tm">{t("Check Method")}</p>
                </th>
                <th>
                  <p className="tm">{t("Pregnancy Status")}</p>
                </th>
                <th>
                  <p className="tm">{t("Documents")}</p>
                </th>
                <th>
                  <p className="tm">{t("Amount")}</p>
                </th>
                <th className="text-right">
                  <p className="tm">{t("Action")}</p>
                </th>
              </tr>
            </thead>
            <tbody>
              {listData?.map((items: any, index: number) => (
                <tr key={index + "breeding-conteol-mare"}>
                  <td>
                    <p className="bl">{items?.dates}</p>
                  </td>
                  <td>
                    <p className="bl">{items?.vat_clinic}</p>
                  </td>
                  <td>
                    <p className="bl">{items?.check_method_value}</p>
                  </td>
                  <td>
                    <p className="bl">{items?.pregnancy_status_value}</p>
                  </td>
                  <td>
                    {items?.document != "" ? (
                      <a onClick={() => openDocument(items?.document)}>
                        <PaperClipIcon />
                      </a>
                    ) : (
                      ""
                    )}
                  </td>
                  <td>
                    <p className="bl">
                      {items?.amount} {prefrence?.currency_value}
                    </p>
                  </td>
                  <td>
                    <div className="icon-wrapper">
                      {(metaData?.activeService == serviceId ||
                        (canEditService && index === 0)) && (
                        <>
                          <a>
                            <img
                              src={pencilIcon}
                              onClick={() => editHandler(items?.id)}
                              alt=""
                            />
                          </a>
                          <a>
                            <img
                              src={binIcon}
                              onClick={() => deleteNow(items?.id)}
                              alt=""
                            />
                          </a>
                        </>
                      )}
                    </div>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
        {!listData?.length && <NoDataFound />}
      </div>

      <ConfirmPopup
        heading={t("Delete")}
        message={t("Are you sure to delete this record?")}
        show={confirmPopupShow}
        onHide={() => {
          setConfirmPopupShow(false);
          setDeletableRecords([]);
        }}
        confirm={deleteHandler}
      />
    </React.Fragment>
  );
}
