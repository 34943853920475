import { END_POINTS } from "../../Constant/Api"
import { SHOW_MESSAGE } from "../../Constant/General";
import { globalDownloadAction, globalGetAction, globalPostAction } from '../globalActions';

export const addEditAchievementAction = (payload : any) => {
    let apiUrl = END_POINTS.addEditAchievement;
    return globalPostAction(payload , apiUrl , SHOW_MESSAGE);
}

export const destroyAchievements = (payload : any) => {
    let apiUrl = END_POINTS.destroyAchievement;
    return globalPostAction(payload , apiUrl , SHOW_MESSAGE);
}

export const getAchieventList = (payload:any) => {
   let apiUrl = END_POINTS.achievementList;
   return globalPostAction(payload , apiUrl);
}

export const getSingleAchivement = (payload:any) => {
    let apiUrl = END_POINTS.achivementView;
    return globalPostAction(payload , apiUrl)
}

export const getDocumentsList = (payload:any) => {
    let apiUrl = END_POINTS.documentList;
    return globalPostAction(payload , apiUrl)
}

export const uploadDocument = (payload:any) => {
    let apiUrl = END_POINTS.uploadDocument;
    return globalPostAction(payload , apiUrl , SHOW_MESSAGE)
}

export const destroyDocument = (payload:any) => {
    let apiUrl = END_POINTS.destroyDocument;
    return globalPostAction(payload , apiUrl , SHOW_MESSAGE)
}

export const getNoteList = (payload:any) => {
    let apiUrl = END_POINTS.noteList;
    return globalPostAction(payload , apiUrl)
}

export const addEditNote = (payload:any) => {
    let apiUrl = END_POINTS.addEditNote;
    return globalPostAction(payload , apiUrl , SHOW_MESSAGE)
}

export const destroyNote = (payload : any) => {
    let apiUrl = END_POINTS.destroyNote;
    return globalPostAction(payload , apiUrl , SHOW_MESSAGE)
}

export const getSingleNote = (payload : any) => {
    let apiUrl = END_POINTS.noteView;
    return globalPostAction(payload , apiUrl)
} 

export const addEditImageVideo = (payload : any) => {
    let apiUrl = END_POINTS.addEditImageVideo;
    return globalPostAction(payload , apiUrl , SHOW_MESSAGE)
}

export const getImageList = (payload:any) => {
    let apiUrl = END_POINTS.imageList;
    return globalPostAction(payload , apiUrl)
}

export const getVideoList = (payload:any) => {
    let apiUrl = END_POINTS.videoList;
    return globalPostAction(payload , apiUrl)
}

export const getSingleImageVideo = (payload:any) => {
    let apiUrl = END_POINTS.viewImageVideo;
    return globalPostAction(payload , apiUrl)
}

export const destroyImageVideo = (payload:any) => {
    let apiUrl = END_POINTS.destroyImageViode;
    return globalPostAction(payload , apiUrl , SHOW_MESSAGE)
}
  
export const changeVideoImagePrivacy = (payload:any) => {
    let apiUrl = END_POINTS.changeVideoImagePrivacy;
    return globalPostAction(payload , apiUrl)
}

export const getCompletePedigree = (payload:any) => {
    let apiUrl = END_POINTS.getCompletePedigree;
    return globalPostAction(payload , apiUrl)
}

export const editPedigree = (payload:any) => {
    let apiUrl = END_POINTS.editPedigree;
    return globalPostAction(payload , apiUrl , SHOW_MESSAGE)
}

export const addEditPublicLink = (payload:any) =>{
    let apiUrl = END_POINTS.addEditPublicLink;
    return globalPostAction(payload , apiUrl)
}

export const addEditInsurace = (payload:any) => {
    let apiUrl = END_POINTS.addEditInsurance;
    return globalPostAction(payload , apiUrl , SHOW_MESSAGE)
}

export const downloadDocument = (payload: any, fileName: any) => {
  let apiUrl = END_POINTS.downloadDocument;
  return globalDownloadAction(payload, apiUrl, fileName);
};