import React from "react";

export default function ListIcon() {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M18.7309 18.1003V18.9757C18.7309 19.8511 17.8555 20.3982 17.0895 20.0699L15.3388 19.304C15.0105 19.1945 14.5728 19.1945 14.2445 19.4134L11.8373 20.8359C11.509 21.0547 10.9619 21.0547 10.6336 20.8359L8.22631 19.4134C7.89805 19.1945 7.46036 19.1945 7.13209 19.304L5.38133 20.0699C4.61537 20.3982 3.73999 19.8511 3.73999 18.9757V4.20365C3.73999 3.54711 4.2871 3 4.94364 3H17.6366C18.2932 3 18.8403 3.54711 18.8403 4.20365V6.72036"
        stroke="#919EAB"
        strokeWidth="1.5"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M7.89804 7.04865H14.4634"
        stroke="#919EAB"
        strokeWidth="1.5"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M7.89804 10.8784H14.4634"
        stroke="#919EAB"
        strokeWidth="1.5"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M7.89804 14.8176H14.4634"
        stroke="#919EAB"
        strokeWidth="1.5"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M19.8251 10.6596C19.6062 10.4407 19.278 10.3313 18.8403 10.3313C17.9649 10.3313 17.5272 10.8784 17.5272 11.3161C17.5272 12.1915 18.4026 12.3009 18.9497 12.5197C19.4968 12.6292 20.2628 12.848 20.2628 13.614C20.2628 14.0517 19.8251 14.5988 18.8403 14.5988C18.1837 14.5988 17.746 14.3799 17.5272 14.0517"
        stroke="#919EAB"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M18.8403 10.3313V9.12769"
        stroke="#919EAB"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M18.8403 15.693V14.5988"
        stroke="#919EAB"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}
