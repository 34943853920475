import React from "react";
import Tooltip from '@mui/material/Tooltip';
import InfoIcon from "../../../Assets/img/info.svg";

export default function TooltipContent(props:any) {
  return (
    <div className="title-info">
      <p className="bs">{props.title}</p>
      <Tooltip className="hello" title={props.tooltipValue} arrow={true}>
        <img src={InfoIcon} alt="info" />
      </Tooltip>
    </div>
  );
}
