import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { Outlet, useLocation, useNavigate, useOutletContext, useParams } from "react-router-dom";
import BandageIcon from "../../../Assets/img/Inner-tab-icon/BandageIcon";
import ClinincIcon from "../../../Assets/img/Inner-tab-icon/ClinincIcon";
import FilmIcon from "../../../Assets/img/Inner-tab-icon/FilmIcon";
import HeartPulseIcon from "../../../Assets/img/Inner-tab-icon/HeartPulseIcon";
import MicroscopeIcon from "../../../Assets/img/Inner-tab-icon/MicroscopeIcon";
import ParasiteControlIcon from "../../../Assets/img/Inner-tab-icon/ParasiteControlIcon";
import SyringeIcon from "../../../Assets/img/Inner-tab-icon/SyringeIcon";
import ToothIcon from "../../../Assets/img/Inner-tab-icon/ToothIcon";
import {
  HORSEDETAILS_HEALTH_DENTAL,
  HORSEDETAILS_HEALTH_LABTESTS,
  HORSEDETAILS_HEALTH_PARASITECONTROL,
  HORSEDETAILS_HEALTH_TREATMENTS,
  HORSEDETAILS_HEALTH_VACCINATION,
  HORSEDETAILS_HEALTH_VETVISITS,
  HORSEDETAILS_HEALTH_VITALSIGNS,
  HORSEDETAILS_HEALTH_XRAY,
} from "../../../Constant/Route";
import InnerTab from "../../Layouts/InnerTab/InnerTab";

export default function HealthPage() {
  const { t, i18n } = useTranslation();
  const [innerTab, setInnerTab] = React.useState(0);
  const param = useParams();
  const innerTabHandleChange = (
    event: React.SyntheticEvent,
    newValue: number
  ) => {
    setInnerTab(newValue);
  };
  const innerTabData = [
    {
      index: 0,
      img: <HeartPulseIcon />,
      label: t("Vital Signs"),
      path: HORSEDETAILS_HEALTH_VITALSIGNS+ "/" + param?.slug,
    },
    {
      index: 0,
      img: <SyringeIcon />,
      label: t("Vaccination"),
      path: HORSEDETAILS_HEALTH_VACCINATION+ "/" + param?.slug,
    },
    {
      index: 0,
      img: <ParasiteControlIcon />,
      label: t("Parasite Control"),
      path: HORSEDETAILS_HEALTH_PARASITECONTROL+ "/" + param?.slug,
    },
    {
      index: 0,
      img: <ToothIcon />,
      label: t("Dental"),
      path: HORSEDETAILS_HEALTH_DENTAL+ "/" + param?.slug,
    },
    {
      index: 0,
      img: <ClinincIcon />,
      label: t("Vet/Clinic Visits"),
      path: HORSEDETAILS_HEALTH_VETVISITS+ "/" + param?.slug,
    },
    {
      index: 0,
      img: <BandageIcon />,
      label: t("Treatments"),
      path: HORSEDETAILS_HEALTH_TREATMENTS+ "/" + param?.slug,
    },
    {
      index: 0,
      img: <MicroscopeIcon />,
      label: t("Lab Tests"),
      path: HORSEDETAILS_HEALTH_LABTESTS+ "/" + param?.slug,
    },
    {
      index: 0,
      img: <FilmIcon />,
      label: t("X-Ray"),
      path: HORSEDETAILS_HEALTH_XRAY+ "/" + param?.slug,
    },
  ];

  let navigate = useNavigate();
  const healthRouteChange = (pathName: any) => {
    navigate(pathName);
  };
  const location:any = useLocation();

  useEffect(()=>{
    if (location.pathname.includes(HORSEDETAILS_HEALTH_VITALSIGNS)) {
      setInnerTab(0);
    }else if (location.pathname.includes(HORSEDETAILS_HEALTH_VACCINATION)) {
      setInnerTab(1);
    }else if (location.pathname.includes(HORSEDETAILS_HEALTH_PARASITECONTROL)) {
      setInnerTab(2);
    }else if (location.pathname.includes(HORSEDETAILS_HEALTH_DENTAL)) {
      setInnerTab(3);
    }else if (location.pathname.includes(HORSEDETAILS_HEALTH_VETVISITS)) {
      setInnerTab(4);
    }else if (location.pathname.includes(HORSEDETAILS_HEALTH_TREATMENTS)) {
      setInnerTab(5);
    }else if (location.pathname.includes(HORSEDETAILS_HEALTH_LABTESTS)) {
      setInnerTab(6);
    }else if (location.pathname.includes(HORSEDETAILS_HEALTH_XRAY)) {
      setInnerTab(7);
    }
    // location.pathname == DASHBOARD ? "active" : "";
    // setInnerTab(1);
  },[location?.pathname])

  return (
    <div className="health-horse-page">
      <InnerTab
        value={innerTab}
        healthRouteChange={healthRouteChange}
        handleChange={innerTabHandleChange}
        parentTabData={innerTabData}
      />
      <div>
        <Outlet context={useOutletContext()}/>
      </div>
    </div>
  );
}
