import React, { useEffect, useState } from "react";
import { Col, Row } from "react-bootstrap";
import AddPicturePopup from "../../Popups/AddPicturePopup/AddPicturePopup";
import AddVideoPopup from "../../Popups/AddVideoPopup/AddVideoPopup";
import {
  changeVideoImagePrivacy,
  destroyImageVideo,
  getImageList,
  getSingleImageVideo,
  getVideoList,
} from "../../../actions/Horse/MyHorseActions";
import { PICTUREVIDEOTYPE, UPGRADE_MESSAGE } from "../../../Constant/General";
import SinglePicture from "./SinglePicture";
import SingleVideo from "./SingleVideo";
import { useDispatch } from "react-redux";
import { useParams } from "react-router-dom";
import { API_STATUS } from "../../../Constant/Api";
import { SUCCESS_RESPONSE_CODE } from "../../../Constant/Status";
import { encrDecrStr } from "../../../Utility/General";
import ConfirmPopup from "../../Popups/ConfirmPopup/ConfirmPopup";
import NoDataFound from "../../Common/NoDataFound/NoDataFound";
import { useTranslation } from "react-i18next";
import { useLoading } from "../../../context/LoadingContext";
import { upgradePopupAction } from "../../../Redux/General/GeneralAction";

export default function PictureVideoPage() {
  const { t, i18n } = useTranslation();

  const param = useParams();
  const dispatch = useDispatch();
  const [horseId, setHorseId] = useState<any>(0);
  const [apiStatus, setApiStatus] = useState(API_STATUS.SUCCESS);
  const [pictureList, setPictureList] = useState([]);
  const [videoList, setVideoList] = useState([]);
  const [deletableRecords, setDeletableRecords] = useState<any>([]);
  const [confirmPopupShow, setConfirmPopupShow] = useState(false);
  const [editData, setEditData] = useState<any>({});
  const [isEdit, setIsEdit] = useState<boolean>(false);
  const [refreshList, setRefreshList] = useState(false);
  const [pictureVideoType, setPictureVideoType] = useState<string>(
    PICTUREVIDEOTYPE.PICTURE
  );
  const [AddPictureModalShow, setAddPictureModalShow] = useState(false);
  const [AddVideoModalShow, setAddVideoModalShow] = useState(false);
  const [canAddImage,setCanAddImage] = useState(true);
  const [canAddVideo,setCanAddVideo] = useState(true)


  // const [picturerecordsTotal, setPictureRecordsTotal] = React.useState(1);
  // const [pictureCurrentPage, setPictureCurrentPage] = React.useState(1);
  // const [picturecurrentPageSize, setpictureCurrentPageSize] = React.useState(2);

  const { setLoading } = useLoading();
  // const [videocordsTotal, setVideoRecordsTotal] = React.useState(1);
  // const [videoCurrentPage, setVideoCurrentPage] = React.useState(1);
  // const [videocurrentPageSize, setVideoCurrentPageSize] = React.useState(2);

  // const paginationSelect = ["2", "4", "6", "8"];

  const getAllImageList = () => {
    let id = encrDecrStr(param?.slug, "decr");
    setHorseId(id);
    let params = {
      horse_id: id,
    };
    setLoading(true);
    getImageList(params)
      .then((response: any) => {
        setPictureList(response?.component?.listing);
        setCanAddImage(response?.component?.can_add_image)
        setLoading(false);
      })
      .catch((error) => {
        setLoading(false);
      });
  };

  const getAllVideoList = () => {
    let id = encrDecrStr(param?.slug, "decr");
    setHorseId(id);
    let params = {
      horse_id: id,
    };
    setLoading(true);
    getVideoList(params)
      .then((response: any) => {
        setLoading(false);
        setVideoList(response?.component?.listing);
        setCanAddVideo(response?.component?.can_add_video)
      })
      .catch((error) => {
        setLoading(false);
      });
  };

  // useEffect(() => {
  //   getAllImageList();
  //   getAllVideoList();
  // }, []);

  useEffect(() => {
    getAllImageList();
    getAllVideoList();
  }, []);

  useEffect(() => {
    if (refreshList) {
      if (pictureVideoType === PICTUREVIDEOTYPE.PICTURE) {
        getAllImageList();
      } else if (pictureVideoType === PICTUREVIDEOTYPE.VIDEO) {
        getAllVideoList();
      }
      setRefreshList(false);
    }
  }, [refreshList]);

  // useEffect(() => {
  //   getAllImageList();
  // }, [pictureCurrentPage, picturecurrentPageSize]);

  // useEffect(() => {
  //   getAllVideoList();
  // }, [videoCurrentPage, videocurrentPageSize]);

  const editHandler = (id: number, type: string) => {
    setPictureVideoType(type);
    let params = {
      horse_id: horseId,
      id: id,
      type: type,
    };

    setApiStatus(API_STATUS.LOADING);
    setLoading(true);
    getSingleImageVideo(params)
      .then((response: any) => {
        if (response?.statusCode === SUCCESS_RESPONSE_CODE) {
          setApiStatus(API_STATUS.SUCCESS);
          setLoading(false);
          setIsEdit(true);
          setEditData(response?.component);

          if (type === PICTUREVIDEOTYPE.PICTURE) {
            setAddPictureModalShow(true);
          } else if (type === PICTUREVIDEOTYPE.VIDEO) {
            setAddVideoModalShow(true);
          }
        } else {
          setApiStatus(API_STATUS.FAILED);
          setLoading(false);
        }
      })
      .catch((error) => {
        setApiStatus(API_STATUS.FAILED);
        setLoading(false);
      });
  };

  const deleteHandler = () => {
    let params = {
      id: deletableRecords,
      type: pictureVideoType,
    };

    setApiStatus(API_STATUS.LOADING);
    setLoading(true);
    destroyImageVideo(params)
      .then((response: any) => {
        if (response.statusCode === SUCCESS_RESPONSE_CODE) {
          setApiStatus(API_STATUS.SUCCESS);
          setLoading(false);
          setRefreshList(true);
          setConfirmPopupShow(false);
        } else {
          setApiStatus(API_STATUS.FAILED);
          setLoading(false);
        }
      })
      .catch((error) => {
        setApiStatus(API_STATUS.FAILED);
        setLoading(false);
      });
  };

  const deleteNow = (id: number, type: string) => {
    setPictureVideoType(type);
    let ids = deletableRecords;
    ids.push(id);
    setDeletableRecords(ids);
    setConfirmPopupShow(true);
  };

  const changePrivacy = (id: number, type: string, updatedPrivacy: number) => {
    let params = {
      id,
      private: updatedPrivacy ? "1" : "0",
      type,
    };

    changeVideoImagePrivacy(params)
      .then((response: any) => {
        if (response.statusCode === SUCCESS_RESPONSE_CODE) {
          const privatekey = response?.component?.private_key;
          const privateVal = response?.component?.private;
          if (type === PICTUREVIDEOTYPE.VIDEO) {
            let updatedVideoList: any = videoList.map((element: any) =>
              element.id === response?.component?.id
                ? { ...element, private_key: privatekey, private: privateVal }
                : element
            );
            setVideoList(updatedVideoList);
          } else if (type === PICTUREVIDEOTYPE.PICTURE) {
            let updatedPictureList: any = pictureList.map((element: any) =>
              element.id === response?.component?.id
                ? { ...element, private_key: privatekey, private: privateVal }
                : element
            );
            setPictureList(updatedPictureList);
          }
        }
      })
      .catch((error) => {});
  };
  const handlePictureAdd = () => {
    if (!canAddImage) {
      upgradePopupAction({ show: true , message:t(UPGRADE_MESSAGE.ADD_IMAGE)} );
      return;
    }
    setIsEdit(false);
    setEditData({});
    setPictureVideoType(PICTUREVIDEOTYPE.PICTURE);
    setAddPictureModalShow(true);
  };

  const handleVideoAdd = () => {
    if (!canAddVideo) {
      upgradePopupAction({ show: true , message:t(UPGRADE_MESSAGE.ADD_VIDEO)});
      return;
    }
    setIsEdit(false);
    setEditData({});
    setPictureVideoType(PICTUREVIDEOTYPE.VIDEO);
    setAddVideoModalShow(true);
  };

  return (
    <>
      <div className="picture-video-horse-page">
        <Row>
          <Col md={12} lg={6}>
            <div className="card--layout">
              <div className="card--header">
                <p className="tl">{t("Pictures")}</p>
                <div className="btn-wrapper">
                  <a className="a-ll" onClick={handlePictureAdd}>
                    {t("Add")}
                  </a>
                </div>
              </div>
              <div className="card--body">
                <div className="picture-wrapper">
                  {pictureList && pictureList.length > 0 ? (
                    pictureList.map((items: any, index: number) => (
                      <SinglePicture
                        items={items}
                        key={index + "image-data"}
                        editHandler={editHandler}
                        deleteNow={deleteNow}
                        changePrivacy={changePrivacy}
                      />
                    ))
                  ) : (
                    <NoDataFound />
                  )}
                </div>
                {/* {pictureList && pictureList.length > 0 && (
                  <Pagination
                    results={picturerecordsTotal}
                    currentPage={pictureCurrentPage}
                    setCurrentPage={setPictureCurrentPage}
                    currentLength={picturecurrentPageSize}
                    setCurrentPageSize={setpictureCurrentPageSize}
                    paginationSelect={paginationSelect}
                  />
                )} */}
              </div>
            </div>
          </Col>
          <Col md={12} lg={6}>
            <div className="card--layout">
              <div className="card--header">
                <p className="tl">{t("Videos")}</p>
                <div className="btn-wrapper">
                  <a className="a-ll" onClick={handleVideoAdd}>
                    {t("Add")}
                  </a>
                </div>
              </div>
              <div className="card--body">
                <div className="video-wrapper">
                  {videoList && videoList.length > 0 ? (
                    videoList.map((items: any, index: number) => (
                      <SingleVideo
                        items={items}
                        key={index + "image-data"}
                        editHandler={editHandler}
                        deleteNow={deleteNow}
                        changePrivacy={changePrivacy}
                      />
                    ))
                  ) : (
                    <NoDataFound />
                  )}
                </div>
                {/* {videoList && videoList.length > 0 && (
                  <Pagination
                    results={videocordsTotal}
                    currentPage={videoCurrentPage}
                    setCurrentPage={setVideoCurrentPage}
                    currentLength={videocurrentPageSize}
                    setCurrentPageSize={setVideoCurrentPageSize}
                    paginationSelect={paginationSelect}
                  />
                )} */}
              </div>
            </div>
          </Col>
        </Row>
      </div>

      <AddPicturePopup
        show={AddPictureModalShow}
        onHide={() => {
          setAddPictureModalShow(false);
        }}
        horseid={horseId}
        isedit={isEdit ? 1 : 0}
        picturedata={editData}
        setrefreshlist={() => {
          setRefreshList(true);
        }}
      />

      <AddVideoPopup
        show={AddVideoModalShow}
        onHide={() => {
          setAddVideoModalShow(false);
        }}
        horseid={horseId}
        isedit={isEdit ? 1 : 0}
        videodata={editData}
        setrefreshlist={() => {
          setRefreshList(true);
        }}
      />

      <ConfirmPopup
        heading={t("Delete")}
        message={t("Are you sure to delete this record?")}
        show={confirmPopupShow}
        onHide={() => {
          setConfirmPopupShow(false);
          setDeletableRecords([]);
        }}
        confirm={deleteHandler}
      />
    </>
  );
}
