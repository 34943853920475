import { DatePicker } from "antd";
import { t } from "i18next";
import moment, { now } from "moment";
import React, { useEffect, useState } from "react";

export default function Datepicker(props: any) {
  return (
    <div className="input-groups date-picker">
      <label>{props.label}</label>
      <DatePicker
       placeholder={`${t("Select a date")}`}
       allowClear={props?.allowClear ? props?.allowClear : false}
       disabledDate={(d) => {
        if (moment().format("YYYY-MM-DD") === moment().format("YYYY-MM-DD") && props?.notDisableToday) {
          return false
        }
        return !d || 
        (
        (props?.endDate && (d.isAfter((props.endDate)))) 
        ||
        (props?.startDate && d.isBefore(props?.startDate))
        )
       }
        }
        inputReadOnly={true}
        format={props?.format ? props?.format : "DD/MM/YYYY"}
        showToday={false}
        onChange={props.onChange}
        value={props.value ? props.value : undefined}
        defaultValue={props.defaultValue}
        picker={props?.picker}
        className={props.error && "error"}
      />
      {props.error && <span className="error">{props.error}</span>}
    </div>
  );
}
