import { globalDownloadAction, globalPostAction } from "../../actions/globalActions";
import { END_POINTS } from "../../Constant/Api";
import { SHOW_MESSAGE } from "../../Constant/General";

export function addEditInventory(params: any) {
  let apiUrl = END_POINTS.addEditInventory;
  return globalPostAction(params, apiUrl, SHOW_MESSAGE);
}

export function listOfInventory(params: any) {
  let apiUrl = END_POINTS.listInventory;
  return globalPostAction(params, apiUrl);
}

export function destroyInventory(params: any) {
  let apiUrl = END_POINTS.destroyInventory;
  return globalPostAction(params, apiUrl , SHOW_MESSAGE);
}

export function viewInventory(params: any) {
  let apiUrl = END_POINTS.viewInventory;
  return globalPostAction(params, apiUrl);
}

export function exportInventory(params: any , fileName:string) {
  let apiUrl = END_POINTS.exportInventory;
  return globalDownloadAction(params, apiUrl , fileName);
}