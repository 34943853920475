import React from "react";

export default function Farriericon() {
  return (
    <svg
      width="32"
      height="32"
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M22.53 19.8667C22.6634 19.3333 22.7967 18.9333 22.93 18.4"
        stroke="#E8E5E2"
        strokeMiterlimit="5.3333"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M23.3255 15.3357C23.3255 14.9357 23.3255 14.4024 23.3255 14.0024C23.3255 9.86907 19.9921 6.66907 15.9921 6.66907C11.8588 6.66907 8.65881 10.0024 8.65881 14.0024C8.65881 14.9357 8.79216 16.0024 8.9255 16.9357"
        stroke="#E8E5E2"
        strokeMiterlimit="5.3333"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeDasharray="2.01 2.01"
      />
      <path
        d="M9.08441 18.4148C9.21774 18.9481 9.35108 19.3481 9.48442 19.8815"
        stroke="#E8E5E2"
        strokeMiterlimit="5.3333"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M24.0126 22.6667C24.9459 20.8 25.7459 17.3333 25.8792 14.4C26.0125 8.93335 21.8792 4.26667 16.5459 4.00001C10.9459 3.73334 6.27924 8.26668 6.27924 13.8667C6.27924 16.9333 6.67923 20.1333 8.14589 22.6667C8.94589 24 11.7459 27.7333 11.7459 27.7333C12.8126 27.7333 13.7459 26.8 13.7459 25.7333V23.6C13.7459 23.0667 13.6126 22.4 13.3459 21.8667C12.0126 19.4667 11.3459 16.8 11.3459 14C11.3459 11.3333 13.3459 8.93334 16.0126 8.80001C18.8126 8.66667 21.0792 10.9333 21.0792 13.7333C21.0792 16.5333 20.4126 19.3333 19.0792 21.8667C18.8126 22.4 18.6792 22.9333 18.6792 23.6V25.7333C18.6792 26.8 19.6126 27.7333 20.6792 27.7333C20.6792 27.7333 23.3459 24 24.0126 22.6667Z"
        stroke="#E8E5E2"
        strokeWidth="1.5"
        strokeMiterlimit="13.3333"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}
