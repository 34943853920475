
export const ADD_MEASUREMENTS_REQUEST = "ADD_MEASUREMENTS_REQUEST";
export const ADD_MEASUREMENTS_SUCCESS = "ADD_MEASUREMENTS_SUCCESS";
export const ADD_MEASUREMENTS_FAILD = "ADD_MEASUREMENTS_FAILD";
export const ADD_MEASUREMENTS_LONG = "ADD_MEASUREMENTS_LONG";
export const ADD_MEASUREMENTS_CLEAR = "ADD_MEASUREMENTS_CLEAR";

export const GET_MEASUREMENTS_LIST_REQUEST = "GET_MEASUREMENTS_LIST_REQUEST";
export const GET_MEASUREMENTS_LIST_SUCCESS = "GET_MEASUREMENTS_LIST_SUCCESS";
export const GET_MEASUREMENTS_LIST_FAILD = "GET_MEASUREMENTS_LIST_FAILD";
export const GET_MEASUREMENTS_LIST_LONG = "GET_MEASUREMENTS_LIST_LONG";
export const GET_MEASUREMENTS_LIST_CLEAR = "GET_MEASUREMENTS_LIST_CLEAR";

export const DESTROY_MEASUREMENTS_REQUEST = "DESTROY_MEASUREMENTS_REQUEST";
export const DESTROY_MEASUREMENTS_SUCCESS = "DESTROY_MEASUREMENTS_SUCCESS";
export const DESTROY_MEASUREMENTS_FAILD = "DESTROY_MEASUREMENTS_FAILD";
export const DESTROY_MEASUREMENTS_LONG = "DESTROY_MEASUREMENTS_LONG";
export const DESTROY_MEASUREMENTS_CLEAR = "DESTROY_MEASUREMENTS_CLEAR";

export const VIEW_MEASUREMENTS_REQUEST = "VIEW_MEASUREMENTS_REQUEST";
export const VIEW_MEASUREMENTS_SUCCESS = "VIEW_MEASUREMENTS_SUCCESS";
export const VIEW_MEASUREMENTS_FAILD = "VIEW_MEASUREMENTS_FAILD";
export const VIEW_MEASUREMENTS_LONG = "VIEW_MEASUREMENTS_LONG";
export const VIEW_MEASUREMENTS_CLEAR = "VIEW_MEASUREMENTS_CLEAR";