import { t } from "i18next";
import { useEffect, useState } from "react";
import CustomeButton from "../../Common/CustomButton/CustomButton";
import SelectGropus from "../../Common/SelectGroups/SelectGroups";
import Modal from "react-bootstrap/Modal";
import { useDispatch } from "react-redux";

import { ERROR, FEMALE, MALE, PREGNANT } from "../../../Constant/General";
import InputGroups from "../../Common/InputGroups/InputGroups";
import {
  downloadDocument,
  getCompletePedigree,
} from "../../../actions/Horse/MyHorseActions";
import { useLoading } from "../../../context/LoadingContext";
import { dowloadPedigree, getPedigree } from "../../../Redux/NonRedux/breeding";
import GestationPedigree from "../../Common/GestationPedigree/GestationPedigree";
import { showToast } from "../../../Utility/General";

export default function GestationCalculatorPopup(props: any) {
  const { horseList, ...rest } = props;

  const [selectedMare, setSelectedMare] = useState("");
  const [stallion, setStallion] = useState("");
  const [mareList, setMareList] = useState([]);
  const [stallionList, setStallionList] = useState([]);
  const [marePedigreeData, setMarePedigreeData] = useState({});
  const [stallionPedigreeData, setStallioPedigreeData] = useState({});
  const [canDownloadPedigree , setCanDownloadPedigree] = useState(false);
  const [pedigreeErrorMessage, setPedigreeErrorMessage] = useState<string | null>();

  const { setLoading } = useLoading();
  const dispatch = useDispatch();
  const pedigreeLayer = ["span_32", "span_16", "span_8", "span_4"];

  useEffect(() => {
    const allMare = horseList?.filter(
      (item: any) => item?.type_slug === FEMALE || item?.type_slug === PREGNANT
    );
    const allStallion = horseList?.filter(
      (item: any) => item?.type_slug === MALE
    );
    setMareList(allMare);
    setStallionList(allStallion);
  }, [horseList]);

  const matchObject = (mareObj: any, stallionOb: any) => {
    try {
      let stallionData = Object.assign(stallionOb);
      let mareData = Object.assign(mareObj);
      let stallionHorseName: any = "";
      let mareHorseName = "";
      pedigreeLayer.forEach((span: any) => {
        stallionData?.[span]?.map((item: any, index: number) => {
          stallionHorseName = item["horseName"].split(",")[0];
          stallionHorseName = stallionHorseName?.trim()?.toLowerCase();
          // check for mare matching
          pedigreeLayer.forEach((span2: any) => {
            mareData?.[span2]?.map((item2: any, index2: number) => {
              mareHorseName = item2["horseName"]?.split(",")?.[0];
              mareHorseName = mareHorseName?.trim()?.toLowerCase();

              if (
                stallionHorseName === mareHorseName &&
                stallionHorseName != "" &&
                mareHorseName != ""
              ) {
                if (stallionData?.[span]?.[index]) {
                  stallionData[span][index]["match"] = true;
                  mareData[span2][index2]["match"] = true;
                } else {
                  stallionData[span][index]["match"] = false;
                  mareData[span2][index2]["match"] = false;
                }
              }
            });
          });
        });
      });
      setStallioPedigreeData(stallionData);
      setMarePedigreeData(mareData);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    if (props?.show === false) {
      setSelectedMare("");
      setStallion("");
      setMarePedigreeData({});
      setStallioPedigreeData({});
    }
  }, [props?.show]);

  // useEffect(() => {
  //   if(Object.keys(stallionPedigreeData)?.length > 0 && Object.keys(marePedigreeData)?.length > 0){
  //     matchObject()
  //   }
  // }, [stallionPedigreeData , marePedigreeData])

  const findPedigree = () => {
    setLoading(true);
    getPedigree({ name: stallion })
      .then((res: any) => {
        if (res?.component?.pedigree_data?.length) {
          showToast(ERROR, t("Stallion Pedigree not found"));
        }
        setStallioPedigreeData(res?.component?.pedigree_data);
        setCanDownloadPedigree(res?.component?.downloadable)
        setPedigreeErrorMessage(res?.component?.message)
        matchObject(marePedigreeData, res?.component?.pedigree_data);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const downloadPedigree = () => {
    setLoading(true);
    dowloadPedigree({ name: stallion, horse_id: selectedMare })
      .then((res: any) => {})
      .finally(() => {
        setLoading(false);
      });
  };

  const getMarePedigree = (id: number) => {
    if (!id) {
      setMarePedigreeData({});
      matchObject({}, stallionPedigreeData);
      return;
    }
    setLoading(true);
    getCompletePedigree({ horse_id: id })
      .then((res: any) => {
        setMarePedigreeData(res?.component?.pedigree_data);
        matchObject(res?.component?.pedigree_data, stallionPedigreeData);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  return (
    <div>
      <Modal
        {...rest}
        centered
        className="gestation-calculator-popup modal-1192"
      >
        <Modal.Header closeButton>
          <p className="tl">{t("Test Mating")}</p>
        </Modal.Header>
        <Modal.Body>
          <div className="input-btn-header">
            <div className="field-wrapper">
              <SelectGropus
                label={`${t("Select Mare")}*`}
                value={mareList}
                dropKey="id"
                dropVal="name"
                defaultSelect={selectedMare}
                onChange={(e: any) => {
                  setSelectedMare(e.target.value);
                  getMarePedigree(e.target.value);
                }}
              />
              <InputGroups
                label={`${t("Stallion")}*`}
                type="text"
                autoComplete="off"
                value={stallion}
                onChange={(e: any) => setStallion(e.target.value)}
              />
            </div>
            <CustomeButton
              bg="fill"
              onClick={findPedigree}
              disabled={!(stallion?.length && selectedMare)}
            >
              {t("Search")}
            </CustomeButton>

            {(stallion?.length && selectedMare ) ? (
              <CustomeButton
                bg="border-btn"
                disabled={!canDownloadPedigree}
                onClick={downloadPedigree}
              >
                {t("download")}
              </CustomeButton>
            ) : null}
          </div>
          <div className="flow-chart-wrapper">
          {pedigreeErrorMessage && <p className="text-danger mb-3">{pedigreeErrorMessage}</p>}
            <GestationPedigree pedigreedata={marePedigreeData} />
            
            <GestationPedigree pedigreedata={stallionPedigreeData} />
          </div>
        </Modal.Body>
      </Modal>
    </div>
  );
}
