import type { DatePickerProps } from "antd";
import { t } from "i18next";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { Col, Row } from "react-bootstrap";
import Modal from "react-bootstrap/Modal";
import { useDispatch, useSelector } from "react-redux";
import { addEditInsurace } from "../../../actions/Horse/MyHorseActions";
import { API_STATUS } from "../../../Constant/Api";
import { SUCCESS_RESPONSE_CODE } from "../../../Constant/Status";
import { useLoading } from "../../../context/LoadingContext";
import validate from "../../../Utility/Validation";
import CustomeButton from "../../Common/CustomButton/CustomButton";
import Datepicker from "../../Common/Datepicker/Datepicker";
import InputGroups from "../../Common/InputGroups/InputGroups";
import InputSelectMix from "../../Common/InputSelectMix/InputSelectMix";
import TextButton from "../../Common/TextButton/TextButton";

export default function EditInsurancePopup(props: any) {
  const INITIAL_INSURANCE_DATA: any = {
    insurance_company: {
      value: "",
      validation: ["required"],
      errors: [t("Insurance Company is required.")],
    },
    policy_number: {
      value: "",
      validation: ["required"],
      errors: [t("Policy/Contract Number is required.")],
    },
    policy_type: {
      value: "",
      validation: ["required"],
      errors: [t("Policy Type is required.")],
    },
    policy_start_date: {
      value: moment().format("YYYY-MM-DD"),
      validation: ["required"],
      errors: [t("Policy start date is required.")],
    },
    policy_end_date: {
      value: moment().format("YYYY-MM-DD"),
      validation: ["required"],
      errors: [t("policy end date is required.")],
    },
    premium: {
      value: "",
      validation: [""],
      errors: [t("premium is required.")],
    },
    limit_of_liability: {
      value: "",
      validation: [""],
      errors: [t("limit of iability is required.")],
    },
    contact_number: {
      value: "",
      validation: ["required"],
      errors: [t("Contact Number is required.")],
    },
    email: {
      value: "",
      validation: ["required", "email"],
      errors: [t("Email is required."), t("Please enter valid email.")],
    },
    id: {
      value: "",
    },
  };

  const [insuranceData, setInsuranceData] = useState(INITIAL_INSURANCE_DATA);
  const [isFormValidated, setIsFormValidated] = useState(false);
  const [insuranceDataError, setInsuranceDataError] = useState<any>({});
  const { setLoading } = useLoading();

  const dispatch = useDispatch();
  const fromChange: DatePickerProps["onChange"] = (date, dateString) => {
    let dateIs = date?.format("YYYY-MM-DD");
    setData("policy_start_date", dateIs);
  };
  const toChange: DatePickerProps["onChange"] = (date, dateString) => {
    let dateIs = date?.format("YYYY-MM-DD");
    setData("policy_end_date", dateIs);
  };

  const prefrence = useSelector(
    (state: any) => state?.masterReducer?.data?.component?.preferance
  );

  const setData = (field: string, value: any) => {
    const insuranceOb = Object.assign({}, insuranceData);
    insuranceOb[field].value = value;
    setInsuranceData({ ...insuranceOb });
  };

  useEffect(() => {
    setInsuranceDataError({ ...validate(insuranceData).errors });
  }, [insuranceData]);

 

  useEffect(() => {
    if (props?.insurancedata) {
      let insuranceOb = JSON.parse(JSON.stringify(props?.insurancedata));
      Object.keys(insuranceData).forEach((element) => {
        if (insuranceOb[element]) {
          setData(element, insuranceOb[element]);
        }
        else{
          setData(element, '');
        }
      });
      setInsuranceDataError({});
      setIsFormValidated(false);
    }
  }, [props?.insurancedata, props?.show]);

  const handleSubmit = () => {
    if (validate(insuranceData).isValidated === true) {
      const horseId = props?.horseid;
      let params: any = {};
      Object.keys(insuranceData).forEach((element) => {
        params[element] = insuranceData[element].value;
      });
      params.horse_id = horseId;
      setLoading(true);
      addEditInsurace(params)
        .then((res: any) => {
          if (res.statusCode === SUCCESS_RESPONSE_CODE) {
            props.onHide();
            props.setinsurancedata({ ...res.component });
          }
        })
        .catch((error: any) => {})
        .finally(() => {
          setLoading(false);
        });
    } else {
      setIsFormValidated(true);
      setInsuranceDataError({ ...validate(insuranceData).errors });
    }
  };

  return (
    <Modal {...props} centered className="edit-insurance-popup modal-784">
      <Modal.Header closeButton>
        <p className="tl">{t("Edit Insurance Information")}</p>
      </Modal.Header>
      <Modal.Body>
        <Row>
          <Col sm={6} md={6}>
            <InputGroups
              label={`${t("Insurance Company")}*`}
              type="text"
              autoComplete="off"
              value={insuranceData?.insurance_company?.value}
              onChange={(e: any) =>
                setData("insurance_company", e.target.value)
              }
              error={
                isFormValidated && insuranceDataError["insurance_company"]
                  ? insuranceDataError["insurance_company"]
                  : null
              }
            />
          </Col>
        </Row>
        <Row>
          <Col sm={6} md={6}>
            <InputGroups
              label={`${t("Policy/Contract Number")}*`}
              type="text"
              autoComplete="off"
              value={insuranceData?.policy_number?.value}
              onChange={(e: any) => setData("policy_number", e.target.value)}
              error={
                isFormValidated && insuranceDataError["policy_number"]
                  ? insuranceDataError["policy_number"]
                  : null
              }
            />
          </Col>
          <Col sm={6} md={6}>
            <InputGroups
              label={`${t("Type (coverage of policy type)")}*`}
              type="text"
              autoComplete="off"
              value={insuranceData?.policy_type?.value}
              onChange={(e: any) => setData("policy_type", e.target.value)}
              error={
                isFormValidated && insuranceDataError["policy_type"]
                  ? insuranceDataError["policy_type"]
                  : null
              }
            />
          </Col>
          <Col sm={6} md={6}>
            <Datepicker
              label={`${t("From")}*`}
              onChange={fromChange}
              value={insuranceData?.policy_start_date?.value ? moment(insuranceData?.policy_start_date?.value) : undefined}
              error={
                isFormValidated && insuranceDataError["policy_start_date"]
                  ? insuranceDataError["policy_start_date"]
                  : null
              }
            />
          </Col>
          <Col sm={6} md={6}>
            <Datepicker
              label={`${t("To")}*`}
              onChange={toChange}
              value={insuranceData?.policy_end_date?.value ? moment(insuranceData?.policy_end_date?.value) : undefined}
              error={
                isFormValidated && insuranceDataError["policy_end_date"]
                  ? insuranceDataError["policy_end_date"]
                  : null
              }
            />
          </Col>
        </Row>
        <Row>
          <Col sm={6} md={6}>
            <InputSelectMix
              label={`${t("Premium")}`}
              inputType="number"
              inputValue={insuranceData?.premium?.value}
              onInputChange={(e: any) => setData("premium", e.target.value)}
              inputError={
                isFormValidated && insuranceDataError["premium"]
                  ? insuranceDataError["premium"]
                  : null
              }
              inputSecondValue={prefrence?.currency_value}
            />
          </Col>
        </Row>
        <Row>
          <Col sm={6} md={6}>
            <InputGroups
              label={`${t("Limit of Liability")}`}
              type="number"
              autoComplete="off"
              value={insuranceData?.limit_of_liability?.value}
              onChange={(e: any) =>
                setData("limit_of_liability", e.target.value)
              }
              error={
                isFormValidated && insuranceDataError["limit_of_liability"]
                  ? insuranceDataError["limit_of_liability"]
                  : null
              }
            />
          </Col>
        </Row>
        <Row>
          <Col sm={6} md={6}>
            <InputGroups
              label={`${t("Contact Number")}*`}
              type="text"
              autoComplete="off"
              value={insuranceData?.contact_number?.value}
              onChange={(e: any) => setData("contact_number", e.target.value)}
              error={
                isFormValidated && insuranceDataError["contact_number"]
                  ? insuranceDataError["contact_number"]
                  : null
              }
            />
          </Col>
          <Col sm={6} md={6}>
            <InputGroups
              label={`${t("Email")}*`}
              type="text"
              autoComplete="off"
              value={insuranceData?.email?.value}
              onChange={(e: any) => setData("email", e.target.value)}
              error={
                isFormValidated && insuranceDataError["email"]
                  ? insuranceDataError["email"]
                  : null
              }
            />
          </Col>
        </Row>
      </Modal.Body>
      <Modal.Footer>
        <div className="footer-2-btn">
          <TextButton onClick={props?.onHide}>{t("Cancel")}</TextButton>
          <CustomeButton bg="fill" onClick={handleSubmit}>
            {t("Save")}
          </CustomeButton>
        </div>
      </Modal.Footer>
    </Modal>
  );
}
