import moment from "moment";
import React, { useState, useEffect, useContext } from "react";
import { useSelector } from "react-redux";
import Datepicker from "../../Common/Datepicker/Datepicker";
import SelectGropus from "../../Common/SelectGroups/SelectGroups";
import { useLocation, useOutletContext, useParams } from "react-router-dom";
import { t } from "i18next";
import { invoiceHorseList } from "../../../Redux/NonRedux/invoice";
import { useLoading } from "../../../context/LoadingContext";

export default function IncomeExpenseFilter({
  filterOption,
  setFilterOption,
}: any) {
  let { category } = useParams();

  const [horseList, setHorseList] = useState<any>([]);
  const location = useLocation();
  // const horseList = useSelector(
  //   (state: any) => state?.metaHorseListReducer?.data?.component
  // );

  const {metaData } : any = useOutletContext();

  const { setLoading } = useLoading();

  const ownerChange = (val:string) => {
    invoiceHorseList({ owner_id: val })
      .then((res: any) => {
        setHorseList(res?.component);
      })
      .finally(() => {
      });
  }


  useEffect(() => {
    if(filterOption.owner_id){
      ownerChange(filterOption.owner_id)
    }else{
      setHorseList(metaData.horse_list);
    }
  }, [filterOption?.owner_id])
  

  return (
    <div className="date-and-input-filter">
      <Datepicker
        label={t("From")}
        endDate={moment().format("YYYY-MM-DD")}
        onChange={(date: any) => {
          let dateIs = date?.format("YYYY-MM-DD");
          setFilterOption({ ...filterOption, from: dateIs });
        }}
        value={filterOption?.from ? moment(filterOption?.from) : null}
      />
      <Datepicker
        label={t("To")}
        startDate={filterOption?.from ? moment(filterOption?.from) : null}
        endDate={moment().format("YYYY-MM-DD")}
        onChange={(date: any) => {
          let dateIs = date?.format("YYYY-MM-DD");
          setFilterOption({ ...filterOption, to: dateIs });
        }}
        value={filterOption?.to ? moment(filterOption?.to) : null}
      />
      <SelectGropus
        label={t("Owner")}
        value={metaData?.owner_list}
        dropKey="id"
        dropVal="name"
        onChange={(e: any) =>
        {
          setFilterOption({ ...filterOption,
            owner_id: e.target.value,
            horse_id:"",
           });
        }
        }
        defaultSelect={filterOption?.owner_id || ""}
      />
      <SelectGropus
        label={t("Horse")}
        value={horseList}
        dropKey="id"
        dropVal="name"
        onChange={(e: any) =>
          setFilterOption({ ...filterOption, horse_id: e.target.value })
        }
        defaultSelect={filterOption?.horse_id || ""}
      />
      {!category && (
        <SelectGropus
          label={t("Category")}
          value={metaData?.income_category}
          dropKey="id"
          dropVal="metadata"
          onChange={(e: any) =>
            setFilterOption({ ...filterOption, category_id: e.target.value })
          }
          defaultSelect={filterOption?.category_id || ""}
        />
      )}
    </div>
  );
}
