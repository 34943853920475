export const DASHBOARD = '/';
export const LOGIN = "/login";
export const LOGOUT = "/logout";
export const MYHORSES = '/horses';
export const BOARDING = '/boarding';
export const BREEDING = '/breeding';
export const HEALTH = '/health';
export const DIET = '/diet';
export const FARRIER = '/farrier';
export const TRAINING = '/training';
export const TRAINING_CALENDER = '/trainingcalender';
export const TRANSPORTATION = '/transportation';
export const INVENTORY = '/inventory';
export const TASK = '/task';
export const REMINDERS = '/reminders';
export const INCOME_EXPENSE = '/incomeexpense';
export const INVOICING = '/invoicing';

export const HORSEDETAILS = '/detail';
export const HORSEDETAILS_HORSE = HORSEDETAILS + "/horse";
export const HORSEDETAILS_BREEDING = HORSEDETAILS + "/breeding";
export const HORSEDETAILS_BREEDING_SERVICE = HORSEDETAILS_BREEDING +  "/service";
export const HORSEDETAILS_BREEDING_SERVICE_DETAILS =  HORSEDETAILS_BREEDING + "/servicedetails";
export const HORSEDETAILS_HEALTH = HORSEDETAILS + "/health";
export const HORSEDETAILS_DIET = HORSEDETAILS + "/diet";
export const HORSEDETAILS_FARRIER = HORSEDETAILS + "/farrier";
export const HORSEDETAILS_MEASUREMENTS = HORSEDETAILS + "/measurements";
export const HORSEDETAILS_ACHIEVEMENTS = HORSEDETAILS + "/achievements";
export const HORSEDETAILS_DOCUMENTS = HORSEDETAILS + "/documents";
export const HORSEDETAILS_PICTUREANDVIDEO = HORSEDETAILS + "/picturesandvideos";
export const HORSEDETAILS_NOTES = HORSEDETAILS + "/notes";

export const HORSEDETAILS_HEALTH_VITALSIGNS = HORSEDETAILS_HEALTH + '/vitalsigns';
export const HORSEDETAILS_HEALTH_PARASITECONTROL = HORSEDETAILS_HEALTH + '/parasitecontrol';
export const HORSEDETAILS_HEALTH_VACCINATION = HORSEDETAILS_HEALTH + '/vaccination';
export const HORSEDETAILS_HEALTH_DENTAL = HORSEDETAILS_HEALTH + '/dental';
export const HORSEDETAILS_HEALTH_VETVISITS = HORSEDETAILS_HEALTH + '/vetvisits';
export const HORSEDETAILS_HEALTH_TREATMENTS = HORSEDETAILS_HEALTH + '/treatments';
export const HORSEDETAILS_HEALTH_LABTESTS = HORSEDETAILS_HEALTH + '/labtests';
export const HORSEDETAILS_HEALTH_XRAY = HORSEDETAILS_HEALTH + '/xray';

export const HEALTH_VITALSIGNS = HEALTH + "/vitalsigns";
export const HEALTH_PARASITECONTROL = HEALTH + '/parasitecontrol';
export const HEALTH_VACCINATION = HEALTH + '/vaccination';
export const HEALTH_DENTAL = HEALTH + '/dental';
export const HEALTH_VETVISITS = HEALTH + '/vetvisits';
export const HEALTH_TREATMENTS = HEALTH + '/treatments';
export const HEALTH_LABTESTS = HEALTH + '/labtests';
export const HEALTH_XRAY = HEALTH + '/xray';

export const REMINDERS_PREGNANCY = REMINDERS + "/pregnancy";
export const REMINDERS_REGISTRATION = REMINDERS + '/registration';
export const REMINDERS_WEANING = REMINDERS + '/weaning';
export const REMINDERS_VACCINATION = REMINDERS + '/vaccination';
export const REMINDERS_PARASITECONTROL = REMINDERS + '/parasitecontrol';
export const REMINDERS_DENTAL = REMINDERS + '/dental';
export const REMINDERS_FARRIER = REMINDERS + '/farrier';

export const INCOME_EXPENSE_CATEGORY = INCOME_EXPENSE + "/category";
export const INCOME_EXPENSE_CATEGORY_DETAIL = INCOME_EXPENSE + "/categorydetail" ;
export const INCOME_EXPENSE_HORSE = INCOME_EXPENSE + "/horse";

export const INVOICING_SERVICE_TO_INVOICE = INVOICING + "/servicetoinvoice";
export const INVOICING_INVOICE_LIST = INVOICING + "/invoicelist";
export const INVOICING_NEW_INVOICE = INVOICING + "/newinvoice";
export const INVOICING_MANUAL_INVOICE = INVOICING + "/manualinvoice";

export const MYACCOUNT = "/myaccount";
export const LOCATION = "/location";
export const PREFERENCE = "/preference";
export const USER = "/user";
export const USER_CONTACT = USER + "/usercontact";
export const USER_STAFF = USER + "/userstaff";
export const USER_PAYROLL = USER + "/userpayroll";
export const IMPORTDATA = "/importdata";
export const CHANGEPASSWORD = "/changepassword";


export const FARM_DIRECTORY = "/farmdirectory";
export const FARM_DIRECTORY_LISTING = FARM_DIRECTORY + "/listing";
export const FARM_DIRECTORY_DETAILS = FARM_DIRECTORY + "/details";