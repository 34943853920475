import React, { useEffect, useState } from "react";

const InputIconGroups = (props: any) => {
  return (
    <div className="input-groups input-icon-groups">
      <label>{props.label}</label>
      <div className="input-icon-wrapper">
        <img src={props.icon} alt="" />
        <input
          type={props.type}
          inputMode={props.inputmode}
          defaultValue={props.defaultValue}
          value={props.value}
          placeholder={props.placeholder}
          readOnly={props.readOnly}
          autoComplete={props.autoComplete}
          name={props.name}
          id={props.id}
          className={props.error && "error"}
          onChange={props.onChange}
        />
      </div>
      {props.error && <span className="error">{props.error}</span>}
    </div>
  );
};

export default InputIconGroups;
