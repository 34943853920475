import React from "react";

export default function Movementicon() {
  return (
    <svg
      width="32"
      height="32"
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M17.1 6.2H7.3C6 6.2 5 7.2 5 8.5V19C5 19.8 5.6 20.4 6.4 20.4H19.3V8.5C19.4 7.2 18.3 6.2 17.1 6.2Z"
        stroke="#E8E5E2"
        strokeWidth="1.5"
      />
      <path
        d="M5 11.6H22.7C23.4 11.6 24 11.9 24.5 12.3L26.3 14.1C26.8 14.6 27 15.2 27 15.9V18.9C27 19.7 26.4 20.3 25.6 20.3H19.4V11.6"
        stroke="#E8E5E2"
        strokeWidth="1.5"
      />
      <path
        d="M9.90001 25.8C11.4 25.8 12.6 24.6 12.6 23.1C12.6 21.6 11.4 20.4 9.90001 20.4C8.40001 20.4 7.20001 21.6 7.20001 23.1C7.20001 24.6 8.40001 25.8 9.90001 25.8Z"
        stroke="#E8E5E2"
        strokeWidth="1.5"
      />
      <path
        d="M22.1 25.8C23.6 25.8 24.8 24.6 24.8 23.1C24.8 21.6 23.6 20.4 22.1 20.4C20.6 20.4 19.4 21.6 19.4 23.1C19.4 24.6 20.6 25.8 22.1 25.8Z"
        stroke="#E8E5E2"
        strokeWidth="1.5"
      />
    </svg>
  );
}
