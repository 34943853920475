import React, { useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import AddPlusIcon from "../../Assets/icon/AddPlusIcon";
import ArrowLeftRight from "../../Assets/img/arrow-left-right.svg";
import ArrowRight from "../../Assets/img/arrow-right.svg";
import binIcon from "../../Assets/img/bin.svg";
import PaperClipIcon from "../../Assets/img/Common-icon/PaperClipIcon";
import FemaleIcon from "../../Assets/img/Female.svg";
import MaleIcon from "../../Assets/img/Male.svg";
import TransportIcon from "../../Assets/img/Movement-primary.svg";
import pencilIcon from "../../Assets/img/pencil.svg";
import PregnantIcon from "../../Assets/img/Pregnant.svg";
import { DATATABLE, PERMISSIONS, SIDEBAR } from "../../Constant/General";
import { useLoading } from "../../context/LoadingContext";
import { SidebarContext } from "../../context/SideBarContext";
import {
  destroyTransport,
  listOfTransport,
  viewTransport,
} from "../../Redux/NonRedux/transportApi";
import CustomeButton from "../Common/CustomButton/CustomButton";
import NoDataFound from "../Common/NoDataFound/NoDataFound";
import OnlyCk from "../Common/OnlyCk/OnlyCk";
import SearchBox from "../Common/SearchBox/SearchBox";
import Pagination from "../Layouts/Pagination/Pagination";
import AddTransportationPopup from "../Popups/AddTransportationPopup/AddTransportationPopup";
import ConfirmPopup from "../Popups/ConfirmPopup/ConfirmPopup";
import ExportIcon from "../../Assets/img/export.svg";
import { END_POINTS } from "../../Constant/Api";
import { exportDocument } from "../../Redux/globallActions";
import { checkRoutePermission, getDefaultSorting } from "../../Utility/General";
import Filter from "../Filter/Filter";
import { upgradePopupAction } from "../../Redux/General/GeneralAction";
import { getListMetaDataApi } from "../../Redux/NonRedux/general";
export default function Transportation() {
  const defaultSorting: any = getDefaultSorting(DATATABLE.TRANSPORTATION);
  const { t } = useTranslation();

  const [AddTransportationModalShow, setAddTransportationModalShow] =
    React.useState(false);
  const [currentPage, setCurrentPage] = React.useState(1);
  const [currentPageSize, setCurrentPageSize] = React.useState(10);

  const [recordsTotal, setRecordsTotal] = React.useState(1);
  const [searchText, setSearchText] = React.useState();
  const [listData, setListData] = React.useState([]);
  const [transportType, setTransportType] = React.useState([]);
  const [isEdit, setIsEdit] = React.useState<boolean>(false);
  const [refreshList, setRefreshList] = React.useState<boolean>(false);

  const [confirmPopupShow, setConfirmPopupShow] = React.useState(false);
  const [deletableRecords, setDeletableRecords] = React.useState<any>([]);
  const [editData, setEditData] = React.useState<any>({});
  const [checkedRec, setCheckedRec] = useState<number[]>([]);
  const [horseIds, setHorseIds] = useState<number[]>([]);
  const [sortBy, setSortBy] = React.useState(defaultSorting?.value);
  const [sortByField, setSortByField] = useState(defaultSorting?.key);
  const [sortType, setSortType] = useState(defaultSorting?.type);

  const [orderList, setOrderList] = useState<any>([]);
  const [exportParams, setExportParams] = useState<any>({});
  const [filterList, setFilterList] = useState<any>([]);
  const [filteredValue, setFilteredValue] = useState<any>({});
  const [metaData, setMetaData] = useState({});
  const prefrence = useSelector(
    (state: any) => state?.masterReducer?.data?.component?.preferance
  );
  const { setLoading } = useLoading();
  const { setSidebar }: any = useContext(SidebarContext);
  const dispatch = useDispatch();
  const modules = useSelector(
    (state: any) => state?.masterReducer?.data?.component?.modules
  );

  const getMetaData = () => {
    setLoading(true);
    getListMetaDataApi(END_POINTS.metaTransport)
      .then((response: any) => {
        setMetaData(response?.component);
      })
      .catch((error: any) => {})
      .finally(() => {
        setLoading(false);
      });
  };

  useEffect(() => {
    setSidebar(SIDEBAR.TRANSPORATATION);
    getMetaData();
  }, []);

  useEffect(() => {
    listApiCall();
  }, [currentPage, currentPageSize, sortBy, sortType, filteredValue]);

  useEffect(() => {
    if (searchText != null) {
      const searchTimeOut = setTimeout(() => {
        if (currentPage === 1) {
          listApiCall();
        } else {
          setCurrentPage(1);
        }
      }, 500);
      return () => clearTimeout(searchTimeOut);
    }
  }, [searchText]);

  useEffect(() => {
    if (refreshList) {
      listApiCall();
      setRefreshList(false);
    }
  }, [refreshList]);

  const listApiCall = () => {
    let params: any = {
      page: currentPage,
      limit: currentPageSize,
      search: searchText,
    };
    if (sortBy && sortType) {
      params["sort"] = {
        column: sortBy,
        type: sortType,
      };
    }
    if (filteredValue && Object.keys(filteredValue)?.length) {
      params["filter"] = filteredValue;
    }
    setExportParams(params);
    setLoading(true);
    listOfTransport(params)
      .then((response: any) => {
        setListData(response?.component?.listing);
        // setTransportType(response?.component?.listing);
        setRecordsTotal(response?.component?.recordsFiltered);
        setOrderList(response?.component?.orderlist);
        setFilterList(response?.component?.filterlist);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const editHandler = (id: any) => {
    let params = {
      id: id,
    };
    setLoading(true);
    // dispatch(viewSingleHealthAction(params));
    viewTransport(params)
      .then((res: any) => {
        setIsEdit(true);
        setEditData(res?.component);
        setAddTransportationModalShow(true);
      })
      .catch((err) => {})
      .finally(() => {
        setLoading(false);
      });
  };

  const deleteHandler = () => {
    let params = {
      id: deletableRecords,
    };
    setLoading(true);
    destroyTransport(params)
      .then((res: any) => {
        setRefreshList(true);
        setConfirmPopupShow(false);
      })
      .catch((err: any) => {});
  };

  const deleteNow = (id: any) => {
    // let ids = deletableRecords;
    let ids = [];
    ids.push(id);
    setDeletableRecords(ids);
    setConfirmPopupShow(true);
  };

  useEffect(() => {
    let records = listData?.filter((item: any) =>
      checkedRec.includes(item?.id)
    );
    let horse_ids: any = records.map((list: any) => list.horse_id);
    setHorseIds(
      horse_ids.filter((v: any, i: any, a: any) => a.indexOf(v) === i)
    );
  }, [checkedRec]);

  const getIconbyType = (type: any) => {
    if (type == "male") {
      return MaleIcon;
    } else if (type == "female") {
      return FemaleIcon;
    } else if (type == "pregnant") {
      return PregnantIcon;
    }
  };

  const openDocument = (doc: any) => {
    window.open(doc, "_blank");
  };

  // const checkSingleRec = (e: any, val: number) => {
  //   //
  //   var checkedRecData: number[] = Object.assign([], checkedRec);
  //   if (e.target.checked) {
  //     checkedRecData.push(val);
  //   } else {
  //     checkedRecData = checkedRecData.filter((c: number) => c !== val);
  //   }
  //   setCheckedRec(checkedRecData);
  // };

  // const handleSelectAll = (e: any) => {
  //   if (e.target.checked) {
  //     var checkedRecData: any[] = Object.assign([], checkedRec);
  //     listData?.map((li: any) => {
  //       if (!checkedRecData.includes(li.id)) {
  //         checkedRecData.push(li.id);
  //       }
  //     });
  //     setCheckedRec(checkedRecData);
  //   } else {
  //     setCheckedRec([]);
  //   }
  // };

  const sortrecords = (fieldName: any) => {
    if (orderList?.[fieldName]) {
      let sortByNow = orderList[fieldName];
      if (sortBy == sortByNow) {
        let sortTypeNow = sortType == "asc" ? "desc" : "asc";
        setSortType(sortTypeNow);
      } else {
        setSortBy(sortByNow);
        setSortByField(fieldName);
        setSortType("asc");
      }
    }
  };

  const sortableClass = (fieldName: string): string => {
    if (orderList?.[fieldName]) {
      return "sortable";
    } else {
      return "";
    }
  };

  const sortingTypeClass = (fieldName: string): string => {
    if (sortByField === fieldName) {
      return sortType == "asc" ? "arrow-up" : "arrow-down";
    } else {
      return "";
    }
  };

  const handleExport = () => {
    if (!checkRoutePermission(PERMISSIONS?.EXPORT_FEATURE, modules)) {
      upgradePopupAction({ show: true });
      return;
    }
    setLoading(true);
    exportDocument(END_POINTS.transportExport, exportParams, "transportation")
      .then(() => {})
      .finally(() => {
        setLoading(false);
      });
  };

  return (
    <>
      <div className="transporation-page search-and-btn">
        {/* Page Title Start */}
        <div className="page-title-wrapper">
          <div className="title-first-menu">
            <div className="title-icon-text">
              <img src={TransportIcon} alt="" />
              <h5>{t("Transportation")}</h5>
            </div>
            <div className="title-right-icons">
              <a className="unset-links" onClick={handleExport}>
                <img src={ExportIcon} alt="" />
              </a>
            </div>
          </div>
        </div>
        {/* Page Title End */}

        <div className="page-content">
          <div className="data-wrapper">
            <div className="data-header">
              <SearchBox
                value={searchText}
                placeholder={t("Search Horse...")}
                onChange={(e: any) => setSearchText(e.target.value)}
              />

              <div className="right-section">
                <div className="btn-wrapper f-fb">
                  {/* <CustomeButton>
                    <Filtericon />
                    <p>Filter</p>
                  </CustomeButton> */}
                  <Filter
                    filterList={filterList}
                    setFilteredValue={setFilteredValue}
                    setCurrentPage={setCurrentPage}
                  />
                  <CustomeButton
                    bg="fill"
                    onClick={() => {
                      setEditData({});
                      setIsEdit(false);
                      setAddTransportationModalShow(true);
                    }}
                  >
                    <AddPlusIcon />
                    {t("Add")}
                  </CustomeButton>
                </div>
              </div>
            </div>
            <div className="data-body">
              <div className="img-responsive-table">
                <table>
                  <thead>
                    <tr>
                      {/* <th className="ck-wrapper">
                        <OnlyCk
                          checked={
                            checkedRec?.length &&
                            checkedRec?.length == listData?.length
                          }
                          onChange={(e: any) => handleSelectAll(e)}
                        />
                      </th> */}
                      <th className="first-img-wrapper"></th>
                      <th>
                        <p
                          className={`tm icon-text ${sortableClass(
                            "horse"
                          )} ${sortingTypeClass("horse")}`}
                          onClick={() => sortrecords("horse")}
                        >
                          {t("Horse")}
                        </p>
                      </th>
                      <th>
                        <p
                          className={`tm ${sortableClass(
                            "start_date"
                          )} ${sortingTypeClass("start_date")}`}
                          onClick={() => sortrecords("start_date")}
                        >
                          {t("Start Date")}
                        </p>
                      </th>
                      <th>
                        <p
                          className={`tm ${sortableClass(
                            "transporter_type"
                          )} ${sortingTypeClass("transporter_type")}`}
                          onClick={() => sortrecords("transporter_type")}
                        >
                          {t("Transport Type")}
                        </p>
                      </th>
                      <th
                        className={`from-title ${sortableClass(
                          "from"
                        )} ${sortingTypeClass("from")}`}
                        onClick={() => sortrecords("from")}
                      >
                        <p className="tm">{t("From")}</p>
                      </th>
                      <th
                        className={`to-title ${sortableClass(
                          "to"
                        )} ${sortingTypeClass("to")}`}
                        onClick={() => sortrecords("to")}
                      >
                        <p className="tm">{t("To")}</p>
                      </th>
                      <th>
                        <p
                          className={`tm ${sortableClass(
                            "transporter"
                          )} ${sortingTypeClass("transporter")}`}
                          onClick={() => sortrecords("transporter")}
                        >
                          {t("Transporter")}
                        </p>
                      </th>
                      <th>
                        <PaperClipIcon />
                      </th>
                      <th>
                        <p
                          className={`tm ${sortableClass(
                            "amount"
                          )} ${sortingTypeClass("amount")}`}
                          onClick={() => sortrecords("amount")}
                        >
                          {t("Amount")}
                        </p>
                      </th>
                      <th className="text-right">
                        <p className="tm">{t("Action")}</p>
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {listData?.map((items: any, index: number) => (
                      <tr key={index + "myhorse-tbl"}>
                        {/* <td className="ck-wrapper">
                          <OnlyCk
                            checked={checkedRec.includes(items?.id)}
                            onChange={(e: any) => checkSingleRec(e, items?.id)}
                          />
                        </td> */}
                        <td className="first-img-wrapper">
                          <img
                            src={items.horse_image}
                            alt=""
                            className="horse-img"
                          />
                        </td>
                        <td>
                          <div className="icon-text">
                            <img src={getIconbyType(items.type_slug)} alt="" />
                            {/* <Link to={HORSE} className="a-bl"> */}
                            {items.name}
                            {/* </Link> */}
                          </div>
                        </td>
                        <td>
                          <p className="bl">
                            {items.departure_date}
                            {items?.round_trip_slug == "1"
                              ? ` - ${items.return_date}`
                              : null}
                          </p>
                        </td>
                        <td>
                          <p className="bl">{items.transport_value}</p>
                        </td>
                        <td className="from-title">
                          <p className="bl">{items.boarding}</p>
                          <img
                            src={
                              items.round_trip_slug == "1"
                                ? ArrowLeftRight
                                : ArrowRight
                            }
                            alt=""
                            className="icon-24"
                          />
                        </td>
                        <td className="to-title">
                          <p className="bl">
                            {items.dropping}
                            <span className="bl to-days">
                              {items.round_trip_slug
                                ? parseInt(items.total_days) + 1 + " Days"
                                : null}
                            </span>
                          </p>
                        </td>
                        <td>
                          <p className="bl">{items.transporter_name}</p>
                        </td>
                        {/* <td>{items.icons}</td> */}
                        <td>
                          {items.document ? (
                            <p
                              className="bl"
                              onClick={() => openDocument(items.document)}
                            >
                              <PaperClipIcon />
                            </p>
                          ) : (
                            ""
                          )}
                        </td>
                        <td>
                          <p className="bl">
                            {items.amount}
                            {items.amount ? ` ${prefrence?.currency_value}` : null }
                          </p>
                        </td>
                        <td>
                          <div className="icon-wrapper">
                            <button onClick={() => editHandler(items.id)}>
                              <img src={pencilIcon} alt="" />
                            </button>
                            <button onClick={() => deleteNow(items.id)}>
                              <img src={binIcon} alt="" />
                            </button>
                          </div>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
              {listData?.length ? (
                <Pagination
                  results={recordsTotal}
                  currentPage={currentPage}
                  setCurrentPage={setCurrentPage}
                  currentLength={currentPageSize}
                  setCurrentPageSize={setCurrentPageSize}
                />
              ) : (
                <NoDataFound />
              )}
            </div>
          </div>
        </div>
      </div>

      <AddTransportationPopup
        show={AddTransportationModalShow}
        setrefreshlist={() => {
          setRefreshList(true);
          getMetaData();
        }}
        metaData={metaData}
        isedit={isEdit.toString()}
        editdata={editData}
        onHide={() => setAddTransportationModalShow(false)}
      />
      <ConfirmPopup
        heading={t("Delete")}
        message={t("Are you sure to delete this record?")}
        show={confirmPopupShow}
        onHide={() => {
          setConfirmPopupShow(false);
          setDeletableRecords([]);
        }}
        confirm={deleteHandler}
      />
    </>
  );
}
