import React from "react";

export default function Healthicon() {
  return (
    <svg
      width="32"
      height="32"
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M5.73331 14.8C5.46665 14 5.33331 13.2 5.33331 12.4C5.33331 10.8 5.99998 9.33333 7.06665 8.13333C8.13331 6.93333 9.73331 6.39999 11.3333 6.39999C12.9333 6.39999 14.4 7.06666 15.6 8.13333L16 8.53333L16.4 8.13333C17.4666 7.06666 19.0666 6.39999 20.6666 6.39999C22.2666 6.39999 23.7333 7.06666 24.9333 8.13333C26 9.19999 26.6666 10.8 26.6666 12.4C26.6666 13.0667 26.5333 13.7333 26.2666 14.4"
        stroke="#E8E5E2"
        strokeWidth="1.5"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M22 19.7333L16.8 25.3333C16.5333 25.6 16.2667 25.7333 16 25.7333C15.7333 25.7333 15.4667 25.6 15.2 25.3333L10 19.8667"
        stroke="#E8E5E2"
        strokeWidth="1.5"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M2.53333 17.2H6.26666C8.39999 17.2 10.2667 16.1333 11.4667 14.5333L12.4 13.2L14.8 17.2"
        stroke="#E8E5E2"
        strokeWidth="1.5"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M28.4 17.2H24C21.8667 17.2 19.7334 16 18.6667 14.1333L18.1334 13.3333"
        stroke="#E8E5E2"
        strokeWidth="1.5"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}
