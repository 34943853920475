import React from "react";

export default function Notes() {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M4.91446 5.29792C5.29744 4.91494 5.77616 4.72345 6.25488 4.72345H17.7442C18.7974 4.72345 19.6591 5.58515 19.6591 6.63834V19.0851C19.6591 19.5638 19.4676 20.0426 19.0846 20.4255C18.7017 20.8085 18.2229 21 17.7442 21H6.25488C5.77616 21 5.29744 20.8085 4.91446 20.4255C4.53149 20.0426 4.34 19.5638 4.34 19.0851V6.63834C4.34 6.15962 4.53149 5.68089 4.91446 5.29792Z"
        stroke="#919EAB"
        strokeWidth="1.5"
        strokeLinecap="round"
      />
      <path
        d="M8.16977 3V6.35106"
        stroke="#919EAB"
        strokeWidth="1.5"
        strokeLinecap="round"
      />
      <path
        d="M11.9995 3V6.35106"
        stroke="#919EAB"
        strokeWidth="1.5"
        strokeLinecap="round"
      />
      <path
        d="M15.8293 3V6.35106"
        stroke="#919EAB"
        strokeWidth="1.5"
        strokeLinecap="round"
      />
      <path
        d="M8.26552 11.4255L14.0102 17.1702L16.1166 17.9361C16.2123 17.9361 16.308 17.8404 16.308 17.7447L15.5421 15.6383L9.79743 9.89362L8.16977 11.5213C7.78679 11.9042 7.78679 12.5745 8.16977 12.9574L9.51019 14.2979"
        stroke="#919EAB"
        strokeWidth="0.9201"
        strokeLinecap="round"
      />
      <path
        d="M8.74425 10.3724L8.07404 9.79791"
        stroke="#919EAB"
        strokeWidth="1.5846"
        strokeLinecap="round"
      />
      <path
        d="M14.0102 17.1702L15.4464 15.6383"
        stroke="#919EAB"
        strokeWidth="0.9201"
        strokeLinecap="round"
      />
      <path
        d="M11.5208 14.6808L12.957 13.1489"
        stroke="#919EAB"
        strokeWidth="0.9201"
        strokeLinecap="round"
      />
      <path
        d="M10.4676 13.6276L11.9038 12.0957"
        stroke="#919EAB"
        strokeWidth="0.9201"
        strokeLinecap="round"
      />
    </svg>
  );
}
