import moment from "moment";
import React, { useState, useEffect, useContext } from "react";
import { Col, Row } from "react-bootstrap";

import AddPlusIcon from "../../../Assets/icon/AddPlusIcon";
import CustomeButton from "../../Common/CustomButton/CustomButton";
import AddCostRecordPopup from "../../Popups/AddCostRecordPopup/AddCostRecordPopup";
import { Link, useOutletContext } from "react-router-dom";
import { INCOME_EXPENSE_CATEGORY_DETAIL } from "../../../Constant/Route";
import { useSelector } from "react-redux";
import { categoryCostRecordsList } from "../../../Redux/NonRedux/incomeExpense";
import { SUCCESS_RESPONSE_CODE } from "../../../Constant/Status";
import IncomeExpenseFilter from "../IncomeExpenseFilter/IncomeExpenseFilter";
import { getSlugFromCostCategory } from "../../../Utility/General";
import { useLoading } from "../../../context/LoadingContext";
import { SidebarContext } from "../../../context/SideBarContext";
import { SIDEBAR } from "../../../Constant/General";
import { t } from "i18next";
import useDidMountEffect from "../../../hooks/useDidMountEffect";

export default function CategoryIAEPage() {
  const [AddCostRecordModalShow, setAddCostRecordModalShow] =
    React.useState(false);

  const INTIAL_FILTER_OPTION = {
    category_id: null,
    horse_id: null,
    from: moment().startOf('month').format('YYYY-MM-DD'),
    to: moment().format("YYYY-MM-DD"),
    owner_id: null,
  };

  const [data, setData] = useState<any>({});
  const { setLoading } = useLoading();
  const [refreshList, setRefreshList] = useState(false);
  const [filterOption, setFilterOption] = useState<any>(INTIAL_FILTER_OPTION);
  const { setSidebar }: any = useContext(SidebarContext);
  const prefrence = useSelector(
    (state: any) => state?.masterReducer?.data?.component?.preferance
  );

  const {metaData} : any = useOutletContext();

  useEffect(() => {
    if(Object.keys(metaData).length){
      setSidebar(SIDEBAR.INCOME_EXPENSES);
      lisApicall();
    }
  }, []);

  const lisApicall = () => {
    setLoading(true);
    let ob = Object.assign({}, filterOption);
    categoryCostRecordsList(ob)
      .then((response: any) => {
        if (response?.statusCode === SUCCESS_RESPONSE_CODE) {
          setData(response?.component);
         
        }
      })
      .catch(() => {})
      .finally(() => {
        setLoading(false);
      });
  };

  useEffect(() => {
    if (refreshList) {
      lisApicall();
      setRefreshList(false);
    }
  }, [refreshList]);

  useDidMountEffect(() => {
    lisApicall();
  }, [filterOption]);

  return (
    <>
      <div className="category-IAE-page">
        <div className="summary-head">
          <Row>
            <Col sm="12" md="6" lg="4">
              <div className="summary-box green-text-bg">
                <p className="tl blur-color">{t("Income")}</p>
                <div className="right-details">
                  <h5>
                    {data?.totalincome} {prefrence?.currency_value}
                  </h5>
                </div>
              </div>
            </Col>
            <Col sm="12" md="6" lg="4">
              <div className="summary-box red-text-bg">
                <p className="tl blur-color">{t("Expenses")}</p>
                <div className="right-details">
                  <h5>
                    {data?.totalExpence} {prefrence?.currency_value}
                  </h5>
                </div>
              </div>
            </Col>
            <Col sm="12" md="6" lg="4">
              <div className="summary-box blur-bg-black-text">
                <p className="tl blur-color">{t("Balance")}</p>
                <div className="right-details">
                  <h5>
                    {data?.totalBalance} {prefrence?.currency_value}
                  </h5>
                </div>
              </div>
            </Col>
          </Row>
        </div>
        <IncomeExpenseFilter
          filterOption={filterOption}
          setFilterOption={setFilterOption}
        />
        <div className="data-tbl-wrapper">
          <div className="data-tbl-header">
            <p className="tl">{t("Income & Expenses by Category")}</p>
            <div className="btn-wrapper single-btn">
              <CustomeButton
                bg="fill"
                onClick={() => {
                  setAddCostRecordModalShow(true);
                }}
              >
                <AddPlusIcon />
                {t("Add Cost")} 
              </CustomeButton>
            </div>
          </div>
          <div className="data-tbl last-width-148">
            <table>
              <thead>
                <tr>
                  <th>
                    <p className="tm">{t("Category")}</p>
                  </th>
                  <th>
                    <p className="tm text-right">{t("Expense")}</p>
                  </th>
                  <th>
                    <p className="tm text-right">{t("Income")}</p>
                  </th>
                </tr>
              </thead>
              <tbody>
                {data?.getIncomeRecords?.map((items: any, index: number) => (
                  <tr key={index + "category-data"}>
                    <td>
                      <Link
                        to={
                          INCOME_EXPENSE_CATEGORY_DETAIL +
                          "/" +
                          getSlugFromCostCategory(items?.slug)
                        }
                        state={{...filterOption}}
                        className="a-bl"
                      >
                        {items?.metadata}
                      </Link>
                    </td>
                    <td>
                      <p className="bl error-color text-right">
                        {items?.expense_total} {prefrence?.currency_value}
                      </p>
                    </td>
                    <td>
                      <p className="bl success-color text-right">
                        {items?.income_total} {prefrence?.currency_value}
                      </p>
                    </td>
                  </tr>
                ))}

                <tr>
                  <td>
                    <p className="tm primaryM">{t("Total")}</p>
                  </td>
                  <td>
                    <p className="tm error-color text-right">
                      {data?.totalExpence} {prefrence?.currency_value}
                    </p>
                  </td>
                  <td>
                    <p className="tm success-color text-right">
                      {" "}
                      {data?.totalincome} {prefrence?.currency_value}
                    </p>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>

      <AddCostRecordPopup
        show={AddCostRecordModalShow}
        onHide={() => setAddCostRecordModalShow(false)}
        setRefreshList={setRefreshList}
        metaData={metaData}
      />
    </>
  );
}
