import PlusIcon from "../../../Assets/img/plus-circle.svg";
import CloseIcon from "../../../Assets/img/close.svg";
import { t } from "i18next";

const InputSelectGroup = (props: any) => {
  return (
    <div className="input-select-groups">
      <label>{props.label}</label>
      {props.inputSelectToggle == "select" ? (
        <div className="plus-btn-select">
          <img
            src={PlusIcon}
            alt=""
            className="plus-btn"
            onClick={() => props.setInputSelectToggle("input")}
          />
          <select
            onChange={(e:any) => {props.onSelectChange(e.target.value)}}
            name={props.selectName}
            id={props.selectId}
            value={props.defaultSelect}
            className={props.error && "error"}
          >
            <option value="">{t("Select...")}</option>
            {props.selectValue?.map((items: any, index: number) => (
              <option key={index + Math.random()} value={items[props.dropKey?props.dropKey:'value']}>{items[props.dropVal?props.dropVal:'name']}</option>
            ))}
          </select>
        </div>
      ) : (
        <div className="close-btn-input">
          <img
            src={CloseIcon}
            alt=""
            className="close-btn"
            onClick={() => props.setInputSelectToggle("select")}
          />
          <input
            type={props.inputType}
            inputMode={props.inputmode}
            defaultValue={props.defaultValue}
            value={props.inputValue}
            placeholder={props.placeholder}
            readOnly={props.readOnly}
            autoComplete={props.autoComplete}
            name={props.inputName}
            id={props.inputId}
            className={props.error && "error"}
            onChange={props.onInputChange}
          />
        </div>
      )}

      {props.error && <span className="error">{props.error}</span>}
    </div>
  );
};

export default InputSelectGroup;
