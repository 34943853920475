import React, { useEffect, useState } from "react";
import Modal from "react-bootstrap/Modal";
import Datepicker from "../../Common/Datepicker/Datepicker";
import InputGroups from "../../Common/InputGroups/InputGroups";
import SelectGropus from "../../Common/SelectGroups/SelectGroups";
import type { DatePickerProps } from "antd";
import moment from "moment";
import ToggleButton from "../../Common/ToggleButton/ToggleButton";
import CheckboxLabel from "../../Common/CheckboxLabel/CheckboxLabel";
import TextButton from "../../Common/TextButton/TextButton";
import CustomeButton from "../../Common/CustomButton/CustomButton";
import { useDispatch, useSelector } from "react-redux";
import { metaHorseListAction } from "../../../Redux/General/GeneralAction";
import MultiSelectGroup from "../../Common/MultiSelectGroup/MultiSelectGroup";
import { useLoading } from "../../../context/LoadingContext";
import validate from "../../../Utility/Validation";
import { t } from "i18next";
import { END_POINTS } from "../../../Constant/Api";
import { exportDocument } from "../../../Redux/globallActions";

export default function ExportHorsePopup(props: any) {
  const INTIAL_DATA = {
    horse: {
      value: [],
      validation: ["requiredArray"],
      errors: [t("Horse is Required.")],
    },
    columns: {
      value: [],
      validation: ["requiredArray"],
      errors: [t("Details is Required.")],
    },
    date: {
      value: moment().format("YYYY-MM-DD"),
      validation: ["required"],
      errors: [t("Date is required.")],
    },
    title: {
      value: "",
      validation: ["required"],
      errors: [t("Title is required.")],
    },
    format: {
      value: "excel",
      validation: ["required"],
      errors: [t("Formate is required.")],
    },
  };

  const [formData, setFormData] = useState<any>(INTIAL_DATA);
  const { setLoading } = useLoading();
  const [formDataError, setFormDataError] = useState<any>({});
  const [isFormValidated, setIsFormValidated] = useState(false);

  const dateChange: DatePickerProps["onChange"] = (date, dateString) => {
    let dateIs = date?.format("YYYY-MM-DD");
    setData("date", dateIs);
  };

  const [selected, setSelected] = useState("pdf");
  const [checkedRec, setCheckedRec] = useState<any[]>([]);
  const dispatch = useDispatch();
  const horseList = useSelector(
    (state: any) => state?.metaHorseListReducer?.data?.component
  );

  const toggleBtnValue = [
    { name: "PDF", value: "pdf" },
    { name: "Excel", value: "excel" },
    { name: "JPEG", value: "Image" },
  ];

  useEffect(() => {
    setFormDataError({ ...validate(formData).errors });
  }, [formData]);

  useEffect(() => {
    if (props?.show === false) {
      setFormDataError({});
      setFormData(INTIAL_DATA);
      setIsFormValidated(false);
    }
  }, [props?.show]);

  const submitHandler = () => {
    if (validate(formData).isValidated === true) {
      let params: any = {};
      params["horse"] = formData?.horse?.value?.toString();
      params["title"] = formData?.title?.value;
      params["date"] = formData?.date?.value;
      params["format"] = formData?.format?.value;
      params["column"] = formData?.columns?.value?.toString();

      setLoading(true);
      exportDocument(END_POINTS.horseExport, params, "horseList")
        .then(() => {})
        .finally(() => {
          setLoading(false);
          props.onHide();
        });
    } else {
      setFormDataError({ ...validate(formData)?.errors });
      setIsFormValidated(true);
    }
  };

  const checkSingleRec = (e: any, val: number) => {
    //
    var checkedRecData: number[] = Object.assign([], formData?.columns?.value);
    if (e.target.checked) {
      checkedRecData.push(val);
    } else {
      checkedRecData = checkedRecData.filter((c: number) => c !== val);
    }
    setData("columns", checkedRecData);
  };

  const handleSelectAll = (e: any) => {
    if (e.target.checked) {
      var checkedRecData: any[] = Object.assign([], formData?.columns);
      props.checkboxValue.map((li: any) => {
        if (!checkedRecData.includes(li.key)) {
          checkedRecData.push(li.key);
        }
      });
      setData("columns", checkedRecData);
    } else {
      setData("columns", []);
    }
  };

  const setData = (field: string, value: any) => {
    const tempOb = Object.assign({}, formData);
    tempOb[field].value = value;
    setFormData({ ...tempOb });
  };

  useEffect(() => {
    let params = {};
    metaHorseListAction(params);
  }, []);

  const { checkboxValue, ...rest } = props;
  return (
    <Modal {...rest} centered className="export-horse-popup modal-416">
      <Modal.Header closeButton>
        <p className="tl"> {t("Export Horse List")} </p>
      </Modal.Header>
      <Modal.Body>
        <div>
          <InputGroups
            label={`${t("Title")}*`}
            type="text"
            autoComplete="off"
            value={formData?.title?.value}
            onChange={(e: any) => setData("title", e.target.value)}
            error={
              isFormValidated && formDataError["title"]
                ? formDataError["title"]
                : null
            }
          />
          <Datepicker
            label={`${t("Date")}`}
            
            onChange={dateChange}
            value={moment(formData?.date?.value)}
            error={
              isFormValidated && formDataError["date"]
                ? formDataError["date"]
                : null
            }
          />
          <MultiSelectGroup
            label={`${t("Horse")}*`}
            value={horseList}
            defaultSelect={formData?.horse?.value}
            dropKey="id"
            dropVal="name"
            onChange={(e: any) => {
              setData("horse", e);
            }}
            error={
              isFormValidated && formDataError["horse"]
                ? formDataError["horse"]
                : null
            }
          />

          <ToggleButton
            value={toggleBtnValue}
            label={`${t("Format")}*`}
            selected={formData?.format?.value}
            setSelected={(val: any) => {
              setData("format", val);
            }}
          />
        </div>
        <div className="details">
          <p className="tm">{`${t("Details")}*`}</p>
          <div className="select-all">
            <CheckboxLabel
              checked={
                formData?.columns?.value?.length == props?.checkboxValue?.length
              }
              onChange={(e: any) => handleSelectAll(e)}
            >
            {t("Select All")}  
            </CheckboxLabel>
          </div>
          <div className="ck-wrapper">
            {props?.checkboxValue?.map((items: any, index: number) => (
              <div className="ck-block" key={index + "ck"}>
                <CheckboxLabel
                  checked={formData?.columns?.value?.includes(items.key)}
                  value={items.key}
                  onChange={(e: any) => checkSingleRec(e, items.key)}
                >
                  {items.value}
                </CheckboxLabel>
              </div>
            ))}
          </div>
          {isFormValidated && formDataError["columns"] && (
            <span className="error">{formDataError["columns"]}</span>
          )}
        </div>
       
      </Modal.Body>
      <Modal.Footer>
        <div className="footer-2-btn">
          <TextButton onClick={props.onHide}>{t("Cancel")} </TextButton>
          <CustomeButton bg="fill" onClick={submitHandler}>
            {t("Add")}
          </CustomeButton>
        </div>
      </Modal.Footer>
    </Modal>
  );
}
