import React from "react";

export default function UserCogIcon() {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M16.615 3H6.715C6.1525 3 5.59 3.225 5.2525 3.5625C4.915 4.0125 4.69 4.4625 4.69 5.025V18.975C4.69 19.5375 4.915 19.9875 5.2525 20.4375C5.59 20.775 6.1525 21 6.715 21L9.5275 18.525L12.2275 21L14.9275 18.525L17.4025 21C17.965 21 18.415 20.775 18.7525 20.4375C19.09 20.1 19.315 19.5375 19.315 18.975V5.5875"
        stroke="#919EAB"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M13.24 8.96245C13.015 8.73745 12.565 8.51245 12.0025 8.51245C10.8775 8.51245 10.315 9.18745 10.315 9.74995C10.315 10.875 11.44 10.9875 12.115 11.325C12.79 11.4375 13.8025 11.775 13.8025 12.675C13.8025 13.2375 13.24 13.9125 12.0025 13.9125C11.215 13.9125 10.6525 13.6875 10.315 13.2375"
        stroke="#919EAB"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M11.89 8.51249V7.04999"
        stroke="#919EAB"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M11.89 15.375V14.025"
        stroke="#919EAB"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}
