import { END_POINTS } from "../../../Constant/Api";
import { SHOW_MESSAGE } from "../../../Constant/General";
import { globalPostActionWithReducer } from "../../globallActions"; 
import { ADD_MEASUREMENTS_FAILD, ADD_MEASUREMENTS_LONG, ADD_MEASUREMENTS_REQUEST, ADD_MEASUREMENTS_SUCCESS, DESTROY_MEASUREMENTS_FAILD, DESTROY_MEASUREMENTS_LONG, DESTROY_MEASUREMENTS_REQUEST, DESTROY_MEASUREMENTS_SUCCESS, GET_MEASUREMENTS_LIST_FAILD, GET_MEASUREMENTS_LIST_LONG, GET_MEASUREMENTS_LIST_REQUEST, GET_MEASUREMENTS_LIST_SUCCESS, VIEW_MEASUREMENTS_FAILD, VIEW_MEASUREMENTS_LONG, VIEW_MEASUREMENTS_REQUEST, VIEW_MEASUREMENTS_SUCCESS } from "./MeasurementsTypes";


export function addEditMeasurementsAction(params: any) {
    let apiUrl = END_POINTS.measurementsAddEdit;
    return globalPostActionWithReducer(
      params,
      apiUrl,
      ADD_MEASUREMENTS_REQUEST,
      ADD_MEASUREMENTS_SUCCESS,
      ADD_MEASUREMENTS_LONG,
      ADD_MEASUREMENTS_FAILD,
      SHOW_MESSAGE
    );
}

export function getMeasurementsListAction(params: any) {
  let apiUrl = END_POINTS.measurementsList;
  return globalPostActionWithReducer(
    params,
    apiUrl,
    GET_MEASUREMENTS_LIST_REQUEST,
    GET_MEASUREMENTS_LIST_SUCCESS,
    GET_MEASUREMENTS_LIST_LONG,
    GET_MEASUREMENTS_LIST_FAILD
  );
}

export function destroyMeasurementsAction(params: any){
  let apiUrl = END_POINTS.destroyMesurements;
  return globalPostActionWithReducer(
    params,
    apiUrl,
    DESTROY_MEASUREMENTS_REQUEST,
    DESTROY_MEASUREMENTS_SUCCESS,
    DESTROY_MEASUREMENTS_LONG,
    DESTROY_MEASUREMENTS_FAILD,
    SHOW_MESSAGE
  );
}


export function viewMeasurementsAction(params: any){
  let apiUrl = END_POINTS.measurementView;
  return globalPostActionWithReducer(
    params,
    apiUrl,
    VIEW_MEASUREMENTS_REQUEST,
    VIEW_MEASUREMENTS_SUCCESS,
    VIEW_MEASUREMENTS_LONG,
    VIEW_MEASUREMENTS_FAILD
  );
}