import { globalPostAction } from "../../actions/globalActions";
import { END_POINTS } from "../../Constant/Api";
import { SHOW_MESSAGE } from "../../Constant/General";

export function addEditHorse(params: any) {
  let apiUrl = END_POINTS.addEditHorse;
  return globalPostAction(params, apiUrl , SHOW_MESSAGE);
}

export function deleteHorse(params: any) {
  let apiUrl = END_POINTS.deleteHorse;
  return globalPostAction(params, apiUrl , SHOW_MESSAGE);
}

export function viewHorse(params: any) {
  let apiUrl = END_POINTS.horseDetail;
  return globalPostAction(params, apiUrl);
}

export function getbulkDietList(params: any) {
  let apiUrl = END_POINTS.bulkDietList;
  return globalPostAction(params, apiUrl);
}

export function getbulkFarrierList(params: any) {
  let apiUrl = END_POINTS.bulkFarrierList;
  return globalPostAction(params, apiUrl);
}

export function addBulkFarrier(params: any) {
  let apiUrl = END_POINTS.addBulkFarrier;
  return globalPostAction(params, apiUrl , SHOW_MESSAGE );
}

export function getHorseDetails(params: any){
  let apiUrl = END_POINTS.horseDetail;
  return globalPostAction(params, apiUrl);
}

export function getHorseDetailsMetaData(params: any = {}){
  let apiUrl = END_POINTS.horseDetailMeta;
  return globalPostAction(params, apiUrl);
}

export function gethorseList(params: any){
  let apiUrl = END_POINTS.horselist;
  return globalPostAction(params, apiUrl);
}

export function getMetaHorseList(params: any = {}){
  let apiUrl = END_POINTS.metaHorseList;
  return globalPostAction(params, apiUrl);
}

export function changeFarmVisibalityApi(params: any = {}){
  let apiUrl = END_POINTS.changeFarmVisibality;
  return globalPostAction(params, apiUrl , SHOW_MESSAGE);
}
