
import { ADD_FARRIER_RECORD_REQUEST, ADD_FARRIER_RECORD_SUCCESS, ADD_FARRIER_RECORD_LONG, ADD_FARRIER_RECORD_FAILD, GET_FARRIER_RECORD_LIST_REQUEST, GET_FARRIER_RECORD_LIST_SUCCESS, GET_FARRIER_RECORD_LIST_LONG, GET_FARRIER_RECORD_LIST_FAILD, DESTROY_FARRIER_RECORD_REQUEST, DESTROY_FARRIER_RECORD_SUCCESS, DESTROY_FARRIER_RECORD_LONG, DESTROY_FARRIER_RECORD_FAILD, VIEW_FARRIER_RECORD_REQUEST, VIEW_FARRIER_RECORD_SUCCESS, VIEW_FARRIER_RECORD_LONG, VIEW_FARRIER_RECORD_FAILD, ADD_FARRIER_RECORD_CLEAR, DESTROY_FARRIER_RECORD_CLEAR, GET_FARRIER_RECORD_LIST_CLEAR, VIEW_FARRIER_RECORD_CLEAR } from "./FarrierTypes";

export function addEditFarrierRecordRedux(state = {}, action: any) {
    switch (action.type) {
      case ADD_FARRIER_RECORD_REQUEST:
        return { ...action.payload };
      case ADD_FARRIER_RECORD_SUCCESS:
        return { ...action.payload };
      case ADD_FARRIER_RECORD_FAILD:
        return { ...action.payload };
      case ADD_FARRIER_RECORD_LONG:
        return { ...action.payload };
      case ADD_FARRIER_RECORD_CLEAR:
        return {};
      default:
        return state;
    }
}

export function farrierRecordListRedux(state = {}, action: any) {
  switch (action.type) {
    case GET_FARRIER_RECORD_LIST_REQUEST:
      return { ...action.payload };
    case GET_FARRIER_RECORD_LIST_SUCCESS:
      return { ...action.payload };
    case GET_FARRIER_RECORD_LIST_FAILD:
      return { ...action.payload };
    case GET_FARRIER_RECORD_LIST_LONG:
      return { ...action.payload };
    case GET_FARRIER_RECORD_LIST_CLEAR:
      return {};
    default:
      return state;
  }
}

export function destroyFarrierRecordRedux(state = {}, action: any) {
  switch (action.type) {
    case DESTROY_FARRIER_RECORD_REQUEST:
      return { ...action.payload };
    case DESTROY_FARRIER_RECORD_SUCCESS:
      return { ...action.payload };
    case DESTROY_FARRIER_RECORD_FAILD:
      return { ...action.payload };
    case DESTROY_FARRIER_RECORD_LONG:
      return { ...action.payload };
    case DESTROY_FARRIER_RECORD_CLEAR:
      return {};
    default:
      return state;
  }
}


export function viewFarrierRecordRedux(state = {}, action: any) {
  switch (action.type) {
    case VIEW_FARRIER_RECORD_REQUEST:
      return { ...action.payload };
    case VIEW_FARRIER_RECORD_SUCCESS:
      return { ...action.payload };
    case VIEW_FARRIER_RECORD_FAILD:
      return { ...action.payload };
    case VIEW_FARRIER_RECORD_LONG:
      return { ...action.payload };
    case VIEW_FARRIER_RECORD_CLEAR:
      return {};
    default:
      return state;
  }
}

