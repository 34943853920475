import { t } from "i18next";
import React, { useContext, useEffect, useState } from "react";
import { Col, Row } from "react-bootstrap";
import PasswordIcon from "../../../Assets/img/Key.svg";
import { ERROR } from "../../../Constant/General";
import { useLoading } from "../../../context/LoadingContext";
import { SidebarContext } from "../../../context/SideBarContext";
import { changePassword } from "../../../Redux/NonRedux/account";
import { showToast } from "../../../Utility/General";
import validate from "../../../Utility/Validation";
import CustomeButton from "../../Common/CustomButton/CustomButton";
import InputGroups from "../../Common/InputGroups/InputGroups";
import PasswordGroups from "../../Common/PasswordGroups/PasswordGroups";

export default function ChangePasswordPage() {
  const { setSidebar }: any = useContext(SidebarContext);
  useEffect(() => {
    setSidebar("");
  }, []);

  const INTIAL_DATA = {
    old_password: {
      value: "",
      validation: ["required"],
      errors: [t("Old Password Type is required.")],
    },
    password: {
      value: "",
      validation: ["required", "min:6"],
      errors: [
        t("Password is required."),
        t("Password must be atleast 6 Characters."),
      ],
    },
    confirm_password: {
      value: "",
      validation: ["required", "sameAsField:password"],
      errors: [
        t("Confirm Password is required."),
        t("must be same as password"),
      ],
    },
  };

  const [formData, setFormData] = useState<any>(INTIAL_DATA);
  const { setLoading } = useLoading();
  const [formDataError, setFormDataError] = useState<any>({});
  const [isFormValidated, setIsFormValidated] = useState(false);

  const setData = (field: string, value: any) => {
    const tempOb = Object.assign({}, formData);
    tempOb[field].value = value;
    setFormData({ ...tempOb });
  };

  const submitHandler = (e: any) => {
    e.preventDefault();
    if (validate(formData).isValidated === true) {
      let params: any = {};
      Object.keys(formData).forEach((element) => {
        params[element] = formData[element].value;
      });
      setLoading(true);
      changePassword(params)
        .then((response: any) => {
          setIsFormValidated(false);
          setFormData(INTIAL_DATA);
        })
        .finally(() => {
          setLoading(false);
        });
    } else {
      setFormDataError({ ...validate(formData)?.errors });
      setIsFormValidated(true);
    }
  };

  useEffect(() => {
    setFormDataError({ ...validate(formData).errors });
  }, [formData]);

  return (
    <div className="change-password-page">
      {/* Page Title Start */}
      <div className="page-title-wrapper">
        <div className="title-first-menu">
          <div className="title-icon-text">
            <img src={PasswordIcon} alt="" />
            <h5>{t("Change Password")} </h5>
          </div>
        </div>
      </div>
      {/* Page Title End */}
      <div className="page-content">
        <form onSubmit={submitHandler}>
          <div className="layout-720">
            <Row>
              <Col sm={6} md={6}>
                <PasswordGroups
                  label={t("Current Password")}
                  type="password"
                  autoComplete="off"
                  value={formData?.old_password?.value}
                  onChange={(e: any) => setData("old_password", e.target.value)}
                  error={
                    isFormValidated && formDataError["old_password"]
                      ? formDataError["old_password"]
                      : null
                  }
                />
              </Col>
            </Row>
            <Row>
              <Col sm={6} md={6}>
                <PasswordGroups
                  label={t("New Password")}
                  type="password"
                  autoComplete="off"
                  value={formData?.password?.value}
                  onChange={(e: any) => setData("password", e.target.value)}
                  error={
                    isFormValidated && formDataError["password"]
                      ? formDataError["password"]
                      : null
                  }
                />
              </Col>
              <Col sm={6} md={6}>
                <PasswordGroups
                  label={t("Confirm Password")}
                  type="password"
                  autoComplete="off"
                  value={formData?.confirm_password?.value}
                  onChange={(e: any) =>
                    setData("confirm_password", e.target.value)
                  }
                  error={
                    isFormValidated && formDataError["confirm_password"]
                      ? formDataError["confirm_password"]
                      : null
                  }
                />
              </Col>
            </Row>
            <div className="footer-btn">
              <CustomeButton
                bg="fill"
                disabled={Object.keys(formDataError)?.includes(
                  "confirm_password"
                )}
                type="submit"
              >
               {t("Save")}
 
              </CustomeButton>
            </div>
          </div>
        </form>
      </div>
    </div>
  );
}
