import { useEffect, useState } from "react";

const PasswordGroups = (props: any) => {
  const [isActive, setIsActive] = useState(false);
  const [passwordShown, setPasswordShown] = useState(false);

  function handleTextChange(text: any) {
    text !== "" ? setIsActive(true) : setIsActive(false);
  }

  const togglePassword = (e: any) => {
    e.preventDefault();
    setPasswordShown(!passwordShown);
  };

  const topLabel = (e: any) => {
    handleTextChange(e.target.value);
    if (props.onChange) {
      props.onChange(e);
    }
  };

  useEffect(() => {
    if (props.value) {
      handleTextChange(props.value);
    }
  }, []);

  return (
    <div className="input-groups password">
      <label>{props.label}</label>
      <div className="pwd-wrap">
        <input
          type={
            passwordShown ? "text" : props.type == "text" ? "text" : "password"
          }
          defaultValue={props.defaultValue}
          value={props.value}
          placeholder={props.placeholder}
          autoComplete={props.autoComplete}
          readOnly={props.readOnly}
          name={props.name}
          id={props.id}
          className={props.error && "error"}
          onChange={(e) => topLabel(e)}
        />
        <button
          className={
            passwordShown ? "visibilitybtn show" : "visibilitybtn hide"
          }
          onClick={(e) => togglePassword(e)}
        ></button>
      </div>
      {props.error && <span className="error">{props.error}</span>}
    </div>
  );
};

export default PasswordGroups;
