import { globalPostAction } from "../../actions/globalActions";
import { END_POINTS } from "../../Constant/Api";
import { SHOW_MESSAGE } from "../../Constant/General";

export function getAccountDetails(params: any = {}) {
  let apiUrl = END_POINTS.accouuntInfoView;
  return globalPostAction(params, apiUrl);
}

export const updateAccountDetails = (params: any ) => {
  let apiUrl = END_POINTS.accountInfoUpdate;
  return globalPostAction(params, apiUrl, SHOW_MESSAGE);
};

export const changePassword = (params: any ) => {
  let apiUrl = END_POINTS.changePassword;
  return globalPostAction(params, apiUrl, SHOW_MESSAGE);
};

export const logOutUser = ( ) => {
  let apiUrl = END_POINTS.logout;
  return globalPostAction({}, apiUrl, SHOW_MESSAGE);
};
