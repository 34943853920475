import React from "react";

export default function ContactIcon() {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M16.8 3H7.2C4.9 3 3 4.9 3 7.2V16.8C3 19.1 4.9 21 7.2 21H16.8C19.1 21 21 19.1 21 16.8V7.2C21 4.9 19.1 3 16.8 3Z"
        stroke="#919EAB"
        strokeWidth="1.5"
      />
      <path
        d="M12 13.5C10.1 13.5 8.5 15.1 8.5 17V21H15.6V17.1C15.5 15.1 13.9 13.5 12 13.5Z"
        stroke="#919EAB"
        strokeWidth="1.5"
      />
      <path
        d="M12 11C13.1 11 14 10.1 14 9C14 7.9 13.1 7 12 7C10.9 7 10 7.9 10 9C10 10.1 10.9 11 12 11Z"
        stroke="#919EAB"
        strokeWidth="1.5"
      />
    </svg>
  );
}
