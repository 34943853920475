import React, { useEffect, useState } from "react";
import Modal from "react-bootstrap/Modal";
import Datepicker from "../../Common/Datepicker/Datepicker";
import InputGroups from "../../Common/InputGroups/InputGroups";
import SelectGropus from "../../Common/SelectGroups/SelectGroups";
import type { DatePickerProps } from "antd";
import moment from "moment";
import ToggleButton from "../../Common/ToggleButton/ToggleButton";
import CheckboxLabel from "../../Common/CheckboxLabel/CheckboxLabel";
import TextButton from "../../Common/TextButton/TextButton";
import CustomeButton from "../../Common/CustomButton/CustomButton";
import { useDispatch, useSelector } from "react-redux";
import { metaHorseListAction } from "../../../Redux/General/GeneralAction";
import MultiSelectGroup from "../../Common/MultiSelectGroup/MultiSelectGroup";
import { useLoading } from "../../../context/LoadingContext";
import validate from "../../../Utility/Validation";
import { t } from "i18next";
import { END_POINTS } from "../../../Constant/Api";
import { exportDocument } from "../../../Redux/globallActions";
import { useTranslation } from "react-i18next";
import { Switch } from "antd";

export default function DashboardSetupPopup(props: any) {
  const { t } = useTranslation();

  const { ...rest } = props;

  return (
    <Modal {...rest} centered className="dashboard-setup-popup modal-416">
      <Modal.Header closeButton>
        <p className="tl">Dashboard Setup</p>
      </Modal.Header>
      <Modal.Body>
        <div>
          <div className="switch-label">
            <p className="bl">Horse by Gender</p>
            <Switch
              size="small"
              defaultChecked={true}
            />
          </div>
          <div className="switch-label">
            <p className="bl">Horse by Colour</p>
            <Switch
              size="small"
              defaultChecked={true}
            />
          </div>
          <div className="switch-label">
            <p className="bl">Horse by Age</p>
            <Switch
              size="small"
              defaultChecked={true}
            />
          </div>
          <div className="switch-label">
            <p className="bl">Number of Foals</p>
            <Switch
              size="small"
              defaultChecked={true}
            />
          </div>
          <div className="switch-label">
            <p className="bl">Foals by Colour</p>
            <Switch
              size="small"
              defaultChecked={true}
            />
          </div>
          <div className="switch-label">
            <p className="bl">Foals Per Mare</p>
            <Switch
              size="small"
              defaultChecked={true}
            />
          </div>
          <div className="switch-label">
            <p className="bl">Foals Per Stallion</p>
            <Switch
              size="small"
              defaultChecked={true}
            />
          </div>
          <div className="switch-label">
            <p className="bl">Cost/Expenses</p>
            <Switch
              size="small"
              defaultChecked={true}
            />
          </div>
          <div className="switch-label">
            <p className="bl">Achievements</p>
            <Switch
              size="small"
              defaultChecked={true}
            />
          </div>
          <div className="switch-label">
            <p className="bl">Sales</p>
            <Switch
              size="small"
              defaultChecked={true}
            />
          </div>
        </div>
      </Modal.Body>
      <Modal.Footer>
        <div className="footer-2-btn">
          <TextButton>{t("Cancel")}</TextButton>
          <CustomeButton bg="fill">{t("Submit")}</CustomeButton>
        </div>
      </Modal.Footer>
    </Modal>
  );
}
