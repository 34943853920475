import type { DatePickerProps } from "antd";
import { t } from "i18next";
import moment, { Moment } from "moment";
import React, { useEffect, useState } from "react";
import { Col, Row } from "react-bootstrap";
import Modal from "react-bootstrap/Modal";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { useLoading } from "../../../context/LoadingContext";
import { horseInfoAction } from "../../../Redux/Horse/HorseAction";
import {
  addEditBreedingControl,
  addEditBreedingFoal,
} from "../../../Redux/NonRedux/breeding";
import { isFileValidated, getFileNamefromPath, encrDecrStr } from "../../../Utility/General";
import validate from "../../../Utility/Validation";
import CustomeButton from "../../Common/CustomButton/CustomButton";
import Datepicker from "../../Common/Datepicker/Datepicker";
import FileUpload from "../../Common/FileUpload/FileUpload";
import InputGroups from "../../Common/InputGroups/InputGroups";
import InputSelectGroup from "../../Common/InputSelectGroup/InputSelectGroup";
import InputSelectMix from "../../Common/InputSelectMix/InputSelectMix";
import SelectGropus from "../../Common/SelectGroups/SelectGroups";
import TextButton from "../../Common/TextButton/TextButton";
import ToggleButton from "../../Common/ToggleButton/ToggleButton";
import TooltipContent from "../../Common/TooltipContent/TooltipContent";

export default function AddFoalPopup(props: any) {
  const INTIAL_DATA = {
    id: {
      value: "",
    },
    name: {
      value: "",
      validation: ["required"],
      errors: [t("Name is required.")],
    },
    birthdate: {
      value: moment().format("YYYY-MM-DD"),
      validation: [""],
      errors: [t("Date of birth is required.")],
    },
    sex: {
      value: "",
      validation: ["required"],
      errors: [t("sex is required.")],
    },
    color: {
      value: "",
      validation: [""],
      errors: [t("color is required.")],
    },
    sire: {
      value: "",
      validation: [""],
      errors: [t("")],
    },
    dam: {
      value: "",
      validation: [""],
      errors: [t("")],
    },
    breed: {
      value: "",
      validation: [""],
      errors: [t("breed is required.")],
    },
    strain: {
      value: "",
      validation: [""],
      errors: [t("strain is required.")],
    },
    select_owner: {
      value: "owner_id",
      validation: [""],
      errors: [t("Owner is required.")],
    },
    owner_id: {
      value: "",
      validation: [""],
      errors: [t("Owner is required.")],
    },
    owner: {
      value: "",
      validation: ["letters"],
      errors: [t("Incorrect format.")],
    },
    select_breeder: {
      value: "breeder_id",
      validation: [],
      errors: [t("")],
    },
    breeder: {
      value: "",
      validation: ["letters"],
      errors: [t("Incorrect format.")],
    },
    breeder_id: {
      value: "",
      validation: [],
      errors: [],
    },
    select_location: {
      value: "",
      validation: [""],
      errors: [t("")],
    },
    location_id: {
      value: "",
      validation: [],
      errors: [],
    },
    location: {
      value: "",
      validation: ["letters"],
      errors: [t("Incorrect format")],
    },
    breeder_status: {
      value: "2",
      validation: ["required"],
      errors: [t("breeder_status is required.")],
    },
  };

  const [formData, setFormData] = useState<any>(INTIAL_DATA);
  const { setLoading } = useLoading();
  const [formDataError, setFormDataError] = useState<any>({});
  const [isFormValidated, setIsFormValidated] = useState(false);
  const [inputSelectToggle, setInputSelectToggle] = useState("select");
  const [ownerToggle, setOwnerToggle] = useState("select");
  const [breederToggle, setBreederToggle] = useState("select");
  const [locationToggle, setLocationToggle] = useState("select");
  const param = useParams();
  useEffect(() => {
    // ADD PREDEFINED FIELDS WHILE ADDING TO NEW FOAL
    if (
      props?.currentServiceDetails &&
      Object.keys(props?.currentServiceDetails)?.length
    ) {
      Object.keys(props?.currentServiceDetails).forEach((element: any) => {
        if (formData.hasOwnProperty(element)) {
          setData(element, props.currentServiceDetails[element]);
        }
      });
    }
  }, [props?.currentServiceDetails]);

  const dispatch = useDispatch();

  const toggleBtnValue = [
    { name: t("Yes"), value: "1" },
    { name: t("No"), value: "2" },
  ];

  const setData = (field: string, value: any, subField: any = null) => {
    const tempOb = Object.assign({}, formData);
    tempOb[field][subField ? subField : "value"] = value;
    setFormData({ ...tempOb });
  };

  const dateChange: DatePickerProps["onChange"] = (date, dateString) => {
    let dateIs = date ? date?.format("YYYY-MM-DD") : "";
    setData("birthdate", dateIs);
  };

  useEffect(() => {
    setFormDataError({ ...validate(formData).errors });
  }, [formData]);

  useEffect(() => {
    if (props.isedit && props?.editData) {
      Object.keys(formData).forEach((element) => {
        setData(element, props?.editData?.[element]);
      });
    } else {
      setFormData(INTIAL_DATA);
    }
  }, [props?.editData, props.isedit]);

  const toggleChange = (selectType: string, input: string) => {
    if (selectType === "select") {
      if (formData[`select_${input}`]?.value) {
        setData(`select_${input}`, `${input}_id`);
      }
      setData(input, "");
    } else {
      if (formData[`select_${input}`]?.value) {
        setData(`select_${input}`, input);
      }

      setData(`${input}_id`, "");
    }
  };

  useEffect(() => {
    if (props?.show === false) {
      setFormDataError({});
      // setFormData(INTIAL_DATA);
      setIsFormValidated(false);
      setOwnerToggle("select");
      setLocationToggle("select");
      setBreederToggle("select");
    } else {
      const date = new Date();
      setData("name", `${props?.sire} x ${props?.dam}`);
    }
  }, [props?.show]);

  const submitHandler = () => {
    if (validate(formData).isValidated === true) {
      const formParams: any = new FormData();
      Object.keys(INTIAL_DATA).forEach((element) => {
        if (formData[element]) {
          formParams.append(element, formData[element].value);
        }
      });
      setLoading(true);
      if (props?.serviceId) {
        formParams.append("service_id", props?.serviceId);
      }

      formParams.append("horse_id", props?.horseId);
      addEditBreedingFoal(formParams)
        .then((response: any) => {
          props?.onHide();
          props?.setRefreshList();
        })
        .finally(() => {
          setLoading(false);
        });
    } else {
      setFormDataError({ ...validate(formData)?.errors });
      setIsFormValidated(true);
    }
  };

  const {
    setRefreshList,
    editData,
    metaData,
    serviceId,
    horseId,
    sire,
    dam,
    breedingServiceDate,
    isedit,
    ...rest
  } = props;

  return (
    <Modal {...rest} centered className="add-foal-popup modal-784">
      <Modal.Header closeButton>
        <p className="tl">{isedit ? t("Edit Foal") : t("Add Foal")} </p>
      </Modal.Header>
      <Modal.Body>
        <Row>
          <Col sm={6} md={6}>
            <InputGroups
              label={`${t("Name")}*`}
              type="text"
              autoComplete="off"
              value={formData?.name?.value}
              onChange={(e: any) => setData("name", e.target.value)}
              error={
                isFormValidated && formDataError["name"]
                  ? formDataError["name"]
                  : null
              }
            />
          </Col>
          <Col sm={6} md={6}>
            <Datepicker
              startDate={
                breedingServiceDate
                  ? moment(breedingServiceDate).format("YYYY-MM-DD")
                  : null
              }
              allowClear={true}
              endDate={moment().format("YYYY-MM-DD")}
              label={`${t("Date of Birth")}`}
              notDisableToday={true}
              onChange={dateChange}
              value={
                formData?.birthdate?.value
                  ? moment(formData?.birthdate?.value)
                  : undefined
              }
              error={
                isFormValidated && formDataError["birthdate"]
                  ? formDataError["birthdate"]
                  : null
              }
            />
          </Col>
          <Col sm={6} md={6}>
            <SelectGropus
              label={`${t("Sex")}*`}
              value={metaData?.gender}
              dropKey="id"
              dropVal="metadata"
              defaultSelect={formData?.sex?.value}
              onChange={(e: any) => setData("sex", e.target.value)}
              error={
                isFormValidated && formDataError["sex"]
                  ? formDataError["sex"]
                  : null
              }
            />
          </Col>
          <Col sm={6} md={6}>
            <SelectGropus
              label={`${t("Colour")}`}
              value={metaData?.color}
              dropKey="id"
              dropVal="metadata"
              defaultSelect={formData?.color?.value}
              onChange={(e: any) => setData("color", e.target.value)}
              error={
                isFormValidated && formDataError["color"]
                  ? formDataError["color"]
                  : null
              }
            />
          </Col>
          <Col sm={6} md={6}>
            <InputGroups label={`${t("Sire")}`} value={sire} readOnly={true} />
          </Col>
          <Col sm={6} md={6}>
            <InputGroups label={`${t("Dam")}`} value={dam} readOnly={true} />
          </Col>
          <Col sm={6} md={6}>
            <SelectGropus
              label={`${t("Breed")}`}
              value={metaData?.breed}
              dropKey="id"
              dropVal="metadata"
              defaultSelect={formData?.breed?.value}
              onChange={(e: any) => setData("breed", e.target.value)}
              error={
                isFormValidated && formDataError["breed"]
                  ? formDataError["breed"]
                  : null
              }
            />
          </Col>
          <Col sm={6} md={6}>
            <InputGroups
              label={`${t("Strain")}`}
              value={formData?.strain?.value}
              type="text"
              autoComplete="off"
              onChange={(e: any) => setData("strain", e.target.value)}
              error={
                isFormValidated && formDataError["strain"]
                  ? formDataError["strain"]
                  : null
              }
            />
          </Col>
          <Col sm={6} md={6}>
            <InputSelectGroup
              label={`${t("Owner")}`}
              selectValue={metaData?.owner}
              inputSelectToggle={ownerToggle}
              inputValue={formData?.owner?.value}
              setInputSelectToggle={(val: any) => {
                setOwnerToggle(val);
                toggleChange(val, "owner");
              }}
              onSelectChange={(value: any) => {
                setData("owner_id", value);
              }}
              defaultSelect={formData?.owner_id?.value}
              dropKey="id"
              dropVal="first_name"
              onInputChange={(e: any) => {
                setData("owner", e.target.value);
              }}
              error={
                isFormValidated &&
                (formDataError["owner"] || formDataError["owner_id"])
                  ? formDataError["owner"] || formDataError["owner_id"]
                  : null
              }
            />
          </Col>
          <Col sm={6} md={6}>
            <InputSelectGroup
              label={`${t("Breeder")}`}
              selectValue={metaData?.breeder}
              inputSelectToggle={breederToggle}
              setInputSelectToggle={(val: any) => {
                setBreederToggle(val);
                toggleChange(val, "breeder");
              }}
              onSelectChange={(value: any) => {
                setData("breeder_id", value);
              }}
              defaultSelect={formData?.breeder_id?.value}
              dropKey="id"
              dropVal="first_name"
              onInputChange={(e: any) => {
                setData("breeder", e.target.value);
              }}
              error={
                isFormValidated &&
                (formDataError["breeder"] || formDataError["breeder_id"])
                  ? formDataError["breeder"] || formDataError["breeder_id"]
                  : null
              }
            />
          </Col>
          <Col sm={6} md={6}>
            <InputSelectGroup
              label={`${t("Location")}`}
              selectValue={metaData?.location}
              inputSelectToggle={locationToggle}
              inputValue={formData?.location?.value}
              setInputSelectToggle={(val: any) => {
                setLocationToggle(val);
                toggleChange(val, "location");
              }}
              onSelectChange={(value: any) => {
                setData("location_id", value);
              }}
              defaultSelect={formData?.location_id?.value}
              dropKey="id"
              dropVal="name"
              onInputChange={(e: any) => {
                setData("location", e.target.value);
              }}
              error={
                isFormValidated &&
                (formDataError["location"] || formDataError["location_id"])
                  ? formDataError["location"] || formDataError["location_id"]
                  : null
              }
            />
          </Col>
          <Col sm={6} md={6}>
            <ToggleButton
              value={toggleBtnValue}
              label={`${t("Are you the Breeder?")}`}
              selected={formData?.breeder_status?.value}
              setSelected={(value: any) => {
                setData("breeder_status", value);
              }}
            />
          </Col>
        </Row>
      </Modal.Body>
      <Modal.Footer>
        <div className="footer-2-btn">
          <TextButton onClick={props.onHide}>{t("Cancel")}</TextButton>
          <CustomeButton bg="fill" onClick={submitHandler}>
            {" "}
            {props.isedit ? t("Update") : t("Add")}
          </CustomeButton>
        </div>
      </Modal.Footer>
    </Modal>
  );
}
