import { globalPostAction } from "../../actions/globalActions";
import { END_POINTS } from "../../Constant/Api";
import { SHOW_MESSAGE } from "../../Constant/General";

export function addExpenses(params: any) {
    let apiUrl = END_POINTS.addExpenses;
    return globalPostAction(params, apiUrl , SHOW_MESSAGE);
}

export function categoryCostRecordsList(params: any) {
    let apiUrl = END_POINTS.categoryCostRecordsList;
    return globalPostAction(params, apiUrl );
}

export function categoryCostRecordsDetails(params: any) {
    let apiUrl = END_POINTS.categoryCostRecordsDetails;
    return globalPostAction(params, apiUrl );
}

export function horseWiseCostDetails(params: any) {
    let apiUrl = END_POINTS.horseWiseCostRecordDetails;
    return globalPostAction(params, apiUrl );
}

export function getSingleCost(params: any) {
    let apiUrl = END_POINTS.getSingleCost;
    return globalPostAction(params, apiUrl );
}

export function getCostCategory(params: any = {}) {
    let apiUrl = END_POINTS.costCategory;
    return globalPostAction(params, apiUrl );
}

export function destroyCost(params: any) {
    let apiUrl = END_POINTS.destroyCost;
    return globalPostAction(params, apiUrl , SHOW_MESSAGE );
}