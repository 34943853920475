import { globalPostAction } from "../../actions/globalActions";
import { END_POINTS } from "../../Constant/Api";
import { SHOW_MESSAGE } from "../../Constant/General";

export function getReminderPreferenceList(params: any) {
  let apiUrl = END_POINTS.reminderPreferenceList;
  return globalPostAction(params, apiUrl);
}

export const savePreference = (params: any) => {
  let apiUrl = END_POINTS.reminderPreferenceSave;
  return globalPostAction(params, apiUrl, SHOW_MESSAGE);
};

export const deleteReminder = (params: any) => {
  let apiUrl = END_POINTS.deleteReminder;
  return globalPostAction(params, apiUrl, SHOW_MESSAGE);
};

export const getreminderList = (params: any) => {
  let apiUrl = END_POINTS.reminderList;
  return globalPostAction(params, apiUrl);
};

export const changeReminderStatus = (params: any) => {
  let apiUrl = END_POINTS.reminderStatusChange;
  return globalPostAction(params, apiUrl, SHOW_MESSAGE);
};

export const nextPreReminder = (params:any) => {
  let apiUrl = END_POINTS.nextPreReminder;
  return globalPostAction(params, apiUrl);
}