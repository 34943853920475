import React, { useEffect, useState } from "react";
import { Col, Row } from "react-bootstrap";
import Modal from "react-bootstrap/Modal";
import MinusIcon from "../../../Assets/img/minus-circle.svg";
import PlusIcon from "../../../Assets/img/plus-circle.svg";

import CustomeButton from "../../Common/CustomButton/CustomButton";
import InputGroups from "../../Common/InputGroups/InputGroups";
import InputSelectMix from "../../Common/InputSelectMix/InputSelectMix";
import TextButton from "../../Common/TextButton/TextButton";
import { useDispatch, useSelector } from "react-redux";
import { API_STATUS } from "../../../Constant/Api";
import validate from "../../../Utility/Validation";
import { addBoardingPlan, editBoardingPlan } from "../../../Redux/NonRedux/boarding";
import { SUCCESS_RESPONSE_CODE } from "../../../Constant/Status";
import { getBoardingPlanList } from "../../../Redux/Boarding/BoardingAction";
import SingleBoardingPlan from "./SingleBoardingPlan";
import { useLoading } from "../../../context/LoadingContext";
import { t } from "i18next";

export default function BoardingPlanPopup(props: any) {
  const { boardingplanlist } = props;

  const INTIAL_BOARDING_PLAN_DATA = {
    boarding_plan: [
      {
        type: {
          value: "",
          validation: ["required"],
          errors: [t("Boarding type is required.")],
        },
        amount: {
          value: "",
          validation: ["required"],
          errors: [t("Amount is required.")],
        },

        id: {
          value: "",
        },
      },
    ],
  };

  

  

  const [planData, setPlanData] = useState(INTIAL_BOARDING_PLAN_DATA);
  const [isFormValidated, setIsFormValidated] = useState(false);
  const [planDataError, setPlanDataError] = useState<any>({});
  const [editId, setEditId] = useState<any>();
  const [planList , setPlanList] = useState<any>([]);
  const [apiStatus, setApiStatus] = useState(API_STATUS.SUCCESS);
  const dispatch = useDispatch();
  const {setLoading} = useLoading();

  useEffect(() => {
    if(boardingplanlist?.length){
      setPlanList(boardingplanlist);
    }
  }, [boardingplanlist])
  

  useEffect(() => {
    if (props?.show === false) {
      setPlanDataError({});
      setPlanData(INTIAL_BOARDING_PLAN_DATA);
      setIsFormValidated(false);
      setEditId('');

    }
  }, [props?.show]);

  useEffect(() => {
    if (isFormValidated) {
      setPlanDataError({ ...validate(planData)?.errors });
    }
  }, [planData]);

  const prefrence = useSelector(
    (state: any) => state?.masterReducer?.data?.component?.preferance
  );

  const addItem = () => {
    let items: any = JSON.parse(JSON.stringify(planData.boarding_plan));
    items.push(INTIAL_BOARDING_PLAN_DATA.boarding_plan[0]);
    setPlanData({ ...planData, boarding_plan: items });
  };

  const handleChange = (index: number, e: any, fieldName: string) => {
    let items: any = JSON.parse(JSON.stringify(planData.boarding_plan));
    items[index][fieldName].value = e.target.value;
    setPlanData({ ...planData, boarding_plan: items });
  };

  const removePlanItem = (index: number) => {
    let items = JSON.parse(JSON.stringify(planData.boarding_plan));
    if (items.length <= 1) {
      return;
    }
    items.splice(index, 1);
    setPlanData({ ...planData, boarding_plan: items });
  };

  const submitHandler = () => {
    if (validate(planData).isValidated === true) {
      let items: any = [];
      planData?.boarding_plan.map((item: any) => {
        let ob: any = {};
        ob["type"] = item.type.value;
        ob["amount"] = item.amount.value;
        items.push(ob);
      });

      let params: any = {
        boarding_plan: items,
      };
      setLoading(true);
      addBoardingPlan(params).then((response: any) => {
          props.onHide();
          let playPayload = {};
          getBoardingPlanList(playPayload);
      }).finally(() => {
        setLoading(false);
      });
    } else {
      setIsFormValidated(true);
      setPlanDataError({ ...validate(planData)?.errors });
    }
  };

  const handleEditSubmit = (params:any) => {
    setLoading(true);
    editBoardingPlan(params).then((res) => {
     let newPlan = planList.map((plan:any) => 
   plan.id == params?.id ? {...params} : plan
     )
     getBoardingPlanList({});
     setPlanList(newPlan);
     setEditId('');
    }).finally(() => {
      setLoading(false);
    })
   
  }

  return (
    <Modal {...props} centered className="boarding-plan-popup modal-824">
      <Modal.Header closeButton>
        <p className="tl">{t("Boarding Plans")}</p>
      </Modal.Header>
      <Modal.Body>
        <div className="data-tbl-wrapper">
          <div className="data-tbl ptb-12">
            <table>
              <thead>
                <tr>
                  <th>
                    <p className="tm">{t("Sr. No.")}</p>
                  </th>
                  <th>
                    <p className="tm">{t("Boarding Type")}</p>
                  </th>
                  <th>
                    <p className="tm">{t("Status")}</p>
                  </th>
                  <th>
                    <p className="tm text-right">{t("Amount Per Month")}</p>
                  </th>
                  <th className="text-right">
                    <p className="tm">{t("Action")}</p>
                  </th>
                </tr>
              </thead>
              <tbody>
                {planList &&
                  planList.length > 0 &&
                  planList.map((item: any, index: number) => (
                    <SingleBoardingPlan
                      item={item}
                      index={index}
                      key={item?.id}
                      editId={editId}
                      handleEdit={setEditId}
                      handleEditSubmit={handleEditSubmit}
                    />
                  ))}
              </tbody>
            </table>
          </div>
        </div>
        {planData?.boarding_plan.map((item: any, index: any) => {
          return (
            <React.Fragment key={index + "plan"}>
              <div className="repeate-box">
                <div className="nrm-pr-40">
                  <Row>
                    <Col sm={6} md={6}>
                      <InputGroups
                        label={`${t("Boarding type")}*`}
                        type="text"
                        autoComplete="off"
                        value={item?.type?.value}
                        onChange={(e: any) => handleChange(index, e, "type")}
                        error={
                          isFormValidated &&
                          planDataError["boarding_plan"]?.[index]?.["type"]
                            ? planDataError["boarding_plan"]?.[index]?.["type"]
                            : null
                        }
                      />
                    </Col>
                    <Col sm={6} md={6}>
                      <div className="remove-btn-parent">
                        <InputSelectMix
                          label={`${t("Amount Per Month")}*`}
                          inputType="number"
                          inputValue={item?.amount?.value}
                          onInputChange={(e: any) =>
                            handleChange(index, e, "amount")
                          }
                          inputSecondValue={prefrence?.currency_value}
                          inputError={
                            isFormValidated &&
                            planDataError["boarding_plan"]?.[index]?.["amount"]
                              ? planDataError["boarding_plan"]?.[index]?.[
                                  "amount"
                                ]
                              : null
                          }
                        />
                        {planData.boarding_plan?.length > 1 && (
                          <div className="remove-btn">
                            <img
                              src={MinusIcon}
                              onClick={() => removePlanItem(index)}
                              alt=""
                            />
                          </div>
                        )}
                      </div>
                    </Col>
                  </Row>
                </div>
              </div>
            </React.Fragment>
          );
        })}

        <div className="add-row-btn" onClick={addItem}>
          <img src={PlusIcon} alt="" />
          <p className="bm">{t("Add Boarding Plan")}</p>
        </div>
      </Modal.Body>
      <Modal.Footer>
        <div className="footer-2-btn">
          <TextButton onClick={props.onHide}> {t("Cancel")}</TextButton>
          <CustomeButton bg="fill" onClick={submitHandler}>
          {t("Save")} 
          </CustomeButton>
        </div>
      </Modal.Footer>
    </Modal>
  );
}
