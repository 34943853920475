import { createContext, useState } from "react";

interface SidebarContext {
  sidebar: string;
  setSidebar: any;
}

export const SidebarContext : any = createContext({
  sidebar: "",
  setSidebar:null
});

export const SidebarProvider = ({ children }: any) => {
  const [sidebar, setSidebar] = useState<string>("");
  let val: any = { sidebar, setSidebar };
  return (
    <SidebarContext.Provider value={val}>{children}</SidebarContext.Provider>
  );
};
