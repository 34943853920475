import type { DatePickerProps } from "antd";
import { t } from "i18next";
import moment from "moment";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import { INVOICING_INVOICE_LIST } from "../../../Constant/Route";
import { useLoading } from "../../../context/LoadingContext";
import { InvoiceTotals } from "../../../interface/invoice";
import {
  invoiceLineAdd,
  invoiceLineView,
} from "../../../Redux/NonRedux/invoice";
import {
  calculatePercent,
  caluculateTotal,
} from "../../../Utility/General";
import CustomeButton from "../../Common/CustomButton/CustomButton";
import Datepicker from "../../Common/Datepicker/Datepicker";
import InputGroups from "../../Common/InputGroups/InputGroups";
import TextareaGroups from "../../Common/TextareaGroups/TextareaGroups";
import TextButton from "../../Common/TextButton/TextButton";

export default function NewInvoicePage() {
  const [invoiceDate, setInvoiceDate] = useState(moment().format("YYYY-MM-DD"));
  const [dueDate, setDueDate] = useState(moment().format("YYYY-MM-DD"));
  const [invoiceData, setInvoiceData] = useState<any>();
  const [invoiceList, setInvoiceList] = useState<any>([]);
  const [totals, setTotals] = useState<InvoiceTotals>({});
  const [paymentInsruction, setPaymentInstruction] = useState("");
  const navigate = useNavigate();
  const location: any = useLocation();
  const { setLoading } = useLoading();

  const invoiceDateChange: DatePickerProps["onChange"] = (date, dateString) => {
    let dateIs: any = date?.format("YYYY-MM-DD");
    setInvoiceDate(dateIs);
  };

  const prefrence = useSelector(
    (state: any) => state?.masterReducer?.data?.component?.preferance
  );

  const dueDateChange: DatePickerProps["onChange"] = (date, dateString) => {
    let dateIs: any = date?.format("YYYY-MM-DD");
    setDueDate(dateIs);
  };

  const handleSubmit = () => {
    const params = {
      owner_id: invoiceData?.owner_id,
      invoice_date: invoiceDate,
      due_date: dueDate,
      payment_instruction: paymentInsruction,
      items: invoiceList?.map((item: any) => {
        return {
          expence_id: item?.id,
          tax: item?.tax,
          horse_id: item?.horse_id,
          description: item?.description,
          qty:item?.qty,
          unit_price:item?.unit_price
        };
      }),
    };
    setLoading(true);
    invoiceLineAdd(params)
      .then(() => {
        navigate(INVOICING_INVOICE_LIST);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const handleCancel = () => {
    navigate(INVOICING_INVOICE_LIST);
  };

  useEffect(() => {
    const records = location?.state?.records;
    if (records?.length) {
      setLoading(true);
      invoiceLineView({ id: records })
        .then((res: any) => {
          setInvoiceData(res?.component);
          setInvoiceList(res?.component?.listing);
          setTotals({
            tax: res?.component?.total?.tax,
            subTotal: res?.component?.total?.subtotal,
            total: res?.component?.total?.totalCost,
          });
        })
        .finally(() => {
          setLoading(false);
        });
    }
  }, [location?.state]);

  useEffect(() => {
    setPaymentInstruction(prefrence?.bank_payment_instructions);
  }, []);

  const onTaxChange = (index: number, value: number) => {
    let tempInvoiceOb: any = Object.assign([], invoiceList);
    let amount =
      tempInvoiceOb[index]["unit_price"] * tempInvoiceOb[index]["qty"];
    tempInvoiceOb[index]["tax"] = value;
    tempInvoiceOb[index]["total_price"] =
      amount + calculatePercent(amount, value);
    let total = caluculateTotal(
      tempInvoiceOb?.map((item: any) => {
        return { qty: item?.qty, unit_price: item?.unit_price, tax: item?.tax };
      })
    );
    setTotals(total);
    setInvoiceList(tempInvoiceOb);
  };

  return (
    <>
      <div className="new-invoice-page">
        <div className="this-title">
          <p className="tl">{t("New Invoice")}</p>
          <p className="tm">{prefrence?.farm_manager_name}</p>
          <span className="bl blur-color">
            {prefrence?.farm_name} - {prefrence?.farm_manager_phone}
          </span>
        </div>
        <div className="date-and-input-filter">
          <InputGroups
            label={`${t("Customer")}`}
            readOnly={true}
            value={invoiceData?.owner || ""}
          />
          <Datepicker
            label={`${t("Invoice Date")}*`}
            onChange={invoiceDateChange}
            value={moment(invoiceDate)}
          />
          <Datepicker
            label={`${t("Due Date")}*`}
            onChange={dueDateChange}
            value={moment(dueDate)}
          />
        </div>
        <div className="data-tbl-wrapper">
          <div className="data-tbl total-tbl">
            <table>
              <thead>
                <tr>
                  <th>
                    <p className="tm">{t("Horse")}</p>
                  </th>
                  <th>
                    <p className="tm"> {t("Item Description")}</p>
                  </th>
                  <th>
                    <p className="tm text-center">{t("Quantity")}</p>
                  </th>
                  <th>
                    <p className="tm text-right"> {t("Unit Price")}</p>
                  </th>
                  <th>
                    <p className="tm"> {t("Tax")}</p>
                  </th>
                  <th>
                    <p className="tm text-right"> {t("Total")}</p>
                  </th>
                </tr>
              </thead>
              <tbody>
                {invoiceList?.map((items: any, index: number) => (
                  <tr key={index + "vaccination-data"}>
                    <td>
                      <p className="bl">{items.horse_name}</p>
                    </td>
                    <td>
                      <p className="bl">{items.description || "-"}</p>
                    </td>
                    <td>
                      <p className="bl text-center">{items.qty}</p>
                    </td>
                    <td>
                      <p className="bl text-right">
                        {items.unit_price} {prefrence?.currency_value}
                      </p>
                    </td>
                    <td>
                      <select
                        className="tax-select"
                        onChange={(e: any) =>
                          onTaxChange(index, e.target.value)
                        }
                        value={invoiceList[index]["tax"]?.value || ""}
                      >
                        <option value="0">{t("No tax")}</option>
                        <option value="0">{t("No tax")}</option>
                        {/* <option value="3">3 %</option> */}
                      </select>
                    </td>
                    <td>
                      <p className="bl text-right">
                        {parseFloat(items.total_price).toFixed(2)} {prefrence?.currency_value}
                      </p>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
        <hr />
        <div className="invoice-footer">
          <TextareaGroups
            label={t("Payment Instructions")}
            value={paymentInsruction}
            onChange={(e: any) => setPaymentInstruction(e.target.value)}
          />
          <div className="table-and-btn">
            <table>
              <tbody>
                <tr>
                  <td>
                    <p className="bl">{t("Subtotal")}</p>
                  </td>
                  <td>
                    <p className="tm">
                      {totals?.subTotal} {prefrence?.currency_value}
                    </p>
                  </td>
                </tr>
                <tr>
                  <td>
                    <p className="bl">{t("Taxes")}</p>
                  </td>
                  <td>
                    <p className="tm">{totals?.tax}</p>
                  </td>
                </tr>
              </tbody>
              <tfoot>
                <tr>
                  <td>
                    <p className="tm">{t("Grand Total")}</p>
                  </td>
                  <td>
                    <p className="tm">
                      {totals?.total} {prefrence?.currency_value}
                    </p>
                  </td>
                </tr>
              </tfoot>
            </table>
            <div className="table-btns">
              <TextButton onClick={handleCancel}> {t("Cancel")}</TextButton>
              <CustomeButton bg="fill" onClick={handleSubmit}>
                {t("Save Invoice")}
              </CustomeButton>
              {/* <SquareButton>
                <ShareIcon />
              </SquareButton> */}
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
