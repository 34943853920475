import { t } from "i18next";
import React, { useContext, useEffect, useState } from "react";
import { Col, Row } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import BreedingIcon from "../../Assets/img/Breeding-primary.svg";
import ChartIcon from "../../Assets/img/chart-primary.svg";
import PregnantFillIcon from "../../Assets/img/Common-icon/PregnantFill";
import ExportIcon from "../../Assets/img/export.svg";
import FemaleIcon from "../../Assets/img/Female.svg";
import MaleIcon from "../../Assets/img/Male.svg";
import CalcuIcon from "../../Assets/img/MareGestationCalculator.svg";
import OpenIcon from "../../Assets/img/open.svg";
import PregnantBorderIcon from "../../Assets/img/pregnant-fill.svg";
import PregnantIcon from "../../Assets/img/Pregnant.svg";
import { END_POINTS } from "../../Constant/Api";
import { DATATABLE, PERMISSIONS, SIDEBAR } from "../../Constant/General";
import { HORSEDETAILS_BREEDING_SERVICE } from "../../Constant/Route";
import { useLoading } from "../../context/LoadingContext";
import { SidebarContext } from "../../context/SideBarContext";
import { upgradePopupAction } from "../../Redux/General/GeneralAction";
import { exportDocument } from "../../Redux/globallActions";
import { getBreedingListHorseWise } from "../../Redux/NonRedux/breeding";
import { getListMetaDataApi } from "../../Redux/NonRedux/general";
import {
  checkRoutePermission,
  encrDecrStr,
  getDefaultSorting
} from "../../Utility/General";
import TableHead from "../Common/DataTable/TableHead/TableHead";
import NoDataFound from "../Common/NoDataFound/NoDataFound";
import SearchBox from "../Common/SearchBox/SearchBox";
import SquareButton from "../Common/SquareButton/SquareButton";
import Filter from "../Filter/Filter";
import Numbernamecard from "../Layouts/Numbernamecard/Numbernamecard";
import Pagination from "../Layouts/Pagination/Pagination";
import GestationCalculatorPopup from "../Popups/GestationCalculatorPopup/GestationCalculatorPopup";
import MareGestationCalculatorPopup from "../Popups/MareGestationCalculatorPopup/MareGestationCalculatorPopup";
import PregnantMaresPopup from "../Popups/PregnantMaresPopup/PregnantMaresPopup";
import { Estimated } from "../../interface/Breeding.interface";

export default function Breeding() {
  const defaultSorting: any = getDefaultSorting(DATATABLE.BREEDING);
  const [activeTab, setActiveTab] = useState("");
  const tableHead = [
    {
      isOnlyTh: true,
      headClass: "first-img-wrapper",
    },
    {
      label: t("Horse"),
      className: "tm icon-text",
      key: "horse",
    },
    {
      label: t("Breeding Date"),
      className: "tm",
      key: "dates",
    },
    {
      label: t("Stallion"),
      className: "tm",
      key: "stallion",
    },
    {
      label: t("Status"),
      className: "tm",
      key: "status",
    },
    {
      label: t("Pregnancy Check"),
      className: "tm",
      key: "last_checked",
    },
    {
      label: t("Expected Foaling Date"),
      className: "tm",
      key: "expected_foaling_date",
    },
  ];

  const [refreshList, setRefreshList] = useState(false);
  const [MareGestationCalculatorShow, setMareGestationCalculatorShow] =
    useState(false);
  const [listData, setListData] = useState<any>([]);
  const [recordsTotal, setRecordsTotal] = useState(1);
  const [currentPage, setCurrentPage] = useState(1);
  const [currentPageSize, setCurrentPageSize] = useState(10);
  const { setSidebar }: any = useContext(SidebarContext);
  const [searchText, setSearchText] = React.useState();
  const [mainList, setMainList] = useState<any>([]);
  const { setLoading } = useLoading();
  const [checkedRec, setCheckedRec] = useState<number[]>([]);
  const [sortByField, setSortByField] = useState(defaultSorting?.key);
  const [sortType, setSortType] = useState(defaultSorting?.type);
  const [orderList, setOrderList] = useState<any>([]);
  const [exportParams, setExportParams] = useState<any>({});
  const [filterList, setFilterList] = useState<any>([]);
  const [filteredValue, setFilteredValue] = useState<any>({});
  const [metaData, setMetaData] = useState<any>({});
  const [estimated,setEstimated] = useState<Estimated[]>([]);
  const [gestationCalculatorPopupShow, setGestationCalCulatorPopupShow] =
    useState(false);
  const [PregnantMaresShow, setPregnantMaresShow] =
    useState(false);
  const modules = useSelector(
    (state: any) => state?.masterReducer?.data?.component?.modules
  );
  const dispatch = useDispatch();

  const listApiCall = async () => {
    try {
      setLoading(true);
      let params: any = {
        page: currentPage,
        limit: currentPageSize,
        search: searchText,
      };
      if (sortByField && sortType) {
        params["sort"] = {
          column: orderList[sortByField]
            ? orderList[sortByField]
            : defaultSorting?.value,
          type: sortType,
        };
      }
      if (filteredValue && Object.keys(filteredValue)?.length) {
        params["filter"] = filteredValue;
      }
      setExportParams(params);
      const response: any = await getBreedingListHorseWise(params);
      setListData(response?.component?.listing);
      setRecordsTotal(response?.component?.recordsFiltered);
      setMainList(response?.component?.mainList);
      setOrderList(response?.component?.orderlist);
      setFilterList(response?.component?.filterlist);
      setEstimated(response?.component?.estimated?.listing)
    } finally {
      setLoading(false);
    }
  };

  const getMetaData = () => {
    setLoading(true);
    getListMetaDataApi(END_POINTS.metaBreeeding)
      .then((response: any) => {
        setMetaData(response?.component);
      })
      .catch((error: any) => {})
      .finally(() => {
        setLoading(false);
      });
  };

  useEffect(() => {
    getMetaData();
    setSidebar(SIDEBAR.BREEDING);
  }, []);

  useEffect(() => {
    listApiCall();
  }, [currentPage, currentPageSize, sortByField, sortType, filteredValue]);

  useEffect(() => {
    if (searchText != null) {
      const searchTimeOut = setTimeout(() => {
        if (currentPage === 1) {
          listApiCall();
        } else {
          setCurrentPage(1);
        }
      }, 500);
      return () => clearTimeout(searchTimeOut);
    }
  }, [searchText]);

  const getIconbyType = (type: any) => {
    if (type == "male") {
      return MaleIcon;
    } else if (type == "female") {
      return FemaleIcon;
    } else if (type == "pregnant") {
      return PregnantIcon;
    }
  };

  const handleExport = () => {
    if (!checkRoutePermission(PERMISSIONS?.EXPORT_FEATURE, modules)) {
      upgradePopupAction({ show: true });
      return;
    }
    setLoading(true);
    exportDocument(END_POINTS.breedingExport, exportParams, "breeding")
      .then(() => {})
      .finally(() => {
        setLoading(false);
      });
  };

  // const handleSelectAll = (e: any) => {
  //   if (e.target.checked) {
  //     var checkedRecData: any[] = Object.assign([], checkedRec);
  //     listData?.map((li: any) => {
  //       if (!checkedRecData.includes(li.id)) {
  //         checkedRecData.push(li.id);
  //       }
  //     });
  //     setCheckedRec(checkedRecData);
  //   } else {
  //     setCheckedRec([]);
  //   }
  // };

  // const checkSingleRec = (e: any, val: number) => {
  //   //
  //   var checkedRecData: number[] = Object.assign([], checkedRec);
  //   if (e.target.checked) {
  //     checkedRecData.push(val);
  //   } else {
  //     checkedRecData = checkedRecData.filter((c: number) => c !== val);
  //   }
  //   setCheckedRec(checkedRecData);
  // };

  return (
    <>
      <div className="breeding-page">
        {/* Page Title Start */}
        <div className="page-title-wrapper">
          <div className="title-first-menu">
            <div className="title-icon-text">
              <img src={BreedingIcon} alt="" />
              <h5>{t("Breeding")}</h5>
            </div>
            <div className="title-right-icons">
              <a
                className="unset-links"
                onClick={() => setMareGestationCalculatorShow(true)}
              >
                <img src={CalcuIcon} alt="" />
              </a>
              <a
                className="unset-links"
                onClick={() => setGestationCalCulatorPopupShow(true)}
              >
                <img src={ChartIcon} alt="" />
              </a>
              <a className="unset-links" onClick={handleExport}>
                <img src={ExportIcon} alt="" />
              </a>
            </div>
          </div>
        </div>
        {/* Page Title End */}

        <div className="page-content">
          <div className="page-tab">
            <Row>
              <Col sm="6" md="4">
                <Numbernamecard
                  number={mainList?.totalPregnantMare}
                  name={t("Pregnant Mares")}
                />
              </Col>
              <Col sm="6" md="4">
                <Numbernamecard
                  number={mainList?.totalOpenMare}
                  name={t("Open Mares")}
                />
              </Col>
              <Col sm="6" md="4">
                <Numbernamecard
                  number={mainList?.totalFoalMare}
                  name={t("Foals this year")}
                />
              </Col>
            </Row>
          </div>
          <div className="data-wrapper">
            <div className="data-header">
              <SearchBox
                placeholder={t("Search Horse...")}
                onChange={(e: any) => setSearchText(e.target.value)}
                value={searchText}
              />
              <div className="btn-wrapper s-f">
                <SquareButton onClick={() => setPregnantMaresShow(true)}>
                  <PregnantFillIcon />
                </SquareButton>
                <Filter
                  filterList={filterList}
                  setFilteredValue={setFilteredValue}
                  setCurrentPage={setCurrentPage}
                />
              </div>
            </div>
            <div className="data-body">
              <div className="img-responsive-table">
                <table>
                  <TableHead
                    data={tableHead}
                    orderList={orderList}
                    sortByField={sortByField}
                    sortType={sortType}
                    setSortType={setSortType}
                    setSortByField={setSortByField}
                  />
                  <tbody>
                    {listData?.map((items: any, index: number) => (
                      <tr key={index + "myhorse-tbl"}>
                        {/* <td className="ck-wrapper"> */}
                        {/* <OnlyCk
                              checked={checkedRec.includes(items?.id)}
                              disabled={
                                checkedRec.includes(items.id)
                                  ? false
                                  : true
                              }
                              onChange={(e: any) =>
                                checkSingleRec(e, items?.id)
                              }
                            /> */}
                        {/* </td> */}
                        <td className="first-img-wrapper">
                          <img
                            src={items?.profile}
                            alt=""
                            className="horse-img"
                          />
                        </td>
                        <td>
                          <div className="icon-text">
                            <img src={getIconbyType(items?.type_slug)} alt="" />
                            <Link
                              to={
                                HORSEDETAILS_BREEDING_SERVICE +
                                "/" +
                                encrDecrStr(items?.horse_id, "encr")
                              }
                              className="a-bl"
                            >
                              {items?.horse_name}
                            </Link>
                          </div>
                        </td>
                        <td>
                          <p className="bl">{items?.dates}</p>
                        </td>
                        <td>
                          <p className="bl">{items?.stallion}</p>
                        </td>
                        <td>
                          <p className="bl icon-text">
                            <img
                              src={
                                items?.pregnancy_status_slug === "pregnant"
                                  ? PregnantBorderIcon
                                  : OpenIcon
                              }
                              alt=""
                            />{" "}
                            {items?.pregnant_status_value}
                          </p>
                        </td>
                        <td>
                          <p className="bl">{items?.next_check_controll}</p>
                        </td>
                        <td>
                          <p className="bl">{items?.expected_foaling_date}</p>
                        </td>
                        {/* <td>
                          <p className="bl">{items.foal}</p>
                        </td> */}
                        {/* <td>
                          <div className="icon-wrapper">
                            <a>
                              <img src={flushIcon} alt="" />
                            </a>
                            <a>
                              <img src={pencilIcon} alt="" />
                            </a>
                            <a>
                              <img src={binIcon} alt="" />
                            </a>
                          </div>
                        </td> */}
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
              {listData.length > 0 ? (
                <Pagination
                  results={recordsTotal}
                  currentPage={currentPage}
                  setCurrentPage={setCurrentPage}
                  currentLength={currentPageSize}
                  setCurrentPageSize={setCurrentPageSize}
                />
              ) : (
                <NoDataFound />
              )}
            </div>
          </div>
        </div>
      </div>
      <MareGestationCalculatorPopup
        show={MareGestationCalculatorShow}
        onHide={() => setMareGestationCalculatorShow(false)}
      />
      <GestationCalculatorPopup
        show={gestationCalculatorPopupShow}
        onHide={() => setGestationCalCulatorPopupShow(false)}
        horseList={metaData?.horse_list}
      />
      <PregnantMaresPopup
        show={PregnantMaresShow}
        onHide={() => setPregnantMaresShow(false)}
        estimated={estimated}
      />
    </>
  );
}
