import { t } from "i18next";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import AddPlusIcon from "../../../../Assets/icon/AddPlusIcon";
import Filtericon from "../../../../Assets/icon/Filtericon";
import binIcon from "../../../../Assets/img/bin.svg";
import pencilIcon from "../../../../Assets/img/pencil.svg";
import { END_POINTS } from "../../../../Constant/Api";
import { DATATABLE, SHOW_MESSAGE, UPGRADE_MESSAGE } from "../../../../Constant/General";
import { SUCCESS_RESPONSE_CODE } from "../../../../Constant/Status";
import { useLoading } from "../../../../context/LoadingContext";
import { upgradePopupAction } from "../../../../Redux/General/GeneralAction";
import { destroyFarms } from "../../../../Redux/NonRedux/farm";
import { getListMetaDataApi } from "../../../../Redux/NonRedux/general";
import {
  destroyStaff,
  getAllStaff,
  viewSingleStaff,
} from "../../../../Redux/NonRedux/users";
import { getDefaultSorting, getSessionItemDecrypt } from "../../../../Utility/General";
import CustomeButton from "../../../Common/CustomButton/CustomButton";
import TableHead from "../../../Common/DataTable/TableHead/TableHead";
import NoDataFound from "../../../Common/NoDataFound/NoDataFound";
import OnlyCk from "../../../Common/OnlyCk/OnlyCk";
import SearchBox from "../../../Common/SearchBox/SearchBox";
import Filter from "../../../Filter/Filter";
import Pagination from "../../../Layouts/Pagination/Pagination";
import Loader from "../../../Loader/Loader";
import AddStaffPopup from "../../../Popups/AddStaffPopup/AddStaffPopup";
import ConfirmPopup from "../../../Popups/ConfirmPopup/ConfirmPopup";
import { USER } from "../../../../Constant/LocalStorage";


export default function StaffPage() {
  const defaultSorting : any = getDefaultSorting(DATATABLE.STAFF);
  const userInfo = getSessionItemDecrypt(USER);
  const tableHead = [
    {
      label: t("First Name"),
      className: "tm",
      key: "first_name",
    },
    {
      label: t("Last Name"),
      className: "tm",
      key: "last_name",
    },
    {
      label: t("Designation"),
      className: "tm",
      key: "designation",
    },
    {
      label: t("Email"),
      className: "tm",
      key: "email",
    },
    {
      label: t("Passport Number"),
      className: "tm",
      key: "passport_number",
    },
    {
      label: t("Passport Exp. Date"),
      className: "tm",
      key: "passport_exp_date",
    },
    {
      label: t("Civil ID"),
      className: "tm",
      key: "civil_iD",
    },
    {
      label: t("Civil ID Exp. Date"),
      className: "tm",
      key: "Civil_ID_Exp_Date",
    },
    {
      label: t("Salary"),
      className: "tm",
      key: "salary",
    },
    {
      label: t("Action"),
      className: "tm",
      key: "",
      headClass: "text-right",
    },
  ];

  const [currentPage, setCurrentPage] = useState(1);
  const [currentPageSize, setCurrentPageSize] = useState(10);
  const [searchText, setSearchText] = useState();
  const [AddStaffModalShow, setAddStaffModalShow] = useState(false);
  const { setLoading } = useLoading();
  const [data, setData] = useState([]);
  const [recordsTotal, setRecordsTotal] = useState(1);
  const [isEdit, setIsEdit] = useState(false);
  const [editData, setEditData] = useState({});
  const [refreshList, setRefreshList] = useState(false);
  const [deletableRecords, setDeletableRecords] = useState<any>([]);
  const [confirmPopupShow, setConfirmPopupShow] = useState(false);
  const [checkedRec, setCheckedRec] = useState<number[]>([]);
  const [sortByField, setSortByField] = useState(defaultSorting?.key);
  const [sortType, setSortType] = useState(defaultSorting?.type);
  const [orderList, setOrderList] = useState<any>([]);
  const [filterList, setFilterList] = useState<any>([]);
  const [filteredValue, setFilteredValue] = useState<any>({});
  const [canAdd, setCanAdd] = useState(true);
  const [metaData, setMetaData] = useState({});
  
  const dispatch = useDispatch();
  const listApiCall = () => {
    let params: any = {
      page: currentPage,
      limit: currentPageSize,
      search: searchText,
    };
    if (sortByField && sortType) {
      params["sort"] = {
        column: orderList[sortByField] ? orderList[sortByField] : defaultSorting?.value ,
        type: sortType,
      };
    }
    if (filteredValue && Object.keys(filteredValue)?.length) {
      params["filter"] = filteredValue;
    }
    setLoading(true);
    getAllStaff(params)
      .then((response: any) => {
        if (response.statusCode === SUCCESS_RESPONSE_CODE) {
          setRecordsTotal(response?.component?.recordsFiltered);
          setData(response?.component?.listing);
          setOrderList(response?.component?.orderlist);
          setFilterList(response?.component?.filterlist);
          setCanAdd(response?.component?.can_add_staff);
        }
      })
      .catch((error) => {})
      .finally(() => {
        setLoading(false);
      });
  };

  useEffect(() => {
    if (searchText != null) {
      const searchTimeOut = setTimeout(() => {
        if (currentPage === 1) {
          listApiCall();
        } else {
          setCurrentPage(1);
        }
      }, 500);
      return () => clearTimeout(searchTimeOut);
    }
  }, [searchText]);

  useEffect(() => {
    listApiCall();
  }, [currentPage, currentPageSize, sortByField, sortType, filteredValue]);

  useEffect(() => {
    if (refreshList) {
      listApiCall();
      setRefreshList(false);
    }
  }, [refreshList]);

  const getMetaData = () => {
    setLoading(true);  
    getListMetaDataApi(END_POINTS.metaUserStaff)
      .then((response: any) => {
        setMetaData(response?.component);
      })
      .catch((error: any) => {})
      .finally(() => {
        setLoading(false);
      });
  };

  useEffect(() => {
    getMetaData();
  },[]);

  const editHandler = (id: number) => {
    let params = {
      id: id,
    };
    setLoading(true);
    viewSingleStaff(params)
      .then((response: any) => {
        if (response?.statusCode === SUCCESS_RESPONSE_CODE) {
          setIsEdit(true);
          setEditData(response?.component);
          setAddStaffModalShow(true);
        }
      })
      .catch((error) => {})
      .finally(() => {
        setLoading(false);
      });
  };

  const deleteHandler = (id: number) => {
    let params = {
      id: deletableRecords,
    };

    setLoading(true);
    destroyStaff(params)
      .then((response: any) => {
        if (response.statusCode === SUCCESS_RESPONSE_CODE) {
          setRefreshList(true);
          setConfirmPopupShow(false);
        } else {
        }
      })
      .catch((error: any) => {})
      .finally(() => {
        setLoading(false);
      });
  };

  const deleteNow = (id: number) => {
    let ids = deletableRecords;
    ids.push(id);
    setDeletableRecords(ids);
    setConfirmPopupShow(true);
  };

  const checkSingleRec = (e: any, val: number) => {
    //
    var checkedRecData: number[] = Object.assign([], checkedRec);
    if (e.target.checked) {
      checkedRecData.push(val);
    } else {
      checkedRecData = checkedRecData.filter((c: number) => c !== val);
    }
    setCheckedRec(checkedRecData);
  };

  const handleSelectAll = (e: any) => {
    if (e.target.checked) {
      var checkedRecData: any[] = Object.assign([], checkedRec);
      data?.map((li: any) => {
        if (!checkedRecData.includes(li.id)) {
          checkedRecData.push(li.id);
        }
      });
      setCheckedRec(checkedRecData);
    } else {
      setCheckedRec([]);
    }
  };

  const prefrence = useSelector(
    (state: any) => state?.masterReducer?.data?.component?.preferance
  );

  const handleAdd = () => {
    setIsEdit(false);
    setEditData({});
    setAddStaffModalShow(true);
  };

  return (
    <>
      <div className="staff-page">
        <div className="page-content">
          <div className="data-tbl-wrapper">
            <div className="data-tbl-header">
              <SearchBox
                value={searchText}
                placeholder={t("Search...")}
                onChange={(e: any) => setSearchText(e.target.value)}
              />
              <div className="btn-wrapper f-fb">
                {/* <CustomeButton>
                  <Filtericon />
                  <p>{t("Filter")}</p>
                </CustomeButton> */}

                <Filter
                  filterList={filterList}
                  setFilteredValue={setFilteredValue}
                  setCurrentPage={setCurrentPage}
                />
                <CustomeButton bg="fill" onClick={handleAdd}>
                  <AddPlusIcon />
                  {t("Add")}
                </CustomeButton>
              </div>
            </div>
            <div className="data-tbl">
              <table>
                <TableHead
                  data={tableHead}
                  orderList={orderList}
                  sortByField={sortByField}
                  sortType={sortType}
                  setSortType={setSortType}
                  setSortByField={setSortByField}
                />
                <tbody>
                  {data?.map((items: any, index: number) => (
                    <tr key={index + "vaccination-data"}>
                      {/* <td className="ck-wrapper">
                      <OnlyCk
                            checked={checkedRec.includes(items?.id)}
                            onChange={(e: any) => checkSingleRec(e, items?.id)}
                          />
                      </td> */}
                      <td>
                        <p className="bl">{items.first_name}</p>
                      </td>
                      <td>
                        <p className="bl">{items.last_name}</p>
                      </td>
                      <td>
                        <p className="bl">{items.designation}</p>
                      </td>
                      <td>
                        <p className="bl">{items.email}</p>
                      </td>
                      <td>
                        <p className="bl">{items.passport_number}</p>
                      </td>
                      <td>
                        <p className="bl">{items.passport_exp_date}</p>
                      </td>
                      <td>
                        <p className="bl">{items.civil_id}</p>
                      </td>
                      <td>
                        <p className="bl">{items.civil_id_exp_date}</p>
                      </td>
                      <td>
                        <p className="bl">
                          {items.salary
                            ? `${items.salary} ${prefrence?.currency_value}`
                            : "-"}
                        </p>
                      </td>
                      {
                        items?.id !== userInfo?.component?.id ?   <td>
                        <div className="icon-wrapper">
                          <a>
                            <img
                              src={pencilIcon}
                              onClick={() => editHandler(items?.id)}
                              alt=""
                            />
                          </a>
                          <a>
                            <img
                              src={binIcon}
                              onClick={() => deleteNow(items?.id)}
                              alt=""
                            />
                          </a>
                        </div>
                      </td> : <td></td>
                      }
                    
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
            {data?.length ? (
              <Pagination
                results={recordsTotal}
                currentPage={currentPage}
                setCurrentPage={setCurrentPage}
                currentLength={currentPageSize}
                setCurrentPageSize={setCurrentPageSize}
              />
            ) : (
              <NoDataFound />
            )}
          </div>
        </div>
      </div>

      <AddStaffPopup
        show={AddStaffModalShow}
        onHide={() => {
          setAddStaffModalShow(false);
        }}
        isedit={isEdit ? 1 : 0}
        editdata={editData}
        setrefreshlist={() => {
          setRefreshList(true);
        }}
        canAdd={canAdd}
        metaData={metaData}
      />

      <ConfirmPopup
        heading={t("Delete")}
        message={t("Are you sure to delete this record?")}
        show={confirmPopupShow}
        onHide={() => {
          setConfirmPopupShow(false);
          setDeletableRecords([]);
        }}
        confirm={deleteHandler}
      />
    </>
  );
}
