import {
  DEFAULT_PARAMETER,
  MASTER_FAILD,
  MASTER_LONG,
  MASTER_REQUEST,
  MASTER_SUCCESS,
  CLINIC_CONTACT_REQUEST,
  CLINIC_CONTACT_SUCCESS,
  CLINIC_CONTACT_FAILD,
  CLINIC_CONTACT_LONG,
  USER_LANGUAGE_REQUEST,
  USER_LANGUAGE_SUCCESS,
  USER_LANGUAGE_LONG,
  USER_LANGUAGE_FAILD,
  META_VACCINES_REQUEST,
  META_VACCINES_SUCCESS,
  META_VACCINES_FAILD,
  META_VACCINES_LONG,
  META_DEWORMER_REQUEST,
  META_DEWORMER_SUCCESS,
  META_DEWORMER_FAILD,
  META_DEWORMER_LONG,
  META_COLOR_FAILD,
  META_COLOR_LONG,
  META_COLOR_REQUEST,
  META_COLOR_SUCCESS,
  META_GENDER_FAILD,
  META_GENDER_LONG,
  META_GENDER_REQUEST,
  META_GENDER_SUCCESS,
  META_HORSE_STATUS_FAILD,
  META_HORSE_STATUS_LONG,
  META_HORSE_STATUS_REQUEST,
  META_HORSE_STATUS_SUCCESS,
  META_DIET_CATEGORY_REQUEST,
  META_DIET_CATEGORY_SUCCESS,
  META_DIET_CATEGORY_FAILD,
  META_DIET_CATEGORY_LONG,
  META_CURRENCY_REQUEST,
  META_CURRENCY_SUCCESS,
  META_CURRENCY_FAILD,
  META_CURRENCY_LONG,
  META_UNIT_REQUEST,
  META_UNIT_SUCCESS,
  META_UNIT_FAILD,
  META_UNIT_LONG,
  FARRIER_CONTACT_FAILD,
  FARRIER_CONTACT_LONG,
  FARRIER_CONTACT_REQUEST,
  FARRIER_CONTACT_SUCCESS,
  META_SHOEING_TYPES_FAILD,
  META_SHOEING_TYPES_LONG,
  META_SHOEING_TYPES_REQUEST,
  META_SHOEING_TYPES_SUCCESS,
  META_SHOE_MATERIAL_FAILD,
  META_SHOE_MATERIAL_LONG,
  META_SHOE_MATERIAL_REQUEST,
  META_SHOE_MATERIAL_SUCCESS,
  GET_COUNTRY_LIST_FAILD,
  GET_COUNTRY_LIST_LONG,
  GET_COUNTRY_LIST_REQUEST,
  GET_COUNTRY_LIST_SUCCESS,
  META_DISCIPLINE_FAILD,
  META_DISCIPLINE_LONG,
  META_DISCIPLINE_REQUEST,
  META_DISCIPLINE_SUCCESS,
  HORSE_BREEDER_CONTACT_FAILD,
  HORSE_BREEDER_CONTACT_LONG,
  HORSE_BREEDER_CONTACT_REQUEST,
  HORSE_BREEDER_CONTACT_SUCCESS,
  HORSE_OWNER_CONTACT_FAILD,
  HORSE_OWNER_CONTACT_LONG,
  HORSE_OWNER_CONTACT_REQUEST,
  HORSE_OWNER_CONTACT_SUCCESS,
  META_BREED_FAILD,
  META_BREED_LONG,
  META_BREED_REQUEST,
  META_BREED_SUCCESS,
  DAM_LIST_FAILD,
  DAM_LIST_LONG,
  DAM_LIST_REQUEST,
  DAM_LIST_SUCCESS,
  SIRE_LIST_FAILD,
  SIRE_LIST_LONG,
  SIRE_LIST_REQUEST,
  SIRE_LIST_SUCCESS,
  LOCATION_LIST_FAILD,
  LOCATION_LIST_LONG,
  LOCATION_LIST_REQUEST,
  LOCATION_LIST_SUCCESS,
  META_HORSE_LIST_FAILD,
  META_HORSE_LIST_LONG,
  META_HORSE_LIST_REQUEST,
  META_HORSE_LIST_SUCCESS,
  META_TRAINING_TYPES_REQUEST,
  META_TRAINING_TYPES_FAILD,
  META_TRAINING_TYPES_LONG,
  META_TRAINING_TYPES_SUCCESS,
  META_INCOME_CATEGORY_FAILD,
  META_INCOME_CATEGORY_LONG,
  META_INCOME_CATEGORY_REQUEST,
  META_INCOME_CATEGORY_SUCCESS,
  META_BREEDING_SERVICE_TYPE_FAILD,
  META_BREEDING_SERVICE_TYPE_LONG,
  META_BREEDING_SERVICE_TYPE_REQUEST,
  META_BREEDING_SERVICE_TYPE_SUCCESS,
  REMINDER_STATUS_CHANGE_FAILD,
  REMINDER_STATUS_CHANGE_REQUEST,
  REMINDER_STATUS_CHANGE_SUCCESS,
  REMINDER_STATUS_CHANGE_CLEAR,
  REMINDER_ADDED,
  SET_USER_DETAILS,
  CHANGE_UPGRADE_SUBSCRIPTION_POPUP_STATUS,
} from "./GeneralType";
const defaultParameters = {
  customerId: "",
  customerToken: "",
  websiteId: "",
  groupId: "",
  storeId: "",
};
export function masterRedux(state = {}, action: any) {
  switch (action.type) {
    case MASTER_REQUEST:
      return { ...action.payload };
    case MASTER_SUCCESS:
      return { ...action.payload };
    case MASTER_FAILD:
      return { ...action.payload };
    case MASTER_LONG:
      return { ...action.payload };
    default:
      return state;
  }
}

export function defaultParameter(state = defaultParameters, action: any) {
  switch (action.type) {
    case DEFAULT_PARAMETER:
      return { ...action.payload.data };
    default:
      return state;
  }
}

export function userLangugaeRedux(state = {}, action: any) {
  switch (action.type) {
    case USER_LANGUAGE_REQUEST:
      return { ...action.payload };
    case USER_LANGUAGE_SUCCESS:
      return { ...action.payload };
    case USER_LANGUAGE_LONG:
      return { ...action.payload };
    case USER_LANGUAGE_FAILD:
      return { ...action.payload };
    default:
      return state;
  }
}
export function clinicContactRedux(state = {}, action: any) {
  switch (action.type) {
    case CLINIC_CONTACT_REQUEST:
      return { ...action.payload };
    case CLINIC_CONTACT_SUCCESS:
      return { ...action.payload };
    case CLINIC_CONTACT_FAILD:
      return { ...action.payload };
    case CLINIC_CONTACT_LONG:
      return { ...action.payload };
    default:
      return state;
  }
}

export function horseOwnerContactRedux(state = {}, action: any) {
  switch (action.type) {
    case HORSE_OWNER_CONTACT_REQUEST:
      return { ...action.payload };
    case HORSE_OWNER_CONTACT_SUCCESS:
      return { ...action.payload };
    case HORSE_OWNER_CONTACT_FAILD:
      return { ...action.payload };
    case HORSE_OWNER_CONTACT_LONG:
      return { ...action.payload };
    default:
      return state;
  }
}

export function horseBreederContactRedux(state = {}, action: any) {
  switch (action.type) {
    case HORSE_BREEDER_CONTACT_REQUEST:
      return { ...action.payload };
    case HORSE_BREEDER_CONTACT_SUCCESS:
      return { ...action.payload };
    case HORSE_BREEDER_CONTACT_FAILD:
      return { ...action.payload };
    case HORSE_BREEDER_CONTACT_LONG:
      return { ...action.payload };
    default:
      return state;
  }
}

export function breedRedux(state = {}, action: any) {
  switch (action.type) {
    case META_BREED_REQUEST:
      return { ...action.payload };
    case META_BREED_SUCCESS:
      return { ...action.payload };
    case META_BREED_FAILD:
      return { ...action.payload };
    case META_BREED_LONG:
      return { ...action.payload };
    default:
      return state;
  }
}

export function sireListRedux(state = {}, action: any) {
  switch (action.type) {
    case SIRE_LIST_REQUEST:
      return { ...action.payload };
    case SIRE_LIST_SUCCESS:
      return { ...action.payload };
    case SIRE_LIST_FAILD:
      return { ...action.payload };
    case SIRE_LIST_LONG:
      return { ...action.payload };
    default:
      return state;
  }
}

export function damListRedux(state = {}, action: any) {
  switch (action.type) {
    case DAM_LIST_REQUEST:
      return { ...action.payload };
    case DAM_LIST_SUCCESS:
      return { ...action.payload };
    case DAM_LIST_FAILD:
      return { ...action.payload };
    case DAM_LIST_LONG:
      return { ...action.payload };
    default:
      return state;
  }
}

export function locationListRedux(state = {}, action: any) {
  switch (action.type) {
    case LOCATION_LIST_REQUEST:
      return { ...action.payload };
    case LOCATION_LIST_SUCCESS:
      return { ...action.payload };
    case LOCATION_LIST_FAILD:
      return { ...action.payload };
    case LOCATION_LIST_LONG:
      return { ...action.payload };
    default:
      return state;
  }
}

export function vaccinesRedux(state = {}, action: any) {
  switch (action.type) {
    case META_VACCINES_REQUEST:
      return { ...action.payload };
    case META_VACCINES_SUCCESS:
      return { ...action.payload };
    case META_VACCINES_FAILD:
      return { ...action.payload };
    case META_VACCINES_LONG:
      return { ...action.payload };
    default:
      return state;
  }
}

export function dewormerRedux(state = {}, action: any) {
  switch (action.type) {
    case META_DEWORMER_REQUEST:
      return { ...action.payload };
    case META_DEWORMER_SUCCESS:
      return { ...action.payload };
    case META_DEWORMER_FAILD:
      return { ...action.payload };
    case META_DEWORMER_LONG:
      return { ...action.payload };
    default:
      return state;
  }
}

export function colorRedux(state = {}, action: any) {
  switch (action.type) {
    case META_COLOR_REQUEST:
      return { ...action.payload };
    case META_COLOR_SUCCESS:
      return { ...action.payload };
    case META_COLOR_FAILD:
      return { ...action.payload };
    case META_COLOR_LONG:
      return { ...action.payload };
    default:
      return state;
  }
}

export function genderRedux(state = {}, action: any) {
  switch (action.type) {
    case META_GENDER_REQUEST:
      return { ...action.payload };
    case META_GENDER_SUCCESS:
      return { ...action.payload };
    case META_GENDER_FAILD:
      return { ...action.payload };
    case META_GENDER_LONG:
      return { ...action.payload };
    default:
      return state;
  }
}

export function horseStatusRedux(state = {}, action: any) {
  switch (action.type) {
    case META_HORSE_STATUS_REQUEST:
      return { ...action.payload };
    case META_HORSE_STATUS_SUCCESS:
      return { ...action.payload };
    case META_HORSE_STATUS_FAILD:
      return { ...action.payload };
    case META_HORSE_STATUS_LONG:
      return { ...action.payload };
    default:
      return state;
  }
}

export function metaHorseListRedux(state = {}, action: any) {
  switch (action.type) {
    case META_HORSE_LIST_REQUEST:
      return { ...action.payload };
    case META_HORSE_LIST_SUCCESS:
      return { ...action.payload };
    case META_HORSE_LIST_FAILD:
      return { ...action.payload };
    case META_HORSE_LIST_LONG:
      return { ...action.payload };
    default:
      return state;
  }
}

export function unitRedux(state = {}, action: any) {
  switch (action.type) {
    case META_UNIT_REQUEST:
      return { ...action.payload };
    case META_UNIT_SUCCESS:
      return { ...action.payload };
    case META_UNIT_FAILD:
      return { ...action.payload };
    case META_UNIT_LONG:
      return { ...action.payload };
    default:
      return state;
  }
}

export function dietCategoryRedux(state = {}, action: any) {
  switch (action.type) {
    case META_DIET_CATEGORY_REQUEST:
      return { ...action.payload };
    case META_DIET_CATEGORY_SUCCESS:
      return { ...action.payload };
    case META_DIET_CATEGORY_FAILD:
      return { ...action.payload };
    case META_DIET_CATEGORY_LONG:
      return { ...action.payload };
    default:
      return state;
  }
}

export function currencyRedux(state = {}, action: any) {
  switch (action.type) {
    case META_CURRENCY_REQUEST:
      return { ...action.payload };
    case META_CURRENCY_SUCCESS:
      return { ...action.payload };
    case META_CURRENCY_FAILD:
      return { ...action.payload };
    case META_CURRENCY_LONG:
      return { ...action.payload };
    default:
      return state;
  }
}

export function farrierContactRedux(state = {}, action: any) {
  switch (action.type) {
    case FARRIER_CONTACT_REQUEST:
      return { ...action.payload };
    case FARRIER_CONTACT_SUCCESS:
      return { ...action.payload };
    case FARRIER_CONTACT_FAILD:
      return { ...action.payload };
    case FARRIER_CONTACT_LONG:
      return { ...action.payload };
    default:
      return state;
  }
}

export function shoeingTypesRedux(state = {}, action: any) {
  switch (action.type) {
    case META_SHOEING_TYPES_REQUEST:
      return { ...action.payload };
    case META_SHOEING_TYPES_SUCCESS:
      return { ...action.payload };
    case META_SHOEING_TYPES_FAILD:
      return { ...action.payload };
    case META_SHOEING_TYPES_LONG:
      return { ...action.payload };
    default:
      return state;
  }
}

export function shoeMaterialRedux(state = {}, action: any) {
  switch (action.type) {
    case META_SHOE_MATERIAL_REQUEST:
      return { ...action.payload };
    case META_SHOE_MATERIAL_SUCCESS:
      return { ...action.payload };
    case META_SHOE_MATERIAL_FAILD:
      return { ...action.payload };
    case META_SHOE_MATERIAL_LONG:
      return { ...action.payload };
    default:
      return state;
  }
}

export function disciplineRedux(state = {}, action: any) {
  switch (action.type) {
    case META_DISCIPLINE_REQUEST:
      return { ...action.payload };
    case META_DISCIPLINE_SUCCESS:
      return { ...action.payload };
    case META_DISCIPLINE_FAILD:
      return { ...action.payload };
    case META_DISCIPLINE_LONG:
      return { ...action.payload };
    default:
      return state;
  }
}

export function countryListRedux(state = {}, action: any) {
  switch (action.type) {
    case GET_COUNTRY_LIST_REQUEST:
      return { ...action.payload };
    case GET_COUNTRY_LIST_SUCCESS:
      return { ...action.payload };
    case GET_COUNTRY_LIST_FAILD:
      return { ...action.payload };
    case GET_COUNTRY_LIST_LONG:
      return { ...action.payload };
    default:
      return state;
  }
}

export function incomeCategoryRedux(state = {}, action: any) {
  switch (action.type) {
    case META_INCOME_CATEGORY_REQUEST:
      return { ...action.payload };
    case META_INCOME_CATEGORY_SUCCESS:
      return { ...action.payload };
    case META_INCOME_CATEGORY_FAILD:
      return { ...action.payload };
    case META_INCOME_CATEGORY_LONG:
      return { ...action.payload };
    default:
      return state;
  }
}

export function trainingTypesRedux(state = {}, action: any) {
  switch (action.type) {
    case META_TRAINING_TYPES_REQUEST:
      return { ...action.payload };
    case META_TRAINING_TYPES_SUCCESS:
      return { ...action.payload };
    case META_TRAINING_TYPES_FAILD:
      return { ...action.payload };
    case META_TRAINING_TYPES_LONG:
      return { ...action.payload };
    default:
      return state;
  }
}

export function reminderStatusRedux(state = {}, action: any) {
  switch (action.type) {
    case REMINDER_STATUS_CHANGE_REQUEST:
      return { ...action.payload };
    case REMINDER_STATUS_CHANGE_SUCCESS:
      return { ...action.payload };
    case REMINDER_STATUS_CHANGE_FAILD:
      return { ...action.payload };
    case REMINDER_ADDED:
      return { ...action.payload };
    case REMINDER_STATUS_CHANGE_CLEAR:
      return {};
    default:
      return state;
  }
}

export function userDetailsRedux(state = {}, action: any) {
  switch (action.type) {
    case SET_USER_DETAILS:
      return { ...action.payload };
    default:
      return state;
  }
}

export function breedingServiceTypeRedux(state = {}, action: any) {
  switch (action.type) {
    case META_BREEDING_SERVICE_TYPE_REQUEST:
      return { ...action.payload };
    case META_BREEDING_SERVICE_TYPE_SUCCESS:
      return { ...action.payload };
    case META_BREEDING_SERVICE_TYPE_FAILD:
      return { ...action.payload };
    case META_BREEDING_SERVICE_TYPE_LONG:
      return { ...action.payload };
    default:
      return state;
  }
}


export function upgradePopupRedux(state = {show:false}, action: any) {
  switch (action.type) {
    case CHANGE_UPGRADE_SUBSCRIPTION_POPUP_STATUS:
      return { ...action.payload };
    default:
      return state;
}
}