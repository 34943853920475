import React from "react";

export default function Dashboardicon() {
  return (
    <svg
      width="32"
      height="32"
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M5.64429 16.0287L12.8443 8.8287L16.8443 12.8287L24.4443 5.2287"
        stroke="#E8E5E2"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M24.5552 9.83981V5.23981H19.9552"
        stroke="#E8E5E2"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M9.90001 27H5.10001V21.6H9.80001V27H9.90001Z"
        stroke="#E8E5E2"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M18.4 27H13.7V18.1H18.4V27Z"
        stroke="#E8E5E2"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M26.9 27H22.2V14.5H26.9V27Z"
        stroke="#E8E5E2"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}
