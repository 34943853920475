export const weightChartOptions = {
  responsive: true,
  plugins: {
    tooltip: {
      enabled: true,
    },
    legend: {
      display: false,
    },
    title: {
      display: false,
    },
  },
  scales: {
    yAxis: {
      min: 0,
      title: {
        display: true,
        text: "Kg",
        font: {
          size: 14,
          family: '"Figtree", sans-serif',
        },
        color: "#212B36",
      },
      ticks: {
        color: "#212B36",
        font: {
          size: 14,
          family: '"Figtree", sans-serif',
        },
        // stepSize: 100
      },
      grid: {
        borderWidth: 0,
        tickWidth: 0,
      },
    },
    xAxis: {
      title: {
        display: true,
        text: "Age (Months)",
        font: {
          size: 14,
          family: '"Figtree", sans-serif',
        },
        color: "#212B36",
      },
      ticks: {
        color: "#212B36",
        font: {
          size: 14,
          family: '"Figtree", sans-serif',
        },
      },
      grid: {
        display: false,
        borderColor: "#CCCCCC",
        color: "#DFE3E8",
        tickWidth: 0,
      },
    },
  },
};

export const weightChartConfigData = {
  // labels : [
  //   0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20,
  //   21, 22, 23, 24, 25, 26, 27, 28, 29, 30, 31, 32, 33, 34, 35, 36, 37, 38, 39,
  //   40,
  // ],
  labels: Array.from(Array(10).keys()),
  datasets: [
    {
      label: "",
      data: [],
      borderColor: "#DFE3E8",
      borderWidth: 1,
      fill: true,
      pointBorderWidth: 2,
      pointBackgroundColor: "#9C6ADE",
      pointBorderColor: "#9C6ADE",
    },
  ],
};

export const heightChartOptions = {
  responsive: true,
  plugins: {
    legend: {
      display: false,
    },
    title: {
      display: false,
    },
  },
  scales: {
    yAxis: {
      min: 0,
      title: {
        display: true,
        text: "cm",
        font: {
          size: 14,
          family: '"Figtree", sans-serif',
        },
        color: "#212B36",
      },
      ticks: {
        color: "#212B36",
        font: {
          size: 14,
          family: '"Figtree", sans-serif',
        },
        // stepSize: 30,
      },
      grid: {
        borderWidth: 0,
        tickWidth: 0,
      },
    },
    xAxis: {
      title: {
        display: true,
        text: "Age (Months)",
        font: {
          size: 14,
          family: '"Figtree", sans-serif',
        },
        color: "#212B36",
      },
      ticks: {
        color: "#212B36",
        font: {
          size: 14,
          family: '"Figtree", sans-serif',
        },
      },
      grid: {
        display: false,
        borderColor: "#CCCCCC",
        color: "#DFE3E8",
        tickWidth: 0,
      },
    },
  },
};

export const heightChartConfigData = {
  labels: Array.from(Array(65).keys()),
  // labels : [
  //   0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20,
  //   21, 22, 23, 24, 25, 26, 27, 28, 29, 30, 31, 32, 33, 34, 35, 36, 37, 38, 39,
  //   40,
  // ],
  datasets: [
    {
      label: "",
      data: [],
      borderColor: "#DFE3E8",
      borderWidth: 1,
      fill: true,
      pointBorderWidth: 2,
      pointBackgroundColor: "#006FBB",
      pointBorderColor: "#006FBB",
    },
  ],
};


export const lineChartOptions = {
  type: 'line',
  responsive: true,
  plugins: {
    legend: {
      labels: {
        font: {
          size: 14,
          family: '"Figtree", sans-serif',
        },
        color: "#212B36",
      },
      display:false,
    },
    title: {
      display: false,
    },
  },
  maintainAspectRatio: false,
  scales: {
    yAxis: {
      min: 0,
      title: {
        display: true,
       
        font: {
          size: 14,
          family: '"Figtree", sans-serif',
        },
        color: "#212B36",
      },
      ticks: {
        color: "#212B36",
        precision:0,
        font: {
          size: 14,
          family: '"Figtree", sans-serif',
        },
        // stepSize: 100
      },
      grid: {
        borderWidth: 0,
        tickWidth: 0,
      },
    },
    xAxis: {
      title: {
        display: true,
        font: {
          size: 14,
          family: '"Figtree", sans-serif',
        },
        color: "#212B36",
      },
      ticks: {
        color: "#212B36",
        font: {
          size: 14,
          family: '"Figtree", sans-serif',
        },
      },
      grid: {
        borderColor: "#CCCCCC",
        color: "#DFE3E8",
        tickWidth: 0,
      },
    },
  },
};

export const barChartOptions = {
  responsive: true,
  plugins: {
    legend: {
      labels: {
        font: {
          size: 14,
          family: '"Figtree", sans-serif',
        },
        color: "#212B36",
      },
    },
    title: {
      display: false,
    },
  },
  maintainAspectRatio: false,
  scales: {
    y: {
      min: 0,
      title: {
        display: false,
        font: {
          size: 14,
          family: '"Figtree", sans-serif',
        },
        color: "#212B36",
      },
      ticks: {
        color: "#212B36",
        precision:0,
        font: {
          size: 14,
          family: '"Figtree", sans-serif',
        },
        callback: function(value:any) {
          var ranges = [
             { divider: 1e6, suffix: ' M' },
             { divider: 1e3, suffix: ' k' }
          ];
          function formatNumber(n:any) {
             for (var i = 0; i < ranges.length; i++) {
                if (n >= ranges[i].divider) {
                   return (n / ranges[i].divider).toString() + ranges[i].suffix;
                }
             }
             return n;
          }
          return formatNumber(value);
        }
      },
      grid: {
        padding: 46,
        borderColor: "#DFE3E8",
        color: "#DFE3E8",
        tickWidth: 0,
      },
    },
    x: {
      title: {
        display: true,
        
        font: {
          size: 14,
          family: '"Figtree", sans-serif',
        },
        color: "#212B36",
      },
      ticks: {
        color: "#212B36",
        font: {
          size: 14,
          family: '"Figtree", sans-serif',
        },
      },
      grid: {
        padding: 46,
        borderColor: "#CCCCCC",
        color: "#DFE3E8",
        tickWidth: 0,
      },
    },
  },
};

export const stackedBarChartOptions = {
  categoryPercentage:0.8,
  maxBarThickness:110,
  responsive: true,
  interaction: {
    intersect: false,
  },
  plugins: {
    legend: {
        display: false,
    },
    title: {
      display: false,
    },
  },
  maintainAspectRatio: false,
  scales: {
    y: {
      stacked: true,
      title: {
        display: false,
        font: {
          size: 14,
          family: '"Figtree", sans-serif',
        },
        color: "#212B36",
      },
      ticks: {
        min:Math.min(),
        color: "#212B36",
        font: {
          size: 14,
          family: '"Figtree", sans-serif',
        },
        callback: function(value:any) {
         
          let isNagative = value < 0 ? true : false;
          if(isNagative){
            value = -1 * value;
          }
          var ranges = [
             { divider: 1e6, suffix: ' M' },
             { divider: 1e3, suffix: ' k' }
          ];
          function formatNumber(n:any) {
             for (var i = 0; i < ranges.length; i++) {
                if (n >= ranges[i].divider) {
                   return (n / ranges[i].divider).toString() + ranges[i].suffix;
                }
             }
             return n;
          }
          let num = formatNumber(value);
        
          return  isNagative ? `-${num}` : num
        }
      },
      grid: {
        padding: 46,
        borderColor: "#DFE3E8",
        color: "#DFE3E8",
        tickWidth: 0,
      },
    },
    x: {
      stacked: true,
      title: {
        display: true,
        
        font: {
          size: 14,
          family: '"Figtree", sans-serif',
        },
        color: "#212B36",
      },
      ticks: {
        color: "#212B36",
        min:Math.min(),
        font: {
          size: 14,
          family: '"Figtree", sans-serif',
        },
      },
      grid: {
        padding: 46,
        borderColor: "#CCCCCC",
        color: "#DFE3E8",
        tickWidth: 0,
      },
    },
  },
};

export const singleBarChartOptions = {
  responsive: true,
  plugins: {
    legend: {
      display: false,
    },
    title: {
      display: false,
    },
  },
  maintainAspectRatio: false,
  scales: {
    y: {
      min: 0,
      title: {
        display: false,
        
        font: {
          size: 14,
          family: '"Figtree", sans-serif',
        },
        color: "#212B36",
      },
      ticks: {
        precision:0,
        color: "#212B36",
        font: {
          size: 14,
          family: '"Figtree", sans-serif',
        },
        callback: function(value:any) {
          var ranges = [
             { divider: 1e6, suffix: 'M' },
             { divider: 1e3, suffix: 'k' }
          ];
          function formatNumber(n:any) {
             for (var i = 0; i < ranges.length; i++) {
                if (n >= ranges[i].divider) {
                   return (n / ranges[i].divider).toString() + ranges[i].suffix;
                }
             }
             return n;
          }
          return formatNumber(value);
        }
      },
      grid: {
        padding: 46,
        borderColor: "#DFE3E8",
        color: "#DFE3E8",
        tickWidth: 0,
      },
    },
    x: {
      title: {
        display: true,
        
        font: {
          size: 14,
          family: '"Figtree", sans-serif',
        },
        color: "#212B36",
      },
      ticks: {
        color: "#212B36",
        font: {
          size: 14,
          family: '"Figtree", sans-serif',
        },
      },
      grid: {
        padding: 46,
        borderColor: "#CCCCCC",
        color: "#DFE3E8",
        tickWidth: 0,
      },
    },
  },
};

const lineDataConfig = [
  {
    borderColor: "#252525",
    backgroundColor: '#252525',
    borderWidth: 1,
    fill: true,
    borderJoinStyle: 'round',
    pointBorderWidth: 2,
    pointBackgroundColor: "#C6A761",
    pointBorderColor: "#C6A761",
  },
];

const barDataConfig = [
  {
    barPercentage: 1,
    backgroundColor: "#E3E3E3",
    hoverBackgroundColor: "#E3E3E3"
  },
  {
    barPercentage: 1,
    backgroundColor: "#F1E9D7",
    hoverBackgroundColor: "#F1E9D7"
  },
];

const singleBarDataConfig = [
  {
    barPercentage: 1,
    // backgroundColor: "#E3E3E3",
    // hoverBackgroundColor: "#E3E3E3"
  },
];

export const graphMap = new Map([
  [
    "count_foals",
    {
      graph: "line",
      dataConfig: lineDataConfig,
      option:lineChartOptions,
     
    },
  ],
  [
    "foal_by_gender",
    {
      graph: "bar",
      dataConfig: barDataConfig,
      option:barChartOptions
     
    },
  ],
  [
    "foal_by_color",
    {
      graph: "bar",
      dataConfig: singleBarDataConfig,
      option: singleBarChartOptions,
      subFilterSelect:"Select Colours"
    },
  ],
  [
    "foal_per_mares",
    {
      graph: "bar",
      dataConfig: singleBarDataConfig,
      option: singleBarChartOptions,
      subFilterSelect:"Select Mares"
    },
  ],
  [
    "foal_per_stallion",
    {
      graph: "bar",
      dataConfig: singleBarDataConfig,
      option: singleBarChartOptions,
      subFilterSelect:"Select Stallions"
    },
  ],
  [
    "net_income_expense",
    {
      graph: "bar",
      dataConfig: barDataConfig,
      option:barChartOptions
    },
  ],
  [
    "income_expense_category",
    {
      graph: "bar",
      type:"stacked",
      dataConfig: barDataConfig,
      option:stackedBarChartOptions
    },
  ],
]);
