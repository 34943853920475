import { t } from "i18next";
import { useState, useEffect } from "react";
import Modal from "react-bootstrap/Modal";
import { useSelector } from "react-redux";
import { API_STATUS } from "../../../Constant/Api";
import { SUCCESS_RESPONSE_CODE } from "../../../Constant/Status";
import { useLoading } from "../../../context/LoadingContext";
import { addEditFarm } from "../../../Redux/NonRedux/farm";
import validate from "../../../Utility/Validation";
import CustomeButton from "../../Common/CustomButton/CustomButton";
import InputGroups from "../../Common/InputGroups/InputGroups";
import SelectGropus from "../../Common/SelectGroups/SelectGroups";
import TextareaGroups from "../../Common/TextareaGroups/TextareaGroups";
import TextButton from "../../Common/TextButton/TextButton";

export default function AddFarmLocationPopup(props: any) {
  const INTIAL_FARM_LOCATION_DATA = {
    id: {
      value: "",
    },
    location: {
      value: "",
      validation: ["required"],
      errors: [t("Name is required.")],
    },
    country: {
      value: "",
      validation: ["required"],
      errors: [t("Country is required.")],
    },
    address: {
      value: "",
      validation: ["required"],
      errors: [t("Address is required.")],
    },
    description: {
      value: "",
      validation: [""],
      errors: [t("Description is required.")],
    },
  };

 
  const [farmData, setFarmData] = useState<any>(INTIAL_FARM_LOCATION_DATA);
  const [isFormValidated, setIsFormValidated] = useState(false);
  const [farmDataError, setfarmDataError] = useState<any>({});
  const [apiStatus, setApiStatus] = useState(API_STATUS.SUCCESS);
  const { setLoading } = useLoading();

  const setData = (field: string, value: any) => {
    const ob: any = Object.assign({}, farmData);
    ob[field].value = value;
    setFarmData({ ...ob });
  };

  const countryList = useSelector(
    (state: any) => state?.countryListReducer?.data?.component
  );

  const submitHandler = () => {
    if (validate(farmData).isValidated === true) {
      let params: any = {};
      Object.keys(farmData).forEach((element) => {
        if (farmData[element]) {
          params[element] = farmData[element].value;
        }
      });
      setLoading(true);
      addEditFarm(params)
        .then((response: any) => {
          if (response.statusCode === SUCCESS_RESPONSE_CODE) {
            setApiStatus(API_STATUS.SUCCESS);
            props.onHide();
            props.setrefreshlist();
          } else {
            setApiStatus(API_STATUS.FAILED);
          }
        })
        .catch((error) => {
          setApiStatus(API_STATUS.FAILED);
        })
        .finally(() => {
          setLoading(false);
        });
    } else {
      setfarmDataError({ ...validate(farmData)?.errors });
      setIsFormValidated(true);
    }
  };

  useEffect(() => {
    setLoading(true);
    if (props.isedit && props?.editdata) {
      Object.keys(farmData).forEach((element) => {
        if (props?.editdata?.[element]) {
          setData(element, `${props?.editdata?.[element]}`);
        }
      });
    }
    setLoading(false);
  }, [props?.editdata]);



  useEffect(() => {
    if (props?.show === false) {
      setfarmDataError({});
      setFarmData(INTIAL_FARM_LOCATION_DATA);
      setIsFormValidated(false);
    }
  }, [props?.show]);

  useEffect(() => {
    if (isFormValidated) {
      setfarmDataError({ ...validate(farmData).errors });
    }
  }, [farmData]);

  useEffect(() => {
    if (props.isedit && props?.editData) {
      Object.keys(farmData).forEach((element) => {
        setData(element, `${props?.editData?.[element]}`);
      });
    }
  }, [props?.editData]);

  const { setrefreshlist , metaData , ...rest } = props;

  return (
    <Modal {...rest} centered className="add-farm-location-popup modal-416">
      <Modal.Header closeButton>
        <p className="tl">
          {" "}
          {props.isedit ? t("Edit Farm Location") : t("Add Farm Location")} 
        </p>
      </Modal.Header>
      <Modal.Body>
        <div>
          <InputGroups
            label={`${t("Name")}*`}
            type="text"
            autoComplete="off"
            value={farmData?.location?.value}
            onChange={(e: any) => setData("location", e.target.value)}
            error={
              isFormValidated && farmDataError["location"]
                ? farmDataError["location"]
                : null
            }
          />
          <SelectGropus
            label={`${t("Country")}*`}
            value={metaData?.country}
            onChange={(e: any) => setData("country", e.target.value)}
            dropKey="id"
            dropVal="country"
            defaultSelect={farmData?.country?.value}
            error={
              isFormValidated && farmDataError["country"]
                ? farmDataError["country"]
                : null
            }
          />
          <TextareaGroups
            label={`${t("Address")}*`}
            type="text"
            autoComplete="off"
            value={farmData?.address?.value}
            onChange={(e: any) => setData("address", e.target.value)}
            error={
              isFormValidated && farmDataError["address"]
                ? farmDataError["address"]
                : null
            }
          />
          <TextareaGroups
            label={`${t("Description")}`}
            type="text"
            autoComplete="off"
            value={farmData?.description?.value}
            onChange={(e: any) => setData("description", e.target.value)}
            error={
              isFormValidated && farmDataError["description"]
                ? farmDataError["description"]
                : null
            }
          />
        </div>
      </Modal.Body>
      <Modal.Footer>
        <div className="footer-2-btn">
          <TextButton onClick={props.onHide}>{t("Cancel")}</TextButton>
          <CustomeButton bg="fill" onClick={submitHandler}>
            {" "}
            {props.isedit ? t("Update") : t("Add")}{" "}
          </CustomeButton>
        </div>
      </Modal.Footer>
    </Modal>
  );
}
