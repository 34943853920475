export const MAX_CALLS = 1;
const API_URL = process.env.REACT_APP_API_URL;  
export const END_POINTS = {
  master: process.env.REACT_APP_API_URL + "master",
  login: process.env.REACT_APP_API_URL + "authenticate",
  changePassword:process.env.REACT_APP_API_URL + "change-password",
  logout:process.env.REACT_APP_API_URL + "logoutWeb",
  changeLang: process.env.REACT_APP_API_URL + "language/change",
  horselist: process.env.REACT_APP_API_URL + "horse/list",
  metaHorseList: process.env.REACT_APP_API_URL + "horsemeta/list",
  horseDetail: process.env.REACT_APP_API_URL + "horse/view",
  singleHealthAddEdit: process.env.REACT_APP_API_URL + "health/add-edit",
  getSingleHealthList: process.env.REACT_APP_API_URL + "health/list",
  getSingleHealthDestroy: process.env.REACT_APP_API_URL + "health/destroy",
  getSingleHealthView: process.env.REACT_APP_API_URL + "health/view",
  horseInformation: process.env.REACT_APP_API_URL + "horse/information",
  healthVitalSign: process.env.REACT_APP_API_URL + "health/vital-sign/list",
  healthVaccination: process.env.REACT_APP_API_URL + "health/vaccination/list",
  healthParasite: process.env.REACT_APP_API_URL + "health/parasite/list",
  healthDental: process.env.REACT_APP_API_URL + "health/dental/list",
  healthVetClinic: process.env.REACT_APP_API_URL + "health/clinic/list",
  healthTreatments: process.env.REACT_APP_API_URL + "health/treatments/list",
  healthLabtest: process.env.REACT_APP_API_URL + "health/labtest/list",
  healthXray: process.env.REACT_APP_API_URL + "health/xray/list",

  taskAddEdit: process.env.REACT_APP_API_URL + "tasks/add-edit",
  taskList: process.env.REACT_APP_API_URL + "tasks/list",

  viewSingleTask: process.env.REACT_APP_API_URL + "tasks/view",
  destroyTask: process.env.REACT_APP_API_URL + "tasks/delete",
  reminderPreferenceList:
    process.env.REACT_APP_API_URL + "reminder/preference/get",
  reminderPreferenceSave:
    process.env.REACT_APP_API_URL + "reminder/preference/set",
  deleteReminder:process.env.REACT_APP_API_URL + "reminder/delete", 
  reminderList:process.env.REACT_APP_API_URL + "reminder/list",   
  accountPreferenceList: process.env.REACT_APP_API_URL + "preference/list",
  reminderStatusChange:process.env.REACT_APP_API_URL + "reminder/change-status",
  nextPreReminder:API_URL + "reminder/list-prev-next",
  accountPreferenceSet: process.env.REACT_APP_API_URL + "preference/set",

  contacts: process.env.REACT_APP_API_URL + "contact/list",
  countryList: process.env.REACT_APP_API_URL + "country/list",
  metaMain: process.env.REACT_APP_API_URL + "metadata/",
  metaVaccine: process.env.REACT_APP_API_URL + "metadata/vaccine/",
  metaDewormer: process.env.REACT_APP_API_URL + "metadata/dewormer/",
  metaTaskType: process.env.REACT_APP_API_URL + "metadata/task_type/",
  metaColor: process.env.REACT_APP_API_URL + "metadata/color/",
  metaGender: process.env.REACT_APP_API_URL + "metadata/gender/",
  metaHorseStatus: process.env.REACT_APP_API_URL + "metadata/horse_status/",
  metaUnit: process.env.REACT_APP_API_URL + "metadata/qty_units/",
  metaCurrency: process.env.REACT_APP_API_URL + "metadata/currency/",
  metaDietCategory: process.env.REACT_APP_API_URL + "metadata/diet_category/",
  metaBreedTypes: process.env.REACT_APP_API_URL + "metadata/breed/",
  metaShoeingTypes: process.env.REACT_APP_API_URL + "metadata/shoeing_type/",
  metaShoeMaterial:
    process.env.REACT_APP_API_URL + "metadata/shoeing_material/",
  metaDiscipline: process.env.REACT_APP_API_URL + "metadata/discipline/",
  metaIncomeCategory:
    process.env.REACT_APP_API_URL + "metadata/income_category/",
  meataBreedingServiceType:process.env.REACT_APP_API_URL + "metadata/breeding_service_type/",
  costCategory:API_URL + "expence/category",
  dietAddEdit: process.env.REACT_APP_API_URL + "diet/add-edit",
  bulkDietAdd: process.env.REACT_APP_API_URL + "diet/bulk-add",
  dietList: process.env.REACT_APP_API_URL + "diet/list",
  bulkDietList: process.env.REACT_APP_API_URL + "diet/main-list",
  dietView: process.env.REACT_APP_API_URL + "diet/view",
  destroyDiet: process.env.REACT_APP_API_URL + "diet/destroy",
  measurementsAddEdit: process.env.REACT_APP_API_URL + "measurements/add-edit",
  measurementsList: process.env.REACT_APP_API_URL + "measurements/list",
  measurementView: process.env.REACT_APP_API_URL + "measurements/view",
  destroyMesurements: process.env.REACT_APP_API_URL + "measurements/destroy",
  destroyFarrir: process.env.REACT_APP_API_URL + "farrier/destroy",
  viewFarrier: process.env.REACT_APP_API_URL + "farrier/view",
  farrierList: process.env.REACT_APP_API_URL + "farrier/list",
  bulkFarrierList: process.env.REACT_APP_API_URL + "farrier/main-list",
  addBulkFarrier: process.env.REACT_APP_API_URL + "farrier/bulk-add",
  addEditFarrier: process.env.REACT_APP_API_URL + "farrier/add-edit",
  addEditAchievement: process.env.REACT_APP_API_URL + "achievement/add-edit",
  achievementList: process.env.REACT_APP_API_URL + "achievement/list",
  achivementView: process.env.REACT_APP_API_URL + "achievement/view",
  destroyAchievement: process.env.REACT_APP_API_URL + "achievement/destroy",
  uploadDocument: process.env.REACT_APP_API_URL + "documents/add",
  documentList: process.env.REACT_APP_API_URL + "documents/list",
  destroyDocument: process.env.REACT_APP_API_URL + "documents/destroy",
  addEditNote: process.env.REACT_APP_API_URL + "notes/add-edit",
  noteList: process.env.REACT_APP_API_URL + "notes/list",
  destroyNote: process.env.REACT_APP_API_URL + "notes/destroy",
  noteView: process.env.REACT_APP_API_URL + "notes/view",
  addEditImageVideo: process.env.REACT_APP_API_URL + "imageVideo/add-edit",
  imageList: process.env.REACT_APP_API_URL + "imageVideo/image/list",
  videoList: process.env.REACT_APP_API_URL + "imageVideo/video/list",
  destroyImageViode: process.env.REACT_APP_API_URL + "imageVideo/destroy",
  viewImageVideo: process.env.REACT_APP_API_URL + "imageVideo/view",
  changeVideoImagePrivacy:
    process.env.REACT_APP_API_URL + "imageVideo/privacyChange",
  getCompletePedigree: process.env.REACT_APP_API_URL + "pedigree/complete",
  editPedigree: process.env.REACT_APP_API_URL + "pedigree/edit",
  addEditPublicLink: process.env.REACT_APP_API_URL + "publicLink/add",
  addEditInsurance: process.env.REACT_APP_API_URL + "insurance/add-edit",
  downloadDocument: process.env.REACT_APP_API_URL + "documents/download",
  sireList: process.env.REACT_APP_API_URL + "sire/list",
  damList: process.env.REACT_APP_API_URL + "dam/list",
  locationList: process.env.REACT_APP_API_URL + "location/list",
  addEditHorse: process.env.REACT_APP_API_URL + "horse/add-edit",
  deleteHorse: process.env.REACT_APP_API_URL + "horse/destroy",

  addBoardingplan: process.env.REACT_APP_API_URL + "boarding/plan/add",
  editBoardingPlan: process.env.REACT_APP_API_URL + "boarding/plan/edit",
  boardingPlanList: process.env.REACT_APP_API_URL + "boarding/plan/list",
  addEditBoarding: process.env.REACT_APP_API_URL + "boarding/add-edit",
  boardingList: process.env.REACT_APP_API_URL + "boarding/list",
  viewBoarding: process.env.REACT_APP_API_URL + "boarding/get",
  destroyBoarding: process.env.REACT_APP_API_URL + "boarding/destroy",
  destroyBoardingPlan: process.env.REACT_APP_API_URL + "boarding/destroy",
  endBoardigHorse: API_URL + "boarding/end",
  horseDetailMeta: process.env.REACT_APP_API_URL + "horse/details/metadata",
  breedingListHorseWise: process.env.REACT_APP_API_URL + "breading/main-list",
  addTraining: process.env.REACT_APP_API_URL + "training/add",
  editTraining: process.env.REACT_APP_API_URL + "training/edit",
  destroyTraining: process.env.REACT_APP_API_URL + "training/destroy",
  trainingList: process.env.REACT_APP_API_URL + "training/list",
  viewTraining: process.env.REACT_APP_API_URL + "training/view",
  metaTrainingType: process.env.REACT_APP_API_URL + "metadata/training_type/",
  trainingSchedule: process.env.REACT_APP_API_URL + "training/schedule",
  endTraining: process.env.REACT_APP_API_URL + "training/end", 
  
  changeFarmVisibality:API_URL + "horse/changeVisible",
  addEditFarm: process.env.REACT_APP_API_URL + "FarmLocation/add-edit",
  destroyFarm: process.env.REACT_APP_API_URL + "FarmLocation/delete",
  farmList: process.env.REACT_APP_API_URL + "FarmLocation/list",
  viewFarm: process.env.REACT_APP_API_URL + "FarmLocation/view",

  addEditContact: process.env.REACT_APP_API_URL + "usercontact/add-edit",
  viewContact: process.env.REACT_APP_API_URL + "usercontact/view",
  contactList: process.env.REACT_APP_API_URL + "usercontact/list",
  destroyContact: process.env.REACT_APP_API_URL + "usercontact/delete",

  addEditInventory: process.env.REACT_APP_API_URL + "inventory/add-edit",
  viewInventory: process.env.REACT_APP_API_URL + "inventory/view",
  listInventory: process.env.REACT_APP_API_URL + "inventory/list",
  destroyInventory: process.env.REACT_APP_API_URL + "inventory/destroy",
  exportInventory: process.env.REACT_APP_API_URL + "inventory/export",

  addTransport: process.env.REACT_APP_API_URL + "transport/add",
  editTransport: process.env.REACT_APP_API_URL + "transport/edit",
  viewTransport: process.env.REACT_APP_API_URL + "transport/view",
  listTransport: process.env.REACT_APP_API_URL + "transport/list",
  destroyTransport: process.env.REACT_APP_API_URL + "transport/delete",

  addEditStaff: process.env.REACT_APP_API_URL + "staff/add-edit",
  destroyStaff: process.env.REACT_APP_API_URL + "staff/destroy",
  viewStaff: process.env.REACT_APP_API_URL + "staff/view",
  staffList: process.env.REACT_APP_API_URL + "staff/list",
  
  addExpenses: process.env.REACT_APP_API_URL + "categorycostrecords/add-edit",
  listofExpenses: process.env.REACT_APP_API_URL + "categorycostrecords/details",

  metaStaffList: process.env.REACT_APP_API_URL + "staff/fullname",

  categoryCostRecordsList:
    process.env.REACT_APP_API_URL + "categorycostrecords/list",
  categoryCostRecordsDetails:
    process.env.REACT_APP_API_URL + "categorycostrecords/details",
  horseWiseCostRecordDetails:
    process.env.REACT_APP_API_URL + "categorycostrecords/horselist",
  getSingleCost: process.env.REACT_APP_API_URL + "categorycostrecords/view",
  destroyCost: process.env.REACT_APP_API_URL + "categorycostrecords/destroy",
  
  accouuntInfoView:process.env.REACT_APP_API_URL +  "accountinfo/view",
  accountInfoUpdate:process.env.REACT_APP_API_URL +  "accountinfo/addupdate",
  
  payrollList:process.env.REACT_APP_API_URL +  "staff/payroll/list",
  viewSinglePayroll:process.env.REACT_APP_API_URL +  "staff/payroll/view",
  updatePayroll:process.env.REACT_APP_API_URL +  "staff/payroll/edit",
  destroyPayroll:process.env.REACT_APP_API_URL +  "staff/payroll/destroy",
  bulkPayrollUpdate:process.env.REACT_APP_API_URL +  "staff/payroll/bulk-update",
  
  sampleHorseImport:process.env.REACT_APP_API_URL +  "horse/import/sampleFile",
  uploadHorseImport:process.env.REACT_APP_API_URL +  "horse/import",

  getBreedingMeta : process.env.REACT_APP_API_URL +  "meta_list/breeding",

  getAllBreedingServiceList:process.env.REACT_APP_API_URL +  "breading/service/list",
  viewBreedingService:process.env.REACT_APP_API_URL +  "breading/service/view",
  addEditBreedingService:process.env.REACT_APP_API_URL +  "breading/service/add-edit",
  destroyBreedingService:process.env.REACT_APP_API_URL +  "breading/service/destroy",

  addEditBreedingControl:process.env.REACT_APP_API_URL + "breading/controll/add-edit",
  getBreedingServiceDetails:process.env.REACT_APP_API_URL +  "breading/service/details",
  viewBreedingControl:process.env.REACT_APP_API_URL +  "breading/controll/view",
  destroyBreedingControl:process.env.REACT_APP_API_URL +  "breading/controll/destroy",
  
  addEditBreedingStallion:process.env.REACT_APP_API_URL + "breading/stallion/add-edit",
  getAllBreedingStallionList:process.env.REACT_APP_API_URL +  "breading/stallion/list",
  viewBreedingStallion:process.env.REACT_APP_API_URL +  "breading/stallion/view",
  destroyBreedingStallion:process.env.REACT_APP_API_URL +  "breading/stallion/destroy",
  
  editStallion: process.env.REACT_APP_API_URL + "horse/stallion/edit",
  viewStallion: process.env.REACT_APP_API_URL + "horse/stallion/view",

  addEditBreedingFlush:process.env.REACT_APP_API_URL + "breading/flush/add-edit",
  viewBreedingFlush:process.env.REACT_APP_API_URL +  "breading/flush/view",
  destroyBreedingFlush:process.env.REACT_APP_API_URL +  "breading/flush/destroy",

  addEditBreedingFoal:process.env.REACT_APP_API_URL + "breading/foal/add-edit",
  viewBreedingFoal:process.env.REACT_APP_API_URL +  "breading/foal/view",
  destroyBreedingFoal:process.env.REACT_APP_API_URL +  "breading/Foal/destroy",

  addEditEmbryoTransfer:process.env.REACT_APP_API_URL + "breading/embryotransfer/add-edit",
  viewEmbryoTransfer:process.env.REACT_APP_API_URL +  "breading/embryotransfer/view",
  destroyEmbryoTransfer:process.env.REACT_APP_API_URL +  "breading/flush/destroy",

  invoiceLineList: process.env.REACT_APP_API_URL + "invoice/line/list",
  invoiceLineView: process.env.REACT_APP_API_URL + "invoice/line/view",
  invoiceLineAdd: process.env.REACT_APP_API_URL + "invoice/line/add",
  invoiceAddManualy:process.env.REACT_APP_API_URL + "invoice/add",
  invoiceList:process.env.REACT_APP_API_URL + "invoice/list",
  changeInvoicePayment:process.env.REACT_APP_API_URL + "invoice/payment",
  invoiceHorseList:process.env.REACT_APP_API_URL + "invoice/horselist",
  invoiceDelete:process.env.REACT_APP_API_URL + "invoice/delete",
  invoiceDowload:process.env.REACT_APP_API_URL + "invoice/download",
  
  // Export API
  boardingExport:API_URL + "boarding/export",
  vitalSignExport:API_URL + "health/vital-sign/export",  
  clinicExport:API_URL + "health/clinic/export",
  parasiteExport:API_URL + "health/parasite/export",
  dentalExport:API_URL + "health/dental/export",
  vacinationExport:API_URL + "health/vaccination/export",
  treatmentExport:API_URL + "health/treatments/export",
  labtestExport:API_URL + "health/labtest/export",
  xrayExport:API_URL + "health/xray/export",
  trainingExport:API_URL + "training/export",
  transportExport:API_URL + "transport/export",
  inventoryExport:API_URL + "inventory/export",
  taskExport:API_URL + "tasks/export",
  notesExport:API_URL + "notes/export",
  horseDetailsExport:API_URL + "horse/detail/export",
  helthExport:API_URL + "health/export",
  achivementExport:API_URL + "achievement/export",
  measurementExport:API_URL + "measurements/export",
  breedingMareExport:API_URL + "breeding/mare/export",
  breedingStallionExport:API_URL + "breeding/stallion/export",
  breedingExport:API_URL + "breeding/main/export",
  dietExport:API_URL + "diet/export",
  farrierExport:API_URL + "farrier/export",
  horseExport:API_URL + "horse/export",
  

  getHorsePedigree: API_URL + "horse/get-pedigree",
  downloadHorsePedigree: API_URL + "horse/test-match-export",

  getStudFarmList:API_URL + "studfarm/list",
  getStudFarmDetails:API_URL + "studfarm/view",
  studFarmHorsePreview:API_URL + "studfarm/preview",


  // API FOR META DATA
  metaFarmLocation:API_URL + "meta_list/farm_location",
  metaUserContact:API_URL + "meta_list/users_contact",
  metaUserStaff:API_URL + "meta_list/users_staff",
  metaUserPayroll:API_URL + "meta_list/users_payroll",

  metaHelthParasite:API_URL + "meta_list/helth_parasite",
  metaHelthDental:API_URL + "meta_list/helth_dental",
  metaHelthVaccination:API_URL + "meta_list/helth_vaccination",
  metaHelthTreatment:API_URL + "meta_list/helth_treatment",
  metaHelthLabtest:API_URL + "meta_list/helth_labtest",
  metaHelthXray:API_URL + "meta_list/helth_xray",
  metaHelthVetVisit:API_URL + "meta_list/helth_vet_visit",
  metaHelthVitalsign:API_URL + "meta_list/vital_sign",
  metaTraining:API_URL + "meta_list/training",
  metaTransport:API_URL + "meta_list/transportation",
  metaInventory:API_URL + "meta_list/inventory",
  metaTask:API_URL + "meta_list/task",
  metaDiet:API_URL + "meta_list/diet",
  metaFarrier:API_URL + "meta_list/farrier", 
  metaAchievement:API_URL + "meta_list/achivements", 
  metaIncomeExpense:API_URL + "meta_list/income_expense", 
  metaMeasurement:API_URL + "meta_list/measurement", 
  metaBoarding:API_URL + "meta_list/boarding", 
  metaInvoices:API_URL + "meta_list/invoice", 
  metaBreeeding:API_URL + "meta_list/breeding_main", 
  getDashboard:API_URL + "dashboard", 
  getSalesData  :API_URL + "dashboard/sales",
  getGraphData :API_URL + "dashboard/analytics",
};

export const API_STATUS = {
  LOADING: "loading",
  SUCCESS: "success",
  LONG: "long",
  FAILED: "failed",
  NO_DATA: "nodata",
};


