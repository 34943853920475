import { t } from "i18next";
import React, { useContext, useEffect, useState } from "react";
import { Col, Row } from "react-bootstrap";
import { useDispatch } from "react-redux";
import SettingIcon from "../../../Assets/img/settings.svg";
import { useLoading } from "../../../context/LoadingContext";
import { SidebarContext } from "../../../context/SideBarContext";
import { masterAction } from "../../../Redux/General/GeneralAction";
import { getAccountPreferenceList, setAccountPreference } from "../../../Redux/NonRedux/prefernce";
import validate from "../../../Utility/Validation";
import CustomeButton from "../../Common/CustomButton/CustomButton";
import InputGroups from "../../Common/InputGroups/InputGroups";
import SelectGropus from "../../Common/SelectGroups/SelectGroups";
import ToggleButton from "../../Common/ToggleButton/ToggleButton";

export default function PreferencePage() {
  const INITIAL_PREFERENCE_DATA: any = {
    language: {
      value: "",
      validation: ["required"],
      errors: [t("Language is required.")],
    },
    timezone: {
      value: "",
      validation: ["required"],
      errors: [t("Time Zone is required.")],
    },
    age_format: {
      value: "",
      validation: ["required"],
      errors: [t("Age Format is required.")],
    },
    currency: {
      value: "",
      validation: ["required"],
      errors: [t("Currency Symbol is required.")],
    },
    unit_system: {
      value: "",
      validation: ["required"],
      errors: [t("Unit system is required.")],
    },
    // heightUnit: {
    //   value: "",
    //   validation: ["required"],
    //   errors: [t("heightUnit is required.")],
    // },
    // weightUnit: {
    //   value: "",
    //   validation: ["required"],
    //   errors: [t("weightUnit is required.")],
    // },
    // distanceUnit: {
    //   value: "",
    //   validation: ["required"],
    //   errors: [t("distanceUnit is required.")],
    // },
    // speedUnit: {
    //   value: "",
    //   validation: ["required"],
    //   errors: [t("speedUnit is required.")],
    // },
    // temperatureUnit: {
    //   value: "",
    //   validation: ["required"],
    //   errors: [t("temperatureUnit is required.")],
    // },
    activeDefault: {
      value: "1",
      validation: ["required"],
      errors: [t("Active by default is required.")],
    },
    // currentYearDefault: {
    //   value: "0",
    //   validation: [""],
    //   errors: [t("currentYearDefault is required.")],
    // },
    // transportationUpdate: {
    //   value: "0",
    //   validation: ["required"],
    //   errors: [t("transportationUpdate is required.")],
    // },
    pregnancyDays: {
      value: "",
      validation: ["required"],
      errors: [t("Duration of pregnancy is required.")],
    },
    foaltime: {
      value: "",
      validation: ["required"],
      errors: [t("Duration of Foal is required.")],
    },
    embryoFlushDays: {
      value: "",
      validation: ["required" , "beteween:6:10" ],
      errors: [t("Days to embryo flush is required.") , t("Please enter range from 6 to 10")],
    },
  };

  const [preferenceData, setPreferenceData] = useState(INITIAL_PREFERENCE_DATA);

  const setData = (field: string, value: any) => {
    let preferenceDetail = Object.assign({}, preferenceData);
    preferenceDetail[field].value = value;
    setPreferenceData({ ...preferenceDetail });
  };



  const [submitButtonStatus, setSubmitButtonStatus] = useState<any>(false);
  const [detectChange, setDetectChange] = useState<any>(false);
  const [currentPreference, setCurrentPreference] = useState<any>({});
  const [preferenceList, setPreferenceList] = useState<any>({});
  const [isFormValidated, setIsFormValidated] = useState(false);
  const [preferenceDataError, setPreferenceDataError] = useState<any>({});
  const {setSidebar} : any =  useContext(SidebarContext);
  const {setLoading} = useLoading();
  const toggleButtons = [
    { name: t("Yes"), value: "1" },
    { name: t("No"), value: "0" },
  ];

  useEffect(() => {
    listApiCall();
    setSidebar("");
  }, []);

  useEffect(() => {
    setPreferenceDataError({ ...validate(preferenceData).errors });
}, [preferenceData]);


  useEffect(() => {
    if(detectChange){
      let preferenceDataList: any = {};
      Object.keys(preferenceData).forEach(function (key) {
        preferenceDataList[key] = preferenceData[key].value;
      });
      
      let currentPreferenceString = JSON.stringify(currentPreference);
      let preferenceDataListString = JSON.stringify(preferenceDataList);
      if (currentPreferenceString != preferenceDataListString) {
        setSubmitButtonStatus(true);
      }else{
        setSubmitButtonStatus(false);
      }
    }
  }, [preferenceData]);

  const listApiCall = () => {
    getAccountPreferenceList({})
      .then((response: any) => {
        let list = [
          "language",
          "timezone",
          "age_format",
          "currency",
          "foaltime",
          // "heightUnit",
          // "weightUnit",
          "unit_system",
          // "distanceUnit",
          // "speedUnit",
          // "temperatureUnit",
          "activeDefault",
          // "transportationUpdate",
          "pregnancyDays",
          "embryoFlushDays",
        ];
        let currentPref = response?.component?.currentPreferance;
        let currentPreference: any = {};
        Object.keys(currentPref).forEach(function (key) {
          if (list.includes(key)) {
            if(currentPref[key]){
              setData(key, currentPref[key]);
            }
            currentPreference[key] = preferenceData[key].value;
          }
        });
        setCurrentPreference(currentPreference);
        setPreferenceList(response?.component?.preferenceList);
      })
      .catch((error) => {});
  };

  const dispatch = useDispatch();
  const submitHandler = () => {
   
    if (validate(preferenceData).isValidated === true) {
      let params:any = {};
      Object.keys(preferenceData).forEach((element) => {
        params[element] = preferenceData[element].value;
      });
      setLoading(true);
      setAccountPreference(params).then((response:any) =>{
        setSubmitButtonStatus(false);
        setDetectChange(false);
        setIsFormValidated(false);
        setPreferenceDataError({});
        listApiCall();
        
        masterAction();
      }).catch((error:any) =>{

      }).finally(() => {
        setLoading(false);
      });
    } else {
      setPreferenceDataError({ ...validate(preferenceData)?.errors });
      setIsFormValidated(true);
    }
  };

  return (
    <div className="preference-page">
      {/* Page Title Start */}
      <div className="page-title-wrapper">
        <div className="title-first-menu">
          <div className="title-icon-text">
            <img src={SettingIcon} alt="" />
            <h5>{`${t("Preferences")}`}</h5>
          </div>
        </div>
      </div>
      {/* Page Title End */}
      <div className="page-content">
        <div className="layout-720">
          <Row>
            <Col sm={6} md={6}>
              <SelectGropus
                label={`${t("Language")}*`}
                defaultSelect={preferenceData?.language?.value}
                value={preferenceList?.language}
                dropKey="slug"
                dropVal="metadata"
                onChange={(e: any) => {
                  setDetectChange(true);
                  setData("language", e.target.value);
                }}
                error={
                  isFormValidated && preferenceDataError["language"]
                    ? preferenceDataError["language"]
                    : null
                }
              />
            </Col>
            {/* <Col sm={6} md={6}>
              <SelectGropus label={`${t("Time Zone" value={timeZone} />)*}`}
            </Col>
            <Col sm={6} md={6}>
              <SelectGropus label={`${t("Age Format" value={ageFormate} />)*}`}
            </Col> */}
            <Col sm={6} md={6}>
              <SelectGropus
                label={`${t("Time Zone")}*`}
                defaultSelect={preferenceData?.timezone?.value}
                value={preferenceList?.timezone}
                dropKey="slug"
                dropVal="metadata"
                onChange={(e: any) => {
                  setDetectChange(true);
                  setData("timezone", e.target.value);
                }}
                error={
                  isFormValidated && preferenceDataError["timezone"]
                    ? preferenceDataError["timezone"]
                    : null
                }
              />
            </Col>
            <Col sm={6} md={6}>
              <SelectGropus
                label={`${t("Age Format")}*`}
                defaultSelect={preferenceData?.age_format?.value}
                value={preferenceList?.age_format}
                dropKey="slug"
                dropVal="metadata"
                onChange={(e: any) => {
                  setDetectChange(true);
                  setData("age_format", e.target.value);
                }}
                error={
                  isFormValidated && preferenceDataError["age_format"]
                    ? preferenceDataError["age_format"]
                    : null
                }
              />
            </Col>
            <Col sm={6} md={6}>
              <SelectGropus
                label={`${t("Currency Symbol")}*`}
                defaultSelect={preferenceData?.currency?.value}
                value={preferenceList?.currency}
                dropKey="slug"
                dropVal="metadata"
                onChange={(e: any) => {
                  setDetectChange(true);
                  setData("currency", e.target.value);
                }}
                error={
                  isFormValidated && preferenceDataError["currency"]
                    ? preferenceDataError["currency"]
                    : null
                }
              />
            </Col>
              <Col sm={6} md={6}>
              <SelectGropus
                label={`${t("Unit System")}*`}
                defaultSelect={preferenceData?.unit_system?.value}
                value={preferenceList?.unit_system}
                dropKey="slug"
                dropVal="metadata"
                onChange={(e: any) => {
                  setDetectChange(true);
                  setData("unit_system", e.target.value);
                }}
                error={
                  isFormValidated && preferenceDataError["unit_system"]
                    ? preferenceDataError["unit_system"]
                    : null
                }
              />
            </Col>
            {/* <Col sm={6} md={6}>
              <SelectGropus
                label={`${t("Height Unit")}*`}
                defaultSelect={preferenceData?.heightUnit?.value}
                value={preferenceList?.heightUnits}
                dropKey="slug"
                dropVal="metadata"
                onChange={(e: any) => {
                  setDetectChange(true);
                  setData("heightUnit", e.target.value);
                }}
                error={
                  isFormValidated && preferenceDataError["heightUnit"]
                    ? preferenceDataError["heightUnit"]
                    : null
                }
              />
            </Col>
            <Col sm={6} md={6}>
              <SelectGropus
                label={`${t("Weight Unit")}*`}
                defaultSelect={preferenceData?.weightUnit?.value}
                value={preferenceList?.weightUnits}
                dropKey="slug"
                dropVal="metadata"
                onChange={(e: any) => {
                  setDetectChange(true);
                  setData("weightUnit", e.target.value);
                }}
                error={
                  isFormValidated && preferenceDataError["weightUnit"]
                    ? preferenceDataError["weightUnit"]
                    : null
                }
              />
            </Col> */}
            {/* <Col sm={6} md={6}>
              <SelectGropus
                label={`${t("Distance Unit")}*`}
                defaultSelect={preferenceData?.distanceUnit?.value}
                value={preferenceList?.distanceUnits}
                dropKey="slug"
                dropVal="metadata"
                onChange={(e: any) => {
                  setDetectChange(true);
                  setData("distanceUnit", e.target.value);
                }}
                error={
                  isFormValidated && preferenceDataError["distanceUnit"]
                    ? preferenceDataError["distanceUnit"]
                    : null
                }
              />
            </Col>
            <Col sm={6} md={6}>
              <SelectGropus
                label={`${t("Speed Unit")}*`}
                defaultSelect={preferenceData?.speedUnit?.value}
                value={preferenceList?.speedUnits}
                dropKey="slug"
                dropVal="metadata"
                onChange={(e: any) => {
                  setDetectChange(true);
                  setData("speedUnit", e.target.value);
                }}
                error={
                  isFormValidated && preferenceDataError["speedUnit"]
                    ? preferenceDataError["speedUnit"]
                    : null
                }
              />
            </Col>
            <Col sm={6} md={6}>
              <SelectGropus
                label={`${t("Temperature Unit")}*`}
                defaultSelect={preferenceData?.temperatureUnit?.value}
                value={preferenceList?.temperatureUnits}
                dropKey="slug"
                dropVal="metadata"
                onChange={(e: any) => {
                  setDetectChange(true);
                  setData("temperatureUnit", e.target.value);
                }}
                error={
                  isFormValidated && preferenceDataError["temperatureUnit"]
                    ? preferenceDataError["temperatureUnit"]
                    : null
                }
              />
            </Col> */}
          </Row>
          <Row>
            <Col sm={6} md={6}>
              <ToggleButton
                value={toggleButtons}
                label={`${t("Active by default")}*`}
                selected={preferenceData?.activeDefault?.value}
                setSelected={(e: any) => {
                  setDetectChange(true);
                  setData("activeDefault", e);
                }}
                error={
                  isFormValidated && preferenceDataError["activeDefault"]
                    ? preferenceDataError["activeDefault"]
                    : null
                }
              />
            </Col>
            <Col sm={6} md={6}>
              <InputGroups
                label={`${t("Duration of foal (Days)")}*`}
                value={preferenceData?.foaltime?.value}
                type="text"
                autoComplete="off"
                onChange={(e: any) => {
                  setDetectChange(true);
                  setData("foaltime", e.target.value);
                }}
                error={
                  isFormValidated && preferenceDataError["foaltime"]
                    ? preferenceDataError["foaltime"]
                    : null
                }
              />
            </Col>
          </Row>
          {/* <Row>
            <Col sm={6} md={6}>
              <ToggleButton
                value={toggleButtons}
                label={`${t("Show current year by default")}*`}
                selected={preferenceData?.currentYearDefault?.value}
                setSelected={(e: any) => {
                  setDetectChange(true);
                  setData("currentYearDefault", e);
                }}
                error={
                  isFormValidated && preferenceDataError["currentYearDefault"]
                    ? preferenceDataError["currentYearDefault"]
                    : null
                }
              />
            </Col>
          </Row> */}
          {/* <Row>
            <Col sm={6} md={6}>
              <ToggleButton
                value={toggleButtons}
                label={`${t("Transportation update horse location")}*`}
                selected={preferenceData?.transportationUpdate?.value}
                setSelected={(e: any) => {
                  setDetectChange(true);
                  setData("transportationUpdate", e);
                }}
                error={
                  isFormValidated && preferenceDataError["transportationUpdate"]
                    ? preferenceDataError["transportationUpdate"]
                    : null
                }
              />
            </Col>
          </Row> */}
          <Row>
            <Col sm={6} md={6}>
              <InputGroups
                label={`${t("Duration of pregnancy (Days)")}*`}
                value={preferenceData?.pregnancyDays?.value}
                type="text"
                autoComplete="off"
                onChange={(e: any) => {
                  setDetectChange(true);
                  setData("pregnancyDays", e.target.value);
                }}
                error={
                  isFormValidated && preferenceDataError["pregnancyDays"]
                    ? preferenceDataError["pregnancyDays"]
                    : null
                }
              />
            </Col>
            <Col sm={6} md={6}>
              <InputGroups
                label={`${t("Embryo flush time window (Days)")}*`}
                value={preferenceData?.embryoFlushDays?.value}
                type="text"
                autoComplete="off"
                onChange={(e: any) => {
                  setDetectChange(true);
                  setData("embryoFlushDays", e.target.value);
                }}
                error={
                  isFormValidated && preferenceDataError["embryoFlushDays"]
                    ? preferenceDataError["embryoFlushDays"]
                    : null
                }
              />
            </Col>
          </Row>
          <div className="footer-btn">
            <CustomeButton
              bg="fill"
              onClick={() => submitHandler()}
              disabled={!submitButtonStatus}
            >
            {t("Save")}  
            </CustomeButton>
          </div>
        </div>
      </div>
    </div>
  );
}
