import React from "react";
import Button from "@mui/material/Button";

const TextButton = (props: any) => {
  return (
    <Button
      data-aos={props.animatioName}
      onClick={props.onClick}
      type={props.type}
      id={props.id}
      value={props.value}
      className={"text-btn"}
      disabled={props.disabled}
      variant={"contained"}
    >
      {props.children}
    </Button>
  );
};

export default TextButton;
