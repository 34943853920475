import type { DatePickerProps } from "antd";
import { t } from "i18next";
import moment from "moment";
import React, { useEffect, useRef, useState } from "react";
import { Col, Row } from "react-bootstrap";
import Modal from "react-bootstrap/Modal";
import { useDispatch, useSelector } from "react-redux";
import { API_STATUS } from "../../../Constant/Api";
import { DEATH_ID, REFRESH, SOLD_ID } from "../../../Constant/General";
import { SUCCESS_RESPONSE_CODE } from "../../../Constant/Status";
import { useLoading } from "../../../context/LoadingContext";
import { addEditHorse } from "../../../Redux/NonRedux/horse";
import validate from "../../../Utility/Validation";
import CustomeButton from "../../Common/CustomButton/CustomButton";
import Datepicker from "../../Common/Datepicker/Datepicker";
import InputGroups from "../../Common/InputGroups/InputGroups";
import InputSelectGroup from "../../Common/InputSelectGroup/InputSelectGroup";
import InputSelectMix from "../../Common/InputSelectMix/InputSelectMix";
import SelectGropus from "../../Common/SelectGroups/SelectGroups";
import TextButton from "../../Common/TextButton/TextButton";
import ToggleButton from "../../Common/ToggleButton/ToggleButton";

export default function AddEditHorsePopup(props: any) {
  const INITIAL_HORSE_DATA: any = {
    name: {
      value: "",
      validation: ["required", "letters"],
      errors: [t("Name is required."), t("Incorrect format")],
    },
    birthdate: {
      value: moment().format("YYYY-MM-DD"),
      validation: [""],
      errors: [t("Date of birth is required.")],
    },
    sex: {
      value: "",
      validation: ["required"],
      errors: [t("Sex is required.")],
    },
    color: {
      value: "",
      validation: [""],
      errors: [t("Color is required.")],
    },
    select_sire: {
      value: "",
      validation: [""],
      errors: [t("")],
    },
    sire: {
      value: "",
      validation: ["letters"],
      errors: [t("Incorrect format")],
    },
    sire_id: {
      value: "",
      validation: [],
      errors: [t("Sire is required.")],
    },
    select_dam: {
      value: "",
      validation: [""],
      errors: [t("")],
    },
    dam: {
      value: "",
      validation: ["letters"],
      errors: [ t("Incorrect format")],
    },
    dam_id: {
      value: "",
      validation: [],
      errors: [t("Dam is required.")],
    },
    breed: {
      value: "",
      validation: [""],
      errors: [t("Breed is required.")],
    },
    strain: {
      value: "",
      validation: [""],
      errors: [t("Strain is required.")],
    },
    pedigree_link: {
      value: "",
      validation: ["validatePedigreeLink"],
      errors: [
        t("pedigree_link is required."),
        t("Please Enter valid pedigree link"),
      ],
    },
    registration_no: {
      value: "",
      validation: ["lettersNumber"],
      errors: [t("Incorrect format")],
    },
    microchip_number: {
      value: "",
      validation: ["lettersNumber"],
      errors: [t("Incorrect format")],
    },
    select_owner: {
      value: "",
      validation: [""],
      errors: [t("Owner is required")],
    },
    owner_id: {
      value: "",
      validation: ["requiredIf:select_owner:owner_id"],
      errors: [t("Owner is required.")],
    },
    owner: {
      value: "",
      validation: ["requiredIf:select_owner:owner", "letters"],
      errors: [t("Owner is required."), t("Incorrect format.")],
    },
    select_breeder: {
      value: "",
      validation: [],
      errors: [t("")],
    },
    breeder: {
      value: "",
      validation: ["requiredIf:select_breeder:breeder", "letters"],
      errors: [t("breeder is required."), t("Incorrect format.")],
    },
    breeder_id: {
      value: "",
      validation: ["requiredIf:select_breeder:breeder_id"],
      errors: [t("breeder is required.")],
    },
    select_location: {
      value: "",
      validation: [""],
      errors: [t("")],
    },
    location_id: {
      value: "",
      validation: ["requiredIf:select_location:location_id"],
      errors: [t("Location is required.")],
    },
    location: {
      value: "",
      validation: ["requiredIf:select_location:location", "letters"],
      errors: [t("Location is required."), t("Incorrect format")],
    },
    id: {
      value: "",
      validation: [],
      errors: [t("Id is required.")],
    },
    sold_amount: {
      value: "",
      validation: [""],
      errors: [t("Sold Amount is required.")],
    },

    date_of_sale: {
      value: moment().format("YYYY-MM-DD"),
      validation: [""],
      errors: [t("Date of Sale is required.")],
    },
    date_of_dead: {
      value: moment().format("YYYY-MM-DD"),
      validation: [""],
      errors: [t("Date of Death is required.")],
    },
    breeder_status: {
      value: "2",
      validation: [""],
      errors: [t("breeder_status is required.")],
    },
    status: {
      value: "",
      validation: ["required"],
      errors: [t("Status is required.")],
    },
  };
  const [horseData, setHorseData] = useState(INITIAL_HORSE_DATA);
  const { setLoading } = useLoading();
  const [isFormValidated, setIsFormValidated] = useState(false);
  let demo: any = {};
  const [horseDataError, setHorseDataError] = useState(demo);
  const prefrence = useSelector(
    (state: any) => state?.masterReducer?.data?.component?.preferance
  );
  const [sireList, setSireList] = useState([]);
  const [damList, setDamList] = useState([]);

  const dispatch = useDispatch();
  const setData = (field: string, value: string) => {
    let horseDetails = Object.assign({}, horseData);
    horseDetails[field].value = value;
    setHorseData({ ...horseDetails });
  };

  useEffect(() => {
    setHorseDataError({ ...validate(horseData)?.errors });
  }, [horseData]);

  const horseHandler = () => {
    if (validate(horseData).isValidated === true) {
      setLoading(true);
      let params: any = {};
      Object.keys(horseData).forEach((element) => {
        params[element] = horseData[element].value;
      });
      if(horseData?.status?.value != SOLD_ID){
        params["date_of_sale"] = '';
      }
      if(horseData?.status?.value != DEATH_ID){
        params["date_of_dead"] = '';
      }
      addEditHorse(params)
        .then((response: any) => {
          if (response.statusCode === SUCCESS_RESPONSE_CODE) {
            props.onHide();
            setHorseData(INITIAL_HORSE_DATA);
            setHorseDataError({});
            props.setrefreshlist();
          }
          setLoading(false);
        })
        .catch((error: any) => {})
        .finally(() => {
          setLoading(false);
        });
    } else {
      setLoading(false);
      setHorseDataError({ ...validate(horseData)?.errors });
      setIsFormValidated(true);
    }
  };

  const dateChange: DatePickerProps["onChange"] = (date, dateString) => {
    let dateIs: any =  date ? date?.format("YYYY-MM-DD") : null;
    setData("birthdate", dateIs);
  };

  const dateSaleChange: DatePickerProps["onChange"] = (date, dateString) => {
    let dateIs: any = date?.format("YYYY-MM-DD");
    setData("date_of_sale", dateIs);
  };

  const dateDeadChange: DatePickerProps["onChange"] = (date, dateString) => {
    let dateIs: any = date?.format("YYYY-MM-DD");
    setData("date_of_dead", dateIs);
  };

  const [sireToggle, setSireToggle] = useState("select");
  const [damToggle, setDamToggle] = useState("select");
  const [ownerToggle, setOwnerToggle] = useState("select");
  const [breederToggle, setBreederToggle] = useState("select");
  const [locationToggle, setLocationToggle] = useState("select");

  const toggleBtnValue = [
    { name: t("Yes"), value: "1" },
    { name: t("No"), value: "2" },
  ];

  const changeValidation = (field: string, value: string) => {
    let ob = Object.assign({}, horseData);
    ob[field].value = value;
    setHorseData({ ...ob });
  };

  useEffect(() => {
    if (horseData?.status?.value) {
      let ob = Object.assign({}, horseData);
      if (horseData?.status?.value == SOLD_ID) {
        ob.date_of_sale.validation[0] = "required";
        ob.sold_amount.validation[0] = "required";
      } else if (horseData?.status?.value == DEATH_ID) {
        ob.date_of_dead.validation[0] = "required";
      } else {
        ob.date_of_sale.validation[0] = "";
        ob.sold_amount.validation[0] = "";
        ob.date_of_dead.validation[0] = "";
      }
      setHorseData(ob);
    }
  }, [horseData?.status?.value]);

  useEffect(() => {
    if (props?.horsedata && props?.isedit) {
      Object.keys(horseData).forEach((element) => {
        if (props?.horsedata[element]) {
          setData(element , props?.horsedata[element])
        }
      });
      if (!props?.horsedata['sire_id']  && props?.horsedata['sire'] ) {
        setSireToggle("input");
        changeValidation("select_sire", "sire");
        setData("sire" , props?.horsedata['sire'])
      }
      else{
        setData("sire" , '')
      }
      if (!props?.horsedata['dam_id'] && props?.horsedata['dam'] ) {
        setDamToggle("input");
        changeValidation("select_dam", "dam");
        setData("dam" , props?.horsedata['dam'])
      }else{
        setData("dam" , '')
      }
      setData("breeder_status", props?.horsedata["breeder_status"]);
      setData("owner", "");
      setData("breeder", "");
      setData("location", "");
      
    }
  }, [props?.show ,props?.horsedata  ,  props?.isedit])
  
  useEffect(() => {
    if (props?.show === false) {
      setHorseDataError({});
      setHorseData(INITIAL_HORSE_DATA);
      setIsFormValidated(false);
      setSireToggle("select");
      setDamToggle("select");
      setOwnerToggle("select");
      setBreederToggle("select");
      setLocationToggle("select");
    } 
  }, [props?.show]);

  const toggleChange = (selectType: string, input: string) => {
    if (selectType === "select") {
      // setData(`select_${input}`, `${input}_id`)
      setData(input, "");
    } else {
      // setData(`select_${input}`, input)
      setData(`${input}_id`, "");
    }
  };

  const { metaData, setrefreshlist, isedit, ...rest } = props;
  return (
    <Modal {...rest} centered className="add-edit-horse-popup modal-784">
      <Modal.Header closeButton>
        <p className="tl">
          {props.isedit ? t("Edit Horse Information") : t("Add New Horse")}
        </p>
      </Modal.Header>
      <Modal.Body>
        <Row>
          <Col sm={6} md={6}>
            <InputGroups
              label={`${t("Name")}*`}
              type="text"
              value={horseData?.name?.value}
              autoComplete="off"
              onChange={(e: any) => setData("name", e.target.value)}
              error={
                isFormValidated && horseDataError["name"]
                  ? horseDataError["name"]
                  : null
              }
            />
          </Col>
          <Col sm={6} md={6}>
            <Datepicker
              allowClear={true}
              label={`${t("Date of birth")}`}
              endDate={moment().format("YYYY-MM-DD")}
              onChange={dateChange}
              notDisableToday={true}
              value={
                horseData?.birthdate?.value
                  ? moment(horseData?.birthdate?.value)
                  : undefined
              }
              type="text"
              autoComplete="off"
              error={
                isFormValidated && horseDataError["birthdate"]
                  ? horseDataError["birthdate"]
                  : null
              }
            />
          </Col>
          <Col sm={6} md={6}>
            <SelectGropus
              label={`${t("Sex")}*`}
              value={metaData?.gender}
              dropKey="id"
              dropVal="metadata"
              defaultSelect={horseData?.sex?.value}
              onChange={(e: any) => setData("sex", e.target.value)}
              error={
                isFormValidated && horseDataError["sex"]
                  ? horseDataError["sex"]
                  : null
              }
            />
          </Col>
          <Col sm={6} md={6}>
            <SelectGropus
              label={`${t("Colour")}`}
              value={metaData?.color}
              dropKey="id"
              dropVal="metadata"
              defaultSelect={horseData?.color?.value}
              onChange={(e: any) => setData("color", e.target.value)}
              error={
                isFormValidated && horseDataError["color"]
                  ? horseDataError["color"]
                  : null
              }
            />
          </Col>
          <Col sm={6} md={6}>
            <InputSelectGroup
              label={`${t("Sire")}`}
              selectValue={
                props?.isedit && props?.horsedata?.type === "male"
                  ? metaData?.sireList?.filter(
                      (item: any) => item?.id !== props?.horsedata?.id
                    )
                  : metaData?.sireList
              }
              inputSelectToggle={sireToggle}
              inputValue={horseData?.sire?.value}
              setInputSelectToggle={(val: any) => {
                setSireToggle(val);
                toggleChange(val, "sire");
              }}
              onSelectChange={(value: any) => {
                setData("sire_id", value);
              }}
              defaultSelect={horseData?.sire_id?.value}
              dropKey="id"
              dropVal="name"
              onInputChange={(e: any) => {
                setData("sire", e.target.value);
              }}
              error={
                isFormValidated &&
                (horseDataError["sire"] || horseDataError["sire_id"])
                  ? horseDataError["sire"] || horseDataError["sire_id"]
                  : null
              }
            />
          </Col>
          <Col sm={6} md={6}>
            <InputSelectGroup
              label={`${t("Dam")}`}
              selectValue={
                props?.isedit && props?.horsedata?.type === "female"
                  ? metaData?.damList?.filter(
                      (item: any) => item?.id !== props?.horsedata?.id
                    )
                  : metaData?.damList
              }
              inputSelectToggle={damToggle}
              inputValue={horseData?.dam?.value}
              setInputSelectToggle={(val: any) => {
                setDamToggle(val);
                toggleChange(val, "dam");
              }}
              onSelectChange={(value: any) => {
                setData("dam_id", value);
              }}
              defaultSelect={horseData?.dam_id?.value}
              dropKey="id"
              dropVal="name"
              onInputChange={(e: any) => {
                setData("dam", e.target.value);
              }}
              error={
                isFormValidated &&
                (horseDataError["dam"] || horseDataError["dam_id"])
                  ? horseDataError["dam"] || horseDataError["dam_id"]
                  : null
              }
            />
          </Col>
          <Col sm={6} md={6}>
            <SelectGropus
              label={`${t("Breed")}`}
              value={metaData?.breed}
              onChange={(e: any) => setData("breed", e.target.value)}
              dropKey="id"
              dropVal="metadata"
              defaultSelect={horseData?.breed?.value}
              error={
                isFormValidated && horseDataError["breed"]
                  ? horseDataError["breed"]
                  : null
              }
            />
          </Col>
          <Col sm={6} md={6}>
            <InputGroups
              label={`${t("Strain")}`}
              value={horseData?.strain?.value}
              type="text"
              autoComplete="off"
              onChange={(e: any) => setData("strain", e.target.value)}
              error={
                isFormValidated && horseDataError["strain"]
                  ? horseDataError["strain"]
                  : null
              }
            />
          </Col>
          <Col sm={12} md={6}>
            <InputGroups
              label={`${t(
                "Pedigree link (Import from www.allbreedpedigree.com)"
              )}`}
              value={horseData?.pedigree_link?.value}
              type="text"
              autoComplete="off"
              onChange={(e: any) => setData("pedigree_link", e.target.value)}
              error={
                isFormValidated && horseDataError["pedigree_link"]
                  ? horseDataError["pedigree_link"]
                  : null
              }
            />
          </Col>
        </Row>
        <Row>
          <Col sm={6} md={6}>
            <InputGroups
              label={`${t("Registration Number")}`}
              type="text"
              value={horseData?.registration_no?.value}
              autoComplete="off"
              onChange={(e: any) => setData("registration_no", e.target.value)}
              error={
                isFormValidated && horseDataError["registration_no"]
                  ? horseDataError["registration_no"]
                  : null
              }
            />
          </Col>
          <Col sm={6} md={6}>
            <InputGroups
              label={`${t("Microchip Number")}`}
              value={horseData?.microchip_number?.value}
              type="text"
              autoComplete="off"
              onChange={(e: any) => setData("microchip_number", e.target.value)}
              error={
                isFormValidated && horseDataError["microchip_number"]
                  ? horseDataError["microchip_number"]
                  : null
              }
            />
          </Col>
          <Col sm={6} md={6}>
            <InputSelectGroup
              label={`${t("Owner")}`}
              selectValue={metaData?.owner}
              inputSelectToggle={ownerToggle}
              inputValue={horseData?.owner?.value}
              setInputSelectToggle={(val: any) => {
                setOwnerToggle(val);
                toggleChange(val, "owner");
              }}
              onSelectChange={(value: any) => {
                setData("owner_id", value);
              }}
              defaultSelect={horseData?.owner_id?.value}
              dropKey="id"
              dropVal="first_name"
              onInputChange={(e: any) => {
                setData("owner", e.target.value);
              }}
              error={
                isFormValidated &&
                (horseDataError["owner"] || horseDataError["owner_id"])
                  ? horseDataError["owner"] || horseDataError["owner_id"]
                  : null
              }
            />
          </Col>
          <Col sm={6} md={6}>
            <InputSelectGroup
              label={`${t("Breeder")}`}
              selectValue={metaData?.breeder}
              inputSelectToggle={breederToggle}
              setInputSelectToggle={(val: any) => {
                setBreederToggle(val);
                toggleChange(val, "breeder");
              }}
              onSelectChange={(value: any) => {
                setData("breeder_id", value);
              }}
              defaultSelect={horseData?.breeder_id?.value}
              dropKey="id"
              dropVal="first_name"
              onInputChange={(e: any) => {
                setData("breeder", e.target.value);
              }}
              error={
                isFormValidated &&
                (horseDataError["breeder"] || horseDataError["breeder_id"])
                  ? horseDataError["breeder"] || horseDataError["breeder_id"]
                  : null
              }
            />
          </Col>
        </Row>
        <Row>
          <Col sm={6} md={6}>
            <InputSelectGroup
              label={`${t("Location")}`}
              selectValue={metaData?.location}
              inputSelectToggle={locationToggle}
              inputValue={horseData?.location?.value}
              setInputSelectToggle={(val: any) => {
                setLocationToggle(val);
                toggleChange(val, "location");
              }}
              onSelectChange={(value: any) => {
                setData("location_id", value);
              }}
              defaultSelect={horseData?.location_id?.value}
              dropKey="id"
              dropVal="name"
              onInputChange={(e: any) => {
                setData("location", e.target.value);
              }}
              error={
                isFormValidated &&
                (horseDataError["location"] || horseDataError["location_id"])
                  ? horseDataError["location"] || horseDataError["location_id"]
                  : null
              }
            />
          </Col>
          <Col sm={6} md={6}>
            <ToggleButton
              value={toggleBtnValue}
              label={`${t("Are you the Breeder?")}`}
              selected={`${horseData?.breeder_status?.value}`}
              setSelected={(value: any) => {
                setData("breeder_status", value);
              }}
            />
          </Col>
          <Col sm={6} md={6}>
            <SelectGropus
              label={`${t("Status")}*`}
              value={metaData?.horse_status}
              defaultSelect={horseData?.status?.value}
              dropKey="id"
              dropVal="metadata"
              onChange={(e: any) => {
                setData("status", e.target.value);
              }}
              error={
                isFormValidated && horseDataError["status"]
                  ? horseDataError["status"]
                  : null
              }
            />
          </Col>
        </Row>
        {horseData?.status?.value == SOLD_ID && (
          <Row>
            <Col sm={6} md={6}>
              <InputSelectMix
                label={`${t("Amount")}*`}
                inputType="number"
                inputValue={horseData?.sold_amount?.value}
                onInputChange={(e: any) =>
                  setData("sold_amount", e.target.value)
                }
                inputError={
                  isFormValidated && horseDataError["sold_amount"]
                    ? horseDataError["sold_amount"]
                    : null
                }
                inputSecondValue={prefrence?.currency_value}
              />
            </Col>
            <Col sm={6} md={6}>
              <Datepicker
                allowClear={true}
                label={`${t("Date of Sale")}*`}
                notDisableToday={true}
                endDate={moment().format("YYYY-MM-DD")}
                onChange={dateSaleChange}
                value={
                  horseData?.date_of_sale?.value
                    ? moment(horseData?.date_of_sale?.value)
                    : undefined
                }
                error={
                  isFormValidated && horseDataError["date_of_sale"]
                    ? horseDataError["date_of_sale"]
                    : null
                }
              />
            </Col>
          </Row>
        )}

        {horseData?.status?.value == DEATH_ID && (
          <Row>
            <Col sm={6} md={6}>
              <Datepicker
                allowClear={true}
                label={`${t("Date of Death")}*`}
                notDisableToday={true}
                endDate={moment().format("YYYY-MM-DD")}
                onChange={dateDeadChange}
                value={
                  horseData?.date_of_dead?.value
                    ? moment(horseData?.date_of_dead?.value)
                    : undefined
                }
                error={
                  isFormValidated && horseDataError["date_of_dead"]
                    ? horseDataError["date_of_dead"]
                    : null
                }
              />
            </Col>
          </Row>
        )}
      </Modal.Body>
      <Modal.Footer>
        <div className="footer-2-btn">
          <TextButton onClick={props.onHide}>{t("Cancel")}</TextButton>
          <CustomeButton
            bg="fill"
            onClick={() => horseHandler()}
            // disabled={Object.keys(validate(horseData)?.errors).length != 0}
          >
            {props.isedit ? t("Update") : t("Add")}
          </CustomeButton>
        </div>
      </Modal.Footer>
    </Modal>
  );
}
