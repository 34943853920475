export const HORSE_REQUEST = "HORSE_REQUEST";
export const HORSE_SUCCESS = "HORSE_SUCCESS";
export const HORSE_FAILD = "HORSE_FAILD";
export const HORSE_LONG = "HORSE_LONG";
export const HORSE_CLEAR = "HORSE_CLEAR";

export const ADD_EDIT_HORSE_REQUEST = "ADD_EDIT_HORSE_REQUEST";
export const ADD_EDIT_HORSE_SUCCESS = "ADD_EDIT_HORSE_SUCCESS";
export const ADD_EDIT_HORSE_FAILD = "ADD_EDIT_HORSE_FAILD";
export const ADD_EDIT_HORSE_LONG = "ADD_EDIT_HORSE_LONG";
export const ADD_EDIT_HORSE_CLEAR = "ADD_EDIT_HORSE_CLEAR";


export const HORSEDETAIL_REQUEST = "HORSEDETAIL_REQUEST";
export const HORSEDETAIL_SUCCESS = "HORSEDETAIL_SUCCESS";
export const HORSEDETAIL_FAILD = "HORSEDETAIL_FAILD";
export const HORSEDETAIL_LONG = "HORSEDETAIL_LONG";
export const HORSEDETAIL_CLEAR = "HORSEDETAIL_CLEAR";

export const HORSEINFO_REQUEST = "HORSEINFO_REQUEST";
export const HORSEINFO_SUCCESS = "HORSEINFO_SUCCESS";
export const HORSEINFO_FAILD = "HORSEINFO_FAILD";
export const HORSEINFO_LONG = "HORSEINFO_LONG";
export const HORSEINFO_CLEAR = "HORSEINFO_CLEAR";