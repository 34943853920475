import {
  ADD_HORSE_HEALTH_CLEAR,
  ADD_HORSE_HEALTH_FAILD,
  ADD_HORSE_HEALTH_LONG,
  ADD_HORSE_HEALTH_REQUEST,
  ADD_HORSE_HEALTH_SUCCESS,
  VIEW_HEALTH_REQUEST,
  VIEW_HEALTH_SUCCESS,
  VIEW_HEALTH_FAILD,
  VIEW_HEALTH_LONG,
  VIEW_HEALTH_CLEAR,
  DESTROY_HEALTH_REQUEST,
  DESTROY_HEALTH_SUCCESS,
  DESTROY_HEALTH_FAILD,
  DESTROY_HEALTH_LONG,
  DESTROY_HEALTH_CLEAR,
  HORSE_VITALSIGN_REQUEST,
  HORSE_VITALSIGN_SUCCESS,
  HORSE_VITALSIGN_FAILD,
  HORSE_VITALSIGN_LONG,
  HORSE_VITALSIGN_CLEAR,
  HORSE_VACCINE_REQUEST,
  HORSE_VACCINE_SUCCESS,
  HORSE_VACCINE_FAILD,
  HORSE_VACCINE_LONG,
  HORSE_VACCINE_CLEAR,
  HORSE_PARASITE_REQUEST,
  HORSE_PARASITE_SUCCESS,
  HORSE_PARASITE_FAILD,
  HORSE_PARASITE_LONG,
  HORSE_PARASITE_CLEAR,
  HORSE_DENTAL_REQUEST,
  HORSE_DENTAL_SUCCESS,
  HORSE_DENTAL_FAILD,
  HORSE_DENTAL_LONG,
  HORSE_DENTAL_CLEAR,
  HORSE_VET_CLINIC_REQUEST,
  HORSE_VET_CLINIC_SUCCESS,
  HORSE_VET_CLINIC_FAILD,
  HORSE_VET_CLINIC_LONG,
  HORSE_VET_CLINIC_CLEAR,
  HORSE_TREATMENTS_REQUEST,
  HORSE_TREATMENTS_SUCCESS,
  HORSE_TREATMENTS_FAILD,
  HORSE_TREATMENTS_LONG,
  HORSE_TREATMENTS_CLEAR,
  HORSE_LABTESTS_REQUEST,
  HORSE_LABTESTS_SUCCESS,
  HORSE_LABTESTS_FAILD,
  HORSE_LABTESTS_LONG,
  HORSE_LABTESTS_CLEAR,
  HORSE_XRAYS_REQUEST,
  HORSE_XRAYS_SUCCESS,
  HORSE_XRAYS_FAILD,
  HORSE_XRAYS_LONG,
  HORSE_XRAYS_CLEAR,
} from "./SingleHorseType";

export function singleHealthAddEditRedux(state = {}, action: any) {
  switch (action.type) {
    case ADD_HORSE_HEALTH_REQUEST:
      return { ...action.payload };
    case ADD_HORSE_HEALTH_SUCCESS:
      return { ...action.payload };
    case ADD_HORSE_HEALTH_FAILD:
      return { ...action.payload };
    case ADD_HORSE_HEALTH_LONG:
      return { ...action.payload };
    case ADD_HORSE_HEALTH_CLEAR:
      return {};
    default:
      return state;
  }
}

export function singleHealthViewRedux(state = {}, action: any) {
  switch (action.type) {
    case VIEW_HEALTH_REQUEST:
      return { ...action.payload };
    case VIEW_HEALTH_SUCCESS:
      return { ...action.payload };
    case VIEW_HEALTH_FAILD:
      return { ...action.payload };
    case VIEW_HEALTH_LONG:
      return { ...action.payload };
    case VIEW_HEALTH_CLEAR:
      return {};
    default:
      return state;
  }
}

export function singleHealthDeleteRedux(state = {}, action: any) {
  switch (action.type) {
    case DESTROY_HEALTH_REQUEST:
      return { ...action.payload };
    case DESTROY_HEALTH_SUCCESS:
      return { ...action.payload };
    case DESTROY_HEALTH_FAILD:
      return { ...action.payload };
    case DESTROY_HEALTH_LONG:
      return { ...action.payload };
    case DESTROY_HEALTH_CLEAR:
      return {};
    default:
      return state;
  }
}

export function singleHealthVitalSignRedux(state = {}, action: any) {
  switch (action.type) {
    case HORSE_VITALSIGN_REQUEST:
      return { ...action.payload };
    case HORSE_VITALSIGN_SUCCESS:
      return { ...action.payload };
    case HORSE_VITALSIGN_FAILD:
      return { ...action.payload };
    case HORSE_VITALSIGN_LONG:
      return { ...action.payload };
    case HORSE_VITALSIGN_CLEAR:
      return {};
    default:
      return state;
  }
}

export function singleHealthVaccineRedux(state = {}, action: any) {
  switch (action.type) {
    case HORSE_VACCINE_REQUEST:
      return { ...action.payload };
    case HORSE_VACCINE_SUCCESS:
      return { ...action.payload };
    case HORSE_VACCINE_FAILD:
      return { ...action.payload };
    case HORSE_VACCINE_LONG:
      return { ...action.payload };
    case HORSE_VACCINE_CLEAR:
      return {};
    default:
      return state;
  }
}

export function singleHealthParasiteRedux(state = {}, action: any) {
  switch (action.type) {
    case HORSE_PARASITE_REQUEST:
      return { ...action.payload };
    case HORSE_PARASITE_SUCCESS:
      return { ...action.payload };
    case HORSE_PARASITE_FAILD:
      return { ...action.payload };
    case HORSE_PARASITE_LONG:
      return { ...action.payload };
    case HORSE_PARASITE_CLEAR:
      return {};
    default:
      return state;
  }
}

export function singleHealthDentalRedux(state = {}, action: any) {
  switch (action.type) {
    case HORSE_DENTAL_REQUEST:
      return { ...action.payload };
    case HORSE_DENTAL_SUCCESS:
      return { ...action.payload };
    case HORSE_DENTAL_FAILD:
      return { ...action.payload };
    case HORSE_DENTAL_LONG:
      return { ...action.payload };
    case HORSE_DENTAL_CLEAR:
      return {};
    default:
      return state;
  }
}

export function singleHealthVetClinincRedux(state = {}, action: any) {
  switch (action.type) {
    case HORSE_VET_CLINIC_REQUEST:
      return { ...action.payload };
    case HORSE_VET_CLINIC_SUCCESS:
      return { ...action.payload };
    case HORSE_VET_CLINIC_FAILD:
      return { ...action.payload };
    case HORSE_VET_CLINIC_LONG:
      return { ...action.payload };
    case HORSE_VET_CLINIC_CLEAR:
      return {};
    default:
      return state;
  }
}

export function singleHealthTreatmentRedux(state = {}, action: any) {
  switch (action.type) {
    case HORSE_TREATMENTS_REQUEST:
      return { ...action.payload };
    case HORSE_TREATMENTS_SUCCESS:
      return { ...action.payload };
    case HORSE_TREATMENTS_FAILD:
      return { ...action.payload };
    case HORSE_TREATMENTS_LONG:
      return { ...action.payload };
    case HORSE_TREATMENTS_CLEAR:
      return {};
    default:
      return state;
  }
}

export function singleHealthLabTestsRedux(state = {}, action: any) {
  switch (action.type) {
    case HORSE_LABTESTS_REQUEST:
      return { ...action.payload };
    case HORSE_LABTESTS_SUCCESS:
      return { ...action.payload };
    case HORSE_LABTESTS_FAILD:
      return { ...action.payload };
    case HORSE_LABTESTS_LONG:
      return { ...action.payload };
    case HORSE_LABTESTS_CLEAR:
      return {};
    default:
      return state;
  }
}

export function singleHealthXraysRedux(state = {}, action: any) {
  switch (action.type) {
    case HORSE_XRAYS_REQUEST:
      return { ...action.payload };
    case HORSE_XRAYS_SUCCESS:
      return { ...action.payload };
    case HORSE_XRAYS_FAILD:
      return { ...action.payload };
    case HORSE_XRAYS_LONG:
      return { ...action.payload };
    case HORSE_XRAYS_CLEAR:
      return {};
    default:
      return state;
  }
}