import type { DatePickerProps } from "antd";
import moment from "moment";
import React, { useState, useEffect } from "react";
import { Col, Row } from "react-bootstrap";
import Modal from "react-bootstrap/Modal";
import CustomeButton from "../../Common/CustomButton/CustomButton";
import Datepicker from "../../Common/Datepicker/Datepicker";
import InputGroups from "../../Common/InputGroups/InputGroups";
import InputSelectMix from "../../Common/InputSelectMix/InputSelectMix";
import TextButton from "../../Common/TextButton/TextButton";
import ToggleButton from "../../Common/ToggleButton/ToggleButton";
import { useTranslation } from "react-i18next";
import validate from "../../../Utility/Validation";
import { addEditStaff, updatePayroll } from "../../../Redux/NonRedux/users";
import { SUCCESS_RESPONSE_CODE } from "../../../Constant/Status";
import { useSelector } from "react-redux";
import { useLoading } from "../../../context/LoadingContext";
export default function EditPayrollPopup(props: any) {
  const { t } = useTranslation();

  const INTIAL_PAYROLL_DATA = {
    id: {
      value: "",
    },
    status: {
      value: "",
      validation: ["required"],
      errors: [t("status is required.")],
    },
    month: {
      value: moment().format("YYYY-MM"),
      validation: ["required"],
      errors: [t("month is required.")],
    },
    amount: {
      value: "",
      validation: ["required"],
      errors: [t("Amount is required.")],
    },
  };

  const [payRollData, setPayrollData] = useState<any>(INTIAL_PAYROLL_DATA);
  const [isFormValidated, setIsFormValidated] = useState(false);
  const [payrollDataError, setPayrollDataError] = useState<any>({});
   const { setLoading} =  useLoading();

  const prefrence = useSelector(
    (state: any) => state?.masterReducer?.data?.component?.preferance
  );

  const setData = (field: string, value: any) => {
    const ob: any = Object.assign({}, payRollData);
    ob[field].value = value;
    setPayrollData({ ...ob });
  };

  useEffect(() => {
    setPayrollDataError({ ...validate(payRollData).errors });
  }, [payRollData]);

  const submitHandler = () => {
    if (validate(payRollData).isValidated === true) {
      let params: any = {};
      Object.keys(payRollData).forEach((element) => {
        if (payRollData[element].value) {
          params[element] = payRollData[element].value;
        }
      });
      setLoading(true);
      updatePayroll(params)
        .then((response: any) => {
          props.onHide();
          props.setRefreshList();
        })
        .catch((error: any) => {})
        .finally(() => {
          setLoading(false);
        });
    } else {
      setLoading(false);
      setPayrollDataError({ ...validate(payRollData)?.errors });
      setIsFormValidated(true);
    }
  };

  useEffect(() => {
    if (props.isedit && props?.editData) {
      setData("month", props?.editData?.month_db);
      setData(
        "amount",
        props?.editData?.payroll_amount);
      setData("id", props?.editData?.id);
      setData("status", props?.editData?.status);
    }
  }, [props?.editData]);

  useEffect(() => {
    if (props?.show === false) {
     setPayrollDataError({}); 
     setIsFormValidated(false);
    }
  }, [props?.show]);


  const { setRefreshList , editData , statusList , ...rest } = props;
  return (
    <Modal {...rest} centered className="add-staff-popup modal-784">
      <Modal.Header closeButton>
        <p className="tl">
        {t("Update payroll for")} {props?.editData?.first_name}{" "}
          {props?.editData?.last_name}
        </p>
      </Modal.Header>
      <Modal.Body>
        <Row>
          <Col sm={6} md={6}>
            <InputSelectMix
              label={`${t("Amount")}*`}
              type="number"
              onInputChange={(e: any) => setData("amount", e.target.value)}
              inputValue={payRollData?.amount?.value || ""}
              inputError={
                isFormValidated && payrollDataError["amount"]
                  ? payrollDataError["amount"]
                  : null
              }
              inputSecondValue={prefrence?.currency_value}
            />
          </Col>

          <Col sm={6} md={6}>
            <ToggleButton
              value={props?.statusList}
              toggleVal="value"
              toggleKey="key"
              label={`${t("Status")}`}
              selected={payRollData?.status?.value}
              setSelected={(val: string) => {
                setData("status", val);
              }}
            />
          </Col>
        </Row>
      </Modal.Body>
      <Modal.Footer>
        <div className="footer-2-btn">
          <TextButton onClick={props.onHide}> {t("Cancel")}</TextButton>
          <CustomeButton bg="fill" onClick={submitHandler}>
          {t("Update")}  
          </CustomeButton>
        </div>
      </Modal.Footer>
    </Modal>
  );
}
