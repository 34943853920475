import { globalDownloadAction, globalGetAction, globalPostAction } from "../../actions/globalActions";
import { END_POINTS } from "../../Constant/Api";
import { SHOW_MESSAGE } from "../../Constant/General";
import { getTokan } from "../../Interceptor/Interceptor";
import { showToast } from "../../Utility/General";

export function sampleHorseImport(params: any = {}, filename:any) {
  let apiUrl = END_POINTS.sampleHorseImport;
  return globalDownloadAction(params, apiUrl, filename);
}

// export function uploadHorseImport(params: any) {
//   let apiUrl = END_POINTS.uploadHorseImport;
//   return globalPostAction(params, apiUrl, SHOW_MESSAGE);
// }

export function uploadHorseImport(param: any) {
  let apiUrl = END_POINTS.uploadHorseImport;

  return new Promise((resolve, reject) => {
    var myHeaders = new Headers();
    myHeaders.append("Accept", "");
    myHeaders.append("Authorization", getTokan());
    let requestOptions: any = {
      method: "POST",
      body: param,
      headers: myHeaders,
      redirect: "follow",
    };
    fetch(apiUrl, requestOptions).then((response) => {
      let responseType = response?.headers.get("Content-Type");
      if (responseType == "application/json") {
        resolve(
          response.json().then((data) => {
            showToast("success", data.message);
            return {
              contentType: response.headers.get("Content-Type"),
              raw: data,
            };
          })
        );
      } else {
        resolve(
          response.blob().then((blob) => {
            return {
              contentType: response.headers.get("Content-Type"),
              raw: blob,
            };
          })
        );
      }
    });
  });
}
