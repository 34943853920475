import createCache from "@emotion/cache";
import { CacheProvider } from "@emotion/react";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import Tab from "@mui/material/Tab";
import Tabs from "@mui/material/Tabs";
import * as React from "react";
import { prefixer } from "stylis";
import rtlPlugin from "stylis-plugin-rtl";
import { LANGUAGE } from "../../../Constant/LocalStorage";

let inputLang: any = localStorage.getItem(LANGUAGE);

const theme = createTheme({
  direction: inputLang === "ar" ? "rtl" : "ltr"
});

const cacheRtl = createCache({
  key: "muirtl",
  stylisPlugins: [prefixer, rtlPlugin],
});

export default function InnerTab(props: any) {
  return (
    <div className="inner-tab">
      <CacheProvider value={cacheRtl}>
        <ThemeProvider theme={theme}>
          <Tabs
            value={props.value}
            onChange={props.handleChange}
            variant="scrollable"
            scrollButtons="auto"
            allowScrollButtonsMobile={true}
          >
            {props.parentTabData.map((items: any, index: number) => (
              <Tab
                key={index + Math.random()}
                icon={items.img}
                label={items.label}
                onClick={() => {
                  props.healthRouteChange(items.path);
                }}
              />
            ))}
          </Tabs>
        </ThemeProvider>
      </CacheProvider>
    </div>
  );
}

// import Tab from "@mui/material/Tab";
// import Tabs from "@mui/material/Tabs";
// import React from "react";

// export default function InnerTab(props: any) {

//   return (
//     <div className="inner-tab">
//       <Tabs
//         value={props.value}
//         onChange={props.handleChange}
//         variant="scrollable"
//         scrollButtons="auto"
//         allowScrollButtonsMobile={true}
//       >
//         {props.parentTabData.map((items: any, index: number) => (
//           <Tab key={index+Math.random()} icon={items.img} label={items.label} onClick={() => {props.healthRouteChange(items.path)}} />
//         ))}
//       </Tabs>
//     </div>
//   );
// }
