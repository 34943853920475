import React, { useEffect } from "react";
import { PERMISSION } from "../../Constant/General";
import { USER } from "../../Constant/LocalStorage";
import Loader from "../Loader/Loader";
import { logOutUser } from "../../Redux/NonRedux/account";

export default function Logout() {
  useEffect(() => {
    logOutUser()
      .then((data:any) => {
        localStorage.removeItem(USER);
        localStorage.removeItem(PERMISSION);
        window.location.replace(data?.component?.url ?? "https://aljuman-backend.magnetoinfotech.com/login");
      })
      .catch(() => {});
  }, []);
  return <Loader />;
}
