import { t } from 'i18next';
import React from 'react'
import { useOutletContext } from 'react-router-dom';
import binIcon from "../../../Assets/img/bin.svg";
import pencilIcon from "../../../Assets/img/pencil.svg";
import { useLoading } from '../../../context/LoadingContext';
import { viewBreedingFoal } from '../../../Redux/NonRedux/breeding';

export default function FoalDetails({
  listData,
  metaData,
  editHandler,
  serviceId
}: any) {

  const deleteHandler = (id: any) => {

  }

  return (
    <React.Fragment>
      <div className="data-tbl-wrapper">
        <div className="data-tbl-header">
          <p className="tl">{t("Foal Details")}</p>
        </div>
        <div className="data-tbl">
          <table>
            <thead>
              <tr>
                <th>
                  <p className="tm">{t("Name")}</p>
                </th>

                <th>
                  <p className="tm">{t("Date of Birth")}</p>
                </th>
                <th>
                  <p className="tm">{t("Sex")}</p>
                </th>
                <th>
                  <p className="tm">{t("Colour")}</p>
                </th>
                <th>
                  <p className="tm">{t("Sire")}</p>
                </th>
                <th>
                  <p className="tm">{t("Dam")}</p>
                </th>
                <th>
                  <p className="tm">{t("Breed")}</p>
                </th>
                <th>
                  <p className="tm">{t("Owner")}</p>
                </th>
                <th>
                  <p className="tm">{t("Breeder")}</p>
                </th>
                <th>
                  <p className="tm">{t("Location")}</p>
                </th>
                <th className="text-right">
                  <p className="tm">{t("Action")}</p>
                </th>
              </tr>
            </thead>
            <tbody>

              <tr >
                <td>
                  <p className="bl">{listData?.name}</p>
                </td>
                <td>
                  <p className="bl">{listData?.birthdate}</p>
                </td>
                <td>
                  <p className="bl">{listData?.sex_value}</p>
                </td>
                <td>
                  <p className="bl">{listData?.color_value}</p>
                </td>
                <td>
                  <p className="bl">{listData?.sire}</p>
                </td>
                <td>
                  <p className="bl">{listData?.dam}</p>
                </td>
                <td>
                  <p className="bl">{listData?.breed_value}</p>
                </td>
                <td>
                  <p className="bl">{listData?.owner_name}</p>
                </td>
                <td>
                  <p className="bl">{listData?.breeder}</p>
                </td>
                <td>
                  <p className="bl">{listData?.location_value}</p>
                </td>
                <td>
                  <div className="icon-wrapper">
                    {metaData?.activeService === serviceId && (
                      <a>
                        <img src={pencilIcon} alt="" onClick={() => editHandler(listData?.id)} />
                      </a>
                    )
                    }
                    {/* <a>
                        <img src={binIcon} onClick={() => deleteHandler(items?.id)}  alt="" />
                      </a> */}
                  </div>
                </td>
              </tr>

            </tbody>
          </table>
        </div>
      </div>
    </React.Fragment>
  )
}
