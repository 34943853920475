import React, { useContext, useEffect, useState } from "react";
import { Col, Row } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import DashboardActiveIcon from "../../Assets/img/dashboard-active.svg";
import { SIDEBAR } from "../../Constant/General";
import { useLoading } from "../../context/LoadingContext";
import { SidebarContext } from "../../context/SideBarContext";
import useDidMountEffect from "../../hooks/useDidMountEffect";
import {
  getDashboardData,
  getGraphData,
  getSalesData,
} from "../../Redux/NonRedux/dashboard";
import { graphMap } from "../../Utility/Configaration";
import { getComponentData, getGraphDataSet } from "../../Utility/General";
import LineChart from "../Common/Chart/LineChart";
import { VerticalBarChart } from "../Common/Chart/VerticalBarChart";
import SelectGropus from "../Common/SelectGroups/SelectGroups";
import DashboardSetupPopup from "../Popups/DashboardSetupPopup/DashboardSetupPopup";
import DashboardBox from "./DashboardBox";
import MultiSelectGroup from "../Common/MultiSelectGroup/MultiSelectGroup";
import { t } from "i18next";

export default function Dashboard() {
 
  const { setLoading } = useLoading();
  let navigate = useNavigate();
  const horseRouteChange = (pathName: any) => {
    navigate(pathName);
  };

  const dispatch = useDispatch();
  const modules = useSelector(
    (state: any) => state?.masterReducer?.data?.component?.modules
  );
  const { setSidebar }: any = useContext(SidebarContext);
  const [exportParams, setExportParams] = useState<any>({});
  useEffect(() => {
    setSidebar(SIDEBAR.DASHBOARD);
  }, []);

  // const handleExport = () => {
  //   if (!checkRoutePermission(PERMISSIONS?.EXPORT_FEATURE, modules)) {
  //     dispatch(upgradePopupAction({ show: true }));
  //     return;
  //   }
  //   setLoading(true);
  //   exportDocument(END_POINTS.boardingExport, exportParams, "dashboard")
  //     .then(() => {})
  //     .finally(() => {
  //       setLoading(false);
  //     });
  // };

  const [dashboardSetupModalShow, setdashboardSetupModalShow] = useState(false);

  const [keyMetrics, setkeyMetrics] = useState<any>([]);
  const [sales, setSales] = useState<any>([]);
  const [anaylytics, setAnalytics] = useState<any>([]);
  const [salesFilter, setSalesFilter] = useState<any>([]);
  const [selectedSales, setSelectedSales] = useState<any>();
  const [analyticsFilter, setAnalyticsFilter] = useState<any>([]);
  const [selectedAnalyticsFilter, setSelectedAnalyticsFilter] = useState<any>();

  const [analyticsInnerSelected, setAnalyticsInnerSelected] = useState<any>({
    filter: "",
    subfilter: [],
  });
  const [currentGraphData, setCurrentGraphData] = useState<any>({});
  const [allGraphData, setAllGraphData] = useState<any>([]);
  const [updateChart, setUpdateChart] = useState<any>(false);
  const [selectedSubFilter, setSelectedSubFilter] = useState<any>("");
  const [subFilterTitle , setSubFilterTitle] = useState("");

  const getDashboardApiCall = React.useCallback(async () => {
    try {
      setLoading(true);
      const response: any = await getDashboardData();
      const keyMetricsData : any = getComponentData(
        "key_matrics",
        response.component
      );
      const salesData : any = getComponentData("sales", response.component);
      const analyticsRes : any = getComponentData("analytics", response.component);
      
      setkeyMetrics(keyMetricsData);
      setSalesFilter(salesData?.filter);
      setSelectedSales(salesData?.filter?.[0]?.key);
      setAnalyticsFilter(analyticsRes?.filter);
      setSales(salesData);
      setAnalytics(analyticsRes);
      const defaultGraphKey = analyticsRes?.filter?.[0]?.key;
      const defaultGraphData = analyticsRes?.data?.find(
        (item: any) => item.key === defaultGraphKey
      );
      setAllGraphData(analyticsRes?.data);
      setSelectedAnalyticsFilter(defaultGraphKey);
      setCurrentGraphData(defaultGraphData);
    } catch (err) {
    } finally {
      setLoading(false);
    }
  }, []);

  const onSalesChange = React.useCallback( async (key: string) => {
    try {
      setLoading(true);
      setSelectedSales(key);
      const salesData: any = await getSalesData({ filter: key });
      setSales(salesData?.component);
    } catch (error) {
    } finally {
      setLoading(false);
    }
  },[]);
  

  useEffect(() => {
    getDashboardApiCall();
  }, []);

  const renderGraphBasedOnSelection = () => {
    let graph: any = graphMap.get(selectedAnalyticsFilter);
    if (graph) {
     
      let data :any= getGraphDataSet(graph.dataConfig, currentGraphData);
      let options = graph.option;
      let graphType = graph?.graph;
      // let dynamicOptions: any = structuredClone(graph.option);
      if (options?.scales?.y?.title?.text && options?.scales?.x?.title?.text) {
      }
      if (graphType === "line") {
        // options = {
        //   ...options,
        //   scales: {
        //     xAxis: {
        //       ...options.scales.xAxis,
        //       title: {
        //         ...options.scales.xAxis.title,
        //         text: currentGraphData?.title,
        //       },
        //     },
        //     yAxis: {
        //       ...options.scales.yAxis,
        //       title: {
        //         ...options.scales.yAxis.title,
        //         text: currentGraphData?.title,
        //       },
        //     },
        //   },
        // };
        return <LineChart options={options} data={data} />;
      } else if (graphType === "bar") {
        if(graph.type === "stacked"){
          let datasets  = data.datasets.map((item:any) => {
              return {...item,stack:'Stack 0',hoverBackgroundColor:item.backgroundColor}
            })
         
          data = {
            ...data , datasets
          }
        }
        // options = {
        //   ...options,
        //   scales: {
        //     x: {
        //       ...options.scales.x,
        //       title: {
        //         ...options.scales.x.title,
        //         text: currentGraphData?.title,
        //       },
        //     },
        //     y: {
        //       ...options.scales.y,
        //       title: {
        //         ...options.scales.y.title,
        //         text: currentGraphData?.title,
        //       },
        //     },
        //   },
        // };
        return <VerticalBarChart options={options} data={data} />;
      }
    }
  };

  const onGraphChange = (e: any) => {
    let value = e.target.value;
    setAnalyticsInnerSelected({
      filter: "",
      subfilter: [],
    });
    const graphData = allGraphData.find((item: any) => item.key === value);
    setCurrentGraphData(graphData);
    setSelectedAnalyticsFilter(value);
  };

  // const getInnerDefaultSelect = (key: string) => {
  //   return analyticsInnerSelected?.find((item: any) => item.key === key)?.value;
  // };

  const filterGraph = async () => {
    try {
      setLoading(true);
      const graphResponse: any = await getGraphData({
        type: currentGraphData?.key,
        filter: analyticsInnerSelected?.filter,
        subfilter: analyticsInnerSelected?.subfilter?.join(","),
      });
      setCurrentGraphData(graphResponse?.component[0]);
    } catch (error) {
    } finally {
      setLoading(false);
    }
  };

  useDidMountEffect(() => {
    filterGraph();
  }, [analyticsInnerSelected]);
  
  return (
    <>
      <div className="dashboard">
        {/* Page Title Start */}
        <div className="page-title-wrapper">
          <div className="title-first-menu">
            <div className="title-icon-text">
              <img src={DashboardActiveIcon} alt="" />
              <h5>{t("Dashboard")}</h5>
            </div>
          </div>
        </div>
        {/* Page Title End */}

        <div className="page-content">
          {keyMetrics?.data?.length ? (
            <div className="key-metrics-section">
              <div className="header-title-select">
                <h6>{keyMetrics?.title}</h6>
              </div>
              <Row>
                {keyMetrics?.data?.map((item: any) => (
                  <DashboardBox
                    key={item.key}
                    title={item.title}
                    value={item.value}
                    more_less={item.more_less}
                    more_less_value={item.more_less_value}
                    more_message={item.more_message}
                    less_message={item.less_message}
                  />
                ))}
              </Row>
            </div>
          ) : null}

          {sales?.data?.length ? (
            <div className="sales-section">
              <div className="header-title-select">
                <h6>{sales?.title}</h6>
                <SelectGropus
                  value={salesFilter}
                  dropKey="key"
                  dropVal="value"
                  removeSelect={true}
                  defaultSelect={selectedSales}
                  onChange={(e: any) => {
                    onSalesChange(e.target.value);
                  }}
                ></SelectGropus>
              </div>
              <Row>
                {sales?.data?.map((item: any) => (
                  <DashboardBox
                    key={item?.key}
                    title={item?.title}
                    removeMoreLess={selectedSales == ""}
                    value={item?.value}
                    more_less={item?.more_less}
                    more_less_value={item?.more_less_value}
                    more_message={item?.more_message}
                    less_message={item?.less_message}
                  />
                ))}
              </Row>
            </div>
          ) : null}

          <div className="chart-sections">
            <div className="header-title-select">
              <h6> {anaylytics.title} </h6>
              <SelectGropus
                dropKey="key"
                dropVal="value"
                removeSelect={true}
                value={analyticsFilter}
                defaultSelect={selectedAnalyticsFilter}
                onChange={onGraphChange}
              ></SelectGropus>
            </div>
            <Row>
              <Col sm={12}>
                <div className="card--layout">
                  <div className="card--header border-0">
                    <p className="tl"> {currentGraphData?.title} </p>
                    <div className="cart-filter-wrapper">
                      {currentGraphData?.subfilter ? (
                        <div className="multiselect-wpr">
                          <MultiSelectGroup
                            value={currentGraphData?.subfilter}
                            dropKey="key"
                            selectName={graphMap.get(currentGraphData.key)?.subFilterSelect || null}
                            // dropVal="value"
                            onChange={(val: any) =>
                              setAnalyticsInnerSelected({
                                ...analyticsInnerSelected,
                                subfilter: val,
                              })
                            }
                            defaultSelect={analyticsInnerSelected?.subfilter}
                          ></MultiSelectGroup>
                        </div>
                      ) : null}
                      <SelectGropus
                        value={currentGraphData?.filter}
                        dropKey="key"
                        dropVal="value"
                        removeSelect={true}
                        onChange={(e: any) =>
                          setAnalyticsInnerSelected({
                            ...analyticsInnerSelected,
                            filter: e.target.value,
                          })
                        }
                        defaultSelect={
                          analyticsInnerSelected?.filter ??
                          currentGraphData?.filter?.[0]?.value
                        }
                      ></SelectGropus>
                    </div>
                  </div>
                  <div className="card--body">
                    <div className="chart-height-3">
                      {renderGraphBasedOnSelection()}
                    </div>
                  </div>
                </div>
              </Col>
            </Row>
          </div>
        </div>
      </div>

      <DashboardSetupPopup
        show={dashboardSetupModalShow}
        onHide={() => setdashboardSetupModalShow(false)}
      />
    </>
  );
}
