import { randomBytes } from "crypto";
import { t } from "i18next";

const SelectGropus = (props: any) => {
  return (
    <div className="input-groups">
      {props.label ? (
        <label>{props.label}</label>
      ) : props.children ? (
        <div className="info-label">{props.children}</div>
      ) : (
        ""
      )}
      <select
        onChange={props.onChange}
        name={props.name}
        id={props.id}
        value={props.defaultSelect}
        className={props.error && "error"}
      >
        {props?.removeSelect ? null : (
          <>
            {props.selectOption ? (
              <option value="">{props.selectOption}</option>
            ) : (
              <option value="">{t("Select...")}</option>
            )}
          </>
        )}

        {props.value?.map((items: any, index: number) => (
          <option
            key={index + "select"}
            value={items[props.dropKey ? props.dropKey : "value"]}
          >
            {items[props.dropVal ? props.dropVal : "name"]}
          </option>
        ))}
      </select>
      {props.error && <span className="error">{props.error}</span>}
    </div>
  );
};

export default SelectGropus;
