import { Tooltip } from "@mui/material";
import React, { useContext, useEffect, useState } from "react";
import AddPlusIcon from "../../Assets/icon/AddPlusIcon";
import ExportIcon from "../../Assets/img/export.svg";
import FarrierIcon from "../../Assets/img/Farrier-primary.svg";
import FemaleIcon from "../../Assets/img/Female.svg";
import MaleIcon from "../../Assets/img/Male.svg";
import CustomeButton from "../Common/CustomButton/CustomButton";
import SearchBox from "../Common/SearchBox/SearchBox";
import Pagination from "../Layouts/Pagination/Pagination";
import AddBulkFarrierPopup from "../Popups/AddBulkFarrierPopup/AddBulkFarrierPopup";
import CheckCircleIcon from "../../Assets/img/check-circle-2.svg";
import { getbulkFarrierList } from "../../Redux/NonRedux/horse";
import { SUCCESS_RESPONSE_CODE } from "../../Constant/Status";
import { checkRoutePermission, encrDecrStr, getDefaultSorting, getListMonthCol } from "../../Utility/General";
import moment from "moment";
import { Link } from "react-router-dom";
import { HORSEDETAILS_FARRIER } from "../../Constant/Route";
import { useDispatch, useSelector } from "react-redux";
import { useLoading } from "../../context/LoadingContext";
import { SidebarContext } from "../../context/SideBarContext";
import { DATATABLE, PERMISSIONS, SIDEBAR } from "../../Constant/General";
import NoDataFound from "../Common/NoDataFound/NoDataFound";
import { t } from "i18next";
import { END_POINTS } from "../../Constant/Api";
import { exportDocument } from "../../Redux/globallActions";
import { upgradePopupAction } from "../../Redux/General/GeneralAction";
import { getListMetaDataApi } from "../../Redux/NonRedux/general";
import PregnantIcon from "../../Assets/img/Pregnant.svg";

export default function Farrier() {
  const defaultSorting : any = getDefaultSorting(DATATABLE.FARRIER_MAIN_LIST);
  const [AddBulkFarrierModalShow, setAddBulkFarrierModalShow] =
    React.useState(false);
  const [currentPage, setCurrentPage] = React.useState(1);
  const [currentPageSize, setCurrentPageSize] = React.useState(10);
  const [refreshList, setRefreshList] = useState<boolean>(false);
  const [farrierList, setFarrierList] = useState<any>([]);
  const [listHeaderMonth, setListHeaderMonth] = React.useState<any>();
  const [recordsTotal, setRecordsTotal] = React.useState(1);
  const [searchText, setSearchText] = React.useState("");
  const [checkedRec, setCheckedRec] = useState<number[]>([]);
  const { setLoading } = useLoading();
  const { setSidebar }: any = useContext(SidebarContext);
  const [exportParams, setExportParams] = useState<any>({});
  const prefrence = useSelector(
    (state: any) => state?.masterReducer?.data?.component?.preferance
  );
  const [sortBy, setSortBy] = React.useState(defaultSorting?.value);
  const [sortByField, setSortByField] = useState(defaultSorting?.key);
  const [sortType, setSortType] = useState(defaultSorting?.type);
  const [orderList, setOrderList] = useState<any>([]);
  const [metaData, setMetaData] = useState({});
  const dispatch = useDispatch();
  const modules = useSelector(
   (state: any) => state?.masterReducer?.data?.component?.modules
 );
  const listApiCall = () => {
    let params : any = { page: currentPage, limit: currentPageSize, search: searchText };
    if (sortBy && sortType) {
      params["sort"] = {
        column: sortBy,
        type: sortType,
      };
    }
    setExportParams(params);
    setLoading(true);
    getbulkFarrierList(params)
      .then((response: any) => {
        if (response.statusCode === SUCCESS_RESPONSE_CODE) {
          setFarrierList(response?.component?.listing);
          setRecordsTotal(response?.component?.recordsFiltered);
          setOrderList(response?.component?.orderlist);
        }
        setLoading(false);
      })
      .catch((error: any) => { setLoading(false) });
  };

  const getMetaData = () => {
    setLoading(true);  
    getListMetaDataApi(END_POINTS.metaFarrier , {type:"multiple"})
      .then((response: any) => {
        setMetaData(response?.component);
      })
      .catch((error: any) => {})
      .finally(() => {
        setLoading(false);
      });
  };

  useEffect(() => {
    listApiCall();
  }, [currentPage, currentPageSize, searchText, sortBy, sortType]);

  useEffect(() => {
    setSidebar(SIDEBAR.FARRIER);
    let headerMonth = getListMonthCol();
    setListHeaderMonth(headerMonth);
    getMetaData();
  }, []);

  useEffect(() => {
    if (refreshList) {
      listApiCall();
      setRefreshList(false);
    }
  }, [refreshList]);

  const handleExport = () => {
    if(!checkRoutePermission(PERMISSIONS?.EXPORT_FEATURE , modules)){
       upgradePopupAction({show:true});
      return;
    }
    setLoading(true);
    exportDocument(END_POINTS.farrierExport, exportParams, "farrier")
      .then(() => {})
      .finally(() => {
        setLoading(false);
      });
  };
  
  const checkSingleRec = (e: any, val: number) => {
    var checkedRecData: number[] = Object.assign([], checkedRec);
    if (e.target.checked) {
      checkedRecData.push(val);
    } else {
      checkedRecData = checkedRecData.filter((c: number) => c !== val);
    }
    setCheckedRec(checkedRecData);
  };

  const selectAll = (e: any) => {
    let checkedRecord = [];
    let ids = farrierList.map((item: any) => item.id);
    if (e.target.checked) {
      checkedRecord = ids;
    } else {
      checkedRecord = [];
    }
    setCheckedRec(checkedRecord);
  }

  const horseDetailLink = (id: any) => {
    return HORSEDETAILS_FARRIER + "/" + encrDecrStr(id, "encr");
  };

  const sortrecords = (fieldName: any) => {
    if (orderList?.[fieldName]) {
      let sortByNow = orderList[fieldName];
      if (sortBy == sortByNow) {
        let sortTypeNow = sortType == "asc" ? "desc" : "asc";
        setSortType(sortTypeNow);
      } else {
        setSortBy(sortByNow);
        setSortByField(fieldName);
        setSortType("asc");
      }
    }
  };

  const sortableClass = (fieldName: string): string => {
    if (orderList?.[fieldName]) {
      return "sortable";
    } else {
      return "";
    }
  };

  const sortingTypeClass = (fieldName: string): string => {
    if (sortByField === fieldName) {
      return sortType == "asc" ? "arrow-up" : "arrow-down";
    } else {
      return "";
    }
  };

  const getIconbyType = (type: any) => {
    if (type === "male") {
      return MaleIcon;
    } else if (type === "female") {
      return FemaleIcon;
    } else if (type === "pregnant") {
      return PregnantIcon;
    }
  };


  const tooltipValue = (tooltipObj: any) => {
    return (
      <div className="info-table tooltip-tbl">
        <table>
          <tbody>
            <tr>
              <td>
                <p className="tm">{t("Date")}</p>
              </td>
              <td>
                <p className="tm blur-color">{tooltipObj.dates}</p>
              </td>
            </tr>
            <tr>
              <td>
                <p className="tm">{t("Farrier")}</p>
              </td>
              <td>
                <p className="tm blur-color">{tooltipObj.farrier}</p>
              </td>
            </tr>
            <tr>
              <td>
                <p className="tm">{t("Shoieng Type")}</p>
              </td>
              <td>
                <p className="tm blur-color">{tooltipObj.shoeing_type_value}</p>
              </td>
            </tr>
            {tooltipObj?.shoe_material && tooltipObj?.shoe_material != "" && (
              <tr>
                <td>
                  <p className="tm">{t("Shoe material")}</p>
                </td>
                <td>
                  <p className="tm blur-color">
                    {tooltipObj?.shoe_material_value}
                  </p>
                </td>
              </tr>
            )}
            <tr>
              <td>
                <p className="tm">{t("Amount")}</p>
              </td>
              <td>
                <p className="tm blur-color">
                  {tooltipObj?.amount}{` ${prefrence?.currency_value}`}
                </p>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    );
  };

  console.log(listHeaderMonth , "listHeaderMonth")
  return (
    <>

      <div className="health-veccination-page search-and-btn">
        {/* Page Title Start */}
        <div className="page-title-wrapper">
          <div className="title-first-menu">
            <div className="title-icon-text">
              <img src={FarrierIcon} alt="" />
              <h5>{t("Farrier")}</h5>
            </div>
            <div className="title-right-icons">
                <a className="unset-links" onClick={handleExport}>
                  <img src={ExportIcon} alt="" />
                </a>
            </div>
          </div>
        </div>
        {/* Page Title End */}

        <div className="page-content">
          <div className="data-wrapper">
            <div className="data-header">
              <SearchBox
                placeholder={t("Search Horse...")}
                onChange={(e: any) => setSearchText(e.target.value)}
                value={searchText}
              />

              <div className="right-section">
                <div className="btn-wrapper fb">
                  {/* <CustomeButton>
                    <Filtericon />
                    <p>Filter</p>
                  </CustomeButton> */}
                  <CustomeButton
                    bg="fill"
                    onClick={() => setAddBulkFarrierModalShow(true)}
                  >
                    <AddPlusIcon 
                    />
                    <p>{t("Add")}</p>
                  </CustomeButton>
                </div>
              </div>
            </div>
            <div className="data-body">
              <div className="img-responsive-table first-2-column-fix-web">
                <table>
                  <thead>
                    <tr>
                      {/* <th className="ck-wrapper">
                        <OnlyCk onChange={selectAll} />
                      </th> */}
                      <th className="first-img-wrapper"></th>
                      <th>
                        <p className={`tm icon-text ${sortableClass(
                            "name"
                          )} ${sortingTypeClass("name")}`} 
                          onClick={() => sortrecords("name")}>{t("Horse")}</p>
                      </th>
                      {listHeaderMonth?.map((items: any, index: number) => (
                        <th key={index + "listHeader"}>
                          <p className="tm MMW-100">
                            {`${moment(items).format("MMM")} ${moment(items).format("YYYY")}`}
                          </p>
                        </th>
                      ))}
                      {/* <th className="text-right">
                        <p className="tm">{t("Action")}</p>
                      </th> */}
                    </tr>
                  </thead>
                  <tbody>
                    {farrierList?.map((items: any, index: number) => (
                      <tr key={index + "myhorse-tbl"}>
                        {/* <td className="ck-wrapper">
                          <OnlyCk
                            checked={checkedRec.includes(items?.id)}
                            disabled={
                              checkedRec.includes(items.id)
                                ? false
                                : true
                            }
                            onChange={(e: any) =>
                              checkSingleRec(e, items?.id)
                            }
                          />
                        </td> */}
                        <td className="first-img-wrapper">
                          <img
                            src={items.profile}
                            alt=""
                            className="horse-img"
                          />
                        </td>
                        <td>
                          <div className="icon-text">
                            <img
                             src={getIconbyType(items?.type_slug)}
                              alt=""
                            />
                            <Link to={horseDetailLink(items.id)} className="a-bl">
                              {items.horse_name}
                            </Link>
                          </div>
                        </td>

                        {listHeaderMonth?.map((itemsIs: any, index2: number) =>
                          items?.health?.[moment(itemsIs).format("YYYY-MM")]?.length ? (

                            <td key={index + index2 + "list"}>
                              {items.health[
                                moment(itemsIs).format("YYYY-MM")
                              ]?.map((itemTip: any, index3: number) => (
                                <Tooltip
                                  title={tooltipValue(itemTip)}
                                  arrow={true}
                                  key={index + index2 + index3}
                                >
                                  <img
                                    src={CheckCircleIcon}
                                    className="icon-24"
                                  />
                                </Tooltip>
                              ))}
                            </td>
                          ) : (
                            <td key={index + index2 + "myhorse-tbltd"}></td>
                          )
                        )}
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
              {farrierList?.length ? (
                <Pagination
                  results={recordsTotal}
                  currentPage={currentPage}
                  setCurrentPage={setCurrentPage}
                  currentLength={currentPageSize}
                  setCurrentPageSize={setCurrentPageSize}
                />
              ) : (
                <NoDataFound />
              )}
            </div>
          </div>
        </div>
      </div>

      <AddBulkFarrierPopup
      metaData={metaData}
        show={AddBulkFarrierModalShow}
        onHide={() => setAddBulkFarrierModalShow(false)}
        setrefreshlist={() => {
          setRefreshList(true);
          getMetaData();
        }}
      />
    </>
  );
}
