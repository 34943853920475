import React from "react";

export default function FileEditIcon() {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M9.88143 21H4.31V3H18.3457V11.1429"
        stroke="#919EAB"
        strokeWidth="1.5"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M12.56 21L13.4171 17.6786L16.9529 14.1428C17.5957 13.5 18.56 13.5 19.2029 14.1428C19.8457 14.7857 19.8457 15.75 19.2029 16.3928L15.6671 19.9286L12.56 21Z"
        stroke="#919EAB"
        strokeWidth="1.5"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M7.31 9.96429L10.0957 12.75L15.2386 6.96429"
        stroke="#919EAB"
        strokeWidth="1.5"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}
