export const MASTER_REQUEST = "MASTER_REQUEST";
export const MASTER_SUCCESS = "MASTER_SUCCESS";
export const MASTER_FAILD = "MASTER_FAILD";
export const MASTER_LONG = "MASTER_LONG";

export const MINI_CART_TRUE = "MINI_CART_TRUE";
export const MINI_CART_FALSE = "MINI_CART_FALSE";

export const DEFAULT_PARAMETER = "DEFAULT_PARAMETER";

export const USER_LANGUAGE_REQUEST = "USER_LANGUAGE_REQUEST";
export const USER_LANGUAGE_SUCCESS = "USER_LANGUAGE_SUCCESS";
export const USER_LANGUAGE_FAILD = "USER_LANGUAGE_FAILD";
export const USER_LANGUAGE_LONG = "USER_LANGUAGE_LONG";

export const CLINIC_CONTACT_REQUEST = "CLINIC_CONTACT_REQUEST";
export const CLINIC_CONTACT_SUCCESS = "CLINIC_CONTACT_SUCCESS";
export const CLINIC_CONTACT_FAILD = "CLINIC_CONTACT_FAILD";
export const CLINIC_CONTACT_LONG = "CLINIC_CONTACT_LONG";

export const HORSE_OWNER_CONTACT_REQUEST = "HORSE_OWNER_CONTACT_REQUEST";
export const HORSE_OWNER_CONTACT_SUCCESS = "HORSE_OWNER_CONTACT_SUCCESS";
export const HORSE_OWNER_CONTACT_FAILD = "HORSE_OWNER_CONTACT_FAILD";
export const HORSE_OWNER_CONTACT_LONG = "HORSE_OWNER_CONTACT_LONG";

export const HORSE_BREEDER_CONTACT_REQUEST = "HORSE_BREEDER_CONTACT_REQUEST";
export const HORSE_BREEDER_CONTACT_SUCCESS = "HORSE_BREEDER_CONTACT_SUCCESS";
export const HORSE_BREEDER_CONTACT_FAILD = "HORSE_BREEDER_CONTACT_FAILD";
export const HORSE_BREEDER_CONTACT_LONG = "HORSE_BREEDER_CONTACT_LONG";

export const META_BREED_REQUEST = "META_BREED_REQUEST";
export const META_BREED_SUCCESS = "META_BREED_SUCCESS";
export const META_BREED_FAILD = "META_BREED_FAILD";
export const META_BREED_LONG = "META_BREED_LONG";

export const META_VACCINES_REQUEST = "META_VACCINES_REQUEST";
export const META_VACCINES_SUCCESS = "META_VACCINES_SUCCESS";
export const META_VACCINES_FAILD = "META_VACCINES_FAILD";
export const META_VACCINES_LONG = "META_VACCINES_LONG";

export const META_DEWORMER_REQUEST = "META_DEWORMER_REQUEST";
export const META_DEWORMER_SUCCESS = "META_DEWORMER_SUCCESS";
export const META_DEWORMER_FAILD = "META_DEWORMER_FAILD";
export const META_DEWORMER_LONG = "META_DEWORMER_LONG";

export const META_COLOR_REQUEST = "META_COLOR_REQUEST";
export const META_COLOR_SUCCESS = "META_COLOR_SUCCESS";
export const META_COLOR_FAILD = "META_COLOR_FAILD";
export const META_COLOR_LONG = "META_COLOR_LONG";

export const META_GENDER_REQUEST = "META_GENDER_REQUEST";
export const META_GENDER_SUCCESS = "META_GENDER_SUCCESS";
export const META_GENDER_FAILD = "META_GENDER_FAILD";
export const META_GENDER_LONG = "META_GENDER_LONG";

export const META_HORSE_STATUS_REQUEST = "META_HORSE_STATUS_REQUEST";
export const META_HORSE_STATUS_SUCCESS = "META_HORSE_STATUS_SUCCESS";
export const META_HORSE_STATUS_FAILD = "META_HORSE_STATUS_FAILD";
export const META_HORSE_STATUS_LONG = "META_HORSE_STATUS_LONG";

export const META_DIET_CATEGORY_REQUEST = "META_DIET_CATEGORY_REQUEST";
export const META_DIET_CATEGORY_SUCCESS = "META_DIET_CATEGORY_SUCCESS";
export const META_DIET_CATEGORY_FAILD = "META_DIET_CATEGORY_FAILD";
export const META_DIET_CATEGORY_LONG = "META_DIET_CATEGORY_LONG";

export const META_CURRENCY_REQUEST = "META_CURRENCY_REQUEST";
export const META_CURRENCY_SUCCESS = "META_CURRENCY_SUCCESS";
export const META_CURRENCY_FAILD = "META_CURRENCY_FAILD";
export const META_CURRENCY_LONG = "META_CURRENCY_LONG";

export const META_UNIT_REQUEST = "META_UNIT_REQUEST";
export const META_UNIT_SUCCESS = "META_UNIT_SUCCESS";
export const META_UNIT_FAILD = "META_UNIT_FAILD";
export const META_UNIT_LONG = "META_UNIT_LONG";

export const META_SHOEING_TYPES_REQUEST = "META_SHOEING_TYPES_REQUEST";
export const META_SHOEING_TYPES_SUCCESS = "META_SHOEING_TYPES_SUCCESS";
export const META_SHOEING_TYPES_FAILD = "META_SHOEING_TYPES_FAILD";
export const META_SHOEING_TYPES_LONG = "META_SHOEING_TYPES_LONG";
export const META_SHOEING_TYPES_CLEAR = "META_SHOEING_TYPES_CLEAR";

export const META_SHOE_MATERIAL_REQUEST = "META_SHOE_MATERIAL_REQUEST";
export const META_SHOE_MATERIAL_SUCCESS = "META_SHOE_MATERIAL_SUCCESS";
export const META_SHOE_MATERIAL_FAILD = "META_SHOE_MATERIAL_FAILD";
export const META_SHOE_MATERIAL_LONG = "META_SHOE_MATERIAL_LONG";
export const META_SHOE_MATERIAL_CLEAR = "META_SHOE_MATERIAL_CLEAR";


export const FARRIER_CONTACT_REQUEST = "FARRIER_CONTACT_REQUEST";
export const FARRIER_CONTACT_SUCCESS = "FARRIER_CONTACT_SUCCESS";
export const FARRIER_CONTACT_FAILD = "FARRIER_CONTACT_FAILD";
export const FARRIER_CONTACT_LONG = "FARRIER_CONTACT_LONG";
export const FARRIER_CONTACT_CLEAR = "FARRIER_CONTACT_CLEAR";

export const META_DISCIPLINE_REQUEST = "META_DISCIPLINE_REQUEST";
export const META_DISCIPLINE_SUCCESS = "META_DISCIPLINE_SUCCESS";
export const META_DISCIPLINE_FAILD = "META_DISCIPLINE_FAILD";
export const META_DISCIPLINE_LONG = "META_DISCIPLINE_LONG";
export const META_DISCIPLINE_CLEAR = "META_DISCIPLINE_CLEAR";

export const GET_COUNTRY_LIST_REQUEST = "GET_COUNTRY_LIST_REQUEST";
export const GET_COUNTRY_LIST_SUCCESS = "GET_COUNTRY_LIST_SUCCESS";
export const GET_COUNTRY_LIST_FAILD = "GET_COUNTRY_LIST_FAILD";
export const GET_COUNTRY_LIST_LONG = "GET_COUNTRY_LIST_LONG";
export const GET_COUNTRY_LIST_CLEAR = "GET_COUNTRY_LIST_CLEAR";

export const SIRE_LIST_REQUEST = "SIRE_LIST_REQUEST";
export const SIRE_LIST_SUCCESS = "SIRE_LIST_SUCCESS";
export const SIRE_LIST_FAILD = "SIRE_LIST_FAILD";
export const SIRE_LIST_LONG = "SIRE_LIST_LONG";

export const DAM_LIST_REQUEST = "DAM_LIST_REQUEST";
export const DAM_LIST_SUCCESS = "DAM_LIST_SUCCESS";
export const DAM_LIST_FAILD = "DAM_LIST_FAILD";
export const DAM_LIST_LONG = "DAM_LIST_LONG";

export const LOCATION_LIST_REQUEST = "LOCATION_LIST_REQUEST";
export const LOCATION_LIST_SUCCESS = "LOCATION_LIST_SUCCESS";
export const LOCATION_LIST_FAILD = "LOCATION_LIST_FAILD";
export const LOCATION_LIST_LONG = "LOCATION_LIST_LONG";

export const META_HORSE_LIST_REQUEST = "META_HORSE_LIST_REQUEST";
export const META_HORSE_LIST_SUCCESS = "META_HORSE_LIST_SUCCESS";
export const META_HORSE_LIST_FAILD = "META_HORSE_LIST_FAILD";
export const META_HORSE_LIST_LONG = "META_HORSE_LIST_LONG";

export const META_TRAINING_TYPES_REQUEST = "META_TRAINING_TYPES_REQUEST";
export const META_TRAINING_TYPES_SUCCESS = "META_TRAINING_TYPES_SUCCESS";
export const META_TRAINING_TYPES_FAILD = "META_TRAINING_TYPES_FAILD";
export const META_TRAINING_TYPES_LONG = "META_TRAINING_TYPES_LONG";

export const META_INCOME_CATEGORY_REQUEST = "META_INCOME_CATEGORY_REQUEST";
export const META_INCOME_CATEGORY_SUCCESS = "META_INCOME_CATEGORY_SUCCESS";
export const META_INCOME_CATEGORY_FAILD = "META_INCOME_CATEGORY_FAILD";
export const META_INCOME_CATEGORY_LONG = "META_INCOME_CATEGORY_LONG";
export const META_INCOME_CATEGORY_CLEAR = "META_INCOME_CATEGORY_CLEAR";

export const META_BREEDING_SERVICE_TYPE_REQUEST = "META_BREEDING_SERVICE_TYPE_REQUEST";
export const META_BREEDING_SERVICE_TYPE_SUCCESS = "META_BREEDING_SERVICE_TYPE_SUCCESS";
export const META_BREEDING_SERVICE_TYPE_FAILD = "META_BREEDING_SERVICE_TYPE_FAILD";
export const META_BREEDING_SERVICE_TYPE_LONG = "META_BREEDING_SERVICE_TYPE_LONG";
export const META_BREEDING_SERVICE_TYPE_CLEAR = "META_BREEDING_SERVICE_TYPE_CLEAR";

export const REMINDER_STATUS_CHANGE_REQUEST = "REMINDER_STATUS_CHANGE_REQUEST";
export const REMINDER_STATUS_CHANGE_SUCCESS = "REMINDER_STATUS_CHANGE_SUCCESS";
export const REMINDER_STATUS_CHANGE_FAILD = "REMINDER_STATUS_CHANGE_FAILD";
export const REMINDER_STATUS_CHANGE_CLEAR = "REMINDER_STATUS_CHANGE_CLEAR";
export const REMINDER_ADDED = "REMINDER_ADDED";

export const SET_USER_DETAILS = "SET_USER_DETAILS";
export const CHANGE_UPGRADE_SUBSCRIPTION_POPUP_STATUS = "CHANGE_UPGRADE_SUBSCRIPTION_POPUP_STATUS";
