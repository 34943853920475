import React from "react";

export default function Filtericon() {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M3.64102 7.43588V14"
        stroke="#212B36"
        strokeWidth="1.5"
        strokeMiterlimit="10"
        strokeLinecap="round"
      />
      <path
        d="M3.64102 2V3.64103"
        stroke="#212B36"
        strokeWidth="1.5"
        strokeMiterlimit="10"
        strokeLinecap="round"
      />
      <path
        d="M4.25641 7.23075H3.02564C2.41026 7.23075 2 6.82049 2 6.20511V4.87177C2 4.25639 2.41026 3.84613 3.02564 3.84613H4.35897C4.97436 3.84613 5.38462 4.25639 5.38462 4.87177V6.20511C5.28205 6.82049 4.8718 7.23075 4.25641 7.23075Z"
        stroke="#212B36"
        strokeWidth="1.5"
        strokeMiterlimit="10"
        strokeLinecap="round"
      />
      <path
        d="M12.4615 7.43588V14"
        stroke="#212B36"
        strokeWidth="1.5"
        strokeMiterlimit="10"
        strokeLinecap="round"
      />
      <path
        d="M12.4615 2V3.64103"
        stroke="#212B36"
        strokeWidth="1.5"
        strokeMiterlimit="10"
        strokeLinecap="round"
      />
      <path
        d="M13.0769 7.23075H11.7436C11.1282 7.23075 10.718 6.82049 10.718 6.20511V4.87177C10.718 4.25639 11.1282 3.84613 11.7436 3.84613H13.0769C13.6923 3.84613 14.1026 4.25639 14.1026 4.87177V6.20511C14.1026 6.82049 13.6923 7.23075 13.0769 7.23075Z"
        stroke="#212B36"
        strokeWidth="1.5"
        strokeMiterlimit="10"
        strokeLinecap="round"
      />
      <path
        d="M8.05127 8.46154V2"
        stroke="#212B36"
        strokeWidth="1.5"
        strokeMiterlimit="10"
        strokeLinecap="round"
      />
      <path
        d="M8.05127 14V12.2564"
        stroke="#212B36"
        strokeWidth="1.5"
        strokeMiterlimit="10"
        strokeLinecap="round"
      />
      <path
        d="M7.33332 8.66666H8.66665C9.28204 8.66666 9.69229 9.07691 9.69229 9.6923V11.0256C9.69229 11.641 9.28204 12.0513 8.66665 12.0513H7.33332C6.71793 12.0513 6.30768 11.641 6.30768 11.0256V9.6923C6.30768 9.07691 6.8205 8.66666 7.33332 8.66666Z"
        stroke="#212B36"
        strokeWidth="1.5"
        strokeMiterlimit="10"
        strokeLinecap="round"
      />
    </svg>
  );
}
