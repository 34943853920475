import React from "react";
import Modal from "react-bootstrap/Modal";
import { Estimated } from "../../../interface/Breeding.interface";
import { t } from "i18next";

interface Props {
  show: boolean;
  onHide: () => void;
  estimated: Estimated[];
}

export default function PregnantMaresPopup(props: Props) {
  const { estimated }: Props = props;

  return (
    <Modal {...props} centered className="pregnant-mares-popup modal-416">
      <Modal.Header closeButton>
        <p className="tl">{t("Pregnant Mares")}</p>
      </Modal.Header>
      <Modal.Body>
        <div className="pregnant-progress-wrapper">
          {estimated.length ? (
            estimated?.map((items: any, index: number) => (
              <div className="pregnant-progress-box" key={index + "PPB"}>
                <p className="tm">{items.name}</p>
                <div className="pregnant-progress">
                  <div
                    className="pregnant-progress-line"
                    style={{ width: items.percents + "%" }}
                  ></div>
                  <p className="ll">
                    {items.pregnantDays} {items.pregnantDaysText}
                  </p>
                  <p className="ll">
                    {items.remainingDays} {items.remainingDaysText}
                  </p>
                </div>
              </div>
            ))
          ) : (
            <p className="no-data-wrapper ts blur-color">
              {t("No Data Found")}
            </p>
          )}
        </div>
      </Modal.Body>
    </Modal>
  );
}
