import React, { useEffect, useState } from "react";
import { BrowserRouter, Route, Routes, useNavigate } from "react-router-dom";
import Boarding from "../Component/Boarding/Boarding";
import Breeding from "../Component/Breeding/Breeding";
import Dashboard from "../Component/Dashboard/Dashboard";
import Diet from "../Component/Diet/Diet";
import Farrier from "../Component/Farrier/Farrier";
import DentalHealth from "../Component/Health/DentalHealth/DentalHealth";
import Health from "../Component/Health/Health";
import LabTestHealth from "../Component/Health/LabTestHealth/LabTestHealth";
import ParasiteControlHealth from "../Component/Health/ParasiteControlHealth/ParasiteControlHealth";
import TreatmentsHealth from "../Component/Health/TreatmentsHealth/TreatmentsHealth";
import VaccinationHealth from "../Component/Health/VaccinationHealth/VaccinationHealth";
import VetVisitHealth from "../Component/Health/VetVisitHealth/VetVisitHealth";
import VitalSignsHealth from "../Component/Health/VitalSignsHealth/VitalSignsHealth";
import XRayHealth from "../Component/Health/XRayHealth/XRayHealth";
import AchievementPage from "../Component/HorseDetails/AchievementPage/AchievementPage";
import BreedingPage from "../Component/HorseDetails/BreedingPage/BreedingPage";
import DietPage from "../Component/HorseDetails/DietPage/DietPage";
import DocumentsPage from "../Component/HorseDetails/DocumentsPage/DocumentsPage";
import FarrierPage from "../Component/HorseDetails/FarrierPage/FarrierPage";
import DentalPage from "../Component/HorseDetails/HealthPage/DentalPage/DentalPage";
import HealthPage from "../Component/HorseDetails/HealthPage/HealthPage";
import LabTestPage from "../Component/HorseDetails/HealthPage/LabTestPage/LabTestPage";
import ParasiteControlPage from "../Component/HorseDetails/HealthPage/ParasiteControlPage/ParasiteControlPage";
import TreatmentsPage from "../Component/HorseDetails/HealthPage/TreatmentsPage/TreatmentsPage";
import VaccinationPage from "../Component/HorseDetails/HealthPage/VaccinationPage/VaccinationPage";
import VetVisitPage from "../Component/HorseDetails/HealthPage/VetVisitPage/VetVisitPage";
import VitalSignsPage from "../Component/HorseDetails/HealthPage/VitalSignsPage/VitalSignsPage";
import XRayPage from "../Component/HorseDetails/HealthPage/XRayPage/XRayPage";
import HorseDetails from "../Component/HorseDetails/HorseDetails";
import HorsePage from "../Component/HorseDetails/HorsePage/HorsePage";
import MeasurementsPage from "../Component/HorseDetails/MeasurementsPage/MeasurementsPage";
import NotesPage from "../Component/HorseDetails/NotesPage/NotesPage";
import PictureVideoPage from "../Component/HorseDetails/PictureVideoPage/PictureVideoPage";
import CategoryIAEPage from "../Component/IncomeAndExpenses/CategoryIAEPage/CategoryIAEPage";
import DetailCategoryIAEPage from "../Component/IncomeAndExpenses/DetailCategoryIAEPage/DetailCategoryIAEPage";
import HorseIAEPage from "../Component/IncomeAndExpenses/HorseIAEPage/HorseIAEPage";
import IncomeAndExpenses from "../Component/IncomeAndExpenses/IncomeAndExpenses";
import Inventory from "../Component/Inventory/Inventory";
import InvoiceListPage from "../Component/Invoicing/InvoiceListPage/InvoiceListPage";
import Invoicing from "../Component/Invoicing/Invoicing";
import ManualInvoicePage from "../Component/Invoicing/ManualInvoicePage/ManualInvoicePage";
import NewInvoicePage from "../Component/Invoicing/NewInvoicePage/NewInvoicePage";
import ServiceToInvoicePage from "../Component/Invoicing/ServiceToInvoicePage/ServiceToInvoicePage";
import Login from "../Component/Login/Login";
import MyHorses from "../Component/MyHorses/MyHorses";
import DentalReminderPage from "../Component/Reminders/DentalReminderPage/DentalReminderPage";
import FarrierReminderPage from "../Component/Reminders/FarrierReminderPage/FarrierReminderPage";
import ParasiteControlReminderPage from "../Component/Reminders/ParasiteControlReminderPage/ParasiteControlReminderPage";
import PregnancyReminderPage from "../Component/Reminders/PregnancyReminderPage/PregnancyReminderPage";
import RegistrationReminderPage from "../Component/Reminders/RegistrationReminderPage/RegistrationReminderPage";
import Reminders from "../Component/Reminders/Reminders";
import VaccinationReminderPage from "../Component/Reminders/VaccinationReminderPage/VaccinationReminderPage";
import WeaningReminderPage from "../Component/Reminders/WeaningReminderPage/WeaningReminderPage";
import Task from "../Component/Task/Task";
import Training from "../Component/Training/Training";
import TrainingCalender from "../Component/TrainingCalender/TrainingCalender";
import Transportation from "../Component/Transportation/Transportation";
import ChangePasswordPage from "../Component/UserProfile/ChangePasswordPage/ChangePasswordPage";
import ImportDataPage from "../Component/UserProfile/ImportDataPage/ImportDataPage";
import LocationPage from "../Component/UserProfile/LocationPage/LocationPage";
import ProtectedRoutes from "./ProtectedRoutes";
import Logout from "../Component/Login/Logout";
import MyAccountPage from "../Component/UserProfile/MyAccountPage/MyAccountPage";
import PreferencePage from "../Component/UserProfile/PreferencePage/PreferencePage";
import ContactPage from "../Component/UserProfile/UsersPage/ContactPage/ContactPage";
import PayrollPage from "../Component/UserProfile/UsersPage/PayrollPage/PayrollPage";
import StaffPage from "../Component/UserProfile/UsersPage/StaffPage/StaffPage";
import UsersPage from "../Component/UserProfile/UsersPage/UsersPage";
import {
  BOARDING,
  BREEDING,
  CHANGEPASSWORD,
  DASHBOARD,
  DIET,
  FARM_DIRECTORY,
  FARM_DIRECTORY_DETAILS,
  FARM_DIRECTORY_LISTING,
  FARRIER,
  HEALTH,
  HEALTH_DENTAL,
  HEALTH_LABTESTS,
  HEALTH_PARASITECONTROL,
  HEALTH_TREATMENTS,
  HEALTH_VACCINATION,
  HEALTH_VETVISITS,
  HEALTH_VITALSIGNS,
  HEALTH_XRAY,
  HORSEDETAILS,
  HORSEDETAILS_ACHIEVEMENTS,
  HORSEDETAILS_BREEDING,
  HORSEDETAILS_BREEDING_SERVICE_DETAILS,
  HORSEDETAILS_BREEDING_SERVICE,
  HORSEDETAILS_DIET,
  HORSEDETAILS_DOCUMENTS,
  HORSEDETAILS_FARRIER,
  HORSEDETAILS_HEALTH,
  HORSEDETAILS_HEALTH_DENTAL,
  HORSEDETAILS_HEALTH_LABTESTS,
  HORSEDETAILS_HEALTH_PARASITECONTROL,
  HORSEDETAILS_HEALTH_TREATMENTS,
  HORSEDETAILS_HEALTH_VACCINATION,
  HORSEDETAILS_HEALTH_VETVISITS,
  HORSEDETAILS_HEALTH_VITALSIGNS,
  HORSEDETAILS_HEALTH_XRAY,
  HORSEDETAILS_HORSE,
  HORSEDETAILS_MEASUREMENTS,
  HORSEDETAILS_NOTES,
  HORSEDETAILS_PICTUREANDVIDEO,
  IMPORTDATA,
  INCOME_EXPENSE,
  INCOME_EXPENSE_CATEGORY,
  INCOME_EXPENSE_CATEGORY_DETAIL,
  INCOME_EXPENSE_HORSE,
  INVENTORY,
  INVOICING,
  INVOICING_INVOICE_LIST,
  INVOICING_MANUAL_INVOICE,
  INVOICING_NEW_INVOICE,
  INVOICING_SERVICE_TO_INVOICE,
  LOCATION,
  LOGIN,
  LOGOUT,
  MYACCOUNT,
  MYHORSES,
  PREFERENCE,
  REMINDERS,
  REMINDERS_DENTAL,
  REMINDERS_FARRIER,
  REMINDERS_PARASITECONTROL,
  REMINDERS_PREGNANCY,
  REMINDERS_REGISTRATION,
  REMINDERS_VACCINATION,
  REMINDERS_WEANING,
  TASK,
  TRAINING,
  TRAINING_CALENDER,
  TRANSPORTATION,
  USER,
  USER_CONTACT,
  USER_PAYROLL,
  USER_STAFF,
} from "../Constant/Route";
import ScrollToTop from "../Constant/ScrollToTop";
import MainRoutes from "./MainRoutes";
import { ToastContainer } from "react-toastify";
import { useLoading } from "../context/LoadingContext";
import Loader from "../Component/Loader/Loader";
import SomethingWrong from "../Component/SomethingWrong/SomethingWrong";
import BreedingControlMarePage from "../Component/HorseDetails/BreedingPage/BreedingControlMarePage";
import RoleRoutes from "./RoleRoutes";
import ProtecetdRoleRoutes from "./ProtecetdRoleRoutes";
import UpgradeSubscriptionPopup from "../Component/Popups/UpgradeSubscriptionPopup/UpgradeSubscriptionPopup";
import FarmDirectory from "../Component/FarmDirectory/FarmDirectory";
import FarmDirectoryListing from "../Component/FarmDirectory/FarmDirectoryListing/FarmDirectoryListing";
import FarmDirectoryDetails from "../Component/FarmDirectory/FarmDirectoryDetails/FarmDirectoryDetails";
import AcessDenied from "../Component/Errors/AcessDenied/AcessDenied";
import BreedingServiceDetailsMarePage from "../Component/HorseDetails/BreedingPage/BreedingServiceDetailsMarePage";

const Routesconfig = () => {
  const [windowSize, setWindowSize] = useState(window.innerWidth);
  useEffect(() => {
    window.addEventListener("load", (event: any) => {
      setWindowSize(event?.currentTarget?.innerWidth);
    });
    window.addEventListener("resize", (event: any) => {
      setWindowSize(event?.currentTarget?.innerWidth);
    });
    return () => {
      window.removeEventListener("load", () => {});
      window.removeEventListener("resize", () => {});
    };
  }, [windowSize]);

  const [sidebarToggle, setSidebarToggle] = useState(false);
  const { loading } = useLoading();
  return (
    <BrowserRouter>
      <ScrollToTop>
        {loading && <Loader />}
        <ToastContainer />
        <UpgradeSubscriptionPopup/>
        <Routes>
          <Route path={LOGIN + "/:token"} element={<Login />} />
          <Route path={LOGOUT} element={<Logout />} />
          <Route
            element={
              <ProtectedRoutes>
                <MainRoutes />
              </ProtectedRoutes>
            }
          >
            <Route path={DASHBOARD} element={<Dashboard />} />
            <Route path={MYHORSES} element={<MyHorses />} />
          
            <Route path={BREEDING} element={<Breeding />} />
            <Route path={HORSEDETAILS} element={<HorseDetails />}>
              <Route
                path={HORSEDETAILS_HORSE + "/:slug"}
                element={<HorsePage />}
              />
                 <Route
                  path={HORSEDETAILS_BREEDING_SERVICE + "/:slug"}
                  element={<BreedingPage />}
                />
                <Route
                  path={HORSEDETAILS_BREEDING_SERVICE_DETAILS + "/:slug"}
                  element={<BreedingServiceDetailsMarePage />}
                />
              <Route path={HORSEDETAILS_HEALTH} element={<HealthPage />}>
                <Route
                  path={HORSEDETAILS_HEALTH_VITALSIGNS + "/:slug"}
                  element={<VitalSignsPage />}
                />
                <Route
                  path={HORSEDETAILS_HEALTH_VACCINATION + "/:slug"}
                  element={<VaccinationPage />}
                />
                <Route
                  path={HORSEDETAILS_HEALTH_PARASITECONTROL + "/:slug"}
                  element={<ParasiteControlPage />}
                />
                <Route
                  path={HORSEDETAILS_HEALTH_DENTAL + "/:slug"}
                  element={<DentalPage />}
                />
                <Route
                  path={HORSEDETAILS_HEALTH_VETVISITS + "/:slug"}
                  element={<VetVisitPage />}
                />
                <Route
                  path={HORSEDETAILS_HEALTH_TREATMENTS + "/:slug"}
                  element={<TreatmentsPage />}
                />
                <Route
                  path={HORSEDETAILS_HEALTH_LABTESTS + "/:slug"}
                  element={<LabTestPage />}
                />
                <Route
                  path={HORSEDETAILS_HEALTH_XRAY + "/:slug"}
                  element={<XRayPage />}
                />
              </Route>
              <Route
                path={HORSEDETAILS_DIET + "/:slug"}
                element={<DietPage />}
              />
              <Route
                path={HORSEDETAILS_FARRIER + "/:slug"}
                element={<FarrierPage />}
              />
              <Route
                path={HORSEDETAILS_MEASUREMENTS + "/:slug"}
                element={<MeasurementsPage />}
              />
              <Route
                path={HORSEDETAILS_ACHIEVEMENTS + "/:slug"}
                element={<AchievementPage />}
              />
              <Route
                path={HORSEDETAILS_DOCUMENTS + "/:slug"}
                element={<DocumentsPage />}
              />
              <Route
                path={HORSEDETAILS_PICTUREANDVIDEO + "/:slug"}
                element={<PictureVideoPage />}
              />
              <Route
                path={HORSEDETAILS_NOTES + "/:slug"}
                element={<NotesPage />}
              />
            </Route>
            <Route path={HEALTH} element={<Health />}>
              <Route path={HEALTH_VITALSIGNS} element={<VitalSignsHealth />} />
              <Route
                path={HEALTH_VACCINATION}
                element={<VaccinationHealth />}
              />
              <Route
                path={HEALTH_PARASITECONTROL}
                element={<ParasiteControlHealth />}
              />
              <Route path={HEALTH_DENTAL} element={<DentalHealth />} />
              <Route path={HEALTH_VETVISITS} element={<VetVisitHealth />} />
              <Route path={HEALTH_TREATMENTS} element={<TreatmentsHealth />} />
              <Route path={HEALTH_LABTESTS} element={<LabTestHealth />} />
              <Route path={HEALTH_XRAY} element={<XRayHealth />} />
            </Route>
            <Route path={DIET} element={<Diet />} />
            <Route path={FARRIER} element={<Farrier />} />
            <Route path={TRAINING} element={<Training />} />
            <Route path={TRAINING_CALENDER} element={<TrainingCalender />} />
            <Route path={TRANSPORTATION} element={<Transportation />} />
           
            <Route path={TASK} element={<Task />} />
            <Route path={REMINDERS} element={<Reminders />}>
              <Route
                path={REMINDERS_PREGNANCY}
                element={<PregnancyReminderPage />}
              />
              <Route
                path={REMINDERS_REGISTRATION}
                element={<RegistrationReminderPage />}
              />
              <Route
                path={REMINDERS_WEANING}
                element={<WeaningReminderPage />}
              />
              <Route
                path={REMINDERS_VACCINATION}
                element={<VaccinationReminderPage />}
              />
              <Route
                path={REMINDERS_PARASITECONTROL}
                element={<ParasiteControlReminderPage />}
              />
              <Route path={REMINDERS_DENTAL} element={<DentalReminderPage />} />
              <Route
                path={REMINDERS_FARRIER}
                element={<FarrierReminderPage />}
              />
            </Route>
            <Route
                path={"/upgrade-plan"}
                element={<AcessDenied />}
              />
            <Route
            element={
              <ProtecetdRoleRoutes>
                <RoleRoutes />
              </ProtecetdRoleRoutes>
            }
             >
             <Route path={BOARDING} element={<Boarding />} />
             <Route path={INVENTORY} element={<Inventory />} />
            <Route path={INCOME_EXPENSE} element={<IncomeAndExpenses />}>
              <Route
                path={INCOME_EXPENSE_CATEGORY}
                element={<CategoryIAEPage />}
              />
              <Route
                path={INCOME_EXPENSE_CATEGORY_DETAIL + "/:category"}
                element={<DetailCategoryIAEPage />}
              />
              <Route path={INCOME_EXPENSE_HORSE} element={<HorseIAEPage />} />
            </Route>
            <Route path={INVOICING} element={<Invoicing />}>
              <Route
                path={INVOICING_SERVICE_TO_INVOICE}
                element={<ServiceToInvoicePage />}
              />
              <Route
                path={INVOICING_INVOICE_LIST}
                element={<InvoiceListPage />}
              />
              <Route
                path={INVOICING_NEW_INVOICE}
                element={<NewInvoicePage />}
              />
              <Route
                path={INVOICING_MANUAL_INVOICE}
                element={<ManualInvoicePage />}
              />
            </Route>
            <Route path={USER} element={<UsersPage />}>
            <Route path={USER_PAYROLL} element={<PayrollPage />} />
            </Route>
            </Route>
            <Route path={MYACCOUNT} element={<MyAccountPage />} />
            <Route path={LOCATION} element={<LocationPage />} />
            <Route path={PREFERENCE} element={<PreferencePage />} />
            <Route path={USER} element={<UsersPage />}>
              <Route path={USER_CONTACT} element={<ContactPage />} />
              <Route path={USER_STAFF} element={<StaffPage />} />
            </Route>
            <Route path={IMPORTDATA} element={<ImportDataPage />} />
            <Route path={CHANGEPASSWORD} element={<ChangePasswordPage />} />

            <Route path={FARM_DIRECTORY} element={<FarmDirectory />}>
              <Route
                path={FARM_DIRECTORY_LISTING}
                element={<FarmDirectoryListing />}
              />
              <Route
                path={FARM_DIRECTORY_DETAILS + "/:slug"}
                element={<FarmDirectoryDetails />}
              />
            </Route>
          </Route>
          <Route path="*" element={<SomethingWrong />} />
        </Routes>
      </ScrollToTop>
    </BrowserRouter>
  );
};

export default Routesconfig;
