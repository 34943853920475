import React from "react";

export default function ToothIcon() {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M17.1313 12.2338C18.0934 10.0957 18.9487 6.46087 18.3073 4.85729C17.452 2.50537 15.4208 2.93299 13.7103 3.25371C13.0689 3.36062 12.4275 3.46752 11.8929 3.46752C11.3584 3.46752 10.717 3.36062 10.0755 3.25371C8.47196 2.93299 6.54767 2.61228 5.69243 4.85729C5.05099 6.46087 5.90624 10.0957 6.86838 12.2338C6.86838 12.3407 6.86838 12.3407 6.86838 12.4476C6.76148 13.3028 6.76148 13.6235 6.76148 14.4788C6.76148 16.9376 7.08219 21 8.47196 21C9.64792 21 9.75483 19.3964 9.96864 17.7928C10.1825 15.4409 10.5032 13.7304 11.9998 13.7304C13.4965 13.7304 13.7103 15.4409 14.031 17.7928C14.2449 19.5033 14.3518 21 15.5277 21C16.9175 21 17.2382 16.9376 17.2382 14.4788C17.2382 13.6235 17.2382 13.3028 17.1313 12.4476C17.1313 12.3407 17.1313 12.3407 17.1313 12.2338Z"
        stroke="#919EAB"
        strokeWidth="1.5"
      />
    </svg>
  );
}
