import type { DatePickerProps } from "antd";
import { t } from "i18next";
import type { Moment } from "moment";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { Col, Row } from "react-bootstrap";
import Modal from "react-bootstrap/Modal";
import { useDispatch, useSelector } from "react-redux";
import { useLoading } from "../../../context/LoadingContext";
import {
  addEditBreedingControl,
  addEditEmbryoTransfer,
} from "../../../Redux/NonRedux/breeding";
import validate from "../../../Utility/Validation";
import CustomeButton from "../../Common/CustomButton/CustomButton";
import Datepicker from "../../Common/Datepicker/Datepicker";
import InputGroups from "../../Common/InputGroups/InputGroups";
import InputSelectGroup from "../../Common/InputSelectGroup/InputSelectGroup";
import InputSelectMix from "../../Common/InputSelectMix/InputSelectMix";
import TextButton from "../../Common/TextButton/TextButton";
import Timepicker from "../../Common/Timepicker/Timepicker";

export default function AddTransferPopup(props: any) {
  const INTIAL_DATA = {
    id: {
      value: "",
    },
    dates: {
      value: moment().format("YYYY-MM-DD"),
      validation: ["required"],
      errors: [t("Date is required.")],
    },
    time: {
      value: moment().format("YYYY-MM-DD"),
      validation: ["required"],
      errors: [t("Time is required.")],
    },
    amount: {
      value: "",
      validation: [""],
      errors: [t("Amount is required.")],
    },
    select_vet_clinic: {
      value: "vet_clinic_id",
      // validation: ["requiredIf:vet_clinic_id"],
      errors: [t("Vet Clinic is required.")],
    },
    vet_clinic: {
      value: "",
      validation: ["requiredIf:select_vet_clinic:vet_clinic", "letters"],
      errors: [t("Vet Clinic is required."), t("Incorrect format")],
    },
    vet_clinic_id: {
      value: "",
      validation: ["requiredIf:select_vet_clinic:vet_clinic_id"],
      errors: [t("Vet Clinic is required.")],
    },
    select_recipient_mare: {
      value: "recipient_mare_id",
      // validation: ["requiredIf:vet_clinic_id"],
      errors: [t("Recipient Mare is required.")],
    },
    recipient_mare_id: {
      value: "",
      validation: ["requiredIf:select_recipient_mare:recipient_mare_id"],
      errors: [t("Recipient Mare is required.")],
    },
    recipient_mare: {
      value: "",
      validation: [
        "requiredIf:select_recipient_mare:recipient_mare",
        "letters",
      ],
      errors: [t("Recipient Mare is required."), t("Incorrect format")],
    },
    embryo_age: {
      value: "",
      validation: [""],
      errors: [t("Embryo Age is required.")],
    },
  };

  const [formData, setFormData] = useState<any>(INTIAL_DATA);
  const { setLoading } = useLoading();
  const [formDataError, setFormDataError] = useState<any>({});
  const [isFormValidated, setIsFormValidated] = useState(false);
  const [vetClinicSelectToggle, setVetClinicSelectToggle] = useState("select");
  const [recipientSelectToggle, setRecipientSelectToggle] = useState("select");
  const dispatch = useDispatch();
  const horseInfoId = useSelector(
    (state: any) => state?.horseInfoReducer?.data?.component?.id
  );

  const prefrence = useSelector(
    (state: any) => state?.masterReducer?.data?.component?.preferance
  );



  const setData = (field: string, value: any) => {
    const tempOb = Object.assign({}, formData);
    tempOb[field].value = value;
    setFormData({ ...tempOb });
  };

  const dateChange: DatePickerProps["onChange"] = (date, dateString) => {
    let dateIs = date?.format("YYYY-MM-DD");
    setData("dates", dateIs);
  };

  const timeChange = (time: Moment, timeString: string) => {
    setData("time", time.format("YYYY-MM-DD HH:mm"));
  };

  useEffect(() => {
    setFormDataError({ ...validate(formData).errors });
  }, [formData]);

  useEffect(() => {
    if (props.isedit && props?.editData) {
      Object.keys(formData).forEach((element) => {
        setData(element, `${props?.editData?.[element]}`);
      });
    } else {
      setFormData(INTIAL_DATA);
      setData(
        "vet_clinic_id",
        props?.metaData?.vet_clinic_id ? props?.metaData?.vet_clinic_id : ""
      );
    }
  }, [props?.editData, props.isedit]);

  useEffect(() => {
    if (props?.show === false) {
      setFormDataError({});
      setFormData(INTIAL_DATA);
      setIsFormValidated(false);
      setVetClinicSelectToggle("select");
      setRecipientSelectToggle("select");
    }
  }, [props?.show]);

  const submitHandler = () => {
    if (validate(formData).isValidated === true) {
      let params: any = {};
      Object.keys(formData).forEach((element) => {
        params[element] = formData[element].value;
      });
      params["service_id"] = props?.serviceId;
      setLoading(true);
      addEditEmbryoTransfer(params)
        .then((response: any) => {
          props.onHide();
          props.setRefreshList();
        })
        .finally(() => {
          setLoading(false);
        });
    } else {
      setFormDataError({ ...validate(formData)?.errors });
      setIsFormValidated(true);
    }
  };

  const toggleChange = (selectType: string, input: string) => {
    if (selectType === "select") {
      setData(`select_${input}`, `${input}_id`);
      setData(input, "");
    } else {
      setData(`select_${input}`, input);
      setData(`${input}_id`, "");
    }
  };

  const { metaData, serviceId, setRefreshList, editData, breedingServiceDate , isedit, ...rest } =
    props;
  
  return (
    <Modal {...rest} centered className="add-transfer-popup modal-784">
      <Modal.Header closeButton>
        <p className="tl">
          {props.isedit ? t("Edit Embryo Transfer") : t("Add Embryo Transfer")}{" "}
        </p>
      </Modal.Header>
      <Modal.Body>
        <Row>
          <Col sm={6} md={6}>
            <Datepicker
              startDate={
                breedingServiceDate
                  ? moment(breedingServiceDate).format("YYYY-MM-DD")
                  : null
              }
              endDate={moment().format("YYYY-MM-DD")}
              label={`${t("Date")}*`}
              onChange={dateChange}
              value={moment(formData?.dates?.value)}
              error={
                isFormValidated && formDataError["dates"]
                  ? formDataError["dates"]
                  : null
              }
            />
          </Col>
          <Col sm={6} md={6}>
            <Timepicker
              label={`${t("Time")}*`}
              onChange={timeChange}
              value={moment(formData?.time?.value)}
              error={
                isFormValidated && formDataError["time"]
                  ? formDataError["time"]
                  : null
              }
            />
          </Col>
          <Col sm={6} md={6}>
            <InputSelectGroup
              label={`${t("Vet / Clinic")}*`}
              selectValue={metaData?.vet_clinic}
              dropKey="id"
              dropVal="first_name"
              defaultSelect={formData?.vet_clinic_id?.value}
              value={formData?.vet_clinic?.value}
              inputSelectToggle={vetClinicSelectToggle}
              setInputSelectToggle={(val: any) => {
                setVetClinicSelectToggle(val);
                toggleChange(val, "vet_clinic");
              }}
              error={
                isFormValidated &&
                (formDataError["vet_clinic"] || formDataError["vet_clinic_id"])
                  ? formDataError["vet_clinic"] ||
                    formDataError["vet_clinic_id"]
                  : null
              }
              onSelectChange={(e: any) => {
                setData("vet_clinic_id", e);
              }}
              onInputChange={(e: any) => {
                setData("vet_clinic", e.target.value);
              }}
            />
          </Col>
        </Row>

        <Row>
          <Col sm={6} md={6}>
            <InputGroups
              label={`${t("Donor")}`}
              readOnly={true}
              type="text"
              autoComplete="off"
              value={metaData?.dam}
              error={
                isFormValidated && formDataError["name"]
                  ? formDataError["name"]
                  : null
              }
            />
          </Col>
          <Col sm={6} md={6}>
            <InputGroups
              label={`${t("Stallion")}`}
              readOnly={true}
              type="text"
              autoComplete="off"
              value={metaData?.sire}
              error={
                isFormValidated && formDataError["name"]
                  ? formDataError["name"]
                  : null
              }
            />
          </Col>
          <Col sm={6} md={6}>
            <InputSelectGroup
              label={`${t("Recipient Mare")}*`}
              selectValue={metaData?.recipient_mare?.filter(
                (e: any) => e.id !== horseInfoId
              )}
              inputSelectToggle={recipientSelectToggle}
              setInputSelectToggle={(val: any) => {
                setRecipientSelectToggle(val);
                toggleChange(val, "recipient_mare");
              }}
              dropKey="id"
              dropVal="name"
              defaultSelect={formData?.recipient_mare_id?.value}
              value={formData?.recipient_mare?.value}
              error={
                isFormValidated &&
                (formDataError["recipient_mare"] ||
                  formDataError["recipient_mare_id"])
                  ? formDataError["recipient_mare"] ||
                    formDataError["recipient_mare_id"]
                  : null
              }
              onSelectChange={(e: any) => {
                setData("recipient_mare_id", e);
              }}
              onInputChange={(e: any) => {
                setData("recipient_mare", e.target.value);
              }}
            />
          </Col>
          <Col sm={6} md={6}>
            <InputGroups
              label={`${t("Embryo Age (Days)")}`}
              type="text"
              autoComplete="off"
              value={formData?.embryo_age?.value}
              onChange={(e: any) => setData("embryo_age", e.target.value)}
              error={
                isFormValidated && formDataError["embryo_age"]
                  ? formDataError["embryo_age"]
                  : null
              }
            />
          </Col>
          <Col sm={6} md={6}>
            <InputSelectMix
              label={`${t("Amount")}`}
              inputType="number"
              onInputChange={(e: any) => setData("amount", e.target.value)}
              inputValue={formData?.amount?.value}
              inputError={
                isFormValidated && formDataError["amount"]
                  ? formDataError["amount"]
                  : null
              }
              inputSecondValue={prefrence?.currency_value}
            />
          </Col>
        </Row>
      </Modal.Body>
      <Modal.Footer>
        <div className="footer-2-btn">
          <TextButton onClick={props?.onHide}> {t("Cancel")}</TextButton>
          <CustomeButton bg="fill" onClick={submitHandler}>
            {props.isedit ? t("Update") : t("Add")}
          </CustomeButton>
        </div>
      </Modal.Footer>
    </Modal>
  );
}
