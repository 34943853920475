// import { InputLabel } from "@mui/material";
// import FormControl from "@mui/material/FormControl";
// import ListItemText from "@mui/material/ListItemText";
// import MenuItem from "@mui/material/MenuItem";
// import OutlinedInput from "@mui/material/OutlinedInput";
// import Select, { SelectChangeEvent } from "@mui/material/Select";
// import OnlyCk from "../OnlyCk/OnlyCk";
import { t } from "i18next";
import  React, { useEffect, useState } from "react";

import { Select } from "antd";
import type { SelectProps } from "antd";

export default function MultiSelectWithLegends(props: any) {
  // const ITEM_HEIGHT = 40;
  // const MenuProps = {
  //   PaperProps: {
  //     style: {
  //       maxHeight: ITEM_HEIGHT * 5,
  //       width: 250,
  //     },
  //   },
  // };

  const [options,setOptions] = useState<SelectProps["options"]>([]);
  
//   useEffect(() => {
//     const option: SelectProps["options"] = [];
//     props?.value?.map((items: any, index: number) => {
//       option.push({
//         label: items[props.dropVal ? props.dropVal : "value"] ,
//         value:items[props.dropKey ? props.dropKey : "value"] ,
//       })
//   })
//   setOptions(option);
//   }, [props?.value])
  
  const handleMaxTagPlaceholder = (selectedcValue:any) => {
   
  };

 

  return (
    <div className="input-groups multi-select-legend">
      <label>{props.label}</label>
      <Select
        mode="multiple"
        style={{ width: "100%" }}
        placeholder={t("Select...")}
        maxTagCount={0}
        maxTagPlaceholder={`${props?.defaultSelect?.length} ${t("items selected")}`}
        defaultValue={props?.defaultSelect}
        onChange={props.onChange}
        options={props?.value}
        popupClassName="customeMultiSelect"
        className={`${props.error && "error"}`}
      />
      {props.error && <span className="error">{props.error}</span>}
    </div>
  );
}
