import { END_POINTS, MAX_CALLS } from "../../../Constant/Api";
import { SHOW_MESSAGE } from "../../../Constant/General";
import { SUCCESS_RESPONSE_CODE } from "../../../Constant/Status";
import api from "../../../Service/Axios";
import { showToast } from "../../../Utility/General";
import { globalPostActionWithReducer } from "../../globallActions";
import {
  ADD_HORSE_HEALTH_FAILD,
  ADD_HORSE_HEALTH_LONG,
  ADD_HORSE_HEALTH_REQUEST,
  ADD_HORSE_HEALTH_SUCCESS,
  VIEW_HEALTH_REQUEST,
  VIEW_HEALTH_SUCCESS,
  VIEW_HEALTH_FAILD,
  VIEW_HEALTH_LONG,
  DESTROY_HEALTH_REQUEST,
  DESTROY_HEALTH_SUCCESS,
  DESTROY_HEALTH_FAILD,
  DESTROY_HEALTH_LONG,
  HORSE_VITALSIGN_FAILD,
  HORSE_VITALSIGN_LONG,
  HORSE_VITALSIGN_REQUEST,
  HORSE_VITALSIGN_SUCCESS,
  HORSE_VACCINE_FAILD,
  HORSE_VACCINE_LONG,
  HORSE_VACCINE_REQUEST,
  HORSE_VACCINE_SUCCESS,
  HORSE_PARASITE_REQUEST,
  HORSE_PARASITE_SUCCESS,
  HORSE_PARASITE_LONG,
  HORSE_PARASITE_FAILD,
  HORSE_DENTAL_REQUEST,
  HORSE_DENTAL_SUCCESS,
  HORSE_DENTAL_LONG,
  HORSE_DENTAL_FAILD,
  HORSE_VET_CLINIC_REQUEST,
  HORSE_VET_CLINIC_SUCCESS,
  HORSE_VET_CLINIC_LONG,
  HORSE_VET_CLINIC_FAILD,
  HORSE_TREATMENTS_REQUEST,
  HORSE_TREATMENTS_SUCCESS,
  HORSE_TREATMENTS_LONG,
  HORSE_TREATMENTS_FAILD,
  HORSE_LABTESTS_REQUEST,
  HORSE_LABTESTS_SUCCESS,
  HORSE_LABTESTS_LONG,
  HORSE_LABTESTS_FAILD,
  HORSE_XRAYS_REQUEST,
  HORSE_XRAYS_SUCCESS,
  HORSE_XRAYS_LONG,
  HORSE_XRAYS_FAILD,
} from "./SingleHorseType";

// export function addEditHealthAction(param: any) {
//   let recallCount = 0;
//   return (dispatch: any) => {
//     dispatch({
//       type: ADD_HORSE_HEALTH_REQUEST,
//       payload: { type: ADD_HORSE_HEALTH_REQUEST },
//     });
//     function recursiveCall() {
//       api
//         .post(END_POINTS.singleHealthAddEdit, param)
//         .then((result) => {
//           if (result.data.statusCode === SUCCESS_RESPONSE_CODE) {
//             showToast("success", result.data.message);
//             dispatch({
//               type: ADD_HORSE_HEALTH_SUCCESS,
//               payload: {
//                 data: result.data,
//                 type: ADD_HORSE_HEALTH_SUCCESS,
//               },
//             });
//           } else if (recallCount < MAX_CALLS) {
//             recursiveCall();
//             recallCount++;
//             dispatch({
//               type: ADD_HORSE_HEALTH_LONG,
//               payload: { data: {}, type: ADD_HORSE_HEALTH_LONG },
//             });
//           } else {
//             showToast("error", result.data.message);
//             dispatch({
//               type: ADD_HORSE_HEALTH_FAILD,
//               payload: { data: {}, type: ADD_HORSE_HEALTH_FAILD },
//             });
//           }
//         })
//         .catch((error) => {
//           showToast("error", error);
//           if (recallCount < MAX_CALLS) {
//             recursiveCall();
//             recallCount++;
//             dispatch({
//               type: ADD_HORSE_HEALTH_LONG,
//               payload: { data: {}, type: ADD_HORSE_HEALTH_LONG },
//             });
//           } else {
//             dispatch({
//               type: ADD_HORSE_HEALTH_FAILD,
//               payload: { data: {}, type: ADD_HORSE_HEALTH_FAILD },
//             });
//           }
//         });
//     }
//     recursiveCall();
//   };
// }

export function addEditHealthAction(params: any) {
  let apiUrl = END_POINTS.singleHealthAddEdit;
  return globalPostActionWithReducer(
    params,
    apiUrl,
    ADD_HORSE_HEALTH_REQUEST,
    ADD_HORSE_HEALTH_SUCCESS,
    ADD_HORSE_HEALTH_LONG,
    ADD_HORSE_HEALTH_FAILD,
    SHOW_MESSAGE
  );
}

export function viewSingleHealthAction(params: any) {
  let apiUrl = END_POINTS.getSingleHealthView;
  return globalPostActionWithReducer(
    params,
    apiUrl,
    VIEW_HEALTH_REQUEST,
    VIEW_HEALTH_SUCCESS,
    VIEW_HEALTH_LONG,
    VIEW_HEALTH_FAILD
  );
}

export function destroySingleHealthAction(params: any) {
  let apiUrl = END_POINTS.getSingleHealthDestroy;
  return globalPostActionWithReducer(
    params,
    apiUrl,
    DESTROY_HEALTH_REQUEST,
    DESTROY_HEALTH_SUCCESS,
    DESTROY_HEALTH_LONG,
    DESTROY_HEALTH_FAILD,
    SHOW_MESSAGE
  );
}

export function getSingleVitalHealth(params: any) {
  let apiUrl = END_POINTS.getSingleHealthList;
  return globalPostActionWithReducer(
    params,
    apiUrl,
    HORSE_VITALSIGN_REQUEST,
    HORSE_VITALSIGN_SUCCESS,
    HORSE_VITALSIGN_LONG,
    HORSE_VITALSIGN_FAILD
  );
}
export function getSingleVaccineHealth(params: any) {
  let apiUrl = END_POINTS.getSingleHealthList;
  return globalPostActionWithReducer(
    params,
    apiUrl,
    HORSE_VACCINE_REQUEST,
    HORSE_VACCINE_SUCCESS,
    HORSE_VACCINE_LONG,
    HORSE_VACCINE_FAILD
  );
}

export function getSingleParasiteHealth(params: any) {
  let apiUrl = END_POINTS.getSingleHealthList;
  return globalPostActionWithReducer(
    params,
    apiUrl,
    HORSE_PARASITE_REQUEST,
    HORSE_PARASITE_SUCCESS,
    HORSE_PARASITE_LONG,
    HORSE_PARASITE_FAILD
  );
}

export function getSingleDentalHealth(params: any) {
  let apiUrl = END_POINTS.getSingleHealthList;
  return globalPostActionWithReducer(
    params,
    apiUrl,
    HORSE_DENTAL_REQUEST,
    HORSE_DENTAL_SUCCESS,
    HORSE_DENTAL_LONG,
    HORSE_DENTAL_FAILD
  );
}

export function getSingleClinicHealth(params: any) {
  let apiUrl = END_POINTS.getSingleHealthList;
  return globalPostActionWithReducer(
    params,
    apiUrl,
    HORSE_VET_CLINIC_REQUEST,
    HORSE_VET_CLINIC_SUCCESS,
    HORSE_VET_CLINIC_LONG,
    HORSE_VET_CLINIC_FAILD
  );
}

export function getSingleTreatmentHealth(params: any) {
  let apiUrl = END_POINTS.getSingleHealthList;
  return globalPostActionWithReducer(
    params,
    apiUrl,
    HORSE_TREATMENTS_REQUEST,
    HORSE_TREATMENTS_SUCCESS,
    HORSE_TREATMENTS_LONG,
    HORSE_TREATMENTS_FAILD
  );
}

export function getSingleLabTestHealth(params: any) {
  let apiUrl = END_POINTS.getSingleHealthList;
  return globalPostActionWithReducer(
    params,
    apiUrl,
    HORSE_LABTESTS_REQUEST,
    HORSE_LABTESTS_SUCCESS,
    HORSE_LABTESTS_LONG,
    HORSE_LABTESTS_FAILD
  );
}

export function getSingleXraysHealth(params: any) {
  let apiUrl = END_POINTS.getSingleHealthList;
  return globalPostActionWithReducer(
    params,
    apiUrl,
    HORSE_XRAYS_REQUEST,
    HORSE_XRAYS_SUCCESS,
    HORSE_XRAYS_LONG,
    HORSE_XRAYS_FAILD
  );
}
