import React, { useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import AddPlusIcon from "../../Assets/icon/AddPlusIcon";
import binIcon from "../../Assets/img/bin.svg";
import ExportIcon from "../../Assets/img/export.svg";
import eyeIcon from "../../Assets/img/eye.svg";
import pencilIcon from "../../Assets/img/pencil.svg";
import TaskIcon from "../../Assets/img/Tasks-primary.svg";
import { END_POINTS } from "../../Constant/Api";
import { DATATABLE, PERMISSIONS, SIDEBAR } from "../../Constant/General";
import { SUCCESS_RESPONSE_CODE } from "../../Constant/Status";
import { useLoading } from "../../context/LoadingContext";
import { SidebarContext } from "../../context/SideBarContext";
import { upgradePopupAction } from "../../Redux/General/GeneralAction";
import { exportDocument } from "../../Redux/globallActions";
import { getListMetaDataApi } from "../../Redux/NonRedux/general";
import {
  destroyTask,
  getMetaTaskType,
  getSingleTask,
  getTaskList,
} from "../../Redux/NonRedux/taskApi";
import { getMetaStaffList } from "../../Redux/NonRedux/users";
import { checkRoutePermission, getDefaultSorting } from "../../Utility/General";
import CustomeButton from "../Common/CustomButton/CustomButton";
import TableHead from "../Common/DataTable/TableHead/TableHead";
import NoDataFound from "../Common/NoDataFound/NoDataFound";
import SearchBox from "../Common/SearchBox/SearchBox";
import Filter from "../Filter/Filter";
import Pagination from "../Layouts/Pagination/Pagination";
import AddTaskPopup from "../Popups/AddTaskPopup/AddTaskPopup";
import ConfirmPopup from "../Popups/ConfirmPopup/ConfirmPopup";
import TaskDescriptionPopup from "../Popups/TaskDescriptionPopup/TaskDescriptionPopup";

export default function Task() {
  const { t } = useTranslation();
  const defaultSorting: any = getDefaultSorting(DATATABLE.TASK);
  const tableHead = [
    {
      label: t("Date"),
      className: "tm",
      key: "date",
    },
    {
      label: t("Title"),
      className: "tm",
      key: "title",
    },
    {
      label: t("Task Type"),
      className: "tm",
      key: "task_type",
    },

    {
      label: t("Status"),
      className: "tm",
      key: "status",
    },
    {
      label: t("Person Assigned"),
      className: "tm",
      key: "assign_person",
    },
    {
      label: t("Recurrence"),
      className: "tm",
      key: "recurrence",
    },
    {
      label: t("Action"),
      className: "tm",
      key: "",
      headClass: "text-right",
    },
  ];
  const [confirmPopupShow, setConfirmPopupShow] = React.useState(false);
  const [deletableRecords, setDeletableRecords] = React.useState<any>([]);
  const [currentPage, setCurrentPage] = React.useState(1);
  const [horseId, setHorseId] = React.useState<any>();
  const [currentPageSize, setCurrentPageSize] = React.useState(10);
  const [recordsTotal, setRecordsTotal] = React.useState(1);
  const [searchText, setSearchText] = React.useState();
  const [listData, setListData] = React.useState([]);
  const [checkedRec, setCheckedRec] = useState<number[]>([]);
  const [refreshList, setRefreshList] = React.useState(false);
  const [isEdit, setIsEdit] = React.useState<boolean>(false);
  const [editData, setEditData] = React.useState<any>({});
  const { setLoading } = useLoading();

  const [descriptionModal, setrDescriptionModal] = useState(false);
  const [description, setDescription] = useState("");

  const [sortByField, setSortByField] = useState(defaultSorting?.key);
  const [sortType, setSortType] = useState(defaultSorting?.type);
  const [orderList, setOrderList] = useState<any>([]);
  const [exportParams, setExportParams] = useState<any>({});
  const [filterList, setFilterList] = useState<any>([]);
  const [filteredValue, setFilteredValue] = useState<any>({});
  const [metaData, setMetaData] = useState({});
  const { setSidebar }: any = useContext(SidebarContext);

  const dispatch = useDispatch();
  const modules = useSelector(
    (state: any) => state?.masterReducer?.data?.component?.modules
  );

  const getMetaData = () => {
    setLoading(true);
    getListMetaDataApi(END_POINTS.metaTask)
      .then((response: any) => {
        setMetaData(response?.component);
      })
      .catch((error: any) => {})
      .finally(() => {
        setLoading(false);
      });
  };

  const listAPICall = () => {
    setLoading(true);
    let params: any = {
      page: currentPage,
      search: searchText,
      limit: currentPageSize,
    };
    if (sortByField && sortType) {
      params["sort"] = {
        column: orderList[sortByField]
          ? orderList[sortByField]
          : defaultSorting?.value,

        type: sortType,
      };
    }
    if (filteredValue && Object.keys(filteredValue)?.length) {
      params["filter"] = filteredValue;
    }
    setExportParams(params);
    getTaskList(params)
      .then((res: any) => {
        if (res?.statusCode === SUCCESS_RESPONSE_CODE) {
          setListData(res?.component?.listing);
          setOrderList(res?.component?.orderlist);
          setRecordsTotal(res?.component?.recordsFiltered);
          setFilterList(res?.component?.filterlist);
        }
      })
      .catch(() => {})
      .finally(() => {
        setLoading(false);
      });
  };

  useEffect(() => {
    setSidebar(SIDEBAR.TASK);
    getMetaData();
  }, []);

  useEffect(() => {
    listAPICall();
  }, [currentPage, currentPageSize, sortByField, sortType, filteredValue]);

  useEffect(() => {
    if (searchText != null) {
      const searchTimeOut = setTimeout(() => {
        if (currentPage === 1) {
          listAPICall();
        } else {
          setCurrentPage(1);
        }
      }, 500);
      return () => clearTimeout(searchTimeOut);
    }
  }, [searchText]);

  useEffect(() => {
    if (refreshList) {
      listAPICall();
      setRefreshList(false);
    }
  }, [refreshList]);

  const editHandler = (id: number) => {
    let params = {
      horse_id: horseId,
      id: id,
    };

    setLoading(true);
    getSingleTask(params)
      .then((response: any) => {
        if (response?.statusCode === SUCCESS_RESPONSE_CODE) {
          setIsEdit(true);
          setEditData(response?.component);
          setAddTaskModalShow(true);
        }
      })
      .catch((error) => {})
      .finally(() => {
        setLoading(false);
      });
  };

  const deleteHandler = (id: number) => {
    let params = {
      id: deletableRecords,
    };

    setLoading(true);
    destroyTask(params)
      .then((response: any) => {
        if (response.statusCode === SUCCESS_RESPONSE_CODE) {
          setRefreshList(true);
          setConfirmPopupShow(false);
        }
      })
      .catch((error) => {})
      .finally(() => {
        setLoading(false);
      });
  };

  const deleteNow = (id: number) => {
    let ids = deletableRecords;
    ids.push(id);
    setDeletableRecords(ids);
    setConfirmPopupShow(true);
  };

  const [AddTaskModalShow, setAddTaskModalShow] = React.useState(false);

  const handleExport = () => {
    if (!checkRoutePermission(PERMISSIONS?.EXPORT_FEATURE, modules)) {
      upgradePopupAction({ show: true });
      return;
    }
    setLoading(true);
    exportDocument(END_POINTS.taskExport, exportParams, "task")
      .then(() => {})
      .finally(() => {
        setLoading(false);
      });
  };

  return (
    <>
      <div className="task-page">
        {/* Page Title Start */}
        <div className="page-title-wrapper">
          <div className="title-first-menu">
            <div className="title-icon-text">
              <img src={TaskIcon} alt="" />
              <h5>{t("Tasks")}</h5>
            </div>
            <div className="title-right-icons">
              <a className="unset-links" onClick={handleExport}>
                <img src={ExportIcon} alt="" />
              </a>
            </div>
          </div>
        </div>
        {/* Page Title End */}
        <div className="page-content">
          <div className="data-tbl-wrapper">
            <div className="data-tbl-header">
              <SearchBox
                value={searchText}
                placeholder={t("Search...")}
                onChange={(e: any) => {
                  setSearchText(e.target.value);
                }}
              />
              <div className="btn-wrapper f-fb">
                {/* <CustomeButton>
                  <Filtericon />
                  <p>Filter</p>
                </CustomeButton> */}

                <Filter
                  filterList={filterList}
                  setFilteredValue={setFilteredValue}
                  setCurrentPage={setCurrentPage}
                />
                <CustomeButton
                  bg="fill"
                  onClick={() => {
                    setIsEdit(false);
                    setEditData({});
                    setAddTaskModalShow(true);
                  }}
                >
                  <AddPlusIcon />
                  {t("Add")}
                </CustomeButton>
              </div>
            </div>
            <div className="data-tbl ptb-12">
              <table>
                <TableHead
                  data={tableHead}
                  orderList={orderList}
                  sortByField={sortByField}
                  sortType={sortType}
                  setSortType={setSortType}
                  setSortByField={setSortByField}
                />
                <tbody>
                  {listData?.map((items: any, index: number) => (
                    <tr key={index + "vaccination-data"}>
                      <td>
                        <p className="bl">{items.date}</p>
                      </td>
                      <td>
                        <p className="bl">{items.title}</p>
                      </td>
                      <td>
                        <p className="bl">{items.task_type_value}</p>
                      </td>
                      <td>
                        <label
                          className={`bl status-badge ${
                            items?.status == "1" ? "completed" : "pending"
                          }`}
                        >
                          {items?.status == "1" ? t("completed") : t("pending")}
                        </label>
                      </td>
                      <td>
                        <p className="bl">{items.person_name}</p>
                      </td>
                      <td>
                        <p className="bl">{items.recurence}</p>
                      </td>
                      <td>
                        <div className="icon-wrapper">
                          <a>
                            <img
                              src={eyeIcon}
                              onClick={() => {
                                setDescription(items?.description);
                                setrDescriptionModal(true);
                              }}
                              alt=""
                            />
                          </a>
                          <a>
                            <img
                              src={pencilIcon}
                              onClick={() => editHandler(items?.id)}
                              alt=""
                            />
                          </a>
                          <a>
                            <img
                              src={binIcon}
                              onClick={() => deleteNow(items?.id)}
                              alt=""
                            />
                          </a>
                        </div>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>

            {listData?.length ? (
              <Pagination
                results={recordsTotal}
                currentPage={currentPage}
                setCurrentPage={setCurrentPage}
                currentLength={currentPageSize}
                setCurrentPageSize={setCurrentPageSize}
              />
            ) : (
              <NoDataFound />
            )}
          </div>
        </div>
      </div>
      <AddTaskPopup
        show={AddTaskModalShow}
        setrefreshlist={() => {
          setRefreshList(true);
        }}
        onHide={() => setAddTaskModalShow(false)}
        isedit={isEdit ? 1 : 0}
        editData={editData}
        metaData={metaData}
      />
      <TaskDescriptionPopup
        description={description}
        show={descriptionModal}
        onHide={() => setrDescriptionModal(false)}
      />
      <ConfirmPopup
        heading={t("Delete")}
        message={t("Are you sure to delete this record?")}
        show={confirmPopupShow}
        onHide={() => {
          setConfirmPopupShow(false);
          setDeletableRecords([]);
        }}
        confirm={deleteHandler}
      />
    </>
  );
}
