import { t } from "i18next";
import React, { useState, useEffect, useContext } from "react";
import { Col, Row } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import AddPlusIcon from "../../Assets/icon/AddPlusIcon";
import AmountIcon from "../../Assets/img/Amount.svg";
import DietIcon from "../../Assets/img/Diet-primary.svg";
import ExportIcon from "../../Assets/img/export.svg";
import FemaleIcon from "../../Assets/img/Female.svg";
import GrainIcon from "../../Assets/img/Grain.svg";
import HayIcon from "../../Assets/img/Hay.svg";
import MaleIcon from "../../Assets/img/Male.svg";
import CalcuIcon from "../../Assets/img/MareGestationCalculator.svg";
import QtyIcon from "../../Assets/img/Qty.svg";
import SupplementsIcon from "../../Assets/img/Supplements.svg";
import { END_POINTS } from "../../Constant/Api";
import { DATATABLE, PERMISSIONS, SIDEBAR } from "../../Constant/General";
import { HORSEDETAILS_DIET } from "../../Constant/Route";
import { SUCCESS_RESPONSE_CODE } from "../../Constant/Status";
import { useLoading } from "../../context/LoadingContext";
import { SidebarContext } from "../../context/SideBarContext";
import { upgradePopupAction } from "../../Redux/General/GeneralAction";
import { exportDocument } from "../../Redux/globallActions";
import { getListMetaDataApi } from "../../Redux/NonRedux/general";
import { getbulkDietList } from "../../Redux/NonRedux/horse";
import {  checkRoutePermission, encrDecrStr, getDefaultSorting, getSessionItem } from "../../Utility/General";
import CustomeButton from "../Common/CustomButton/CustomButton";
import NoDataFound from "../Common/NoDataFound/NoDataFound";
import OnlyCk from "../Common/OnlyCk/OnlyCk";
import SearchBox from "../Common/SearchBox/SearchBox";
import Pagination from "../Layouts/Pagination/Pagination";
import AddDietPopup from "../Popups/AddDietPopup/AddDietPopup";
import DietCalculatorPopup from "../Popups/DietCalculatorPopup/DietCalculatorPopup";
import MareGestationCalculatorPopup from "../Popups/MareGestationCalculatorPopup/MareGestationCalculatorPopup";
import PregnantIcon from "../../Assets/img/Pregnant.svg";

export default function Diet() {
  const defaultSorting : any = getDefaultSorting(DATATABLE.DIET_MAIN_LIST);
  const [currentPage, setCurrentPage] = useState(1);
  const [currentPageSize, setCurrentPageSize] = useState(10);
  const [checkedRec, setCheckedRec] = useState<number[]>([]);

  const [AddDietModalShow, setAddDietModalShow] = useState(false);
  const [refreshList, setRefreshList] = useState(false);
  const [recordsTotal, setRecordsTotal] = useState(1);
  const [header, setHeader] = useState<any>([]);
  const [dietList, setDietList] = useState<any>([]);
  const { setLoading } = useLoading();
  const [total, setTotal] = useState<any>([]);
  const [searchText, setSearchText] = React.useState();
  const { setSidebar }: any = useContext(SidebarContext)
  const [exportParams, setExportParams] = useState<any>({});
  const [DietCalculatorShow,setDietCalculatorShow] = useState(false);
  const [sortBy, setSortBy] = React.useState(defaultSorting?.value);
  const [sortByField, setSortByField] = useState(defaultSorting?.key);
  const [sortType, setSortType] = useState(defaultSorting?.type);
  const [orderList, setOrderList] = useState<any>([]);
  const [metaData, setMetaData] = useState({});
  const prefrence = useSelector(
    (state: any) => state?.masterReducer?.data?.component?.preferance
  );
  const modules = useSelector(
    (state: any) => state?.masterReducer?.data?.component?.modules
  );
const dispatch = useDispatch();

  const dietCategory = new Map([
    ["hay", { icon: HayIcon, cardColor: "light-marron-2" }],
    ["grain", { icon: GrainIcon, cardColor: "light-green-2" }],
    ["supplements", { icon: SupplementsIcon, cardColor: "light-blue" }],
  ]);


  const getMetaData = () => {
    setLoading(true);  
    getListMetaDataApi(END_POINTS.metaDiet , {type:"multiple"})
      .then((response: any) => {
        setMetaData(response?.component);
      })
      .catch((error: any) => {})
      .finally(() => {
        setLoading(false);
      });
  };
  
  const checkSingleRec = (e: any, val: number) => {
    var checkedRecData: number[] = Object.assign([], checkedRec);
    if (e.target.checked) {
      checkedRecData.push(val);
    } else {
      checkedRecData = checkedRecData.filter((c: number) => c !== val);
    }
    setCheckedRec(checkedRecData);
  };

  const selectAll = (e: any) => {
    let checkedRecord = [];
    let ids = dietList.map((item: any) => item.id);
    if (e.target.checked) {
      checkedRecord = ids;
    } else {
      checkedRecord = [];
    }
    setCheckedRec(checkedRecord);
  }

  const horseDetailLink = (id: any) => {
    return HORSEDETAILS_DIET + "/" + encrDecrStr(id, "encr");
  };

  

  const listApiCall = () => {
    let params: any = {
      page: currentPage,
      limit: currentPageSize,
      search: searchText
    };
    if (sortBy && sortType) {
      params["sort"] = {
        column: sortBy,
        type: sortType,
      };
    }
    setExportParams(params);
    setLoading(true);
    getbulkDietList(params)
      .then((response: any) => {
        if (response?.statusCode === SUCCESS_RESPONSE_CODE) {
          setDietList(response?.component?.listing);
          setHeader(response?.component?.healthHeader);
          setTotal(response?.component?.total);
          setOrderList(response?.component?.orderlist);
          setRecordsTotal(response?.component?.recordsFiltered);
        }
        setLoading(false);
      })
      .catch(() => {
        setLoading(false);
      });
  };

  useEffect(() => {
    setSidebar(SIDEBAR.DIET);
    getMetaData();
  }, []);

  useEffect(() => {
    listApiCall();
  }, [currentPage, currentPageSize, sortBy, sortType]);

  useEffect(() => {
    if (searchText != null) {
      const searchTimeOut = setTimeout(() => {
        if (currentPage === 1) {
          listApiCall();
        }
        else {
          setCurrentPage(1)
        }
      }, 500);
      return () => clearTimeout(searchTimeOut);
    }
  }, [searchText]);

  useEffect(() => {
    if (refreshList) {
      listApiCall();
      setRefreshList(false);
    }
  }, [refreshList]);

  const handleExport = () => {
    if(!checkRoutePermission(PERMISSIONS?.EXPORT_FEATURE , modules)){
      upgradePopupAction({show:true});
      return;
    }
    setLoading(true);
    exportDocument(END_POINTS.dietExport, exportParams, "diet")
      .then(() => {})
      .finally(() => {
        setLoading(false);
      });
  };

  const sortrecords = (fieldName: any) => {
    if (orderList?.[fieldName]) {
      let sortByNow = orderList[fieldName];
      if (sortBy == sortByNow) {
        let sortTypeNow = sortType == "asc" ? "desc" : "asc";
        setSortType(sortTypeNow);
      } else {
        setSortBy(sortByNow);
        setSortByField(fieldName);
        setSortType("asc");
      }
    }
  };

  const sortableClass = (fieldName: string): string => {
    if (orderList?.[fieldName]) {
      return "sortable";
    } else {
      return "";
    }
  };

  const sortingTypeClass = (fieldName: string): string => {
    if (sortByField === fieldName) {
      return sortType == "asc" ? "arrow-up" : "arrow-down";
    } else {
      return "";
    }
  };

  const getIconbyType = (type: any) => {
    if (type === "male") {
      return MaleIcon;
    } else if (type === "female") {
      return FemaleIcon;
    } else if (type === "pregnant") {
      return PregnantIcon;
    }
  };


  return (
    <>

      <div className="diet-page">
        {/* Page Title Start */}
        <div className="page-title-wrapper">
          <div className="title-first-menu">
            <div className="title-icon-text">
              <img src={DietIcon} alt="" />
              <h5>{t("Diet")}</h5>
            </div>
            <div className="title-right-icons">
              <a className="unset-links"  onClick={() => setDietCalculatorShow(true)}>
                <img src={CalcuIcon} alt="" />
              </a>
             
                <a className="unset-links" onClick={handleExport}>
                  <img src={ExportIcon} alt="" />
                </a>
           
            </div>
          </div>
        </div>
        {/* Page Title End */}

        <div className="page-content">
          <div className="summary-head">
            <Row>
              {total &&
                total.length > 0 &&
                total?.map((item: any) => (
                  <Col sm="12" md="6" lg="4" key={item?.category_slug + "summary"}>
                    <div className="summary-box">
                      <p className="tl">{item?.category_value}</p>
                      <div className="right-details">
                        <div className="icon-bold-small-text">
                          <img src={QtyIcon} alt="" />
                          <div className="bold-small-text">
                            <p className="tm">
                              {item?.totalKg} {prefrence?.weightUnit_value}
                            </p>
                            <span className="bm blur-color">
                            {t("/ Month")}  
                            </span>
                          </div>
                        </div>
                        <div className="icon-bold-small-text">
                          <img src={AmountIcon} alt="" />
                          <div className="bold-small-text">
                            <p className="tm">
                              {item?.amount_per_unit} {prefrence?.currency_value}
                            </p>
                            <span className="bm blur-color">
                            {t("/ Month")}  
                            </span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </Col>
                ))}
            </Row>
          </div>

          <div className="data-wrapper">
            <div className="data-header">
              <div className="two-search">
                <SearchBox
                  placeholder={t("Search Horse...")}
                  onChange={(e: any) => setSearchText(e.target.value)}
                  value={searchText}
                />
                {/* <SearchBox placeholder="Search Time..." /> */}
              </div>
              <div className="right-section">
                <div className="btn-wrapper fb">
                  {/* <CustomeButton>
                    <Filtericon />
                    <p>Filter</p>
                  </CustomeButton> */}
                  <CustomeButton
                    bg="fill"
                    onClick={() => setAddDietModalShow(true)}
                  >
                    <AddPlusIcon />
                    {t("Add")}
                  </CustomeButton>
                </div>
              </div>
            </div>
            <div className="data-body">
              <div className="img-responsive-table double-row first-2-column-fix-web">
                <table>
                  <thead>
                    <tr>
                      {/* <th className="ck-wrapper"> */}
                        {/* <OnlyCk onChange={selectAll} /> */}
                      {/* </th> */}
                      <th className="first-img-wrapper"></th>
                      <th>
                        <p className={`tm icon-text ${sortableClass(
                            "name"
                          )} ${sortingTypeClass("name")}`} 
                          onClick={() => sortrecords("name")}
                          >{t("Horse")}</p>
                      </th>
                      {header &&
                        header?.map((item: any , index:number) => (
                          <th key={item + index}>
                            <p className="tm icon-text">{item}</p>
                          </th>
                        ))}
                    </tr>
                  </thead>
                  <tbody>
                    {dietList &&
                      dietList?.length > 0 &&
                      dietList?.map((items: any, index: number) => (
                        <tr key={index + "myhorse-tbl"}>
                          {/* <td className="ck-wrapper"> */}
                            {/* <OnlyCk
                              checked={checkedRec.includes(items?.id)}
                              disabled={
                                checkedRec.includes(items.id)
                                  ? false
                                  : true
                              }
                              onChange={(e: any) =>
                                checkSingleRec(e, items?.id)
                              }
                            /> */}
                          {/* </td> */}
                          <td className="first-img-wrapper">
                            <img
                              src={items.profile}
                              alt=""
                              className="horse-img"
                            />
                          </td>
                          <td>
                            <div className="icon-text">
                              <img
                                src={getIconbyType(items?.type_slug)}
                                alt=""
                              />
                              <Link to={horseDetailLink(items.id)} className="a-bl">
                                {items.horse_name}
                              </Link>
                            </div>
                          </td>
                          {header?.map((time: any , index1:number) => {
                            return (
                              <td key={index + index1 + "header"}>
                                {items?.health?.[time]?.map((element: any , index2:number) => {
                                  return (
                                    <p
                                      className="bl icon-text p-mb-16"
                                      key={element?.id + index + index1 + index2 +"HeaderTime"}
                                    >
                                      <img src={`${dietCategory.get(element.category_slug)?.icon}`} alt="" />
                                    {element?.product ? `${element?.product}, ` : null} {element?.qty ? `${element?.qty} ${element?.unit_value}` : null} 
                                      
                                    </p>
                                  );
                                })}
                              </td>
                            );
                          })}
                        </tr>
                      ))}
                  </tbody>
                </table>
              </div>
              {dietList?.length ? (
                <Pagination
                  results={recordsTotal}
                  currentPage={currentPage}
                  setCurrentPage={setCurrentPage}
                  currentLength={currentPageSize}
                  setCurrentPageSize={setCurrentPageSize}
                />
              ) : (
                <NoDataFound />
              )}
            </div>
          </div>
        </div>
      </div>
      <DietCalculatorPopup
        show={DietCalculatorShow}
        onHide={() => setDietCalculatorShow(false)}
      />
      <AddDietPopup
        show={AddDietModalShow}
        onHide={() => setAddDietModalShow(false)}
        metaData={metaData}
        // horseid={checkedRec}
        ismultiple={1}
        setrefreshlist={() => {
          setRefreshList(true);
        }}
      />
    </>
  );
}
