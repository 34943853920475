import React from "react";

export default function UserIcon() {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M12.0551 12.6464C10.6398 11.8843 9.44227 10.6867 9.11567 8.94485C9.0068 8.29164 9.11567 7.31183 9.76887 6.54976C10.2043 6.11429 10.8576 5.56995 11.9462 5.56995C13.5792 5.56995 14.5591 6.54976 14.7768 7.7473C14.9945 8.72711 14.6679 10.0335 13.6881 10.9045"
        stroke="#919EAB"
        strokeWidth="1.5"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M4.32549 12.9729C3.23681 12.3197 2.257 11.4488 2.03927 10.0335C1.9304 9.59801 2.03927 8.72707 2.47474 8.18273C2.80134 7.85613 3.34568 7.42065 4.21662 7.42065C5.52303 7.42065 6.28511 8.18273 6.39398 9.16254C6.50284 9.81574 6.39398 10.7956 5.84964 11.4488"
        stroke="#919EAB"
        strokeWidth="1.5"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M18.0428 11.3398C17.4985 10.6865 17.2807 9.8156 17.4985 9.05353C17.7162 8.18259 18.3694 7.31165 19.6758 7.31165C20.5468 7.31165 21.0911 7.63825 21.4177 8.07372C21.9621 8.72693 22.0709 9.489 21.9621 9.92447C21.7443 11.2309 20.7645 12.2107 19.6758 12.8639"
        stroke="#919EAB"
        strokeWidth="1.5"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M17.1719 17.5455C17.1719 17.4366 17.1719 17.2189 17.1719 17.11C17.1719 16.3479 17.1719 15.5859 16.9541 14.8238C16.6275 14.1706 15.43 13.4085 14.7768 13.0819C14.1236 14.1706 13.1437 14.8238 12.0551 14.8238C10.9664 14.8238 9.98658 14.1706 9.22451 13.0819C8.5713 13.4085 7.26489 14.0617 7.04715 14.8238C6.82942 15.3681 6.72055 16.5657 6.72055 18.4164H13.5792"
        stroke="#919EAB"
        strokeWidth="1.5"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}
