import React from "react";

export default function Farrier() {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M17.0151 15.3161C17.1185 14.9024 17.2219 14.5922 17.3254 14.1785"
        stroke="#919EAB"
        strokeMiterlimit="5.3333"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M17.6321 11.8016C17.6321 11.4913 17.6321 11.0776 17.6321 10.7673C17.6321 7.56121 15.0466 5.07904 11.9438 5.07904C8.73772 5.07904 6.25555 7.66463 6.25555 10.7673C6.25555 11.4913 6.35899 12.3187 6.46241 13.0426"
        stroke="#919EAB"
        strokeMiterlimit="5.3333"
        strokeLinecap="round"
        strokeLinejoin="round"
        stroke-dasharray="2.01 2.01"
      />
      <path
        d="M6.58563 14.1899C6.68906 14.6036 6.79248 14.9139 6.89591 15.3276"
        stroke="#919EAB"
        strokeMiterlimit="5.3333"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M12.3636 3.00868C8.01982 2.80183 4.39999 6.31824 4.39999 10.662C4.39999 13.0408 4.71026 15.5229 5.84792 17.488C6.26162 18.1085 7.29585 19.5565 7.9164 20.4873C8.64036 21.4181 10.0883 21.0044 10.1917 19.8667V18.2119C10.1917 17.7983 10.0883 17.2811 9.88144 16.8674C8.84721 15.0058 8.33009 12.9373 8.33009 10.7654C8.33009 8.69698 9.88144 6.83535 11.9499 6.73193C14.1218 6.62851 15.88 8.38671 15.88 10.5586C15.88 12.7305 15.3629 14.9024 14.3287 16.8674C14.1218 17.2811 14.0184 17.6948 14.0184 18.2119V19.8667C14.0184 21.0044 15.5697 21.4181 16.1903 20.4873C16.8108 19.5565 17.7416 18.1085 18.1553 17.488C18.8793 16.0401 19.4998 13.351 19.6033 11.0757C19.7067 6.83535 16.5006 3.21553 12.3636 3.00868Z"
        stroke="#919EAB"
        strokeWidth="1.5"
        strokeMiterlimit="13.3333"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}
