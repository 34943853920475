import type { DatePickerProps } from "antd";
import { t } from "i18next";
import moment from "moment";
import React, { useState } from "react";
import Modal from "react-bootstrap/Modal";
import CustomeButton from "../../Common/CustomButton/CustomButton";
import Datepicker from "../../Common/Datepicker/Datepicker";
import InputGroups from "../../Common/InputGroups/InputGroups";
import SelectGropus from "../../Common/SelectGroups/SelectGroups";
import TextButton from "../../Common/TextButton/TextButton";
import ToggleButton from "../../Common/ToggleButton/ToggleButton";
import TooltipContent from "../../Common/TooltipContent/TooltipContent";

export default function PregnancyStatusPopup(props: any) {
  const toggleBtnValue = [
    { name: t("Yes"), value: "Yes" },
    { name: t("No"), value: "No" },
  ];
  const [selected, setSelected] = useState("Yes");

  const dateChange: DatePickerProps["onChange"] = (date, dateString) => {
    
  };

  const status = ["Pregnant", "Unpregnant"];

  return (
    <Modal {...props} centered className="pregnancy-status-popup modal-416">
      <Modal.Header closeButton>
        <p className="tl">Change Pregnancy Status </p>
      </Modal.Header>
      <Modal.Body>
        <div>
          <SelectGropus label="Status" value={status} />
          <InputGroups label="Stallion" />
          <Datepicker
            label="Breeding Date"
            onChange={dateChange}
            defaultValue={moment()}
          />
          <ToggleButton
            value={toggleBtnValue}
            selected={selected}
            setSelected={setSelected}
          ><TooltipContent title="To be flushed?*" tooltipValue="this is tooltip value." /></ToggleButton>
        </div>
      </Modal.Body>
      <Modal.Footer>
        <div className="footer-2-btn">
          <TextButton> {t("Cancel")}</TextButton>
          <CustomeButton bg="fill">Save</CustomeButton>
        </div>
      </Modal.Footer>
    </Modal>
  );
}
