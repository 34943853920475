import { DatePicker } from "antd";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import ExportIcon from "../../Assets/img/export.svg";
import FemaleIcon from "../../Assets/img/Female.svg";
import MaleIcon from "../../Assets/img/Male.svg";
import PregnantIcon from "../../Assets/img/Pregnant.svg";
import TrainingIcon from "../../Assets/img/Training-primary.svg";
import TrainingCalIcon from "../../Assets/img/TrainingSchedule.svg";
import { TRAINING, TRAINING_CALENDER } from "../../Constant/Route";
import ChevronLeftIcon from "../../Assets/img/chevron-left-primary.svg";
import ChevronRightIcon from "../../Assets/img/chevron-right-primary.svg";
import { getTrainingSchedule } from "../../Redux/NonRedux/training";
import { SUCCESS_RESPONSE_CODE } from "../../Constant/Status";
import Loader from "../Loader/Loader";
import { useDispatch, useSelector } from "react-redux";
import { metaHorseListAction } from "../../Redux/General/GeneralAction";
import { MALE } from "../../Constant/General";
import { useLoading } from "../../context/LoadingContext";
import { Tooltip } from "@mui/material";
import { t } from "i18next";

export default function Training() {
  const [selectedMonthYear, setSelectedMonthYear] = React.useState(
    moment().format("YYYY-MM")
  );
  const [lastDate, setLastDate] = React.useState(1);
  const [dates, setDates] = React.useState([]);
  const [eventsData, setEventsData] = useState([]);
  const { setLoading } = useLoading();
  const [exportParams, setExportParams] = useState<any>({});

  const eventColor = ["green", "blue", "yellow", "red"];
  const dispatch = useDispatch();
  const { state }: any = useLocation();

  const horseList: any = useSelector(
    (state: any) => state?.metaHorseListReducer?.data?.component
  );

  useEffect(() => {
    if (state?.date) {
      setSelectedMonthYear(moment(state?.date).format("YYYY-MM"));
    }
  }, [state]);

  useEffect(() => {
    let params = {};
    metaHorseListAction(params);

    setLoading(true);
    getTrainingSchedule({})
      .then((response: any) => {
        if (response.statusCode === SUCCESS_RESPONSE_CODE) {
          setEventsData(
            response?.component?.map((item: any) => {
              let formatedDate = item.startDate;
              formatedDate = formatedDate.replaceAll("/", "-");
              formatedDate = moment(formatedDate, "DD-MM-YYYY").format(
                "YYYY-MM-DD"
              );
              let endDate = item.endDate;
              endDate = moment(endDate, "DD/MM/YYYY").format("YYYY-MM-DD");
              return { ...item, startDate: formatedDate, endDate: endDate };
            })
          );
        }
        setLoading(false);
      })
      .catch((error) => {
        setLoading(false);
      });
  }, []);

  useEffect(() => {
    initDates();
  }, [lastDate]);

  useEffect(() => {
    initMonth();
  }, [selectedMonthYear]);

  const initMonth = () => {
    let date = selectedMonthYear + "-01";
    let endDate = moment(date).endOf("month").format("DD");
    setLastDate(parseInt(endDate));
  };
  const initDates = () => {
    let content: any = [];
    for (let i = 0; i < lastDate; i++) {
      let insert: any = i + 1;
      insert = "0" + insert;
      insert = insert.slice(-2);
      content.push(insert);
    }
    setDates(content);
  };

  const getDate = (date: any) => {
    let dateIs = selectedMonthYear + "-" + date;
    let day = moment(dateIs).format("dddd").substring(0, 3);
    return date + " " + day;
  };

  const getDayDiff = (date1: any, date2: any) => {
    if (moment(date1).format("YYYY-MM") == moment(date2).format("YYYY-MM")) {
      date1 = moment(date1);
      date2 = moment(date2);
      let diff = date2.diff(date1, "days");
      return diff + 1;
    } else {
      if (selectedMonthYear == moment(date1).format("YYYY-MM")) {
        date1 = moment(date1);
        date2 = moment(selectedMonthYear + "-" + lastDate);
        let diff = date2.diff(date1, "days");
        return diff + 1;
      } else {
        date1 = moment(selectedMonthYear + "-01");
        date2 = moment(date2);
        let diff = date2.diff(date1, "days");
        return diff + 1;
      }
    }
  };

  const tooltipValue = (item: any) => {
    return (
      <div className="info-table tooltip-tbl">
        <table>
          <tbody>
            <tr>
              <td>
                <p className="tm">{t("Training Type")}</p>
              </td>
              <td>
                <p className="tm blur-color">{item?.title}</p>
              </td>
            </tr>
            <tr>
              <td>
                <p className="tm">{t("Trainer")}</p>
              </td>
              <td>
                <p className="tm blur-color">{item?.trainer}</p>
              </td>
            </tr>
            <tr>
              <td>
                <p className="tm">{t("Duration")}</p>
              </td>
              <td>
                <p className="tm blur-color">
                  {/* {item?.startTime} {item?.endTime ? t("to ") :""}
                  {item?.endTime} */}
                  {item?.endTime ? t('from_time_to_time', {FROM_TIME: item?.startTime,TO_TIME:item?.endTime}) :item?.startTime}
                </p>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    );
  };

  return (
    <>
      <div className="training-page">
        {/* Page Title Start */}
        <div className="page-title-wrapper">
          <div className="title-first-menu">
            <div className="title-icon-text">
              <img src={TrainingIcon} alt="" />
              <h5>{t("Training")}</h5>
            </div>
            <div className="title-right-icons">
              <Link className="unset-links" to={TRAINING}>
                <img src={TrainingCalIcon} alt="" />
              </Link>
              {/* {checkRoutePermission(PERMISSIONS?.EXPORT_FEATURE) && (
                <a className="unset-links" onClick={handleExport}>
                  <img src={ExportIcon} alt="" />
                </a>
              )} */}
            </div>
          </div>
        </div>
        {/* Page Title End */}

        <div className="page-content">
          <div className="calender-header">
            <p className="tl">{t("Training Schedule")}</p>
            <div className="month-calender">
              <p className="tl">
                {moment(selectedMonthYear).format("MMMM YYYY")}
              </p>
              <DatePicker
                picker="month"
                onChange={(e: any) => {
                  setSelectedMonthYear(e.format("YYYY-MM"));
                }}
              />
            </div>
            <div className="today-section">
              <button
                className="nav-icon"
                onClick={() => {
                  setSelectedMonthYear(
                    moment(selectedMonthYear + "-01")
                      .subtract(1, "M")
                      .format("YYYY-MM")
                  );
                }}
              >
                <img src={ChevronLeftIcon} alt="" />
              </button>
              <button
                className="bl"
                onClick={() => {
                  setSelectedMonthYear(moment().format("YYYY-MM"));
                }}
              >
                {t("Today")}
              </button>
              <button
                className="nav-icon"
                onClick={() => {
                  setSelectedMonthYear(
                    moment(selectedMonthYear + "-01")
                      .add(1, "M")
                      .format("YYYY-MM")
                  );
                }}
              >
                <img src={ChevronRightIcon} alt="" />
              </button>
            </div>
          </div>
          <div className="calender-table">
            <table>
              <thead>
                <tr>
                  <th></th>
                  {dates?.map((items: any, index: number) => (
                    <th key={index + Math.random()}>
                      <p className="bm">{getDate(items)}</p>
                    </th>
                  ))}
                </tr>
              </thead>
              <tbody>
                {horseList?.map((horseValue: any, index: number) => (
                  <tr key={"tr" + index + Math.random()}>
                    <td>
                      <p className="bl icon-text">
                        <img
                          src={
                            horseValue?.type_slug == MALE
                              ? MaleIcon
                              : FemaleIcon
                          }
                          alt=""
                        />
                        {horseValue?.name}
                      </p>
                    </td>
                    {dates &&
                      dates.length > 0 &&
                      dates?.map((items: any, index2: number) => (
                        <td key={index2 + Math.random()}>
                          {eventsData?.map((e: any) => {
                            if (horseValue.id == e.horse_id) {
                              let date = selectedMonthYear + "-" + items;
                              let day = moment(date).format("d").toString();
                              day = day == "0" ? "7" : day;
                              if (
                                moment(date).isBetween(
                                  e.startDate,
                                  e.endDate,
                                  null,
                                  "[]"
                                ) &&
                                e.days.includes(day)
                              ) {
                                let days = getDayDiff(e.startDate, e.endDate);
                                let endMonthYear = moment(e.endDate).format(
                                  "YYYY-MM"
                                );
                                return (
                                  <div
                                    className={
                                      "event-wrapper" +
                                      (endMonthYear != selectedMonthYear
                                        ? " end-between"
                                        : "")
                                    }
                                  >
                                    <div
                                      className={
                                        "events " + eventColor[index % 4]
                                      }
                                      style={{
                                        width: "100%",
                                      }}
                                    >
                                      <Tooltip
                                        title={tooltipValue(e)}
                                        arrow={true}
                                      >
                                        <div>
                                          <label className="ll">
                                            {e.title}
                                          </label>
                                          <span className="lm time">
                                            {e.startTime} to {e.endTime}
                                          </span>
                                        </div>
                                      </Tooltip>
                                    </div>
                                  </div>
                                );
                              }
                              // if (date === e.startDate) {
                              //   let days = getDayDiff(e.startDate, e.endDate);
                              //   let endMonthYear = moment(e.endDate).format(
                              //     "YYYY-MM"
                              //   );
                              //   return (
                              //     <div
                              //       className={
                              //         "event-wrapper" +
                              //         (endMonthYear != selectedMonthYear
                              //           ? " end-between"
                              //           : "")
                              //       }
                              //     >
                              //       <div
                              //         className={
                              //           "events " + eventColor[index % 4]
                              //         }
                              //         style={{
                              //           width:
                              //             "calc(" +
                              //             days * 100 +
                              //             "% + " +
                              //             days +
                              //             "px)",
                              //         }}
                              //         title={
                              //           e.title +
                              //           ", " +
                              //           e.startTime +
                              //           " to " +
                              //           e.endTime
                              //         }
                              //       >
                              //         <label className="ll">{e.title}</label>
                              //         <span className="lm time">
                              //           {e.startTime} to {e.endTime}
                              //         </span>
                              //       </div>
                              //     </div>
                              //   );
                              // } else {
                              //   if (
                              //     moment(date).isBetween(
                              //       e.startDate,
                              //       e.endDate
                              //     ) ||
                              //     moment(date).isSame(e.endDate)
                              //   ) {
                              //     if (
                              //       moment(e.startDate).format("YYYY-MM") !=
                              //       selectedMonthYear
                              //     ) {
                              //       let startDate = selectedMonthYear + "-01";
                              //       if (date === startDate) {
                              //         let days = getDayDiff(
                              //           startDate,
                              //           e.endDate
                              //         );
                              //         return (
                              //           <div className="event-wrapper start-between">
                              //             <div
                              //               className={
                              //                 "events " + eventColor[index % 4]
                              //               }
                              //               style={{
                              //                 width:
                              //                   "calc(" +
                              //                   days * 100 +
                              //                   "% + " +
                              //                   days +
                              //                   "px)",
                              //               }}
                              //               title={
                              //                 e.title +
                              //                 ", " +
                              //                 e.startTime +
                              //                 " to " +
                              //                 e.endTime
                              //               }
                              //             >
                              //               <label className="ll">
                              //                 {e.title}
                              //               </label>
                              //               <span className="lm time">
                              //                 {e.startTime} to {e.endTime}
                              //               </span>
                              //             </div>
                              //           </div>
                              //         );
                              //       }
                              //     } else {
                              //       return (
                              //         <div className="event-wrapper"></div>
                              //       );
                              //     }
                              //   }
                              // }
                            }
                          })}
                        </td>
                      ))}
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </>
  );
}
