import React from "react";

export default function FilmIcon() {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M19.5096 21.0547H4.49043C3.68788 21.0547 3 20.3668 3 19.5642V4.43045C3 3.6279 3.68788 2.94 4.49043 2.94H19.5096C20.3121 2.94 21 3.6279 21 4.43045V19.5642C21 20.3668 20.3121 21.0547 19.5096 21.0547Z"
        stroke="#919EAB"
        strokeWidth="1.5"
        strokeMiterlimit="13.3333"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M18.1338 12.5706C18.2484 11.9974 16.2994 9.24576 15.4968 8.90181C14.6943 8.55787 9.53503 7.18207 9.07643 7.41137C9.07643 7.41137 6.21019 11.5388 5.86624 12.7999C5.75159 13.3732 5.86624 13.9464 6.09554 14.405"
        stroke="#919EAB"
        strokeWidth="1.5"
        strokeMiterlimit="13.3333"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M16.758 14.0611C17.5605 13.3732 18.0191 12.7999 18.1338 12.5706"
        stroke="#919EAB"
        strokeWidth="1.5"
        strokeMiterlimit="13.3333"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M6.09558 14.405C6.43953 14.9783 7.01278 15.4369 7.92998 15.8955C8.04463 15.8955 8.15928 16.0101 8.27393 16.0101"
        stroke="#919EAB"
        strokeWidth="1.5"
        strokeMiterlimit="13.3333"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M7.2421 10.8509C8.50324 10.507 9.87904 10.163 11.4841 9.93374C13.2039 9.70444 15.1529 9.93374 16.5287 9.81909"
        stroke="#919EAB"
        strokeWidth="0.75"
        strokeMiterlimit="13.3333"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M8.61783 16.2394C9.53503 14.8636 9.30573 13.1439 8.96178 11.9974C8.73248 11.1948 8.96178 10.3923 9.53503 9.81903L11.3694 7.64069"
        stroke="#919EAB"
        strokeMiterlimit="13.3333"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M11.4841 16.698C11.9427 14.5196 11.828 12.9145 11.5987 11.8827C11.3694 10.9655 11.7134 9.93365 12.4013 9.3604L13.6624 8.32855"
        stroke="#919EAB"
        strokeMiterlimit="13.3333"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M13.6624 10.163C13.6624 10.163 14.121 10.9656 14.3503 12.3414C14.5796 13.4879 14.3503 15.8955 14.3503 15.8955"
        stroke="#919EAB"
        strokeMiterlimit="13.3333"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}
