import React, { useEffect, useState } from "react";
import calculatorIcon from "../../../Assets/img/calculator.svg";

const InputCalcuGroups = (props: any) => {
  return (
    <div className="input-calcu-groups">
      <label>{props.label}</label>
      <div className="input-wrapper">
        <div className="calcu-btn" onClick={props.onBtnClick}>
          <img  src={calculatorIcon} alt="" />
        </div>
        <input
          type={props.type}
          inputMode={props.inputmode}
          defaultValue={props.defaultValue}
          value={props.value}
          placeholder={props.placeholder}
          readOnly={props.readOnly}
          autoComplete={props.autoComplete}
          name={props.name}
          id={props.id}
          className={props.error && "error"}
          onChange={props.onChange}
        />
      </div>
      {props.error && <span className="error">{props.error}</span>}
    </div>
  );
};

export default InputCalcuGroups;
