import React from "react";

export default function BookIcon() {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M4.94556 21H21.4275V3H4.94556V21Z"
        stroke="#E8E5E2"
        strokeWidth="1.5"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M6.24674 7.66266H2.56"
        stroke="#E8E5E2"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M6.24674 12H2.56"
        stroke="#E8E5E2"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M6.24674 16.3374H2.56"
        stroke="#E8E5E2"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M8.41541 3V21"
        stroke="#E8E5E2"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M14.9214 11.4578C15.9395 11.4578 16.7648 10.6325 16.7648 9.61446C16.7648 8.59639 15.9395 7.77109 14.9214 7.77109C13.9033 7.77109 13.078 8.59639 13.078 9.61446C13.078 10.6325 13.9033 11.4578 14.9214 11.4578Z"
        stroke="#E8E5E2"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M11.6684 16.2289C11.6684 14.3855 13.0781 12.9759 14.9214 12.9759C16.7648 12.9759 18.1745 14.3855 18.1745 16.2289"
        stroke="#E8E5E2"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}
