import React from "react";

export default function PregnantFill() {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M3.04073 19.9801C7.70253 20.2015 12.4392 18.5723 15.9994 15.0924C19.1336 12.029 20.8005 8.07452 21 4.06328C5.57175 3.05904 2.59872 14.2561 3.04073 19.9801Z"
        fill="#212B36"
        stroke="#212B36"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}
