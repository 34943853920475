import type { DatePickerProps } from "antd";
import { t } from "i18next";
import moment from "moment";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { END_POINTS } from "../../../Constant/Api";
import {
  INVOICING_MANUAL_INVOICE,
  INVOICING_NEW_INVOICE,
} from "../../../Constant/Route";
import { useLoading } from "../../../context/LoadingContext";
import {
  getInstantContact,
} from "../../../Redux/globallActions";
import { getListMetaDataApi } from "../../../Redux/NonRedux/general";
import { getInvoiceLineItems, invoiceHorseList } from "../../../Redux/NonRedux/invoice";
import CustomeButton from "../../Common/CustomButton/CustomButton";
import Datepicker from "../../Common/Datepicker/Datepicker";
import NoDataFound from "../../Common/NoDataFound/NoDataFound";
import OnlyCk from "../../Common/OnlyCk/OnlyCk";
import SelectGropus from "../../Common/SelectGroups/SelectGroups";

interface ContactList {
  first_name: string;
  id: number;
  last_name: string;
  name: number;
}

interface LineItems {
  id: number;
  horse_name: string;
  horse_id: number;
  owner: string;
  date: string;
  currency: number;
  currency_value?: string;
  qty: number;
  unit_price: number;
  total_price: number;
  category_id: number;
  category_value: string;
  sub_categoty?: string;
}

const INTIAL_FILTER_DATA = {
  from:  moment().subtract(30,"days").format("YYYY-MM-DD"),
  to: moment().format("YYYY-MM-DD"),
  owner_id: "",
  horse_id: "",
};

export default function ServiceToInvoicePage() {
  let navigate = useNavigate();
  const [ownerList, setOwnerList] = useState<ContactList[]>([]);
  const [horseList, setHorseList] = useState<any>([]);
  const [lineItems, setLineItems] = useState<LineItems[]>([]);
  const { setLoading } = useLoading();
  const [filter, setFilter] = useState<any>(INTIAL_FILTER_DATA);
  const [showLineItems, setShowLineItems] = useState<boolean>(false);
  const [checkedRec, setCheckedRec] = useState<number[]>([]);
  const [metaData, setMetaData] = useState<any>({});
  const prefrence = useSelector(
    (state: any) => state?.masterReducer?.data?.component?.preferance
  );
  const setData = (field: string, value: string) => {
    const tempObj: any = Object.assign({}, filter);
    tempObj[field] = value;
    setFilter({ ...tempObj });
  };

  const fromDateChange: DatePickerProps["onChange"] = (date, dateString) => {
    let dateIs : any = date?.format("YYYY-MM-DD");
    setData("from", dateIs);
  };

  const toDateChange: DatePickerProps["onChange"] = (date, dateString) => {
    let dateIs : any = date?.format("YYYY-MM-DD");
    setData("to", dateIs);
  };

  const selectAll = (e: any) => {
    let checkedRecord = [];
    let ids = lineItems.map((item: any) => item.id);
    if (e.target.checked) {
      checkedRecord = ids;
    } else {
      checkedRecord = [];
    }
    setCheckedRec(checkedRecord);
  }

  const checkSingleRec = (e: any, val: number) => {
    var checkedRecData: number[] = Object.assign([], checkedRec);
    if (e.target.checked) {
      checkedRecData.push(val);
    } else {
      checkedRecData = checkedRecData.filter((c: number) => c !== val);
    }
    setCheckedRec(checkedRecData);
  };


  const getMetaData = () => {
    setLoading(true);
    getListMetaDataApi(END_POINTS.metaInvoices)
      .then((response: any) => {
        setMetaData(response?.component);
        setHorseList(response?.component?.horse_list);
        setOwnerList(response?.component?.owner_list);
      })
      .catch((error: any) => {})
      .finally(() => {
        setLoading(false);
      });
  };

  useEffect(() => {
   getMetaData();
  }, []);

  const ownerChange = (val:string) => {
    invoiceHorseList({ owner_id: val })
      .then((res: any) => {
        setHorseList(res?.component);
      })
      .finally(() => {
      });
  }

  const getListData = async () => {
    setLoading(true);
    getInvoiceLineItems(filter)
      .then((res: any) => {
        setLineItems(res?.component?.listing);
        setShowLineItems(true);
      })
      .finally(() => {
        setLoading(false);
      });
  };



  return (
    <>
      <div className="service-to-invoice-page">
        <div className="date-and-input-filter">
          <Datepicker
            label={`${t("From")}*`}
            onChange={fromDateChange}
            endDate={moment().format("YYYY-MM-DD")}
            value={moment(filter?.from)}
          />
          <Datepicker
            label={`${t("To")}*`}
            startDate={moment(filter?.from).format("YYYY-MM-DD")}
            onChange={toDateChange}
            value={moment(filter?.to)}
          />
          <SelectGropus
            label={`${t("Owner")}`}
            dropKey="id"
            dropVal="name"
            value={ownerList}
            defaultSelect={filter?.owner_id}
            onChange={(e: any) => {
              setFilter({
                ...filter,
                owner_id:e.target.value,
                horse_id:"",
              })
              ownerChange(e.target.value);
            }}
          />
          <SelectGropus
            label={`${t("Horse")}`}
            dropKey="id"
            dropVal="name"
            defaultSelect={filter?.horse_id}
            value={horseList}
            onChange={(e: any) => setData("horse_id", e.target.value)}
          />
        </div>

        <div className="fill-btn-wrapper">
          <CustomeButton
            bg="fill"
            onClick={getListData}
            disabled={!(filter.owner_id != "" || filter?.horse_id != "")}
          >
           {t("Show Line Items")} 
          </CustomeButton>
          <CustomeButton
            bg="fill"
            onClick={() => {
              navigate(INVOICING_MANUAL_INVOICE);
            }}
          >
           {t("Manual Invoice")}
          </CustomeButton>
        </div>
        {showLineItems ? (
          <>
            <div className="data-tbl-wrapper">
              <div className="data-tbl-header">
                <p className="bl">
                {t("Select items from the below list to generate invoice.")}   
                </p>
                <div className="btn-wrapper single-btn">
                 
                  <CustomeButton
                    bg="fill"
                    disabled={!(checkedRec?.length)}
                    onClick={() => {
                      navigate(INVOICING_NEW_INVOICE , {state:{records:checkedRec}});
                    }}
                  >
                  {t("Generate Invoice")}  
                  </CustomeButton>
                </div>
              </div>
              <div className="data-tbl last-width-148">
                <table>
                  <thead>
                    <tr>
                      <th className="ck-wrapper">
                      <OnlyCk onChange={selectAll}
                       checked={checkedRec?.length === lineItems?.length }
                       />
                      </th>
                      <th>
                        <p className="tm">{t("Date")} </p>
                      </th>
                      <th>
                        <p className="tm"> {t("Owner")} </p>
                      </th>
                      <th>
                        <p className="tm">{t("Horse")} </p>
                      </th>
                      <th>
                        <p className="tm"> {t("Service/Product")} </p>
                      </th>
                      <th>
                        <p className="tm"> {t("Quantity")} </p>
                      </th>
                      <th>
                        <p className="tm text-right">{t("Unit Price")} </p>
                      </th>
                      <th>
                        <p className="tm text-right">{t("Total")} </p>
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {lineItems.map((items: any, index: number) => (
                      <tr key={index + "category-data"}>
                        <td className="ck-wrapper">
                        <OnlyCk
                              checked={checkedRec.includes(items?.id)}
                              // disabled={
                              //   checkedRec.includes(items.id)
                              //     ? false
                              //     : true
                              // }
                              onChange={(e: any) =>
                                checkSingleRec(e, items?.id)
                              }
                            />
                        </td>
                        <td>
                          <p className="bl">{items.date}</p>
                        </td>
                        <td>
                          <p className="bl">{items.owner}</p>
                        </td>
                        <td>
                          <p className="bl">{items.horse_name}</p>
                        </td>
                        <td>
                          <p className="bl">{items.category_value}</p>
                        </td>
                        <td>
                          <p className="bl">{items.qty}</p>
                        </td>
                        <td>
                          <p className="bl text-right">{items.unit_price} {prefrence?.currency_value}</p>
                        </td>
                        <td>
                          <p className="bl text-right">{items.total_price} {prefrence?.currency_value}</p>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
              {lineItems?.length ? null : <NoDataFound />}
            </div>
          </>
        ) : null}
      </div>
    </>
  );
}
