import React from "react";

export default function HeartPulseIcon() {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M15.9886 17.1077C14.3523 18.6418 12.7159 19.6646 12 20.0737C11.8977 20.0737 11.8977 20.1759 11.7955 20.1759C11.6932 20.2782 11.5909 20.2782 11.5909 20.2782C11.5909 20.2782 11.4886 20.2782 11.3864 20.1759C11.3864 20.1759 11.2841 20.0737 11.1818 20.0737C10.4659 19.6646 8.625 18.5396 6.98864 16.8009C5.25 15.0623 3.51136 12.71 3.10227 9.84639C3.10227 9.74411 3.10227 9.64184 3.10227 9.64184C3.10227 9.4373 3.10227 9.33502 3 9.13048C3 9.0282 3 9.0282 3 8.92593C3 8.72139 3 8.51684 3 8.3123C3 5.75548 5.04545 3.71002 7.60227 3.71002C9.44318 3.71002 10.9773 4.63048 11.6932 6.16457C12.4091 4.63048 13.9432 3.71002 15.7841 3.71002C18.3409 3.71002 20.3864 5.75548 20.3864 8.3123C20.3864 8.51684 20.3864 8.72139 20.3864 8.92593C20.3864 9.0282 20.3864 9.0282 20.3864 9.13048"
        stroke="#919EAB"
        strokeWidth="1.5"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M6.57956 11.2782H9.4432L11.1818 15.2669L14.4546 8.41461L17.9318 13.7328H21"
        stroke="#919EAB"
        strokeWidth="1.5"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}
