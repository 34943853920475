import { t } from "i18next";
import React from "react";
import { Col, Row } from "react-bootstrap";
import Modal from "react-bootstrap/Modal";
import FemaleIcon from "../../../Assets/img/Female.svg";
import MaleIcon from "../../../Assets/img/Male.svg";
import { PEDIGREE_LAYER_CLASS } from "../../../Constant/General";

export default function CompletePedigreePopup(props: any) {
  const { pedigreedata } = props;

  return (
    <Modal {...props} centered className="complete-pedigree-popup modal-1192">
      <Modal.Header closeButton>
        <p className="tl">{t("Complete Pedigree")}</p>
      </Modal.Header>
      <Modal.Body>
        <div className="overflow-x-auto">
          <Row
            className={`pedhi-aambo ${
              PEDIGREE_LAYER_CLASS[pedigreedata?Object.keys(pedigreedata).length:3]
            }`}
          >
            {pedigreedata &&
              pedigreedata?.span_32?.map((item: any, index: number) => (
                <Col key={"cspan_32" + index}>
                  <div
                    className={`layer-box ${
                      item.gender === "m" ? "male" : "female"
                    }`}
                  >
                    <div className="icon-24-text">
                      <img
                        src={`${item.gender === "m" ? MaleIcon : FemaleIcon}`}
                        alt=""
                      />
                      <p className="bl">
                        {item?.horseName}
                        {item?.year ? ", " + item?.year : ""}
                      </p>
                    </div>
                  </div>
                </Col>
              ))}
            {pedigreedata && pedigreedata?.span_16 && (
              <Col>
                {pedigreedata &&
                  pedigreedata?.span_16?.map((item: any, index: number) => (
                    <div
                      className={`layer-box ${
                        item.gender === "m" ? "male" : "female"
                      }`}
                      key={"cspan_16" + index}
                    >
                      <div className="icon-24-text">
                        <img
                          src={`${item.gender === "m" ? MaleIcon : FemaleIcon}`}
                          alt=""
                        />
                        <p className="bl">
                          {item?.horseName}
                          {item?.year ? ", " + item?.year : ""}
                        </p>
                      </div>
                    </div>
                  ))}
              </Col>
            )}
            {pedigreedata && pedigreedata?.span_8 && (
              <Col>
                {pedigreedata &&
                  pedigreedata?.span_8?.map((item: any, index: number) => (
                    <div
                      className={`layer-box ${
                        item.gender === "m" ? "male" : "female"
                      }`}
                      key={"cspan_8" + index}
                    >
                      <div className="icon-24-text">
                        <img
                          src={`${item.gender === "m" ? MaleIcon : FemaleIcon}`}
                          alt=""
                        />
                        <p className="bl">
                          {item?.horseName}
                          {item?.year ? ", " + item?.year : ""}
                        </p>
                      </div>
                    </div>
                  ))}
              </Col>
            )}
            {pedigreedata && pedigreedata?.span_4 && (
              <Col>
                {pedigreedata &&
                  pedigreedata?.span_4?.map((item: any, index: number) => (
                    <div
                      className={`layer-box ${
                        item.gender === "m" ? "male" : "female"
                      }`}
                      key={"cspan_4" + index}
                    >
                      <div className="icon-24-text">
                        <img
                          src={`${item.gender === "m" ? MaleIcon : FemaleIcon}`}
                          alt=""
                        />
                        <p className="bl">
                          {item?.horseName}
                          {item?.year ? ", " + item?.year : ""}
                        </p>
                      </div>
                    </div>
                  ))}
              </Col>
            )}
            {pedigreedata && pedigreedata?.span_2 && (
              <Col>
                {pedigreedata &&
                  pedigreedata?.span_2?.map((item: any, index: number) => (
                    <div
                      className={`layer-box ${
                        item.gender === "m" ? "male" : "female"
                      }`}
                      key={"cspan_2" + index}
                    >
                      <div className="icon-24-text">
                        <img
                          src={`${item.gender === "m" ? MaleIcon : FemaleIcon}`}
                          alt=""
                        />
                        <p className="bl">
                          {item?.horseName}
                          {item?.year ? ", " + item?.year : ""}
                        </p>
                      </div>
                    </div>
                  ))}
              </Col>
            )}
          </Row>
        </div>
      </Modal.Body>
    </Modal>
  );
}
