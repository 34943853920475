import type { DatePickerProps } from "antd";
import { t } from "i18next";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { Col, Row } from "react-bootstrap";
import Modal from "react-bootstrap/Modal";
import { useDispatch, useSelector } from "react-redux";
import { API_STATUS } from "../../../Constant/Api";
import { FORMLABEL } from "../../../Constant/General";
import { SUCCESS_RESPONSE_CODE } from "../../../Constant/Status";
import { useLoading } from "../../../context/LoadingContext";
import { clinicContactAction } from "../../../Redux/General/GeneralAction";
import { addEditHealthAction } from "../../../Redux/Horse/Health/SingleHealthAction";
import {
  ADD_HORSE_HEALTH_FAILD,
  ADD_HORSE_HEALTH_LONG,
  ADD_HORSE_HEALTH_REQUEST,
  ADD_HORSE_HEALTH_SUCCESS,
} from "../../../Redux/Horse/Health/SingleHorseType";
import {
  isFileValidated,
  getFileNamefromPath,
  getFileNamefromLink,
} from "../../../Utility/General";
import validate from "../../../Utility/Validation";
import CustomeButton from "../../Common/CustomButton/CustomButton";
import Datepicker from "../../Common/Datepicker/Datepicker";
import FileUpload from "../../Common/FileUpload/FileUpload";
import InputGroups from "../../Common/InputGroups/InputGroups";
import InputSelectGroup from "../../Common/InputSelectGroup/InputSelectGroup";
import InputSelectMix from "../../Common/InputSelectMix/InputSelectMix";
import SelectGropus from "../../Common/SelectGroups/SelectGroups";
import TextButton from "../../Common/TextButton/TextButton";

export default function AddTreatmentsPopup(props: any) {
  const INITIAL_VITAL_HEALTH_DATA: any = {
    form_type: {
      value: "treatment",
      validation: ["required"],
      errors: [t("Form Type is required.")],
    },
    horse_ids: {
      value: "",
      validation: [""],
      errors: [t("Horse is required.")],
    },

    select_vet_clinic: {
      value: "vet_clinic_id",
      // validation: ["requiredIf:vet_clinic_id"],
      errors: [t("Dewormer Active Ingredient is required.")],
    },
    vet_clinic: {
      value: "",
      validation: ["requiredIf:select_vet_clinic:vet_clinic", "letters"],
      errors: [t("Vet Clinic is required."), t("Incorrect format")],
    },
    vet_clinic_id: {
      value: "",
      validation: ["requiredIf:select_vet_clinic:vet_clinic_id"],
      errors: [t("Vet Clinic is required.")],
    },

    person_id: {
      value: "",
      validation: [],
      errors: [t("Person is required.")],
    },
    product_name: {
      value: "",
      validation: [""],
      errors: [t("Product Name is required.")],
    },
    route: {
      value: "",
      validation: ["required"],
      errors: [t("Route is required.")],
    },
    dosage: {
      value: "",
      validation: ["required"],
      errors: [t("Dosage is required.")],
    },
    frequency: {
      value: "",
      validation: ["required"],
      errors: [t("Frequency is required.")],
    },
    time: {
      value: "",
      validation: ["required"],
      errors: [t("Time is required.")],
    },
    days: {
      value: "",
      validation: ["required"],
      errors: [t("Days is required.")],
    },
    amount: {
      value: "",
      validation: [""],
      errors: [t("Amount is required.")],
    },
    document_file: {
      value: "",
      // validation: ["required"],
      // errors: [t("Document File is required.")],
    },
    date: {
      value: moment().format("YYYY-MM-DD"),
      validation: ["required"],
      errors: [t("Date is required.")],
    },
    // time: {
    //   value: moment().format("YYYY-MM-DD HH:mm"),
    //   validation: ["required"],
    //   errors: ["Time is required."],
    // },
    id: {
      value: "",
    },
  };
  const [healthData, setHealthData] = useState(INITIAL_VITAL_HEALTH_DATA);

  const [apiStatus, setApiStatus] = useState(API_STATUS.SUCCESS);
  const [isFormValidated, setIsFormValidated] = useState(false);
  let demo: any = {};
  const [healthDataError, setHealthDataError] = useState(demo);
  const [timeData, setTimeData] = useState<any>([]);
  const [daysData, setDaysData] = useState<any>([]);

  const dateChange: DatePickerProps["onChange"] = (date, dateString) => {
    let dateIs = date?.format("YYYY-MM-DD");
    setData("date", dateIs);
  };

  
  let frequencyData = [
    { value: "1", name: `1 ${t("time a day")}` },
    { value: "2", name:`2 ${t("times a day")}`},
    { value: "3", name:`3 ${t("times a day")}`},
    { value: "4", name:`4 ${t("times a day")}`},
    { value: "5", name:`5 ${t("times a day")}`},
  ];

  const initTimes = () => {
    let hoursUpto = 24;
    let initArray = [];
    for (let i = 0; i < hoursUpto; i++) {
      let insert: any = i + 1;
      let name = insert > 1 ? insert + ` ${t("hours")}` : insert + ` ${t("hour")}` ;
      initArray.push({ name: name, value: insert });
    }
    setTimeData(initArray);
  };
  const initDates = () => {
    let hoursUpto = 31;
    let initArray = [];
    for (let i = 0; i < hoursUpto; i++) {
      let insert: any = i + 1;
      initArray.push({ name: insert, value: insert });
    }
    setDaysData(initArray);
  };

  const [fileDataName, setFileDataName] = useState<any>(t("Upload Documents"));
  const [fileData, setFileData] = useState<any>(t("Upload Documents"));
  const [removeDoc, setRemoveDoc] = useState<any>(0);
  const [fileDataSelected, setFileDataSelected] = useState("");
  const prefrence = useSelector(
    (state: any) => state?.masterReducer?.data?.component?.preferance
  );
  const allowedFormate = ["jpg", "png", "pdf", "docx" , "heic" , "heif", "jpeg"];
  const allowedFileSizeInMb = 3;

  const contacts = useSelector(
    (state: any) => state?.clinicContactReducer?.data?.component
  );

  const { setLoading } = useLoading();

  const [vetToggle, setVetToggle] = useState("select");

  const fileChange = (e: any) => {
    let file = e.target.files[0];
    if (isFileValidated(file, allowedFormate, allowedFileSizeInMb)) {
      let fileName = getFileNamefromPath(e.target.value);
      setFileDataSelected(fileName);
      setFileData(e.target.files[0]);
      if (props.isedit) {
        setRemoveDoc(1);
      }
    } else {
      e.target.value = "";
    }
  };

  const checkExistFile = () => {
    if (props.isedit) {
      setRemoveDoc(1);
    }
    setFileData("");
    setFileDataSelected("");
  };

  const setData = (field: string, value: any) => {
    let horseDetails = Object.assign({}, healthData);
    horseDetails[field].value = value;
    setHealthData({ ...horseDetails });
  };

  useEffect(() => {
    setHealthDataError({ ...validate(healthData)?.errors });
  }, [healthData]);

  const dispatch = useDispatch();
  const submitHandler = () => {
    if (validate(healthData).isValidated === true) {
      let horseId = props.horseid;
      const formData = new FormData();
      if (props.isedit == "false" && props?.isMultiple) {
        formData.append("horse_id[]", healthData["horse_ids"].value);
      } else {
        formData.append("horse_id", horseId);
      }
      Object.keys(healthData).forEach((element) => {
        if (element === "horse_ids") {
          return;
        }
        if (healthData[element].value) {
          formData.append(element, healthData[element].value);
        }
      });

      formData.append("document", fileData);
      formData.append("remove_doc", removeDoc);
      addEditHealthAction(formData);
    } else {
      setHealthDataError({ ...validate(healthData)?.errors });
      setIsFormValidated(true);
    }
  };

  useSelector((state: any) => {
    if (state?.singleHealthAddEditReducer?.type === ADD_HORSE_HEALTH_REQUEST) {
      if (apiStatus !== API_STATUS.LOADING) {
        setLoading(true);
        setApiStatus(API_STATUS.LOADING);
      }
    } else if (
      state?.singleHealthAddEditReducer?.data?.statusCode ===
        SUCCESS_RESPONSE_CODE &&
      state?.singleHealthAddEditReducer?.type === ADD_HORSE_HEALTH_SUCCESS
    ) {
      if (apiStatus !== API_STATUS.SUCCESS) {
        setApiStatus(API_STATUS.SUCCESS);
        // navigate("/");
        setLoading(false);
        props.onHide();
        props.setrefreshlist();
      }
    } else if (
      state?.singleHealthAddEditReducer?.type === ADD_HORSE_HEALTH_LONG
    ) {
      if (apiStatus !== API_STATUS.LONG) {
        setLoading(false);
        setApiStatus(API_STATUS.LONG);
      }
    } else if (
      state?.singleHealthAddEditReducer?.type === ADD_HORSE_HEALTH_FAILD
    ) {
      if (apiStatus !== API_STATUS.FAILED) {
        setLoading(false);
        setApiStatus(API_STATUS.FAILED);
      }
    }
  });

  useEffect(() => {
    if (props.isedit == "true") {
      if (props.healthdata) {
        setData("vet_clinic_id", props?.healthdata?.docter_id);
        setData("product_name", props?.healthdata?.product_name);
        setData("amount", props?.healthdata?.amount);
        setData("document_file", props?.healthdata?.document_file);
        setData("date", props?.healthdata?.withoutFormatedDate);
        setData("dosage", props?.healthdata?.dosage);
        setData("frequency", props?.healthdata?.frequency);
        setData("route", props?.healthdata?.route);
        setData("person_id", props?.healthdata?.person_id);
        setData("time", props?.healthdata?.time);
        setData("days", props?.healthdata?.days);
        setData("id", props?.healthdata?.id);
        if (props?.healthdata?.document) {
          let document = props?.healthdata?.document;
          document = getFileNamefromLink(document);
          setFileDataSelected(document);
        }
      }
    }
  }, [props?.healthdata]);

  useEffect(() => {
    // dispatch(metaVaccineAction());
    initTimes();
    initDates();
  }, []);

  useEffect(() => {
    if (props?.show === false) {
      setHealthDataError({});
      setHealthData(INITIAL_VITAL_HEALTH_DATA);
      setIsFormValidated(false);
      setRemoveDoc(0);
      setFileDataSelected("");
      setFileData("");
      setVetToggle("select");
    } else if (props?.isMultiple && props.isedit == "false") {
      const ob: any = Object.assign({}, healthData);
      ob["horse_ids"].validation[0] = "required";
      setHealthData({ ...ob });
    }
  }, [props?.show]);

  useEffect(() => {
    if (vetToggle == "select") {
      setData("select_vet_clinic", "vet_clinic_id");
      setData("vet_clinic", "");
    } else {
      setData("select_vet_clinic", "vet_clinic");
      setData("vet_clinic_id", "");
    }
  }, [vetToggle]);

  const {
    setrefreshlist,
    isMultiple,
    healthdata,
    horseid,
    metaData,
    ...rest
  } = props;
  return (
    <Modal {...rest} centered className="add-treatments-popup modal-784">
      <Modal.Header closeButton>
        <p className="tl">
          {props.isedit == "true" ? t("Edit Treatment") : t("Add Treatment")}
        </p>
      </Modal.Header>
      <Modal.Body>
        <Row>
          {props.isedit == "false" && props?.isMultiple ? (
            <Col sm={6} md={6}>
              <SelectGropus
                label={`${t("Horse")}*`}
                defaultSelect={healthData?.horse_ids?.value}
                value={metaData?.horse_list}
                dropKey="id"
                dropVal="name"
                onChange={(e: any) => {
                  setData("horse_ids", e.target.value);
                }}
                error={
                  isFormValidated && healthDataError["horse_ids"]
                    ? healthDataError["horse_ids"]
                    : null
                }
              />
            </Col>
          ) : null}
          <Col sm={6} md={6}>
            <Datepicker
              label={`${t("Date")}*`}
              endDate={moment().format("YYYY-MM-DD")}
              onChange={dateChange}
              value={moment(healthData?.date?.value)}
              error={
                isFormValidated && healthDataError["date"]
                  ? healthDataError["date"]
                  : null
              }
            />
          </Col>
        </Row>
        <Row>
          <Col sm={6} md={6}>
            <InputSelectGroup
              label={`${t("Vet/Clinic")}*`}
              selectValue={metaData?.vet_clinic}
              dropKey="id"
              dropVal="first_name"
              defaultSelect={healthData?.vet_clinic_id?.value}
              value={healthData?.vet_clinic?.value}
              inputSelectToggle={vetToggle}
              setInputSelectToggle={setVetToggle}
              error={
                isFormValidated &&
                (healthDataError["vet_clinic"] ||
                  healthDataError["vet_clinic_id"])
                  ? healthDataError["vet_clinic"] ||
                    healthDataError["vet_clinic_id"]
                  : null
              }
              onSelectChange={(e: any) => {
                setData("vet_clinic_id", e);
              }}
              onInputChange={(e: any) => {
                setData("vet_clinic", e.target.value);
              }}
            />
          </Col>
          <Col sm={6} md={6}>
            <SelectGropus
              label={`${t("Assign to a person")}`}
              dropKey="id"
              dropVal="first_name"
              value={metaData?.staff}
              defaultSelect={healthData?.person_id?.value}
              onChange={(e: any) => setData("person_id", e.target.value)}
              error={
                isFormValidated && healthDataError["person_id"]
                  ? healthDataError["person_id"]
                  : null
              }
            />
          </Col>
          <Col sm={6} md={6}>
            <InputGroups
              label={`${t("Product Name")}`}
              value={healthData?.product_name?.value}
              type="text"
              autoComplete="off"
              onChange={(e: any) => setData("product_name", e.target.value)}
              error={
                isFormValidated && healthDataError["product_name"]
                  ? healthDataError["product_name"]
                  : null
              }
            />
          </Col>
          <Col sm={6} md={6}>
            <SelectGropus
              label={`${t("Route")}*`}
              defaultSelect={healthData?.route?.value}
              onChange={(e: any) => setData("route", e.target.value)}
              value={metaData?.route}
              dropKey={"key"}
              dropVal={"value"}
              error={
                isFormValidated && healthDataError["route"]
                  ? healthDataError["route"]
                  : null
              }
            />
          </Col>
        </Row>
        <Row>
          <Col xs={6} sm={6} md={3}>
            <InputGroups
              label={`${t("Dosage")}*`}
              value={healthData?.dosage?.value}
              type="text"
              autoComplete="off"
              onChange={(e: any) => setData("dosage", e.target.value)}
              error={
                isFormValidated && healthDataError["dosage"]
                  ? healthDataError["dosage"]
                  : null
              }
            />
          </Col>
          <Col xs={6} sm={6} md={3}>
            <SelectGropus
              label={`${t("Frequency")}*`}
              defaultSelect={healthData?.frequency?.value}
              onChange={(e: any) => setData("frequency", e.target.value)}
              value={frequencyData}
              error={
                isFormValidated && healthDataError["frequency"]
                  ? healthDataError["frequency"]
                  : null
              }
            />
          </Col>
          <Col xs={6} sm={6} md={3}>
            <SelectGropus
              label={`${t("Time")}*`}
              defaultSelect={healthData?.time?.value}
              onChange={(e: any) => setData("time", e.target.value)}
              value={timeData}
              error={
                isFormValidated && healthDataError["time"]
                  ? healthDataError["time"]
                  : null
              }
            />
          </Col>
          <Col xs={6} sm={6} md={3}>
            <SelectGropus
              label={`${t("Days")}*`}
              defaultSelect={healthData?.days?.value}
              onChange={(e: any) => setData("days", e.target.value)}
              value={daysData}
              error={
                isFormValidated && healthDataError["days"]
                  ? healthDataError["days"]
                  : null
              }
            />
          </Col>
        </Row>
        <Row>
          <Col sm={6} md={6}>
            <InputSelectMix
              label={`${t("Amount")}`}
              inputValue={healthData?.amount?.value}
              onInputChange={(e: any) => setData("amount", e.target.value)}
              inputType="number"
              inputAutoComplete="off"
              inputSecondValue={prefrence?.currency_value}
              inputError={
                isFormValidated && healthDataError["amount"]
                  ? healthDataError["amount"]
                  : null
              }
            />
          </Col>
          <Col sm={12} md={12}>
            <FileUpload
              onChange={(e: any) => {
                fileChange(e);
              }}
              removeFile={() => checkExistFile()}
              selectedValue={fileDataSelected}
              value={fileDataName}
              fileType={t(FORMLABEL.USE_FILE_TYPE)}
            />
          </Col>
        </Row>
      </Modal.Body>
      <Modal.Footer>
        <div className="footer-2-btn">
          <TextButton onClick={props.onHide}> {t("Cancel")}</TextButton>
          <CustomeButton bg="fill" onClick={() => submitHandler()}>
            {props.isedit == "true" ? t("Update") : t("Add")}
          </CustomeButton>
        </div>
      </Modal.Footer>
    </Modal>
  );
}
