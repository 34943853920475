import React from "react";

export default function Remindersicon() {
  return (
    <svg
      width="32"
      height="32"
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M26 14C26 11 24.8 8.1 22.7 6"
        stroke="#E8E5E2"
        strokeWidth="1.5"
        strokeMiterlimit="10"
        strokeLinecap="round"
      />
      <path
        d="M9.3 6C7.1 8.1 6 11 6 14"
        stroke="#E8E5E2"
        strokeWidth="1.5"
        strokeMiterlimit="10"
        strokeLinecap="round"
      />
      <path
        d="M13.6 24.9C13.6 26.2 14.7 27.3 16 27.3C17.3 27.3 18.4 26.2 18.4 24.9"
        stroke="#E8E5E2"
        strokeWidth="1.5"
        strokeMiterlimit="10"
        strokeLinecap="round"
      />
      <path
        d="M23.3 24.1H8.70001C7.80001 24.1 7.20001 23.4 7.20001 22.6C7.20001 22.1 7.40001 21.7 7.70001 21.4C9.00001 20.3 9.80001 18.6 9.80001 16.9V14.4C9.80001 11 12.6 8.2 16 8.2C19.4 8.2 22.2 11 22.2 14.4V16.9C22.2 18.6 23 20.3 24.3 21.4C24.7 21.7 24.8 22.1 24.8 22.6C24.8 23.4 24.1 24.1 23.3 24.1Z"
        stroke="#E8E5E2"
        strokeWidth="1.5"
        strokeMiterlimit="10"
        strokeLinecap="round"
      />
      <path
        d="M16 8.2C16.9389 8.2 17.7 7.43889 17.7 6.5C17.7 5.56112 16.9389 4.8 16 4.8C15.0611 4.8 14.3 5.56112 14.3 6.5C14.3 7.43889 15.0611 8.2 16 8.2Z"
        stroke="#E8E5E2"
        strokeWidth="1.5"
        strokeMiterlimit="10"
        strokeLinecap="round"
      />
    </svg>
  );
}
