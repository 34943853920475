import React, { useState, useEffect } from "react";
import { Col, Row } from "react-bootstrap";
import CustomeButton from "../../Common/CustomButton/CustomButton";
import AddPlusIcon from "../../../Assets/icon/AddPlusIcon";
import binIcon from "../../../Assets/img/bin.svg";
import pencilIcon from "../../../Assets/img/pencil.svg";
import SquareButton from "../../Common/SquareButton/SquareButton";
import MedalIcon from "../../../Assets/img/Common-icon/Medal";
import GoldIcon from "../../../Assets/img/gold.svg";
import SilverIcon from "../../../Assets/img/silver.svg";
import BronzeIcon from "../../../Assets/img/bronze.svg";

import Medal1Icon from "../../../Assets/img/Medal1.svg";
import Medal2Icon from "../../../Assets/img/Medal2.svg";
import Medal3Icon from "../../../Assets/img/Medal3.svg";
import Medal4Icon from "../../../Assets/img/Medal4.svg";
import Medal5Icon from "../../../Assets/img/Medal5.svg";

import PaperClipIcon from "../../../Assets/img/Common-icon/PaperClipIcon";
import AddAchievementPopup from "../../Popups/AddAchievementPopup/AddAchievementPopup";
import MedalRankingPopup from "../../Popups/MedalRankingPopup/MedalRankingPopup";
import { useDispatch, useSelector } from "react-redux";
import { useOutletContext, useParams } from "react-router-dom";
import { API_STATUS, END_POINTS } from "../../../Constant/Api";
import {
  destroyAchievements,
  getAchieventList,
  getSingleAchivement,
} from "../../../actions/Horse/MyHorseActions";
import {
  encrDecrStr,
  getDefaultSorting,
  openDocument,
} from "../../../Utility/General";
import { SUCCESS_RESPONSE_CODE } from "../../../Constant/Status";
import ConfirmPopup from "../../Popups/ConfirmPopup/ConfirmPopup";
import Loader from "../../Loader/Loader";
import NoDataFound from "../../Common/NoDataFound/NoDataFound";
import { useTranslation } from "react-i18next";
import Pagination from "../../Layouts/Pagination/Pagination";
import { useLoading } from "../../../context/LoadingContext";
import TableHead from "../../Common/DataTable/TableHead/TableHead";
import { DATATABLE } from "../../../Constant/General";
import { getListMetaDataApi } from "../../../Redux/NonRedux/general";

export default function AchievementPage() {
  const { t, i18n } = useTranslation();
  const defaultSorting: any = getDefaultSorting(DATATABLE.ACHIVEMENT);
  const tableHead = [
    {
      label: t("Date"),
      className: "tm",
      key: "date",
    },
    {
      label: t("Discipline"),
      className: "tm",
      key: "discipline",
    },
    {
      label: t("Event Name"),
      className: "tm",
      key: "event_name",
    },

    {
      label: t("Country"),
      className: "tm",
      key: "countries",
    },
    {
      label: t("Ranking"),
      className: "tm",
      key: "ranking",
    },

    {
      label: t("Result"),
      className: "tm",
      key: "result",
    },
    {
      isIcon: true,
      icon: <PaperClipIcon />,
      includePara: true,
    },
    {
      label: t("Amount"),
      className: "tm",
      key: "amount",
    },
    {
      label: t("Action"),
      className: "tm",
      key: "",
      headClass: "text-right",
    },
  ];

  const [rankingData, setRankingData] = useState<any>({});
  const medalIcon = new Map([
    ["gold", GoldIcon],
    ["silver", SilverIcon],
    ["bronze", BronzeIcon],
    ["rank_1", Medal1Icon],
    ["rank_2", Medal2Icon],
    ["rank_3", Medal3Icon],
    ["rank_4", Medal4Icon],
    ["rank_5", Medal5Icon],
  ]);

  const dispatch = useDispatch();
  const param = useParams();
  const [horseId, setHorseId] = useState<any>(0);
  const [apiStatus, setApiStatus] = useState(API_STATUS.SUCCESS);
  const [achievementList, setAchievementList] = useState<any>([]);
  const [deletableRecords, setDeletableRecords] = useState<any>([]);
  const [confirmPopupShow, setConfirmPopupShow] = useState(false);
  const [editData, setEditData] = useState<any>({});
  const [numOfParticipants, setNumOfParticipants] = useState<any>("");
  const [numOfMedals, setNumOfMedals] = useState<any>("");
  const [prizeMoney, setPrizeMoney] = useState<any>("");
  const [isEdit, setIsEdit] = useState<boolean>(false);
  const [refreshList, setRefreshList] = useState(false);
  const { setLoading } = useLoading();
  const [recordsTotal, setRecordsTotal] = React.useState(1);
  const [currentPage, setCurrentPage] = React.useState(1);
  const [currentPageSize, setCurrentPageSize] = React.useState(10);
  const [sortByField, setSortByField] = useState(defaultSorting?.key);
  const [sortType, setSortType] = useState(defaultSorting?.type);
  const [orderList, setOrderList] = useState<any>([]);
  const { setExportParams }: any = useOutletContext();
  const prefrence = useSelector(
    (state: any) => state?.masterReducer?.data?.component?.preferance
  );
  const [metaData, setMetaData] = useState({});
  const [MedalRankingModalShow, setMedalRankingModalShow] =
    React.useState(false);

  const [AddAchievementModalShow, setAddAchievementModalShow] =
    React.useState(false);

  const listApiCall = () => {
    let id = encrDecrStr(param?.slug, "decr");
    setHorseId(id);
    let params: any = {
      page: currentPage,
      limit: currentPageSize,
      horse_id: id,
    };

    if (sortByField && sortType) {
      params["sort"] = {
        column: orderList[sortByField]
          ? orderList[sortByField]
          : defaultSorting?.value,
        type: sortType,
      };
    }
    
  setExportParams(params);
    setLoading(true);
    getAchieventList(params)
      .then((response: any) => {
        setAchievementList(response?.component?.listing);
        setRecordsTotal(response?.component?.recordsTotal);
        setNumOfMedals(response?.component?.totalMedals);
        setNumOfParticipants(response?.component?.No_of_participations);
        setPrizeMoney(response?.component?.totalPrice);
        setLoading(false);
        setOrderList(response?.component?.orderlist);
        setRankingData(response?.component?.rankingData)
      })
      .catch(() => {
        setLoading(false);
      });
  };

  const getMetaData = () => {
    setLoading(true);  
    getListMetaDataApi(END_POINTS.metaAchievement)
      .then((response: any) => {
        setMetaData(response?.component);
      })
      .catch((error: any) => {})
      .finally(() => {
        setLoading(false);
      });
  };


  useEffect(() => {
    getMetaData();
  }, []);

  useEffect(() => {
    if (refreshList) {
      listApiCall();
      setRefreshList(false);
    }
  }, [refreshList]);

  useEffect(() => {
    listApiCall();
  }, [currentPage, currentPageSize, sortByField, sortType]);

  const editHandler = (id: number) => {
    let params = {
      horse_id: horseId,
      id: id,
    };
    setApiStatus(API_STATUS.LOADING);
    setLoading(true);
    getSingleAchivement(params)
      .then((response: any) => {
        if (response?.statusCode === SUCCESS_RESPONSE_CODE) {
          setApiStatus(API_STATUS.SUCCESS);
          setLoading(false);
          setIsEdit(true);
          setEditData(response?.component);
          setAddAchievementModalShow(true);
        } else {
          setApiStatus(API_STATUS.FAILED);
          setLoading(false);
        }
      })
      .catch((error) => {
        setApiStatus(API_STATUS.FAILED);
        setLoading(false);
      });
  };

  const deleteHandler = (id: number) => {
    let params = {
      id: deletableRecords,
    };

    setApiStatus(API_STATUS.LOADING);
    setLoading(true);
    destroyAchievements(params)
      .then((response: any) => {
        if (response.statusCode === SUCCESS_RESPONSE_CODE) {
          setApiStatus(API_STATUS.SUCCESS);
          setLoading(false);
          setRefreshList(true);
          setConfirmPopupShow(false);
        } else {
          setApiStatus(API_STATUS.FAILED);
          setLoading(false);
        }
      })
      .catch((error: any) => {
        setApiStatus(API_STATUS.FAILED);
        setLoading(false);
      });
  };

  const deleteNow = (id: number) => {
    let ids = deletableRecords;
    ids.push(id);
    setDeletableRecords(ids);
    setConfirmPopupShow(true);
  };

  return (
    <>
      <div className="achievement-horse-page">
        <div className="summary-head">
          <Row>
            <Col sm="12" md="6" lg="4">
              <div className="summary-box">
                <p className="tl blur-color">{t("No. of Participations")}</p>
                <div className="right-details">
                  <h5>{numOfParticipants}</h5>
                </div>
              </div>
            </Col>
            <Col sm="12" md="6" lg="4">
              <div className="summary-box">
                <p className="tl blur-color">{t("No. of Medals")}</p>
                <div className="right-details">
                  <h5>{numOfMedals}</h5>
                </div>
              </div>
            </Col>
            <Col sm="12" md="6" lg="4">
              <div className="summary-box">
                <p className="tl blur-color">{t("Total Prize Money")}</p>
                <div className="right-details">
                  <h5>
                    {" "}
                    {prizeMoney} {prefrence?.currency_value}
                  </h5>
                </div>
              </div>
            </Col>
          </Row>
        </div>

        <div className="data-tbl-wrapper">
          <div className="data-tbl-header">
            <p className="tl">{t("Achievements")}</p>
            <div className="btn-wrapper small-big-btn">
              <SquareButton
                onClick={() => {
                  setMedalRankingModalShow(true);
                }}
              >
                <MedalIcon />
              </SquareButton>
              <CustomeButton
                bg="fill"
                onClick={() => {
                  setIsEdit(false);
                  setEditData({});
                  setAddAchievementModalShow(true);
                }}
              >
                <AddPlusIcon />
                {t("Add")}
              </CustomeButton>
            </div>
          </div>
          <div className="data-tbl">
            <table>
              <TableHead
                data={tableHead}
                orderList={orderList}
                sortByField={sortByField}
                sortType={sortType}
                setSortType={setSortType}
                setSortByField={setSortByField}
              />
              <tbody>
                {achievementList &&
                  achievementList.length > 0 &&
                  achievementList.map((items: any, index: number) => (
                    <tr key={items.id}>
                      <td>
                        <p className="bl">{items.dates}</p>
                      </td>
                      <td>
                        <p className="bl">{items.discipline_value}</p>
                      </td>
                      <td>
                        <p className="bl">{items.event_name}</p>
                      </td>
                      <td>
                        <p className="bl">{items.country_value}</p>
                      </td>
                      <td> 
                        <div className="multi-icons">
                          {items.championship_medal ? (
                            <img
                              src={medalIcon.get(items.championship_medal)}
                              alt=""
                              className="hw-24"
                            />
                          ) : null}{" "}
                          {items?.ranking ? (
                            <img
                              src={medalIcon.get(`rank_${items?.ranking}`)}
                              alt=""
                              className="hw-24"
                            />
                          ) : null}
                        </div>
                      </td>
                      <td>
                        <p className="bl">
                          {items.score ? items.score : items.time}
                        </p>
                      </td>
                      <td>
                        {items.document ? (
                          <p
                            className="bl"
                            onClick={() => openDocument(items.document)}
                          >
                            <PaperClipIcon />
                          </p>
                        ) : (
                          ""
                        )}
                      </td>
                      <td>
                        <p className="bl">
                          {items.prize_money} {prefrence?.currency_value}
                        </p>
                      </td>
                      <td>
                        <div className="icon-wrapper">
                          <a>
                            <img
                              src={pencilIcon}
                              alt=""
                              onClick={() => editHandler(items?.id)}
                            />
                          </a>
                          <a>
                            <img
                              src={binIcon}
                              onClick={() => deleteNow(items?.id)}
                              alt=""
                            />
                          </a>
                        </div>
                      </td>
                    </tr>
                  ))}
              </tbody>
            </table>
          </div>
          {achievementList?.length ? (
            <Pagination
              results={recordsTotal}
              currentPage={currentPage}
              setCurrentPage={setCurrentPage}
              currentLength={currentPageSize}
              setCurrentPageSize={setCurrentPageSize}
            />
          ) : (
            <NoDataFound />
          )}
        </div>
      </div>

      <MedalRankingPopup
        show={MedalRankingModalShow}
        onHide={() => setMedalRankingModalShow(false)}
        rankingdata={rankingData}
      />

      <AddAchievementPopup
        show={AddAchievementModalShow}
        onHide={() => {
          setAddAchievementModalShow(false);
        }}
        metaData={metaData}
        horseid={horseId}
        isedit={isEdit ? 1 : 0}
        achievementdata={editData}
        setrefreshlist={() => {
          setRefreshList(true);
        }}
      />

      <ConfirmPopup
        heading={t("Delete")}
        message={t("Are you sure to delete this record?")}
        show={confirmPopupShow}
        onHide={() => {
          setConfirmPopupShow(false);
          setDeletableRecords([]);
        }}
        confirm={deleteHandler}
      />
    </>
  );
}
