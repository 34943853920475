import { globalPostAction } from "../../actions/globalActions";
import { END_POINTS } from "../../Constant/Api";
import { SHOW_MESSAGE } from "../../Constant/General";

export function addEditContacts(params: any) {
    let apiUrl = END_POINTS.addEditContact;
    return globalPostAction(params, apiUrl , SHOW_MESSAGE);
}

export function getAllContacts(params: any) {
    let apiUrl = END_POINTS.contactList;
    return globalPostAction(params, apiUrl );
}

export function destroyContacts(params: any) {
    let apiUrl = END_POINTS.destroyContact;
    return globalPostAction(params, apiUrl , SHOW_MESSAGE);
}

export function getSingleContact(params: any) {
    let apiUrl = END_POINTS.viewContact;
    return globalPostAction(params, apiUrl );
}

export function addEditStaff(params: any) {
    let apiUrl = END_POINTS.addEditStaff;
    return globalPostAction(params, apiUrl , SHOW_MESSAGE);
}

export function getAllStaff(params: any = {}) {
    let apiUrl = END_POINTS.staffList;
    return globalPostAction(params, apiUrl );
}

export function destroyStaff(params: any) {
    let apiUrl = END_POINTS.destroyStaff;
    return globalPostAction(params, apiUrl , SHOW_MESSAGE);
}

export function viewSingleStaff(params: any) {
    let apiUrl = END_POINTS.viewStaff;
    return globalPostAction(params, apiUrl );
}

export const getMetaStaffList = (params = {}) => {
    let apiUrl = END_POINTS.metaStaffList;
    return globalPostAction(params, apiUrl);
}


export function updatePayroll(params: any) {
    let apiUrl = END_POINTS.updatePayroll;
    return globalPostAction(params, apiUrl , SHOW_MESSAGE);
}

export function deletePayroll(params: any) {
    let apiUrl = END_POINTS.destroyPayroll;
    return globalPostAction(params, apiUrl , SHOW_MESSAGE);
}

export function getAllPayrollList(params: any = {}) {
    let apiUrl = END_POINTS.payrollList;
    return globalPostAction(params, apiUrl );
}

export function viewSinglePayroll(params: any) {
    let apiUrl = END_POINTS.viewSinglePayroll;
    return globalPostAction(params, apiUrl );
}

export function bulkPayrollUpdateApi(params: any) {
    let apiUrl = END_POINTS.bulkPayrollUpdate;
    return globalPostAction(params, apiUrl );
}