import React from "react";
import SearchIcon from "../../../Assets/img/search.svg";

export default function SearchBox(props: any) {
  return (
    <div className="search-box">
      <input
        type={props.type}
        inputMode={props.inputmode}
        defaultValue={props.defaultValue}
        value={props.value ? props.value : ''}
        placeholder={props.placeholder}
        readOnly={props.readOnly}
        autoComplete={props.autoComplete}
        name={props.name}
        id={props.id}
        className={props.error && "error"}
        onChange={props.onChange}
      />
      <button className="search-btn">
        <img src={SearchIcon} alt="" />
      </button>
    </div>
  );
}
