import { END_POINTS } from "../../../Constant/Api";
import { SHOW_MESSAGE } from "../../../Constant/General";
import { globalPostActionWithReducer } from "../../globallActions";
import { ADD_FARRIER_RECORD_REQUEST, ADD_FARRIER_RECORD_SUCCESS, ADD_FARRIER_RECORD_LONG, ADD_FARRIER_RECORD_FAILD, GET_FARRIER_RECORD_LIST_REQUEST, GET_FARRIER_RECORD_LIST_SUCCESS, GET_FARRIER_RECORD_LIST_LONG, GET_FARRIER_RECORD_LIST_FAILD, DESTROY_FARRIER_RECORD_REQUEST, DESTROY_FARRIER_RECORD_SUCCESS, DESTROY_FARRIER_RECORD_LONG, DESTROY_FARRIER_RECORD_FAILD, VIEW_FARRIER_RECORD_REQUEST, VIEW_FARRIER_RECORD_SUCCESS, VIEW_FARRIER_RECORD_LONG, VIEW_FARRIER_RECORD_FAILD } from "./FarrierTypes";

export function addEditFarrierRecordAction(params: any) {
    let apiUrl = END_POINTS.addEditFarrier;
    return globalPostActionWithReducer(
      params,
      apiUrl,
      ADD_FARRIER_RECORD_REQUEST,
      ADD_FARRIER_RECORD_SUCCESS,
      ADD_FARRIER_RECORD_LONG,
      ADD_FARRIER_RECORD_FAILD,
      SHOW_MESSAGE
    );
}

export function getFarrierRecordsAction(params: any) {
  let apiUrl = END_POINTS.farrierList;
  return globalPostActionWithReducer(
    params,
    apiUrl,
    GET_FARRIER_RECORD_LIST_REQUEST,
    GET_FARRIER_RECORD_LIST_SUCCESS,
    GET_FARRIER_RECORD_LIST_LONG,
    GET_FARRIER_RECORD_LIST_FAILD
  );
}

export function destoryFarrierRecordAction(params: any){
  let apiUrl = END_POINTS.destroyFarrir;
  return globalPostActionWithReducer(
    params,
    apiUrl,
    DESTROY_FARRIER_RECORD_REQUEST,
    DESTROY_FARRIER_RECORD_SUCCESS,
    DESTROY_FARRIER_RECORD_LONG,
    DESTROY_FARRIER_RECORD_FAILD,
    SHOW_MESSAGE
  );
}


export function viewFarrierRecordAction(params: any){
  let apiUrl = END_POINTS.viewFarrier;
  return globalPostActionWithReducer(
    params,
    apiUrl,
    VIEW_FARRIER_RECORD_REQUEST,
    VIEW_FARRIER_RECORD_SUCCESS,
    VIEW_FARRIER_RECORD_LONG,
    VIEW_FARRIER_RECORD_FAILD
  );
}
