import type { DatePickerProps } from "antd";
import { t } from "i18next";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { Col, Row } from "react-bootstrap";
import Modal from "react-bootstrap/Modal";
import { useSelector } from "react-redux";
import { useLoading } from "../../../context/LoadingContext";
import {
  getInstantContact,
  getInstantMeta,
} from "../../../Redux/globallActions";
import { addEditInventory } from "../../../Redux/NonRedux/inventoryApi";
import validate from "../../../Utility/Validation";
import CustomeButton from "../../Common/CustomButton/CustomButton";
import Datepicker from "../../Common/Datepicker/Datepicker";
import InputGroups from "../../Common/InputGroups/InputGroups";
import InputSelectGroup from "../../Common/InputSelectGroup/InputSelectGroup";
import InputSelectMix from "../../Common/InputSelectMix/InputSelectMix";
import InputSelectWithLabel from "../../Common/InputSelectWithTitle/InputSelectWithTitle";
import SelectGropus from "../../Common/SelectGroups/SelectGroups";
import TextareaGroups from "../../Common/TextareaGroups/TextareaGroups";
import TextButton from "../../Common/TextButton/TextButton";

export default function AddInventoryPopup(props: any) {
  const INITIAL_INVENTORY_DATA: any = {
    product_name: {
      value: "",
      validation: ["required"],
      errors: [t("Product is required.")],
    },
    category: {
      value: "",
      validation: ["required"],
      errors: [t("Category is required.")],
    },
    dates: {
      value: moment().format("YYYY-MM-DD"),
      validation: ["required"],
      errors: [t("Date is required.")],
    },
    qty: {
      value: "",
      validation: ["required"],
      errors: [t("Qty is required.")],
    },
    unit: {
      value: "",
      validation: ["required"],
      errors: [t("Unit is required.")],
    },
    amount: {
      value: "",
      validation: [""],
      errors: [t("Amount is required.")],
    },
    comments: {
      value: "",
      validation: [""],
      errors: [t("Comments is required.")],
    },
    provider: {
      value: "",
      validation: ["requiredIf:select_provider:provider" , "letters"],
      errors: [t("Provider is required.") ,t("Incorrect format")],
    },
    provider_id: {
      value: "",
      validation: ["requiredIf:select_provider:provider_id"],
      errors: [t("Provider is required.")],
    },
    select_provider: {
      value: "provider_id",
      // validation: ["requiredIf:provider_id"],
      errors: [t("Dewormer Active Ingredient is required.")],
    },
    id: {
      value: "",
    },
  };

  const [inventoryData, setInventoryData] = useState(INITIAL_INVENTORY_DATA);
  const dateChange: DatePickerProps["onChange"] = (date, dateString) => {
    let dateIs = date?.format("YYYY-MM-DD");
    setData("dates", dateIs);
  };
  const [inventoryDataError, setInventoryDataError] = useState<any>({});
  const [isFormValidated, setIsFormValidated] = useState(false);

  const [ProviderToggle, setProviderToggle] = useState("select");
  const { setLoading } = useLoading();

  const prefrence = useSelector(
    (state: any) => state?.masterReducer?.data?.component?.preferance
  );

  const setData = (field: string, value: any) => {
    let invDetails = Object.assign({}, inventoryData);
    invDetails[field].value = value;
    setInventoryData({ ...invDetails });
  };

  useEffect(() => {
    if (ProviderToggle == "select") {
      setData("select_provider", "provider_id");
      setData("provider", "");
    } else {
      setData("select_provider", "provider");
      setData("provider_id", "");
    }
  }, [ProviderToggle]);

  useEffect(() => {
    setInventoryDataError({ ...validate(inventoryData)?.errors });
  }, [inventoryData]);

  const submitHandler = () => {
    if (validate(inventoryData).isValidated === true) {
      let params: any = {};
      Object.keys(inventoryData).forEach((element) => {
        params[element] = inventoryData[element].value;
      });

      setLoading(true);
      addEditInventory(params)
        .then((response: any) => {
          setIsFormValidated(false);
          props.setrefreshlist();
          props.onHide();
        }).finally(() => {
          setLoading(false);
        });

    } else {
      setInventoryDataError({ ...validate(inventoryData)?.errors });
      setIsFormValidated(true);
    }
  };




  useEffect(() => {
    if (props.isedit == "true") {
      if (props.editdata) {
        setData("provider_id", props?.editdata?.provider_id);
        setData("comments", props?.editdata?.comments);
        setData("amount", props?.editdata?.amount);
        setData("qty", props?.editdata?.qty);
        setData("unit", props?.editdata?.unit);
        setData("product_name", props?.editdata?.product_name);
        setData("category", props?.editdata?.category);
        setData("dates", props?.editdata?.withoutFormatedDate);
        setData("id", props?.editdata?.id);
      }
    } else {
      setInventoryData(INITIAL_INVENTORY_DATA);
    }
  }, [props?.editdata]);

  useEffect(() => {
    if (props?.show === false) {
      setInventoryDataError({});
      setInventoryData(INITIAL_INVENTORY_DATA);
      setIsFormValidated(false);
      setProviderToggle("select")
    }
  }, [props?.show]);

  const { setrefreshlist , metaData ,  ...rest } = props;
  return (
    <Modal {...rest} centered className="add-inventory-popup modal-784">
      <Modal.Header closeButton>
        <p className="tl">
          {props.isedit == "true" ? t("Edit Inventory") : t("Add Inventory")} 
        </p>
      </Modal.Header>
      <Modal.Body>
        <Row>
          <Col sm={6} md={6}>
            <Datepicker
              label={`${t("Date")}*`}
              endDate={moment().format("YYYY-MM-DD")}
              onChange={dateChange}
              value={moment(inventoryData?.dates?.value)}
              error={
                isFormValidated && inventoryDataError["dates"]
                  ? inventoryDataError["dates"]
                  : null
              }
            />
          </Col>
        </Row>
        <Row>
          <Col sm={6} md={6}>
            <SelectGropus
              label={`${t("Category")}*`}
              defaultSelect={inventoryData?.category?.value}
              dropKey="id"
              dropVal="metadata"
              value={metaData?.inventory_category}
              onChange={(e: any) => setData("category", e.target.value)}
              error={
                isFormValidated && inventoryDataError["category"]
                  ? inventoryDataError["category"]
                  : null
              }
            />
          </Col>
          <Col sm={6} md={6}>
            <InputGroups
              label={`${t("Product")}*`}
              type="text"
              autoComplete="off"
              value={inventoryData?.product_name?.value}
              onChange={(e: any) => setData("product_name", e.target.value)}
              error={
                isFormValidated && inventoryDataError["product_name"]
                  ? inventoryDataError["product_name"]
                  : null
              }
            />
          </Col>
          <Col sm={6} md={6}>
            <InputSelectWithLabel
              inputLabel={`${t("Qty")}*`}
              inputType="number"
              selectLabel={`${t("Unit")}*`}
              dropKey="id"
              dropVal="metadata"
              inputValue={inventoryData?.qty?.value}
              selectDefaultSelect={inventoryData?.unit?.value}
              selectValue={metaData?.qty_units}
              onInputChange={(e: any) => setData("qty", e.target.value)}
              onSelectChange={(e: any) => setData("unit", e.target.value)}
              inputError={
                isFormValidated && inventoryDataError["qty"]
                  ? inventoryDataError["qty"]
                  : null
              }
              selectError={
                isFormValidated && inventoryDataError["unit"]
                  ? inventoryDataError["unit"]
                  : null
              }
            />
          </Col>
          <Col sm={6} md={6}>
            <InputSelectMix
              label={`${t("Amount")}`}
              inputType="number"
              inputValue={inventoryData?.amount?.value}
              // selectValue={unitData}
              onInputChange={(e: any) => setData("amount", e.target.value)}
              inputError={
                isFormValidated && inventoryDataError["amount"]
                  ? inventoryDataError["amount"]
                  : null
              }
              inputSecondValue={prefrence?.currency_value}
            />
          </Col>
          <Col sm={6} md={6}>
            <InputSelectGroup
              label={`${t("Provider")}*`}
              inputSelectToggle={ProviderToggle}
              setInputSelectToggle={setProviderToggle}
              selectValue={metaData?.provider}
              dropKey="id"
              dropVal="first_name"
              defaultSelect={inventoryData?.provider_id?.value}
              value={inventoryData?.provider?.value}
              error={
                isFormValidated &&
                  (inventoryDataError["provider"] ||
                    inventoryDataError["provider_id"])
                  ? inventoryDataError["provider"] ||
                  inventoryDataError["provider_id"]
                  : null
              }
              onSelectChange={(e: any) => {
                setData("provider_id", e);
              }}
              onInputChange={(e: any) => {
                setData("provider", e.target.value);
              }}
            />
          </Col>
        </Row>
        <Row>
          <Col sm={12} md={12}>
            <TextareaGroups
              label={`${t("Comment")}`}
              autoComplete="off"
              value={inventoryData?.comments?.value}
              onChange={(e: any) => setData("comments", e.target.value)}
              error={
                isFormValidated && inventoryDataError["comments"]
                  ? inventoryDataError["comments"]
                  : null
              }
            />
          </Col>
        </Row>
      </Modal.Body>
      <Modal.Footer>
        <div className="footer-2-btn">
          <TextButton onClick={props.onHide}> {t("Cancel")}</TextButton>
          <CustomeButton bg="fill" onClick={() => submitHandler()}>
            {props.isedit == "true" ? t("Update") : t("Add")}
          </CustomeButton>
        </div>
      </Modal.Footer>
    </Modal>
  );
}
