import { END_POINTS } from "../../Constant/Api";
import { globalPostAction } from "../../actions/globalActions";

export function getDashboardData() {
  let apiUrl = END_POINTS.getDashboard;
  return globalPostAction({}, apiUrl);
}

export function getSalesData(params: any) {
  let apiUrl = END_POINTS.getSalesData;
  return globalPostAction(params, apiUrl);
}

export function getGraphData(params: any) {
  let apiUrl = END_POINTS.getGraphData;
  return globalPostAction(params, apiUrl);
}
