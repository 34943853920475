import type { DatePickerProps } from "antd";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { Col, Row } from "react-bootstrap";
import Datepicker from "../../Common/Datepicker/Datepicker";
import SelectGropus from "../../Common/SelectGroups/SelectGroups";

import AddPlusIcon from "../../../Assets/icon/AddPlusIcon";
import binIcon from "../../../Assets/img/bin.svg";
import PaperClipIcon from "../../../Assets/img/Common-icon/PaperClipIcon";
import pencilIcon from "../../../Assets/img/pencil.svg";
import CustomeButton from "../../Common/CustomButton/CustomButton";
import AddCostRecordPopup from "../../Popups/AddCostRecordPopup/AddCostRecordPopup";
import { useSelector } from "react-redux";
import {
  categoryCostRecordsDetails,
  categoryCostRecordsList,
  destroyCost,
  getSingleCost,
} from "../../../Redux/NonRedux/incomeExpense";
import { SUCCESS_RESPONSE_CODE } from "../../../Constant/Status";
import IncomeExpenseFilter from "../IncomeExpenseFilter/IncomeExpenseFilter";
import { useLocation, useOutletContext, useParams } from "react-router-dom";
import ConfirmPopup from "../../Popups/ConfirmPopup/ConfirmPopup";
import { t } from "i18next";
import { DATATABLE, INCOME_EXPENSE_TYPE } from "../../../Constant/General";
import NoDataFound from "../../Common/NoDataFound/NoDataFound";
import Pagination from "../../Layouts/Pagination/Pagination";
import Loader from "../../Loader/Loader";
import { getDefaultSorting, getSlugFromCostCategory } from "../../../Utility/General";
import { useLoading } from "../../../context/LoadingContext";
import { usePrevious } from "../../../hooks/usePrevious";
import TableHead from "../../Common/DataTable/TableHead/TableHead";
import useDidMountEffect from "../../../hooks/useDidMountEffect";

export default function DetailCategoryIAEPage() {
  const defaultSorting : any = getDefaultSorting(DATATABLE.COST_CATEGORY_DETAILS);
  
  const INTIAL_FILTER_OPTION = {
    horse_id: null,
    from: moment().startOf('month').format('YYYY-MM-DD'),
    to: moment().format("YYYY-MM-DD"),
    owner_id: null,
  };

  const tableHead = [
    {
      label: t("Date"),
      className: "tm",
      key: "date",
    },
    {
      label: t("Horse"),
      className: "tm",
      key: "horse",
    },
    {
      label: t("Category"),
      className: "tm",
      key: "category",
    },

    {
      label: t("Description"),
      className: "tm",
      key: "description",
    },
    {
      label: t("Amount"),
      className: "tm ",
      key: "amount",
      headClass: "text-right",
    },

    {
      label: t("Action"),
      className: "tm text-right",
      key: "",
      headClass: "",
    },
  ];

  const [data, setData] = useState<any>({});
  const { setLoading , loading } = useLoading();
  const [refreshList, setRefreshList] = useState(false);
  const { category } = useParams();
  const [categoryId, setCategoryId] = useState(null);
  const prefrence = useSelector(
    (state: any) => state?.masterReducer?.data?.component?.preferance
  );

  const [AddCostRecordModalShow, setAddCostRecordModalShow] =
    React.useState(false);
  const [confirmPopupShow, setConfirmPopupShow] = useState(false);
  const [deletableRecords, setDeletableRecords] = useState<any>([]);
  const [editData, setEditData] = useState({});
  const [isEdit, setIsEdit] = useState(false);
  const [filterOption, setFilterOption] = useState<any>(INTIAL_FILTER_OPTION);
  const [recordsTotal, setRecordsTotal] = useState(1);
  const [currentPage, setCurrentPage] = useState(1);
  const [currentPageSize, setCurrentPageSize] = useState(10);
  const location: any = useLocation();
  const [showAddBtn, setShowAddBtn] = useState(false);
  // const { costCategory }: any = useOutletContext();
  const preValue = usePrevious({ filterOption });
  
  const [orderList, setOrderList] = useState<any>([]);
  const [sortByField, setSortByField] = useState(defaultSorting?.key);
  const [sortType, setSortType] = useState(defaultSorting?.type);
  const {metaData} : any = useOutletContext();
  
  useEffect(() => {
    setFilterOption({ ...location?.state });
  }, [location?.state]);

  useDidMountEffect(() => {
      if (currentPage != 1) {
        setCurrentPage(1);
      } else {
        listApicall();
      }
  }, [filterOption]);


  const listApicall = () => {
    setLoading(true);
    let categoryOb = metaData?.income_category?.find(
      (item: any) => getSlugFromCostCategory(item?.slug) === category
    );
    if (categoryOb?.id) {
      let params: any = {
        category_id: categoryOb?.id,
        page: currentPage,
        limit: currentPageSize,
      };
      if (sortByField && sortType) {
        params["sort"] = {
          column: orderList[sortByField] ? orderList[sortByField] : defaultSorting?.value ,
          type: sortType,
        };
      }
      Object.keys(filterOption).forEach((item: any) => {
        if (filterOption[item] && filterOption[item] != "") {
          params[item] = filterOption[item];
        }
      });
      categoryCostRecordsDetails(params)
        .then((response: any) => {
          if (response?.statusCode === SUCCESS_RESPONSE_CODE) {
            setData(response?.component);
            setRecordsTotal(response?.component?.recordsFiltered);
            setOrderList(response?.component?.orderList);
          }
        })
        .catch(() => {})
        .finally(() => {
          setLoading(false);
        });
    }
  };

  useEffect(() => {
    if (refreshList) {
      listApicall();
      setRefreshList(false);
    }
  }, [refreshList]);

  useDidMountEffect(() => {
    listApicall();
  }, [currentPage, currentPageSize, sortByField, sortType]);

  useEffect(() => {
    let categoryOb = metaData?.income_category?.find(
      (item: any) => getSlugFromCostCategory(item?.slug) === category
    );
    setCategoryId(categoryOb?.id);
  }, [metaData?.income_category]);

  useEffect(() => {
    if (categoryId) {
      let isCategoryPresent = metaData?.income_category?.filter(
        (item: any) => item?.id === categoryId
      )?.length;
      setShowAddBtn(isCategoryPresent);
    }
  }, [categoryId]);

  const editHandler = (id: number) => {
    let params = {
      id: id,
    };
    setLoading(true);
    getSingleCost(params)
      .then((response: any) => {
        if (response?.statusCode === SUCCESS_RESPONSE_CODE) {
          setLoading(false);
          setIsEdit(true);
          setEditData(response?.component);
          setAddCostRecordModalShow(true);
        }
      })
      .catch((error: any) => {})
      .finally(() => {
        setLoading(false);
      });
  };

  const deleteNow = (id: number) => {
    let ids = deletableRecords;
    ids.push(id);
    setDeletableRecords(ids);
    setConfirmPopupShow(true);
  };

  const deleteHandler = (id: number) => {
    let params = {
      id: deletableRecords,
    };

    setLoading(true);
    destroyCost(params)
      .then((response: any) => {
        if (response.statusCode === SUCCESS_RESPONSE_CODE) {
          setLoading(false);
          setRefreshList(true);
          setConfirmPopupShow(false);
        } else {
          setLoading(false);
        }
      })
      .catch((error) => {
        setLoading(false);
      });
  };

  return (
    <>
      <div className="detail-category-IAE-page">
        <IncomeExpenseFilter
          filterOption={filterOption}
          setFilterOption={setFilterOption}
        />

        <div className="data-tbl-wrapper">
          <div className="data-tbl-header">
            <p className="tl">{t("Detailed Income & Expenses")}</p>
            {showAddBtn ? (
              <div className="btn-wrapper single-btn">
                <CustomeButton
                  bg="fill"
                  onClick={() => {
                    setIsEdit(false);
                    setEditData({});
                    setAddCostRecordModalShow(true);
                  }}
                >
                  <AddPlusIcon />
                  {t("Add Cost")}
                </CustomeButton>
              </div>
            ) : null}
          </div>
          <div className="data-tbl total-tbl">
            <table>
              <TableHead
                data={tableHead}
                orderList={orderList}
                sortByField={sortByField}
                sortType={sortType}
                setSortType={setSortType}
                setSortByField={setSortByField}
              />
              <tbody>
                {data?.listing?.map((items: any, index: number) => (
                  <tr key={index + "vaccination-data"}>
                    <td>
                      <p className="bl">{items.date}</p>
                    </td>
                    <td>
                      <p className="bl">{items.horse_name}</p>
                    </td>
                    <td>
                      <p className="bl">{items.category_value}</p>
                    </td>
                    <td>
                      <p className="bl">{items.description}</p>
                    </td>
                    <td>
                      <p
                        className={`bl text-right ${
                          items?.income_expence_type ===
                          INCOME_EXPENSE_TYPE.INCOME
                            ? "success-color"
                            : "error-color"
                        }`}
                      >
                        {items.amount} {prefrence?.currency_value}
                      </p>
                    </td>
                    <td>
                      {/* check if user can delete or not */}
                      {items?.deleted ? (
                        <div className="icon-wrapper">
                          <a>
                            <img
                              src={pencilIcon}
                              onClick={() => editHandler(items?.id)}
                              alt=""
                            />
                          </a>
                          <a>
                            <img
                              src={binIcon}
                              onClick={() => deleteNow(items?.id)}
                              alt=""
                            />
                          </a>
                        </div>
                      ) : null}
                    </td>
                  </tr>
                ))}
                {data?.listing?.length ? (
                  <tr>
                    <td colSpan={4}>
                      <p className="tm primaryM">{t("Total")}</p>
                    </td>
                    <td>
                      <p className="tm primaryM text-right">
                        {data?.total?.totalCost} {prefrence?.currency_value}
                      </p>
                    </td>
                    <td></td>
                  </tr>
                ) : null}
              </tbody>
            </table>
          </div>
          {data?.listing?.length ? (
            <Pagination
              results={recordsTotal}
              currentPage={currentPage}
              setCurrentPage={setCurrentPage}
              currentLength={currentPageSize}
              setCurrentPageSize={setCurrentPageSize}
            />
          ) : (
            <NoDataFound />
          )}
        </div>
      </div>

      <AddCostRecordPopup
        show={AddCostRecordModalShow}
        onHide={() => setAddCostRecordModalShow(false)}
        setRefreshList={setRefreshList}
        categoryId={categoryId}
        isedit={isEdit ? 1 : 0}
        editData={editData}
        metaData={metaData}
      />

      <ConfirmPopup
        heading={t("Delete")}
        message={t("Are you sure to delete this record?")}
        show={confirmPopupShow}
        onHide={() => {
          setConfirmPopupShow(false);
          setDeletableRecords([]);
        }}
        confirm={deleteHandler}
      />
    </>
  );
}
