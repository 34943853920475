import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useOutletContext, useParams } from "react-router-dom";
import AddPlusIcon from "../../../../Assets/icon/AddPlusIcon";
import BarHighIcon from "../../../../Assets/img/bar-chart-high.svg";
import BarLowIcon from "../../../../Assets/img/bar-chart-low.svg";
import BarMidIcon from "../../../../Assets/img/bar-chart-mid.svg";
import binIcon from "../../../../Assets/img/bin.svg";
import pencilIcon from "../../../../Assets/img/pencil.svg";
import { API_STATUS } from "../../../../Constant/Api";
import { DATATABLE } from "../../../../Constant/General";
import { useLoading } from "../../../../context/LoadingContext";
import {
  destroySingleHealthAction,
  getSingleHelthList,
  viewSingleHealthAction,
} from "../../../../Redux/NonRedux/health";
import { encrDecrStr, getDefaultSorting } from "../../../../Utility/General";
import CustomeButton from "../../../Common/CustomButton/CustomButton";
import TableHead from "../../../Common/DataTable/TableHead/TableHead";
import NoDataFound from "../../../Common/NoDataFound/NoDataFound";
import Pagination from "../../../Layouts/Pagination/Pagination";
import AddVitalSignsPopup from "../../../Popups/AddVitalSignsPopup/AddVitalSignsPopup";
import ConfirmPopup from "../../../Popups/ConfirmPopup/ConfirmPopup";

export default function VitalSignsPage() {
  const { t, i18n } = useTranslation();
  const defaultSorting : any = getDefaultSorting(DATATABLE.HORSRE_HELTH);
  const tableHead = [
    {
      label:t("Date"),
      className:"tm",
      key:"date",
    },
    {
      label:t("Temperature"),
      className:"tm icon-text",
      key:"temperature",
    },
    {
      label:t("Heart Rate"),
      className:"tm icon-text",
      key:"heart_rate",
    },
    {
      label:t("Respiration Rate"),
      className:"tm icon-text",
      key:"respiration_rate",
    },
    {
      label:t("Action"),
      className:"tm",
      key:"",
      headClass:"text-right"
    },
  ]
  const param = useParams();
  const dispatch = useDispatch();
  const [horseId, setHorseId] = React.useState<any>(0);
  const [refreshList, setRefreshList] = React.useState(false);
  const [confirmPopupShow, setConfirmPopupShow] = React.useState(false);
  const [isEdit, setIsEdit] = React.useState<boolean>(false);
  const [apiStatus, setApiStatus] = React.useState(API_STATUS.SUCCESS);
  const [deletableRecords, setDeletableRecords] = React.useState<any>([]);
  const [editData, setEditData] = React.useState<any>({});
  const [recordsTotal, setRecordsTotal] = React.useState(1);
  const [currentPage, setCurrentPage] = React.useState(1);
  const [currentPageSize, setCurrentPageSize] = React.useState(10);
  const { setLoading } = useLoading();
  const [healthList, setHelthList] = useState<any>([]);
  const [sortByField, setSortByField] = useState(defaultSorting?.key);
  const [sortType, setSortType] = useState(defaultSorting?.type);
 
  const [orderList, setOrderList] = useState<any>([]);
  const { setExportParams }: any = useOutletContext();
  const prefrence = useSelector(
    (state: any) => state?.masterReducer?.data?.component?.preferance
  );
  const [AddVitalSignsModalShow, setAddVitalSignsModalShow] =
  React.useState(false);
  useEffect(() => {
    // listApiCall();
  }, []);

  useEffect(() => {
    listApiCall();
  }, [currentPage, currentPageSize , sortByField , sortType]);

  useEffect(() => {
    if (refreshList) {
      listApiCall();
      setRefreshList(false);
    }
  }, [refreshList]);

  

  const listApiCall = async () => {
    let id = encrDecrStr(param?.slug, "decr");
    setHorseId(id);
    let params : any = {
      page: currentPage,
      limit: currentPageSize,
      form_type: "vital_sign",
      horse_id: id,
    };
    if (sortByField && sortType) {
      params["sort"] = {
        column: orderList[sortByField] ? orderList[sortByField] : defaultSorting?.value ,
        type: sortType,
      };
    }
    setExportParams(params);
    setLoading(true);
    getSingleHelthList(params)
      .then((res: any) => {
        setHelthList(res?.component?.listing);
        setRecordsTotal(res?.component?.recordsTotal);
        setOrderList(res?.component?.orderlist);
      })
      .catch(() => {})
      .finally(() => {
        setLoading(false);
      });
  };

  

  const editHandler = (id: any) => {
    let params = {
      form_type: "vital_sign",
      id: id,
    };
    setLoading(true);
    // dispatch(viewSingleHealthAction(params));
    viewSingleHealthAction(params)
      .then((res: any) => {
        if (res?.component?.form_type == "vital_sign") {
          setIsEdit(true);
          setEditData(res?.component);
          setAddVitalSignsModalShow(true);
        }
      })
      .catch((err) => {})
      .finally(() => {
        setLoading(false);
      });
  };
  const deleteHandler = () => {
    let params = {
      form_type: "vital_sign",
      id: deletableRecords,
    };
    setLoading(true);
    destroySingleHealthAction(params)
      .then((res: any) => {
        setRefreshList(true);
        setConfirmPopupShow(false);
      })
      .catch((err: any) => {})
      .finally(() => {
        setLoading(false);
      });
  };

  const deleteNow = (id: any) => {
    let ids = deletableRecords;
    ids.push(id);
    setDeletableRecords(ids);
    setConfirmPopupShow(true);
  };
  //
  return (
    <>
      <div className="vital-signs-page">
        <div className="data-tbl-wrapper">
          <div className="data-tbl-header">
            <p className="tl">  {t("Vital Signs")}</p>
            <div className="btn-wrapper single-btn">
              <CustomeButton
                bg="fill"
                onClick={() => {
                  setEditData({});
                  setIsEdit(false);
                  setAddVitalSignsModalShow(true);
                }}
              >
                <AddPlusIcon />
                {t("Add")} 
              </CustomeButton>
            </div>
          </div>
          <div className="data-tbl">
            <table>
            <TableHead
              data={tableHead}
              orderList={orderList}
              sortByField={sortByField}
              sortType={sortType}
              setSortType={setSortType}
              setSortByField={setSortByField}
              />
              {healthList?.length ? (
                <tbody>
                  {healthList?.map((items: any, index: number) => (
                    <tr key={index + "vital-signs-data"}>
                      <td>
                        <p className="bl">{items.dates}</p>
                      </td>
                      <td>
                        <p className="bl icon-text">
                          <img src={items?.temperature_icon} alt="" />
                          {items.temperature} {prefrence?.temperatureUnit_value}
                        </p>
                      </td>
                      <td>
                        <p className="bl icon-text">
                          <img src={items?.heart_rate_icon} alt="" />
                          {items.heartRate}  {t("bpm")} 
                        </p>
                      </td>
                      <td>
                        <p className="bl icon-text">
                          <img src={items?.respiration_icon} alt="" />
                          {items.respirationRate} {t("bpm")} 
                        </p>
                      </td>
                      <td>
                        <div className="icon-wrapper">
                          <button onClick={() => editHandler(items.id)}>
                            <img src={pencilIcon} alt="" />
                          </button>
                          <button onClick={() => deleteNow(items.id)}>
                            <img src={binIcon} alt="" />
                          </button>
                        </div>
                      </td>
                    </tr>
                  ))}
                </tbody>
              ) : null}
            </table>
          </div>
          {healthList?.length ? (
            <Pagination
              results={recordsTotal}
              currentPage={currentPage}
              setCurrentPage={setCurrentPage}
              currentLength={currentPageSize}
              setCurrentPageSize={setCurrentPageSize}
            />
          ) : (
            <NoDataFound />
          )}
        </div>
      </div>

      <AddVitalSignsPopup
        show={AddVitalSignsModalShow}
        horseid={isEdit ? horseId : [horseId]}
        isedit={isEdit.toString()}
        healthdata={editData}
        isMultiple={0}
        setrefreshlist={() => {
          setRefreshList(true);
        }}
        onHide={() => setAddVitalSignsModalShow(false)}
      />
      <ConfirmPopup
        heading={t("Delete")}
        message={t("Are you sure to delete this record?")}
        show={confirmPopupShow}
        onHide={() => {
          setConfirmPopupShow(false);
          setDeletableRecords([]);
        }}
        confirm={deleteHandler}
      />
    </>
  );
}
