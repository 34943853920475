import { t } from "i18next";
import React, { useState, useEffect } from "react";
import rightIcon from "../../../Assets/img/pagi-chevron-right.svg";
import leftIcon from "../../../Assets/img/pegi-chevron-left.svg";

export default function Pagination(props: any) {
  const pageLength = Math.ceil(props.results / props.currentLength);
  const [paginationSelect, setPaginationSelect] = useState([
    10, 20, 30, 40, 50,
  ]);

  useEffect(() => {
    if (props?.paginationSelect) {
      setPaginationSelect(props.paginationSelect);
    }
  }, []);
  const pageLengthData = (pageLength: any) => {
    return pageLength ? pageLength : 1;
  };
  return (
    <div className="pagination-wrapper">
      <p className="bl">
        {props.results} {t("Results")}
      </p>

      <div className="show-select">
        <label className="bl">{t("Show")}</label>
        <select
          className="select"
          onChange={(e) => {props.setCurrentPageSize(e.target.value); props.setCurrentPage(1)}}
          value={props.currentLength}
        >
          {paginationSelect.map((item, index) => (
            <option value={item} key={index + "item"}>
              {item}
            </option>
          ))}
        </select>
      </div>
      <div className="pagination-box">
        <button
          className="pre"
          onClick={() =>
            props.currentPage > 1
              ? props.setCurrentPage(props.currentPage - 1)
              : ""
          }
        >
          <img src={leftIcon} alt="" />
        </button>
        {pageLengthData(pageLength) > 7 ? (
          <>
            <button
              className={`tm ${1 == props.currentPage ? "active" : ""}`}
              onClick={() => props.setCurrentPage(1)}
            >
              1
            </button>
            {props.currentPage - 1 > 2 ? (
              <button className="tm">...</button>
            ) : (
              ""
            )}
            {Array.apply(1, Array(pageLengthData(pageLength))).map(function (
              x,
              i
            ) {
              return i > 1 &&
                ((props.currentPage - 2 < i && i < props.currentPage + 2) ||
                  (props.currentPage - 1 <= 2 &&
                    props.currentPage - 2 < i &&
                    i < 6) ||
                  (props.currentPage + 1 >= pageLengthData(pageLength) - 1 &&
                    pageLengthData(pageLength) - 5 < i &&
                    i < props.currentPage + 2)) ? (
                <button
                  key={i + "one" + Math.random()}
                  className={`tm ${i == props.currentPage ? "active" : ""}`}
                  onClick={() => props.setCurrentPage(i)}
                >
                  {i}
                </button>
              ) : null
            })}
            {props.currentPage + 1 < pageLengthData(pageLength) - 1 ? (
              <button className="tm">...</button>
            ) : (
              ""
            )}
            <button
              className={`tm ${
                pageLengthData(pageLength) == props.currentPage ? "active" : ""
              }`}
              onClick={() => props.setCurrentPage(pageLengthData(pageLength))}
            >
              {pageLengthData(pageLength)}
            </button>
          </>
        ) : (
          <>
            {Array.apply(1, Array(pageLengthData(pageLength) + 1)).map(
              function (x, i) {
                return i ? (
                  <button
                    key={i + Math.random()}
                    className={`tm ${i == props.currentPage ? "active" : ""}`}
                    onClick={() => props.setCurrentPage(i)}
                  >
                    {i}
                  </button>
                ) : (
                  ""
                );
              }
            )}
          </>
        )}
        {/* <button className="tm">1</button>
        <button className="tm">2</button>
        <button className="tm">3</button>
        <button className="tm">4</button> */}
        <button
          className="next"
          onClick={() =>
            props.currentPage < pageLengthData(pageLength)
              ? props.setCurrentPage(props.currentPage + 1)
              : ""
          }
        >
          <img src={rightIcon} alt="" />
        </button>
      </div>
    </div>
  );
}
