import { t } from "i18next";
import React, { useContext, useEffect } from "react";
import { Outlet, useLocation, useNavigate } from "react-router-dom";
import InvoicingIcon from "../../Assets/img/Invoicing-primary.svg";
import ListIcon from "../../Assets/img/Tab-icon/ListIcon";
import UserCogIcon from "../../Assets/img/Tab-icon/UserCogIcon";
import { SIDEBAR } from "../../Constant/General";
import {
  INCOME_EXPENSE_CATEGORY,
  INCOME_EXPENSE_HORSE,
  INVOICING_INVOICE_LIST,
  INVOICING_SERVICE_TO_INVOICE
} from "../../Constant/Route";
import { SidebarContext } from "../../context/SideBarContext";
import TabWrapper from "../Layouts/TabWrapper/TabWrapper";

export default function Invoicing() {
  const [parentTab, setParentTab] = React.useState(0);
  const parentTabHandleChange = (
    event: React.SyntheticEvent,
    newValue: number
  ) => {
    setParentTab(newValue);
  };
  const parentTabData = [
    {
      index: 0,
      img: <UserCogIcon />,
      label:t("Service to Invoice"),
      path: INVOICING_SERVICE_TO_INVOICE,
    },
    {
      index: 1,
      img: <ListIcon />,
      label:t("Invoice List"),
      path: INVOICING_INVOICE_LIST,
    },
  ];

  let navigate = useNavigate();
  const location:any = useLocation();
  const {setSidebar} : any =  useContext(SidebarContext);
  const userRouteChange = (pathName: any) => {
    navigate(pathName);
  };
  useEffect(() => {
    setSidebar(SIDEBAR.INVOICING);
  }, [])

  useEffect(() => {
    if (location.pathname.includes(INVOICING_SERVICE_TO_INVOICE)) {
      setParentTab(0);
    } else if (location.pathname.includes(INVOICING_INVOICE_LIST)) {
      setParentTab(1);
    }
  }, [location?.pathname])
  
  return (
    <div className="invoicing-page">
      {/* Page Title Start */}
      <div className="page-title-wrapper">
        <div className="title-first-menu">
          <div className="title-icon-text">
            <img src={InvoicingIcon} alt="" />
            <h5>{t("Invoicing")}</h5>
          </div>
        </div>
        <TabWrapper
          value={parentTab}
          horseRouteChange={userRouteChange}
          handleChange={parentTabHandleChange}
          parentTabData={parentTabData}
        />
      </div>

      {/* Page Title End */}

      <div className="page-content">
        <Outlet />
      </div>
    </div>
  );
}
