import type { DatePickerProps } from "antd";
import { t } from "i18next";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { Col, Row } from "react-bootstrap";
import Modal from "react-bootstrap/Modal";
import { addEditImageVideo } from "../../../actions/Horse/MyHorseActions";
import { API_STATUS } from "../../../Constant/Api";
import { SUCCESS_RESPONSE_CODE } from "../../../Constant/Status";
import { useLoading } from "../../../context/LoadingContext";
import { getFileNamefromPath, isFileValidated } from "../../../Utility/General";
import validate from "../../../Utility/Validation";
import CheckboxLabel from "../../Common/CheckboxLabel/CheckboxLabel";
import CustomeButton from "../../Common/CustomButton/CustomButton";
import Datepicker from "../../Common/Datepicker/Datepicker";
import FileUpload from "../../Common/FileUpload/FileUpload";
import InputGroups from "../../Common/InputGroups/InputGroups";
import TextareaGroups from "../../Common/TextareaGroups/TextareaGroups";
import TextButton from "../../Common/TextButton/TextButton";
import ToggleButton from "../../Common/ToggleButton/ToggleButton";

export default function ChangeProfilePopup(props: any) {
  const INTIAL_PICTURE_DATA = {
    form_type: {
      value: "picture",
      validation: ["required"],
      errors: ["Form Type is required."],
    },

    date: {
      value: moment().format("YYYY-MM-DD"),
      validation: ["required"],
      errors: ["Date is required."],
    },
    photographer: {
      value: "",
      validation: [""],
      errors: ["Photographer is required."],
    },
    title: {
      value: "",
      validation: [""],
      errors: [t("Title is required.")],
    },
    image: {
      value: "",
      validation: ["required"],
      errors: [t("Image is required.")],
    },
    private: {
      value: "0",
    },
    description: {
      value: "",
      validation: [""],
      errors: ["Description is required."],
    },
    type: {
      value: "0",
      validation: ["required"],
      errors: ["Type is required."],
    },
    is_default: {
      value: "1",
    },
  };

  const [pictureData, setPictureData] = useState<any>(INTIAL_PICTURE_DATA);
  const [fileData, setFileData] = useState("");
  const [fileDataName, setFileDataName] = useState<any>(`${t("Upload Picture")}*`);
  const [isFormValidated, setIsFormValidated] = useState(false);
  const [fileDataSelected, setFileDataSelected] = useState("");
  const [pictureDataError, setPictureDataError] = useState<any>({});
  const allowedFormate = ["jpg", "png", "jpeg" , "heic" , "heif"];
  const allowedFileSizeInMb = 4;

  const { setLoading } = useLoading();

  const dateChange: DatePickerProps["onChange"] = (date, dateString) => {
    let dateIs = date?.format("YYYY-MM-DD");
    setData("date", dateIs);
  };

  const toggleBtnValue = [
    { name: t("Yes"), value: "0" },
    { name: t("No"), value: "1" },
  ];

  const checkExistFile = () => {
    setFileData("");
    setFileDataSelected("");
    setData("image", "");
  };

  const setData = (field: string, value: any) => {
    const pictureInfo = Object.assign({}, pictureData);
    pictureInfo[field].value = value;
    setPictureData({ ...pictureInfo });
  };

  const fileChange = (e: any) => {
    let file = e.target.files[0];
    if(isFileValidated(file , allowedFormate , allowedFileSizeInMb)){
     let fileName = getFileNamefromPath(e.target.value)
      setFileDataSelected(fileName);
      setFileData(e.target.files[0]);
      setData("image" , e.target.value);

    }
    else{
      e.target.value = "";
    }
  };

  useEffect(() => {
    if (isFormValidated) {
      setPictureDataError({ ...validate(pictureData).errors });
    }
  }, [pictureData]);

  useEffect(() => {
    if (props?.show === false) {
      setPictureDataError({});
      setPictureData(INTIAL_PICTURE_DATA);
      setIsFormValidated(false);
      setFileData("");
      setFileDataSelected("");
      setData("image" , "");
    }
  }, [props?.show]);

  const submitHandler = () => {
    if (validate(pictureData).isValidated === true) {
      let horseId = props.horseid;
      const formData: any = new FormData();
      Object.keys(pictureData).forEach((element) => {
        if (pictureData[element]) {
          formData.append(element, pictureData[element].value);
        }
      });
      formData.append("private", pictureData["private"].value);
      if (fileData && fileData != "") {
        formData.append("image_video", fileData);
      }
      formData.append("horse_id", horseId);

      setLoading(true);
      addEditImageVideo(formData)
        .then((response: any) => {
          if (response.statusCode === SUCCESS_RESPONSE_CODE) {
            setLoading(false);
            props.onHide();
            props.setprofile(response?.component?.profile_picture);
          }
        })
        .catch((error) => {})
        .finally(() => {
          setLoading(false);
        });
    } else {
      setPictureDataError({ ...validate(pictureData)?.errors });
      setIsFormValidated(true);
    }
  };

  return (
    <Modal {...props} centered className="change-profile-popup modal-784">
      <Modal.Header closeButton>
        <p className="tl">{t("Change Profile Picture")}</p>
      </Modal.Header>
      <Modal.Body>
        <Row>
          <Col sm={6} md={6}>
            <Datepicker
              label={`${t("Date")}*`}
              endDate={moment().format("YYYY-MM-DD")}
              onChange={dateChange}
              value={moment(pictureData?.date?.value)}
              error={
                isFormValidated && pictureDataError["date"]
                  ? pictureDataError["date"]
                  : null
              }
            />
          </Col>
          <Col sm={6} md={6}>
            <InputGroups
              label={`${t("Title")}`}
              type="text"
              autoComplete="off"
              value={pictureData?.title?.value}
              onChange={(e: any) => setData("title", e.target.value)}
              error={
                isFormValidated && pictureDataError["title"]
                  ? pictureDataError["title"]
                  : null
              }
            />
          </Col>
          <Col sm={6} md={6}>
            <InputGroups
              label={`${t("Photographer")}`}
              type="text"
              autoComplete="off"
              value={pictureData?.photographer?.value}
              onChange={(e: any) => setData("photographer", e.target.value)}
              error={
                isFormValidated && pictureDataError["photographer"]
                  ? pictureDataError["photographer"]
                  : null
              }
            />
          </Col>
          <Col sm={6} md={6}>
            <ToggleButton
              value={toggleBtnValue}
              label={`${t("Private")}`}
              selected={pictureData?.private?.value}
              setSelected={(value: any) => {
                setData("private", value);
              }}
            />
          </Col>
          <Col sm={12} md={12}>
            <TextareaGroups
              label={`${t("Description")}`}
              type="text"
              autoComplete="off"
              value={pictureData?.description?.value}
              onChange={(e: any) => setData("description", e.target.value)}
              error={
                isFormValidated && pictureDataError["description"]
                  ? pictureDataError["description"]
                  : null
              }
            />
          </Col>
          <Col sm={12} md={12}>
            <CheckboxLabel
              checked={pictureData?.is_default?.value}
              onChange={(e: any) => {
                setData("is_default", e.target.checked ? 1 : 0);
              }}
            >
             {t("Set as horse profile picture")} 
            </CheckboxLabel>
          </Col>
          <Col sm={12} md={12}>
            <FileUpload
              onChange={(e: any) => {
                fileChange(e);
              }}
              removeFile={() => checkExistFile()}
              selectedValue={fileDataSelected}
              value={fileDataName}
              fileType={t("Use a jpg, jpeg, png, heic")}
              error={
                isFormValidated && pictureDataError["image"]
                  ? pictureDataError["image"]
                  : null
              }
            />
          </Col>
        </Row>
      </Modal.Body>
      <Modal.Footer>
        <div className="footer-2-btn">
          <TextButton onClick={props.onHide}> {t("Cancel")}</TextButton>
          <CustomeButton onClick={submitHandler} bg="fill">
          {t("Add")} 
          </CustomeButton>
        </div>
      </Modal.Footer>
    </Modal>
  );
}
