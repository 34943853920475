import type { DatePickerProps } from "antd";
import { t } from "i18next";
import type { Moment } from "moment";
import moment from "moment";
import { useState, useEffect } from "react";
import { Col, Row } from "react-bootstrap";
import Modal from "react-bootstrap/Modal";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { useLoading } from "../../../context/LoadingContext";
import { getInstantMeta } from "../../../Redux/globallActions";
import { horseInfoAction } from "../../../Redux/Horse/HorseAction";
import { addEditBreedingControl } from "../../../Redux/NonRedux/breeding";
import {
  encrDecrStr,
  getFileNamefromLink,
  getFileNamefromPath,
  getSlugNamefromId,
  getValFromKey,
  isFileValidated,
} from "../../../Utility/General";
import validate from "../../../Utility/Validation";
import CustomeButton from "../../Common/CustomButton/CustomButton";
import Datepicker from "../../Common/Datepicker/Datepicker";
import FileUpload from "../../Common/FileUpload/FileUpload";
import InputSelectGroup from "../../Common/InputSelectGroup/InputSelectGroup";
import InputSelectMix from "../../Common/InputSelectMix/InputSelectMix";
import SelectGropus from "../../Common/SelectGroups/SelectGroups";
import TextButton from "../../Common/TextButton/TextButton";
import Timepicker from "../../Common/Timepicker/Timepicker";

export default function AddBreedingControlPopup(props: any) {
  const INTIAL_DATA = {
    id: {
      value: "",
    },
    dates: {
      value: moment().format("YYYY-MM-DD"),
      validation: ["required"],
      errors: [t("Date is required.")],
    },
    amount: {
      value: "",
      validation: [""],
      errors: [t("Amount is required.")],
    },
    select_vet_clinic: {
      value: "vet_clinic_id",
      // validation: ["requiredIf:vet_clinic_id"],
      errors: [t("Vet Clinic is required.")],
    },
    vet_clinic: {
      value: "",
      validation: ["requiredIf:select_vet_clinic:vet_clinic", "letters"],
      errors: [t("Vet Clinic is required."), t("Incorrect format")],
    },
    vet_clinic_id: {
      value: "",
      validation: ["requiredIf:select_vet_clinic:vet_clinic_id"],
      errors: [t("Vet Clinic is required.")],
    },
    pregnancy_status: {
      value: "",
      validation: ["required"],
      errors: [t("Pregnancy Status is required.")],
    },
    check_method: {
      value: "",
      validation: [""],
      errors: [t("Check Method is required.")],
    },
    time: {
      value: moment().format("YYYY-MM-DD HH:mm"),
      validation: ["required"],
      errors: [t("Time is required.")],
    },
    document: {
      value: "",
      selected_value: "",
      validation: ["allowed_formate:['doc', 'pdf', 'docx']", "max_file_size:4"],
      errors: [t("")],
      allowed_formate: ["doc", "pdf", "docx" , "heic" , "heif" , "jpg" , "jpeg" , "png"],
      max_file_size: 4,
    },
    remove_doc: {
      value: "0",
    },
  };

  const [formData, setFormData] = useState<any>(INTIAL_DATA);
  const { setLoading } = useLoading();
  const [formDataError, setFormDataError] = useState<any>({});
  const [isFormValidated, setIsFormValidated] = useState(false);
  const [inputSelectToggle, setInputSelectToggle] = useState("select");
  const [checkMethods, setCheckMethods] = useState<any>([]);
  const [preganancyStatusOptions, setPreganancyStatusOptions] = useState<any>(
    []
  );

  const param = useParams();
  const dispatch = useDispatch();
  const {
    editData,
    setRefreshList,
    metaData,
    serviceId,
    breedingServiceDate,
    isEmbryoTransferCase,
    ...rest
  } = props;

  const prefrence = useSelector(
    (state: any) => state?.masterReducer?.data?.component?.preferance
  );

  const setData = (field: string, value: any, subField: any = null) => {
    const tempOb = Object.assign({}, formData);
    tempOb[field][subField ? subField : "value"] = value;
    setFormData({ ...tempOb });
  };

  const dateChange: DatePickerProps["onChange"] = (date, dateString) => {
    let dateIs = date?.format("YYYY-MM-DD");
    setData("dates", dateIs);
  };

  const timeChange = (time: Moment, timeString: string) => {
    setData("time", time.format("YYYY-MM-DD HH:mm"));
  };

  useEffect(() => {
    setFormDataError({ ...validate(formData).errors });
  }, [formData]);

  useEffect(() => {
    if (props.isedit && props?.editData) {
      Object.keys(formData).forEach((element) => {
        if (props?.editData?.[element]) {
          setData(element, `${props?.editData?.[element]}`);
        }
        if (element === "document" && props?.editData?.[element]) {
          let docName = getFileNamefromLink(props?.editData?.[element]);
          setData("document", docName, "selected_value");
        }
        setData("remove_doc", "0");
      });
      setData(
        "time",
        moment(props?.editData?.time, "hh:mm A").format("YYYY-MM-DD HH:mm")
      );
    } else {
      setFormData(INTIAL_DATA);
      setData(
        "vet_clinic_id",
        props?.metaData?.vet_clinic_id ? props?.metaData?.vet_clinic_id : ""
      );
    }
  }, [props?.editData, props.isedit]);

  useEffect(() => {
    if (props?.show === false) {
      setFormDataError({});
      setFormData(INTIAL_DATA);
      setIsFormValidated(false);
      setInputSelectToggle("select");
    }
  }, [props?.show]);

  useEffect(() => {
    if (inputSelectToggle == "select") {
      setData("select_vet_clinic", "vet_clinic_id");
      setData("vet_clinic", "");
    } else {
      setData("select_vet_clinic", "vet_clinic");
      setData("vet_clinic_id", "");
    }
  }, [inputSelectToggle]);

  const submitHandler = () => {
    if (validate(formData).isValidated === true) {
      let params: any = new FormData();
      Object.keys(formData).forEach((element) => {
        params.append(element, formData[element].value);
      });
      params.append("service_id", serviceId);
      setLoading(true);
      addEditBreedingControl(params)
        .then((response: any) => {
          props.onHide();
          setRefreshList();
        })
        .finally(() => {
          setLoading(false);
        });
    } else {
      setFormDataError({ ...validate(formData)?.errors });
      setIsFormValidated(true);
    }
  };

  const removeFile = (key: string) => {
    let removeKey = "remove_doc";
    setData(removeKey, "1");
    setData(key, "");
    setData(key, "", "selected_value");
  };

  const fileChange = (key: string, e: any) => {
    let file = e.target.files[0];
    let removeKey = "remove_doc";
    if (
      isFileValidated(
        file,
        formData[key].allowed_formate,
        formData[key].max_file_size
      )
    ) {
      let fileName = getFileNamefromPath(e.target.value);
      setData(key, e.target.files[0]);
      setData(key, fileName, "selected_value");
      setData(removeKey, "1");
    } else {
      e.target.value = "";
    }
  };

  useEffect(() => {
    let statusOptions = props?.metaData?.pregnancy_status;
    if (isEmbryoTransferCase && statusOptions && Object.keys(statusOptions)) {
      setPreganancyStatusOptions(
        statusOptions?.filter(
          (item: any) =>
            item.slug === "to_be_rechecked" || item.slug === "aborted"
        )
      );
    } else {
      setPreganancyStatusOptions(statusOptions);
    }
  }, [props?.metaData?.pregnancy_status, isEmbryoTransferCase]);

  return (
    <Modal {...rest} centered className="add-breeding-control-popup modal-784">
      <Modal.Header closeButton>
        <p className="tl">
          {props.isedit
            ? t("Edit Breeding Control")
            : t("Add Breeding Control")}{" "}
        </p>
      </Modal.Header>
      <Modal.Body>
        <Row>
          <Col sm={6} md={6}>
            <Datepicker
              startDate={
                breedingServiceDate
                  ? moment(breedingServiceDate).format("YYYY-MM-DD")
                  : null
              }
              endDate={moment().format("YYYY-MM-DD")}
              label={`${t("Date")}*`}
              onChange={dateChange}
              value={moment(formData?.dates?.value)}
              error={
                isFormValidated && formDataError["dates"]
                  ? formDataError["dates"]
                  : null
              }
            />
          </Col>
          <Col sm={6} md={6}>
            <Timepicker
              label={`${t("Time")}`}
              onChange={timeChange}
              value={moment(formData?.time?.value)}
              error={
                isFormValidated && formDataError["time"]
                  ? formDataError["time"]
                  : null
              }
            />
          </Col>
          <Col sm={6} md={6}>
            <InputSelectGroup
              label={`${t("Vet / Clinic")}*`}
              selectValue={props?.metaData?.vet_clinic}
              dropKey="id"
              dropVal="first_name"
              defaultSelect={formData?.vet_clinic_id?.value}
              value={formData?.vet_clinic?.value}
              inputSelectToggle={inputSelectToggle}
              setInputSelectToggle={setInputSelectToggle}
              error={
                isFormValidated &&
                (formDataError["vet_clinic"] || formDataError["vet_clinic_id"])
                  ? formDataError["vet_clinic"] ||
                    formDataError["vet_clinic_id"]
                  : null
              }
              onSelectChange={(e: any) => {
                setData("vet_clinic_id", e);
              }}
              onInputChange={(e: any) => {
                setData("vet_clinic", e.target.value);
              }}
            />
          </Col>
        </Row>

        <Row>
          <Col sm={6} md={6}>
            <SelectGropus
              label={`${t("Check Method")}`}
              value={props?.metaData?.breeding_check_method}
              onChange={(e: any) => setData("check_method", e.target.value)}
              dropKey="id"
              dropVal="metadata"
              defaultSelect={formData?.check_method?.value}
              error={
                isFormValidated && formDataError["check_method"]
                  ? formDataError["check_method"]
                  : null
              }
            />
          </Col>
          <Col sm={6} md={6}>
            <SelectGropus
              label={`${t("Pregnancy Status")}*`}
              value={preganancyStatusOptions}
              onChange={(e: any) => setData("pregnancy_status", e.target.value)}
              dropKey="id"
              dropVal="metadata"
              defaultSelect={formData?.pregnancy_status?.value}
              error={
                isFormValidated && formDataError["pregnancy_status"]
                  ? formDataError["pregnancy_status"]
                  : null
              }
            />
          </Col>
          <Col sm={6} md={6}>
            <InputSelectMix
              label={`${t("Amount")}`}
              inputValue={formData?.amount?.value}
              inputType="number"
              inputAutoComplete="off"
              onInputChange={(e: any) => setData("amount", e.target.value)}
              inputError={
                isFormValidated && formDataError["amount"]
                  ? formDataError["amount"]
                  : null
              }
              inputSecondValue={prefrence?.currency_value}
            />
          </Col>
          <Col sm={12} md={12}>
            <FileUpload
              accept="*"
              onChange={(e: any) => {
                fileChange("document", e);
              }}
              removeFile={() => removeFile("document")}
              selectedValue={formData?.document?.selected_value}
              value={t("Upload Documents")}
              fileType={t("Use a pdf, docx, jpg, png, heic")}
            />
          </Col>
        </Row>
      </Modal.Body>
      <Modal.Footer>
        <div className="footer-2-btn">
          <TextButton onClick={props?.onHide}> {t("Cancel")}</TextButton>
          <CustomeButton bg="fill" onClick={submitHandler}>
            {" "}
            {props.isedit ? t("Update") : t("Add")}
          </CustomeButton>
        </div>
      </Modal.Footer>
    </Modal>
  );
}
