import type { DatePickerProps } from "antd";
import moment from "moment";
import type { Moment } from "moment";
import React, { useState, useEffect } from "react";
import { Col, Row } from "react-bootstrap";
import Modal from "react-bootstrap/Modal";
import MinusIcon from "../../../Assets/img/minus-circle.svg";
import PlusIcon from "../../../Assets/img/plus-circle.svg";
import CustomeButton from "../../Common/CustomButton/CustomButton";
import Datepicker from "../../Common/Datepicker/Datepicker";
import InputGroups from "../../Common/InputGroups/InputGroups";
import InputSelectGroup from "../../Common/InputSelectGroup/InputSelectGroup";
import InputSelectMix from "../../Common/InputSelectMix/InputSelectMix";
import SelectGropus from "../../Common/SelectGroups/SelectGroups";
import TextButton from "../../Common/TextButton/TextButton";
import Timepicker from "../../Common/Timepicker/Timepicker";
import validate from "../../../Utility/Validation";
import { API_STATUS } from "../../../Constant/Api";
import { useDispatch, useSelector } from "react-redux";
import {
  metaHorseListAction,
  metaTrainingTypesAction,
} from "../../../Redux/General/GeneralAction";
import MultiSelectGroup from "../../Common/MultiSelectGroup/MultiSelectGroup";
import { t } from "i18next";
import { addTraining } from "../../../Redux/NonRedux/training";
import { SUCCESS_RESPONSE_CODE } from "../../../Constant/Status";
import { useLoading } from "../../../context/LoadingContext";

export default function AddTrainingPopup(props: any) {
  const INTIAL_TRAINING_ITEM = {
    start_date: {
      value: moment().format("YYYY-MM-DD"),
      validation: ["required"],
      errors: [t("start Date is required.")],
    },
    end_date: {
      value: moment().format("YYYY-MM-DD"),
      validation: ["required"],
      errors: [t("end Date is required.")],
    },
    training_type: {
      value: "",
      validation: ["required"],
      errors: [t("Training Type is required.")],
    },
    trainer: {
      value: "",
      validation: ["required", "letters"],
      errors: [t("Trainer is required."), t("Incorrect format")],
    },
    duration_hour: {
      value: "",
      validation: [ "beteween:0:23"],
      errors: [t("Please enter range from 0 to 23")],
    },
    duration_minutes: {
      value: "",
      validation: [ "beteween:0:59"],
      errors: [t
        ("Please enter range from 0 to 59"),
      ],
    },
    duration_second: {
      value: "",
      validation: ["beteween:0:59"],
      errors: [t
        ("Please enter range from 0 to 59"),
      ],
    },
    days: {
      value: [],
      validation: ["requiredArray"],
      errors: [t("Days is Required.")],
    },
    start_time: {
      value: '',
      validation: [],
      errors: [t("start_time is Required.")],
    },
  };

  const INTIAL_TRAINING_DATA = {
    horse_id: {
      value: '',
      validation: ["required"],
      errors: [t("Horse is Required.")],
    },
    items: [INTIAL_TRAINING_ITEM],
  };

  const [trainingData, setTrainingData] = useState<any>(INTIAL_TRAINING_DATA);
  const [isFormValidated, setIsFormValidated] = useState(false);
  const [trainingError, setTrainingError] = useState<any>({});
  const [apiStatus, setApiStatus] = useState(API_STATUS.SUCCESS);
  const weekdays = t("weekdays", { returnObjects: true });
  const {setLoading} = useLoading();
  const dispatch = useDispatch();




  useEffect(() => {
    setTrainingError({ ...validate(trainingData).errors });
  }, [trainingData]);

  const setData = (field: string, value: any) => {
    let items: any = Object.assign({}, trainingData);
    items[field].value = value;
    setTrainingData({ ...items });
  };

  const addListItem = () => {
    let item = Object.assign([], trainingData?.items);
    item.push(INTIAL_TRAINING_ITEM);
    setTrainingData({ ...trainingData, items: item });
  };

  const removeListItem = (index: number) => {
    let items = Object.assign([], trainingData?.items);
    if (items.length <= 1) {
      return;
    }
    items.splice(index, 1);
    setTrainingData({ ...trainingData, items: items });
  };

  const handleChange = (index: number, fieldName: string, value: any) => {
    let items: any = Object.assign([], trainingData.items);
    items[index][fieldName].value = value;
    if (fieldName === "start_date") {
      let start_date = moment(value);
      let end_date = moment(items[index]["end_date"].value);
      if (start_date > end_date) {
        items[index]["end_date"].value = value;
      }
    }
    setTrainingData({ ...trainingData, items: items });
  };

  const handleSubmit = () => {
    if (validate(trainingData).isValidated === true) {
      let items: any = [];

      trainingData?.items?.map((item: any, index: number) => {
        let ob: any = {};
        Object.keys(INTIAL_TRAINING_ITEM).forEach((element) => {
          if (item[element].value !== "") {
            ob[element] = item[element].value;
          }
        });
        items.push(ob);
      });
      let params: any = {
        horse_id: [trainingData.horse_id.value],
        items: items,
      };
   
      setLoading(true);
      addTraining(params)
        .then((response: any) => {
          if (response.statusCode === SUCCESS_RESPONSE_CODE) {
            props.onHide();
            props.setrefreshlist();
          }
        })
        .catch((error: any) => {}).finally(() => {
          setLoading(false);
        });
    } else {
      setIsFormValidated(true);
      setTrainingError({ ...validate(trainingData)?.errors });
    }
  };

  useEffect(() => {
    if (props?.show === false) {
      setTrainingError({});
      setTrainingData(INTIAL_TRAINING_DATA);
      setIsFormValidated(false);
    } 
  }, [props?.show]);

  const { setrefreshlist , metaData, horseIds,...rest } = props;
  return (
    <Modal {...rest} centered className="add-training-popup modal-824">
      <Modal.Header closeButton>
        <p className="tl">{t("Add Training")}</p>
      </Modal.Header>
      <Modal.Body>
        <Row>
          <Col sm={6} md={6}>
            <SelectGropus
              label={`${t("Horse")}*`}
              value={metaData?.horse_list}
              defaultSelect={trainingData?.horse_id?.value}
              dropKey="id"
              dropVal="name"
              onChange={(e: any) => {
                setData("horse_id", e.target.value);
              }}
              error={
                isFormValidated && trainingError["horse_id"]
                  ? trainingError["horse_id"]
                  : null
              }
            />
          </Col>
        </Row>

        {trainingData.items?.map((item: any, index: number) => {
          let errors = trainingError?.["items"]?.[index];
          return (
            <div className="repeate-box" key={index + "add-training"}>
              <hr />
              <div className="rm-pr-40">
                <Row>
                  <Col sm={6} md={6}>
                    <Datepicker
                      label={`${t("Start Date")}*`}
                      onChange={(date: any) => {
                        let dateIs = date?.format("YYYY-MM-DD");
                        handleChange(index, "start_date", dateIs);
                      }}
                      value={moment(item?.start_date?.value)}
                      error={
                        isFormValidated && errors?.["start_date"]
                          ? errors?.["start_date"]
                          : null
                      }
                    />
                  </Col>
                  <Col sm={6} md={6}>
                    <Datepicker
                      label={`${t("End Date")}*`}
                      startDate={moment(item?.start_date?.value).format(
                        "YYYY-MM-DD"
                      )}
                      onChange={(date: any) => {
                        let dateIs = date?.format("YYYY-MM-DD");
                        handleChange(index, "end_date", dateIs);
                      }}
                      value={moment(item?.end_date?.value)}
                      error={
                        isFormValidated && errors?.["end_date"]
                          ? errors?.["end_date"]
                          : null
                      }
                    />
                  </Col>
                </Row>
                <Row>
                  <Col sm={6} md={6}>
                    <SelectGropus
                      label={`${t("Training Type")}*`}
                      value={metaData?.training_type}
                      defaultSelect={item?.training_type.value}
                      onChange={(e: any) => {
                        handleChange(index, "training_type", e.target.value);
                      }}
                      dropKey="id"
                      dropVal="metadata"
                      error={
                        isFormValidated && errors?.["training_type"]
                          ? errors?.["training_type"]
                          : null
                      }
                    />
                  </Col>
                  <Col sm={6} md={6}>
                    <InputGroups
                      label={`${t("Trainer")}*`}
                      type="text"
                      value={item?.trainer?.value}
                      onChange={(e: any) =>
                        handleChange(index, "trainer", e.target.value)
                      }
                      error={
                        isFormValidated && errors?.["trainer"]
                          ? errors?.["trainer"]
                          : null
                      }
                    />
                  </Col>
                </Row>
                <div className="duration-input">
                  <label>{t("Duration")}</label>
                  <div className="this-inpus-wrapper">
                    <InputGroups
                      type="text"
                      placeholder={t("HH")}
                      value={trainingData?.duration_hour?.value}
                      onChange={(e: any) =>
                        handleChange(index, "duration_hour", e.target.value)
                      }
                      error={
                        isFormValidated && errors?.["duration_hour"]
                          ? errors?.["duration_hour"]
                          : null
                      }
                    />
                    <InputGroups
                      type="text"
                      placeholder={t("MM")}
                      value={trainingData?.duration_minutes?.value}
                      onChange={(e: any) =>
                        handleChange(index, "duration_minutes", e.target.value)
                      }
                      error={
                        isFormValidated && errors?.["duration_minutes"]
                          ? errors?.["duration_minutes"]
                          : null
                      }
                    />
                    <InputGroups
                      type="text"
                      placeholder={t("SS")}
                      value={trainingData?.duration_second?.value}
                      onChange={(e: any) =>
                        handleChange(index, "duration_second", e.target.value)
                      }
                      error={
                        isFormValidated && errors?.["duration_second"]
                          ? errors?.["duration_second"]
                          : null
                      }
                    />
                  </div>
                </div>
              </div>
              <div className="nrm-pr-40">
                <Row>
                  <Col sm={6} md={6}>
                    <div className="widthPlus32">
                      <MultiSelectGroup
                        label={`${t("Days")}*`}
                        value={weekdays}
                        defaultSelect={item?.days?.value}
                        dropKey="id"
                        dropVal="name"
                        onChange={(e: any) => {
                          handleChange(index, "days", e);
                        }}
                        error={
                          isFormValidated && errors?.["days"]
                            ? errors?.["days"]
                            : null
                        }
                      />
                    </div>
                  </Col>
                  <Col sm={6} md={6}>
                    <div className="remove-btn-parent">
                      <Timepicker
                        allowClear={true}
                        label={`${t("Time")}`}
                        onChange={(time: any) => {
                          handleChange(
                            index,
                            "start_time",
                            time ? time.format("YYYY-MM-DD HH:mm") : null
                          );
                        }}
                        value={item?.start_time?.value ? moment(item?.start_time?.value) : undefined}
                        error={
                          isFormValidated && errors?.["start_time"]
                            ? errors?.["start_time"]
                            : null
                        }
                      />
                      {trainingData?.items?.length > 1 && (
                        <div
                          className="remove-btn"
                          onClick={() => removeListItem(index)}
                        >
                          <img src={MinusIcon} alt="" />
                        </div>
                      )}
                    </div>
                  </Col>
                </Row>
              </div>
            </div>
          );
        })}

        <hr />
        <div className="add-row-btn" onClick={addListItem}>
          <img src={PlusIcon} alt="" />
          <p className="bm">{t("Add Training")}</p>
        </div>
      </Modal.Body>
      <Modal.Footer>
        <div className="rm-pr-40">
          <div className="footer-2-btn">
            <TextButton onClick={props.onHide}> {t("Cancel")}</TextButton>
            <CustomeButton bg="fill" onClick={handleSubmit}>
            {t("Create")}  
            </CustomeButton>
          </div>
        </div>
      </Modal.Footer>
    </Modal>
  );
}
