import { t } from "i18next";
import moment from "moment";
import React, { useEffect, useState } from "react";
import Modal from "react-bootstrap/Modal";
import { useDispatch } from "react-redux";
import { API_STATUS } from "../../../Constant/Api";
import { SUCCESS_RESPONSE_CODE } from "../../../Constant/Status";
import { useLoading } from "../../../context/LoadingContext";
import { addEditContacts } from "../../../Redux/NonRedux/users";
import validate from "../../../Utility/Validation";
import CustomeButton from "../../Common/CustomButton/CustomButton";
import InputGroups from "../../Common/InputGroups/InputGroups";
import SelectGropus from "../../Common/SelectGroups/SelectGroups";
import TextButton from "../../Common/TextButton/TextButton";
import ToggleButton from "../../Common/ToggleButton/ToggleButton";

export default function AddContactPopup(props: any) {
  const INTIAL_CONTACT_DATA = {
    first_name: {
      value: "",
      validation: ["required"],
      errors: [t("First Name is required.")],
    },
    id: {
      value: "",
    },
    last_name: {
      value: "",
      validation: [""],
      errors: [t("Last Name is required.")],
    },
    email: {
      value: "",
      validation: ["email"],
      errors: [t("Please enter valid email.")],
    },
    phone_number: {
      value: "",
      validation: [""],
      errors: [t("Phone Number is required.")],
    },
    contact_type: {
      value: "",
      validation: ["required"],
      errors: [t("Contact Type is required.")],
    },
    status: {
      value: "0",
      validation: [""],
      errors: [t("status is required.")],
    },
  };

  const contactType = ["", ""];

  const [selected, setSelected] = useState("Active");

  const toggleBtnValue = [
    { name: t("Active"), value: "1" },
    { name: t("Inactive"), value: "0" },
  ];

  const [contactData, setContactData] = useState<any>(INTIAL_CONTACT_DATA);
  const [isFormValidated, setIsFormValidated] = useState(false);
  const [contactDataError, setContactDataError] = useState<any>({});
  const [apiStatus, setApiStatus] = useState(API_STATUS.SUCCESS);
  const dispatch = useDispatch();
  const { setLoading } = useLoading();
  const setData = (field: string, value: any) => {
    const ob: any = Object.assign({}, contactData);
    ob[field].value = value;
    setContactData({ ...ob });
  };

  const submitHandler = () => {
    if (validate(contactData).isValidated === true) {
      let params: any = {};
      Object.keys(contactData).forEach((element) => {
        if (contactData[element]) {
          params[element] = contactData[element].value;
        }
      });
      setLoading(true);
      addEditContacts(params)
        .then((response: any) => {
          if (response.statusCode === SUCCESS_RESPONSE_CODE) {
            setApiStatus(API_STATUS.SUCCESS);
            props.onHide();
            props.setrefreshlist();
          } else {
            setApiStatus(API_STATUS.FAILED);
          }
        })
        .catch((error) => {
          setApiStatus(API_STATUS.FAILED);
        })
        .finally(() => {
          setLoading(false);
        });
    } else {
      setContactDataError({ ...validate(contactData)?.errors });
      setIsFormValidated(true);
    }
  };

  useEffect(() => {
    if (props?.show === false) {
      setContactDataError({});
      setContactData(INTIAL_CONTACT_DATA);
      setIsFormValidated(false);
    }
  }, [props?.show]);

  useEffect(() => {
    setContactDataError({ ...validate(contactData).errors });
  }, [contactData]);

  useEffect(() => {
    if (props.isedit && props?.editData) {
      Object.keys(contactData).forEach((element) => {
        if (props?.editData?.[element]) {
          setData(element, `${props?.editData?.[element]}`);
        }
      });
    }
  }, [props?.editData]);

  const { setrefreshlist  , metaData ,  editData , ...rest } = props;
  return (
    <Modal {...rest} centered className="add-contact-popup modal-416">
      <Modal.Header closeButton>
        <p className="tl">{props?.isedit ? t("Edit Contact") : t("Add Contact") } </p>
      </Modal.Header>
      <Modal.Body>
        <div>
          <InputGroups
            label={`${t("First Name")}*`}
            type="text"
            autoComplete="off"
            value={contactData?.first_name?.value}
            onChange={(e: any) => setData("first_name", e.target.value)}
            error={
              isFormValidated && contactDataError["first_name"]
                ? contactDataError["first_name"]
                : null
            }
          />
          <InputGroups
            label={`${t("Last Name")}`}
            type="text"
            autoComplete="off"
            value={contactData?.last_name?.value}
            onChange={(e: any) => setData("last_name", e.target.value)}
            error={
              isFormValidated && contactDataError["last_name"]
                ? contactDataError["last_name"]
                : null
            }
          />
          <InputGroups
            label={`${t("Email")}`}
            type="text"
            autoComplete="off"
            value={contactData?.email?.value}
            onChange={(e: any) => setData("email", e.target.value)}
            error={
              isFormValidated && contactDataError["email"]
                ? contactDataError["email"]
                : null
            }
          />
          <InputGroups
            label={`${t("Phone Number")}`}
            type="text"
            autoComplete="off"
            value={contactData?.phone_number?.value}
            onChange={(e: any) => setData("phone_number", e.target.value)}
            error={
              isFormValidated && contactDataError["phone_number"]
                ? contactDataError["phone_number"]
                : null
            }
          />
          <SelectGropus
            label={`${t("Contact Type")}*`}
            value={metaData?.contacts_list}
            onChange={(e: any) => setData("contact_type", e.target.value)}
            dropKey="key"
            dropVal="value"
            defaultSelect={contactData?.contact_type?.value}
            error={
              isFormValidated && contactDataError["contact_type"]
                ? contactDataError["contact_type"]
                : null
            }
          />
          <ToggleButton
            value={metaData?.contact_status}
            toggleKey="id"
            toggleVal="value"
            label={`${t("Status")}`}
            selected={contactData?.status?.value}
            setSelected={(value: any) => {
              setSelected(value);
              setData("status", value);
            }}
          />
        </div>
      </Modal.Body>
      <Modal.Footer>
        <div className="footer-2-btn">
          <TextButton onClick={props.onHide}> {t("Cancel")}</TextButton>
          <CustomeButton bg="fill" onClick={submitHandler}>
            {props?.isedit ? t("Update") : t("Add")}
          </CustomeButton>
        </div>
      </Modal.Footer>
    </Modal>
  );
}
