import React from "react";

export default function Medal() {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M11.9937 8.69617L13.9304 12.6835L18.4873 13.3671L15.1835 16.5569L15.981 21L11.9937 18.8354L8.00633 21L8.8038 16.5569L5.5 13.3671L10.057 12.6835L11.9937 8.69617Z"
        stroke="#212B36"
        strokeWidth="1.5"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M17.9177 3V6.18987L11.9937 8.6962L6.06961 6.18987V3H17.9177Z"
        stroke="#212B36"
        strokeWidth="1.5"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}
