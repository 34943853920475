import React from "react";
import LoaderImg from "../../Assets/img/loader.svg";
import HorsesIcon from "../../Assets/img/Icon-logo.svg"

const Loader = () => {
  return (
    <div className="loader-wrapper">
      <div className="loader-box">
        <img src={HorsesIcon} alt="" />
        <span className="loader-5"></span>
      </div>
    </div>
  );
};

export default Loader;
