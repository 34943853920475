import type { DatePickerProps } from "antd";
import { t } from "i18next";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { Col, Row } from "react-bootstrap";
import Modal from "react-bootstrap/Modal";
import { useSelector } from "react-redux";
import { useLoading } from "../../../context/LoadingContext";
import { addEditBreedingStallion } from "../../../Redux/NonRedux/breeding";
import validate from "../../../Utility/Validation";
import CustomeButton from "../../Common/CustomButton/CustomButton";
import Datepicker from "../../Common/Datepicker/Datepicker";
import InputGroups from "../../Common/InputGroups/InputGroups";
import InputSelectGroup from "../../Common/InputSelectGroup/InputSelectGroup";
import InputSelectMix from "../../Common/InputSelectMix/InputSelectMix";
import TextButton from "../../Common/TextButton/TextButton";

export default function AddBreedingServiceStallionPopup(props: any) {
  const INTIAL_DATA = {
    id: {
      value: "",
    },
    dates: {
      value: moment().format("YYYY-MM-DD"),
      validation: ["required"],
      errors: [t("Date is required.")],
    },
    amount: {
      value: "",
      validation: [""],
      errors: [t("Amount is required.")],
    },
    select_vet_clinic: {
      value: "vet_clinic_id",
      // validation: ["requiredIf:vet_clinic_id"],
      errors: [t("Vet Clinic is required.")],
    },
    vet_clinic: {
      value: "",
      validation: ["requiredIf:select_vet_clinic:vet_clinic", "letters"],
      errors: [t("Vet Clinic is required."), t("Incorrect format")],
    },
    vet_clinic_id: {
      value: "",
      validation: ["requiredIf:select_vet_clinic:vet_clinic_id"],
      errors: [t("Vet Clinic is required.")],
    },
    // select_mare: {
    //   value: "mare_id",
    //   // validation: ["requiredIf:mare_id"],
    //   errors: ["Mare is required."],
    // },
    // mare: {
    //   value: "",
    //   validation: ["requiredIf:select_mare:mare", "letters"],
    //   errors: [t("Mare is required."), t("Incorrect format")],
    // },
    // mare_id: {
    //   value: "",
    //   validation: ["requiredIf:select_mare:mare_id"],
    //   errors: [t("Mare is required.")],
    // },
    select_sire: {
      value: "",
      // validation: ["requiredIf:sire_id"],
      errors: ["Sire is required."],
    },
    sire: {
      value: "",
      validation: ["letters"],
      errors: [t("Incorrect format")],
    },
    sire_id: {
      value: "",
      validation: [],
      errors: [t("Sire is required.")],
    },
    mare: {
      value: "",
      validation: ["required", "letters"],
      errors: [t("Mare is required."),t("Incorrect format")],
    },
    select_dam: {
      value: "dam_id",
      // validation: ["requiredIf:dam_id"],
      errors: [t("Dam is required.")],
    },
    dam: {
      value: "",
      validation: ["letters"],
      errors: [t("Incorrect format")],
    },
    dam_id: {
      value: "",
      validation: [],
      errors: [],
    },

    select_owner: {
      value: "owner_id",
      // validation: ["requiredIf:owner_id"],
      errors: ["Owner is required."],
    },
    owner: {
      value: "",
      validation: ["letters"],
      errors: [t("Incorrect format")],
    },
    owner_id: {
      value: "",
      validation: [],
      errors: [],
    },
  };

  const [isFormValidated, setIsFormValidated] = useState(false);
  const [dataError, setDataError] = useState<any>({});
  const [serviceData, setServiceData] = useState<any>(INTIAL_DATA);

  const { setLoading } = useLoading();

  const [vetClinicToggle, setVetClinicToggle] = useState("select");
  const [damToggle, setDamToggle] = useState("select");
  const [ownerToggle, setOwnerToggle] = useState("select");
  const [sireToggle, setSireToggle] = useState("select");

  const setData = (field: string, value: any) => {
    
    let ob = Object.assign({}, serviceData);
    ob[field].value = value;
    setServiceData({ ...ob });
  };

  const changeValidation = (field: string, value: string) => {
    let ob = Object.assign({}, serviceData);
    ob[field].value = value;
    setServiceData({ ...ob });
  };

  useEffect(() => {
    if (props.isedit && props?.editData) {
        setData("id", props?.editData.id);
        setData("dates", props?.editData?.dates);
        setData("amount", props?.editData?.amount);
        setData("mare", props?.editData?.mare);
        setData("owner_id", props?.editData?.owner_id);
        setData("vet_clinic_id", props?.editData?.vet_clinic_id);
        if (props?.editData?.sire_id) {
          setData("sire_id", props?.editData?.sire_id);
        } else if(props?.editData?.sire){
          setData("sire", props?.editData?.sire);
          setSireToggle("input")
          changeValidation("select_sire","sire");
        }
        if (props?.editData?.dam_id) {
          setData("dam_id", props?.editData?.dam_id);
        } else if(props?.editData?.dam){
          setData("dam", props?.editData?.dam);
          setDamToggle("input")
          changeValidation("select_dam","dam");
        }
    }
  }, [props?.editData]);

  useEffect(() => {
    setDataError({ ...validate(serviceData).errors });
  }, [serviceData]);

  const submitHandler = () => {
    if (validate(serviceData).isValidated === true) {
      setLoading(true);
      let params: any = {};
      Object.keys(serviceData).forEach((element) => {
        if (serviceData[element]) {
          params[element] = serviceData[element]?.value;
        }
      });
      params["horse_id"] = props.horseId;
      addEditBreedingStallion(params)
        .then((response: any) => {
          props.onHide();
          props.setRefreshList();
        })
        .catch((error: any) => {})
        .finally(() => {
          setLoading(false);
        });
    } else {
      setDataError({ ...validate(serviceData)?.errors });
      setIsFormValidated(true);
    }
  };

  useEffect(() => {
    if (props?.show === false) {
      setDataError({});
      setServiceData(INTIAL_DATA);
      setIsFormValidated(false);
      setVetClinicToggle("select");
      setOwnerToggle("select");
      setDamToggle("select");
      setSireToggle("select");
    }
  }, [props?.show]);

  const dateChange: DatePickerProps["onChange"] = (date, dateString) => {
    let dateIs = date?.format("YYYY-MM-DD");
    setData("dates", dateIs);
  };

  const toggleChange = (selectType: string, input: string) => {
    
    if (selectType === "select") {
      setData(`select_${input}`, `${input}_id`);
      setData(input, "");
    } else {
      setData(`select_${input}`, input);
      setData(`${input}_id`, "");
    }
  };
  const changeAmountIfNotAdded = (selectType: string) => {
    if (selectType == "input") {
      setData("amount", props?.amount);
    }
  };

  const prefrence = useSelector(
    (state: any) => state?.masterReducer?.data?.component?.preferance
  );
  const { editData, setRefreshList, metaData, ...rest } = props;

  return (
    <Modal {...rest} centered className="add-breeding-service-popup modal-784">
      <Modal.Header closeButton>
        <p className="tl">
          {props.isedit
            ? t("Edit Breeding Service")
            : t("Add Breeding Service")}{" "}
        </p>
      </Modal.Header>
      <Modal.Body>
        <Row>
          <Col sm={6} md={6}>
            <Datepicker
              endDate={moment().format("YYYY-MM-DD")}
              label={t("Date*")}
              onChange={dateChange}
              value={moment(serviceData?.dates?.value)}
              error={
                isFormValidated && dataError["dates"]
                  ? dataError["dates"]
                  : null
              }
            />
          </Col>
        </Row>
        <Row>
          <Col sm={6} md={6}>
            <InputSelectGroup
              label={`${t("Vet / Clinic")}*`}
              selectValue={metaData?.vet_clinic}
              dropKey="id"
              dropVal="first_name"
              defaultSelect={serviceData?.vet_clinic_id?.value}
              value={serviceData?.vet_clinic?.value}
              inputSelectToggle={vetClinicToggle}
              setInputSelectToggle={(val: any) => {
                setVetClinicToggle(val);
                toggleChange(val, "vet_clinic");
              }}
              error={
                isFormValidated &&
                (dataError["vet_clinic"] || dataError["vet_clinic_id"])
                  ? dataError["vet_clinic"] || dataError["vet_clinic_id"]
                  : null
              }
              onSelectChange={(e: any) => {
                setData("vet_clinic_id", e);
              }}
              onInputChange={(e: any) => {
                setData("vet_clinic", e.target.value);
              }}
            />
          </Col>
        </Row>
        <Row>
          <Col sm={6} md={6}>
            <InputGroups
              label={`${t("Mare")}*`}
              type="text"
              autoComplete="off"
              value={serviceData?.mare?.value}
              onChange={(e: any) => setData("mare", e.target.value)}
              error={
                isFormValidated && dataError["mare"] ? dataError["mare"] : null
              }
            />
          </Col>
        </Row>
        <Row>
          <Col sm={6} md={6}>
            <InputSelectGroup
              label={`${t("Sire")}`}
              selectValue={metaData?.stallion}
              inputSelectToggle={sireToggle}
              inputValue={serviceData?.sire?.value}
              setInputSelectToggle={(val: any) => {
                setSireToggle(val);
                toggleChange(val, "sire");
              }}
              onSelectChange={(value: any) => {
                setData("sire_id", value);
              }}
              defaultSelect={serviceData?.sire_id?.value}
              dropKey="id"
              dropVal="name"
              onInputChange={(e: any) => {
                setData("sire", e.target.value);
              }}
              error={
                isFormValidated && (dataError["sire"] || dataError["sire_id"])
                  ? dataError["sire"] || dataError["sire_id"]
                  : null
              }
            />
            {/* 
            <InputGroups
              label="Sire"
              type="text"
              readOnly={true}
              autoComplete="off"
              value={horseInfo?.name}
            /> */}
          </Col>
          <Col sm={6} md={6}>
            <InputSelectGroup
              label={`${t("Dam")}`}
              selectValue={metaData?.recipient_mare}
              inputSelectToggle={damToggle}
              inputValue={serviceData?.dam?.value}
              setInputSelectToggle={(val: any) => {
                setDamToggle(val);
                toggleChange(val, "dam");
                changeAmountIfNotAdded(val);
              }}
              onSelectChange={(value: any) => {
                setData("dam_id", value);
              }}
              defaultSelect={serviceData?.dam_id?.value}
              dropKey="id"
              dropVal="name"
              onInputChange={(e: any) => {
                setData("dam", e.target.value);
              }}
              error={
                isFormValidated && (dataError["dam"] || dataError["dam_id"])
                  ? dataError["dam"] || dataError["dam_id"]
                  : null
              }
            />
          </Col>
        </Row>
        <Row>
          <Col sm={6} md={6}>
            <InputSelectGroup
              label={`${t("Owner")}`}
              selectValue={metaData?.owner}
              inputSelectToggle={ownerToggle}
              inputValue={serviceData?.owner?.value}
              setInputSelectToggle={(val: any) => {
                setOwnerToggle(val);
                toggleChange(val, "owner");
              }}
              onSelectChange={(value: any) => {
                setData("owner_id", value);
              }}
              defaultSelect={serviceData?.owner_id?.value}
              dropKey="id"
              dropVal="first_name"
              onInputChange={(e: any) => {
                setData("owner", e.target.value);
              }}
              error={
                isFormValidated && (dataError["owner"] || dataError["owner_id"])
                  ? dataError["owner"] || dataError["owner_id"]
                  : null
              }
            />
          </Col>
        </Row>
        <Row>
          <Col sm={6} md={6}>
            <InputSelectMix
              label={`${t("Amount")}`}
              inputType="number"
              onInputChange={(e: any) => setData("amount", e.target.value)}
              inputValue={serviceData?.amount?.value}
              inputError={
                isFormValidated && dataError["amount"]
                  ? dataError["amount"]
                  : null
              }
              inputSecondValue={prefrence?.currency_value}
            />
          </Col>
        </Row>
      </Modal.Body>
      <Modal.Footer>
        <div className="footer-2-btn">
          <TextButton onClick={props?.onHide}> {t("Cancel")}</TextButton>
          <CustomeButton bg="fill" onClick={submitHandler}>
            {props.isedit ? t("Update") : t("Add")}
          </CustomeButton>
        </div>
      </Modal.Footer>
    </Modal>
  );
}
