import { globalPostAction } from "../../actions/globalActions";
import { END_POINTS, MAX_CALLS } from "../../Constant/Api";
import { MASTER_API } from "../../Constant/Config";
import { LANGUAGE } from "../../Constant/LocalStorage";
import { SUCCESS_RESPONSE_CODE } from "../../Constant/Status";
import api from "../../Service/Axios";
import { showToast } from "../../Utility/General";
import {
  globalGetActionWithReducer,
  globalPostActionWithReducer,
} from "../globallActions";
// import { showToast } from "../../Utility/General";
import {
  META_COLOR_REQUEST,
  META_COLOR_SUCCESS,
  META_COLOR_FAILD,
  META_COLOR_LONG,
  USER_LANGUAGE_REQUEST,
  USER_LANGUAGE_SUCCESS,
  USER_LANGUAGE_LONG,
  USER_LANGUAGE_FAILD,
  CLINIC_CONTACT_REQUEST,
  CLINIC_CONTACT_SUCCESS,
  CLINIC_CONTACT_LONG,
  CLINIC_CONTACT_FAILD,
  META_VACCINES_REQUEST,
  META_VACCINES_SUCCESS,
  META_VACCINES_LONG,
  META_VACCINES_FAILD,
  META_DEWORMER_REQUEST,
  META_DEWORMER_SUCCESS,
  META_DEWORMER_LONG,
  META_DEWORMER_FAILD,
  META_GENDER_REQUEST,
  META_GENDER_SUCCESS,
  META_GENDER_FAILD,
  META_GENDER_LONG,
  META_HORSE_STATUS_REQUEST,
  META_HORSE_STATUS_SUCCESS,
  META_HORSE_STATUS_FAILD,
  META_HORSE_STATUS_LONG,
  META_DIET_CATEGORY_REQUEST,
  META_DIET_CATEGORY_SUCCESS,
  META_DIET_CATEGORY_FAILD,
  META_DIET_CATEGORY_LONG,
  META_CURRENCY_REQUEST,
  META_CURRENCY_SUCCESS,
  META_CURRENCY_FAILD,
  META_CURRENCY_LONG,
  META_UNIT_REQUEST,
  META_UNIT_SUCCESS,
  META_UNIT_FAILD,
  META_UNIT_LONG,
  FARRIER_CONTACT_FAILD,
  FARRIER_CONTACT_LONG,
  FARRIER_CONTACT_REQUEST,
  FARRIER_CONTACT_SUCCESS,
  META_SHOEING_TYPES_FAILD,
  META_SHOEING_TYPES_LONG,
  META_SHOEING_TYPES_REQUEST,
  META_SHOEING_TYPES_SUCCESS,
  META_SHOE_MATERIAL_REQUEST,
  META_SHOE_MATERIAL_FAILD,
  META_SHOE_MATERIAL_LONG,
  META_SHOE_MATERIAL_SUCCESS,
  GET_COUNTRY_LIST_FAILD,
  GET_COUNTRY_LIST_LONG,
  GET_COUNTRY_LIST_REQUEST,
  GET_COUNTRY_LIST_SUCCESS,
  META_DISCIPLINE_FAILD,
  META_DISCIPLINE_LONG,
  META_DISCIPLINE_REQUEST,
  META_DISCIPLINE_SUCCESS,
  HORSE_BREEDER_CONTACT_FAILD,
  HORSE_BREEDER_CONTACT_LONG,
  HORSE_BREEDER_CONTACT_REQUEST,
  HORSE_BREEDER_CONTACT_SUCCESS,
  HORSE_OWNER_CONTACT_FAILD,
  HORSE_OWNER_CONTACT_LONG,
  HORSE_OWNER_CONTACT_REQUEST,
  HORSE_OWNER_CONTACT_SUCCESS,
  META_BREED_FAILD,
  META_BREED_LONG,
  META_BREED_REQUEST,
  META_BREED_SUCCESS,
  DAM_LIST_FAILD,
  DAM_LIST_LONG,
  DAM_LIST_REQUEST,
  DAM_LIST_SUCCESS,
  SIRE_LIST_FAILD,
  SIRE_LIST_LONG,
  SIRE_LIST_REQUEST,
  SIRE_LIST_SUCCESS,
  LOCATION_LIST_FAILD,
  LOCATION_LIST_LONG,
  LOCATION_LIST_REQUEST,
  LOCATION_LIST_SUCCESS,
  META_HORSE_LIST_FAILD,
  META_HORSE_LIST_LONG,
  META_HORSE_LIST_REQUEST,
  META_HORSE_LIST_SUCCESS,
  META_TRAINING_TYPES_FAILD,
  META_TRAINING_TYPES_LONG,
  META_TRAINING_TYPES_REQUEST,
  META_TRAINING_TYPES_SUCCESS,
  MASTER_FAILD,
  MASTER_LONG,
  MASTER_REQUEST,
  MASTER_SUCCESS,
  META_INCOME_CATEGORY_FAILD,
  META_INCOME_CATEGORY_LONG,
  META_INCOME_CATEGORY_REQUEST,
  META_INCOME_CATEGORY_SUCCESS,
  META_BREEDING_SERVICE_TYPE_FAILD,
  META_BREEDING_SERVICE_TYPE_LONG,
  META_BREEDING_SERVICE_TYPE_REQUEST,
  META_BREEDING_SERVICE_TYPE_SUCCESS,
  REMINDER_STATUS_CHANGE_SUCCESS,
  SET_USER_DETAILS,
  CHANGE_UPGRADE_SUBSCRIPTION_POPUP_STATUS,
} from "./GeneralType";

const lang = localStorage.getItem(LANGUAGE)
  ? localStorage.getItem(LANGUAGE)
  : "en";

// export function getMaster(params: any) {
//   let recallCount = 0;
//   return (dispatch: any) => {
//     dispatch({
//       type: MASTER_REQUEST,
//       payload: { type: MASTER_REQUEST },
//     });
//     function recursiveCall() {
//       api
//         .post(MASTER_API, params)
//         .then((result) => {
//           if (result.data.statusCode === SUCCESS_RESPONSE_CODE) {
//             dispatch({
//               type: MASTER_SUCCESS,
//               payload: { data: result.data, type: MASTER_SUCCESS },
//             });

//             dispatch({
//               type: CART_COUNT_GLOBAL,
//               payload: { data: result.data?.cartCount },
//             });
//           } else if (recallCount < MAX_CALLS) {
//             recursiveCall();
//             recallCount++;
//             dispatch({
//               type: MASTER_LONG,
//               payload: { data: {}, type: MASTER_LONG },
//             });
//           } else {
//             dispatch({
//               type: MASTER_FAILD,
//               payload: { data: {}, type: MASTER_FAILD },
//             });
//           }
//         })
//         .catch((error) => {
//           if (recallCount < MAX_CALLS) {
//             recursiveCall();
//             recallCount++;
//             dispatch({
//               type: MASTER_LONG,
//               payload: { data: {}, type: MASTER_LONG },
//             });
//           } else {
//             dispatch({
//               type: MASTER_FAILD,
//               payload: { data: {}, type: MASTER_FAILD },
//             });
//           }
//         });
//     }
//     recursiveCall();
//   };
// }

export function masterAction() {
  let apiUrl = END_POINTS.master;
  return globalGetActionWithReducer(
    apiUrl,
    MASTER_REQUEST,
    MASTER_SUCCESS,
    MASTER_LONG,
    MASTER_FAILD
  );
}

export function clinicContactAction(params: any) {
  let apiUrl = END_POINTS.contacts;
  return globalPostActionWithReducer(
    params,
    apiUrl,
    CLINIC_CONTACT_REQUEST,
    CLINIC_CONTACT_SUCCESS,
    CLINIC_CONTACT_LONG,
    CLINIC_CONTACT_FAILD
  );
}

export function horseOwnerContactAction(params: any) {
  let apiUrl = END_POINTS.contacts;
  return globalPostActionWithReducer(
    params,
    apiUrl,
    HORSE_OWNER_CONTACT_REQUEST,
    HORSE_OWNER_CONTACT_SUCCESS,
    HORSE_OWNER_CONTACT_LONG,
    HORSE_OWNER_CONTACT_FAILD
  );
}
export function horseBreederContactAction(params: any) {
  let apiUrl = END_POINTS.contacts;
  return globalPostActionWithReducer(
    params,
    apiUrl,
    HORSE_BREEDER_CONTACT_REQUEST,
    HORSE_BREEDER_CONTACT_SUCCESS,
    HORSE_BREEDER_CONTACT_LONG,
    HORSE_BREEDER_CONTACT_FAILD
  );
}

export function metaBreedAction() {
  let apiUrl = END_POINTS.metaBreedTypes + lang;
  return globalGetActionWithReducer(
    apiUrl,
    META_BREED_REQUEST,
    META_BREED_SUCCESS,
    META_BREED_LONG,
    META_BREED_FAILD
  );
}

export function sireListAction(params: any) {
  let apiUrl = END_POINTS.sireList;
  return globalPostActionWithReducer(
    params,
    apiUrl,
    SIRE_LIST_REQUEST,
    SIRE_LIST_SUCCESS,
    SIRE_LIST_LONG,
    SIRE_LIST_FAILD
  );
}

export function damListAction(params: any) {
  let apiUrl = END_POINTS.damList;
  return globalPostActionWithReducer(
    params,
    apiUrl,
    DAM_LIST_REQUEST,
    DAM_LIST_SUCCESS,
    DAM_LIST_LONG,
    DAM_LIST_FAILD
  );
}

export function locationListAction(params: any) {
  let apiUrl = END_POINTS.locationList;
  return globalPostActionWithReducer(
    params,
    apiUrl,
    LOCATION_LIST_REQUEST,
    LOCATION_LIST_SUCCESS,
    LOCATION_LIST_LONG,
    LOCATION_LIST_FAILD
  );
}

export function changeLangAction(params: any) {
  let apiUrl = END_POINTS.changeLang;
  return globalPostAction(params, apiUrl);
}

export function metaVaccineAction() {
  let apiUrl = END_POINTS.metaVaccine + lang;
  return globalGetActionWithReducer(
    apiUrl,
    META_VACCINES_REQUEST,
    META_VACCINES_SUCCESS,
    META_VACCINES_LONG,
    META_VACCINES_FAILD
  );
}



export function metaColorAction() {
  let apiUrl = END_POINTS.metaColor + lang;
  return globalGetActionWithReducer(
    apiUrl,
    META_COLOR_REQUEST,
    META_COLOR_SUCCESS,
    META_COLOR_LONG,
    META_COLOR_FAILD
  );
}

export function metaGenderAction() {
  let apiUrl = END_POINTS.metaGender + lang;
  return globalGetActionWithReducer(
    apiUrl,
    META_GENDER_REQUEST,
    META_GENDER_SUCCESS,
    META_GENDER_LONG,
    META_GENDER_FAILD
  );
}

export function metaHorseStatusAction() {
  let apiUrl = END_POINTS.metaHorseStatus + lang;
  return globalGetActionWithReducer(
    apiUrl,
    META_HORSE_STATUS_REQUEST,
    META_HORSE_STATUS_SUCCESS,
    META_HORSE_STATUS_LONG,
    META_HORSE_STATUS_FAILD
  );
}

export function metaDietCategoryAction() {
  let apiUrl = END_POINTS.metaDietCategory + lang;
  return globalGetActionWithReducer(
    apiUrl,
    META_DIET_CATEGORY_REQUEST,
    META_DIET_CATEGORY_SUCCESS,
    META_DIET_CATEGORY_LONG,
    META_DIET_CATEGORY_FAILD
  );
}

export function metaCurrencyAction() {
  let apiUrl = END_POINTS.metaCurrency + lang;
  return globalGetActionWithReducer(
    apiUrl,
    META_CURRENCY_REQUEST,
    META_CURRENCY_SUCCESS,
    META_CURRENCY_LONG,
    META_CURRENCY_FAILD
  );
}

export function metaUnitAction() {
  let apiUrl = END_POINTS.metaUnit + lang;
  return globalGetActionWithReducer(
    apiUrl,
    META_UNIT_REQUEST,
    META_UNIT_SUCCESS,
    META_UNIT_LONG,
    META_UNIT_FAILD
  );
}

export function metaShoeingTypesAction() {
  let apiUrl = END_POINTS.metaShoeingTypes + lang;
  return globalGetActionWithReducer(
    apiUrl,
    META_SHOEING_TYPES_REQUEST,
    META_SHOEING_TYPES_SUCCESS,
    META_SHOEING_TYPES_LONG,
    META_SHOEING_TYPES_FAILD
  );
}

export function metaShoeMaterialAction() {
  let apiUrl = END_POINTS.metaShoeMaterial + lang;
  return globalGetActionWithReducer(
    apiUrl,
    META_SHOE_MATERIAL_REQUEST,
    META_SHOE_MATERIAL_SUCCESS,
    META_SHOE_MATERIAL_LONG,
    META_SHOE_MATERIAL_FAILD
  );
}


export function metaIncomeCategoryAction() {
  let apiUrl = END_POINTS.metaIncomeCategory + lang;
  return globalGetActionWithReducer(
    apiUrl,
    META_INCOME_CATEGORY_REQUEST,
    META_INCOME_CATEGORY_SUCCESS,
    META_INCOME_CATEGORY_LONG,
    META_INCOME_CATEGORY_FAILD
  );
}

export function countryListAction(params: any = {}) {
  let apiUrl = END_POINTS.countryList;
  return globalPostActionWithReducer(
    params,
    apiUrl,
    GET_COUNTRY_LIST_REQUEST,
    GET_COUNTRY_LIST_SUCCESS,
    GET_COUNTRY_LIST_LONG,
    GET_COUNTRY_LIST_FAILD
  );
}

export function farrierContactAction(params: any) {
  let apiUrl = END_POINTS.contacts;
  return globalPostActionWithReducer(
    params,
    apiUrl,
    FARRIER_CONTACT_REQUEST,
    FARRIER_CONTACT_SUCCESS,
    FARRIER_CONTACT_LONG,
    FARRIER_CONTACT_FAILD
  );
}

export function metaHorseListAction(params: any) {
  let apiUrl = END_POINTS.metaHorseList;
  return globalPostActionWithReducer(
    params,
    apiUrl,
    META_HORSE_LIST_REQUEST,
    META_HORSE_LIST_SUCCESS,
    META_HORSE_LIST_LONG,
    META_HORSE_LIST_FAILD
  );
}

export function metaTrainingTypesAction() {
  let apiUrl = END_POINTS.metaTrainingType + lang;
  return globalGetActionWithReducer(
    apiUrl,
    META_TRAINING_TYPES_REQUEST,
    META_TRAINING_TYPES_SUCCESS,
    META_TRAINING_TYPES_LONG,
    META_TRAINING_TYPES_FAILD
  );
}

export function metaBreedingServiceTypesAction() {
  let apiUrl = END_POINTS.meataBreedingServiceType + lang;
  return globalGetActionWithReducer(
    apiUrl,
    META_BREEDING_SERVICE_TYPE_REQUEST,
    META_BREEDING_SERVICE_TYPE_SUCCESS,
    META_BREEDING_SERVICE_TYPE_LONG,
    META_BREEDING_SERVICE_TYPE_FAILD
  );
}

export function reminderStatusAction(
  params: any,
  type: string = REMINDER_STATUS_CHANGE_SUCCESS
) {
  return (dispatch: any) => {
    dispatch({
      type: type,
      payload: {
        type: type,
        data: {
          type: type,
          reminder_id: params?.reminder_id,
          completion: params?.completion,
          key: params?.rediract_to,
          path: params?.path,
        },
      },
    });
  };
}

export function userDetailsAction(params: any) {
  return (dispatch: any) => {
    dispatch({
      type: SET_USER_DETAILS,
      payload: {
        type: SET_USER_DETAILS,
        data: {
          ...params,
        },
      },
    });
  };
}

export function upgradePopupAction(params: any) {
  return (dispatch: any) => {
    dispatch({
      type: CHANGE_UPGRADE_SUBSCRIPTION_POPUP_STATUS,
      payload: {
        type: CHANGE_UPGRADE_SUBSCRIPTION_POPUP_STATUS,
        data: {
          ...params,
        },
      },
    });
  };
}