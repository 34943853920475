import axios from "axios";

const api = {
    get(url: string, params: any) {
      return axios.get(url, { params })
    },
  
    post(url: string, data: any) {
      return axios.post(url, data)
    }
  }
  
  export default api