import { ADD_DIET_REQUEST  , ADD_DIET_SUCCESS , ADD_DIET_FAILD , ADD_DIET_CLEAR , ADD_DIET_LONG, GET_DIET_LIST_CLEAR, GET_DIET_LIST_FAILD, GET_DIET_LIST_LONG, GET_DIET_LIST_REQUEST, GET_DIET_LIST_SUCCESS, DESTROY_DIET_CLEAR, DESTROY_DIET_FAILD, DESTROY_DIET_LONG, DESTROY_DIET_REQUEST, DESTROY_DIET_SUCCESS, VIEW_DIET_CLEAR, VIEW_DIET_FAILD, VIEW_DIET_LONG, VIEW_DIET_REQUEST, VIEW_DIET_SUCCESS} from "./DietType";


export function addEditDietRedux(state = {}, action: any) {
    switch (action.type) {
      case ADD_DIET_REQUEST:
        return { ...action.payload };
      case ADD_DIET_SUCCESS:
        return { ...action.payload };
      case ADD_DIET_FAILD:
        return { ...action.payload };
      case ADD_DIET_LONG:
        return { ...action.payload };
      case ADD_DIET_CLEAR:
        return {};
      default:
        return state;
    }
}

export function DietListRedux(state = {}, action: any) {
  switch (action.type) {
    case GET_DIET_LIST_REQUEST:
      return { ...action.payload };
    case GET_DIET_LIST_SUCCESS:
      return { ...action.payload };
    case GET_DIET_LIST_FAILD:
      return { ...action.payload };
    case GET_DIET_LIST_LONG:
      return { ...action.payload };
    case GET_DIET_LIST_CLEAR:
      return {};
    default:
      return state;
  }
}

export function destroyDietRedux(state = {}, action: any) {
  switch (action.type) {
    case DESTROY_DIET_REQUEST:
      return { ...action.payload };
    case DESTROY_DIET_SUCCESS:
      return { ...action.payload };
    case DESTROY_DIET_FAILD:
      return { ...action.payload };
    case DESTROY_DIET_LONG:
      return { ...action.payload };
    case DESTROY_DIET_CLEAR:
      return {};
    default:
      return state;
  }
}


export function viewDietRedux(state = {}, action: any) {
  switch (action.type) {
    case VIEW_DIET_REQUEST:
      return { ...action.payload };
    case VIEW_DIET_SUCCESS:
      return { ...action.payload };
    case VIEW_DIET_FAILD:
      return { ...action.payload };
    case VIEW_DIET_LONG:
      return { ...action.payload };
    case VIEW_DIET_CLEAR:
      return {};
    default:
      return state;
  }
}