import { t } from "i18next";
import React, { useContext, useEffect } from "react";
import { Outlet } from "react-router-dom";
import BookPrimaryIcon from "../../Assets/img/book-primary.svg";
import { SidebarContext } from "../../context/SideBarContext";

export default function FarmDirectory() {
  const { setSidebar }: any = useContext(SidebarContext);
  useEffect(() => {
    setSidebar("");
  }, []);
  return (
    <>
      <div className="farm-directory-page">
        {/* Page Title Start */}
        <div className="page-title-wrapper">
          <div className="title-first-menu">
            <div className="title-icon-text">
              <img src={BookPrimaryIcon} alt="" />
              <h5>{t("Farm Directory")}</h5>
            </div>
          </div>
        </div>
        {/* Page Title End */}

        <div>
          <Outlet />
        </div>
      </div>
    </>
  );
}
