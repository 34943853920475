import React from "react";
import Modal from "react-bootstrap/Modal";
import CustomeButton from "../../Common/CustomButton/CustomButton";
import { useNavigate } from "react-router-dom";
import TextButton from "../../Common/TextButton/TextButton";
import { t } from "i18next";
import CheckboxLabel from "../../Common/CheckboxLabel/CheckboxLabel";
import { Col } from "react-bootstrap";

export default function ConfirmPopupWithCheckBox(props: any) {
  const {
    confirm,
    isChecked,
    isReminderAdded,
    setIsreminderAdded,
    isRedirect,
    ...rest
  } = props;
  return (
    <Modal {...rest} centered className="logout-popup modal-416">
      <Modal.Body>
        <p className="tl">{props.heading}</p>
        <span className="bl">{props.message}</span>
        <Col sm={12} className="mt-3" md={12}>
          {isRedirect ? (
            <CheckboxLabel
              checked={isReminderAdded == "0" ? 0 : 1}
              onChange={(e: any) => {
                setIsreminderAdded(e.target.checked);
              }}
            >
              {" "}
              {t("Already added")}
            </CheckboxLabel>
          ) : null}
        </Col>
      </Modal.Body>
      <Modal.Footer>
        <div className="footer-2-btn">
          <TextButton onClick={props.onHide}>{t("Cancel")}</TextButton>
          <CustomeButton onClick={props.confirm} bg="fill">
            {t("Confirm")}
          </CustomeButton>
        </div>
      </Modal.Footer>
    </Modal>
  );
}
