import {
  HORSEDETAILS_DIET,
  HORSEDETAILS_FARRIER,
  HORSEDETAILS_HEALTH_DENTAL,
  HORSEDETAILS_HEALTH_PARASITECONTROL,
  HORSEDETAILS_HEALTH_VACCINATION,
  HORSEDETAILS_MEASUREMENTS,
} from "./Route";

export enum USER_TYPE {
    STUD_OWNER = "stud_owner",
    STUD_STAFF = "stud_staff"
}

export const CHECKOUTPAGE = {
  auth: "auth",
  auth_login: "auth_login",
  auth_signup: "auth_signup",
  auth_guest: "auth_guest",
  mob_login: "mob_login",
  mob_signup: "mob_signup",
  mob_guest: "mob_guest",
  address: "address",
  payment: "payment",
  shipping: "shipping",
  address_payment: "address_payment",
  auth_payment: "auth_payment",
};

export const REMINDER_REDIRECT: any = {
  measurements: HORSEDETAILS_MEASUREMENTS,
  diet: HORSEDETAILS_DIET,
  vaccination: HORSEDETAILS_HEALTH_VACCINATION,
  parasite_control: HORSEDETAILS_HEALTH_PARASITECONTROL,
  dental: HORSEDETAILS_HEALTH_DENTAL,
  farrier: HORSEDETAILS_FARRIER,
};

export const SIDEBAR = {
  DASHBOARD: "DASHBOARD",
  MY_HORSE: "MY_HORSE",
  BOARDING: "BOARDING",
  BREEDING: "BREEDING",
  HEALTH: "HEALTH",
  DIET: "DIET",
  FARRIER: "FARRIER",
  TRAINING: "TRAINING",
  TRANSPORATATION: "TRANSPORATATION",
  INVENTORY: "INVENTORY",
  TASK: "TASK",
  REMINDER: "REMINDER",
  INVOICING: "INVOICING",
  INCOME_EXPENSES: "INCOME_EXPENSES",
};

export const DATATABLE = {
  BOARDING: "boarding",
  LOCATION: "location",
  CONTACT: "contact",
  STAFF: "staff",
  PAYROLL: "payroll",
  INVOICE: "invoice",
  COST_CATEGORY_DETAILS: "cost_category_details",
  COST_HORSE_DETAILS: "cost_horse_details",
  REMINDER: "reminder",
  TASK: "task",
  INVENTORY: "inventory",
  TRANSPORTATION: "transportation",
  TRAINING: "training",
  FARRIER_MAIN_LIST: "farrier_main_list",
  DIET_MAIN_LIST: "diet_main_list",
  HORSRE_HELTH:"health",
  HELTH_VITAL_SIGNS: "helth_vital_signs",
  HELTH_VACINATION: "helth_vacination",
  HELTH_PARASITE_CONTROL: "helth_parasite_control",
  HELTH_DENTAL: "helth_dental",
  HELTH_VET_CLINIC_VISIT: "helth_vet_clinic_visit",
  HELTH_TREATMENT: "helth_treatment",
  HELTH_LAB_TEST: "helth_lab_test",
  HELTH_X_RAY: "helth_x_ray",
  BREEDING: "breeding",
  MY_HORSE: "my_horse",
  HORSE_BREEDING: "horse_breeding",
  FARRIER: "farrier",
  ACHIVEMENT: "achivement",
};

export const PERMISSIONS = {
  ACCESS_FARM_DIRECTORY: "access_farm_directory",
  ACCESS_MARKET_PLACE: "access_market_place",
  EXPORT_FEATURE: "export_feature",
  INCOME_EXPENSE: "income_expenses",
  INVOICING: "invoicing",
  INVENTORY: "inventory",
  LINKS_SHARING_FEATURES: "links_sharing_feature",
  PICTURES: "pictures",
  VIDEO: "videos",
  EXPORT: "export_feature",
  BOARDING:"boarding",
  PAYROLL:"payroll",
};

export const UPGRADE_MESSAGE = {
  ADD_STAFF: "You need to upgrade plan to add more staff.",
  ADD_HORSE: "You need to upgrade plan to add more horse.",
  ADD_IMAGE: "You need to upgrade plan to add more images.",
  ADD_VIDEO: "You need to upgrade plan to add more videos.",
  GRANT_USER:"You need to upgrade the plan to grant more staff."
};

export const PERMISSION_ROUTES = new Map([
  ["incomeexpense", { permission: PERMISSIONS.INCOME_EXPENSE }],
  ["invoicing", { permission: PERMISSIONS.INVOICING }],
  ["inventory", { permission: PERMISSIONS.INVENTORY }],
  ["boarding" , { permission: PERMISSIONS.BOARDING }],
  ["/user/userpayroll" , { permission: PERMISSIONS.PAYROLL }]
]);

export const PERMISSION = "PERMISSIONS";
export const DEFAULT_SORTING = "default_sorting";

export const DOWNLOADEXT = {
  "application/pdf": "pdf",
  "application/vnd.ms-excel": "xls",
  "application/msword": "doc",
  "application/vnd.ms-powerpoint": "ppt",
  "application/zip": "zip",
  "image/png": "png",
  "image/jpeg": "jpeg",
  "image/jpg": "jpg",
  "text/csv": "csv",
  "image/gif": "gif",
  "image/webp": "webp",
  "application/docx": "docx",
  "application/doc": "doc",
  "image/svg+xml": "svg",
  "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet": "xlsx",
  "application/vnd.openxmlformats-officedocument.presentationml.presentation":
    "pptx",
};

export const MESSAGE = {
  TO_BIG: "file is to big.",
  VALID_FILE_FORMATE: "please Upload valid file formate.",
};

export const SHOW_MESSAGE = true;

export const MEASUREMENT = {
  INCHES: "INCHES",
  CENTIMETER: "CENTIMETER",
  WEIGHT_CENTIMETER_CONVESION: 11990,
  WEIGHT_INCHES_CONVERSION: 330,
  HEIGHT_CONVERSION: 4,
};

export const PICTUREVIDEOTYPE = {
  PICTURE: "0",
  VIDEO: "1",
};

export const YOUTUBE_EMBED_LINK = "https://www.youtube.com/embed/";
export const WATCH = "watch";

export const PEDIGREE = {
  SPAN_32: "span_32",
  SPAN_16: "span_16",
  SPAN_8: "span_8",
  SPAN_4: "span_4",
  SPAN_2: "span_2",
  SPAN_1: "span_1",
};

export const REFRESH = {
  PEDIGREE: "PEDIGREE",
  HORSE_DETAILS: "HORSE_DETAILS",
  STALLION_STATUS: "STALLION_STATUS",
};

export const INCOME_EXPENSE_TYPE = {
  INCOME: 2,
  EXPENSE: 1,
};

export const SUCCESS = "success";
export const ERROR = "error";

export const SOLD_ID = "20";
export const DEATH_ID = "21";

export const MALE = "male";
export const FEMALE = "female";
export const PREGNANT = "pregnant";
export const DATE_FORMATE = "DD/MM/YYYY";

export const DIET_ACTIVITY = {
  maintainace: { concentrate: 5, forage: 95 },
  breed_groth: { concentrate: 10, forage: 90 },
  conditioning: { concentrate: 20, forage: 80 },
  light_work: { concentrate: 20, forage: 80 },
  medium_work: { concentrate: 30, forage: 70 },
  heavy_work: { concentrate: 50, forage: 50 },
  feed_percentage: 2.5,
};

export const FILETYPE = {
  DOC: "doc",
  DOCX: "docx",
};

export const DEFAULT_HORSE_PROFILE =
  process.env.REACT_APP_ASSET_URL + "/horse/image/default.png";

export const FORMLABEL = {
  USE_FILE_TYPE: "Use a pdf, docx, jpg, png, heic",
};

export const PEDIGREE_LAYER_CLASS: any = {
  "2": "two-layer",
  "3": "three-layer",
  "4": "four-layer",
  "5": "five-layer",
  "6": "five-layer",
  "7": "five-layer",
};