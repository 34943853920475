import React, { useContext, useEffect, useState } from "react";
import MapPinIcon from "../../../Assets/img/map-pin.svg";
import AddPlusIcon from "../../../Assets/icon/AddPlusIcon";
import binIcon from "../../../Assets/img/bin.svg";
import Filtericon from "../../../Assets/icon/Filtericon";
import pencilIcon from "../../../Assets/img/pencil.svg";
import CustomeButton from "../../Common/CustomButton/CustomButton";
import Pagination from "../../Layouts/Pagination/Pagination";
import OnlyCk from "../../Common/OnlyCk/OnlyCk";
import SearchBox from "../../Common/SearchBox/SearchBox";
import AddFarmLocationPopup from "../../Popups/AddFarmLocationPopup/AddFarmLocationPopup";
import {
  destroyFarms,
  getAllFarmList,
  viewSingleFarm,
} from "../../../Redux/NonRedux/farm";
import { t } from "i18next";
import { API_STATUS, END_POINTS } from "../../../Constant/Api";
import { SUCCESS_RESPONSE_CODE } from "../../../Constant/Status";
import ConfirmPopup from "../../Popups/ConfirmPopup/ConfirmPopup";
import Loader from "../../Loader/Loader";
import { useLoading } from "../../../context/LoadingContext";
import { useDispatch, useSelector } from "react-redux";
import { countryListAction } from "../../../Redux/General/GeneralAction";
import NoDataFound from "../../Common/NoDataFound/NoDataFound";
import { SidebarContext } from "../../../context/SideBarContext";
import TableHead from "../../Common/DataTable/TableHead/TableHead";
import Filter from "../../Filter/Filter";
import { getDefaultSorting } from "../../../Utility/General";
import { DATATABLE } from "../../../Constant/General";
import { getListMetaDataApi } from "../../../Redux/NonRedux/general";

export default function LocationPage() {
  const defaultSorting: any = getDefaultSorting(DATATABLE.LOCATION);

  const tableHead = [
    {
      label: t("Name"),
      className: "tm",
      key: "name",
    },
    {
      label: t("Country"),
      className: "tm",
      key: "countries",
    },
    {
      label: t("Address"),
      className: "tm",
      key: "address",
    },
    {
      label: t("Description"),
      className: "tm",
      key: "description",
    },
    {
      label: t("Action"),
      className: "tm",
      key: "",
      headClass: "text-right",
    },
  ];

  const [currentPage, setCurrentPage] = React.useState(1);
  const [currentPageSize, setCurrentPageSize] = React.useState(10);

  const [apiStatus, setApiStatus] = useState(API_STATUS.SUCCESS);
  const [editData, setEditData] = useState<any>({});
  const [deletableRecords, setDeletableRecords] = useState<any>([]);
  const [confirmPopupShow, setConfirmPopupShow] = useState(false);
  const [isEdit, setIsEdit] = useState<boolean>(false);
  const [refreshList, setRefreshList] = useState(false);
  const { setLoading } = useLoading();
  const [farmlocationList, setFarmLocationList] = useState([]);
  const [recordsTotal, setRecordsTotal] = React.useState(1);
  const [searchText, setSearchText] = useState();
  const [AddFarmLocationModalShow, setAddFarmLocationModalShow] =
    React.useState(false);
  const dispatch = useDispatch();
  const [checkedRec, setCheckedRec] = useState<number[]>([]);
  const { setSidebar }: any = useContext(SidebarContext);
  const [sortByField, setSortByField] = useState(defaultSorting?.key);
  const [sortType, setSortType] = useState(defaultSorting?.type);
  const [orderList, setOrderList] = useState<any>([]);
  const [filterList, setFilterList] = useState<any>([]);
  const [filteredValue, setFilteredValue] = useState<any>({});
  const [metaData, setMetaData] = useState({});

  const listApiCall = () => {
    let params: any = {
      page: currentPage,
      limit: currentPageSize,
      search: searchText,
    };
    if (sortByField && sortType) {
      params["sort"] = {
        column: orderList[sortByField]
          ? orderList[sortByField]
          : defaultSorting?.value,
        type: sortType,
      };
    }
    if (filteredValue && Object.keys(filteredValue)?.length) {
      params["filter"] = filteredValue;
    }
    setLoading(true);
    getAllFarmList(params)
      .then((response: any) => {
        if (response.statusCode === SUCCESS_RESPONSE_CODE) {
          setFarmLocationList(response?.component?.listing);
          setRecordsTotal(response?.component?.recordsFiltered);
          setOrderList(response?.component?.orderlist);
          setFilterList(response?.component?.filterlist);
        }
        setLoading(false);
      })
      .catch((error) => {
        setLoading(false);
      });
  };

  const getMetaData = () => {
    getListMetaDataApi(END_POINTS.metaFarmLocation)
      .then((response: any) => {
        setMetaData(response?.component);
      })
      .catch((error: any) => {})
      .finally(() => {});
  };

  useEffect(() => {
    setSidebar("");
    let params = {};
    getMetaData();
    // dispatch(countryListAction(params));
  }, []);

  useEffect(() => {
    if (searchText != null) {
      const searchTimeOut = setTimeout(() => {
        if (currentPage === 1) {
          listApiCall();
        } else {
          setCurrentPage(1);
        }
      }, 500);
      return () => clearTimeout(searchTimeOut);
    }
  }, [searchText]);

  useEffect(() => {
    listApiCall();
  }, [currentPage, currentPageSize, sortByField, sortType, filteredValue]);

  useEffect(() => {
    if (refreshList) {
      listApiCall();
      setRefreshList(false);
    }
  }, [refreshList]);

  const editHandler = (id: number) => {
    let params = {
      id: id,
    };
    setApiStatus(API_STATUS.LOADING);
    setLoading(true);
    viewSingleFarm(params)
      .then((response: any) => {
        if (response?.statusCode === SUCCESS_RESPONSE_CODE) {
          setApiStatus(API_STATUS.SUCCESS);
          setLoading(false);
          setIsEdit(true);
          setEditData(response?.component);
          setAddFarmLocationModalShow(true);
        } else {
          setApiStatus(API_STATUS.FAILED);
          setLoading(false);
        }
      })
      .catch((error) => {
        setApiStatus(API_STATUS.FAILED);
        setLoading(false);
      });
  };

  const deleteHandler = (id: number) => {
    let params = {
      id: deletableRecords,
    };

    setApiStatus(API_STATUS.LOADING);
    setLoading(true);
    destroyFarms(params)
      .then((response: any) => {
        if (response.statusCode === SUCCESS_RESPONSE_CODE) {
          setApiStatus(API_STATUS.SUCCESS);
          setLoading(false);
          setRefreshList(true);
          setConfirmPopupShow(false);
        } else {
          setApiStatus(API_STATUS.FAILED);
          setLoading(false);
        }
      })
      .catch((error) => {
        setApiStatus(API_STATUS.FAILED);
        setLoading(false);
      });
  };

  const deleteNow = (id: number) => {
    let ids = deletableRecords;
    ids.push(id);
    setDeletableRecords(ids);
    setConfirmPopupShow(true);
  };

  return (
    <>
      <div className="location-page">
        {/* Page Title Start */}
        <div className="page-title-wrapper">
          <div className="title-first-menu">
            <div className="title-icon-text">
              <img src={MapPinIcon} alt="" />
              <h5>{t("Farm Locations")}</h5>
            </div>
          </div>
        </div>
        {/* Page Title End */}
        <div className="page-content">
          <div className="data-tbl-wrapper">
            <div className="data-tbl-header">
              <SearchBox
                value={searchText}
                placeholder={t("Search...")}
                onChange={(e: any) => setSearchText(e.target.value)}
              />
              <div className="btn-wrapper f-fb">
                {/* <CustomeButton>
                  <Filtericon />
                  <p> {t("Filter")} </p>
                </CustomeButton>
                 */}
                <Filter
                  filterList={filterList}
                  setFilteredValue={setFilteredValue}
                  setCurrentPage={setCurrentPage}
                />
                <CustomeButton
                  bg="fill"
                  onClick={() => {
                    setIsEdit(false);
                    setEditData({});
                    setAddFarmLocationModalShow(true);
                  }}
                >
                  <AddPlusIcon />
                  {t("Add")}
                </CustomeButton>
              </div>
            </div>
            <div className="data-tbl">
              <table>
                <TableHead
                  data={tableHead}
                  orderList={orderList}
                  sortByField={sortByField}
                  sortType={sortType}
                  setSortType={setSortType}
                  setSortByField={setSortByField}
                />
                <tbody>
                  {farmlocationList.map((items: any, index: number) => (
                    <tr key={index + "vaccination-data"}>
                      {/* <td className="ck-wrapper">
                        <OnlyCk
                          checked={checkedRec.includes(items?.id)}
                          onChange={(e: any) => checkSingleRec(e, items?.id)}
                        />
                      </td> */}
                      <td>
                        <p className="bl">{items?.location}</p>
                      </td>
                      <td>
                        <p className="bl">{(items?.country_names!="")? items?.country_names : items?.country_value}</p>
                      </td>
                      <td>
                        <p className="bl">{items?.address}</p>
                      </td>
                      <td>
                        <p className="bl">{items?.description}</p>
                      </td>
                      <td>
                        <div className="icon-wrapper">
                          <a>
                            <img
                              src={pencilIcon}
                              onClick={() => editHandler(items?.id)}
                              alt=""
                            />
                          </a>
                          <a>
                            <img
                              src={binIcon}
                              onClick={() => deleteNow(items?.id)}
                              alt=""
                            />
                          </a>
                        </div>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
            {farmlocationList?.length ? (
              <Pagination
                results={recordsTotal}
                currentPage={currentPage}
                setCurrentPage={setCurrentPage}
                currentLength={currentPageSize}
                setCurrentPageSize={setCurrentPageSize}
              />
            ) : (
              <NoDataFound />
            )}
          </div>
        </div>
      </div>

      <AddFarmLocationPopup
        show={AddFarmLocationModalShow}
        onHide={() => {
          setAddFarmLocationModalShow(false);
        }}
        isedit={isEdit ? 1 : 0}
        setrefreshlist={() => setRefreshList(true)}
        editdata={editData}
        metaData={metaData}
      />

      <ConfirmPopup
        heading={t("Delete")}
        message={t("Are you sure to delete this record?")}
        show={confirmPopupShow}
        onHide={() => {
          setConfirmPopupShow(false);
          setDeletableRecords([]);
        }}
        confirm={deleteHandler}
      />
    </>
  );
}
