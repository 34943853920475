import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import BreedingServiceMare from "./BreedingServiceMare";
import BreedingServiceStallion from "./BreedingServiceStallion";

export default function BreedingPage() {
  const [horseInformation, setHorseInformation] = useState<any>({});

  const horseInfo = useSelector(
    (state: any) => state?.horseInfoReducer?.data?.component
  );



  useEffect(() => {
    if(horseInfo){
      setHorseInformation(horseInfo);
    }
  }, [horseInfo]);

  return (
    <>
      {horseInformation?.sex_slug === "male" && <BreedingServiceStallion />}
      {horseInformation?.sex_slug === "female" && <BreedingServiceMare />}
    </>
  );
}
