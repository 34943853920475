import React from "react";
import pencilIcon from "../../../Assets/img/pencil.svg";
import unlockIcon from "../../../Assets/img/publicIcon.svg";
import lockIcon from "../../../Assets/img/lock.svg";
import binIcon from "../../../Assets/img/bin.svg";
import { PICTUREVIDEOTYPE } from "../../../Constant/General";
import { t } from "i18next";

export default function SingleVideo(props: any) {
  const { items } = props;
  return (
    <div className="v-row">
      <div className="v-col v-col-1">
        <iframe
          width="100%"
          src={items?.image_video}
          title="YouTube video player"
          frameBorder="0"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
          allowFullScreen
        ></iframe>
      </div>
      <div className="v-col v-col-2">
        <div className="pv-content">
          <div className="info-table">
            <table>
              <tbody>
                <tr>
                  <td>
                    <p className="tm">{t("Date")}</p>
                  </td>
                  <td>
                    <p className="tm blur-color">{items?.date}</p>
                  </td>
                </tr>
                <tr>
                  <td>
                    <p className="tm">{t("Title")}</p>
                  </td>
                  <td>
                    <p className="tm blur-color">{items?.title}</p>
                  </td>
                </tr>
                <tr>
                  <td>
                    <p className="tm">{t("Description")}</p>
                  </td>
                  <td>
                    <p className="tm blur-color">{items?.description}</p>
                  </td>
                </tr>
                <tr>
                  <td>
                    <p className="tm">{t("Video by")}</p>
                  </td>
                  <td>
                    <p className="tm blur-color">{items?.photographer}</p>
                  </td>
                </tr>
                <tr>
                  <td>
                    <p className="tm">{t("Private")}</p>
                  </td>
                  <td>
                    <p className="tm blur-color">{items?.private}</p>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
          <div className="icon-wrapper">
            <a>
              <img
                src={pencilIcon}
                onClick={() =>
                  props.editHandler(items?.id, PICTUREVIDEOTYPE.VIDEO)
                }
                alt=""
              />
            </a>
            <a>
              <img
                src={binIcon}
                onClick={() =>
                  props.deleteNow(items?.id, PICTUREVIDEOTYPE.VIDEO)
                }
                alt=""
              />
            </a>
            <a onClick={() => {
                props.changePrivacy(items?.id, PICTUREVIDEOTYPE.VIDEO , !parseInt(items?.private_key))
              }}>
              <img src={parseInt(items?.private_key)  ? unlockIcon : lockIcon}  alt=""  />
            </a>
          </div>
        </div>
      </div>
    </div>
  );
}
