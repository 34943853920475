import React from "react";

export default function Breedingicon() {
  return (
    <svg
      width="32"
      height="32"
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M17.7247 19.8907C19.8595 17.7559 20.4505 14.8855 19.0446 13.4797C17.6387 12.0738 14.7684 12.6647 12.6336 14.7996C10.4987 16.9344 9.90778 19.8048 11.3137 21.2106C12.7195 22.6165 15.5898 22.0256 17.7247 19.8907Z"
        stroke="#E8E5E2"
        strokeWidth="1.5"
        strokeMiterlimit="9.9999"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M11.2 21.3333L10 22.6667C8.8 23.8667 6.93334 23.8667 5.73334 22.6667"
        stroke="#E8E5E2"
        strokeWidth="1.5"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M6.80002 16C6.80002 10.6667 11.2 6.26666 16.5334 6.26666C21.8667 6.26666 26.4 10.6667 26.4 16C26.4 21.3333 22 25.7333 16.6667 25.7333"
        stroke="#E8E5E2"
        strokeWidth="1.5"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}
