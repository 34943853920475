import { globalPostAction } from "../../actions/globalActions";
import { END_POINTS } from "../../Constant/Api";
import { SHOW_MESSAGE } from "../../Constant/General";

export function getHealthVitalSigns(params: any) {
  let apiUrl = END_POINTS.healthVitalSign;
  return globalPostAction(params, apiUrl);
}

export function getHealthVaccination(params: any) {
  let apiUrl = END_POINTS.healthVaccination;
  return globalPostAction(params, apiUrl);
}

export function getHealthParasite(params: any) {
  let apiUrl = END_POINTS.healthParasite;
  return globalPostAction(params, apiUrl);
}

export function getHealthDental(params: any) {
  let apiUrl = END_POINTS.healthDental;
  return globalPostAction(params, apiUrl);
}

export function getHealthVetClinic(params: any) {
  let apiUrl = END_POINTS.healthVetClinic;
  return globalPostAction(params, apiUrl);
}

export function getHealthTreatments(params: any) {
  let apiUrl = END_POINTS.healthTreatments;
  return globalPostAction(params, apiUrl);
}

export function getHealthLabtest(params: any) {
  let apiUrl = END_POINTS.healthLabtest;
  return globalPostAction(params, apiUrl);
}

export function getHealthXray(params: any) {
  let apiUrl = END_POINTS.healthXray;
  return globalPostAction(params, apiUrl);
}
export function destroySingleHealthAction(params: any) {
  let apiUrl = END_POINTS.getSingleHealthDestroy;
  return globalPostAction(params, apiUrl , SHOW_MESSAGE);
}

export function viewSingleHealthAction(params: any) {
  let apiUrl = END_POINTS.getSingleHealthView;
  return globalPostAction(params, apiUrl);
}

export function getSingleHelthList(params: any) {
  let apiUrl = END_POINTS.getSingleHealthList;
  return globalPostAction(params, apiUrl);
}