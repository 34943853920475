import { DatePickerProps } from "antd";
import moment from "moment";
import React, { useEffect, useState } from "react";
import xCircleIcon from "../../../Assets/img/x-circle.svg";
import bankNoteIcon from "../../../Assets/img/banknote.svg";
import pdfFileIcon from "../../../Assets/img/pdf-file.svg";
import Datepicker from "../../Common/Datepicker/Datepicker";
import SelectGropus from "../../Common/SelectGroups/SelectGroups";
import RecordPaymentStatusPopup from "../../Popups/RecordPaymentStatusPopup/RecordPaymentStatusPopup";
import { useLoading } from "../../../context/LoadingContext";
import {
  getInstantContact,
  getInstantMeta,
} from "../../../Redux/globallActions";
import {
  deleteInvoice,
  downloadInvoice,
  getinvoiceList,
  invoiceHorseList,
} from "../../../Redux/NonRedux/invoice";
import NoDataFound from "../../Common/NoDataFound/NoDataFound";
import Pagination from "../../Layouts/Pagination/Pagination";
import { useSelector } from "react-redux";
import ConfirmPopup from "../../Popups/ConfirmPopup/ConfirmPopup";
import { t } from "i18next";
import TableHead from "../../Common/DataTable/TableHead/TableHead";
import { DATATABLE } from "../../../Constant/General";
import { getDefaultSorting } from "../../../Utility/General";
import { END_POINTS } from "../../../Constant/Api";
import { getListMetaDataApi } from "../../../Redux/NonRedux/general";
import useDidMountEffect from "../../../hooks/useDidMountEffect";

export default function InvoiceListPage() {
  const defaultSorting: any = getDefaultSorting(DATATABLE.INVOICE);
  const INTIAL_FILTER_DATA = {
    from: moment().subtract(30, "days").format("YYYY-MM-DD"),
    to: moment().format("YYYY-MM-DD"),
    owner_id: "",
    horse_id: "",
    status: "",
  };

  interface ContactList {
    first_name: string;
    id: number;
    last_name: string;
    name: number;
  }

  const tableHead = [
    {
      label: t("Date"),
      className: "tm",
      key: "date",
    },
    {
      label: t("Invoice Number"),
      className: "tm",
      key: "invoice_number",
    },
    {
      label: t("Owner"),
      className: "tm",
      key: "owner",
    },

    {
      label: t("Due Date"),
      className: "tm",
      key: "due_date",
    },
    {
      label: t("Status"),
      className: "tm",
      key: "status",
    },
    {
      label: t("Amount"),
      className: "tm ",
      key: "amount",
      headClass: "text-right",
    },
    {
      label: t("Action"),
      className: "tm",
      key: "",
      headClass: "text-right",
    },
  ];

  const [ownerList, setOwnerList] = useState<ContactList[]>([]);
  const [horseList, setHorseList] = useState<any>([]);
  const [invoiceList, setInvoiceList] = useState<any>([]);
  const [deletableRecords, setDeletableRecords] = useState<number | null>();
  const [confirmPopupShow, setConfirmPopupShow] = useState(false);
  const [RecordPaymentStatusModalShow, setRecordPaymentStatusModalShow] =
    useState(false);
  const { loading, setLoading } = useLoading();
  const [filter, setFilter] = useState<any>(INTIAL_FILTER_DATA);
  const [recordsTotal, setRecordsTotal] = useState(1);
  const [currentPage, setCurrentPage] = useState(1);
  const [currentPageSize, setCurrentPageSize] = useState(10);
  const [refreshList, setRefreshList] = useState(false);
  const [paymentStatusOb, setPaymentStatusOb] = useState({});

  const [sortByField, setSortByField] = useState(defaultSorting?.key);
  const [sortType, setSortType] = useState(defaultSorting?.type);

  const [orderList, setOrderList] = useState<any>([]);
  const [metaData, setMetaData] = useState<any>({});
  

  const prefrence = useSelector(
    (state: any) => state?.masterReducer?.data?.component?.preferance
  );

  const setData = (field: string, value: string) => {
    const tempObj: any = Object.assign({}, filter);
    tempObj[field] = value;
    setFilter({...tempObj});
  };

  const fromDateChange: DatePickerProps["onChange"] = (date, dateString) => {
    let dateIs: any = date?.format("YYYY-MM-DD");
    setData("from", dateIs);
  };

  const toDateChange: DatePickerProps["onChange"] = (date, dateString) => {
    let dateIs: any = date?.format("YYYY-MM-DD");
    setData("to", dateIs);
  };

  const getMetaData = () => {
    setLoading(true);
    getListMetaDataApi(END_POINTS.metaInvoices)
      .then((response: any) => {
        setMetaData(response?.component);
        setHorseList(response?.component?.horse_list);
        setOwnerList(response?.component?.owner_list);
      })
      .catch((error: any) => {})
      .finally(() => {
        setLoading(false);
      });
  };

  useEffect(() => {
    getMetaData();
  }, []);

  const listApiCall = async () => {
    let params: any = {
      page: currentPage,
      limit: currentPageSize,
      ...filter,
    };
    if (sortByField && sortType) {
      params["sort"] = {
        column: orderList[sortByField]
          ? orderList[sortByField]
          : defaultSorting?.value,
        type: sortType,
      };
    }
    try {
      setLoading(true);
      const data: any = await getinvoiceList(params);
      setInvoiceList(data.component?.listing);
      setRecordsTotal(data.component?.recordsFiltered);
      setOrderList(data?.component?.orderlist);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (refreshList) {
      listApiCall();
      setRefreshList(false);
    }
  }, [refreshList]);

  useEffect(() => {
    listApiCall();
  }, []);

  useDidMountEffect(() => {
    listApiCall();
  }, [currentPage, currentPageSize, sortByField, sortType]);

  useDidMountEffect(() => {
    if (currentPage != 1) {
      setCurrentPage(1);
    } else {
      listApiCall();
    }
  }, [filter]);

  // const getListData = async () => {
  //   setLoading(true);
  //   invoiceList(filter)
  //     .then((res: any) => {
  //       setInvoiceList(res?.component?.listing);
  //     })
  //     .finally(() => {
  //       setLoading(false);
  //     });
  // };

  const ownerChange = (val: string) => {
    invoiceHorseList({ owner_id: val })
      .then((res: any) => {
        setHorseList(res?.component);
      })
      .finally(() => {});
  };



  const deleteHandler = (id: number) => {
    let params = {
      id: deletableRecords,
    };
    setLoading(true);
    deleteInvoice(params)
      .then(() => {
        setDeletableRecords(null);
        setConfirmPopupShow(false);
        setRefreshList(true);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const deleteNow = (id: number) => {
    setDeletableRecords(id);
    setConfirmPopupShow(true);
  };

  const download = (id: any, fileName: any) => {
    const formData = new FormData();
    formData.append("id", id);
    downloadInvoice(formData, fileName);
  };

  return (
    <>
      <div className="invoice-list-page">
        <div className="date-and-input-filter">
          <Datepicker
            label={`${t("From")}`}
            endDate={moment().format("YYYY-MM-DD")}
            onChange={fromDateChange}
            defaultValue={moment()}
            value={moment(filter?.from)}
          />
          <Datepicker
            label={`${t("To")}`}
            startDate={moment(filter?.from).format("YYYY-MM-DD")}
            onChange={toDateChange}
            defaultValue={moment()}
            value={moment(filter?.to)}
          />
          <SelectGropus
            label={`${t("Owner")}`}
            dropKey="id"
            dropVal="name"
            value={ownerList}
            defaultSelect={filter?.owner_id}
            onChange={(e: any) => {
              setFilter({
                ...filter,
                owner_id:e.target.value,
                horse_id:"",
              })
              ownerChange(e.target.value);
            }}
          />
          <SelectGropus
            label={`${t("Horse")}`}
            dropKey="id"
            dropVal="name"
            value={horseList}
            defaultSelect={filter?.horse_id}
            onChange={(e: any) => {
              setData("horse_id", e.target.value);
            }}
          />
          <SelectGropus
            label={`${t("Status")}`}
            value={metaData?.paid_status}
            dropKey="key"
            dropVal="value"
            defaultSelect={filter?.status}
            onChange={(e: any) => setData("status", e.target.value)}
          />
        </div>

        <div className="data-tbl-wrapper">
          <div className="data-tbl ptb-12">
            <table>
              <TableHead
                data={tableHead}
                orderList={orderList}
                sortByField={sortByField}
                sortType={sortType}
                setSortType={setSortType}
                setSortByField={setSortByField}
              />
              <tbody>
                {invoiceList?.map((items: any, index: number) => (
                  <tr key={index + "vaccination-data"}>
                    <td>
                      <p className="bl">{items.invoice_date}</p>
                    </td>
                    <td>
                      <p className="bl">{items.invoice_number}</p>
                    </td>
                    <td>
                      <p className="bl">{items.owner}</p>
                    </td>
                    <td>
                      <p className="bl">{items.due_date}</p>
                    </td>
                    <td>
                      <label
                        className={`bl status-badge ${
                          items.payment_status ? "active" : "inactive"
                        }`}
                      >
                        {items.payment_status_value}
                      </label>
                    </td>
                    <td>
                      <p className="bl text-right">
                        {items.total} {prefrence?.currency_value}
                      </p>
                    </td>
                    <td>
                      <div className="icon-wrapper">
                        <a onClick={() => deleteNow(items?.id)}>
                          <img src={xCircleIcon} alt="" />
                        </a>
                        {!items.payment_status && (
                          <a
                            onClick={() => {
                              setPaymentStatusOb(items);
                              setRecordPaymentStatusModalShow(true);
                            }}
                          >
                            <img src={bankNoteIcon} alt="" />
                          </a>
                        )}

                        <a
                          onClick={() =>
                            download(items.id, items.invoice_number)
                          }
                        >
                          <img src={pdfFileIcon} alt="" />
                        </a>
                      </div>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
          {invoiceList?.length ? (
            <Pagination
              results={recordsTotal}
              currentPage={currentPage}
              setCurrentPage={setCurrentPage}
              currentLength={currentPageSize}
              setCurrentPageSize={setCurrentPageSize}
            />
          ) : (
            <NoDataFound />
          )}
        </div>
      </div>

      <RecordPaymentStatusPopup
        metaData={metaData}
        show={RecordPaymentStatusModalShow}
        onHide={() => setRecordPaymentStatusModalShow(false)}
        setRefreshList={() => {
          setRefreshList(true);
        }}
        paymentStataus={paymentStatusOb}
      />

      <ConfirmPopup
        heading={t("Delete")}
        message={t("Are you sure to delete this record?")}
        show={confirmPopupShow}
        onHide={() => {
          setConfirmPopupShow(false);
          setDeletableRecords(null);
        }}
        confirm={deleteHandler}
      />
    </>
  );
}
