import { t } from "i18next";
import React, { useEffect, useState } from "react";

const InputSelectWithLabel = (props: any) => {
  return (
    <div className="input-groups">
      <div className="input-select-with-title">
        <div className="input-wrapper">
          <label>{props.inputLabel}</label>
          <input
            type={props.inputType}
            inputMode={props.inputmode}
            defaultValue={props.inputDefaultValue}
            value={props.inputValue}
            placeholder={props.inputPlaceholder}
            readOnly={props.inputReadOnly}
            autoComplete={props.inputAutoComplete}
            name={props.inputName}
            id={props.inputId}
            className={props.inputError && "error"}
            onChange={props.onInputChange}
          />
          {props.inputError && <span className="error">{props?.inputError}</span>}
        </div>
        <div className="select-wrapper">
          <label>{props.selectLabel}</label>
          <select
            onChange={props.onSelectChange}
            name={props.selectName}
            id={props.selectId}
            value={props.selectDefaultSelect}
            className={props.selectError && "error"}
          >
            {props.selectOption ? (
              <option value="">{props.selectOption}</option>
            ) : (
              <option value="">{t("Select")}</option>
            )}
            {props.selectValue?.map((items: any, index: number) => (
              <option
                key={index}
                value={items[props.dropKey ? props.dropKey : "value"]}
              >
                {items[props.dropVal ? props.dropVal : "name"]}
              </option>
            ))}
          </select>
          {props.selectError && <span className="error">{props?.selectError}</span>}
        </div>
      </div>
    </div>
  );
};

export default InputSelectWithLabel;
