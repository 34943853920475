import React, { useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { Outlet, useLocation, useNavigate, useParams } from "react-router-dom";
import ExportIcon from "../../Assets/img/export.svg";
import PregnantIcon from "../../Assets/img/Pregnant.svg";
import MaleIcon from "../../Assets/img/Male.svg";
import FemaleIcon from "../../Assets/img/Female.svg";
import SellHorseIcon from "../../Assets/img/Sell-Horse.svg";
import Breeding from "../../Assets/img/Tab-icon/Breeding";
import Diet from "../../Assets/img/Tab-icon/Diet";
import Farrier from "../../Assets/img/Tab-icon/Farrier";
import Health from "../../Assets/img/Tab-icon/Health";
import HorseIcon from "../../Assets/img/Tab-icon/Horse";
import Medal from "../../Assets/img/Tab-icon/Medal";
import Notes from "../../Assets/img/Tab-icon/Notes";
import Paperclip from "../../Assets/img/Tab-icon/Paperclip";
import Pic from "../../Assets/img/Tab-icon/Pic";
import Rular from "../../Assets/img/Tab-icon/Rular";
import { PERMISSIONS, SIDEBAR } from "../../Constant/General";
import {
  HORSEDETAILS_ACHIEVEMENTS,
  HORSEDETAILS_BREEDING_SERVICE_DETAILS,
  HORSEDETAILS_BREEDING_SERVICE,
  HORSEDETAILS_DIET,
  HORSEDETAILS_DOCUMENTS,
  HORSEDETAILS_FARRIER,
  HORSEDETAILS_HEALTH,
  HORSEDETAILS_HEALTH_VITALSIGNS,
  HORSEDETAILS_HORSE,
  HORSEDETAILS_MEASUREMENTS,
  HORSEDETAILS_NOTES,
  HORSEDETAILS_PICTUREANDVIDEO,
} from "../../Constant/Route";
import { SidebarContext } from "../../context/SideBarContext";
import { checkRoutePermission, encrDecrStr } from "../../Utility/General";

import TabWrapper from "../Layouts/TabWrapper/TabWrapper";
import Loader from "../Loader/Loader";
import { horseInfoAction } from "../../Redux/Horse/HorseAction";
import {
  HORSEINFO_FAILD,
  HORSEINFO_LONG,
  HORSEINFO_REQUEST,
  HORSEINFO_SUCCESS,
} from "../../Redux/Horse/HorseType";
import { END_POINTS } from "../../Constant/Api";
import { exportDocument } from "../../Redux/globallActions";
import { useLoading } from "../../context/LoadingContext";
import { upgradePopupAction } from "../../Redux/General/GeneralAction";

export default function HorseDetails() {
  const { t, i18n } = useTranslation();
  const [parentTab, setParentTab] = React.useState(0);
  const [isloading, setIsLoading] = useState(false);
  const param = useParams();
  const [exportParams, setExportParams] = useState<any>({});
  const { setSidebar }: any = useContext(SidebarContext);
  const { setLoading } = useLoading();
  const [horseInformation, setHorseInformation] = useState<any>({});
  const parentTabHandleChange = (
    event: React.SyntheticEvent,
    newValue: number
  ) => {
    setParentTab(newValue);
  };
  const modules = useSelector(
    (state: any) => state?.masterReducer?.data?.component?.modules
  );
  const horseInfo = useSelector((state: any) => state?.horseInfoReducer);

  useEffect(() => {
    if (horseInfo.type === HORSEINFO_REQUEST) {
      setLoading(true);
    } else if (horseInfo.type === HORSEINFO_FAILD) {
      setLoading(false);
    } else if (horseInfo.type === HORSEINFO_SUCCESS) {
      setLoading(false);
      setHorseInformation(horseInfo);
    } else if (horseInfo.type === HORSEINFO_LONG) {
      setLoading(false);
    }
  }, [horseInfo]);

  const dispatch = useDispatch();

  useEffect(() => {
    let id = encrDecrStr(param?.slug, "decr");
    let params = {
      id: id,
    };
    horseInfoAction(params);
  }, [param?.slug]);

  useEffect(() => {
    setSidebar(SIDEBAR.MY_HORSE);
  }, []);

  const parentTabData: any = [
    {
      index: 0,
      img: <HorseIcon />,
      label: t("Horse"),
      path: HORSEDETAILS_HORSE + "/" + param?.slug,
      // export_url: END_POINTS?.horseDetailsExport,
    },
    {
      index: 1,
      img: <Breeding />,
      label: t("Breeding"),
      path: HORSEDETAILS_BREEDING_SERVICE + "/" + param?.slug,
      export_url:
        horseInformation?.data?.component.sex_slug === "female"
          ? END_POINTS?.breedingMareExport
          : END_POINTS?.breedingStallionExport,
    },
    {
      index: 2,
      img: <Health />,
      label: t("Health"),
      path: HORSEDETAILS_HEALTH_VITALSIGNS + "/" + param?.slug,
      export_url: END_POINTS?.helthExport,
    },
    {
      index: 3,
      img: <Diet />,
      label: t("Diet"),
      path: HORSEDETAILS_DIET + "/" + param?.slug,
      export_url: END_POINTS?.dietExport,
    },
    {
      index: 4,
      img: <Farrier />,
      label: t("Farrier"),
      path: HORSEDETAILS_FARRIER + "/" + param?.slug,
      export_url: END_POINTS?.farrierExport,
    },
    {
      index: 5,
      img: <Rular />,
      label: t("Measurements"),
      path: HORSEDETAILS_MEASUREMENTS + "/" + param?.slug,
      export_url: END_POINTS?.measurementExport,
    },
    {
      index: 6,
      img: <Medal />,
      label: t("Achievements"),
      path: HORSEDETAILS_ACHIEVEMENTS + "/" + param?.slug,
      export_url: END_POINTS?.achivementExport,
    },
    {
      index: 7,
      img: <Paperclip />,
      label: t("Documents"),
      path: HORSEDETAILS_DOCUMENTS + "/" + param?.slug,
    },

    {
      index: 8,
      img: <Pic />,
      label: t("Pictures and Videos"),
      path: HORSEDETAILS_PICTUREANDVIDEO + "/" + param?.slug,
    },
    {
      index: 9,
      img: <Notes />,
      label: t("Notes"),
      path: HORSEDETAILS_NOTES + "/" + param?.slug,
      export_url: END_POINTS?.notesExport,
    },
  ];

  let navigate = useNavigate();
  const horseRouteChange = (pathName: any) => {
    navigate(pathName);
  };

  const getIconbyType = (type: any) => {
    if (type === "male") {
      return MaleIcon;
    } else if (type === "female") {
      return FemaleIcon;
    } else if (type === "pregnant") {
      return PregnantIcon;
    }
  };

  const location: any = useLocation();
  useEffect(() => {
    if (location.pathname.includes(HORSEDETAILS_HORSE)) {
      setParentTab(0);
    } else if (location.pathname.includes(HORSEDETAILS_BREEDING_SERVICE)) {
      setParentTab(1);
    } else if (
      location.pathname.includes(HORSEDETAILS_BREEDING_SERVICE_DETAILS)
    ) {
      setParentTab(1);
    } else if (location.pathname.includes(HORSEDETAILS_HEALTH)) {
      setParentTab(2);
    } else if (location.pathname.includes(HORSEDETAILS_DIET)) {
      setParentTab(3);
    } else if (location.pathname.includes(HORSEDETAILS_FARRIER)) {
      setParentTab(4);
    } else if (location.pathname.includes(HORSEDETAILS_MEASUREMENTS)) {
      setParentTab(5);
    } else if (location.pathname.includes(HORSEDETAILS_ACHIEVEMENTS)) {
      setParentTab(6);
    } else if (location.pathname.includes(HORSEDETAILS_DOCUMENTS)) {
      setParentTab(7);
    } else if (location.pathname.includes(HORSEDETAILS_PICTUREANDVIDEO)) {
      setParentTab(8);
    } else if (location.pathname.includes(HORSEDETAILS_NOTES)) {
      setParentTab(9);
    }
    // location.pathname == DASHBOARD ? "active" : "";
    // setInnerTab(1);
  }, [location?.pathname]);

  const handleExport = () => {
    if (!checkRoutePermission(PERMISSIONS?.EXPORT_FEATURE, modules)) {
      upgradePopupAction({ show: true });
      return;
    }
    setLoading(true);
    let form_type = exportParams?.form_type ? exportParams?.form_type : null;
    exportDocument(
      parentTabData[parentTab]?.export_url,
      exportParams,
      form_type ? form_type : parentTabData[parentTab]?.label?.replace(" ", "")
    )
      .then(() => {})
      .finally(() => {
        setLoading(false);
      });
  };

  return (
    <>
      <div className="horse-details">
        {/* Page Title Start */}
        <div className="page-title-wrapper">
          <div className="title-first-menu">
            <div className="title-icon-text">
              {/* {horseDetails?.horseInformation?.name} */}
              {horseInformation?.data?.component?.type ? (
                <img
                  src={getIconbyType(horseInformation?.data?.component?.type)}
                  alt=""
                />
              ) : null}

              <h5>{horseInformation?.data?.component?.name}</h5>
            </div>
            <div className="title-right-icons">
              {/* <a className="unset-links">
                <img src={SellHorseIcon} alt="" />
              </a> */}
              {parentTabData[parentTab]?.export_url && (
                <a className="unset-links" onClick={handleExport}>
                  <img src={ExportIcon} alt="" />
                </a>
              )}
            </div>
          </div>
          <TabWrapper
            value={parentTab}
            horseRouteChange={horseRouteChange}
            handleChange={parentTabHandleChange}
            parentTabData={parentTabData}
          />
        </div>
        {/* Page Title End */}

        <div className="page-content">
          <Outlet context={{ setExportParams }} />
        </div>
      </div>
    </>
  );
}
