import React from "react";
import { TimePicker } from "antd";
import type { Moment } from "moment";
import { t } from "i18next";

export default function Timepicker(props: any) {
  return (
    <div className="input-groups time-picker">
      <label>{props.label}</label>
      <TimePicker
        placeholder={t("Select time")}
        allowClear={props?.allowClear}
        inputReadOnly
        use12Hours={props?.hour24 ? false : true}
        format={props.format ? props.format : "hh:mm A"}
        onChange={props.onChange}
        defaultValue={props.defaultValue}
        value={props.value}
      />
      {props.error && <span className="error">{props.error}</span>}
    </div>
  );
}
