import React from "react";

export default function Health() {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M4.4742 11.0149C4.26801 10.3963 4.16492 9.77777 4.16492 9.15921C4.16492 7.92209 4.68038 6.78807 5.50513 5.86023C6.32987 4.93239 7.56699 4.52002 8.8041 4.52002C10.0412 4.52002 11.1752 5.03548 12.1031 5.86023L12.4124 6.16951L12.7216 5.86023C13.5464 5.03548 14.7835 4.52002 16.0206 4.52002C17.2577 4.52002 18.3918 5.03548 19.3196 5.86023C20.1443 6.68497 20.6598 7.92209 20.6598 9.15921C20.6598 9.67467 20.5567 10.1901 20.3505 10.7056"
        stroke="#919EAB"
        strokeWidth="1.5"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M17.0516 14.8293L13.0309 19.1592C12.8248 19.3654 12.6186 19.4685 12.4124 19.4685C12.2062 19.4685 12 19.3654 11.7938 19.1592L7.77319 14.9324"
        stroke="#919EAB"
        strokeWidth="1.5"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M2 12.8706H4.8866C6.53609 12.8706 7.9794 12.0458 8.90723 10.8087L9.62889 9.77777L11.4846 12.8706"
        stroke="#919EAB"
        strokeWidth="1.5"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M22 12.8706H18.5979C16.9484 12.8706 15.2989 11.9428 14.4742 10.4995L14.0618 9.88092"
        stroke="#919EAB"
        strokeWidth="1.5"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}
