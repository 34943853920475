import React from "react";

export default function BookMobileIcon() {
  return (
    <svg
      width="32"
      height="32"
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M7.33337 27.0667H27.6V4.93333H7.33337V27.0667Z"
        stroke="#E8E5E2"
        strokeWidth="1.5"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M8.93336 10.6667H4.40002"
        stroke="#E8E5E2"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M8.93336 16H4.40002"
        stroke="#E8E5E2"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M8.93336 21.3333H4.40002"
        stroke="#E8E5E2"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M11.6 4.93333V27.0667"
        stroke="#E8E5E2"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M19.6 15.3333C20.8519 15.3333 21.8667 14.3185 21.8667 13.0667C21.8667 11.8148 20.8519 10.8 19.6 10.8C18.3482 10.8 17.3334 11.8148 17.3334 13.0667C17.3334 14.3185 18.3482 15.3333 19.6 15.3333Z"
        stroke="#E8E5E2"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M15.6 21.2C15.6 18.9333 17.3334 17.2 19.6 17.2C21.8667 17.2 23.6 18.9333 23.6 21.2"
        stroke="#E8E5E2"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}
