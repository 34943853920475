import type { DatePickerProps } from "antd";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { Col, Row } from "react-bootstrap";
import Modal from "react-bootstrap/Modal";
import { useDispatch, useSelector } from "react-redux";
import CustomeButton from "../../Common/CustomButton/CustomButton";
import Datepicker from "../../Common/Datepicker/Datepicker";
import InputGroups from "../../Common/InputGroups/InputGroups";
import InputSelectGroup from "../../Common/InputSelectGroup/InputSelectGroup";
import InputSelectMix from "../../Common/InputSelectMix/InputSelectMix";
import SelectGropus from "../../Common/SelectGroups/SelectGroups";
import TextButton from "../../Common/TextButton/TextButton";
import { horseOwnerContactAction } from "../../../Redux/General/GeneralAction";
import { API_STATUS } from "../../../Constant/Api";
import validate from "../../../Utility/Validation";
import { addEditBoarding } from "../../../Redux/NonRedux/boarding";
import { SUCCESS_RESPONSE_CODE } from "../../../Constant/Status";
import { t } from "i18next";
import { useLoading } from "../../../context/LoadingContext";
import TextareaGroups from "../../Common/TextareaGroups/TextareaGroups";
export default function AddBoardingHorsePopup(props: any) {
  const dispatch = useDispatch();

  const INTIAL_BOARDING_DATA = {
    id: {
      value: "",
    },
    name: {
      value: "",
      validation: ["required", "letters"],
      errors: [t("Horse is required"), t("Enter valid horse name.")],
    },
    micro_chip_number: {
      value: "",
      validation: [""],
      errors: [t("Microchip Number is required.")],
    },
    date_entry: {
      value: moment().format("YYYY-MM-DD"),
      validation: ["required"],
      errors: [t("Date of entry is required.")],
    },
    end_entry: {
      value: "",
      validation: [""],
      errors: [t("Date of Exit is required.")],
    },
    boarding_type: {
      value: "",
      validation: ["required"],
      errors: [t("Boarding Type is required.")],
    },
    amount: {
      value: "",
      validation: ["number"],
      errors: [t("Please enter valid formate.")],
    },

    select_owner: {
      value: "owner_id",
      errors: [t("")],
    },
    owner_id: {
      value: "",
      validation: [],
      errors: [t("Owner is required.")],
    },
    owner: {
      value: "",
      validation: ["letters"],
      errors: [t("Incorrect format.")],
    },
    contact_number: {
      value: "",
      validation: [""],
      errors: [t("Contact number is required.")],
    },
    comments: {
      value: "",
      validation: [""],
      errors: [t("Comments is required.")],
    },
  };

  const [ownerToggle, setOwnerToggle] = useState("select");
  const [boardingData, setBoardingData] = useState<any>(INTIAL_BOARDING_DATA);
  const [boardingError, setBoardingError] = useState<any>({});
  const [isFormValidated, setIsFormValidated] = useState(false);
  const [apiStatus, setApiStatus] = useState(API_STATUS.SUCCESS);
  const { setLoading } = useLoading();


  const prefrence = useSelector(
    (state: any) => state?.masterReducer?.data?.component?.preferance
  );



  const setData = (field: string, value: any) => {
    const boardingOb = Object.assign({}, boardingData);
    boardingOb[field].value = value;
    setBoardingData({ ...boardingOb });
  };

  const dateChange: DatePickerProps["onChange"] = (date: any, dateString) => {
    let dateIs = date?.format("YYYY-MM-DD");
    let exitDate = moment(boardingData?.end_entry?.value);
    if (exitDate < date) {
      setData("end_entry", dateIs);
    }
    setData("date_entry", dateIs);
  };

  const exitDateChange: DatePickerProps["onChange"] = (date, dateString) => {
    let dateIs = date ? date?.format("YYYY-MM-DD") : null;
    setData("end_entry", dateIs);
  };

  const submitHandler = () => {
    if (validate(boardingData).isValidated === true) {
      setLoading(true);
      let params: any = {};
      Object.keys(boardingData).forEach((element) => {
        if (boardingData[element].value) {
          params[element] = boardingData[element].value;
        }
      });

      addEditBoarding(params)
        .then((response: any) => {
          if (response.statusCode === SUCCESS_RESPONSE_CODE) {
            props.onHide();
            props.setrefreshlist();
          }
          setLoading(false);
        })
        .catch((error: any) => {
          setLoading(false);
        });
    } else {
      setLoading(false);
      setBoardingError({ ...validate(boardingData)?.errors });
      setIsFormValidated(true);
    }
  };

  useEffect(() => {
    setBoardingError({ ...validate(boardingData).errors });
  }, [boardingData]);

  const boardingTypeChange = (value: string) => {
    if (value !== "") {
      let boardingOb = boardingplanlist.find((element: any) => {
        return element.id == value;
      });
      setData("amount", boardingOb?.amount);
    }
  };

  useEffect(() => {
    if (props?.show === false) {
      setBoardingError({});
      setBoardingData(INTIAL_BOARDING_DATA);
      setIsFormValidated(false);
    }
  }, [props?.show]);

  useEffect(() => {
    if (props.isedit) {
      if (props?.editdata) {
        Object.keys(boardingData).forEach((element) => {
          if (props?.editdata?.[element]) {
            setData(element, props?.editdata?.[element]);
          }
        });
      }
    } else {
      setBoardingData(INTIAL_BOARDING_DATA);
    }
  }, [props?.editdata]);

  useEffect(() => {
    if (props?.show === false) {
      setBoardingData(INTIAL_BOARDING_DATA);
      setIsFormValidated(false);
      setBoardingError({});
      setOwnerToggle("select");
    }
  }, [props?.show]);

  const { setrefreshlist,metaData, boardingplanlist,  ...rest } = props;
  return (
    <Modal {...rest} centered className="add-boarding-horse-popup modal-784">
      <Modal.Header closeButton>
        <p className="tl">
          {props.isedit ? t("Update Boarding Horse") : t("Add Boarding Horse")}{" "}
        </p>
      </Modal.Header>
      <Modal.Body>
        <Row>
          <Col sm={6} md={6}>
            <InputGroups
              label={`${t("Horse")}*`}
              type="text"
              autoComplete="off"
              value={boardingData?.name?.value}
              onChange={(e: any) => setData("name", e.target.value)}
              error={
                isFormValidated && boardingError["name"]
                  ? boardingError["name"]
                  : null
              }
            />
          </Col>
          <Col sm={6} md={6}>
            <InputGroups
              label={`${t("Microchip Number")}`}
              type="text"
              autoComplete="off"
              value={boardingData?.micro_chip_number?.value}
              onChange={(e: any) =>
                setData("micro_chip_number", e.target.value)
              }
              error={
                isFormValidated && boardingError["micro_chip_number"]
                  ? boardingError["micro_chip_number"]
                  : null
              }
            />
          </Col>
          <Col sm={6} md={6}>
            <Datepicker
              label={`${t("Date of entry")}*`}
              startDate={moment().startOf("month").format("YYYY-MM-DD")}
              
              onChange={dateChange}
              value={moment(boardingData?.date_entry?.value)}
              error={
                isFormValidated && boardingError["date_entry"]
                  ? boardingError["date_entry"]
                  : null
              }
            />
          </Col>
          <Col sm={6} md={6}>
            <Datepicker
              label={`${t("Date of Exit")}`}
              startDate={
                boardingData?.date_entry?.value
                  ? moment(boardingData?.date_entry?.value)
                  : null
              }
              allowClear={true}
              onChange={exitDateChange}
              value={
                boardingData.end_entry?.value
                  ? moment(boardingData?.end_entry?.value)
                  : undefined
              }
              error={
                isFormValidated && boardingError["end_entry"]
                  ? boardingError["end_entry"]
                  : null
              }
            />
          </Col>
        </Row>
        <Row>
          <Col sm={6} md={6}>
            <SelectGropus
              label={`${t("Boarding type")}*`}
              value={boardingplanlist}
              onChange={(e: any) => {
                boardingTypeChange(e.target.value);
                setData("boarding_type", e.target.value);
              }}
              dropKey="id"
              dropVal="type"
              defaultSelect={boardingData?.boarding_type?.value}
              error={
                isFormValidated && boardingError["boarding_type"]
                  ? boardingError["boarding_type"]
                  : null
              }
            />
          </Col>
          <Col sm={6} md={6}>
            <InputSelectMix
              label={`${t("Amount Per Month")}`}
              inputType="number"
              onInputChange={(e: any) => setData("amount", e.target.value)}
              inputValue={boardingData?.amount?.value}
              inputError={
                isFormValidated && boardingError["amount"]
                  ? boardingError["amount"]
                  : null
              }
              inputSecondValue={prefrence?.currency_value}
            />
          </Col>
          <Col sm={6} md={6}>
            <InputSelectGroup
              label={`${t("Owner")}`}
              selectValue={metaData?.owner}
              inputSelectToggle={ownerToggle}
              inputValue={boardingData?.owner?.value}
              setInputSelectToggle={setOwnerToggle}
              onSelectChange={(value: any) => {
                setData("owner_id", value);
                setData("owner", "");
                setData("select_owner", "owner_id");
              }}
              defaultSelect={boardingData?.owner_id?.value}
              dropKey="id"
              dropVal="first_name"
              onInputChange={(e: any) => {
                setData("owner", e.target.value);
                setData("owner_id", "");
                setData("select_owner", "owner");
              }}
              error={
                isFormValidated &&
                (boardingError["owner"] || boardingError["owner_id"])
                  ? boardingError["owner"] || boardingError["owner_id"]
                  : null
              }
            />
          </Col>
          <Col sm={6} md={6}>
            <InputGroups
              label={`${t("Contact Number")}`}
              type="text"
              autoComplete="off"
              value={boardingData?.contact_number?.value}
              onChange={(e: any) => setData("contact_number", e.target.value)}
              error={
                isFormValidated && boardingError["contact_number"]
                  ? boardingError["contact_number"]
                  : null
              }
            />
          </Col>
          <Col sm={12} md={12}>
            <TextareaGroups
              label={`${t("Comments")}`}
              type="text"
              autoComplete="off"
              value={boardingData?.comments?.value}
              onChange={(e: any) => setData("comments", e.target.value)}
              error={
                isFormValidated && boardingError["comments"]
                  ? boardingError["comments"]
                  : null
              }
            />
          </Col>
        </Row>
      </Modal.Body>
      <Modal.Footer>
        <div className="footer-2-btn">
          <TextButton onClick={props?.onHide}> {t("Cancel")}</TextButton>
          <CustomeButton bg="fill" onClick={submitHandler}>
            {" "}
            {props.isedit ? t("Update") : t("Add")}
          </CustomeButton>
        </div>
      </Modal.Footer>
    </Modal>
  );
}
