import type { DatePickerProps } from "antd";
import moment from "moment";
import React, { useState, useEffect } from "react";
import { Col, Row } from "react-bootstrap";
import Modal from "react-bootstrap/Modal";
import { addEditAchievementAction } from "../../../actions/Horse/MyHorseActions";
import { API_STATUS } from "../../../Constant/Api";
import CustomeButton from "../../Common/CustomButton/CustomButton";
import Datepicker from "../../Common/Datepicker/Datepicker";
import FileUpload from "../../Common/FileUpload/FileUpload";
import InputGroups from "../../Common/InputGroups/InputGroups";
import InputSelectGroup from "../../Common/InputSelectGroup/InputSelectGroup";
import InputSelectMix from "../../Common/InputSelectMix/InputSelectMix";
import SelectGropus from "../../Common/SelectGroups/SelectGroups";
import TextButton from "../../Common/TextButton/TextButton";
import { useDispatch, useSelector } from "react-redux";
import validate from "../../../Utility/Validation";
import { SUCCESS_RESPONSE_CODE } from "../../../Constant/Status";
import {
  getFileNamefromLink,
  isFileValidated,
  getFileNamefromPath,
  getSlugNamefromId,
} from "../../../Utility/General";
import Timepicker from "../../Common/Timepicker/Timepicker";
import { useLoading } from "../../../context/LoadingContext";
import { t } from "i18next";
import { FORMLABEL } from "../../../Constant/General";

export default function AddAchievementPopup(props: any) {
  const INTIAL_ACHIEVEMENTS_DATA: any = {
    form_type: {
      value: "achivements",
      validation: ["required"],
      errors: [t("Form Type is required.")],
    },
    id: {
      value: "",
    },
    dates: {
      value: moment().format("YYYY-MM-DD"),
      validation: ["required"],
      errors: [t("Date is required.")],
    },
    discipline: {
      value: "",
      validation: ["required"],
      errors: [t("Discipline is Required.")],
    },
    event_name: {
      value: "",
      validation: ["required"],
      errors: [t("Event Name is required.")],
    },
    score: {
      value: "",
      validation: [""],
      errors: [t("Score is required.")],
    },
    championship_medal: {
      value: "",
      validation: [""],
      errors: [t("Championship Medal is required.")],
    },
    country: {
      value: "",
      validation: [""],
      errors: [t("Country is required.")],
    },
    ranking: {
      value: "",
      validation: [""],
      errors: [t("Ranking is required.")],
    },
    time: {
      value: "",
      validation: [""],
      errors: [t("time  is required.")],
    },
    prize_money: {
      value: "",
      validation: [""],
      errors: [t("Prize Money  is required.")],
    },
    document_file: {
      value: "",
    },
  };

  const ARABIAN_HORSE_SHOW = "arabian_horse_show";

  const setData = (field: string, value: any) => {
    const achivement = Object.assign({}, achievementData);
    achivement[field].value = value;
    setAchievementData({ ...achivement });
  };

  const dateChange: DatePickerProps["onChange"] = (date, dateString) => {
    let dateIs = date?.format("YYYY-MM-DD");
    setData("dates", dateIs);
  };

  const RankingData = [
    {
      id: 1,
      value: 1,
    },
    {
      id: 2,
      value: 2,
    },
    {
      id: 3,
      value: 3,
    },
    {
      id: 4,
      value: 4,
    },
    {
      id: 5,
      value: 5,
    },
  ];
  const dispatch = useDispatch();
  const [fileData, setFileData] = useState("");
  const [fileDataName, setFileDataName] = useState(t("Upload Documents"));
  const [achievementData, setAchievementData] = useState(
    INTIAL_ACHIEVEMENTS_DATA
  );
  const [isFormValidated, setIsFormValidated] = useState(false);
  const [achievementError, setAchievementError] = useState<any>({});
  const [apiStatus, setApiStatus] = useState(API_STATUS.SUCCESS);
  const [medals, setMedals] = useState([]);
  const [removeDoc, setRemoveDoc] = useState<any>(0);
  const [fileDataSelected, setFileDataSelected] = useState("");
  const { setLoading } = useLoading();
  const allowedFormate = ["jpg", "png", "pdf", "docx" , "heic" , "heif" , "jpeg"];
  const allowedFileSizeInMb = 3;

  const { achievementdata, setrefreshlist,metaData, isedit, horseid, ...rest } = props;

  const prefrence = useSelector(
    (state: any) => state?.masterReducer?.data?.component?.preferance
  );



  const checkExistFile = () => {
    if (props.isedit) {
      setRemoveDoc(1);
    }
    setFileData("");
    setFileDataSelected("");
  };

  const setRequired = (field: string) => {
    const achivement: any = Object.assign({}, achievementData);
    achivement[field].validation[0] = "required";
    setAchievementData({ ...achivement });
  };
  const setRemoveRequired = (field: string) => {
    const achivement: any = Object.assign({}, achievementData);
    achivement[field].validation[0] = "";
    setAchievementData({ ...achivement });
  };



  useEffect(() => {
    if (props.isedit) {
      if (props?.achievementdata) {
        Object.keys(achievementData).forEach((element) => {
          setData(element, props?.achievementdata?.[element]);
        });
        setData(
          "time",
          props?.achievementdata?.time
            ? moment(props?.achievementdata?.time, "HH:mm:ss").format(
                "YYYY-MM-DD HH:mm:ss"
              )
            : ""
        );
        if (props?.achievementdata?.document) {
          let document = props?.achievementdata?.document;
          document = getFileNamefromLink(document);
          setFileDataSelected(document);
        }
      }
    } else {
      setRemoveDoc(0);
      setFileDataSelected("");
      setFileData("");
      setAchievementData(INTIAL_ACHIEVEMENTS_DATA);
    }
  }, [props?.achievementdata]);

  useEffect(() => {
    if (props?.medals && props?.medals.length > 0) {
      setMedals(props?.medals);
    }
  }, [props?.medals]);

  useEffect(() => {
    if (isFormValidated) {
      setAchievementError({ ...validate(achievementData).errors });
    }
  }, [achievementData]);

  // useEffect(() => {
  //   if (achievementData?.discipline?.value === ARABIAN_HORSE_SHOW) {
  //     setRequired("championship_medal");
  //   } else {
  //     setRemoveRequired("championship_medal");
  //     setData("championship_medal", "");
  //     setData("score", "");
  //   }
  // }, [achievementData?.discipline?.value]);

  const fileChange = (e: any) => {
    let file = e.target.files[0];
    if (isFileValidated(file, allowedFormate, allowedFileSizeInMb)) {
      let fileName = getFileNamefromPath(e.target.value);
      setFileDataSelected(fileName);
      setFileData(e.target.files[0]);
      if (props.isedit) {
        setRemoveDoc(1);
      }
    } else {
      e.target.value = "";
    }
  };

  useEffect(() => {
    if (props?.show === false) {
      setAchievementError({});
      setAchievementData(INTIAL_ACHIEVEMENTS_DATA);
      setIsFormValidated(false);
      setRemoveDoc(0);
      setFileDataSelected("");
      setFileData("");
    }
  }, [props?.show]);

  const submitHandler = () => {
    if (validate(achievementData).isValidated === true) {
      const horseId = props?.horseid;
      const formData: any = new FormData();
      Object.keys(achievementData).forEach((element) => {
        formData.append(element, achievementData[element].value);
      });

      if (!props?.isedit) {
        formData.delete("id");
      }
      formData.append("remove_doc", removeDoc);
      formData.set("form_type", "achivements");
      formData.append("horse_id", horseId);
      if (fileData && fileData !== "") {
        formData.append("document", fileData);
      }
      setLoading(true);
      setApiStatus(API_STATUS.LOADING);
      addEditAchievementAction(formData)
        .then((res: any) => {
          props.onHide();
          props.setrefreshlist();
        })
        .finally(() => {
          setLoading(false);
        });
    } else {
      setIsFormValidated(true);
      setAchievementError({ ...validate(achievementData).errors });
    }
  };

  const diciplineChange = (val:any) => {
   const slug =   getSlugNamefromId(metaData?.discipline , val);
   if(slug === ARABIAN_HORSE_SHOW){
    setData("time", "");
   }
   else{
    setData("championship_medal", "");
    setData("score", "");
   }
  }

  

  return (
    <Modal {...rest} centered className="add-achievement-popup modal-784">
      <Modal.Header closeButton>
        <p className="tl">
          {props.isedit ? t("Update Achievement") : t("Add Achievement")}{" "}
        </p>
      </Modal.Header>
      <Modal.Body>
        <Row>
          <Col sm={6} md={6}>
            <Datepicker
              label={`${t("Date")}*`}
              endDate={moment().format("YYYY-MM-DD")}
              onChange={dateChange}
              value={moment(achievementData?.dates?.value)}
              error={
                isFormValidated && achievementError["dates"]
                  ? achievementError["dates"]
                  : null
              }
            />
          </Col>
          <Col sm={6} md={6}>
            <SelectGropus
              label={`${t("Discipline")}*`}
              value={metaData?.discipline}
              onChange={(e: any) => {
                setData("discipline", e.target.value);
                diciplineChange(e.target.value);
              }}
              dropKey="id"
              dropVal="metadata"
              defaultSelect={achievementData?.discipline?.value}
              error={
                isFormValidated && achievementError["discipline"]
                  ? achievementError["discipline"]
                  : null
              }
            />
          </Col>

          <Col sm={6} md={6}>
            <InputGroups
              label={`${t("Event Name")}*`}
              type="text"
              autoComplete="off"
              value={achievementData?.event_name?.value}
              onChange={(e: any) => setData("event_name", e.target.value)}
              error={
                isFormValidated && achievementError["event_name"]
                  ? achievementError["event_name"]
                  : null
              }
            />
          </Col>
          <Col sm={6} md={6}>
            <SelectGropus
              label={`${t("Country")}`}
              value={metaData?.country}
              onChange={(e: any) => setData("country", e.target.value)}
              dropKey="id"
              dropVal="country"
              defaultSelect={achievementData?.country?.value}
              error={
                isFormValidated && achievementError["country"]
                  ? achievementError["country"]
                  : null
              }
            />
          </Col>
          <Col sm={6} md={6}>
            <SelectGropus
              label={`${t("Ranking")}`}
              value={RankingData}
              onChange={(e: any) => setData("ranking", e.target.value)}
              dropKey="id"
              dropVal="value"
              defaultSelect={achievementData?.ranking?.value}
              error={
                isFormValidated && achievementError["ranking"]
                  ? achievementError["ranking"]
                  : null
              }
            />
          </Col>
          {getSlugNamefromId(metaData?.discipline , achievementData?.discipline?.value) ===  ARABIAN_HORSE_SHOW ? (
            <Col sm={6} md={6}>
              <InputGroups
                label={`${t("Score")}`}
                type="number"
                value={achievementData?.score?.value}
                onChange={(e: any) => setData("score", e.target.value)}
                error={
                  isFormValidated && achievementError["score"]
                    ? achievementError["score"]
                    : null
                }
              />
            </Col>
          ) : (
            <Col sm={6} md={6}>
              <Timepicker
                allowClear={true}
                label={`${t("Time")}`}
                hour24={true}
                onChange={(time: any) => {
                  let formated_time = time
                    ? time.format("YYYY-MM-DD HH:mm:ss")
                    : null;
                  setData("time", formated_time);
                }}
                format="HH:mm:ss"
                value={
                  achievementData?.time?.value
                    ? moment(achievementData?.time?.value)
                    : undefined
                }
                error={
                  isFormValidated && achievementError["time"]
                    ? achievementError["time"]
                    : null
                }
              />
            </Col>
          )}
        </Row>
        {getSlugNamefromId(metaData?.discipline , achievementData?.discipline?.value) ===  ARABIAN_HORSE_SHOW && (
          <Row>
            <Col sm={6} md={6}>
              <SelectGropus
                label={`${t("Championship Medal")}`}
                value={metaData?.championshipMedal}
                onChange={(e: any) =>
                  setData("championship_medal", e.target.value)
                }
                dropKey="key"
                dropVal="value"
                defaultSelect={achievementData?.championship_medal?.value}
                error={
                  isFormValidated && achievementError["championship_medal"]
                    ? achievementError["championship_medal"]
                    : null
                }
              />
            </Col>
          </Row>
        )}
        <Row>
          <Col sm={6} md={6}>
            <InputSelectMix
              label={`${t("Prize Money")}`}
              inputType="number"
              onInputChange={(e: any) => setData("prize_money", e.target.value)}
              inputValue={achievementData?.prize_money?.value}
              inputError={
                isFormValidated && achievementError["prize_money"]
                  ? achievementError["prize_money"]
                  : null
              }
              inputSecondValue={prefrence?.currency_value}
            />
          </Col>
          <Col sm={12} md={12}>
            <FileUpload
              onChange={(e: any) => {
                fileChange(e);
              }}
              removeFile={() => checkExistFile()}
              value={fileDataName}
              selectedValue={fileDataSelected}
              fileType={t(FORMLABEL.USE_FILE_TYPE)}
            />
          </Col>
        </Row>
      </Modal.Body>
      <Modal.Footer>
        <div className="footer-2-btn">
          <TextButton onClick={props?.onHide}> {t("Cancel")}</TextButton>
          <CustomeButton bg="fill" onClick={submitHandler}>
            {props.isedit ? t("Update") : t("Add")}
          </CustomeButton>
        </div>
      </Modal.Footer>
    </Modal>
  );
}
