import { useContext, useEffect, useState } from "react";
import { Col, Row } from "react-bootstrap";
import Dropdown from "react-bootstrap/Dropdown";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import AddPlusIcon from "../../Assets/icon/AddPlusIcon";
import Filtericon from "../../Assets/icon/Filtericon";
import binIcon from "../../Assets/img/bin.svg";
import OffspringsIcon from "../../Assets/img/Common-icon/Offsprings";
import ExportIcon from "../../Assets/img/export.svg";
import FemaleIcon from "../../Assets/img/Female.svg";
import HorseImg from "../../Assets/img/Horses-title.svg";
import MaleIcon from "../../Assets/img/Male.svg";
import PedigreeIcon from "../../Assets/img/Pedigree.svg";
import pencilIcon from "../../Assets/img/pencil.svg";
import PregnantIcon from "../../Assets/img/Pregnant.svg";
import { API_STATUS, END_POINTS } from "../../Constant/Api";
import { HORSEDETAILS_HORSE } from "../../Constant/Route";
import { SUCCESS_RESPONSE_CODE } from "../../Constant/Status";
import { horseListAction } from "../../Redux/Horse/HorseAction";
import {
  checkRoutePermission,
  encrDecrStr,
  getDefaultSorting,
  getIdfromSlug,
  getSlugNamefromId,
} from "../../Utility/General";
import AllSelectLabel from "../Common/AllSelectLabel/AllSelectLabel";
import CustomeButton from "../Common/CustomButton/CustomButton";
import OnlyCk from "../Common/OnlyCk/OnlyCk";
import SearchBox from "../Common/SearchBox/SearchBox";
import SquareButton from "../Common/SquareButton/SquareButton";
import Numbernamecard from "../Layouts/Numbernamecard/Numbernamecard";
import Pagination from "../Layouts/Pagination/Pagination";
import AddEditHorsePopup from "../Popups/AddEditHorsePopup/AddEditHorsePopup";
import ExportHorsePopup from "../Popups/ExportHorsePopup/ExportHorsePopup";
import ConfirmPopup from "../Popups/ConfirmPopup/ConfirmPopup";
import {
  deleteHorse,
  getHorseDetailsMetaData,
  gethorseList,
  viewHorse,
} from "../../Redux/NonRedux/horse";
import CompletePedigreePopup from "../Popups/CompletePedigreePopup/CompletePedigreePopup";
import { getCompletePedigree } from "../../actions/Horse/MyHorseActions";
import { useLoading } from "../../context/LoadingContext";
import NoDataFound from "../Common/NoDataFound/NoDataFound";
import { exportDocument, getInstantMeta } from "../../Redux/globallActions";
import {
  DATATABLE,
  DEFAULT_HORSE_PROFILE,
  FEMALE,
  MALE,
  PERMISSIONS,
  SIDEBAR,
  UPGRADE_MESSAGE,
} from "../../Constant/General";
import { SidebarContext } from "../../context/SideBarContext";
import Filter from "../Filter/Filter";
import { upgradePopupAction } from "../../Redux/General/GeneralAction";

export default function MyHorses() {
  const { t, i18n } = useTranslation();

  const tabValue: any = [
    {
      number: "",
      name: "all",
      value: "all",
    },
    {
      number: "",
      name: "stallions",
      value: "male",
    },
    {
      number: "",
      name: "mares",
      value: "female",
    },
  ];

  const tableHead = [
    {
      label: t("Horse"),
      className: "tm icon-text",
      key: "name",
    },
    {
      label: t("Sire"),
      className: "tm ",
      key: "sire",
    },
    {
      label: t("Dam"),
      className: "tm ",
      key: "dam",
    },

    {
      label: t("Breed"),
      className: "tm ",
      key: "breed",
    },

    {
      label: t("Age"),
      className: "tm ",
      key: "birthdate",
    },

    {
      label: t("Colour"),
      className: "tm ",
      key: "color",
    },

    {
      label: t("Status"),
      className: "tm ",
      key: "status",
    },
    {
      label: t("Action"),
      className: "tm text-right",
      key: "",
      headClass: "",
    },
  ];

  const [tabValueData, setTabValueData] = useState(tabValue);
  const [activeTab, setActiveTab] = useState("all");
  const [exportHorseModalShow, setExportHorseModalShow] = useState(false);
  const [addEditHorseModalShow, setAddEditHorseModalShow] = useState(false);
  const [recordsTotal, setRecordsTotal] = useState(1);
  const [currentPage, setCurrentPage] = useState(1);
  const [currentPageSize, setCurrentPageSize] = useState(10);
  const [searchText, setSearchText] = useState<any>(null);
  const [apiStatus, setApiStatus] = useState(API_STATUS.SUCCESS);
  const [columnSetting, setColumnSetting] = useState([]);
  const [listingData, setListingData] = useState([]);
  const [checkedRec, setCheckedRec] = useState<number[]>([]);

  const [deletableRecords, setDeletableRecords] = useState<any>([]);
  const [confirmPopupShow, setConfirmPopupShow] = useState(false);
  const [editData, setEditData] = useState<any>({});
  const { setLoading } = useLoading();
  const [isEdit, setIsEdit] = useState<boolean>(false);
  const [refreshList, setRefreshList] = useState(false);
  const [complatePedigreeModalShow, setComplatePedigreeModalShow] =
    useState(false);
  const [pedigreeData, setPedigreeData] = useState<any>({});
  const dispatch = useDispatch();
  const { setSidebar }: any = useContext(SidebarContext);
  const horseListData = useSelector((state: any) => state?.horseListReducer);
  const [metaData, setMetaData] = useState<any>([]);
  const [sortByField, setSortByField] = useState("");
  const [sortType, setSortType] = useState("");
  const [orderList, setOrderList] = useState<any>([]);
  const [exportParams, setExportParams] = useState<any>({});
  const [filterList, setFilterList] = useState<any>([]);
  const [filteredValue, setFilteredValue] = useState<any>({});
  const [breederHorseStatus, setBreederHorseStatus] = useState<any>(null);
  const [canAdd, setCanAdd] = useState(true);
  // const prefrence = useSelector(
  //   (state: any) => state?.masterReducer?.data?.component?.preferance
  // );
  const modules = useSelector(
    (state: any) => state?.masterReducer?.data?.component?.modules
  );
  const horseListApi = async () => {
    setLoading(true);
    const sexId = getIdfromSlug(metaData?.gender, activeTab);
    const postData: any = {
      page: currentPage,
      limit: currentPageSize,
      search: searchText,
      filter: {
        sex: sexId,
        ...filteredValue,
        // status: 0,
        // age: "",
        // color: "",
      },
      // sort: {
      //   column: "birthdate",
      //   type: "Asc",
      // },
    };
    if (breederHorseStatus) postData["breeder_status"] = breederHorseStatus;

    if (sortByField && sortType) {
      postData["sort"] = {
        column: orderList[sortByField],
        type: sortType,
      };
    }

    setExportParams(postData);
    try {
      const horseList: any = await gethorseList(postData);
      setRecordsTotal(horseList?.component?.recordsFiltered);
      let tabVal: any = tabValueData;
      tabVal[0]["number"] = horseList?.component?.totalHorse;
      tabVal[1]["number"] = horseList?.component?.totalStallions;
      tabVal[2]["number"] = horseList?.component?.totalMares;
      setTabValueData(tabVal);
      setListingData(horseList?.component?.listing);
      setColumnSetting(horseList?.component?.columnSettings?.allColomnValue);
      setOrderList(horseList?.component?.orderList);
      setFilterList(horseList?.component?.filterlist);
      setCanAdd(horseList?.component?.can_add_horse);
    } finally {
      setLoading(false);
    }
  };

  const horseMetaApi = async () => {
    try {
      setLoading(true);
      const data: any = await getHorseDetailsMetaData();
      setMetaData(data?.component?.metadata);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    horseListApi();
  }, [
    activeTab,
    currentPage,
    currentPageSize,
    sortByField,
    sortType,
    filteredValue,
    breederHorseStatus,
  ]);

  useEffect(() => {
    if (searchText != null) {
      const searchTimeOut = setTimeout(() => {
        if (currentPage === 1) {
          horseListApi();
        } else {
          setCurrentPage(1);
        }
      }, 500);
      return () => clearTimeout(searchTimeOut);
    }
  }, [searchText]);

  useEffect(() => {
    horseMetaApi();
    setSidebar(SIDEBAR.MY_HORSE);
  }, []);

  const deleteHandler = () => {
    setLoading(true);
    deleteHorse({ id: deletableRecords })
      .then((response: any) => {
        setRefreshList(true);
        setConfirmPopupShow(false);
      })
      .catch((error: any) => {})
      .finally(() => {
        setLoading(false);
      });
  };

  useEffect(() => {
    if (refreshList) {
      horseListApi();
      horseMetaApi();
      setRefreshList(false);
    }
  }, [refreshList]);

  const getIconbyType = (type: any) => {
    if (type == "male") {
      return MaleIcon;
    } else if (type == "female") {
      return FemaleIcon;
    } else if (type == "pregnant") {
      return PregnantIcon;
    }
  };

  const editHandler = (id: number) => {
    setLoading(true);
    viewHorse({ id: id })
      .then((response: any) => {
        if (response?.statusCode === SUCCESS_RESPONSE_CODE) {
          setIsEdit(true);
          setEditData(response?.component?.horseInformation);
          setAddEditHorseModalShow(true);
        }
        setLoading(false);
      })
      .catch((error) => {
        setLoading(false);
      });
  };

  const sortrecords = (fieldName: any) => {
    if (orderList?.[fieldName]) {
      if (sortByField == fieldName) {
        let sortTypeNow = sortType == "asc" ? "desc" : "asc";
        setSortType(sortTypeNow);
      } else {
        setSortByField(fieldName);
        setSortType("asc");
      }
    }
  };

  const sortableClass = (fieldName: string): string => {
    if (orderList?.[fieldName]) {
      return "sortable";
    } else {
      return "";
    }
  };

  const sortingTypeClass = (fieldName: string): string => {
    if (sortByField === fieldName) {
      return sortType == "asc" ? "arrow-up" : "arrow-down";
    } else {
      return "";
    }
  };

  const checkSingleRec = (e: any, val: number) => {
    //
    var checkedRecData: number[] = Object.assign([], checkedRec);
    if (e.target.checked) {
      checkedRecData.push(val);
    } else {
      checkedRecData = checkedRecData.filter((c: number) => c !== val);
    }
    setCheckedRec(checkedRecData);
  };

  const handleSelectAll = (e: any) => {
    if (e.target.checked) {
      var checkedRecData: any[] = Object.assign([], checkedRec);
      listingData?.map((li: any) => {
        if (!checkedRecData.includes(li.id)) {
          checkedRecData.push(li.id);
        }
      });
      setCheckedRec(checkedRecData);
    } else {
      setCheckedRec([]);
    }
  };

  const handleExport = () => {
    if (!checkRoutePermission(PERMISSIONS?.EXPORT_FEATURE, modules)) {
      upgradePopupAction({ show: true });
      return;
    }
    setExportHorseModalShow(true);
  };

  const horseDetailLink = (id: any) => {
    return HORSEDETAILS_HORSE + "/" + encrDecrStr(id, "encr");
  };

  const deleteNow = (id: number) => {
    const ids = deletableRecords;
    ids.push(id);
    setDeletableRecords(ids);
    setConfirmPopupShow(true);
  };

  const handlePedigree = (id: number) => {
    setLoading(true);
    getCompletePedigree({ horse_id: id })
      .then((response: any) => {
        if (response.statusCode === SUCCESS_RESPONSE_CODE) {
          setPedigreeData(response?.component?.pedigree_data);
          setComplatePedigreeModalShow(true);
        }
        setLoading(false);
      })
      .catch((error: any) => {
        setLoading(false);
      });
  };

  const changeBreederStatus = () => {
    if (!breederHorseStatus) setBreederHorseStatus("1");
    else setBreederHorseStatus("");
  };

  const resetFilter = () => {
    setFilteredValue({});
    setBreederHorseStatus("");
    setCurrentPage(1);
    setSearchText(null);
  };

  const handleAdd = () => {
    if (!canAdd) {
      upgradePopupAction({ show: true , message:t(UPGRADE_MESSAGE.ADD_HORSE)});
      return;
    }
      setIsEdit(false);
      setEditData({});
      setAddEditHorseModalShow(true);
  };
  return (
    <>
      <div className="my-horses-page">
        {/* Page Title Start */}
        <div className="page-title-wrapper">
          <div className="title-first-menu">
            <div className="title-icon-text">
              <img src={HorseImg} alt="" />
              <h5>{`${t("My Horses")}`}</h5>
            </div>
            <div className="title-right-icons">
              <a className="unset-links" onClick={handleExport}>
                <img src={ExportIcon} alt="" />
              </a>
            </div>
          </div>
        </div>
        {/* Page Title End */}

        <div className="page-content">
          <div className="page-tab">
            <Row>
              {tabValueData.map((items: any, index: number) => (
                <Col sm="6" md="4" key={index + "tab1"}>
                  <Numbernamecard
                    number={items.number}
                    name={t(items.name)}
                    onClick={() => {
                      resetFilter();
                      setActiveTab(items.value);
                    }}
                    className={items.value == activeTab ? "active" : ""}
                  />
                </Col>
              ))}
            </Row>
          </div>
          <div className="data-wrapper">
            <div className="data-header">
              <SearchBox
                onChange={(e: any) => setSearchText(e.target.value)}
                placeholder={t("Search Horse...")}
                value={searchText}
              />
              <div className="right-section">
                {/* {checkedRec.length ? (
                  <div className="select-action">
                    <div className="allck-wrapper">
                      <AllSelectLabel
                        defaultChecked={checkedRec.length}
                        onChange={(e: any) => handleSelectAll(e)}
                      >
                        {checkedRec.length} Selected
                      </AllSelectLabel>
                    </div>
                    <Dropdown>
                      <Dropdown.Toggle>More actions</Dropdown.Toggle>

                      <Dropdown.Menu>
                        <Dropdown.Item href="#/action-1">Action</Dropdown.Item>
                        <Dropdown.Item href="#/action-2">
                          Another action
                        </Dropdown.Item>
                        <Dropdown.Item href="#/action-3">
                          Something else
                        </Dropdown.Item>
                      </Dropdown.Menu>
                    </Dropdown>
                  </div>
                ) : (
                  ""
                )} */}
                <div className="btn-wrapper f-s-fb">
                  <Filter
                    filterList={filterList}
                    setFilteredValue={setFilteredValue}
                    setCurrentPage={setCurrentPage}
                  />
                  {/* <SquareButton
                    bg={breederHorseStatus === "1" ? "fill" : null}
                    onClick={changeBreederStatus}
                  >
                    <OffspringsIcon />
                  </SquareButton> */}
                  <CustomeButton bg="fill" onClick={handleAdd}>
                    <AddPlusIcon />
                    {t("Add")}
                  </CustomeButton>
                </div>
              </div>
            </div>
            <div className="data-body">
              <div className="img-responsive-table">
                <table>
                  <thead>
                    <tr>
                      {/* <th className="ck-wrapper">
                        <OnlyCk
                          checked={
                            checkedRec?.length &&
                            checkedRec?.length == listingData?.length
                          }
                          onChange={(e: any) => handleSelectAll(e)}
                        />
                      </th> */}
                      <th className="first-img-wrapper"></th>
                      {tableHead?.map((item: any, index: number) => (
                        <th key={index}>
                          <p
                            className={`${item?.className} ${sortableClass(
                              item?.key
                            )} ${sortingTypeClass(item?.key)}`}
                            onClick={() => sortrecords(item?.key)}
                          >
                            {item?.label}
                          </p>
                        </th>
                      ))}
                    </tr>
                  </thead>
                  <tbody>
                    {listingData?.map((items: any, index: number) => (
                      <tr key={index + "myhorse-tbl"}>
                        {/* <td className="ck-wrapper">
                          <OnlyCk
                            checked={checkedRec.includes(items?.id)}
                            onChange={(e: any) => checkSingleRec(e, items?.id)}
                          />
                        </td> */}
                        <td className="first-img-wrapper">
                          <img
                            src={
                              items?.profile
                                ? items?.profile
                                : DEFAULT_HORSE_PROFILE
                            }
                            alt=""
                            className="horse-img"
                          />
                          {/* <img
                                src={items?.img}
                                alt=""
                                className="horse-img"
                              /> */}
                        </td>
                        <td>
                          <div className="icon-text">
                            <img
                              src={getIconbyType(items?.type_slug)}
                              alt=""
                              className="horse-status-icon"
                            />
                            <div className="horse-name">
                              <Link
                                to={horseDetailLink(items?.id)}
                                className="a-bl"
                              >
                                {items?.name}
                              </Link>
                            </div>
                          </div>
                        </td>
                        <td>
                          <p className="bl">{items?.sire}</p>
                        </td>
                        <td>
                          <p className="bl">{items?.dam}</p>
                        </td>
                        <td>
                          <p className="bl">{items?.breed_value}</p>
                        </td>
                        <td>
                          <p className="bl">{items?.birthdate}</p>
                        </td>
                        <td>
                          <p className="bl">{items?.color_value}</p>
                        </td>
                        <td>
                          <label
                            className={`bm status-badge ${
                             getSlugNamefromId(metaData?.horse_status ,items?.status_key)  == "active" ? "active" : "sold"
                            }`}
                          >
                            {items?.status}
                          </label>
                        </td>
                        <td>
                          <div className="icon-wrapper">
                            <a>
                              <img
                                src={PedigreeIcon}
                                onClick={() => handlePedigree(items?.id)}
                                alt=""
                              />
                            </a>
                            <a>
                              <img
                                src={pencilIcon}
                                onClick={() => editHandler(items?.id)}
                                alt=""
                              />
                            </a>
                            <a>
                              <img
                                src={binIcon}
                                onClick={() => deleteNow(items?.id)}
                                alt=""
                              />
                            </a>
                          </div>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
              {listingData?.length ? (
                <Pagination
                  results={recordsTotal}
                  currentPage={currentPage}
                  setCurrentPage={setCurrentPage}
                  currentLength={currentPageSize}
                  setCurrentPageSize={setCurrentPageSize}
                />
              ) : (
                <NoDataFound />
              )}
            </div>
          </div>
        </div>
      </div>
      <ExportHorsePopup
        checkboxValue={columnSetting}
        show={exportHorseModalShow}
        onHide={() => setExportHorseModalShow(false)}
      />

      <AddEditHorsePopup
        show={addEditHorseModalShow}
        onHide={() => {
          setAddEditHorseModalShow(false);
        }}
        isedit={isEdit ? 1 : 0}
        horsedata={editData}
        setrefreshlist={() => {
          setRefreshList(true);
        }}
        metaData={metaData}
      />

      <CompletePedigreePopup
        show={complatePedigreeModalShow}
        onHide={() => setComplatePedigreeModalShow(false)}
        pedigreedata={pedigreeData}
      />

      <ConfirmPopup
        heading={"Delete"}
        message={"Are you sure to delete this record?"}
        show={confirmPopupShow}
        onHide={() => {
          setConfirmPopupShow(false);
          setDeletableRecords([]);
        }}
        confirm={deleteHandler}
      />
    </>
  );
}
