import type { DatePickerProps } from "antd";
import { t } from "i18next";
import moment from "moment";
import React, { useEffect, useState } from "react";
import Modal from "react-bootstrap/Modal";
import { addEditImageVideo } from "../../../actions/Horse/MyHorseActions";
import { API_STATUS } from "../../../Constant/Api";
import { SUCCESS_RESPONSE_CODE } from "../../../Constant/Status";
import { useLoading } from "../../../context/LoadingContext";
import {
  getFileNamefromLink,
  getFileNamefromPath,
  isFileValidated,
} from "../../../Utility/General";
import validate from "../../../Utility/Validation";
import CheckboxLabel from "../../Common/CheckboxLabel/CheckboxLabel";
import CustomeButton from "../../Common/CustomButton/CustomButton";
import Datepicker from "../../Common/Datepicker/Datepicker";
import FileUpload from "../../Common/FileUpload/FileUpload";
import InputGroups from "../../Common/InputGroups/InputGroups";
import TextareaGroups from "../../Common/TextareaGroups/TextareaGroups";
import TextButton from "../../Common/TextButton/TextButton";
import ToggleButton from "../../Common/ToggleButton/ToggleButton";

export default function AddPicturePopup(props: any) {
  const INTIAL_PICTURE_DATA = {
    form_type: {
      value: "picture",
      validation: ["required"],
      errors: [t("Form Type is required.")],
    },
    id: {
      value: "",
    },
    date: {
      value: moment().format("YYYY-MM-DD"),
      validation: ["required"],
      errors: [t("Date is required.")],
    },
    photographer: {
      value: "",
      validation: [""],
      errors: [t("Photographer is required.")],
    },
    title: {
      value: "",
      validation: [""],
      errors: [t("Title is required.")],
    },
    image: {
      value: "",
      validation: ["required"],
      errors: [t("Image is required.")],
    },
    private: {
      value: "0",
    },
    description: {
      value: "",
      validation: [""],
      errors: [t("Description is required.")],
    },
    type: {
      value: "0",
      validation: ["required"],
      errors: [t("Type is required.")],
    },
    is_default: {
      value: "0",
    },
  };

  const [pictureData, setPictureData] = useState<any>(INTIAL_PICTURE_DATA);
  const [fileData, setFileData] = useState("");
  const [fileDataName, setFileDataName] = useState<any>(`${t("Upload Picture")}*`);
  const [isFormValidated, setIsFormValidated] = useState(false);
  const [pictureDataError, setPictureDataError] = useState<any>({});
  const [apiStatus, setApiStatus] = useState(API_STATUS.SUCCESS);
  const [removeDoc, setRemoveDoc] = useState<any>(0);
  const [fileDataSelected, setFileDataSelected] = useState("");
  const allowedFormate = ["jpg", "png", "jpeg" , "heic" , "heif", "jpeg"];
  const allowedFileSizeInMb = 3;
  const { setLoading } = useLoading();
  const setData = (field: string, value: any) => {
    const pictureInfo = Object.assign({}, pictureData);
    pictureInfo[field].value = value;
    setPictureData({ ...pictureInfo });
  };

  const dateChange: DatePickerProps["onChange"] = (date, dateString) => {
    let dateIs = date?.format("YYYY-MM-DD");
    setData("date", dateIs);
  };

  const toggleBtnValue = [
    { name:t("Yes"), value: "0" },
    { name: t("No"), value: "1" },
  ];

  const checkExistFile = () => {
    if (props.isedit) {
      setRemoveDoc(1);
    }
    setFileData("");
    setData("image", "");
    setFileDataSelected("");
  };

  const fileChange = (e: any) => {
    let file = e.target.files[0];
    if (isFileValidated(file, allowedFormate, allowedFileSizeInMb)) {
      let fileName = getFileNamefromPath(e.target.value);
      setFileDataSelected(fileName);
      setFileData(e.target.files[0]);
      setData("image", fileName);
      if (props.isedit) {
        setRemoveDoc(1);
      }
    } else {
      e.target.value = "";
    }
  };

  useEffect(() => {
      setPictureDataError({ ...validate(pictureData).errors });
  }, [pictureData]);

  useEffect(() => {
    if (props.isedit) {
      if (props?.picturedata) {
        Object.keys(pictureData).forEach((element) => {
          if(props?.picturedata?.[element]){
            setData(element, props?.picturedata?.[element]);
          }
        });

        if (props?.picturedata?.image) {
          let document = props?.picturedata?.image;
          document = getFileNamefromLink(document);
          setFileDataSelected(document);
        }
      }
    } else {
      setPictureData(INTIAL_PICTURE_DATA);
      setRemoveDoc(0);
      setFileDataSelected("");
      setFileData("");
    }
  }, [props?.picturedata]);



  const submitHandler = () => {
    if (validate(pictureData).isValidated === true) {
      let horseId = props.horseid;
      const formData: any = new FormData();
      Object.keys(pictureData).forEach((element) => {
          formData.append(element, pictureData[element].value);
      });
      if (fileData && fileData != "") {
        formData.append("image_video", fileData);
      }
      formData.append("remove_doc", removeDoc);
      formData.append("horse_id", horseId);

      setLoading(true);
      addEditImageVideo(formData)
        .then((response: any) => {
          if (response.statusCode === SUCCESS_RESPONSE_CODE) {
            setApiStatus(API_STATUS.SUCCESS);
            props.onHide();
            props.setrefreshlist();
          } else {
            setApiStatus(API_STATUS.FAILED);
          }
        })
        .catch((error) => {
          setApiStatus(API_STATUS.FAILED);
        })
        .finally(() => {
          // setLoading(false);
        });
    } else {
      setPictureDataError({ ...validate(pictureData)?.errors });
      setIsFormValidated(true);
    }
  };

  useEffect(() => {
    if (props?.show === false) {
      setPictureDataError({});
      setPictureData(INTIAL_PICTURE_DATA);
      setIsFormValidated(false);
      setRemoveDoc(0);
      setFileDataSelected("");
      setFileData("");
      setData("image", "");
    }
  }, [props?.show]);
  
  const { setrefreshlist , ...rest } = props;
  return (
    <Modal {...rest} centered className="add-picture-popup modal-416">
      <Modal.Header closeButton>
        <p className="tl"> {props.isedit ? t("Update Picture") : t("Add Picture") } </p>
      </Modal.Header>
      <Modal.Body>
        <div>
          <Datepicker
            label={`${t("Date")}*`}
            endDate={moment().format("YYYY-MM-DD")}
            onChange={dateChange}
            value={moment(pictureData?.date?.value)}
            error={
              isFormValidated && pictureDataError["date"]
                ? pictureDataError["date"]
                : null
            }
          />
          <InputGroups
            label={`${t("Title")}`}
            type="text"
            autoComplete="off"
            value={pictureData?.title?.value}
            onChange={(e: any) => setData("title", e.target.value)}
            error={
              isFormValidated && pictureDataError["title"]
                ? pictureDataError["title"]
                : null
            }
          />
          <InputGroups
            label={`${t("Photographer")}`}
            type="text"
            autoComplete="off"
            value={pictureData?.photographer?.value}
            onChange={(e: any) => setData("photographer", e.target.value)}
            error={
              isFormValidated && pictureDataError["photographer"]
                ? pictureDataError["photographer"]
                : null
            }
          />

          <ToggleButton
            value={toggleBtnValue}
            label={`${t("Private")}`}
            selected={pictureData?.private?.value}
            setSelected={(value: any) => {
              setData("private", value);
            }}
          ></ToggleButton>

          <TextareaGroups
            label={`${t("Description")}`}
            type="text"
            autoComplete="off"
            value={pictureData?.description?.value}
            onChange={(e: any) => setData("description", e.target.value)}
            error={
              isFormValidated && pictureDataError["description"]
                ? pictureDataError["description"]
                : null
            }
          />
          <CheckboxLabel
            checked={pictureData?.is_default?.value == "0" ? 0 : 1 }
            onChange={(e: any) => {
              setData("is_default", e.target.checked ? 1 : 0);
            }}
          >
           {t("Set as horse profile picture")} 
          </CheckboxLabel>
          <FileUpload
            onChange={(e: any) => {
              fileChange(e);
            }}
            value={fileDataName}
            removeFile={() => checkExistFile()}
            selectedValue={fileDataSelected}
            fileType={t("Use a jpg, jpeg, png, heic")}
            error={
              isFormValidated && pictureDataError["image"]
                ? pictureDataError["image"]
                : null
            }
          />
        </div>
      </Modal.Body>
      <Modal.Footer>
        <div className="footer-2-btn">
          <TextButton onClick={props.onHide}>{t("Cancel")}</TextButton>
          <CustomeButton bg="fill" onClick={submitHandler}>
            {" "}
            {props.isedit ? t("Update") : t("Add")}
          </CustomeButton>
        </div>
      </Modal.Footer>
    </Modal>
  );
}
