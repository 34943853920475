import React, { useState , useEffect } from "react";
import { Col, Row } from "react-bootstrap";
import Modal from "react-bootstrap/Modal";
import CustomeButton from "../../Common/CustomButton/CustomButton";
import InputGroups from "../../Common/InputGroups/InputGroups";
import TextButton from "../../Common/TextButton/TextButton";
import HorseHeight from "../../../Assets/img/horse-height.png";
import validate from "../../../Utility/Validation";
import { calculateHandFromHeight, calculateHeightFromHand } from "../../../Utility/General";
import { useSelector } from "react-redux";
import { t } from "i18next";

export default function HeightCalcuPopup(props: any) {
  const INTIAL_HEIGHT_CAL_DATA = {
    height: {
      value: "",
      validation: ["required"],
      errors: [t("Height is required.")],
    },
    heightInHand: {
      value: "",
    },
  };

  const [heightCalData, setHeightCalData] = useState<any>(
    INTIAL_HEIGHT_CAL_DATA
  );
  const [heightCalError, setHeightCalError] = useState<any>({});
  const [isFormValidated, setIsFormValidated] = useState(false);

  const prefrence = useSelector(
    (state: any) => state?.masterReducer?.data?.component?.preferance
  );

  const setData = (field: string, value: any) => {
    let heightData: any = Object.assign({}, heightCalData);
    heightData[field].value = value;
    setHeightCalData({ ...heightData });
  };

  const handleCalculateHeight = () => {
    if (validate(heightCalData).isValidated === true) {
      props.calculate(heightCalData.height.value);
    } else {
      setIsFormValidated(true);
      setHeightCalError({ ...validate(heightCalData).errors });
    }
  };

  useEffect(() => {
    if (props?.show === false) {
      setHeightCalError({});
      setHeightCalData(INTIAL_HEIGHT_CAL_DATA);
      setIsFormValidated(false);
    }
  }, [props?.show]);

  useEffect(() => {
      if(isFormValidated){
        setHeightCalError({...validate(setHeightCalData).errors});
      }
  }, [heightCalData])
  
  const { calculate , ...rest } = props;

  return (
    <Modal {...rest} centered className="height-calcu-popup modal-784">
      <Modal.Header closeButton>
        <p className="tl">{t("Height Calculator")}</p>
      </Modal.Header>
      <Modal.Body>
        <Row>
          <Col sm={12} md={6}>
            <img src={HorseHeight} alt="" className="horse-img" />
          </Col>
          <Col sm={12} md={6}>
            <p className="bl">
            {t("height_description")}  
            </p>
            <InputGroups
              label={`${t("Height")} (${prefrence?.heightUnit_value})*`}
              type="number"
              value={heightCalData?.height?.value}
              onChange={(e: any) => {
                setData("height", e.target.value);
                let hand = calculateHandFromHeight(e.target.value , prefrence);
                setData("heightInHand", hand);
              }}
              error={
                isFormValidated && heightCalError["height"]
                  ? heightCalError["height"]
                  : null
              }
            />
            <InputGroups
              label={t("Height (hands)")}
              type="number"
              value={heightCalData?.heightInHand?.value}
              onChange={(e: any) => {
                const regex = /^(?:\d+|\d*\.\d?[0123])$/;
                if(regex.test(e.target.value) || !(e.target.value)){
                  setData("heightInHand", e.target.value);  
                }
                let height = calculateHeightFromHand(e.target.value , prefrence);
                setData("height" , height);
              }}
              error={
                isFormValidated && heightCalError["heightInHand"]
                  ? heightCalError["heightInHand"]
                  : null
              }
            />
            <div className="footer-2-btn">
              <TextButton onClick={props.onHide}> {t("Cancel")}</TextButton>
              <CustomeButton bg="fill" onClick={handleCalculateHeight}>
              {t("Add")}  
              </CustomeButton>
            </div>
          </Col>
        </Row>
      </Modal.Body>
    </Modal>
  );
}
