import type { DatePickerProps } from "antd";
import moment from "moment";
import React, { useState, useEffect } from "react";
import { Col, Row } from "react-bootstrap";
import Modal from "react-bootstrap/Modal";
import MinusIcon from "../../../Assets/img/minus-circle.svg";
import PlusIcon from "../../../Assets/img/plus-circle.svg";
import CustomeButton from "../../Common/CustomButton/CustomButton";
import Datepicker from "../../Common/Datepicker/Datepicker";
import InputGroups from "../../Common/InputGroups/InputGroups";
import InputSelectGroup from "../../Common/InputSelectGroup/InputSelectGroup";
import InputSelectMix from "../../Common/InputSelectMix/InputSelectMix";
import SelectGropus from "../../Common/SelectGroups/SelectGroups";
import TextButton from "../../Common/TextButton/TextButton";
import Timepicker from "../../Common/Timepicker/Timepicker";
import validate from "../../../Utility/Validation";
import { API_STATUS } from "../../../Constant/Api";
import { useDispatch, useSelector } from "react-redux";
import {
  metaHorseListAction,
  metaTrainingTypesAction,
} from "../../../Redux/General/GeneralAction";
import MultiSelectGroup from "../../Common/MultiSelectGroup/MultiSelectGroup";
import { t } from "i18next";
import { addTraining, editTraining } from "../../../Redux/NonRedux/training";
import { SUCCESS_RESPONSE_CODE } from "../../../Constant/Status";
import { useLoading } from "../../../context/LoadingContext";

export default function EditTrainingPopup(props: any) {
  const INTIAL_TRAINING_DATA = {
    id: {
      value: "",
    },
    horse_id: {
      value: "",
      validation: ["required"],
      errors: [t("Horse is Required.")],
    },
    start_date: {
      value: moment().format("YYYY-MM-DD"),
      validation: ["required"],
      errors: [t("start Date is required.")],
    },
    end_date: {
      value: moment().format("YYYY-MM-DD"),
      validation: ["required"],
      errors: [t("end Date is required.")],
    },
    training_type: {
      value: "",
      validation: ["required"],
      errors: [t("Training Type is required.")],
    },
    trainer: {
      value: "",
      validation: ["required", "letters"],
      errors: [t("Training Type is required.", "Incorrect format")],
    },
    duration_hour: {
      value: "",
      validation: ["beteween:0:23"],
      errors: [t("Please enter range from 0 to 23")],
    },
    duration_minutes: {
      value: "",
      validation: ["beteween:0:59"],
      errors: [t("Please enter range from 0 to 59")],
    },
    duration_second: {
      value: "",
      validation: ["beteween:0:59"],
      errors: [t("Please enter range from 0 to 59")],
    },
    days: {
      value: [],
      validation: ["requiredArray"],
      errors: [t("Days is Required.")],
    },
    start_time: {
      value: moment().format("YYYY-MM-DD HH:mm"),
      validation: [],
      errors: [t("Start time is Required.")],
    },
  };

  const [trainingData, setTrainingData] = useState<any>(INTIAL_TRAINING_DATA);
  const [isFormValidated, setIsFormValidated] = useState(false);
  const [trainingError, setTrainingError] = useState<any>({});
  const [apiStatus, setApiStatus] = useState(API_STATUS.SUCCESS);
  const weekdays = t("weekdays", { returnObjects: true });



  const { setLoading } = useLoading();

  const setData = (field: string, value: any) => {
    let items: any = Object.assign({}, trainingData);
    items[field].value = value;
    setTrainingData({ ...items });
  };

  useEffect(() => {
    if (props.isedit && props?.editdata) {
      Object.keys(INTIAL_TRAINING_DATA).forEach((element) => {
        if (props?.editdata[element] !== "") {
          setData(element, props?.editdata[element]);
        }
        if (element === "days") {
          setData(element, props?.editdata[element].split(","));
        }
      });
     
      setData(
        "start_time",
        props?.editdata["start_time"] ? moment(props?.editdata["start_time"], "HH:mm:ss").format(
          "YYYY-MM-DD HH:mm:ss"
        ) : null
      );
    }
  }, [props?.editdata]);

  useEffect(() => {
    setTrainingError({ ...validate(trainingData).errors });
  }, [trainingData]);

  const handleSubmit = () => {
    if (validate(trainingData).isValidated === true) {
      let editParams: any = {};
      Object.keys(INTIAL_TRAINING_DATA).forEach((element) => {
        if (trainingData[element].value !== "") {
          editParams[element] = trainingData[element].value;
        }
      });
      setLoading(true);
      editTraining(editParams)
        .then((response: any) => {
          if (response.statusCode === SUCCESS_RESPONSE_CODE) {
            props.onHide();
            props.setrefreshlist();
          }
        })
        .catch((error: any) => {})
        .finally(() => {
          setLoading(false);
        });
    } else {
      setIsFormValidated(true);
      setTrainingError({ ...validate(trainingData)?.errors });
    }
  };

  useEffect(() => {
    if (props?.show === false) {
      setTrainingError({});
      setIsFormValidated(false);
    }
  }, [props?.show]);

  const { setrefreshlist,metaData, ...rest } = props;

  
  return (
    <Modal {...rest} centered className="add-training-popup modal-824">
      <Modal.Header closeButton>
        <p className="tl">{t("Edit Training")}</p>
      </Modal.Header>
      <Modal.Body>
        <div className="repeate-box">
          <hr />
          <div className="rm-pr-40">
            <Row>
              <Col sm={6} md={6}>
                <Datepicker
                  label={`${t("Start Date")}*`}
                  onChange={(date: any) => {
                    let end_date = moment(trainingData["end_date"].value);
                    let dateIs = date?.format("YYYY-MM-DD");
                    setData("start_date", dateIs);
                    if (date > end_date) {
                      setData("end_date", date);
                    }
                  }}
                  value={moment(trainingData?.start_date?.value)}
                  error={
                    isFormValidated && trainingError?.["start_date"]
                      ? trainingError?.["start_date"]
                      : null
                  }
                />
              </Col>
              <Col sm={6} md={6}>
                <Datepicker
                  label={`${t("End Date")}*`}
                  startDate={moment(trainingData?.start_date?.value).format(
                    "YYYY-MM-DD"
                  )}
                  onChange={(date: any) => {
                    let dateIs = date?.format("YYYY-MM-DD");
                    setData("end_date", dateIs);
                  }}
                  value={moment(trainingData?.end_date?.value)}
                  error={
                    isFormValidated && trainingError?.["end_date"]
                      ? trainingError?.["end_date"]
                      : null
                  }
                />
              </Col>
            </Row>
            <Row>
              <Col sm={6} md={6}>
                <SelectGropus
                  label={`${t("Training Type")}*`}
                  value={metaData?.training_type}
                  defaultSelect={trainingData?.training_type.value}
                  onChange={(e: any) => {
                    setData("training_type", e.target.value);
                  }}
                  dropKey="id"
                  dropVal="metadata"
                  error={
                    isFormValidated && trainingError?.["training_type"]
                      ? trainingError?.["training_type"]
                      : null
                  }
                />
              </Col>
              <Col sm={6} md={6}>
                <InputGroups
                  label={`${t("Trainer")}`}
                  type="text"
                  value={trainingData?.trainer?.value}
                  onChange={(e: any) => setData("trainer", e.target.value)}
                  error={
                    isFormValidated && trainingError?.["trainer"]
                      ? trainingError?.["trainer"]
                      : null
                  }
                />
              </Col>
            </Row>
            <div className="duration-input">
              <label>{t("Duration")}</label>
              <div className="this-inpus-wrapper">
                <InputGroups
                  type="text"
                  placeholder="HH"
                  value={trainingData?.duration_hour?.value}
                  onChange={(e: any) =>
                    setData("duration_hour", e.target.value)
                  }
                  error={
                    isFormValidated && trainingError?.["duration_hour"]
                      ? trainingError?.["duration_hour"]
                      : null
                  }
                />
                <InputGroups
                  type="text"
                  placeholder="MM"
                  value={trainingData?.duration_minutes?.value}
                  onChange={(e: any) =>
                    setData("duration_minutes", e.target.value)
                  }
                  error={
                    isFormValidated && trainingError?.["duration_minutes"]
                      ? trainingError?.["duration_minutes"]
                      : null
                  }
                />
                <InputGroups
                  type="text"
                  placeholder="SS"
                  value={trainingData?.duration_second?.value}
                  onChange={(e: any) =>
                    setData("duration_second", e.target.value)
                  }
                  error={
                    isFormValidated && trainingError?.["duration_second"]
                      ? trainingError?.["duration_second"]
                      : null
                  }
                />
              </div>
            </div>
          </div>
          <div className="nrm-pr-40">
            <Row>
              <Col sm={6} md={6}>
                <div className="widthPlus32">
                  <MultiSelectGroup
                    label={`${t("Days")}*`}
                    value={weekdays}
                    defaultSelect={trainingData?.days?.value}
                    dropKey="id"
                    dropVal="name"
                    onChange={(e: any) => {
                      setData("days", e);
                    }}
                    error={
                      isFormValidated && trainingError?.["days"]
                        ? trainingError?.["days"]
                        : null
                    }
                  />
                </div>
              </Col>
              <Col sm={6} md={6}>
                <div className="remove-btn-parent">
                  <Timepicker
                    allowClear={true}
                    label={`${t("Time")}`}
                    onChange={(time: any) => {
                      setData(
                        "start_time",
                        time ? time.format("YYYY-MM-DD HH:mm") : null
                      );
                    }}
                    value={
                      trainingData?.start_time?.value
                        ? moment(trainingData?.start_time?.value)
                        : undefined
                    }
                    error={
                      isFormValidated && trainingError?.["start_time"]
                        ? trainingError?.["start_time"]
                        : null
                    }
                  />
                </div>
              </Col>
            </Row>
          </div>
        </div>

        <hr />
      </Modal.Body>
      <Modal.Footer>
        <div className="rm-pr-40">
          <div className="footer-2-btn">
            <TextButton onClick={props.onHide}> {t("Cancel")}</TextButton>
            <CustomeButton bg="fill" onClick={handleSubmit}>
              {t("Update")}
            </CustomeButton>
          </div>
        </div>
      </Modal.Footer>
    </Modal>
  );
}
