import { t } from "i18next";
import React, { useEffect, useState } from "react";
import AddPlusIcon from "../../../../Assets/icon/AddPlusIcon";
import Filtericon from "../../../../Assets/icon/Filtericon";
import binIcon from "../../../../Assets/img/bin.svg";
import pencilIcon from "../../../../Assets/img/pencil.svg";
import { API_STATUS, END_POINTS } from "../../../../Constant/Api";
import { DATATABLE } from "../../../../Constant/General";
import { SUCCESS_RESPONSE_CODE } from "../../../../Constant/Status";
import { useLoading } from "../../../../context/LoadingContext";
import { getListMetaDataApi } from "../../../../Redux/NonRedux/general";
import {
  destroyContacts,
  getAllContacts,
  getSingleContact,
} from "../../../../Redux/NonRedux/users";
import { getClassNameFromStatus, getDefaultSorting } from "../../../../Utility/General";
import CustomeButton from "../../../Common/CustomButton/CustomButton";
import TableHead from "../../../Common/DataTable/TableHead/TableHead";
import NoDataFound from "../../../Common/NoDataFound/NoDataFound";
import OnlyCk from "../../../Common/OnlyCk/OnlyCk";
import SearchBox from "../../../Common/SearchBox/SearchBox";
import Filter from "../../../Filter/Filter";
import Pagination from "../../../Layouts/Pagination/Pagination";
import Loader from "../../../Loader/Loader";
import AddContactPopup from "../../../Popups/AddContactPopup/AddContactPopup";
import ConfirmPopup from "../../../Popups/ConfirmPopup/ConfirmPopup";

export default function ContactPage() {
  const defaultSorting : any = getDefaultSorting(DATATABLE.CONTACT);
  const tableHead = [
    {
      label: t("First Name"),
      className: "tm",
      key: "first_name",
    },
    {
      label: t("Last Name"),
      className: "tm",
      key: "last_name",
    },
    {
      label: t("Email"),
      className: "tm",
      key: "email",
    },
    {
      label: t("Phone Number"),
      className: "tm",
      key: "phone_number",
    },
    {
      label: t("Status"),
      className: "tm",
      key: "status",
    },
    {
      label: t("Contact Type"),
      className: "tm",
      key: "contact_type",
    },
    {
      label: t("Action"),
      className: "tm",
      key: "",
      headClass: "text-right",
    },
  ];

  const [currentPage, setCurrentPage] = React.useState(1);
  const [currentPageSize, setCurrentPageSize] = React.useState(10);

  const [AddContactModalShow, setAddContactModalShow] = React.useState(false);

  const [apiStatus, setApiStatus] = useState(API_STATUS.SUCCESS);
  const [editData, setEditData] = useState<any>({});
  const [deletableRecords, setDeletableRecords] = useState<any>([]);
  const [confirmPopupShow, setConfirmPopupShow] = useState(false);
  const [isEdit, setIsEdit] = useState<boolean>(false);
  const [refreshList, setRefreshList] = useState(false);
  const { setLoading } = useLoading();
  const [contactList, setContactList] = useState([]);
  const [recordsTotal, setRecordsTotal] = React.useState(1);
  const [searchText, setSearchText] = useState();

  const [filterList, setFilterList] = useState<any>([]);
  const [filteredValue, setFilteredValue] = useState<any>({});

  const [sortByField, setSortByField] = useState(defaultSorting?.key);
  const [sortType, setSortType] = useState(defaultSorting?.type);
  const [orderList, setOrderList] = useState<any>([]);
  const [metaData, setMetaData] = useState({});
  const [checkedRec, setCheckedRec] = useState<number[]>([]);

  const listApiCall = async () => {
    let params: any = {
      page: currentPage,
      limit: currentPageSize,
      search: searchText,
    };
    if (sortByField && sortType) {
      params["sort"] = {
        column: orderList?.[sortByField] ? orderList?.[sortByField] : defaultSorting?.value ,
        type: sortType,
      };
    }
    if (filteredValue && Object.keys(filteredValue)?.length) {
      params["filter"] = filteredValue;
    }
    setLoading(true);
    getAllContacts(params)
      .then((response: any) => {
        if (response.statusCode === SUCCESS_RESPONSE_CODE) {
          setContactList(response?.component?.listing);
          setRecordsTotal(response?.component?.recordsFiltered);
          setOrderList(response?.component?.orderlist);
          setFilterList(response?.component?.filterlist);
        }
        setLoading(false);
      })
      .catch((error) => {
        setLoading(false);
      });
  };

  const getMetaData = () => {
    setLoading(true);  
    getListMetaDataApi(END_POINTS.metaUserContact)
      .then((response: any) => {
        setMetaData(response?.component);
      })
      .catch((error: any) => {})
      .finally(() => {
        setLoading(false);
      });
  };

  useEffect(() => {
    getMetaData();
  },[]); 

  useEffect(() => {
    if (searchText != null) {
      const searchTimeOut = setTimeout(() => {
        if (currentPage === 1) {
          listApiCall();
        } else {
          setCurrentPage(1);
        }
      }, 500);
      return () => clearTimeout(searchTimeOut);
    }
  }, [searchText]);

  useEffect(() => {
    listApiCall();
  }, [currentPage, currentPageSize, sortByField, sortType, filteredValue]);

  useEffect(() => {
    if (refreshList) {
      listApiCall();
      setRefreshList(false);
    }
  }, [refreshList]);

  const editHandler = (id: number) => {
    let params = {
      id: id,
    };
    setApiStatus(API_STATUS.LOADING);
    setLoading(true);
    getSingleContact(params)
      .then((response: any) => {
        if (response?.statusCode === SUCCESS_RESPONSE_CODE) {
          setApiStatus(API_STATUS.SUCCESS);
          setLoading(false);
          setIsEdit(true);
          setEditData(response?.component);
          setAddContactModalShow(true);
        } else {
          setApiStatus(API_STATUS.FAILED);
          setLoading(false);
        }
      })
      .catch((error) => {
        setApiStatus(API_STATUS.FAILED);
        setLoading(false);
      });
  };

  const deleteHandler = (id: number) => {
    let params = {
      id: deletableRecords,
    };

    setApiStatus(API_STATUS.LOADING);
    setLoading(true);
    destroyContacts(params)
      .then((response: any) => {
        if (response.statusCode === SUCCESS_RESPONSE_CODE) {
          setApiStatus(API_STATUS.SUCCESS);
          setLoading(false);
          setRefreshList(true);
          setConfirmPopupShow(false);
        } else {
          setApiStatus(API_STATUS.FAILED);
          setLoading(false);
        }
      })
      .catch((error) => {
        setApiStatus(API_STATUS.FAILED);
        setLoading(false);
      });
  };

  const deleteNow = (id: number) => {
    let ids = deletableRecords;
    ids.push(id);
    setDeletableRecords(ids);
    setConfirmPopupShow(true);
  };

  return (
    <>
      <div className="contact-page">
        <div className="page-content">
          <div className="data-tbl-wrapper">
            <div className="data-tbl-header">
              <SearchBox
               value={searchText}
                placeholder={t("Search...")}
                onChange={(e: any) => setSearchText(e.target.value)}
              />
              <div className="btn-wrapper f-fb">
                {/* <CustomeButton>
                  <Filtericon />
                  <p>{t("Filter")}</p>
                </CustomeButton> */}
                <Filter
                  filterList={filterList}
                  setFilteredValue={setFilteredValue}
                  setCurrentPage={setCurrentPage}
                />
                <CustomeButton
                  bg="fill"
                  onClick={() => {
                    setIsEdit(false);
                    setEditData({});
                    setAddContactModalShow(true);
                  }}
                >
                  <AddPlusIcon />
                  {t("Add")}
                </CustomeButton>
              </div>
            </div>
            <div className="data-tbl ptb-12">
              <table>
                <TableHead
                  data={tableHead}
                  orderList={orderList}
                  sortByField={sortByField}
                  sortType={sortType}
                  setSortType={setSortType}
                  setSortByField={setSortByField}
                />
                <tbody>
                  {contactList.map((items: any, index: number) => (
                    <tr key={index + "vaccination-data"}>
                      {/* <td className="ck-wrapper">
                      <OnlyCk
                            checked={checkedRec.includes(items?.id)}
                            onChange={(e: any) => checkSingleRec(e, items?.id)}
                          />
                      </td> */}
                      <td>
                        <p className="bl">{items.first_name}</p>
                      </td>
                      <td>
                        <p className="bl">{items.last_name}</p>
                      </td>
                      <td>
                        <p className="bl">{items.email}</p>
                      </td>
                      <td>
                        <p className="bl">{items.phone_number}</p>
                      </td>
                      <td>
                        <label
                          className={`bl status-badge ${getClassNameFromStatus(
                            items?.status
                          )}`}
                        >
                          {items.status == "1" ? "Active" : "Inactive"}
                        </label>
                      </td>
                      <td>
                        <p className="bl">{items.contact_value}</p>
                      </td>
                      <td>
                        <div className="icon-wrapper">
                          <a>
                            <img
                              src={pencilIcon}
                              onClick={() => editHandler(items?.id)}
                              alt=""
                            />
                          </a>
                          <a>
                            <img
                              src={binIcon}
                              onClick={() => deleteNow(items?.id)}
                              alt=""
                            />
                          </a>
                        </div>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
            {contactList?.length ? (
              <Pagination
                results={recordsTotal}
                currentPage={currentPage}
                setCurrentPage={setCurrentPage}
                currentLength={currentPageSize}
                setCurrentPageSize={setCurrentPageSize}
              />
            ) : (
              <NoDataFound />
            )}
          </div>
        </div>
      </div>

      <AddContactPopup
        show={AddContactModalShow}
        onHide={() => {
          setAddContactModalShow(false);
        }}
        isedit={isEdit ? 1 : 0}
        setrefreshlist={() => {
          setRefreshList(true);
        }}
        editData={editData}
        metaData={metaData}
      />

      <ConfirmPopup
        heading={t("Delete")}
        message={t("Are you sure to delete this record?")}
        show={confirmPopupShow}
        onHide={() => {
          setConfirmPopupShow(false);
          setDeletableRecords([]);
        }}
        confirm={deleteHandler}
      />
    </>
  );
}
