import React, { useCallback, useEffect, useState } from "react";
import Dropdown from "react-bootstrap/Dropdown";
import { Link, useNavigate } from "react-router-dom";
import BookIcon from "../../Assets/icon/BookIcon";
import Management from "../../Assets/icon/Management";
import MarketPlaceIcon from "../../Assets/img/Common-icon/MarketPlaceIcon";
import CalenderIcon from "../../Assets/img/Common-icon/CalenderIcon";
import Global from "../../Assets/img/globe.svg";
import MenuIcon from "../../Assets/img/h-menu.svg";
import User from "../../Assets/img/user.svg";
import Logo from "../../Assets/img/Logo.svg";
import {
  CHANGEPASSWORD,
  FARM_DIRECTORY_DETAILS,
  FARM_DIRECTORY_LISTING,
  IMPORTDATA,
  LOCATION,
  PREFERENCE,
  USER_CONTACT,
} from "../../Constant/Route";
import LogoutPopup from "../Popups/LogoutPopup/LogoutPopup";
import { useTranslation } from "react-i18next";
import { LANGUAGE, USER } from "../../Constant/LocalStorage";
import {  useSelector } from "react-redux";
import { changeLangAction } from "../../Redux/General/GeneralAction";
import { getSessionItemDecrypt } from "../../Utility/General";
import { MASTER_SUCCESS } from "../../Redux/General/GeneralType";
import { USER_TYPE } from "../../Constant/General";

export default function Header(props: any) {
  const { t, i18n } = useTranslation();
  let navigate = useNavigate();

  const userInfo = getSessionItemDecrypt(USER);

  const masterApiResponse = useSelector((state: any) => state?.masterReducer);
  const [farmName, setFarmName] = useState("");
  const [userName, setUserName] = useState("");
  const [LogoutModalShow, setLogoutModalShow] = React.useState(false);
  const [userType, setUserType] = useState();


  useEffect(() => {
    if (masterApiResponse?.type === MASTER_SUCCESS) {
      const userInfo = masterApiResponse?.data?.component?.user_info;
      if (userInfo.farm_name) {
        setFarmName(userInfo.farm_name);
      } else {
        setFarmName("Al Jawaher Stud");
      }
      setUserName(userInfo?.first_name + " " + userInfo?.last_name);
    }
  }, [masterApiResponse]);

  useEffect(() => {
    if (userInfo) {
      setUserType(userInfo?.component?.type);
    }
  }, []);



  const redirectToMarketPlace = useCallback((key: any) => {
    const userData = getSessionItemDecrypt(USER);
    let token = userData.component.refresh_token;
    const time = Date.now();
    token = atob(token);
    let data: any = {
      token,
      time,
      module: key,
    };

    data = btoa(JSON.stringify(data));
    let url: any = `${process.env.REACT_APP_MARKETPLACE_URL}/check-auth/${data}`;
    window.location.href = encodeURI(url);

  }, []);


  const profileRouteChange = (pathName: any) => {
    navigate(pathName);
  };

  const changeLanguage = (lang: any) => {
    localStorage.setItem(LANGUAGE, lang);
    i18n.changeLanguage(lang);
    changeLangAction({ language: lang })
      .then((response: any) => {
        window.location.reload();
      })
      .catch((error: any) => { });
  };


  return (
    <>
      <header className="header">
        <div className="menu-icon" onClick={() => props.setSidebarToggle(true)}>
          <img src={MenuIcon} alt="" />
        </div>
        <div className="brand-title">
          {/* <h6>{farmName}</h6> */}
          <img src={Logo} className="full-logo" alt="logo" />
        </div>
        <ul className="left-menu">
          <li
            className={`${window.location.pathname === FARM_DIRECTORY_LISTING ||
                window.location.pathname.includes(FARM_DIRECTORY_DETAILS)
                ? ""
                : "active"
              }`}
          >
            <Link to="/" className="tm links">
              <Management />
              {t("Horse Management")}
            </Link>
          </li>
          <li
            className={
              window.location.pathname === FARM_DIRECTORY_LISTING ||
                window.location.pathname.includes(FARM_DIRECTORY_DETAILS)
                ? "active"
                : ""
            }
          >
            {/* <Link to={FARM_DIRECTORY_LISTING} className="tm links"> */}
            <a
              className="tm links"
              onClick={() => redirectToMarketPlace("farmDirectory")}
            >
              <BookIcon />
              {t("Farm Directory")}
            </a>
            {/* </Link> */}
          </li>
          <li>
            <a
              className={`${userType === USER_TYPE.STUD_STAFF ? "link-disable" : ""} tm links`}
              onClick={() => redirectToMarketPlace("marketplace")}

            >
              <MarketPlaceIcon />
              {t("Marketplace")}
            </a>
          </li>
          <li>
            <a
              className="tm links"
              onClick={() => redirectToMarketPlace("eventNews")}
            >
              <CalenderIcon />
              {t("News & Events")}
            </a>
          </li>
        </ul>
        <div className="right-menu">
          {/* <ul className="square-menu">
            <li>
              <a className="tm">
                <img src={MarketplaceIcon} alt="" />
              </a>
            </li>
          </ul> */}
          <Dropdown className="language-menu">
            <Dropdown.Toggle>
              <img src={Global} alt="" />
            </Dropdown.Toggle>
            <Dropdown.Menu>
              <Dropdown.Item onClick={() => changeLanguage("en")}>
                English
              </Dropdown.Item>
              <Dropdown.Item onClick={() => changeLanguage("ar")}>
                عربي
              </Dropdown.Item>
            </Dropdown.Menu>
          </Dropdown>
          <Dropdown className="profile-menu">
            <Dropdown.Toggle>
              <img src={User} alt="" />
              <div className="user-details">
                <p className="tm">{userName}</p>
                <p className="ls">Stud Farm Owner</p>
              </div>
            </Dropdown.Toggle>
            <Dropdown.Menu>
              {
                userType !== USER_TYPE.STUD_STAFF ? <Dropdown.Item
                  onClick={() => redirectToMarketPlace("editAccount")}
                >
                  {t("Account")}
                </Dropdown.Item> : null
              }

              <Dropdown.Item
                onClick={() => {
                  profileRouteChange(LOCATION);
                }}
              >
                {t("Farm Locations")}
              </Dropdown.Item>

              {
                userType !== USER_TYPE.STUD_STAFF ?  <Dropdown.Item
                onClick={() => {
                  profileRouteChange(PREFERENCE);
                }}
              >
                {t("Preferences")}
              </Dropdown.Item> : null
              }

             
              <Dropdown.Item
                onClick={() => {
                  profileRouteChange(USER_CONTACT);
                }}
              >
                {t("Users")}
              </Dropdown.Item>
              <Dropdown.Item
                onClick={() => {
                  profileRouteChange(IMPORTDATA);
                }}
              >
                {t("Import Data")}
              </Dropdown.Item>
              {
                userType !== USER_TYPE.STUD_STAFF ?   <Dropdown.Item
                onClick={() => {
                  profileRouteChange(CHANGEPASSWORD);
                }}
              >
                {t("Change Password")}
              </Dropdown.Item> : null
              }

             
              <Dropdown.Item
                onClick={() => {
                  setLogoutModalShow(true);
                }}
              >
                {t("Logout")}
              </Dropdown.Item>
            </Dropdown.Menu>
          </Dropdown>
        </div>
      </header>
      <LogoutPopup
        show={LogoutModalShow}
        onHide={() => setLogoutModalShow(false)}
      />
    </>
  );
}
