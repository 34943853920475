import React, { useEffect, useState } from "react";
import SearchBox from "../../Common/SearchBox/SearchBox";
import { t } from "i18next";
import Filter from "../../Filter/Filter";
import { Col, Row } from "react-bootstrap";
import { getStudFarmList } from "../../../Redux/NonRedux/farm";
import { useLoading } from "../../../context/LoadingContext";
import { Link } from "react-router-dom";
import { FARM_DIRECTORY_DETAILS } from "../../../Constant/Route";
import { encrDecrStr } from "../../../Utility/General";
import Pagination from "../../Layouts/Pagination/Pagination";
import NoDataFound from "../../Common/NoDataFound/NoDataFound";
import { StudFarm } from "../../../interface/FarmDirectory.interface";

export default function FarmDirectoryListing() {
  const [studFarmList, setStudFarmList] = useState<StudFarm[]>([]);
  const { setLoading } = useLoading();
  const [searchText, setSearchText] = React.useState();
  const alphabet = "ABCDEFGHIJKLMNOPQRSTUVWXYZ";
  const [currentChar, setCurrentChar] = useState<string>();
  const [currentNum, setCurrentNum] = useState<string>();

  const [recordsTotal, setRecordsTotal] = React.useState(1);
  const [currentPage, setCurrentPage] = React.useState(1);
  const [currentPageSize, setCurrentPageSize] = React.useState(10);

  const listApiCall = () => {
    setLoading(true);
    getStudFarmList({
      search: searchText,
      searchByNumber: currentNum,
      searchByChar: currentChar,
      page: currentPage,
      limit: currentPageSize,
    })
      .then((response: any) => {
        setStudFarmList(response?.component?.listing);
        setRecordsTotal(response?.component?.recordsFiltered);
        setCurrentPage(response?.component?.page);
      })
      .catch(() => {})
      .finally(() => {
        setLoading(false);
      });
  };

  // useEffect(() => {
  //   listApiCall();
  // }, []);

  useEffect(() => {
    if (searchText != null) {
      const searchTimeOut = setTimeout(() => {
        if (currentPage === 1) {
          listApiCall();
        }
        else {
          setCurrentPage(1)
        }
      }, 500);
      return () => clearTimeout(searchTimeOut);
    }
  }, [searchText]);

  useEffect(() => {
      listApiCall();
  }, [currentChar, currentNum , currentPage , currentPageSize]);

 

  
  // useEffect(() => {
  //   if (currentNum !== "ALL"  ) {
  //     setCurrentChar("")
  //   }
  // }, [currentNum])

  // useEffect(() => {
  //   if (currentChar !== "ALL") {
  //     setCurrentNum("")
  //   }
  // }, [currentChar])

  return (
    <div className="farm-directory-listing">
      <div className="page-content">
        <div className="transparent-header">
          <SearchBox
            placeholder={t("Search...")}
            onChange={(e: any) => setSearchText(e.target.value)}
            value={searchText}
          />
          {/* <div className="btn-wrapper f">
          <Filter />
        </div> */}
        </div>
        <ul className="alphabetic-filter">
          <li
            className={`tm ${currentChar || currentNum ? "" : "active"}`}
            onClick={() => {
              setCurrentChar("");
              setCurrentNum("");
              setCurrentPage(1);              
            }}
          >
           {t("All")}
          </li>
          <li
            className={`tm ${currentNum ? "active" : ""}`}
            onClick={() => {
              setCurrentChar("");
              setCurrentNum("1");
              setCurrentPage(1);
            }}
          >
            #-9
          </li>
          {alphabet.split("").map((letter) => (
            <li
              className={`tm ${
                letter.toLocaleLowerCase() === currentChar ? "active" : ""
              }`}
              key={letter}
              onClick={() => {
                setCurrentChar(letter.toLocaleLowerCase());
                setCurrentNum("");
                setCurrentPage(1);
              }}
            >
              {letter}
            </li>
          ))}
        </ul>
        <div className="fdl-content">
          <Row>
            {studFarmList.map((items: StudFarm, index: number) => (
              <Col
                md={12}
                lg={6}
                xxl={4}
                key={index + "stud-farm" + "blog-listing-data"}
              >
                <div className="fdl-box">
                  <Link to={
                        FARM_DIRECTORY_DETAILS +
                        "/" +
                        encrDecrStr(items.id, "encr")
                      } className="this-img">
                    <img src={items?.profile_image} alt="" />
                  </Link>
                  <div className="this-content">
                    <Link
                      to={
                        FARM_DIRECTORY_DETAILS +
                        "/" +
                        encrDecrStr(items.id, "encr")
                      }
                      className="tm"
                    >
                      {items?.farm_name}
                    </Link>
                    <p className="bl">{items?.farm_city}</p>
                    <span className="bl">{items?.phone}</span>
                    <span className="bl">{items?.farm_email}</span>
                    <span className="bl">{items?.farm_manager_name}</span>
                    <span className="bl">
                      {items?.totalHorse} {t("horses")}
                    </span>
                  </div>
                </div>
              </Col>
            ))}
            <Col xs={12}>
              {studFarmList?.length ? (
                <Pagination
                  results={recordsTotal}
                  currentPage={currentPage}
                  setCurrentPage={setCurrentPage}
                  currentLength={currentPageSize}
                  setCurrentPageSize={setCurrentPageSize}
                />
              ) : (
                <NoDataFound />
              )}
            </Col>
          </Row>
        </div>
      </div>
    </div>
  );
}
