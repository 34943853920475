import React from "react";
import pencilIcon from "../../../Assets/img/pencil.svg";
import lockIcon from "../../../Assets/img/lock.svg";
import unlockIcon from "../../../Assets/img/publicIcon.svg";
import binIcon from "../../../Assets/img/bin.svg";
import { PICTUREVIDEOTYPE } from "../../../Constant/General";
import { t } from "i18next";

export default function SinglePicture(props: any) {
  const { items } = props;
  return (
    <div className="p-row">
      <div className="p-col p-col-1">
        <img src={items?.image_video} alt="" />
      </div>
      <div className="p-col p-col-2">
        <div className="pv-content">
          <div className="info-table">
            <table>
              <tbody>
                <tr>
                  <td>
                    <p className="tm">{t("Date")}</p>
                  </td>
                  <td>
                    <p className="tm blur-color">{items?.date}</p>
                  </td>
                </tr>
                <tr>
                  <td>
                    <p className="tm">{t("Title")}</p>
                  </td>
                  <td>
                    <p className="tm blur-color">{items?.title}</p>
                  </td>
                </tr>
                <tr>
                  <td>
                    <p className="tm">{t("Description")}</p>
                  </td>
                  <td>
                    <p className="tm blur-color">{items?.description}</p>
                  </td>
                </tr>
                <tr>
                  <td>
                    <p className="tm">{t("Photographer")}</p>
                  </td>
                  <td>
                    <p className="tm blur-color">{items?.photographer}</p>
                  </td>
                </tr>
                <tr>
                  <td>
                    <p className="tm">{t("Private")}</p>
                  </td>
                  <td>
                    <p className="tm blur-color">{items?.private}</p>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
          <div className="icon-wrapper">
            <a>
              <img
                src={pencilIcon}
                alt=""
                onClick={() =>
                  props.editHandler(items?.id, PICTUREVIDEOTYPE.PICTURE)
                }
              />
            </a>
            <a>
              <img
                src={binIcon}
                onClick={() =>
                  props.deleteNow(items?.id, PICTUREVIDEOTYPE.PICTURE)
                }
                alt=""
              />
            </a>
            <a onClick={() => {
                props.changePrivacy(items?.id, PICTUREVIDEOTYPE.PICTURE , !parseInt(items?.private_key) )
              }}>
              <img src={parseInt(items?.private_key) ? unlockIcon : lockIcon }  alt=""  />
            </a>
          </div>
        </div>
      </div>
    </div>
  );
}
