import { t } from "i18next";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { Col, Row } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useOutletContext, useParams } from "react-router-dom";
import { ERROR } from "../../../Constant/General";
import { useLoading } from "../../../context/LoadingContext";
import { horseInfoAction } from "../../../Redux/Horse/HorseAction";
import {
  getBreedigServiceDetails,
  getBreedingMeta,
  viewBreedingControl,
  viewBreedingFlush,
  viewBreedingFoal,
  viewEmbryoTransfer,
} from "../../../Redux/NonRedux/breeding";
import { encrDecrStr, showToast } from "../../../Utility/General";
import AddBreedingControlPopup from "../../Popups/AddBreedingControlPopup/AddBreedingControlPopup";
import AddFlushPopup from "../../Popups/AddFlushPopup/AddFlushPopup";
import AddFoalPopup from "../../Popups/AddFoalPopup/AddFoalPopup";
import AddTransferPopup from "../../Popups/AddTransferPopup/AddTransferPopup";
import ControllList from "./ControllList";
import EmbryoTransferDetails from "./EmbryoTransferDetails";
import FlushList from "./FlushList";
import FoalDetails from "./FoalDetails";

export default function BreedingServiceDetailsMarePage() {
  const { setLoading } = useLoading();
  const [serviceDetails, setServiceDetails] = useState<any>({});
  const [refreshList, setRefreshList] = useState(false);
  const [serviceId, setServiceId] = useState<string>();
  const query = new URLSearchParams(window.location.search);
  const [AddBreedingControlModalShow, setAddBreedingControlModalShow] =
    useState(false);
  const [AddFlushModalShow, setAddFlushModalShow] = useState(false);
  const [AddTransferModalShow, setAddTransferModalShow] = useState(false);
  const [AddFoalModalShow, setAddFoalModalShow] = useState(false);
  const [metaData, setMetaData] = useState<any>([]);
  const [refreshMeta, setRefreshMeta] = useState(false);
  const [horseId, setHorseId] = useState<any>(0);
  const [isEmbryoTransferCase, setIsEmbryoTransferCase] =
    useState<boolean>(false);
  const [breedingServiceDate, setBreedingServiceDate] = useState<any>();
  const dispatch = useDispatch();
  
  const [edit, setEdit] = useState<any>({
    controlEdit: false,
    flushEdit: false,
    foalEdit: false,
    embryTransferEdit: false,
  });

  const [editData, setEditData] = useState<any>({
    foalData: {},
    embryTransferData: {},
    flushData: {},
    controlData: {},
  });

  let location: any = useLocation();
  const param = useParams();

  const horseInfo = useSelector(
    (state: any) => state?.horseInfoReducer?.data?.component
  );

  const getMeta = () => {
    setLoading(true);
    let horse_id = encrDecrStr(param?.slug, "decr");
    let params = {
      horse_id: horse_id,
    };
    getBreedingMeta(params)
      .then((res: any) => {
        setMetaData(res?.component);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  useEffect(() => {
    if (refreshMeta) {
      getMeta();
      setRefreshMeta(false);
    }
  }, [refreshMeta]);

  useEffect(() => {
    getMeta();
    let id = encrDecrStr(param?.slug, "decr");
    setHorseId(id);
    listApiCall();
  }, []);

  const listApiCall = () => {
    let service_id = encrDecrStr(query.get("service_id"), "decr");
    let params = {
      service_id: service_id,
    };
    getBreedigServiceDetails(params)
      .then((res: any) => {
        setServiceDetails(res?.component);
        setBreedingServiceDate(
          moment(
            res?.component?.serviceData?.breading_date,
            "DD/MM/YYYY"
          ).format("YYYY-MM-DD")
        );
        setIsEmbryoTransferCase(
          res?.component?.serviceData?.only_embryo_status
        );
      })
      .finally(() => {
        setLoading(false);
      });
  };

  useEffect(() => {
    let id = encrDecrStr(query.get("service_id"), "decr");
    setServiceId(id);
  }, [location]);

  useEffect(() => {
    if (refreshList) {
      listApiCall();
      horseInfoAction({ id: horseId });
     
      setRefreshList(false);
    }
  }, [refreshList]);

  const foalEditHandler = (id: any) => {
    setLoading(true);
    viewBreedingFoal({ id: id })
      .then((res: any) => {
        setEdit({
          ...edit,
          foalEdit: true,
        });
        setAddFoalModalShow(true);
        setEditData({
          ...editData,
          foalData: res?.component,
        });
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const embryoEditHandler = (id: any) => {
    setLoading(true);
    viewEmbryoTransfer({ id: id })
      .then((res: any) => {
        setEdit({
          ...edit,
          embryTransferEdit: true,
        });
        setEditData({ ...edit, embryTransferData: res?.component });
        setAddTransferModalShow(true);
      })
      .finally(() => {
        setLoading(false);
      });
  };
  const flushEditHandler = (id: any) => {
    setLoading(true);
    viewBreedingFlush({ id: id })
      .then((res: any) => {
        setEdit({
          ...edit,
          flushEdit: true,
        });
        setEditData({ ...edit, flushData: res?.component });
        setAddFlushModalShow(true);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const controlEditHandler = (id: any) => {
    setLoading(true);
    viewBreedingControl({ id: id })
      .then((res: any) => {
        setEdit({
          ...edit,
          controlEdit: true,
        });
        setEditData({ ...edit, controlData: res?.component });
        setAddBreedingControlModalShow(true);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const handleAddBreedingControl = () => {
    setEdit({
      ...edit,
      controlEdit: false,
    });
    setEditData({ ...edit, controlData: {} });
    setAddBreedingControlModalShow(true);
  };
  return (
    <>
      <div className="breeding-control-mare-page">
        <div className="bcm-header">
          <p className="tl">{t("Breeding Details")}</p>
          <div className="bcm-data">
            <Row>
              <Col xs={6} sm={6} md={3}>
                <div className="bcm-data-box">
                  <p className="tm">{t("Breeding Date")}</p>
                  <span className="bl blur-color">
                    {serviceDetails?.serviceData?.breading_date}
                  </span>
                </div>
              </Col>
              <Col xs={6} sm={6} md={3}>
                <div className="bcm-data-box">
                  <p className="tm">{t("Stallion")}</p>
                  <span className="bl blur-color">
                    {serviceDetails?.serviceData?.stallion}
                  </span>
                </div>
              </Col>
              <Col xs={6} sm={6} md={3}>
                <div className="bcm-data-box">
                  <p className="tm">{t("Status")}</p>
                  <span className="bl blur-color">
                    {serviceDetails?.serviceData?.status_value}
                  </span>
                </div>
              </Col>
              <Col xs={6} sm={6} md={3}>
                <div className="bcm-data-box">
                  <p className="tm">
                    {serviceDetails?.getFoal?.birthdate ? "" : t("Expected")}{" "}
                    {t("Foaling Date")}
                  </p>
                  <span className="bl blur-color">
                    {serviceDetails?.getFoal?.birthdate
                      ? serviceDetails?.getFoal?.birthdate
                      : serviceDetails?.serviceData?.expected_foaling_date}
                  </span>
                </div>
              </Col>
            </Row>
          </div>
        </div>
        {(serviceDetails?.getFoal &&
          Object.keys(serviceDetails?.getFoal)?.length ) ?  (
            <FoalDetails
              listData={serviceDetails?.getFoal}
              editHandler={foalEditHandler}
              metaData={metaData}
              serviceId={serviceId}
            />
          ) : null}
        { (serviceDetails?.embryoTransfer &&
        Object.keys(serviceDetails?.embryoTransfer)?.length ) ? (
          <EmbryoTransferDetails
            listData={serviceDetails?.embryoTransfer}
            setRefreshList={() => {
              setRefreshList(true);
              setRefreshMeta(true);
            }}
            editHandler={embryoEditHandler}
            metaData={metaData}
            serviceId={serviceId}
          />
        ) : null}
        {serviceDetails?.controllFlush?.length ? (
          <FlushList
            listData={serviceDetails?.controllFlush}
            setRefreshList={() => {
              setRefreshList(true);
              setRefreshMeta(true);
            }}
            editHandler={flushEditHandler}
            metaData={metaData}
            serviceId={serviceId}
          />
        ) : null}

        <ControllList
          canAddControll={serviceDetails?.controllAdd}
          listData={serviceDetails?.controllList}
          canEditService={serviceDetails?.serviceData?.can_edit_controll}
          setRefreshList={() => {
            setRefreshList(true);
            setRefreshMeta(true);
          }}
          metaData={metaData}
          serviceId={serviceId}
          editHandler={controlEditHandler}
          handleAddBreedingControl={handleAddBreedingControl}
        />
      </div>

      <AddBreedingControlPopup
        show={AddBreedingControlModalShow}
        onHide={() => setAddBreedingControlModalShow(false)}
        isedit={edit.controlEdit}
        setRefreshList={() => {
          setRefreshList(true);
          setRefreshMeta(true);
        }}
        breedingServiceDate={breedingServiceDate}
        editData={editData.controlData}
        serviceId={serviceId}
        metaData={metaData}
        isEmbryoTransferCase={isEmbryoTransferCase}
      />

      <AddFlushPopup
        show={AddFlushModalShow}
        breedingServiceDate={breedingServiceDate}
        onHide={() => setAddFlushModalShow(false)}
        isedit={edit.flushEdit}
        setRefreshList={() => {
          setRefreshMeta(true);
          setRefreshList(true);
        }}
        editData={editData.flushData}
        metaData={metaData}
        serviceId={serviceId}
      />

      <AddTransferPopup
        show={AddTransferModalShow}
        breedingServiceDate={breedingServiceDate}
        onHide={() => setAddTransferModalShow(false)}
        isedit={edit.transferEdit}
        setRefreshList={() => {
          setRefreshMeta(true);
        }}
        editData={editData.embryTransferData}
        metaData={metaData}
        serviceId={serviceId}
      />

      {AddFoalModalShow ? (
        <AddFoalPopup
          show={AddFoalModalShow}
          breedingServiceDate={breedingServiceDate}
          onHide={() => setAddFoalModalShow(false)}
          isedit={edit.foalEdit}
          setRefreshList={() => {
            setRefreshList(true);
            setRefreshMeta(true);
          }}
          horseId={horseId}
          editData={edit.foalData}
          metaData={metaData}
          serviceId={serviceId}
          sire={horseInfo?.name}
          dam={serviceDetails?.serviceData?.stallion}
        />
      ) : null}
    </>
  );
}
