import type { DatePickerProps } from "antd";
import { t } from "i18next";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import ShareIcon from "../../../Assets/img/Common-icon/ShareIcon";
import MinusIcon from "../../../Assets/img/minus-circle.svg";
import PlusIcon from "../../../Assets/img/plus-circle.svg";
import { END_POINTS } from "../../../Constant/Api";
import { INVOICING_INVOICE_LIST } from "../../../Constant/Route";
import { useLoading } from "../../../context/LoadingContext";
import { InvoiceTotals } from "../../../interface/invoice";
import { getInstantContact } from "../../../Redux/globallActions";
import { getListMetaDataApi } from "../../../Redux/NonRedux/general";
import {
  invoiceAddManualy,
  invoiceHorseList,
} from "../../../Redux/NonRedux/invoice";
import {
  calculatePercent,
  caluculateTotal,
  getSessionItem,
} from "../../../Utility/General";
import validate from "../../../Utility/Validation";
import CustomeButton from "../../Common/CustomButton/CustomButton";
import Datepicker from "../../Common/Datepicker/Datepicker";
import InputGroups from "../../Common/InputGroups/InputGroups";
import SelectGropus from "../../Common/SelectGroups/SelectGroups";
import SquareButton from "../../Common/SquareButton/SquareButton";
import TextareaGroups from "../../Common/TextareaGroups/TextareaGroups";
import TextButton from "../../Common/TextButton/TextButton";

export default function ManualInvoicePage() {
  const INITIAL_ITEMS: any = [
    {
      id: {
        value: "",
      },
      horse_id: {
        value: "",
        validation: ["required"],
        errors: [t("Horse is required.")],
      },
      tax: {
        value: "0",
        validation: [""],
        errors: [t("")],
      },
      qty: {
        value: "",
        validation: ["required"],
        errors: [t("Qty is required.")],
      },
      item_description: {
        value: "",
        validation: [""],
        errors: [t("")],
      },
      total: {
        value: "0",
        validation: ["required"],
        errors: [t("Total is required.")],
      },
      unit_price: {
        value: "",
        validation: ["required"],
        errors: [t("Unit Price is required.")],
      },
    },
  ];

  const INTIAL_DATA = {
    owner_id: {
      value: "",
    },
    invoice_date: {
      value: moment().format("YYYY-MM-DD HH:mm"),
      validation: ["required"],
      errors: [t("Invoice date is required.")],
    },
    due_date: {
      value: moment().format("YYYY-MM-DD HH:mm"),
      validation: ["required"],
      errors: [t("Due Date is required.")],
    },
    payment_instruction: {
      value: "",
      validation: [""],
      errors: [t("")],
    },
    items: [...INITIAL_ITEMS],
  };

  const invoiceDateChange: DatePickerProps["onChange"] = (date, dateString) => {
    let dateIs: any = date?.format("YYYY-MM-DD");
    setData("invoice_date", dateIs);
  };

  const dueDateChange: DatePickerProps["onChange"] = (date, dateString) => {
    let dateIs: any = date?.format("YYYY-MM-DD");
    setData("due_date", dateIs);
  };

  const [formData, setFormData] = useState<any>(INTIAL_DATA);
  const { setLoading } = useLoading();
  const [formDataError, setFormDataError] = useState<any>({});
  const [isFormValidated, setIsFormValidated] = useState(false);
  const [horseList, setHorseList] = useState([]);
  const [customerList, setCustomerList] = useState([]);
  const [totals, setTotals] = useState<InvoiceTotals>({});
  let navigate = useNavigate();
  const [metaData, setMetaData] = useState<any>({});
  const prefrence = useSelector(
    (state: any) => state?.masterReducer?.data?.component?.preferance
  );

  const getMetaData = () => {
    setLoading(true);
    getListMetaDataApi(END_POINTS.metaInvoices)
      .then((response: any) => {
        setMetaData(response?.component);
        setHorseList(response?.component?.horse_list);
      })
      .catch((error: any) => {})
      .finally(() => {
        setLoading(false);
      });
  };

  useEffect(() => {
    getMetaData();
  }, []);

  const ownerChange = (val: string) => {
    invoiceHorseList({ owner_id: val })
      .then((res: any) => {
        setHorseList(res?.component);
      })
      .finally(() => {});
  };

  const setData = (field: string, value: any) => {
    const tempOb = Object.assign({}, formData);
    tempOb[field].value = value;
    setFormData({ ...tempOb });
  };

  const handleChange = (index: number, e: any, fieldName: string) => {
    let invoiceItems: any = Object.assign([], formData?.items);
    invoiceItems[index][fieldName].value = e.target.value;
    if (fieldName == "qty" || fieldName == "tax" || fieldName == "unit_price") {
      onPricingChange(invoiceItems);
    }
    setFormData({ ...formData, items: invoiceItems });
  };

  const addInvoiceItem = () => {
    let invoiceItems = Object.assign([], formData?.items);
    invoiceItems.push(INITIAL_ITEMS[0]);
    setFormData({ ...formData, items: invoiceItems });
  };

  const removeInvoiceItem = (index: number) => {
    let invoiceItems = Object.assign([], formData?.items);
    if (invoiceItems.length <= 1) {
      return;
    }
    invoiceItems.splice(index, 1);
    onPricingChange(invoiceItems);
    setFormData({ ...formData, items: invoiceItems });
  };

  useEffect(() => {
    setFormDataError({ ...validate(formData).errors });
  }, [formData]);

  const handleSubmit = () => {
    if (validate(formData).isValidated === true) {
      let items: any = [];
      formData?.items.map((item: any, index: number) => {
        let ob: any = {};
        Object.keys(item).forEach((key) => {
          ob[key] = item[key].value;
        });
        items.push(ob);
      });

      let params: any = {
        owner_id: formData?.owner_id?.value,
        invoice_date: formData?.invoice_date?.value,
        due_date: formData?.due_date?.value,
        payment_instruction: formData?.payment_instruction?.value,
        items,
      };

      setLoading(true);
      invoiceAddManualy(params)
        .then((res: any) => {
          navigate(INVOICING_INVOICE_LIST);
        })
        .finally(() => {
          setLoading(false);
        });
    } else {
      setIsFormValidated(true);
      setFormDataError({ ...validate(formData)?.errors });
    }
  };

  const onPricingChange = (invoiceItems: any) => {
    // let tempInvoiceOb: any = Object.assign([], invoiceItems);
    invoiceItems?.map((item: any) => {
      let unit_price = item["unit_price"].value || 0;
      let qty = item["qty"].value || 0;
      let amount = unit_price * qty;
      item["total"].value =
        amount + calculatePercent(amount, item["tax"]?.value || 0);
    });

    let total = caluculateTotal(
      invoiceItems?.map((item: any) => {
        return {
          qty: item?.qty?.value || 0,
          unit_price: item?.unit_price?.value || 0,
          tax: item?.tax?.value || 0,
        };
      })
    );
    setTotals(total);
  };
  const handleCancel = () => {
    navigate(INVOICING_INVOICE_LIST);
  };

  return (
    <>
      <div className="manual-invoice-page">
        <div className="this-title">
          <p className="tl">{t("Manual Invoice")}</p>
          <p className="tm">{prefrence?.farm_manager_name}</p>
          <span className="bl blur-color">
            {prefrence?.farm_name} - {prefrence?.farm_manager_phone}
          </span>
        </div>
        <div className="date-and-input-filter">
          <SelectGropus
            label={`${t("Customer")}*`}
            dropKey="id"
            dropVal="name"
            value={metaData?.owner_list}
            defaultSelect={formData?.owner_id?.value}
            onChange={(e: any) => {
              setData("owner_id", e.target.value);
              ownerChange(e.target.value);
            }}
          />
          <Datepicker
            label={`${t("Invoice Date")}*`}
            onChange={invoiceDateChange}
            value={moment(formData?.invoice_date?.value)}
            error={
              isFormValidated && formDataError["invoice_date"]
                ? formDataError["invoice_date"]
                : null
            }
          />
          <Datepicker
            label={`${t("Due Date")}*`}
            startDate={moment(formData?.invoice_date?.value).format(
              "YYYY-MM-DD"
            )}
            onChange={dueDateChange}
            value={moment(formData?.due_date?.value)}
            error={
              isFormValidated && formDataError["due_date"]
                ? formDataError["due_date"]
                : null
            }
          />
        </div>
        {formData?.owner_id?.value && (
          <>
            <div className="data-tbl-wrapper">
              <div className="data-tbl">
                <table>
                  <thead>
                    <tr>
                      <th>
                        <p className="tm">{t("Horse")}</p>
                      </th>
                      <th>
                        <p className="tm">{t("Item Description")}</p>
                      </th>
                      <th>
                        <p className="tm text-center">{t("Quantity")}</p>
                      </th>
                      <th>
                        <p className="tm text-right">{t("Unit Price")}</p>
                      </th>
                      <th>
                        <p className="tm">{t("Tax")}</p>
                      </th>
                      <th>
                        <p className="tm text-right">{t("Total")}</p>
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {formData?.items?.map((item: any, index: number) => {
                      return (
                        <React.Fragment key={index + "manual"}>
                          <tr>
                            <td>
                              <select
                                className="tax-select"
                                 aria-placeholder={t("Item Description")}
                                value={item?.horse_id?.value}
                                onChange={(e: any) =>
                                  handleChange(index, e, "horse_id")
                                }
                              >
                                <option>{t("Select")}</option>
                                {horseList?.map((list: any) => {
                                  return (
                                    <option value={list?.id} key={list?.id}>
                                      {list?.name}{" "}
                                    </option>
                                  );
                                })}
                              </select>
                              <p className="error">
                                {isFormValidated &&
                                formDataError["items"]?.[index]?.["horse_id"]
                                  ? formDataError["items"]?.[index]?.[
                                      "horse_id"
                                    ]
                                  : null}
                              </p>
                            </td>
                            <td>
                              <input
                                placeholder={t("Item Description")}
                                value={item?.item_description?.value}
                                onChange={(e: any) =>
                                  handleChange(index, e, "item_description")
                                }
                              />
                              <p className="error">
                                {isFormValidated &&
                                formDataError["items"]?.[index]?.[
                                  "item_description"
                                ]
                                  ? formDataError["items"]?.[index]?.[
                                      "item_description"
                                    ]
                                  : null}
                              </p>
                            </td>
                            <td>
                              <input
                                placeholder={t("0")}
                                className="text-center"
                                type="number"
                                value={item?.qty?.value}
                                onChange={(e: any) =>
                                  handleChange(index, e, "qty")
                                }
                              />
                              <p className="error text-center">
                                {isFormValidated &&
                                formDataError["items"]?.[index]?.["qty"]
                                  ? formDataError["items"]?.[index]?.["qty"]
                                  : null}
                              </p>
                            </td>
                            <td>
                              <div className="last-currency">
                                <input
                                  placeholder={`0.00`}
                                  type="number"
                                  className="text-right"
                                  value={item?.unit_price?.value}
                                  onChange={(e: any) =>
                                    handleChange(index, e, "unit_price")
                                  }
                                />
                                <p className="bl">
                                  {prefrence?.currency_value}
                                </p>
                              </div>
                              <p className="error text-right">
                                {isFormValidated &&
                                formDataError["items"]?.[index]?.["unit_price"]
                                  ? formDataError["items"]?.[index]?.[
                                      "unit_price"
                                    ]
                                  : null}
                              </p>
                            </td>
                            <td>
                              <select
                                className="tax-select"
                                value={item?.tax?.value}
                                onChange={(e: any) =>
                                  handleChange(index, e, "tax")
                                }
                              >
                                <option value="0">{t("No Tax")}</option>
                                <option value="0">{t("No Tax")}</option>
                                {/* <option value="3">3%</option> */}
                              </select>
                            </td>
                            <td>
                              <p className="bl text-right">
                                {item?.total?.value} {prefrence?.currency_value}{" "}
                              </p>
                            </td>
                            {formData?.items?.length > 1 ? (
                              <>
                                <div>
                                  <span className="remove-btn">
                                    <img
                                      src={MinusIcon}
                                      alt=""
                                      onClick={() => removeInvoiceItem(index)}
                                    />
                                  </span>
                                </div>
                              </>
                            ) : null}
                          </tr>
                        </React.Fragment>
                      );
                    })}
                  </tbody>
                </table>
              </div>
            </div>
            <div className="add-row-btn" onClick={addInvoiceItem}>
              <img src={PlusIcon} alt="" />
              <p className="bm">{t("Add Product")}</p>
            </div>
            <hr />
            <div className="invoice-footer">
              <TextareaGroups
                label={`${t("Payment Instructions")}`}
                value={formData?.payment_instruction?.value}
                onChange={(e: any) =>
                  setData("payment_instruction", e.target.value)
                }
              />
              <div className="table-and-btn">
                <table>
                  <tbody>
                    <tr>
                      <td>
                        <p className="bl">{t("Subtotal")}</p>
                      </td>
                      <td>
                        <p className="tm">
                          {totals?.subTotal ? totals?.subTotal : "0.00"}{" "}
                          {prefrence?.currency_value}
                        </p>
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <p className="bl">{t("Taxes")}</p>
                      </td>
                      <td>
                        <p className="tm">
                          {totals?.tax ? totals?.tax : "0.00"}{" "}
                          {prefrence?.currency_value}
                        </p>
                      </td>
                    </tr>
                  </tbody>
                  <tfoot>
                    <tr>
                      <td>
                        <p className="tm">{t("Grand Total")}</p>
                      </td>
                      <td>
                        <p className="tm">
                          {" "}
                          {totals?.total ? totals?.total : "0.00 "}{" "}
                          {prefrence?.currency_value}
                        </p>
                      </td>
                    </tr>
                  </tfoot>
                </table>
                <div className="table-btns">
                  <TextButton onClick={handleCancel}> {t("Cancel")}</TextButton>
                  <CustomeButton bg="fill" onClick={handleSubmit}>
                    {t("Save Invoice")}
                  </CustomeButton>
                  {/* <SquareButton>
                    <ShareIcon />
                  </SquareButton> */}
                </div>
              </div>
            </div>
          </>
        )}
      </div>
    </>
  );
}
