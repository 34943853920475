import React, { useEffect, useState } from "react";
import { Outlet, useLocation } from "react-router-dom";
import Header from "../Component/Header/Header";
import Sidebar from "../Component/Sidebar/Sidebar";
import { IMPORTDATA } from "../Constant/Route";

const MainRoutes = () => {
  const [windowSize, setWindowSize] = useState(window.innerWidth);
  useEffect(() => {
    window.addEventListener("load", (event: any) => {
      setWindowSize(event?.currentTarget?.innerWidth);
    });
    window.addEventListener("resize", (event: any) => {
      setWindowSize(event?.currentTarget?.innerWidth);
    });
    return () => {
      window.removeEventListener("load", () => {});
      window.removeEventListener("resize", () => {});
    };
  }, [windowSize]);

  const location:any = useLocation();

  const [sidebarToggle, setSidebarToggle] = useState(false);

  return (
    <>
      <div className={`${sidebarToggle ? "collapsed" : ""}`}>
        <Sidebar
          setSidebarToggle={setSidebarToggle}
          sidebarToggle={sidebarToggle}
        />
        <div className={`content-wrapper ${ location.pathname == IMPORTDATA ? "import-data-wrapper" : ""}`}>
          <Header
            setSidebarToggle={setSidebarToggle}
            sidebarToggle={sidebarToggle}
          />
          <Outlet />
        </div>
      </div>
    </>
  );
};

export default MainRoutes;
