import { t } from "i18next";
import React, { useState, useEffect, useContext, useLayoutEffect } from "react";
import { Col, Row } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import FbIcon from "../../../Assets/img/facebook.svg";
import GlobalIcon from "../../../Assets/img/globe-2.svg";
import InstIcon from "../../../Assets/img/instagram.svg";
import UserPrimaryIcon from "../../../Assets/img/user-primary.svg";
import { useLoading } from "../../../context/LoadingContext";
import { SidebarContext } from "../../../context/SideBarContext";
import {
  countryListAction,
  masterAction,
} from "../../../Redux/General/GeneralAction";
import {
  getAccountDetails,
  updateAccountDetails,
} from "../../../Redux/NonRedux/account";
import {
  changeUserProfile,
  getFileNamefromLink,
  getFileNamefromPath,
  isFileValidated,
} from "../../../Utility/General";
import validate from "../../../Utility/Validation";
import CustomeButton from "../../Common/CustomButton/CustomButton";
import FileUpload from "../../Common/FileUpload/FileUpload";
import InputGroups from "../../Common/InputGroups/InputGroups";
import InputIconGroups from "../../Common/InputIconGroups/InputIconGroups";
import SelectGropus from "../../Common/SelectGroups/SelectGroups";
import TextareaGroups from "../../Common/TextareaGroups/TextareaGroups";
export default function MyAccountPage() {
  const INTIAL_ACCOUNT_DATA: any = {
    first_name: {
      value: "",
      validation: ["required"],
      errors: [t("First Name is required.")],
    },
    last_name: {
      value: "",
      validation: ["required"],
      errors: [t("Last Name is required.")],
    },
    email: {
      value: "",
      validation: ["email"],
      errors: [t("Please enter valid email.")],
    },
    phone: {
      value: "",
      validation: ["required", "mobile_number"],
      errors: [t("Phone Number is required."), t("Incorrect format")],
    },

    farm_name: {
      value: "",
      validation: ["required"],
      errors: [t("Farm Name is required.")],
    },
    farm_manager_name: {
      value: "",
      validation: [""],
      errors: [t("")],
    },
    farm_manager_phone: {
      value: "",
      validation: ["mobile_number"],
      errors: [t("Incorrect format")],
    },
    farm_country: {
      value: "",
      validation: [""],
      errors: [t("")],
    },
    farm_city: {
      value: "",
      validation: [""],
      errors: [t("")],
    },
    farm_area: {
      value: "",
      validation: [""],
      errors: [t("")],
    },
    farm_address: {
      value: "",
      validation: [""],
      errors: [t("")],
    },
    farm_description: {
      value: "",
      validation: [""],
      errors: [t("")],
    },
    Address_gmp: {
      value: "",
      validation: [""],
      errors: [],
    },
    bank_payment_instructions: {
      value: "",
      validation: [""],
      errors: [t("Payment Instruction is required.")],
    },
    smd_instagram: {
      value: "",
      validation: ["instagram_link"],
      errors: [t("Incorrect format")],
    },
    smd_facebook: {
      value: "",
      validation: ["facebook_link"],
      errors: [t("Incorrect format")],
    },
    smd_website: {
      value: "",
      validation: ["website_link"],
      errors: [t("Incorrect format")],
    },
    profile_image: {
      value: "",
      selected_value: "",
      validation: [
        "allowed_formate:['jpg' , 'png' , 'jpeg']",
        "max_file_size:4",
      ],
      errors: [t("")],
      allowed_formate: ["jpg", "png", "jpeg"],
      max_file_size: 4,
    },
    remove_doc: {
      value: "0",
    },
  };

  const [accountDetails, setAccountDetails] = useState(INTIAL_ACCOUNT_DATA);
  const [isFormValidated, setIsFormValidated] = useState(false);
  const [accountError, setAccountError] = useState<any>({});
  const { setLoading } = useLoading();
  const [currentAccountDetails, setCurrentAccountDetails] = useState<any>({});
  const [isDataLoaded, setIsDataLoaded] = useState(false);
  const [submitButtonStatus, setSubmitButtonStatus] = useState<any>(false);
  const { setSidebar }: any = useContext(SidebarContext);
  const [currentSubscription, setCurrentSubscription] = useState<any>("");
  const [profilePicture , setProfilePicture] = useState('');

  const dispatch = useDispatch();

  const countryList = useSelector(
    (state: any) => state?.countryListReducer?.data?.component
  );

  const setData = (field: string, value: any, subField: any = null) => {
    const tempOb = Object.assign({}, accountDetails);
    tempOb[field][subField ? subField : "value"] = value;
    setAccountDetails({ ...tempOb });
  };

  const fileChange = (key: string, e: any) => {
    let file = e.target.files[0];
    let removeKey = "remove_doc";
    if (
      isFileValidated(
        file,
        accountDetails[key].allowed_formate,
        accountDetails[key].max_file_size
      )
    ) {
      let fileName = getFileNamefromPath(e.target.value);
      setProfilePicture(URL.createObjectURL(file))
      setData(key, e.target.files[0]);
      setData(key, fileName, "selected_value");
      setData(removeKey, "1");
    } else {
      e.target.value = "";
    }
  };

  useEffect(() => {
    if (isDataLoaded) {
      let list: any = {};
      let preOb: any = {};
      Object.keys(accountDetails).forEach(function (key) {
        list[key] = accountDetails[key].value;
        preOb[key] = currentAccountDetails[key] || "";
      });
      let preData = JSON.stringify(preOb);
      let currentData = JSON.stringify(list);
      if (preData != currentData) {
        setSubmitButtonStatus(true);
      } else {
        setSubmitButtonStatus(false);
      }
      setAccountError({ ...validate(accountDetails).errors });
    }
  }, [accountDetails]);

  const listApiCall = () => {
    setLoading(true);
    setIsDataLoaded(false);
    getAccountDetails()
      .then((response: any) => {
        setCurrentSubscription(response?.component?.current_plan);
        changeUserProfile(response?.component?.listing);
        setProfilePicture(response?.component?.listing.profile_image)
        let currentOb: any = {};
        Object.keys(INTIAL_ACCOUNT_DATA).forEach((element) => {
          let currentVal = response?.component?.listing[element];
          if (currentVal) {
            setData(element, currentVal);
            currentOb[element] = currentVal;
          }
          if (element === "profile_image" && currentVal != "") {
            let docName = getFileNamefromLink(currentVal);
            setData("profile_image", docName, "selected_value");
          }
          currentOb["remove_doc"] = "0";
        });
        currentOb["farm_country"] =
          response?.component?.listing["farm_country_id"];
        setData(
          "farm_country",
          response?.component?.listing["farm_country_id"]
        );
        setCurrentAccountDetails(currentOb);
        setIsDataLoaded(true);
        setSubmitButtonStatus(false);
      })
      .catch(() => {})
      .finally(() => {
        setLoading(false);
      });
  };

  useEffect(() => {
    setSidebar("");
    countryListAction();
    listApiCall();
  }, []);

  const submitHandler = () => {
    if (validate(accountDetails).isValidated === true) {
      const formData: any = new FormData();
      Object.keys(accountDetails).forEach((element) => {
        formData.append(element, accountDetails[element].value);
      });
      setLoading(true);
      updateAccountDetails(formData)
        .then((response: any) => {
          // setSubmitButtonStatus(false);
          // setDetectChange(false);
          masterAction();
          setIsFormValidated(false);
          setAccountError({});
          listApiCall();
        })
        .catch((error: any) => {})
        .finally(() => {
          setLoading(false);
        });
    } else {
      setAccountError({ ...validate(accountDetails)?.errors });
      setIsFormValidated(true);
    }
  };

  const removeFile = (key: string) => {
    let removeKey = "remove_doc";
    setData(removeKey, "1");
    setProfilePicture("")
    setData(key, "");
    setData(key, "", "selected_value");
  };

  return (
    <div className="my-account-page">
      {/* Page Title Start */}
      <div className="page-title-wrapper">
        <div className="title-first-menu">
          <div className="title-icon-text">
            <img src={UserPrimaryIcon} alt="" />
            <h5>{t("My Account")}</h5>
          </div>
        </div>
      </div>
      {/* Page Title End */}
      <div className="page-content">
        <div className="layout-720">
          <div className="profile-picture-wrapper">
            <p className="tm">{t("Logo")}</p>
           <div className="profile-image-upload-preview">
           <div className="image-upload">
           <FileUpload
              onChange={(e: any) => {
                fileChange("profile_image", e);
              }}
              removeFile={() => removeFile("profile_image")}
              selectedValue={accountDetails?.profile_image?.selected_value}
              value={t("Upload Documents")}
              fileType={t("Use a jpg, jpeg, png, heic")}
            />
           </div>
            {profilePicture ? (
              <img
                src={profilePicture}
                className="account_logo"
                alt="logo"
              />
            ) : null}
           </div>

          </div>
          <div className="title-detail-box">
            <p className="tm">{t("Basic Details")}</p>
            <Row>
              <Col sm={6} md={6}>
                <InputGroups
                  label={`${t("First Name")}*`}
                  type="text"
                  autoComplete="off"
                  value={accountDetails?.first_name?.value}
                  onChange={(e: any) => setData("first_name", e.target.value)}
                  error={
                    isFormValidated && accountError["first_name"]
                      ? accountError["first_name"]
                      : null
                  }
                />
              </Col>
              <Col sm={6} md={6}>
                <InputGroups
                  label={`${t("Last Name")}*`}
                  type="text"
                  autoComplete="off"
                  value={accountDetails?.last_name?.value}
                  onChange={(e: any) => setData("last_name", e.target.value)}
                  error={
                    isFormValidated && accountError["last_name"]
                      ? accountError["last_name"]
                      : null
                  }
                />
              </Col>
              <Col sm={6} md={6}>
                <InputGroups
                  label={`${t("Work Email")}*`}
                  type="text"
                  autoComplete="off"
                  readOnly={true}
                  value={accountDetails?.email?.value}
                  onChange={(e: any) => setData("email", e.target.value)}
                  error={
                    isFormValidated && accountError["email"]
                      ? accountError["email"]
                      : null
                  }
                />
              </Col>
              <Col sm={6} md={6}>
                <InputGroups
                  label={`${t("Phone Number")}*`}
                  type="text"
                  autoComplete="off"
                  value={accountDetails?.phone?.value}
                  onChange={(e: any) => setData("phone", e.target.value)}
                  error={
                    isFormValidated && accountError["phone"]
                      ? accountError["phone"]
                      : null
                  }
                />
              </Col>
            </Row>
          </div>
          <div className="title-detail-box mb-3">
            <p className="tm">{t("Subscription Details")}</p>
            <Col sm={6} md={6}>
              <InputGroups
                label={`${t("Current Subscription")}`}
                type="text"
                readOnly={true}
                value={currentSubscription}
              />
            </Col>
          </div>
          <div className="title-detail-box">
            <p className="tm">{t("Farm Details")}</p>
            <Row>
              <Col sm={6} md={6}>
                <InputGroups
                  label={`${t("Farm Name")}*`}
                  type="text"
                  autoComplete="off"
                  value={accountDetails?.farm_name?.value}
                  onChange={(e: any) => setData("farm_name", e.target.value)}
                  error={
                    isFormValidated && accountError["farm_name"]
                      ? accountError["farm_name"]
                      : null
                  }
                />
              </Col>
            </Row>
            <Row>
              <Col sm={6} md={6}>
                <InputGroups
                  label={`${t("Farm Manager Name")}`}
                  type="text"
                  autoComplete="off"
                  value={accountDetails?.farm_manager_name?.value}
                  onChange={(e: any) =>
                    setData("farm_manager_name", e.target.value)
                  }
                  error={
                    isFormValidated && accountError["farm_manager_name"]
                      ? accountError["farm_manager_name"]
                      : null
                  }
                />
              </Col>
              <Col sm={6} md={6}>
                <InputGroups
                  label={`${t("Farm Manager Contact Number")}`}
                  type="text"
                  autoComplete="off"
                  value={accountDetails?.farm_manager_phone?.value}
                  onChange={(e: any) =>
                    setData("farm_manager_phone", e.target.value)
                  }
                  error={
                    isFormValidated && accountError["farm_manager_phone"]
                      ? accountError["farm_manager_phone"]
                      : null
                  }
                />
              </Col>
              <Col sm={6} md={6}>
                <SelectGropus
                  label={`${t("Country")}`}
                  value={countryList}
                  onChange={(e: any) => setData("farm_country", e.target.value)}
                  dropKey="id"
                  dropVal="country"
                  defaultSelect={accountDetails?.farm_country?.value}
                  error={
                    isFormValidated && accountError["farm_country"]
                      ? accountError["farm_country"]
                      : null
                  }
                />
              </Col>
              <Col sm={6} md={6}>
                <InputGroups
                  label={`${t("City")}`}
                  type="text"
                  autoComplete="off"
                  value={accountDetails?.farm_city?.value}
                  onChange={(e: any) => setData("farm_city", e.target.value)}
                  error={
                    isFormValidated && accountError["farm_city"]
                      ? accountError["farm_city"]
                      : null
                  }
                />
              </Col>
              <Col sm={6} md={6}>
                <InputGroups
                  label={`${t("Area")}`}
                  type="text"
                  autoComplete="off"
                  value={accountDetails?.farm_area?.value}
                  onChange={(e: any) => setData("farm_area", e.target.value)}
                  error={
                    isFormValidated && accountError["farm_area"]
                      ? accountError["farm_area"]
                      : null
                  }
                />
              </Col>
              <Col sm={6} md={6}>
                <InputGroups
                  label={`${t("Address")}`}
                  type="text"
                  autoComplete="off"
                  value={accountDetails?.farm_address?.value}
                  onChange={(e: any) => setData("farm_address", e.target.value)}
                  error={
                    isFormValidated && accountError["farm_address"]
                      ? accountError["farm_address"]
                      : null
                  }
                />
              </Col>
              <Col sm={12} md={12}>
                <TextareaGroups
                  label={`${t("Farm Description")}`}
                  type="text"
                  autoComplete="off"
                  value={accountDetails?.farm_description?.value}
                  onChange={(e: any) =>
                    setData("farm_description", e.target.value)
                  }
                  error={
                    isFormValidated && accountError["farm_description"]
                      ? accountError["farm_description"]
                      : null
                  }
                />
              </Col>
              <Col sm={6} md={6}>
                <InputGroups
                  label={`${t("Address (Google Map Link)")}`}
                  type="text"
                  autoComplete="off"
                  value={accountDetails?.Address_gmp?.value}
                  onChange={(e: any) => setData("Address_gmp", e.target.value)}
                  error={
                    isFormValidated && accountError["Address_gmp"]
                      ? accountError["Address_gmp"]
                      : null
                  }
                />
              </Col>
            </Row>
          </div>
          <div className="title-detail-box">
            <p className="tm">{t("Bank Details for Invoicing")}</p>
            <Row>
              <Col sm={12} md={12}>
                <TextareaGroups
                  label={`${t("Payment Instructions")}`}
                  type="text"
                  autoComplete="off"
                  value={accountDetails?.bank_payment_instructions?.value}
                  onChange={(e: any) =>
                    setData("bank_payment_instructions", e.target.value)
                  }
                  error={
                    isFormValidated && accountError["bank_payment_instructions"]
                      ? accountError["bank_payment_instructions"]
                      : null
                  }
                />
              </Col>
            </Row>
          </div>
          <div className="title-detail-box">
            <p className="tm">{t("Social Media Details")}</p>
            <Row>
              <Col sm={6} md={6}>
                <InputIconGroups
                  icon={InstIcon}
                  label={`${t("Instagram Link")}`}
                  type="text"
                  autoComplete="off"
                  value={accountDetails?.smd_instagram?.value}
                  onChange={(e: any) =>
                    setData("smd_instagram", e.target.value)
                  }
                  error={
                    isFormValidated && accountError["smd_instagram"]
                      ? accountError["smd_instagram"]
                      : null
                  }
                />
              </Col>
              <Col sm={6} md={6}>
                <InputIconGroups
                  icon={FbIcon}
                  label={`${t("Facebook Link")}`}
                  type="text"
                  autoComplete="off"
                  value={accountDetails?.smd_facebook?.value}
                  onChange={(e: any) => setData("smd_facebook", e.target.value)}
                  error={
                    isFormValidated && accountError["smd_facebook"]
                      ? accountError["smd_facebook"]
                      : null
                  }
                />
              </Col>
              <Col sm={6} md={6}>
                <InputIconGroups
                  icon={GlobalIcon}
                  label={`${t("Website Link")}`}
                  type="text"
                  autoComplete="off"
                  value={accountDetails?.smd_website?.value}
                  onChange={(e: any) => setData("smd_website", e.target.value)}
                  error={
                    isFormValidated && accountError["smd_website"]
                      ? accountError["smd_website"]
                      : null
                  }
                />
              </Col>
            </Row>
          </div>
          <div className="footer-btn">
            <CustomeButton
              bg="fill"
              disabled={!submitButtonStatus}
              onClick={submitHandler}
            >
              {t("Save")}
            </CustomeButton>
          </div>
        </div>
      </div>
    </div>
  );
}
