import { globalDownloadAction, globalPostAction } from "../../actions/globalActions";
import { END_POINTS } from "../../Constant/Api";
import { SHOW_MESSAGE } from "../../Constant/General";


export function getInvoiceLineItems(params: any) {
    let apiUrl = END_POINTS.invoiceLineList;
    return globalPostAction(params, apiUrl);
}

export function invoiceLineView(params: any) {
    let apiUrl = END_POINTS.invoiceLineView;
    return globalPostAction(params, apiUrl);
}

export function invoiceLineAdd(params: any) {
    let apiUrl = END_POINTS.invoiceLineAdd;
    return globalPostAction(params, apiUrl);
}

export function invoiceAddManualy(params: any) {
    let apiUrl = END_POINTS.invoiceAddManualy;
    return globalPostAction(params, apiUrl , SHOW_MESSAGE);
}

export function getinvoiceList(params: any) {
    let apiUrl = END_POINTS.invoiceList;
    return globalPostAction(params, apiUrl);
}

export function changeInvoicePayment(params: any) {
    let apiUrl = END_POINTS.changeInvoicePayment;
    return globalPostAction(params, apiUrl , SHOW_MESSAGE);
}

export function invoiceHorseList(params: any = {}) {
    let apiUrl = END_POINTS.invoiceHorseList;
    return globalPostAction(params, apiUrl);
}


export function deleteInvoice(params: any = {}) {
    let apiUrl = END_POINTS.invoiceDelete;
    return globalPostAction(params, apiUrl , SHOW_MESSAGE);
}

export function downloadInvoice(payload: any, fileName: any) {
    let apiUrl = END_POINTS.invoiceDowload;
    return globalDownloadAction(payload, apiUrl, fileName);
}