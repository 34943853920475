import { globalPostAction } from "../../actions/globalActions";
import { END_POINTS } from "../../Constant/Api";


export function getHorseInformation(params: any) {
    let apiUrl = END_POINTS.horseInformation;
    return globalPostAction(params, apiUrl);
}

export function getListMetaDataApi(url : string , params : any = {}) {
    return globalPostAction(params , url);
}