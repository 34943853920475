// import * as localStorageConstant from "Constant/localStorageConstant";
// import { getLangCodeFromUrl } from "utilities";
// import { unregister } from './serviceWorkerRegistration';
import './index.scss';
import { LANGUAGE } from "./Constant/LocalStorage";
import * as serviceWorkerRegistration from "./serviceWorkerRegistration";

// let inputLang = getLangCodeFromUrl();
let inputLang: any = localStorage.getItem(LANGUAGE);


// localStorage.setItem(localStorageConstant.ISUIFLIP, inputLang === "ar" ? "1" : "0");
const direction = inputLang === "ar" ? "rtl" : "ltr";

let render = () => {
  import("./Styles/direction/" + direction + ".scss").then((x) => {
      require("./AppRenderer");
  });
};
// unregister();
render();

serviceWorkerRegistration.register();
