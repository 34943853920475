export const LOGIN_REQUEST = "LOGIN_REQUEST";
export const LOGIN_SUCCESS = "LOGIN_SUCCESS";
export const LOGIN_FAILD = "LOGIN_FAILD";
export const LOGIN_LONG = "LOGIN_LONG";

export const SIGNUP_REQUEST = "SIGNUP_REQUEST";
export const SIGNUP_SUCCESS = "SIGNUP_SUCCESS";
export const SIGNUP_FAILD = "SIGNUP_FAILD";
export const SIGNUP_LONG = "SIGNUP_LONG";

export const CHECK_AUTHORIZATION_REQUEST = "CHECK_AUTHORIZATION_REQUEST";
export const CHECK_AUTHORIZATION_SUCCESS = "CHECK_AUTHORIZATION_SUCCESS";
export const CHECK_AUTHORIZATION_FAILD = "CHECK_AUTHORIZATION_FAILD";

export const FORGOT_PASSWORD_REQUEST = "FORGOT_PASSWORD_REQUEST";
export const FORGOT_PASSWORD_SUCCESS = "FORGOT_PASSWORD_SUCCESS";
export const FORGOT_PASSWORD_FAILD = "FORGOT_PASSWORD_FAILD";
export const FORGOT_PASSWORD_LONG = "FORGOT_PASSWORD_LONG";
export const FORGOT_PASSWORD_CLEAR = "FORGOT_PASSWORD_CLEAR";

export const RESET_PASSWORD_REQUEST = "RESET_PASSWORD_REQUEST";
export const RESET_PASSWORD_SUCCESS = "RESET_PASSWORD_SUCCESS";
export const RESET_PASSWORD_FAILD = "RESET_PASSWORD_FAILD";
export const RESET_PASSWORD_LONG = "RESET_PASSWORD_LONG";
export const RESET_PASSWORD_CLEAR = "RESET_PASSWORD_CLEAR";

export const LOGOUT_SUCCESS = "LOGOUT_SUCCESS";
export const LOGIN_MODAL_SHOW = "LOGOUT_SUCCESS";