import React from "react";

export default function Rular() {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M8.67273 13.1464C11.8057 13.1464 14.3455 11.4369 14.3455 9.32819C14.3455 7.21947 11.8057 5.51001 8.67273 5.51001C5.53977 5.51001 3 7.21947 3 9.32819C3 11.4369 5.53977 13.1464 8.67273 13.1464Z"
        stroke="#919EAB"
        strokeWidth="1.5"
        strokeLinecap="round"
      />
      <path
        d="M8.67272 10.5282C9.69696 10.5282 10.5273 9.99098 10.5273 9.32824C10.5273 8.66549 9.69696 8.12823 8.67272 8.12823C7.64848 8.12823 6.81818 8.66549 6.81818 9.32824C6.81818 9.99098 7.64848 10.5282 8.67272 10.5282Z"
        stroke="#919EAB"
        strokeWidth="1.5"
        strokeLinecap="round"
      />
      <path
        d="M8.67273 13.1464H19.9091C20.5636 13.1464 21 13.5827 21 14.2373V17.4009C21 18.0555 20.5636 18.4918 19.9091 18.4918H9.32727C5.29091 18.4918 3 16.2009 3 14.1282C3 12.0555 3 9.32819 3 9.32819"
        stroke="#919EAB"
        strokeWidth="1.5"
        strokeLinecap="round"
      />
      <path
        d="M9.32727 18.4918V15.8737"
        stroke="#919EAB"
        strokeLinecap="round"
      />
      <path
        d="M12.1636 18.4918V16.9645"
        stroke="#919EAB"
        strokeLinecap="round"
      />
      <path d="M15 18.4918V15.8737" stroke="#919EAB" strokeLinecap="round" />
      <path
        d="M17.8364 18.4918V16.9645"
        stroke="#919EAB"
        strokeLinecap="round"
      />
    </svg>
  );
}
