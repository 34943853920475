import i18n from 'i18next';
import en from './lang/en.json';
import ar from './lang/ar.json';
import { initReactI18next } from 'react-i18next';
import { LANGUAGE } from '../Constant/LocalStorage';

const resources = {
  en:{
    translation:en
  },
  ar:{
    translation:ar
  }
};
let lang = localStorage.getItem(LANGUAGE);

i18n.use(initReactI18next).init({
  lng: lang?lang:'en',
  // ns: ['en', 'ar'],
  interpolation: {
    escapeValue: false, // not needed for react as it escapes by default
  },
  resources,
});

export default i18n;