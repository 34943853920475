import React from "react";
import { Col, Row } from "react-bootstrap";
import { useSelector } from "react-redux";

interface DashboardBox {
  title: string;
  value: number;
  more_less: boolean;
  more_less_value: number;
  more_message: string;
  less_message: string;
  removeMoreLess?: boolean;
}

export default function DashboardBox({
  title,
  value,
  more_less,
  more_less_value,
  more_message,
  less_message,
  removeMoreLess,
}: DashboardBox) {
  //   const prefrence = useSelector(
  //     (state: any) => state?.masterReducer?.data?.component?.preferance
  //   );

  return (
    <Col sm={12} md={6} lg={6} xl={6} xxl={4}>
      <div className="dashboard-box">
        <p className="tl">{title}</p>
        <h2>{value}</h2>
        {!removeMoreLess ? (
          <div className="pos-neg-wrapper">
            <p className={`${more_less ? "green" : "red"} ll`}>
              {" "}
              {`${more_less ? "+" : "-"} ${more_less_value}`}
            </p>
            <p className="tm">{`${more_less ? more_message : less_message}`}</p>
          </div>
        ) : null}
      </div>
    </Col>
  );
}
