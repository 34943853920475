import React from "react";

export default function Breeding() {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M13.5508 15.615C15.5248 13.641 16.0712 10.987 14.7713 9.68703C13.4713 8.38708 10.8173 8.9335 8.84326 10.9075C6.86926 12.8815 6.32283 15.5356 7.62278 16.8355C8.92274 18.1355 11.5768 17.589 13.5508 15.615Z"
        stroke="#919EAB"
        strokeWidth="1.5"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M7.4948 16.9315L6.38521 18.1644C5.27562 19.274 3.54959 19.274 2.44 18.1644"
        stroke="#919EAB"
        strokeWidth="1.5"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M3.4263 12C3.4263 7.06849 7.49479 3 12.4263 3C17.3578 3 21.5496 7.06849 21.5496 12C21.5496 16.9315 17.4811 21 12.5496 21"
        stroke="#919EAB"
        strokeWidth="1.5"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}
