import React from "react";

export default function Management() {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M9.32797 12.4491C8.91092 14.0935 9.95355 15.7379 11.5175 16.0668C13.0814 16.5053 14.6454 15.4091 14.9582 13.7647"
        stroke="#E8E5E2"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M18.0861 8.06402C18.6074 8.94103 18.6074 8.94103 19.1287 9.81805L17.9818 11.2432C18.0861 11.901 18.0861 12.2298 18.1903 12.8876L19.65 13.8742C19.4415 14.8609 19.4415 14.8609 19.233 15.9572L17.4605 16.1764C17.0434 16.7245 16.9392 16.9438 16.5221 17.4919L16.9392 19.3556C16.1051 19.9037 16.1051 19.9037 15.271 20.4519L13.9155 19.246C13.29 19.3556 12.9772 19.3556 12.3516 19.4652L11.4132 21C10.4749 20.7807 10.4749 20.7807 9.43223 20.5615L9.22371 18.6978C8.70239 18.2593 8.49387 18.1497 7.97255 17.7112L6.20008 18.1497C4.84466 16.7245 4.53188 14.6416 4.53188 14.6416C4.21909 13.1069 4.32335 11.5721 4.7404 10.0373C6.09582 4.22707 12.4559 2.80192 13.3942 3.02117L12.5601 5.10409L15.1667 9.16029L13.8113 10.4758L12.2473 8.94103C11.5175 9.37954 10.7877 9.26991 10.1621 8.94103L9.22371 8.3929"
        stroke="#E8E5E2"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}
