import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { Link, useOutletContext } from "react-router-dom";
import AddPlusIcon from "../../../Assets/icon/AddPlusIcon";
import binIcon from "../../../Assets/img/bin.svg";
import FemaleIcon from "../../../Assets/img/Female.svg";
import MaleIcon from "../../../Assets/img/Male.svg";
import pencilIcon from "../../../Assets/img/pencil.svg";
import PregnantIcon from "../../../Assets/img/Pregnant.svg";
import { END_POINTS } from "../../../Constant/Api";
import { DATATABLE } from "../../../Constant/General";
import {
  HORSEDETAILS_HEALTH_VETVISITS,
  HORSEDETAILS_HEALTH_VITALSIGNS,
} from "../../../Constant/Route";
import { useLoading } from "../../../context/LoadingContext";
import { getListMetaDataApi } from "../../../Redux/NonRedux/general";
import {
  destroySingleHealthAction,
  getHealthVetClinic,
  viewSingleHealthAction,
} from "../../../Redux/NonRedux/health";
import { encrDecrStr, getDefaultSorting } from "../../../Utility/General";
import CustomeButton from "../../Common/CustomButton/CustomButton";
import TableHead from "../../Common/DataTable/TableHead/TableHead";
import NoDataFound from "../../Common/NoDataFound/NoDataFound";
import OnlyCk from "../../Common/OnlyCk/OnlyCk";
import SearchBox from "../../Common/SearchBox/SearchBox";
import Filter from "../../Filter/Filter";
import Pagination from "../../Layouts/Pagination/Pagination";
import Loader from "../../Loader/Loader";
import AddVetVisitPopup from "../../Popups/AddVetVisitPopup/AddVetVisitPopup";
import ConfirmPopup from "../../Popups/ConfirmPopup/ConfirmPopup";

export default function VetVisitHealth() {
  const { t, i18n } = useTranslation();
  const defaultSorting: any = getDefaultSorting(
    DATATABLE.HELTH_VET_CLINIC_VISIT
  );
  const tableHead = [
    {
      isOnlyTh: true,
      headClass: "text-right",
    },
    {
      label: t("Horse"),
      className: "tm icon-text",
      key: "horse",
    },
    {
      label: t("Date"),
      className: "tm",
      key: "date",
    },
    {
      label: t("Vet/Clinic"),
      className: "tm ",
      key: "vet_clinic",
    },
    {
      label: t("Comments"),
      className: "tm ",
      key: "comments",
    },
    {
      label: t("Amount"),
      className: "tm ",
      key: "amount",
    },
    {
      label: t("Action"),
      className: "tm",
      key: "",
      headClass: "text-right",
    },
  ];
  const [AddVetVisitModalShow, setAddVetVisitModalShow] = useState(false);

  const [confirmPopupShow, setConfirmPopupShow] = useState(false);
  const [deletableRecords, setDeletableRecords] = useState<any>([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [horseId, setHorseId] = useState<any>();
  const [currentPageSize, setCurrentPageSize] = useState(10);
  const [recordsTotal, setRecordsTotal] = useState(1);
  const [searchText, setSearchText] = useState();
  const [listData, setListData] = useState([]);
  const [checkedRec, setCheckedRec] = useState<number[]>([]);
  const [refreshList, setRefreshList] = useState(false);
  const [isEdit, setIsEdit] = useState<boolean>(false);
  const [editData, setEditData] = useState<any>({});
  const [sortByField, setSortByField] = useState(defaultSorting?.key);
  const [sortType, setSortType] = useState(defaultSorting?.type);
  const [orderList, setOrderList] = useState<any>([]);
  const { setExportParams }: any = useOutletContext();
  const [filterList, setFilterList] = useState<any>([]);
  const [filteredValue, setFilteredValue] = useState<any>({});

  const [metaData, setMetaData] = useState({});

  const prefrence = useSelector(
    (state: any) => state?.masterReducer?.data?.component?.preferance
  );
  const { setLoading } = useLoading();

  const getMetaData = () => {
    setLoading(true);
    getListMetaDataApi(END_POINTS.metaHelthVetVisit, { type: "multiple" })
      .then((response: any) => {
        setMetaData(response?.component);
      })
      .catch((error: any) => {})
      .finally(() => {
        setLoading(false);
      });
  };

  useEffect(() => {
    getMetaData();
  }, []);

  useEffect(() => {
    listAPICall();
  }, [currentPage, currentPageSize, sortByField, sortType, filteredValue]);

  useEffect(() => {
    if (searchText != null) {
      const searchTimeOut = setTimeout(() => {
        if (currentPage === 1) {
          listAPICall();
        } else {
          setCurrentPage(1);
        }
      }, 500);
      return () => clearTimeout(searchTimeOut);
    }
  }, [searchText]);

  useEffect(() => {
    if (refreshList) {
      listAPICall();
      setRefreshList(false);
    }
  }, [refreshList]);

  const listAPICall = () => {
    setLoading(true);
    let params: any = {
      page: currentPage,
      search: searchText,
      limit: currentPageSize,
    };
    if (sortByField && sortType) {
      params["sort"] = {
        column: orderList[sortByField]
          ? orderList[sortByField]
          : defaultSorting?.value,
        type: sortType,
      };
    }
    if (filteredValue && Object.keys(filteredValue)?.length) {
      params["filter"] = filteredValue;
    }
    setExportParams(params);
    getHealthVetClinic(params)
      .then((res: any) => {
        setListData(res?.component?.listing);
        setRecordsTotal(res?.component?.recordsFiltered);
        setOrderList(res?.component?.orderby);
        setFilterList(res?.component?.filterlist);
      })
      .catch((err: any) => {})
      .finally(() => {
        setLoading(false);
      });
  };

  const editHandler = (id: any) => {
    let params = {
      form_type: "clinic",
      id: id,
    };
    setLoading(true);
    viewSingleHealthAction(params)
      .then((res: any) => {
        if (res?.component?.form_type == "clinic") {
          let editDataIs = res?.component;
          setIsEdit(true);
          setEditData(editDataIs);
          setAddVetVisitModalShow(true);
        }
      })
      .catch((err) => {})
      .finally(() => {
        setLoading(false);
      });
  };

  const deleteHandler = () => {
    let params = {
      form_type: "clinic",
      id: deletableRecords,
    };
    setLoading(true);
    destroySingleHealthAction(params)
      .then((res: any) => {
        setRefreshList(true);
        setConfirmPopupShow(false);
      })
      .catch((err: any) => {})
      .finally(() => {
        setLoading(false);
      });
  };

  const deleteNow = (id: any) => {
    setDeletableRecords([...deletableRecords, id]);
    setConfirmPopupShow(true);
  };

  const horseDetailLink = (id: any) => {
    return HORSEDETAILS_HEALTH_VETVISITS + "/" + encrDecrStr(id, "encr");
  };

  const getIconbyType = (type: any) => {
    if (type == "male") {
      return MaleIcon;
    } else if (type == "female") {
      return FemaleIcon;
    } else if (type == "pregnant") {
      return PregnantIcon;
    }
  };

  return (
    <>
      <div className="health-vet-visit-page search-and-btn">
        <div className="data-wrapper">
          <div className="data-header">
            <SearchBox
              value={searchText}
              onChange={(e: any) => setSearchText(e.target.value)}
              placeholder={t("Search Horse...")}
            />

            <div className="right-section">
              <div className="btn-wrapper f-fb">
                <Filter
                  filterList={filterList}
                  setCurrentPage={setCurrentPage}
                  setFilteredValue={setFilteredValue}
                />
                <CustomeButton
                  bg="fill"
                  onClick={() => {
                    setEditData({});
                    setIsEdit(false);
                    setAddVetVisitModalShow(true);
                  }}
                >
                  <AddPlusIcon />
                  {t("Add")}
                </CustomeButton>
              </div>
            </div>
          </div>
          <div className="data-body">
            <div className="img-responsive-table">
              <table>
                <TableHead
                  data={tableHead}
                  orderList={orderList}
                  sortByField={sortByField}
                  sortType={sortType}
                  setSortType={setSortType}
                  setSortByField={setSortByField}
                />
                <tbody>
                  {listData?.map((items: any, index: number) => (
                    <tr key={index + "myhorse-tbl"}>
                      <td className="first-img-wrapper">
                        <img src={items.profile} alt="" className="horse-img" />
                      </td>
                      <td>
                        <div className="icon-text">
                          <img src={getIconbyType(items?.type_slug)} alt="" />
                          <Link
                            to={horseDetailLink(items.horse_id)}
                            className="a-bl"
                          >
                            {items.horse_name}
                          </Link>
                        </div>
                      </td>
                      <td>
                        <p className="bl">{items.dates}</p>
                      </td>
                      <td>
                        <p className="bl">{items.vat_clinic}</p>
                      </td>
                      <td>
                        <p className="bl">{items.comments}</p>
                      </td>
                      <td>
                        <p className="bl">
                          {items.amount} {prefrence?.currency_value}
                        </p>
                      </td>
                      <td>
                        <div className="icon-wrapper">
                          <button
                            onClick={() => {
                              editHandler(items.id);
                              setHorseId(items.horse_id);
                            }}
                          >
                            <img src={pencilIcon} alt="" />
                          </button>
                          <button onClick={() => deleteNow(items.id)}>
                            <img src={binIcon} alt="" />
                          </button>
                        </div>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
            {listData?.length ? (
              <Pagination
                results={recordsTotal}
                currentPage={currentPage}
                setCurrentPage={setCurrentPage}
                currentLength={currentPageSize}
                setCurrentPageSize={setCurrentPageSize}
              />
            ) : (
              <NoDataFound />
            )}
          </div>
        </div>
      </div>
      <AddVetVisitPopup
        show={AddVetVisitModalShow}
        isMultiple={1}
        horseid={isEdit ? horseId : [horseId]}
        isedit={isEdit.toString()}
        healthdata={editData}
        setrefreshlist={() => {
          setRefreshList(true);
          getMetaData();
        }}
        metaData={metaData}
        onHide={() => setAddVetVisitModalShow(false)}
      />
      <ConfirmPopup
        heading={t("Delete")}
        message={t("Are you sure to delete this record?")}
        show={confirmPopupShow}
        onHide={() => {
          setConfirmPopupShow(false);
          setDeletableRecords([]);
        }}
        confirm={deleteHandler}
      />
    </>
  );
}
