import React from "react";
import Button from "@mui/material/Button";

export default function ToggleButton(props: any) {
  return (
    <div className="input-groups">
      {
        props.label ? <label>{props.label}</label> : 
        <div className="info-label">
          {props.children}
        </div>
      }
      <div className="toggle-button-wrapper">
        {props.value.map((items: any, index: number) => (
          <Button
            key={index + "toggle-btn"}
            disabled={props?.disabled}
            className={
            items[props.toggleKey ? props.toggleKey: "value"]   == props.selected ? "fill-btn" : "border-btn"
            }
            onClick={() => props.setSelected(items[props.toggleKey ? props.toggleKey: "value"] )}
            variant={items[props.toggleKey ? props.toggleKey: "value"] == props.selected ? "contained" : "outlined"}
          >
            {items[props.toggleVal ? props.toggleVal: "name"]}
          </Button>
        ))}
      </div>
    </div>
  );
}
