import React from "react";

export default function Tasksicon() {
  return (
    <svg
      width="32"
      height="32"
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M12.9 14.8C14.5568 14.8 15.9 13.4569 15.9 11.8C15.9 10.1431 14.5568 8.8 12.9 8.8C11.2431 8.8 9.89996 10.1431 9.89996 11.8C9.89996 13.4569 11.2431 14.8 12.9 14.8Z"
        stroke="#E8E5E2"
        strokeWidth="1.5"
        strokeMiterlimit="10"
      />
      <path
        d="M9.89996 21.2L12 23.2L16.7 18.6"
        stroke="#E8E5E2"
        strokeWidth="1.5"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M19.7 11.8H22.1"
        stroke="#E8E5E2"
        strokeWidth="1.5"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M19.7 21.2H22.1"
        stroke="#E8E5E2"
        strokeWidth="1.5"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M22 26.9H9.99999C7.99999 26.9 6.29999 25.3 6.29999 23.2V8.80001C6.29999 6.80001 7.89999 5.10001 9.99999 5.10001H22C24 5.10001 25.7 6.70001 25.7 8.80001V23.2C25.7 25.2 24 26.9 22 26.9Z"
        stroke="#E8E5E2"
        strokeWidth="1.5"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}
