import React, { useEffect, useState } from "react";
import Modal from "react-bootstrap/Modal";
import CustomeButton from "../../Common/CustomButton/CustomButton";
import { useNavigate } from "react-router-dom";
import TextButton from "../../Common/TextButton/TextButton";
import { t } from "i18next";
import { useDispatch, useSelector } from "react-redux";
import { upgradePopupAction } from "../../../Redux/General/GeneralAction";

function UpgradePopup(props: any) {
  return (
    <Modal {...props} centered className="subscription-popup modal-416">
      <Modal.Body>
        <p className="tl">{t("Upgrade Subscription")}</p>
        <span className="bl">
          {props?.message ? props?.message : t("upgrade_message")}
        </span>
      </Modal.Body>
      <Modal.Footer>
        <div className="footer-1-btn">
          {/* <TextButton onClick={props.onHide}>{t("Cancel")}</TextButton> */}
          <CustomeButton onClick={props.onHide} bg="fill">
            {t("Okay")}
          </CustomeButton>
        </div>
      </Modal.Footer>
    </Modal>
  );
}
export default function UpgradeSubscriptionPopup(props: any) {
  const { confirm, ...rest } = props;
  const [show, setShow] = useState(false);
  const dispatch = useDispatch();
  const upgradePopupStatus = useSelector(
    (state: any) => state?.upgradePopupReducer?.data
  );
  return (
    <>
      <UpgradePopup
        show={upgradePopupStatus?.show}
        message={upgradePopupStatus?.message}
        onHide={() => upgradePopupAction({ show: false })}
      />
    </>
  );
}
