import React from "react";

export default function MarketPlaceIcon() {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M3 7.725H9H12M3 7.725H12M3 7.725L5.85 3.75C6.225 3.3 6.9 3 7.5 3H16.425C17.1 3 17.7 3.225 18.15 3.75L21 7.8V8.775C21 9.525 20.7 10.275 20.1 10.875C19.575 11.475 18.75 11.775 18 11.775C17.25 11.775 16.5 11.475 15.9 10.875C15.3 10.35 15 9.6 15 8.775M3 7.725V8.7C3 9.525 3.3 10.275 3.9 10.8C4.5 11.4 5.25 11.7 6 11.7C6.75 11.7 7.575 11.4 8.1 10.8C8.7 10.2 9 9.45 9 8.7M12 7.725H21M9 7.8V8.775C9 9.6 9.3 10.35 9.9 10.875C10.5 11.475 11.25 11.775 12 11.775C12.75 11.775 13.575 11.475 14.1 10.875C14.7 10.275 15 9.525 15 8.775M15 8.775V7.8"
        stroke="#E8E5E2"
        strokeWidth="1.5"
      />
      <path
        d="M18.975 11.625V18.75C18.975 20.025 18 21 16.725 21H7.27496C5.99996 21 5.02496 20.025 5.02496 18.75V11.625"
        stroke="#E8E5E2"
        strokeWidth="1.5"
      />
      <path
        d="M9.75 21V17.025C9.75 16.5 9.975 15.975 10.35 15.6C10.725 15.225 11.25 15 11.775 15H12.3C12.825 15 13.35 15.225 13.725 15.6C14.1 15.975 14.325 16.5 14.325 17.025V21"
        stroke="#E8E5E2"
        strokeWidth="1.5"
      />
    </svg>
  );
}
