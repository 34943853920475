import { t } from "i18next";
import React from "react";
import UpgradePlanImg from "../../../Assets/img/upgrade-plan.png";
import CustomeButton from "../../Common/CustomButton/CustomButton";

export default function AcessDenied() {
  return (
    <div className="page-content">
      <div className="upgrade-plan-page">
        <div>
          <img src={UpgradePlanImg} alt="" />
          <h6>{t("Upgrade Your Plan")}</h6>
          <p className="bl blur-color">{t("upgrade_message")}</p>
          <CustomeButton bg="fill">{t("Upgrade Plan")}</CustomeButton>
        </div>
      </div>
    </div>
  );
}
