import { t } from "i18next";
import React, { useEffect, useState } from "react";
import Modal from "react-bootstrap/Modal";
import { useDispatch, useSelector } from "react-redux";
import { useLoading } from "../../../context/LoadingContext";
import { editStallionStatus } from "../../../Redux/NonRedux/breeding";
import validate from "../../../Utility/Validation";
import CustomeButton from "../../Common/CustomButton/CustomButton";
import InputGroups from "../../Common/InputGroups/InputGroups";
import InputSelectGroup from "../../Common/InputSelectGroup/InputSelectGroup";
import InputSelectMix from "../../Common/InputSelectMix/InputSelectMix";
import SelectGropus from "../../Common/SelectGroups/SelectGroups";
import TextButton from "../../Common/TextButton/TextButton";

export default function EditStallionStatusPopup(props: any) {

  const INTIAL_DATA = {
    status: {
      value: "",
      validation: [""],
      errors: [t("Status is required.")],
    },
    id: {
      value: "",
    },
    semen_type: {
      value: '',
      validation: [""],
      errors: [t("Semen type is required.")],
    },
    stud_fee: {
      value: "",
      validation: [""],
      errors: [t("Stud fee is required.")],
    },
    horse_id: {
      value: "",
      validation: [""],
      errors: [],
    },
    select_vet_clinic: {
      value: "vet_clinic_id",
      // validation: ["requiredIf:vet_clinic_id"],
      errors: [""],
    },
    vet_clinic: {
      value: "",
      validation: ["requiredIf:select_vet_clinic:vet_clinic", "letters"],
      errors: [t("Vet Clinic is required."), t("Incorrect format")],
    },
    vet_clinic_id: {
      value: "",
      validation: ["requiredIf:select_vet_clinic:vet_clinic_id"],
      errors: [t("Vet Clinic is required.")],
    },
    vet_clinic_contact: {
      value: "",
      validation: [""],
      errors: ["Vet Clinic Contact is required."],
    },
  };


  const [inputSelectToggle, setInputSelectToggle] = useState("select");
  const [formData, setFormData] = useState<any>(INTIAL_DATA);
  const { setLoading } = useLoading();
  const [formDataError, setFormDataError] = useState<any>({});
  const [isFormValidated, setIsFormValidated] = useState(false);
  
  const prefrence = useSelector(
    (state: any) => state?.masterReducer?.data?.component?.preferance
  );


  const setData = (field: string, value: any) => {
    const tempOb = Object.assign({}, formData);
    tempOb[field].value = value;
    setFormData({ ...tempOb });
  };

  useEffect(() => {
    setFormDataError({ ...validate(formData).errors });
}, [formData]);


  const submitHandler = () => {
    if (validate(formData).isValidated === true) {
      let params: any = {};
      Object.keys(formData).forEach((element) => {
        params[element] = formData[element].value;
      });
        params["horse_id"] = props?.horseid
      setLoading(true);
      editStallionStatus(params)
        .then((response: any) => {
          props.onHide();
          props.setRefreshList();
          props.refreshMeta();
        })
        .finally(() => {
          setLoading(false);
        });
    } else {
      setFormDataError({ ...validate(formData)?.errors });
      setIsFormValidated(true);
    }
  }

  
  useEffect(() => {
    if (inputSelectToggle == "select") {
      setData("select_vet_clinic", "vet_clinic_id");
      setData("vet_clinic", "");
    } else {
      setData("select_vet_clinic", "vet_clinic");
      setData("vet_clinic_id", "");
    }
  }, [inputSelectToggle]);


  const toggleChange = (selectType: string, input: string) => {
    if (selectType === 'select') {
      setData(`select_${input}`, `${input}_id`)
      setData(input, "");
    } else {
      setData(`select_${input}`, input)
      setData(`${input}_id`, "");
    }
  }

  useEffect(() => {
    if (props?.show === false) {
      setFormDataError({});
      setFormData(INTIAL_DATA);
      setIsFormValidated(false);
      setInputSelectToggle("select");
    }
  }, [props?.show]);

  useEffect(() => {
   
    if (props.isedit && Object.keys(props?.editData)?.length) {
      Object.keys(INTIAL_DATA).forEach((element) => {
        if (props?.editData?.hasOwnProperty(element)) {
          setData(element, props?.editData?.[element]);
        }
      });
      if(props?.editData?.vet_clinic_id){
        setData("vet_clinic", "");
      }
    } else {
      setFormData(INTIAL_DATA);
      setData("vet_clinic_id", props?.metaData?.vet_clinic_id ? props?.metaData?.vet_clinic_id : "")
    }
  }, [props?.editData, props.isedit , props?.show]);

  const {isedit , editData , setRefreshList , metaData , ...rest} = props;

  return (
    <Modal {...rest} centered className="edit-stallion-status-popup modal-416">
      <Modal.Header closeButton>
        <p className="tl">{t("Edit Stallion’s Status")}</p>
      </Modal.Header>
      <Modal.Body>
        <div>
          <SelectGropus
            label={`${t("Status")}`}
            value={props?.metaData?.stallion_status}
            dropKey="id"
            dropVal="metadata"
            defaultSelect={formData?.status?.value}
            onChange={(e: any) => setData("status", e.target.value)}
            error={
              isFormValidated && formDataError["status"]
                ? formDataError["status"]
                : null
            }
          />
          <SelectGropus
            label={`${t("Semen Type")}`}
            value={props?.metaData?.semen_type}
            dropKey="id"
            dropVal="metadata"
            defaultSelect={formData?.semen_type?.value}
            onChange={(e: any) => setData("semen_type", e.target.value)}
            error={
              isFormValidated && formDataError["semen_type"]
                ? formDataError["semen_type"]
                : null
            }
          />
          <InputSelectMix label={`${t("Stud Fee")}`}
            inputValue={formData?.stud_fee?.value}
            inputType="text"
            inputAutoComplete="off"
            onInputChange={(e: any) => setData("stud_fee", e.target.value)}
            inputError={
              isFormValidated && formDataError["stud_fee"]
                ? formDataError["stud_fee"]
                : null
            }
            inputSecondValue={prefrence?.currency_value}
          />
          <InputSelectGroup
            label={`${t("Vet / Clinic")}*`}
            selectValue={props?.metaData?.vet_clinic}
            dropKey="id"
            dropVal="name"
            defaultSelect={formData?.vet_clinic_id?.value}
            value={formData?.vet_clinic?.value}
            inputSelectToggle={inputSelectToggle}
            setInputSelectToggle={(val: any) => {
              setInputSelectToggle(val);
            }}
            error={
              isFormValidated &&
                (formDataError["vet_clinic"] ||
                  formDataError["vet_clinic_id"])
                ? formDataError["vet_clinic"] ||
                formDataError["vet_clinic_id"]
                : null
            }
            onSelectChange={(e: any) => {
              setData("vet_clinic_id", e);
            }}
            onInputChange={(e: any) => {
              setData("vet_clinic", e.target.value);
            }}
          />
          <InputGroups 
            label={`${t("Vet / Clinic Contact")}`}
            type="text"
            autoComplete="off"
            value={formData?.vet_clinic_contact?.value}
            onChange={(e: any) => setData("vet_clinic_contact", e.target.value)}
            error={
              isFormValidated && formDataError["vet_clinic_contact"]
                ? formDataError["vet_clinic_contact"]
                : null
            } />
        </div>
      </Modal.Body>
      <Modal.Footer>
        <div className="footer-2-btn">
          <TextButton onClick={props?.onHide}> {t("Cancel")}</TextButton>
          <CustomeButton bg="fill" onClick={submitHandler}>{t("Save")}</CustomeButton>
        </div>
      </Modal.Footer>
    </Modal>
  );
}
