import React, { useEffect, useState } from "react";
import { Col, Row } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import {
  useLocation,
  useNavigate,
  useOutletContext,
  useParams,
} from "react-router-dom";
import AddPlusIcon from "../../../Assets/icon/AddPlusIcon";
import binIcon from "../../../Assets/img/bin.svg";
import pencilIcon from "../../../Assets/img/pencil.svg";
import { API_STATUS, END_POINTS } from "../../../Constant/Api";
import { SUCCESS_RESPONSE_CODE } from "../../../Constant/Status";
import {
  destroyMeasurementsAction,
  getMeasurementsListAction,
  viewMeasurementsAction,
} from "../../../Redux/Horse/Measurements/MeasurementsAction";
import {
  GET_MEASUREMENTS_LIST_CLEAR,
  VIEW_MEASUREMENTS_CLEAR,
  DESTROY_MEASUREMENTS_CLEAR,
  DESTROY_MEASUREMENTS_REQUEST,
  DESTROY_MEASUREMENTS_SUCCESS,
  DESTROY_MEASUREMENTS_LONG,
  DESTROY_MEASUREMENTS_FAILD,
  GET_MEASUREMENTS_LIST_REQUEST,
  GET_MEASUREMENTS_LIST_SUCCESS,
  GET_MEASUREMENTS_LIST_LONG,
  GET_MEASUREMENTS_LIST_FAILD,
  VIEW_MEASUREMENTS_SUCCESS,
  VIEW_MEASUREMENTS_REQUEST,
  VIEW_MEASUREMENTS_FAILD,
} from "../../../Redux/Horse/Measurements/MeasurementsTypes";
import { calculateWeight, encrDecrStr } from "../../../Utility/General";
import Chart from "../../Common/Chart/Chart";
import CustomeButton from "../../Common/CustomButton/CustomButton";
import AddMeasurementPopup from "../../Popups/AddMeasurementPopup/AddMeasurementPopup";
import BodyWeightCalcuPopup from "../../Popups/BodyWeightCalcuPopup/BodyWeightCalcuPopup";
import ConfirmPopup from "../../Popups/ConfirmPopup/ConfirmPopup";
import HeightCalcuPopup from "../../Popups/HeightCalcuPopup/HeightCalcuPopup";
import {
  weightChartOptions,
  weightChartConfigData,
  heightChartConfigData,
  heightChartOptions,
} from "../../../Utility/Configaration";
import Loader from "../../Loader/Loader";
import NoDataFound from "../../Common/NoDataFound/NoDataFound";
import { useTranslation } from "react-i18next";
import Pagination from "../../Layouts/Pagination/Pagination";
import { useLoading } from "../../../context/LoadingContext";
import {
  REMINDER_STATUS_CHANGE_CLEAR,
  REMINDER_STATUS_CHANGE_SUCCESS,
} from "../../../Redux/General/GeneralType";
import TableHead from "../../Common/DataTable/TableHead/TableHead";
import { getListMetaDataApi } from "../../../Redux/NonRedux/general";

export default function MeasurementsPage() {
  const { t, i18n } = useTranslation();
  const tableHead = [
    {
      label: t("Date"),
      className: "tm",
      key: "date",
    },
    {
      label: t("Age"),
      className: "tm",
      key: "age",
    },
    {
      label: t("Weight"),
      className: "tm",
      key: "weight",
    },
    {
      label: t("Height"),
      className: "tm",
      key: "height",
    },
    {
      label: t("Height in Hands"),
      className: "tm",
      key: "height_in_hands",
    },
    {
      label: t("Body Cond. Index"),
      className: "tm",
      key: "body_cond_index",
    },
    {
      label: t("Action"),
      className: "tm",
      key: "",
      headClass: "text-right",
    },
  ];
  const [AddMeasurementModalShow, setAddMeasurementModalShow] =
    React.useState(false);

  const [BodyWeightCalcuModalShow, setBodyWeightCalcuModalShow] =
    React.useState(false);
  const [calculatedWeight, setCalculatedWeight] = useState<any>("");
  const [calculatedHeight, setCalculatedHeight] = useState<any>("");
  const [HeightCalcuModalShow, setHeightCalcuModalShow] = React.useState(false);
  const param = useParams();
  const dispatch = useDispatch();
  const [horseId, setHorseId] = React.useState<any>(0);
  const [apiStatus, setApiStatus] = useState(API_STATUS.SUCCESS);
  const [measurementsList, setMeasurementsList] = useState<any>([]);
  const [deletableRecords, setDeletableRecords] = React.useState<any>([]);
  const [confirmPopupShow, setConfirmPopupShow] = React.useState(false);
  const [editData, setEditData] = React.useState<any>({});
  const [isEdit, setIsEdit] = React.useState<boolean>(false);
  const { setLoading } = useLoading();
  const [refreshList, setRefreshList] = React.useState(false);
  const [bodyIndexList, setBodyIndexList] = useState([]);
  const [singleMesurement, setSingleMeasurement] = useState<any>({});
  const [weightChartData, setWeightChartData] = useState<any>([]);
  const [heightChartData, setHeightChartData] = useState<any>([]);
  const [recordsTotal, setRecordsTotal] = React.useState(1);
  const [currentPage, setCurrentPage] = React.useState(1);
  const [currentPageSize, setCurrentPageSize] = React.useState(10);
  const [sortByField, setSortByField] = useState("");
  const [sortType, setSortType] = useState("");
  const [metaData, setMetaData] = useState({});
  const [orderList, setOrderList] = useState<any>([]);
  const { setExportParams }: any = useOutletContext();

  const location:any = useLocation();
  const navigate = useNavigate();

  const measurementsListResponse = useSelector(
    (state: any) => state?.measurementsListReducer
  );
  const measurementEdit = useSelector(
    (state: any) => state?.viewMeasurementsReducer
  );
  const deleteMeasurementResponse = useSelector(
    (state: any) => state?.destroyMeasurementsReducer
  );

  const prefrence = useSelector(
    (state: any) => state?.masterReducer?.data?.component?.preferance
  );

  useEffect(() => {  
    if(location?.state?.reminder_id && location?.state?.path){
      setIsEdit(false);
      setEditData({});
      setAddMeasurementModalShow(true)
    }
  }, [location?.state]);



  const getMetaData = () => {
    setLoading(true);  
    getListMetaDataApi(END_POINTS.metaMeasurement)
      .then((response: any) => {
        setMetaData(response?.component);
      })
      .catch((error: any) => {})
      .finally(() => {
        setLoading(false);
      });
  };
  
  const handleRedirectBack = () => {
    if(location?.state?.reminder_id && location?.state?.path){
      navigate(location?.state?.path ,{ 
        state: {reminder_id:"" }
       });
    }
  }

  const listApiCall = () => {
    let id = encrDecrStr(param?.slug, "decr");
    setHorseId(id);
    let params: any = {
      horse_id: id,
      page: currentPage,
      limit: currentPageSize,
    };

    if (sortByField && sortType) {
      params["sort"] = {
        column: orderList[sortByField],
        type: sortType,
      };
    }
    setExportParams(params);
    getMeasurementsListAction(params);
  };

  useEffect(() => {
    getMetaData();
    return () => {
      dispatch({
        type: GET_MEASUREMENTS_LIST_CLEAR,
        data: {
          type: GET_MEASUREMENTS_LIST_CLEAR,
        },
      });
      dispatch({
        type: VIEW_MEASUREMENTS_CLEAR,
        data: {
          type: VIEW_MEASUREMENTS_CLEAR,
        },
      });
      dispatch({
        type: DESTROY_MEASUREMENTS_CLEAR,
        data: {
          type: DESTROY_MEASUREMENTS_CLEAR,
        },
      });
    };
  }, []);

  useEffect(() => {
    listApiCall();
  }, [currentPage, currentPageSize, sortByField, sortType]);

  useEffect(() => {
    if (refreshList) {
      listApiCall();
      setRefreshList(false);
    }
  }, [refreshList]);

  useEffect(() => {
    if (deleteMeasurementResponse?.type === DESTROY_MEASUREMENTS_REQUEST) {
      if (apiStatus !== API_STATUS.LOADING) {
        setApiStatus(API_STATUS.LOADING);
        setLoading(true);
      }
    } else if (
      deleteMeasurementResponse?.data?.statusCode === SUCCESS_RESPONSE_CODE &&
      deleteMeasurementResponse?.type === DESTROY_MEASUREMENTS_SUCCESS
    ) {
      if (apiStatus !== API_STATUS.SUCCESS) {
        setApiStatus(API_STATUS.SUCCESS);
        setRefreshList(true);
        setConfirmPopupShow(false);
        setLoading(false);
      }
    } else if (deleteMeasurementResponse?.type === DESTROY_MEASUREMENTS_LONG) {
      if (apiStatus !== API_STATUS.LONG) {
        setApiStatus(API_STATUS.LONG);
        setLoading(false);
      }
    } else if (deleteMeasurementResponse?.type === DESTROY_MEASUREMENTS_FAILD) {
      if (apiStatus !== API_STATUS.FAILED) {
        setApiStatus(API_STATUS.FAILED);
        setLoading(false);
      }
    }
  }, [deleteMeasurementResponse]);

  const deleteHandler = (id: number) => {
    let params = {
      id: deletableRecords,
    };
    destroyMeasurementsAction(params);
  };

  const editHandler = (id: number) => {
    let params = {
      horse_id: horseId,
      id: id,
    };
    viewMeasurementsAction(params);
  };

  const deleteNow = (id: number) => {
    let ids = deletableRecords;
    ids.push(id);
    setDeletableRecords(ids);
    setConfirmPopupShow(true);
  };

  useEffect(() => {
    if (measurementEdit?.type === VIEW_MEASUREMENTS_REQUEST) {
      setLoading(true);
    }
    if (
      measurementEdit?.type === VIEW_MEASUREMENTS_SUCCESS &&
      measurementEdit?.data?.statusCode === SUCCESS_RESPONSE_CODE
    ) {
      setLoading(false);
      setIsEdit(true);
      setEditData(measurementEdit?.data?.component);
      setAddMeasurementModalShow(true);
    } else if (measurementEdit?.type === VIEW_MEASUREMENTS_FAILD) {
      setLoading(false);
    }
  }, [measurementEdit]);

  useEffect(() => {
    if (measurementsListResponse?.type === GET_MEASUREMENTS_LIST_REQUEST) {
      setWeightChartData([]);
      setHeightChartData([]);
      if (apiStatus !== API_STATUS.LOADING) {
        setApiStatus(API_STATUS.LOADING);
        setLoading(true);
      }
    } else if (
      measurementsListResponse?.data?.statusCode === SUCCESS_RESPONSE_CODE &&
      measurementsListResponse?.type === GET_MEASUREMENTS_LIST_SUCCESS
    ) {
      if (apiStatus !== API_STATUS.SUCCESS) {
        setApiStatus(API_STATUS.SUCCESS);
        setLoading(false);
        setMeasurementsList(measurementsListResponse?.data?.component?.listing);
        setRecordsTotal(
          measurementsListResponse?.data?.component?.recordsFiltered
        );
        // setOrderList(measurementsListResponse?.data?.component?.orderlist);
        setBodyIndexList(measurementsListResponse?.data?.component?.bodyIndex);
        setSingleMeasurement(
          measurementsListResponse?.data?.component?.measurementsSingle
        );

        setWeightChartData(
          measurementsListResponse?.data?.component?.weightGraph
        );
        setHeightChartData(
          measurementsListResponse?.data?.component?.heightGraph
        );
      }
    } else if (measurementsListResponse?.type === GET_MEASUREMENTS_LIST_LONG) {
      if (apiStatus !== API_STATUS.LONG) {
        setApiStatus(API_STATUS.LONG);
        setLoading(false);
      }
    } else if (measurementsListResponse?.type === GET_MEASUREMENTS_LIST_FAILD) {
      if (apiStatus !== API_STATUS.FAILED) {
        setApiStatus(API_STATUS.FAILED);
        setLoading(false);
      }
    }
  }, [measurementsListResponse]);

  return (
    <>
      <div className="measurements-horse-page">
        <div className="summary-head">
          <Row>
            <Col sm="12" md="6" lg="4">
              <div className="summary-box">
                <p className="tl">{t("Weight")}</p>
                <div className="right-details">
                  <h5>{`${singleMesurement?.weight ?? 0} ${
                    prefrence?.weightUnit_value
                  }`}</h5>
                  <p className="tm blur-color">
                    {singleMesurement?.dates
                      ? `on ${singleMesurement?.dates}`
                      : ""}
                  </p>
                </div>
              </div>
            </Col>
            <Col sm="12" md="6" lg="4">
              <div className="summary-box">
                <p className="tl">{t("Height")}</p>
                <div className="right-details">
                  <h5>{`${singleMesurement?.height ?? 0} ${
                    prefrence?.heightUnit_value
                  }`}</h5>
                  <p className="tm blur-color">
                    {singleMesurement?.dates
                      ? `on ${singleMesurement?.dates}`
                      : ""}
                  </p>
                </div>
              </div>
            </Col>
            <Col sm="12" md="6" lg="4">
              <div className="summary-box">
                <p className="tl">{t("Body Cond. Index")}</p>
                <div className="right-details">
                  <h5>{singleMesurement?.body_condition ?? 0}</h5>
                  <p className="tm blur-color">
                    {singleMesurement?.dates
                      ? `on ${singleMesurement?.dates}`
                      : ""}
                  </p>
                </div>
              </div>
            </Col>
          </Row>
        </div>

        <div className="chart-wrapper">
          <Row>
            {weightChartData.length > 0 && (
              <Col sm={12} md={6}>
                <div className="card--layout">
                  <div className="card--header border-0">
                    <p className="tl">{t("Evolution of Weights")}</p>
                  </div>
                  <div className="card--body">
                    <Chart
                      options={weightChartOptions}
                      dataConfig={{
                        ...weightChartConfigData,
                        labels: Array.from(
                          Array(
                            Math.max(...weightChartData.map((o: any) => o.x)) +
                              1
                          ).keys()
                        ),
                      }}
                      data={weightChartData}
                      unit={prefrence?.weightUnit_value}
                    />
                  </div>
                </div>
              </Col>
            )}
            {heightChartData.length > 0 && (
              <Col sm={12} md={6}>
                <div className="card--layout">
                  <div className="card--header border-0">
                    <p className="tl">{t("Evolution of Heights")}</p>
                  </div>
                  <div className="card--body">
                    <Chart
                      options={heightChartOptions}
                      dataConfig={{
                        ...heightChartConfigData,
                        labels: Array.from(
                          Array(
                            Math.max(...heightChartData.map((o: any) => o.x)) +
                              1
                          ).keys()
                        ),
                      }}
                      data={heightChartData}
                      unit={prefrence?.heightUnit_value}
                    />
                  </div>
                </div>
              </Col>
            )}
          </Row>
        </div>

        <div className="data-tbl-wrapper">
          <div className="data-tbl-header">
            <p className="tl">{t("Weight & Height")}</p>
            <div className="btn-wrapper single-btn">
              <CustomeButton
                bg="fill"
                onClick={() => {
                  setIsEdit(false);
                  setEditData({});
                  setAddMeasurementModalShow(true);
                }}
              >
                <AddPlusIcon />
                {t("Add")} 
              </CustomeButton>
            </div>
          </div>

          <div className="data-tbl">
            <table>
              <TableHead
                data={tableHead}
                orderList={orderList}
                sortByField={sortByField}
                sortType={sortType}
                setSortType={setSortType}
                setSortByField={setSortByField}
              />
              <tbody>
                {measurementsList.map((items: any, index: number) => {
                  let extraWeight: any = "";
                  let extraHeight: any = "";
                  if (measurementsList.length - 1 > index) {
                    extraWeight =
                      measurementsList[index]?.weight -
                      measurementsList[index + 1]?.weight;
                    extraHeight =
                      measurementsList[index]?.height -
                      measurementsList[index + 1]?.height;
                    extraWeight = parseInt(extraWeight);
                    extraHeight = parseInt(extraHeight);
                  }

                  return (
                    <tr key={index + "breeding-service"}>
                      <td>
                        <p className="bl">{items?.dates}</p>
                      </td>
                      <td>
                        <p className="bl">{items?.age}</p>
                      </td>
                      <td>
                        <p className="bl">
                          {`${items?.weight} ${prefrence?.weightUnit_value}`}
                          {extraWeight > 0 ? (
                            <span className="ml-6 success-color">
                              {extraWeight && `+${Math.abs(extraWeight)}`}
                            </span>
                          ) : (
                            <span className="ml-6 error-color">
                              {extraWeight && `-${Math.abs(extraWeight)}`}
                            </span>
                          )}
                        </p>
                      </td>
                      <td>
                        <p className="bl">
                          {`${items?.height} ${prefrence?.heightUnit_value}`}
                          {extraHeight > 0 ? (
                            <span className="ml-6 success-color">
                              {extraHeight && `+${Math.abs(extraHeight)}`}
                            </span>
                          ) : (
                            <span className="ml-6 error-color">
                              {extraHeight && `-${Math.abs(extraHeight)}`}
                            </span>
                          )}
                        </p>
                      </td>
                      <td>
                        <p className="bl">{items?.height_inhand}</p>
                      </td>
                      <td>
                        <p className="bl">{items?.body_condition}</p>
                      </td>
                      <td>
                        <div className="icon-wrapper">
                          <a>
                            <img
                              src={pencilIcon}
                              onClick={() => editHandler(items?.id)}
                              alt=""
                            />
                          </a>
                          <a>
                            <img
                              src={binIcon}
                              onClick={() => deleteNow(items?.id)}
                              alt=""
                            />
                          </a>
                        </div>
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </div>
          {measurementsList?.length ? (
            <Pagination
              results={recordsTotal}
              currentPage={currentPage}
              setCurrentPage={setCurrentPage}
              currentLength={currentPageSize}
              setCurrentPageSize={setCurrentPageSize}
            />
          ) : (
            <NoDataFound />
          )}
        </div>
      </div>

      <AddMeasurementPopup
        show={AddMeasurementModalShow}
        onHide={() => {
          setAddMeasurementModalShow(false);
          handleRedirectBack();
        }}
        horseid={horseId}
        measurementdata={editData}
        isedit={isEdit}
        setrefreshlist={() => {
          setRefreshList(true);
        }}
        metaData={metaData}
        bodyindexlist={bodyIndexList}
        calculatedWeight={calculatedWeight}
        calculatedHeight={calculatedHeight}
        setBodyWeightCalcuModalShow={setBodyWeightCalcuModalShow}
        setHeightCalcuModalShow={setHeightCalcuModalShow}
      />

      <BodyWeightCalcuPopup
        show={BodyWeightCalcuModalShow}
        onHide={() => setBodyWeightCalcuModalShow(false)}
        calculate={(girth: number, length: number) => {
          let weight = calculateWeight(girth, length, prefrence);
          setCalculatedWeight(weight);
          setBodyWeightCalcuModalShow(false);
        }}
      />

      <HeightCalcuPopup
        show={HeightCalcuModalShow}
        onHide={() => setHeightCalcuModalShow(false)}
        calculate={(height: number) => {
          setCalculatedHeight(height);
          setHeightCalcuModalShow(false);
        }}
      />

      <ConfirmPopup
        heading={t("Delete")}
        message={t("Are you sure to delete this record?")}
        show={confirmPopupShow}
        onHide={() => {
          setConfirmPopupShow(false);
          setDeletableRecords([]);
        }}
        confirm={deleteHandler}
      />
    </>
  );
}
