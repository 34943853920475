import React, { useState, useEffect } from "react";
import pencilIcon from "../../../Assets/img/pencil.svg";
import binIcon from "../../../Assets/img/bin.svg";
import AddNotesPopup from "../../Popups/AddNotesPopup/AddNotesPopup";
import { useDispatch } from "react-redux";
import { useOutletContext, useParams } from "react-router-dom";
import { API_STATUS } from "../../../Constant/Api";
import {
  destroyNote,
  getNoteList,
  getSingleNote,
} from "../../../actions/Horse/MyHorseActions";
import ConfirmPopup from "../../Popups/ConfirmPopup/ConfirmPopup";
import { SUCCESS_RESPONSE_CODE } from "../../../Constant/Status";
import { encrDecrStr, openDocument } from "../../../Utility/General";
import Pagination from "../../Layouts/Pagination/Pagination";
import PaperClipIcon from "../../../Assets/img/Common-icon/PaperClipIcon";
import { useTranslation } from "react-i18next";
import Loader from "../../Loader/Loader";
import { useLoading } from "../../../context/LoadingContext";

export default function NotesPage() {
  const dispatch = useDispatch();
  const param = useParams();
  const [horseId, setHorseId] = useState<any>(0);
  const [apiStatus, setApiStatus] = useState(API_STATUS.SUCCESS);
  const [editData, setEditData] = useState<any>({});
  const [deletableRecords, setDeletableRecords] = useState<any>([]);
  const [confirmPopupShow, setConfirmPopupShow] = useState(false);
  const [isEdit, setIsEdit] = useState<boolean>(false);
  const [refreshList, setRefreshList] = useState(false);
  const [AddNotesModalShow, setAddNotesModalShow] = React.useState(false);
  const [notesData, setNotesData] = useState([]);
  const [recordsTotal, setRecordsTotal] = React.useState(1);
  const [currentPage, setCurrentPage] = React.useState(1);
  const [currentPageSize, setCurrentPageSize] = React.useState(10);
  const { setLoading } = useLoading();
  const { t } = useTranslation();
  const { setExportParams }: any = useOutletContext();
  const listApiCall = () => {
    let id = encrDecrStr(param?.slug, "decr");
    setHorseId(id);
    let params = {
      horse_id: id,
      page: currentPage,
      limit: currentPageSize,
    };
    setExportParams(params);
    setLoading(true);
    getNoteList(params)
      .then((response: any) => {
        if (response.statusCode === SUCCESS_RESPONSE_CODE) {
          setNotesData(response?.component?.listing);
          setRecordsTotal(response?.component?.recordsTotal);
        }
        setLoading(false);
      })
      .catch((error) => {
        setLoading(false);
      });
  };

  // useEffect(() => {
  //   listApiCall();
  // }, []);

  useEffect(() => {
    listApiCall();
  }, [currentPage, currentPageSize]);

  useEffect(() => {
    if (refreshList) {
      listApiCall();
      setRefreshList(false);
    }
  }, [refreshList]);

  const editHandler = (id: number) => {
    let params = {
      horse_id: horseId,
      id: id,
    };
    setApiStatus(API_STATUS.LOADING);
    setLoading(true);
    getSingleNote(params)
      .then((response: any) => {
        if (response?.statusCode === SUCCESS_RESPONSE_CODE) {
          setApiStatus(API_STATUS.SUCCESS);
          setLoading(false);
          setIsEdit(true);
          setEditData(response?.component);
          setAddNotesModalShow(true);
        } else {
          setApiStatus(API_STATUS.FAILED);
          setLoading(false);
        }
      })
      .catch((error) => {
        setApiStatus(API_STATUS.FAILED);
        setLoading(false);
      });
  };

  const deleteHandler = (id: number) => {
    let params = {
      id: deletableRecords,
    };

    setApiStatus(API_STATUS.LOADING);
    setLoading(true);
    destroyNote(params)
      .then((response: any) => {
        if (response.statusCode === SUCCESS_RESPONSE_CODE) {
          setApiStatus(API_STATUS.SUCCESS);
          setLoading(false);
          setRefreshList(true);
          setConfirmPopupShow(false);
        } else {
          setApiStatus(API_STATUS.FAILED);
          setLoading(false);
        }
      })
      .catch((error) => {
        setApiStatus(API_STATUS.FAILED);
        setLoading(false);
      });
  };

  const deleteNow = (id: number) => {
    let ids = deletableRecords;
    ids.push(id);
    setDeletableRecords(ids);
    setConfirmPopupShow(true);
  };

  return (
    <>
      <div className="notes-horse-page">
        <div className="card--layout">
          <div className="card--header">
            <p className="tl">{t("Notes")}</p>
            <div className="btn-wrapper">
              <a
                className="a-ll"
                onClick={() => {
                  setIsEdit(false);
                  setEditData({});
                  setAddNotesModalShow(true);
                }}
              >
                {t("Add")}
              </a>
            </div>
          </div>
          <div className="card--body">
            <div className="notes-box-wrapper">
              {notesData && notesData.length > 0 ? (
                notesData.map((items: any, index: number) => (
                  <div className="notes-box" key={index + "notes-data"}>
                    <div className="notes-box-header">
                      <div className="date-title">
                        <p className="bl blur-color">{items.dates}</p>
                        <p className="tm">{items.title}</p>
                      </div>
                      <div className="icon-wrapper">
                        {items.document ? (
                          <a onClick={() => openDocument(items.document)}>
                            <PaperClipIcon />
                          </a>
                        ) : (
                          ""
                        )}
                        <a>
                          <img
                            src={pencilIcon}
                            onClick={() => editHandler(items?.id)}
                            alt=""
                          />
                        </a>
                        <a>
                          <img
                            src={binIcon}
                            onClick={() => deleteNow(items?.id)}
                            alt=""
                          />
                        </a>
                      </div>
                    </div>
                    <div className="notes-body">
                      <p className="bl">{items.details}</p>
                    </div>
                  </div>
                ))
              ) : (
                <>
                  <p className="no-data-wrapper ts blur-color">
                    {t("No Data Found")}
                  </p>
                </>
              )}
              {notesData.length > 0 && (
                <Pagination
                  results={recordsTotal}
                  currentPage={currentPage}
                  setCurrentPage={setCurrentPage}
                  currentLength={currentPageSize}
                  setCurrentPageSize={setCurrentPageSize}
                />
              )}
            </div>
          </div>
        </div>
      </div>

      
        <AddNotesPopup
          show={AddNotesModalShow}
          onHide={() => {
            setAddNotesModalShow(false);
        
          }}
          horseid={horseId}
          isedit={isEdit ? 1 : 0}
          notedata={editData}
          setrefreshlist={() => {
            setRefreshList(true);
          }}
        />
      

   
        <ConfirmPopup
          heading={t("Delete")}
          message={t("Are you sure to delete this record?")}
          show={confirmPopupShow}
          onHide={() => {
            setConfirmPopupShow(false);
            setDeletableRecords([]);
          }}
          confirm={deleteHandler}
        />
    
    </>
  );
}
