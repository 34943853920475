import { t } from "i18next";
import React, { useContext, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  BrowserRouter,
  Outlet,
  Route,
  Routes,
  useLocation,
  useNavigate,
} from "react-router-dom";
import ExportIcon from "../../Assets/img/export.svg";
import HealthIcon from "../../Assets/img/Health-primary.svg";
import BandageIcon from "../../Assets/img/Inner-tab-icon/BandageIcon";
import ClinincIcon from "../../Assets/img/Inner-tab-icon/ClinincIcon";
import FilmIcon from "../../Assets/img/Inner-tab-icon/FilmIcon";
import HeartPulseIcon from "../../Assets/img/Inner-tab-icon/HeartPulseIcon";
import MicroscopeIcon from "../../Assets/img/Inner-tab-icon/MicroscopeIcon";
import ParasiteControlIcon from "../../Assets/img/Inner-tab-icon/ParasiteControlIcon";
import SyringeIcon from "../../Assets/img/Inner-tab-icon/SyringeIcon";
import ToothIcon from "../../Assets/img/Inner-tab-icon/ToothIcon";
import SellHorseIcon from "../../Assets/img/Sell-Horse.svg";
import { END_POINTS } from "../../Constant/Api";
import { PERMISSIONS, SIDEBAR } from "../../Constant/General";
import {
  HEALTH_DENTAL,
  HEALTH_LABTESTS,
  HEALTH_PARASITECONTROL,
  HEALTH_TREATMENTS,
  HEALTH_VACCINATION,
  HEALTH_VETVISITS,
  HEALTH_VITALSIGNS,
  HEALTH_XRAY,
} from "../../Constant/Route";
import { useLoading } from "../../context/LoadingContext";
import { SidebarContext } from "../../context/SideBarContext";
import { metaHorseListAction, upgradePopupAction } from "../../Redux/General/GeneralAction";
import { exportDocument } from "../../Redux/globallActions";
import { checkRoutePermission } from "../../Utility/General";
import TabWrapper from "../Layouts/TabWrapper/TabWrapper";

export default function Health() {
  const [parentTab, setParentTab] = React.useState(0);
  const [exportParams, setExportParams] = useState<any>({});
  const {loading , setLoading} = useLoading();
  const dispatch = useDispatch();
  const parentTabHandleChange = (
    event: React.SyntheticEvent,
    newValue: number
  ) => {
    setParentTab(newValue);
  };

  const parentTabData = [
    {
      index: 0,
      img: <HeartPulseIcon />,
      label: t("Vital Signs"),
      path: HEALTH_VITALSIGNS,
      export_url: END_POINTS?.vitalSignExport,
    },
    {
      index: 1,
      img: <SyringeIcon />,
      label: t("Vaccination"),
      path: HEALTH_VACCINATION,
      export_url: END_POINTS?.vacinationExport,
    },
    {
      index: 2,
      img: <ParasiteControlIcon />,
      label: t("Parasite Control"),
      path: HEALTH_PARASITECONTROL,
      export_url: END_POINTS?.parasiteExport,
    },
    {
      index: 3,
      img: <ToothIcon />,
      label: t("Dental"),
      path: HEALTH_DENTAL,
      export_url: END_POINTS?.dentalExport,
    },
    {
      index: 4,
      img: <ClinincIcon />,
      label: t("Vet/Clinic Visits"),
      path: HEALTH_VETVISITS,
      export_url: END_POINTS?.clinicExport,
    },
    {
      index: 5,
      img: <BandageIcon />,
      label: t("Treatments"),
      path: HEALTH_TREATMENTS,
      export_url: END_POINTS?.treatmentExport,
    },
    {
      index: 6,
      img: <MicroscopeIcon />,
      label: t("Lab Tests"),
      path: HEALTH_LABTESTS,
      export_url: END_POINTS?.labtestExport,
    },
    {
      index: 7,
      img: <FilmIcon />,
      label: t("X-Rays"),
      path: HEALTH_XRAY,
      export_url: END_POINTS?.xrayExport,
    },
  ];

  let navigate = useNavigate();
  const horseRouteChange = (pathName: any) => {
    navigate(pathName);
  };
  const location:any = useLocation();
  const { setSidebar }: any = useContext(SidebarContext);
  const modules = useSelector(
    (state: any) => state?.masterReducer?.data?.component?.modules
  );
  useEffect(() => {
    setSidebar(SIDEBAR.HEALTH);
  }, []);

  useEffect(() => {
    if (location.pathname.includes(HEALTH_VITALSIGNS)) {
      setParentTab(0);
    } else if (location.pathname.includes(HEALTH_VACCINATION)) {
      setParentTab(1);
    } else if (location.pathname.includes(HEALTH_PARASITECONTROL)) {
      setParentTab(2);
    } else if (location.pathname.includes(HEALTH_DENTAL)) {
      setParentTab(3);
    } else if (location.pathname.includes(HEALTH_VETVISITS)) {
      setParentTab(4);
    } else if (location.pathname.includes(HEALTH_TREATMENTS)) {
      setParentTab(5);
    } else if (location.pathname.includes(HEALTH_LABTESTS)) {
      setParentTab(6);
    } else if (location.pathname.includes(HEALTH_XRAY)) {
      setParentTab(7);
    }
  }, [location?.pathname]);

  const handleExport = () => {
    if(!checkRoutePermission(PERMISSIONS?.EXPORT_FEATURE , modules)){
     upgradePopupAction({show:true});
      return;
    }
    setLoading(true);
    exportDocument(parentTabData[parentTab]?.export_url , exportParams,parentTabData[parentTab]?.label?.replace(" " , "")).then(() => {
    }).finally(() => {
      setLoading(false);
    })
  }

  return (
    <>
      <div className="health-page">
        {/* Page Title Start */}
        <div className="page-title-wrapper">
          <div className="title-first-menu">
            <div className="title-icon-text">
              <img src={HealthIcon} alt="" />
              <h5>{t("Health")}</h5>
            </div>
            <div className="title-right-icons">
              {/* <a className="unset-links">
                <img src={SellHorseIcon} alt="" />
              </a> */}
             
                <a className="unset-links">
                  <img src={ExportIcon} onClick={handleExport} alt="" />
                </a>
             
            </div>
          </div>
          <TabWrapper
            value={parentTab}
            horseRouteChange={horseRouteChange}
            handleChange={parentTabHandleChange}
            parentTabData={parentTabData}
          />
        </div>
        {/* Page Title End */}

        <div className="page-content">
          <Outlet context={{ setExportParams }} />
        </div>
      </div>
    </>
  );
}
