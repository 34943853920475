import { combineReducers } from "redux";
import { login } from "./Auth/AuthReducer";
import {
  colorRedux,
  currencyRedux,
  dewormerRedux,
  genderRedux,
  horseStatusRedux,
  vaccinesRedux,
  unitRedux,
  dietCategoryRedux,
  farrierContactRedux,
  shoeingTypesRedux,
  shoeMaterialRedux,
  clinicContactRedux,
  countryListRedux,
  disciplineRedux,
  userLangugaeRedux,
  horseBreederContactRedux,
  horseOwnerContactRedux,
  breedRedux,
  sireListRedux,
  damListRedux,
  locationListRedux,
  metaHorseListRedux,
  trainingTypesRedux,
  masterRedux,
  incomeCategoryRedux,
  breedingServiceTypeRedux,
  reminderStatusRedux,
  userDetailsRedux,
  upgradePopupRedux,
} from "./General/GeneralReducer";
import {
  singleHealthAddEditRedux,
  singleHealthDeleteRedux,
  singleHealthDentalRedux,
  singleHealthLabTestsRedux,
  singleHealthParasiteRedux,
  singleHealthTreatmentRedux,
  singleHealthVaccineRedux,
  singleHealthVetClinincRedux,
  singleHealthViewRedux,
  singleHealthVitalSignRedux,
  singleHealthXraysRedux,
} from "./Horse/Health/SingleHealthReducer";
import {
  horseDetailRedux,
  horseInfoRedux,
  horseListRedux,
} from "./Horse/HorseReducer";
import {
  addEditDietRedux,
  destroyDietRedux,
  DietListRedux,
  viewDietRedux,
} from "./Horse/Diet/DietReducer";
import {
  addEditFarrierRecordRedux,
  farrierRecordListRedux,
  destroyFarrierRecordRedux,
  viewFarrierRecordRedux,
} from "./Horse/Farrier/FarrierReducer";
import {
  addEditMeasurementsRedux,
  measurementsListRedux,
  destroyMeasurementsRedux,
  viewMeasurementsRedux,
} from "./Horse/Measurements/MeasurementsReducer";
import { boardingPlanListRedux } from "./Boarding/BoardingReducer";

export default combineReducers({
  masterReducer: masterRedux,
  userDetailsReducer: userDetailsRedux,
  login: login,
  horseListReducer: horseListRedux,
  reminderStatusReducer: reminderStatusRedux,
  vaccinesReducer: vaccinesRedux,
  dewormerReducer: dewormerRedux,
  colorReducer: colorRedux,
  breedingServiceTypeReducer: breedingServiceTypeRedux,
  breedReducer: breedRedux,
  unitReducer: unitRedux,
  incomeCategoryReducer: incomeCategoryRedux,
  sireListReducer: sireListRedux,
  damListReducer: damListRedux,
  currencyReducer: currencyRedux,
  trainingTypesReducer: trainingTypesRedux,
  locationListReducer: locationListRedux,
  farrierContactReducer: farrierContactRedux,
  shoeMaterialReducer: shoeMaterialRedux,
  shoeingTypesReducer: shoeingTypesRedux,
  clinicContactReducer: clinicContactRedux,
  horseOwnerContactReducer: horseOwnerContactRedux,
  horseBreederContactReducer: horseBreederContactRedux,
  dietCategoryReducer: dietCategoryRedux,
  addEditDietReducer: addEditDietRedux,
  dietListReducer: DietListRedux,
  destroyDietReducer: destroyDietRedux,
  viewDietReducer: viewDietRedux,
  boardingPlanListReducer: boardingPlanListRedux,
  addEditFarrierRecordReducer: addEditFarrierRecordRedux,
  farrierRecordListReducer: farrierRecordListRedux,
  destroyFarrierRecordReducer: destroyFarrierRecordRedux,
  viewFarrierRecordReducer: viewFarrierRecordRedux,
  metaHorseListReducer: metaHorseListRedux,
  addEditMeasurementsReducer: addEditMeasurementsRedux,
  measurementsListReducer: measurementsListRedux,
  destroyMeasurementsReducer: destroyMeasurementsRedux,
  viewMeasurementsReducer: viewMeasurementsRedux,
  disciplineReducer: disciplineRedux,
  countryListReducer: countryListRedux,
  genderReducer: genderRedux,
  horseStatusReducer: horseStatusRedux,
  userLangugaeReducer: userLangugaeRedux,
  horseDetailReducer: horseDetailRedux,
  singleHealthAddEditReducer: singleHealthAddEditRedux,
  singleHealthViewReducer: singleHealthViewRedux,
  singleHealthDeleteReducer: singleHealthDeleteRedux,
  singleHealthVitalSignReducer: singleHealthVitalSignRedux,
  singleHealthVaccineReducer: singleHealthVaccineRedux,
  singleHealthParasiteReducer: singleHealthParasiteRedux,
  singleHealthDentalReducer: singleHealthDentalRedux,
  singleHealthVetClinincReducer: singleHealthVetClinincRedux,
  singleHealthTreatmentReducer: singleHealthTreatmentRedux,
  singleHealthLabTestsReducer: singleHealthLabTestsRedux,
  singleHealthXraysReducer: singleHealthXraysRedux,
  horseInfoReducer: horseInfoRedux,
  upgradePopupReducer:upgradePopupRedux
});
