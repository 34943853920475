import React from 'react'

export default function Numbernamecard(props: any) {
  return (
    <div onClick={props.onClick} className={`number-name-card ${props.className == 'active' ? 'active' : ''}`}>
      <h2>{props.number}</h2>
      <p className='tl blur-color'>{props.name}</p>
    </div>
  )
}
