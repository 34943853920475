import React from "react";

export default function ManagementMobileIcon() {
  return (
    <svg
      width="32"
      height="32"
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M12.5333 16.4C12 18.4 13.3333 20.4 15.3333 20.8C17.3333 21.3333 19.3333 20 19.7333 18"
        stroke="#E8E5E2"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M23.7333 11.0667C24.4 12.1333 24.4 12.1333 25.0667 13.2L23.6 14.9333C23.7333 15.7333 23.7333 16.1333 23.8667 16.9333L25.7333 18.1333C25.4667 19.3333 25.4667 19.3333 25.2 20.6667L22.9333 20.9333C22.4 21.6 22.2667 21.8667 21.7333 22.5333L22.2667 24.8C21.2 25.4667 21.2 25.4667 20.1333 26.1333L18.4 24.6667C17.6 24.8 17.2 24.8 16.4 24.9333L15.2 26.8C14 26.5333 14 26.5333 12.6667 26.2667L12.4 24C11.7333 23.4667 11.4667 23.3333 10.8 22.8L8.53333 23.3333C6.8 21.6 6.4 19.0667 6.4 19.0667C6 17.2 6.13333 15.3333 6.66666 13.4667C8.4 6.4 16.5333 4.66666 17.7333 4.93333L16.6667 7.46666L20 12.4L18.2667 14L16.2667 12.1333C15.3333 12.6667 14.4 12.5333 13.6 12.1333L12.4 11.4667"
        stroke="#E8E5E2"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}
