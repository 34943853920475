import { t } from "i18next";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Outlet, useLocation, useNavigate } from "react-router-dom";
import IncomeIcon from "../../Assets/img/Income&Expenses-primary.svg";
import CategoryIcon from "../../Assets/img/Tab-icon/CategoryIcon";
import HorseIcon from "../../Assets/img/Tab-icon/Horse";
import { END_POINTS } from "../../Constant/Api";
import { PERMISSIONS } from "../../Constant/General";
import {
  INCOME_EXPENSE_CATEGORY,
  INCOME_EXPENSE_HORSE,
} from "../../Constant/Route";
import { useLoading } from "../../context/LoadingContext";
import {
  horseOwnerContactAction,
  metaHorseListAction,
  metaIncomeCategoryAction,
} from "../../Redux/General/GeneralAction";
import {
  META_INCOME_CATEGORY_FAILD,
  META_INCOME_CATEGORY_REQUEST,
  META_INCOME_CATEGORY_SUCCESS,
} from "../../Redux/General/GeneralType";
import { getListMetaDataApi } from "../../Redux/NonRedux/general";
import { getCostCategory } from "../../Redux/NonRedux/incomeExpense";
import { invoiceHorseList } from "../../Redux/NonRedux/invoice";
import TabWrapper from "../Layouts/TabWrapper/TabWrapper";
import SomethingWrong from "../SomethingWrong/SomethingWrong";

export default function IncomeAndExpenses() {
  const [parentTab, setParentTab] = React.useState(0);
  const [costCategory, setCostCategory] = useState<any>([]);
  const dispatch = useDispatch();


  const parentTabHandleChange = (
    event: React.SyntheticEvent,
    newValue: number
  ) => {
    setParentTab(newValue);
  };
  const parentTabData = [
    {
      index: 0,
      img: <CategoryIcon />,
      label: t("Category"),
      path: INCOME_EXPENSE_CATEGORY,
    },
    {
      index: 1,
      img: <HorseIcon />,
      label: t("Horse"),
      path: INCOME_EXPENSE_HORSE,
    },
  ];

  const location:any = useLocation();
  let navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);
  const userRouteChange = (pathName: any) => {
    navigate(pathName);
  };

  const { setLoading } = useLoading();
  const [metaData, setMetaData] = useState({});

  const getMetaData = () => {
    // setLoading(true);  
    setIsLoading(true)
    getListMetaDataApi(END_POINTS.metaIncomeExpense)
      .then((response: any) => {
        setMetaData(response?.component);
      })
      .catch((error: any) => {})
      .finally(() => {
        // setLoading(false);
        setIsLoading(false)
      });
  };

  useEffect(() => {
    getMetaData();
  }, []);

  useEffect(() => {
    if (location.pathname.includes(INCOME_EXPENSE_CATEGORY)) {
      setParentTab(0);
    } else if (location.pathname.includes(INCOME_EXPENSE_HORSE)) {
      setParentTab(1);
    }
  }, [location?.pathname]);

  return (
    <>
      {!isLoading && (
        <div className="income-and-expenses-page">
          {/* Page Title Start */}
          <div className="page-title-wrapper">
            <div className="title-first-menu">
              <div className="title-icon-text">
                <img src={IncomeIcon} alt="" />
                <h5>{t("Income & Expenses")}</h5>
              </div>
            </div>
            <TabWrapper
              value={parentTab}
              horseRouteChange={userRouteChange}
              handleChange={parentTabHandleChange}
              parentTabData={parentTabData}
            />
          </div>

          {/* Page Title End */}

          <div className="page-content">
            <Outlet context={{ costCategory: costCategory,metaData }} />
          </div>
        </div>
      )}
    </>
  );
}
