import React from "react";

export default function ClinincIcon() {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M10.5176 7.55292H13.4823"
        stroke="#919EAB"
        strokeWidth="1.5"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M12 6.07062V9.14121"
        stroke="#919EAB"
        strokeWidth="1.5"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M16.5529 9.14117H19.6235V21H4.37646V9.14117H7.44705"
        stroke="#919EAB"
        strokeWidth="1.5"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M16.553 12.1059H7.44708V7.55294C7.44708 5.01176 9.45885 3 12 3C14.5412 3 16.553 5.01176 16.553 7.55294V12.1059Z"
        stroke="#919EAB"
        strokeWidth="1.5"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M9.77646 21V15.1765H14.2235V21"
        stroke="#919EAB"
        strokeWidth="1.5"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M3 9.14117H4.37647"
        stroke="#919EAB"
        strokeWidth="1.5"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M21 9.14117H19.6235"
        stroke="#919EAB"
        strokeWidth="1.5"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}
