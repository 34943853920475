import React, { useEffect, useRef } from "react";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import { Line } from "react-chartjs-2";
import { faker } from "@faker-js/faker";

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend
);

export default function LineChart(props: any) {
  const chartRef: any = useRef();
  useEffect(() => {
    if (props.data && chartRef.current) {
     
      chartRef.current.update("active");
    }
  }, [props?.options, props.data]);
  return (
    <>
      <Line options={props.options} data={props.data} />
    </>
  );
}
