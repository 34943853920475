import React from "react";

export default function Boardingicon() {
  return (
    <svg
      width="32"
      height="32"
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M21.3 26H8.60001V14.3"
        stroke="#E8E5E2"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M23.1 5.89999L5.5 12.5"
        stroke="#E8E5E2"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M26.2 21.8C26 21.6 25.7 21.5 25.4 21.5C24.6 21.5 24.2 22 24.2 22.3C24.2 23.1 24.9 23.2 25.4 23.4C25.8 23.5 26.6 23.7 26.6 24.3C26.6 24.7 26.2 25.1 25.4 25.1C24.9 25.1 24.5 24.9 24.2 24.6"
        stroke="#E8E5E2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M25.2 21.4V20.4"
        stroke="#E8E5E2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M25.2 26.1V25.2"
        stroke="#E8E5E2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M19 15.7C19 16 18.8 16.2 18.5 16.2C18.2 16.2 18 16 18 15.7C18 15.4 18.2 15.2 18.5 15.2C18.8 15.3 19 15.5 19 15.7Z"
        fill="#E8E5E2"
      />
      <path
        d="M17.8 18.7C18.1 18.9 18.6 19 19 19C19.3 19 19.6 18.9 19.9 18.8L20.8 19.3C21.6 19.8 22.6 19.4 22.9 18.5C23 18.1 23.2 17.7 23.2 17.3L19.4 13.5C19.6 13.2 19.8 12.8 20.1 12.5C19.3 12.6 18.5 12.6 17.8 12.8C17.9 12.4 17.9 12 18 11.6C16.5 12.1 15.1 12.9 14 13.9C12.2 15.5 12.2 18.3 14 19.9C14.9 20.7 15.8 21.3 16.9 21.8C17.6 22.1 18.3 22.3 19 22.5"
        stroke="#E8E5E2"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}
