import {
  HORSE_FAILD,
  HORSE_LONG,
  HORSE_REQUEST,
  HORSE_SUCCESS,
  HORSEDETAIL_REQUEST,
  HORSEDETAIL_SUCCESS,
  HORSEDETAIL_FAILD,
  HORSEDETAIL_LONG,
  HORSEDETAIL_CLEAR,
  HORSE_CLEAR,
  HORSEINFO_CLEAR,
  HORSEINFO_FAILD,
  HORSEINFO_LONG,
  HORSEINFO_REQUEST,
  HORSEINFO_SUCCESS,
} from "./HorseType";
const defaultParameters = {
  customerId: "",
  customerToken: "",
  websiteId: "",
  groupId: "",
  storeId: "",
};
// export function defaultParameter(state = defaultParameters, action: any) {
//   switch (action.type) {
//     case DEFAULT_PARAMETER:
//       return { ...action.payload.data };
//     default:
//       return state;
//   }
// }


export function horseListRedux(state = {}, action: any) {
  switch (action.type) {
    case HORSE_REQUEST:
      return { ...action.payload };
    case HORSE_SUCCESS:
      return { ...action.payload };
    case HORSE_FAILD:
      return { ...action.payload };
    case HORSE_LONG:
      return { ...action.payload };
      case HORSE_CLEAR:
        return {};
    default:
      return state;
  }
}

export function horseDetailRedux(state = {}, action: any) {
  switch (action.type) {
    case HORSEDETAIL_REQUEST:
      return { ...action.payload };
    case HORSEDETAIL_SUCCESS:
      return { ...action.payload };
    case HORSEDETAIL_FAILD:
      return { ...action.payload };
    case HORSEDETAIL_LONG:
      return { ...action.payload };
    case HORSEDETAIL_CLEAR:
      return {};
    default:
      return state;
  }
}

export function horseInfoRedux(state = {}, action: any) {
  switch (action.type) {
    case HORSEINFO_REQUEST:
      return { ...action.payload };
    case HORSEINFO_SUCCESS:
      return { ...action.payload };
    case HORSEINFO_FAILD:
      return { ...action.payload };
    case HORSEINFO_LONG:
      return { ...action.payload };
    case HORSEINFO_CLEAR:
      return {};
    default:
      return state;
  }
}
