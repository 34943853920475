import type { DatePickerProps } from "antd";
import { t } from "i18next";
import moment from "moment";
import React, { useEffect, useState } from "react";
import Modal from "react-bootstrap/Modal";
import { useSelector } from "react-redux";

import Datepicker from "../../Common/Datepicker/Datepicker";
import InputGroups from "../../Common/InputGroups/InputGroups";

export default function MareGestationCalculatorPopup(props: any) {
  const [date, setDate] = useState<any>(moment().format("YYYY-MM-DD"));
  const [days, setDays] = useState(null);
  const [expectedDate, setExpectedDate] = useState<string>();

  const dateChange: DatePickerProps["onChange"] = (date, dateString) => {
    setDate(date?.format("YYYY-MM-DD"));
  };

  const prefrence: any = useSelector(
    (state: any) => state?.masterReducer?.data?.component?.preferance
  );

  useEffect(() => {
    setDays(prefrence?.pregnancyDays);
  }, [prefrence]);

  useEffect(() => {
    if (date) {
      const eDate = moment(date)
        .add(days ? days : 0, "days")
        .format("DD/MM/YYYY");
      setExpectedDate(eDate);
    }
  }, [days, date]);

  return (
    <Modal
      {...props}
      centered
      className="mare-gestation-calculator-popup modal-416"
    >
      <Modal.Header closeButton>
        <p className="tl">{t("Mare Gestation Calculator")}</p>
      </Modal.Header>
      <Modal.Body>
        <label className="tm">{t("What date was the mare bred?")}</label>
        <div>
          <Datepicker
            label={`${t("Date")}*`}
            onChange={dateChange}
            value={moment(date)}
          />
          <InputGroups
            label={`${t("Average Gestation Period")}*`}
            value={days}
            onChange={(e: any) => setDays(e.target.value)}
          />
        </div>
        <p className="tm">
          {t("Estimated foaling date:")}
          {expectedDate}
        </p>
        <span className="bl">
          {t("Select a breeding date to see the estimated foaling date range.")}
        </span>
      </Modal.Body>
      {/* <Modal.Footer>
        <div className="footer-2-btn">
          <TextButton> {t("Cancel")}</TextButton>
          <CustomeButton bg="fill">Add</CustomeButton>
        </div>
      </Modal.Footer> */}
    </Modal>
  );
}
