import { t } from "i18next";
import React, { useEffect, useState } from "react";
import { Col, Row } from "react-bootstrap";
import Modal from "react-bootstrap/Modal";
import { toast } from "react-toastify";
import { editPedigree } from "../../../actions/Horse/MyHorseActions";
import FemaleIcon from "../../../Assets/img/Female.svg";
import MaleIcon from "../../../Assets/img/Male.svg";
import { ERROR, PEDIGREE, REFRESH } from "../../../Constant/General";
import { SUCCESS_RESPONSE_CODE } from "../../../Constant/Status";
import { useLoading } from "../../../context/LoadingContext";
import { showToast } from "../../../Utility/General";
import CustomeButton from "../../Common/CustomButton/CustomButton";

export default function EditPedigreePopup(props: any) {
  // const { pedigreedata } = props;
  const [pedigreeData, setPedigreeData] = useState<any>({});
  const { setLoading } = useLoading();

  useEffect(() => {
    if (props?.pedigreedata) {
      setPedigreeData(JSON.parse(JSON.stringify(props?.pedigreedata)));
    }
  }, [props?.pedigreedata]);

  const onPedigreeChange = (
    e: any,
    index: number,
    type: string,
    span: string
  ) => {
    const pedigreeOb = Object.assign({}, pedigreeData);
    let pedigree = e.target.value;
    // pedigree = pedigree.trim();

    // pedigree = pedigree.trim();
    // pedigree = pedigree.split(",");
    // pedigree = pedigree.map((item: string) => item.trim());

    // if (pedigree.length == 2) {
    //   let horseName = "";
    //   let horseYear = "";
    //   horseName = pedigree[0];
    //   horseYear = pedigree[1];
    //   pedigreeOb[type][index]["horseName"] = horseName;
    //   pedigreeOb[type][index]["year"] = horseYear;
    // }
    pedigreeOb[type][index]["horseName"] = pedigree;
    pedigreeOb[type][index]["year"] = "";
    if (pedigree) {
      let nextSpan: any = span.replace("span_", "");
      if (nextSpan > 2) {
        nextSpan = parseInt(nextSpan) / 2;
        if (!pedigreeOb["span_" + nextSpan]) {
          let nodeCount = 32 / nextSpan;
          let newObj: any = [];
          for (let index = 0; index < nodeCount; index++) {
            // const element = array[index];
            newObj.push({
              gender: index % 2 == 0 ? "m" : "f",
              horseName: "",
            });
          }
          pedigreeOb["span_" + nextSpan] = newObj;
        }
      }
    }
    setPedigreeData({ ...pedigreeOb });
  };

  useEffect(() => {
    if (props?.show === false) {
      setPedigreeData({ ...props?.pedigreedata });
    }
  }, [props?.show]);

  const submitHandler = () => {
    let params = {
      horse_id: props?.horseid,
      pedigree: pedigreeData,
    };

    // let validate = true;
    // Object.values(pedigreeData).forEach((spanElement: any) => {
    //   spanElement.forEach((element: any) => {
    //     if (element.horseName === "" || element.year === "") {
    //       validate = false;
    //     }
    //   });
    // });

    // if (!validate) {
    //   showToast(ERROR, "Please enter valid padigree Data");
    //   return;
    // }
    setLoading(true);
    editPedigree(params)
      .then((response: any) => {
        if (response.statusCode === SUCCESS_RESPONSE_CODE) {
          props.onHide();
          props.refreshList(REFRESH.PEDIGREE);
        }
      })
      .catch((error) => {})
      .finally(() => {
        setLoading(false);
      });
  };

  useEffect(() => {
    let horseInfo = props.horseInformation;
    if (JSON.stringify(horseInfo) != "{}") {
      if (JSON.stringify(pedigreeData) === "{}") {
        let span32 = {
          span_32: [
            {
              gender: horseInfo?.type == "male" ? "m" : "f",
              horseName:
                horseInfo?.name +
                "," +
                (horseInfo?.birthdate
                  ? horseInfo?.birthdate.substr(horseInfo?.birthdate.length - 4)
                  : ""),
            },
          ],
          span_16: [
            {
              gender: "m",
              horseName: horseInfo?.sire,
              // year: "",
            },
            {
              gender: "f",
              horseName: horseInfo?.dam,
              // year: "",
            },
          ],
        };
        setPedigreeData(span32);
      }
    }
  }, [pedigreeData, props.horseInformation]);

  return (
    <Modal {...props} centered className="edit-pedigree-popup modal-1192">
      <Modal.Header closeButton>
        <p className="tl">{t("Edit Pedigree")}</p>
      </Modal.Header>
      <Modal.Body>
        <div className="overflow-x-auto">
          <Row className="pedhi-aambo five-layer">
            <Col>
              {pedigreeData &&
                pedigreeData?.span_32?.map((item: any, index: number) => (
                  <div
                    className={`layer-box ${
                      item.gender === "m" ? "male" : "female"
                    }`}
                    key={"span_32" + index}
                  >
                    <div className="icon-24-text">
                      <img
                        src={`${item.gender === "m" ? MaleIcon : FemaleIcon}`}
                        alt=""
                      />
                      <p className="bl">
                        <input
                          type="text"
                          value={
                            item?.horseName +
                            (item?.year ? "," + item?.year : "")
                          }
                          onChange={(e: any) =>
                            onPedigreeChange(
                              e,
                              index,
                              PEDIGREE.SPAN_32,
                              "span_32"
                            )
                          }
                        />
                      </p>
                    </div>
                  </div>
                ))}
            </Col>
            {pedigreeData && pedigreeData?.span_16 && (
              <Col>
                {pedigreeData &&
                  pedigreeData?.span_16?.map((item: any, index: number) => (
                    <div
                      className={`layer-box ${
                        item.gender === "m" ? "male" : "female"
                      }`}
                      key={index + "span_16"}
                    >
                      <div className="icon-24-text">
                        <img
                          src={`${item.gender === "m" ? MaleIcon : FemaleIcon}`}
                          alt=""
                        />
                        <input
                          type="text"
                          value={
                            item?.horseName +
                            (item?.year ? "," + item?.year : "")
                          }
                          onChange={(e: any) =>
                            onPedigreeChange(
                              e,
                              index,
                              PEDIGREE.SPAN_16,
                              "span_16"
                            )
                          }
                        />
                      </div>
                    </div>
                  ))}
              </Col>
            )}
            {pedigreeData && pedigreeData?.span_8 && (
              <Col>
                {pedigreeData &&
                  pedigreeData?.span_8?.map((item: any, index: number) => (
                    <div
                      className={`layer-box ${
                        item.gender === "m" ? "male" : "female"
                      }`}
                      key={index + "span_8"}
                    >
                      <div className="icon-24-text">
                        <img
                          src={`${item.gender === "m" ? MaleIcon : FemaleIcon}`}
                          alt=""
                        />
                        <input
                          type="text"
                          value={
                            item?.horseName +
                            (item?.year ? "," + item?.year : "")
                          }
                          onChange={(e: any) =>
                            onPedigreeChange(
                              e,
                              index,
                              PEDIGREE.SPAN_8,
                              "span_8"
                            )
                          }
                        />
                      </div>
                    </div>
                  ))}
              </Col>
            )}
            {pedigreeData && pedigreeData?.span_4 && (
              <Col>
                {pedigreeData &&
                  pedigreeData?.span_4?.map((item: any, index: number) => (
                    <div
                      className={`layer-box ${
                        item.gender === "m" ? "male" : "female"
                      }`}
                      key={index + "span_4"}
                    >
                      <div className="icon-24-text">
                        <img
                          src={`${item.gender === "m" ? MaleIcon : FemaleIcon}`}
                          alt=""
                        />
                        <input
                          type="text"
                          value={
                            item?.horseName +
                            (item?.year ? "," + item?.year : "")
                          }
                          onChange={(e: any) =>
                            onPedigreeChange(
                              e,
                              index,
                              PEDIGREE.SPAN_4,
                              "span_4"
                            )
                          }
                        />
                      </div>
                    </div>
                  ))}
              </Col>
            )}
            {pedigreeData && pedigreeData?.span_2 && (
              <Col>
                {pedigreeData &&
                  pedigreeData?.span_2?.map((item: any, index: number) => (
                    <div
                      className={`layer-box ${
                        item.gender === "m" ? "male" : "female"
                      }`}
                      key={index + "span_2"}
                    >
                      <div className="icon-24-text">
                        <img
                          src={`${item.gender === "m" ? MaleIcon : FemaleIcon}`}
                          alt=""
                        />
                        <input
                          type="text"
                          value={
                            item?.horseName +
                            (item?.year ? "," + item?.year : "")
                          }
                          onChange={(e: any) =>
                            onPedigreeChange(
                              e,
                              index,
                              PEDIGREE.SPAN_2,
                              "span_2"
                            )
                          }
                        />
                      </div>
                    </div>
                  ))}
              </Col>
            )}
          </Row>
          {/* <CustomeButton onClick={submitHandler} bg="fill">
            save
          </CustomeButton> */}
        </div>
      </Modal.Body>
      <Modal.Footer>
        <div className="footer-1-btn">
          <CustomeButton onClick={submitHandler} bg="fill">
          {t("Save")}
          </CustomeButton>
        </div>
      </Modal.Footer>
    </Modal>
  );
}
