import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import {
  destroyDocument,
  downloadDocument,
  getDocumentsList,
  uploadDocument,
} from "../../../actions/Horse/MyHorseActions";
import binIcon from "../../../Assets/img/bin.svg";
import DownloadIcon from "../../../Assets/img/download.svg";
import EyeIcon from "../../../Assets/img/eye.svg";
import { API_STATUS, END_POINTS } from "../../../Constant/Api";
import {
  SUCCESS_RESPONSE_CODE,
  SUCCESS_RESPONSE_STATUS,
} from "../../../Constant/Status";
import { useLoading } from "../../../context/LoadingContext";
import { getTokan } from "../../../Interceptor/Interceptor";
import {
  encrDecrStr,
  isFileValidated,
  openDocument,
} from "../../../Utility/General";

import FileUploadImage from "../../Common/FileUploadImage/FileUploadImage";
import Loader from "../../Loader/Loader";
import ConfirmPopup from "../../Popups/ConfirmPopup/ConfirmPopup";

export default function DocumentsPage() {
  const { t, i18n } = useTranslation();

  const [documentList, setDocumentList] = useState([]);
  const [horseId, setHorseId] = React.useState<any>(0);
  const [deletableRecords, setDeletableRecords] = React.useState<any>([]);
  const [confirmPopupShow, setConfirmPopupShow] = React.useState(false);
  const [apiStatus, setApiStatus] = useState(API_STATUS.SUCCESS);
   const { setLoading} =  useLoading();
  const param = useParams();

  const allowedFormate = ["jpg", "png", "pdf", "docx" , "heic" , "heif" , "jpeg"];
  const allowedFileSizeInMb = 4;

  const listApiCall = () => {
    let id = encrDecrStr(param?.slug, "decr");
    setHorseId(id);
    let params = {
      horse_id: id,
    };
    setApiStatus(API_STATUS.LOADING);
    setLoading(true);
    getDocumentsList(params)
      .then((response: any) => {
        if (response.statusCode === SUCCESS_RESPONSE_CODE) {
          setApiStatus(API_STATUS.SUCCESS);
          setLoading(false);
          setDocumentList(response?.component?.listing);
        } else {
          setApiStatus(API_STATUS.FAILED);
          setLoading(false);
        }
      })
      .catch((error) => {
        setApiStatus(API_STATUS.FAILED);
        setLoading(false);
      });
  };

  useEffect(() => {
    listApiCall();
  }, []);

  const handleDocumentUpload = (e: any, doc_key: any) => {
    if (
      !isFileValidated(e.target.files[0], allowedFormate, allowedFileSizeInMb)
    ) {
      return;
    }
    const formData = new FormData();
    formData.append("horse_id", horseId);
    formData.append("doc_key", doc_key);
    formData.append("doc_name", e.target.files[0]);
    setLoading(true);
    uploadDocument(formData)
      .then((response: any) => {
        if (response.statusCode === SUCCESS_RESPONSE_CODE) {
          setApiStatus(API_STATUS.SUCCESS);
          setLoading(false);
          listApiCall();
        } else {
          setApiStatus(API_STATUS.FAILED);
          setLoading(false);
        }
      })
      .catch((error) => {
        setApiStatus(API_STATUS.FAILED);
        setLoading(false);
      });
  };

  const deleteHandler = (id: number) => {
    let params = {
      id: deletableRecords,
    };

    setApiStatus(API_STATUS.LOADING);
    setLoading(true);
    destroyDocument(params)
      .then((response: any) => {
        if (response.statusCode === SUCCESS_RESPONSE_CODE) {
          setApiStatus(API_STATUS.SUCCESS);
          setLoading(false);
          listApiCall();
          setConfirmPopupShow(false);
        } else {
          setApiStatus(API_STATUS.FAILED);
          setLoading(false);
        }
      })
      .catch((error) => {
        setApiStatus(API_STATUS.FAILED);
        setLoading(false);
      });
  };

  const deleteNow = (id: number) => {
    if (id) {
      let ids = deletableRecords;
      ids.push(id);
      setDeletableRecords(ids);
      setConfirmPopupShow(true);
    }
  };

  const download = (id: any,fileName:any) => {
    const formData = new FormData();
    formData.append("id", id);
    downloadDocument(formData, fileName);
  };

  return (
    <>
      <div className="documents-horse-page">
        <div className="data-tbl-wrapper">
          <div className="data-tbl-header">
            <p className="tl">{t("Documents")}</p>
          </div>
          <div className="data-tbl">
            <table>
              <thead>
                <tr>
                  <th>
                    <p className="tm">{t("Document Type")}</p>
                  </th>
                  <th>
                    <p className="tm">{t("Date")}</p>
                  </th>
                  <th className="text-right">
                    <p className="tm">{t("Action")}</p>
                  </th>
                </tr>
              </thead>
              <tbody>
                {documentList &&
                  documentList.length > 0 &&
                  documentList.map((items: any, index: number) => (
                    <tr key={index + "vaccination-data"}>
                      <td>
                        <p className="bl">{items.document_type}</p>
                      </td>
                      <td>
                        <p className="bl">{items.created_at}</p>
                      </td>
                      <td>
                        <div className="icon-wrapper">
                          {items.doc_name ? (
                            <>
                              <a onClick={() => openDocument(items.doc_name)}>
                                <img src={EyeIcon} alt="" />
                              </a>
                              <a
                                // href={items.doc_name}
                                onClick={() =>
                                  download(items.id, items.document_key)
                                }
                                target="_blank"
                                download
                              >
                                <img src={DownloadIcon} alt="" />
                              </a>
                            </>
                          ) : (
                            ""
                          )}
                          {!items?.doc_name && (
                            <a>
                              <FileUploadImage
                                onChange={(e: any) =>
                                  handleDocumentUpload(e, items.document_key)
                                }
                              />
                            </a>
                          )}
                          {items?.doc_name && (
                            <a>
                              <img
                                src={binIcon}
                                onClick={() => deleteNow(items?.id)}
                                alt=""
                              />
                            </a>
                          )}
                        </div>
                      </td>
                    </tr>
                  ))}
              </tbody>
            </table>
          </div>
        </div>
      </div>

      <ConfirmPopup
        heading={t("Delete")}
        message={"Are you sure to delete this File?"}
        show={confirmPopupShow}
        onHide={() => {
          setConfirmPopupShow(false);
          setDeletableRecords([]);
        }}
        confirm={deleteHandler}
      />
    </>
  );
}
