import { t } from 'i18next';
import React from 'react'
import { useSelector } from 'react-redux';
import { useOutletContext } from 'react-router-dom';
import binIcon from "../../../Assets/img/bin.svg";
import pencilIcon from "../../../Assets/img/pencil.svg";
import { useLoading } from '../../../context/LoadingContext';
import { viewEmbryoTransfer } from '../../../Redux/NonRedux/breeding';

export default function EmbryoTransferDetails({
  listData,
  serviceId,
  editHandler,
  metaData
}: any) {

  const prefrence = useSelector(
    (state: any) => state?.masterReducer?.data?.component?.preferance
  );

  return (
    <React.Fragment>
      <div className="data-tbl-wrapper">
        <div className="data-tbl-header">
          <p className="tl">{t("Embryo Transfer")}</p>
        </div>
        <div className="data-tbl">
          <table>
            <thead>
              <tr>
                <th>
                  <p className="tm">{t("Date")}</p>
                </th>
                <th>
                  <p className="tm">{t("Vet/Clinic")}</p>
                </th>
                <th>
                  <p className="tm">{t("Donor")}</p>
                </th>
                <th>
                  <p className="tm">{t("Stallion")}</p>
                </th>
                <th>
                  <p className="tm">{t("Recipient Mare")}</p>
                </th>
                <th>
                  <p className="tm">{t("Embryo Age (Days)")}</p>
                </th>
                <th>
                  <p className="tm">{t("Amount")}</p>
                </th>
                <th className="text-right">
                  <p className="tm">{t("Action")}</p>
                </th>
              </tr>
            </thead>
            <tbody>
              {listData.map((items: any, index: number) => (
                <tr key={index + "breeding-conteol-mare"}>
                  <td>
                    <p className="bl">{items?.dates}</p>
                  </td>
                  <td>
                    <p className="bl">{items?.vet_clinic}</p>
                  </td>
                  <td>
                    <p className="bl">{items?.doner}</p>
                  </td>
                  <td>
                    <p className="bl">{items?.stallion}</p>
                  </td>
                  <td>
                    <p className="bl">{items.recipient_mare}</p>
                  </td>
                  <td>
                    <p className="bl">{items.embryo_age}</p>
                  </td>
                  <td>
                    <p className="bl">{items?.amount} {prefrence?.currency_value}</p>
                  </td>
                  <td>
                    <div className="icon-wrapper">
                      {metaData?.activeService === serviceId && (
                        <a>
                          <img src={pencilIcon} onClick={() => editHandler(items?.id)} alt="" />
                        </a>
                      )}

                      {/* {
                        metaData?.activeService == currentServiceId && (
                          <a>
                            <img src={binIcon} onClick={() => deleteHandler(items?.id)} alt="" />
                          </a>
                        )
                      } */}

                    </div>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
    </React.Fragment>
  )
}
