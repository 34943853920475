import React, { useState, useEffect, useRef } from "react";
import { Line } from "react-chartjs-2";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import { t } from "i18next";

export default function Chart(props:any) {
  const [data, setData] = useState<any>([]);
  const [configOption, setConfigOption] = useState<any>([]);
  const chartRef:any = useRef();
  ChartJS.register(
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Legend,

  );


  useEffect(() => {
    if (props?.data) {
      let dataConfig:any = Object.assign({}, props?.dataConfig);
      dataConfig.datasets[0].data = props?.data;
      setData(dataConfig);
    }
  }, [props?.data]);

  useEffect(() => {
    let ob = Object.assign({}, props?.options);
    ob.scales.yAxis.title.text = props?.unit;
    ob.scales.xAxis.title.text = t(ob.scales.xAxis.title.text);
    setConfigOption(ob)
  }, [props?.options])

  useEffect(() => {
    if (data && chartRef.current) {
      chartRef.current.update('active')
    }
  }, [data])


  return (
    <>
      {data?.datasets?.[0]?.data?.length > 0 && (
        <Line ref={chartRef} options={configOption} data={data} />
      )}
    </>
  );
}
