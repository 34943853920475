
export const ADD_DIET_REQUEST = "ADD_DIET_REQUEST";
export const ADD_DIET_SUCCESS = "ADD_DIET_SUCCESS";
export const ADD_DIET_FAILD = "ADD_DIET_FAILD";
export const ADD_DIET_LONG = "ADD_DIET_LONG";
export const ADD_DIET_CLEAR = "ADD_DIET_CLEAR";

export const GET_DIET_LIST_REQUEST = "GET_DIET_LIST_REQUEST";
export const GET_DIET_LIST_SUCCESS = "GET_DIET_LIST_SUCCESS";
export const GET_DIET_LIST_FAILD = "GET_DIET_LIST_FAILD";
export const GET_DIET_LIST_LONG = "GET_DIET_LIST_LONG";
export const GET_DIET_LIST_CLEAR = "GET_DIET_LIST_CLEAR";

export const DESTROY_DIET_REQUEST = "DESTROY_DIET_REQUEST";
export const DESTROY_DIET_SUCCESS = "DESTROY_DIET_SUCCESS";
export const DESTROY_DIET_FAILD = "DESTROY_DIET_FAILD";
export const DESTROY_DIET_LONG = "DESTROY_DIET_LONG";
export const DESTROY_DIET_CLEAR = "DESTROY_DIET_CLEAR";

export const VIEW_DIET_REQUEST = "VIEW_DIET_REQUEST";
export const VIEW_DIET_SUCCESS = "VIEW_DIET_SUCCESS";
export const VIEW_DIET_FAILD = "VIEW_DIET_FAILD";
export const VIEW_DIET_LONG = "VIEW_DIET_LONG";
export const VIEW_DIET_CLEAR = "VIEW_DIET_CLEAR";