import { t } from 'i18next';
import React from 'react'
import Modal from "react-bootstrap/Modal";

export default function TaskDescriptionPopup(props:any) {
  return (
    <Modal
    {...props}
    centered
    className="record-payment-status-popup modal-416"
  >
    <Modal.Header closeButton>
      <p className="tl">{t("Task Description")}</p>
    </Modal.Header>
    <Modal.Body>
      <div>
        <p className="tm">{props?.description}</p>
      </div>
    </Modal.Body>
  </Modal>
  )
}
