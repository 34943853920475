import { MAX_CALLS } from "../Constant/Api";
import { DOWNLOADEXT, ERROR } from "../Constant/General";
import { SUCCESS_RESPONSE_CODE } from "../Constant/Status";
import { getTokan } from "../Interceptor/Interceptor";
import api from "../Service/Axios";
import { showToast } from "../Utility/General";

export function globalPostAction(
  param: any,
  apiUrl: any,
  showmessage: boolean = false
) {
  let recallCount = 0;
  return new Promise((resolve, reject) => {
    function recursiveCall() {
      api
        .post(apiUrl, param)
        .then((result) => {
          if (result.data.statusCode === SUCCESS_RESPONSE_CODE) {
            if (showmessage) {
              showToast("success", result.data.message);
            }
            resolve(result.data);
          } else if (recallCount < MAX_CALLS) {
            recursiveCall();
            recallCount++;
          } else {
            showToast("error", result.data.message);
            reject(result.data.message);
          }
        })
        .catch((error) => {
          showToast(ERROR, "Something went wrong Please try again");
          if (recallCount < MAX_CALLS) {
            recursiveCall();
            recallCount++;
          } else {
            reject(error);
          }
        });
    }
    recursiveCall();
  });
}

export function globalGetAction(apiUrl: any) {
  let recallCount = 0;
  return new Promise((resolve, reject) => {
    function recursiveCall() {
      api
        .get(apiUrl, {})
        .then((result) => {
          if (result.data.statusCode === SUCCESS_RESPONSE_CODE) {
            resolve(result.data);
          } else if (recallCount < MAX_CALLS) {
            recursiveCall();
            recallCount++;
          } else {
            showToast("error", result.data.message);
            reject(result.data.message);
          }
        })
        .catch((error) => {
          showToast("error", error);
          if (recallCount < MAX_CALLS) {
            recursiveCall();
            recallCount++;
          } else {
            reject(error);
          }
        });
    }
    recursiveCall();
  });
}

export function globalDownloadAction(param: any, apiUrl: any, fileName: any) {
  return new Promise((resolve , reject) => {
    var myHeaders = new Headers();
    myHeaders.append("Accept", "*/*");
    myHeaders.append("Authorization", getTokan());
    let requestOptions: any = {
      method: "POST",
      body: param,
      headers: myHeaders,
      redirect: "follow",
    };
    fetch(apiUrl, requestOptions)
      .then((response) => {
        return response.blob().then((blob) => {
          return {
            contentType: response.headers.get("Content-Type"),
            raw: blob,
          };
        });
      })
      .then((data) => {
        let ext: any = data.contentType;
        let extList: any = DOWNLOADEXT;
        ext = extList[ext];
        const link = document.createElement("a");
        link.href = URL.createObjectURL(data.raw);
          
        if(typeof ext !== "undefined"){
          link.download = fileName + "." + ext;
          link.click();
          resolve("");
        }
        else{
          showToast(ERROR , "Something went wrong in dowload file.")
          reject("Something went wrong in dowload file.");
        }
        // imageHandler(data.contentType, data.raw);
      }).catch((error:any) => {
        reject(error?.message);
      });
  });
 
}
