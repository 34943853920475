import React from "react";

export default function Horse() {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M13.8788 9.4125C13.8788 9.8625 13.5413 10.2 13.0913 10.2C12.6413 10.2 12.3038 9.8625 12.3038 9.4125C12.3038 8.9625 12.6413 8.625 13.0913 8.625C13.5413 8.625 13.8788 8.9625 13.8788 9.4125Z"
        fill="#919EAB"
      />
      <path
        d="M11.8537 14.3625C12.4162 14.7 13.0912 14.925 13.7662 14.925C14.3287 14.925 14.7787 14.8125 15.2287 14.5875L16.6912 15.4875C17.9287 16.275 19.6162 15.6 20.0662 14.25C20.2912 13.575 20.5162 13.0125 20.6287 12.3375L14.4412 6.15C14.7787 5.5875 15.1162 5.025 15.5662 4.4625C14.2162 4.575 12.9787 4.6875 11.7412 4.9125C11.8537 4.2375 11.9662 3.675 12.0787 3C9.60374 3.7875 7.35374 5.1375 5.55374 6.825C2.62874 9.525 2.62874 14.025 5.55374 16.725C7.01624 18.075 8.59124 19.0875 10.3912 19.875C11.5162 20.325 12.6412 20.775 13.8787 21"
        stroke="#919EAB"
        strokeWidth="1.5"
        strokeMiterlimit="13.3333"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}
