import type { DatePickerProps } from "antd";
import { t } from "i18next";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { Col, Row } from "react-bootstrap";
import Modal from "react-bootstrap/Modal";
import { useSelector, useDispatch } from "react-redux";
import { useOutletContext } from "react-router-dom";
import { API_STATUS } from "../../../Constant/Api";
import { ERROR } from "../../../Constant/General";
import { SUCCESS_RESPONSE_CODE } from "../../../Constant/Status";
import { useLoading } from "../../../context/LoadingContext";
import { metaHorseListAction } from "../../../Redux/General/GeneralAction";
import { getInstantMeta } from "../../../Redux/globallActions";
import { addExpenses } from "../../../Redux/NonRedux/incomeExpense";
import { showToast } from "../../../Utility/General";
import validate from "../../../Utility/Validation";
import CustomeButton from "../../Common/CustomButton/CustomButton";
import Datepicker from "../../Common/Datepicker/Datepicker";
import FileUpload from "../../Common/FileUpload/FileUpload";
import InputGroups from "../../Common/InputGroups/InputGroups";
import InputSelectMix from "../../Common/InputSelectMix/InputSelectMix";
import SelectGropus from "../../Common/SelectGroups/SelectGroups";
import TextareaGroups from "../../Common/TextareaGroups/TextareaGroups";
import TextButton from "../../Common/TextButton/TextButton";
import ToggleButton from "../../Common/ToggleButton/ToggleButton";

export default function AddCostRecordPopup(props: any) {
  const INTIAL_EXPENSES_DATA = {
    id: {
      value: "",
    },
    date: {
      value: moment().format("YYYY-MM-DD"),
      validation: ["required"],
      errors: [t("Date is required.")],
    },
    category_id: {
      value: "",
      validation: ["required"],
      errors: [t("Category is required.")],
    },
    horse_id: {
      value: "",
      validation: [""],
      errors: [t("Horse is required.")],
    },
    sub_category_id: {
      value: "",
      validation: [""],
      errors: [t("Sub Category is required.")],
    },
    description: {
      value: "",
      validation: [""],
      errors: [t("description is required.")],
    },
    amount: {
      value: "",
      validation: ["required"],
      errors: [t("Amount is required.")],
    },
    paid_status: {
      value: "1",
      validation: [""],
      errors: [""],
    },
  };

  const dateChange: DatePickerProps["onChange"] = (date, dateString) => {
    let dateIs = date?.format("YYYY-MM-DD");
    setData("date", dateIs);
  };

  const prefrence = useSelector(
    (state: any) => state?.masterReducer?.data?.component?.preferance
  );

  const [selected, setSelected] = useState(t("Yes"));

  const [expenseData, setExpenseData] = useState<any>(INTIAL_EXPENSES_DATA);
  const [isFormValidated, setIsFormValidated] = useState(false);
  const [expenseDataError, setExpenseDataError] = useState<any>({});
  const [apiStatus, setApiStatus] = useState(API_STATUS.SUCCESS);
  const [subCategorylist, setSubCategory] = useState([]);
  const { setLoading } = useLoading();
  const horseList = useSelector(
    (state: any) => state?.metaHorseListReducer?.data?.component
  );
  const incomeCategoryList = useSelector(
    (state: any) => state?.incomeCategoryReducer?.data?.component
  );

  const setData = (field: string, value: any) => {
    const ob: any = Object.assign({}, expenseData);
    ob[field].value = value;
    setExpenseData({ ...ob });
  };

  const submitHandler = () => {
    if (validate(expenseData).isValidated === true) {
      let params: any = {};
      Object.keys(expenseData).forEach((element) => {
        if (expenseData[element]) {
          params[element] = expenseData[element].value;
        }
      });
      setLoading(true);
      addExpenses(params)
        .then((response: any) => {
          if (response.statusCode === SUCCESS_RESPONSE_CODE) {
            setApiStatus(API_STATUS.SUCCESS);
            props.onHide();
            props.setRefreshList(true);
          } else {
            setApiStatus(API_STATUS.FAILED);
          }
        })
        .catch((error) => {
          setApiStatus(API_STATUS.FAILED);
        })
        .finally(() => {
          setLoading(false);
        });
    } else {
      setExpenseDataError({ ...validate(expenseData)?.errors });
      setIsFormValidated(true);
    }
  };

  useEffect(() => {
    if (props?.show === false) {
      setExpenseDataError({});
      setExpenseData(INTIAL_EXPENSES_DATA);
      setIsFormValidated(false);
    }
  }, [props?.show]);

  useEffect(() => {
    if (props?.categoryId) {
      setData("category_id", props?.categoryId);
    }
  }, [props?.categoryId]);

  useEffect(() => {
    setExpenseDataError({ ...validate(expenseData).errors });
  }, [expenseData]);

  useEffect(() => {
    if (props.isedit && props?.editData) {
      Object.keys(expenseData).forEach((element) => {
        if (expenseData[element]) {
          setData(element, `${props?.editData?.[element]}`);
        }
      });
      setData("amount", props?.editData?.amount);
      setData("date", props?.editData?.date_format);
    }
  }, [props?.editData]);

  useEffect(() => {
    let expenseCategoryId = expenseData?.category_id?.value;
    if (expenseCategoryId) {
      const currentCategory = metaData?.expence_category.find(
        (item: any) => item.id == expenseCategoryId
      );
      let params = {
        type: currentCategory?.slug,
      };
      if (params.type) {
        getInstantMeta(params)
          .then((res: any) => {
            setSubCategory(res?.component);
          })
          .catch(() => {
            showToast(ERROR, t("failed to fetch subcategory"));
          });
      }
    }
  }, [expenseData?.category_id?.value]);

  const { setRefreshList, editData, metaData, categoryId, ...rest } = props;

  return (
    <Modal {...rest} centered className="add-cost-record-popup modal-784">
      <Modal.Header closeButton>
        <p className="tl">
          {props?.isedit ? t("Edit Cost Record") : t("Add Cost Record")}{" "}
        </p>
      </Modal.Header>
      <Modal.Body>
        <Row>
          <Col sm={6} md={6}>
            <Datepicker
              label={`${t("Date")}*`}
              endDate={moment().format("YYYY-MM-DD")}
              onChange={dateChange}
              value={moment(expenseData?.date?.value)}
              error={
                isFormValidated && expenseDataError["date"]
                  ? expenseDataError["date"]
                  : null
              }
            />
          </Col>
        </Row>
        <Row>
          <Col sm={6} md={6}>
            <SelectGropus
              label={`${t("Category")}*`}
              onChange={(e: any) => setData("category_id", e.target.value)}
              dropKey="id"
              value={metaData?.expence_category}
              dropVal="metadata"
              defaultSelect={expenseData?.category_id?.value}
              error={
                isFormValidated && expenseDataError["category_id"]
                  ? expenseDataError["category_id"]
                  : null
              }
            />
          </Col>
          <Col sm={6} md={6}>
            <SelectGropus
              label={`${t("Horse")}`}
              value={metaData?.filter_horse_list}
              onChange={(e: any) => setData("horse_id", e.target.value)}
              dropKey="id"
              dropVal="name"
              defaultSelect={expenseData?.horse_id?.value}
              error={
                isFormValidated && expenseDataError["horse_id"]
                  ? expenseDataError["horse_id"]
                  : null
              }
            />
          </Col>

          {expenseData?.category_id.value != "" && (
            <Col sm={6} md={6}>
              <SelectGropus
                label={`${t("Sub Category")}*`}
                value={subCategorylist}
                onChange={(e: any) =>
                  setData("sub_category_id", e.target.value)
                }
                dropKey="id"
                dropVal="metadata"
                defaultSelect={expenseData?.sub_category_id?.value}
                error={
                  isFormValidated && expenseDataError["sub_category_id"]
                    ? expenseDataError["sub_category_id"]
                    : null
                }
              />
            </Col>
          )}

          <Col sm={6} md={6}>
            <TextareaGroups
              label={`${t("Description")}`}
              type="text"
              autoComplete="off"
              value={expenseData?.description?.value}
              onChange={(e: any) => setData("description", e.target.value)}
              error={
                isFormValidated && expenseDataError["description"]
                  ? expenseDataError["description"]
                  : null
              }
            />
          </Col>
        </Row>
        <Row>
          <Col sm={6} md={6}>
            <InputSelectMix
              label={`${t("Amount")}*`}
              inputType="number"
              inputValue={expenseData?.amount?.value}
              onInputChange={(e: any) => setData("amount", e.target.value)}
              inputError={
                isFormValidated && expenseDataError["amount"]
                  ? expenseDataError["amount"]
                  : null
              }
              inputSecondValue={prefrence?.currency_value}
            />
          </Col>
        </Row>
        <Row>
          <Col sm={6} md={6}>
            <ToggleButton
              label={`${t("Paid")}`}
              toggleKey="key"
              toggleVal="value"
              value={metaData?.paid_status}
              selected={expenseData?.paid_status?.value}
              setSelected={(value: any) => {
                setSelected(value);
                setData("paid_status", value);
              }}
            />
          </Col>
        </Row>
      </Modal.Body>
      <Modal.Footer>
        <div className="footer-2-btn">
          <TextButton onClick={props.onHide}> {t("Cancel")}</TextButton>
          <CustomeButton bg="fill" onClick={submitHandler}>
            {props?.isedit ? t("Update") : t("Add")}
          </CustomeButton>
        </div>
      </Modal.Footer>
    </Modal>
  );
}
