import React from "react";
import Modal from "react-bootstrap/Modal";
import Calculator from "../../Calculator/Calculator";

export default function CalculatorPopup(props: any) {
  const handleInput = (input: any) => {
   
  };

  const onResultChange = (newResult: any) => {
    
  };

  return (
    <Modal {...props} centered className="calculator-popup modal-416">
      <Modal.Body>
      <Calculator />
      </Modal.Body>
      <Modal.Footer>
     
      </Modal.Footer>
    </Modal>
  );
}
