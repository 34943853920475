import type { DatePickerProps } from "antd";
import { t } from "i18next";
import moment from "moment";
import React, { useEffect, useState } from "react";
import Modal from "react-bootstrap/Modal";
import { useDispatch, useSelector } from "react-redux";
import { SUCCESS_RESPONSE_CODE } from "../../../Constant/Status";
import { useLoading } from "../../../context/LoadingContext";
import { metaHorseListAction } from "../../../Redux/General/GeneralAction";
import { addEditTask } from "../../../Redux/NonRedux/taskApi";
import validate from "../../../Utility/Validation";
import CustomeButton from "../../Common/CustomButton/CustomButton";
import Datepicker from "../../Common/Datepicker/Datepicker";
import InputGroups from "../../Common/InputGroups/InputGroups";
import InputSelectGroup from "../../Common/InputSelectGroup/InputSelectGroup";
import MultiSelectGroup from "../../Common/MultiSelectGroup/MultiSelectGroup";
import SelectGropus from "../../Common/SelectGroups/SelectGroups";
import TextareaGroups from "../../Common/TextareaGroups/TextareaGroups";
import TextButton from "../../Common/TextButton/TextButton";
import ToggleButton from "../../Common/ToggleButton/ToggleButton";

export default function AddTaskPopup(props: any) {
  const INITIAL_TASK_DATA: any = {
    date: {
      value: moment().format("YYYY-MM-DD"),
      validation: ["required"],
      errors: [t("date is required.")],
    },
    title: {
      value: "",
      validation: ["required"],
      errors: [t("Title is required.")],
    },
    description: {
      value: "",
      validation: [""],
      errors: [t("Description is required.")],
    },
    task_type: {
      value: "",
      validation: ["required"],
      errors: [t("Task Type is required.")],
    },
    status: {
      value: "0",
      validation: ["required"],
      errors: [t("status is required.")],
    },
    recurence_status: {
      value: "1",
      validation: ["required"],
      errors: [t("Recurence status is required.")],
    },
    select_person: {
      value: "person_id",
      validation: [""],
      errors: [t("")],
    },
    person_id: {
      value: "",
      validation: ["required"],
      errors: [t("Person Assigned is required.")],
    },
    recurence: {
      value: "",
      validation: ["required"],
      errors: [t("Recurrence is required.")],
    },
    related_horses: {
      value: [],
      validation: ["requiredArray"],
      errors: [t("Related Horses is required.")],
    },
    id: {
      value: "",
    },
  };

  const [taskData, setTaskData] = useState(INITIAL_TASK_DATA);
  const [isFormValidated, setIsFormValidated] = useState(false);
  const { setLoading } = useLoading();
  let demo: any = {};
  const [taskDataError, setTaskDataError] = useState(demo);
  const dispatch = useDispatch();
  const setData = (field: string, value: string) => {
    let taskDetails = Object.assign({}, taskData);
    taskDetails[field].value = value;
    setTaskData({ ...taskDetails });
  };

  useEffect(() => {
    setTaskDataError({ ...validate(taskData)?.errors });
  }, [taskData]);

  const taskSubmitHandler = () => {
    if (validate(taskData).isValidated === true) {
      setLoading(true);
      let params: any = {};
      Object.keys(taskData).forEach((element) => {
        if (taskData[element].value) {
          params[element] = taskData[element].value;
        }
      });

      addEditTask(params)
        .then((response: any) => {
          if (response.statusCode === SUCCESS_RESPONSE_CODE) {
            props.onHide();
            setTaskData(INITIAL_TASK_DATA);
            setTaskDataError({});
            props.setrefreshlist();
          }
          
        })
        .catch((error: any) => {}).finally(() => {
          setLoading(false)
        });
    } else {
      setLoading(false);
      setTaskDataError({ ...validate(taskData)?.errors });
      setIsFormValidated(true);
    }
  };

  useEffect(() => {
    if (props?.show === false) {
      setTaskDataError({});
      setTaskData(INITIAL_TASK_DATA);
      setIsFormValidated(false);
    }
  }, [props?.show]);

  useEffect(() => {
    if (props.isedit && props?.editData) {
      Object.keys(taskData).forEach((element) => {
        setData(element, props?.editData?.[element]);
      });
      setData("id", props?.editData?.task_schedule_id);
      setData("date", props?.editData?.date_unformated);
    }
  }, [props?.editData]);

  const dateChange: DatePickerProps["onChange"] = (date, dateString) => {
    let dateIs: any = date?.format("YYYY-MM-DD");
    setData("date", dateIs);
  };


  const { metaData, editData, setrefreshlist, ...rest } = props;

  return (
    <Modal {...rest} centered className="add-task-popup modal-416">
      <Modal.Header closeButton>
        <p className="tl">{props.isedit ? t("Edit Task") : t("Add Task")} </p>
      </Modal.Header>
      <Modal.Body>
        <div>
          <Datepicker
            label={`${t("Date")}*`}
            endDate={moment().format("YYYY-MM-DD")}
            onChange={dateChange}
            value={moment(taskData?.date?.value)}
            error={
              isFormValidated && taskDataError["date"]
                ? taskDataError["date"]
                : null
            }
          />
          <InputGroups
            label={`${t("Title")}*`}
            type="text"
            autoComplete="off"
            value={taskData?.title?.value}
            onChange={(e: any) => setData("title", e.target.value)}
            error={
              isFormValidated && taskDataError["title"]
                ? taskDataError["title"]
                : null
            }
          />
          <TextareaGroups
            label={`${t("Description")}`}
            autoComplete="off"
            value={taskData?.description?.value}
            onChange={(e: any) => setData("description", e.target.value)}
            error={
              isFormValidated && taskDataError["description"]
                ? taskDataError["description"]
                : null
            }
          />
          <SelectGropus
            label={`${t("Task Type")}*`}
            dropKey="id"
            dropVal="metadata"
            value={metaData?.task_type}
            defaultSelect={taskData?.task_type?.value}
            onChange={(e: any) => setData("task_type", e.target.value)}
            error={
              isFormValidated && taskDataError["task_type"]
                ? taskDataError["task_type"]
                : null
            }
          />
          <ToggleButton
            label={`${t("Status")}*`}
            toggleKey="key"
            toggleVal="value"
            value={metaData?.task_status}
            selected={taskData?.status?.value}
            setSelected={(value: any) => {
              setData("status", value);
            }}
          />

          <SelectGropus
            label={`${t("Person Assigned")}*`}
            dropKey="id"
            dropVal="first_name"
            value={metaData?.person_assigned}
            defaultSelect={taskData?.person_id?.value}
            onChange={(e: any) => setData("person_id", e.target.value)}
            error={
              isFormValidated && taskDataError["person_id"]
                ? taskDataError["person_id"]
                : null
            }
          />

          <SelectGropus
            label={`${t("Recurrence")}*`}
            dropKey="key"
            dropVal="value"
            value={metaData?.recurence_list}
            defaultSelect={taskData?.recurence?.value}
            onChange={(e: any) => setData("recurence", e.target.value)}
            error={
              isFormValidated && taskDataError["recurence"]
                ? taskDataError["recurence"]
                : null
            }
          />
          <MultiSelectGroup
            label={`${t("Related Horses")}*`}
            value={metaData?.horse_list}
            defaultSelect={taskData?.related_horses?.value?.map((item: any) =>
              parseInt(item)
            )}
            dropKey="id"
            dropVal="name"
            onChange={(e: any) => {
              setData("related_horses",e);
            }}
            error={
              isFormValidated && taskDataError["related_horses"]
                ? taskDataError["related_horses"]
                : null
            }
          />
        </div>
      </Modal.Body>
      <Modal.Footer>
        <div className="footer-2-btn">
          <TextButton onClick={props?.onHide}> {t("Cancel")}</TextButton>
          <CustomeButton bg="fill" onClick={() => taskSubmitHandler()}>
            {props.isedit ? t("Update") : t("Add")}
          </CustomeButton>
        </div>
      </Modal.Footer>
    </Modal>
  );
}
