import React from "react";

export default function OpenIcon() {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M11.4565 13.0098C11.2391 12.7924 11.1304 12.4663 11.1304 12.1402C11.1304 11.8141 11.2391 11.4881 11.4565 11.2707C11.6739 11.0533 12 10.9446 12.3261 10.9446C12.6521 10.9446 12.9782 11.0533 13.1956 11.2707L13.3043 11.3794L13.413 11.2707C13.6304 11.0533 13.9565 10.9446 14.2826 10.9446C14.6087 10.9446 14.9347 11.0533 15.1521 11.2707C15.3695 11.4881 15.4782 11.8141 15.4782 12.1402C15.4782 12.4663 15.3695 12.7924 15.1521 13.0098L13.413 14.6402C13.3043 14.7489 13.3043 14.7489 13.3043 14.7489C13.3043 14.7489 13.1956 14.7489 13.1956 14.6402L11.4565 13.0098Z"
        fill="#E8E5E2"
      />
      <path
        d="M18.9565 11.3793C18.9565 14.7489 16.2391 17.4663 12.8695 17.4663C10.4782 17.4663 8.41301 16.1619 7.43475 14.0967"
        stroke="#919EAB"
        strokeWidth="1.5"
        strokeMiterlimit="13.3333"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M7.10869 15.8358C7.10869 15.8358 6.02174 17.2489 4.28261 16.7054C4.28261 16.7054 3.52174 14.4228 2 13.5532V6.92278C2 6.92278 7.32609 9.42278 12 7.46626C16.3478 5.72713 19.3913 6.81409 22 7.35757"
        stroke="#919EAB"
        strokeWidth="1.1734"
        strokeMiterlimit="13.3333"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M18.3044 14.0967C18.3044 14.0967 18.8478 15.9445 20.6957 17.0315"
        stroke="#919EAB"
        strokeWidth="1.1734"
        strokeMiterlimit="13.3333"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}
