import React from "react";

export default function SyringeIcon() {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M20.9736 3L16.9958 6.9778"
        stroke="#919EAB"
        strokeWidth="1.5"
        strokeMiterlimit="13.3333"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M9.13895 16.4619L5.45221 20.1487"
        stroke="#919EAB"
        strokeWidth="1.5"
        strokeMiterlimit="13.3333"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M3.81366 18.5616L7.5004 14.8748"
        stroke="#919EAB"
        strokeWidth="1.5"
        strokeMiterlimit="13.3333"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M3.02499 17.7983L6.22663 21"
        stroke="#919EAB"
        strokeWidth="1.5"
        strokeMiterlimit="13.3333"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M9.13899 16.4619C10.0122 17.3351 10.6913 18.0142 10.6913 18.0142C10.2062 17.5291 10.2062 16.85 10.6913 16.4619L17.6767 9.47648V8.99139C17.6767 8.31225 17.3856 7.63312 16.9005 7.051C16.4154 6.5659 15.7363 6.27484 14.9602 6.27484H14.4751L7.48966 13.2602C7.00457 13.7453 6.32542 13.7453 5.93735 13.2602C5.93735 13.2602 7.6837 15.1036 9.13899 16.4619Z"
        stroke="#919EAB"
        strokeWidth="1.5"
        strokeMiterlimit="13.3333"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M8.35028 12.451L11.5519 15.6526"
        stroke="#919EAB"
        strokeWidth="1.5"
        strokeMiterlimit="13.3333"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M12.6826 12.2445L13.4587 13.0207"
        stroke="#919EAB"
        strokeWidth="1.5"
        strokeMiterlimit="13.3333"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M14.6749 10.2581L15.4511 11.0342"
        stroke="#919EAB"
        strokeWidth="1.5"
        strokeMiterlimit="13.3333"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}
