import React, { useEffect, useState } from "react";
import { Col, Row } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { useOutletContext, useParams } from "react-router-dom";
import {
  getCompletePedigree,
  uploadDocument,
} from "../../../actions/Horse/MyHorseActions";
import FemaleIcon from "../../../Assets/img/Female.svg";
import openMareIcon from "../../../Assets/img/open.svg";
import MaleIcon from "../../../Assets/img/Male.svg";
import PregnantIconSmall from "../../../Assets/img/pregnant-small.svg";
import { REFRESH } from "../../../Constant/General";
import {
  encrDecrStr,
  getSlugNamefromId,
  isFileValidated,
  openDocument,
} from "../../../Utility/General";
import AddEditHorsePopup from "../../Popups/AddEditHorsePopup/AddEditHorsePopup";
import AddEmailPopup from "../../Popups/AddEmailPopup/AddEmailPopup";
import ChangeProfilePopup from "../../Popups/ChangeProfilePopup/ChangeProfilePopup";
import CompletePedigreePopup from "../../Popups/CompletePedigreePopup/CompletePedigreePopup";
import EditInsurancePopup from "../../Popups/EditInsurancePopup/EditInsurancePopup";
import EditPedigreePopup from "../../Popups/EditPedigreePopup/EditPedigreePopup";
import EditStallionStatusPopup from "../../Popups/EditStallionStatusPopup/EditStallionStatusPopup";
import HorseHistoryPopup from "../../Popups/HorseHistoryPopup/HorseHistoryPopup";
import HorseNextActivitiesPopup from "../../Popups/HorseNextActivitiesPopup/HorseNextActivitiesPopup";
import PregnancyStatusPopup from "../../Popups/PregnancyStatusPopup/PregnancyStatusPopup";
import ToggleButton from "../../Common/ToggleButton/ToggleButton";
import { useLoading } from "../../../context/LoadingContext";
import FileUploadImage from "../../Common/FileUploadImage/FileUploadImage";
import PaperClipIcon from "../../../Assets/img/Common-icon/PaperClipIcon";
import {
  changeFarmVisibalityApi,
  getHorseDetails,
  getHorseDetailsMetaData,
} from "../../../Redux/NonRedux/horse";
import { viewStallionStatus } from "../../../Redux/NonRedux/breeding";
import { t } from "i18next";
import { nextPreReminder } from "../../../Redux/NonRedux/reminderApi";
import moment from "moment";

export default function HorsePage() {
  const [addEditHorseModalShow, setAddEditHorseModalShow] =
    React.useState(false);

  const [changeProfileModalShow, setChangeProfileModalShow] =
    React.useState(false);

  const [editInsuranceModalShow, setEditInsuranceModalShow] =
    React.useState(false);

  const [addEmailModalShow, setAddEmailModalShow] = React.useState(false);

  const [pregnancyStatusModalShow, setPregnancyStatusModalShow] =
    React.useState(false);

  const [complatePedigreeModalShow, setComplatePedigreeModalShow] =
    React.useState(false);

  const [HorseNextActivitiesModalShow, setHorseNextActivitiesModalShow] =
    React.useState(false);

  const [HorseHistoryModalShow, setHorseHistoryModalShow] =
    React.useState(false);

  const [editPedigreeModalShow, setEditPedigreeModalShow] =
    React.useState(false);

  const [EditStallionStatusModalShow, setEditStallionStatusModalShow] =
    React.useState(false);
  const [pedigreeData, setPedigreeData] = useState<any>([]);
  const [horseId, setHorseId] = useState<any>(0);
  const [profilePicture, setProfilePicture] = useState<string>("");
  const [insuranceData, setInsuranceData] = useState<any>({});
  const [horseInformation, setHorseInformation] = useState<any>({});
  const [documents, setDocuments] = useState<any>([]);
  const { setLoading } = useLoading();
  const [metaData, setMetaData] = useState<any>({});
  const allowedFormate = ["jpg", "png", "pdf", "docx" , "heic" , "heif" , "jpeg"];
  const allowedFileSizeInMb = 4;
  const [statusData, setStatusData] = useState<any>({});
  const [preActivity, setPreActvity] = useState<any>([]);
  const [nextActivity, setNextActvity] = useState<any>([]);

  const [allpreActivity, setAllPreActvity] = useState<any>([]);
  const [allnextActivity, setAllNextActvity] = useState<any>([]);

  const [sibling, setSibling] = useState<any>({});
  const param = useParams();
  const { setExportParams }: any = useOutletContext();
  const dispatch = useDispatch();

  const horseDetails = useSelector((state: any) => state?.horseDetailReducer);

  const prefrence = useSelector(
    (state: any) => state?.masterReducer?.data?.component?.preferance
  );
  const refreshMeta = async () => {
    const metaData: any = await getHorseDetailsMetaData();
    setMetaData(metaData?.component?.metadata);
  };

  const getHorseData = async (id: any) => {
    setExportParams({ horse_id: id });
    setLoading(true);
    try {
      const horseData: any = await getHorseDetails({ id: id });
      const metaData: any = await getHorseDetailsMetaData();
      setPedigreeData(horseData?.component?.pedigree_data);
      setProfilePicture(horseData?.component?.profile_image);
      setInsuranceData(horseData?.component?.insurance_data);
      setHorseInformation(horseData?.component?.horseInformation);
      setDocuments(horseData?.component?.documents_listing);
      setMetaData(metaData?.component?.metadata);
      setStatusData({
        ...horseData?.component?.stallion_data,
        status: horseData?.component?.stallion_data?.stallion_status,
      });
      setPreActvity(horseData?.component?.previeousActivity);
      setNextActvity(horseData?.component?.nextActivity);
      setSibling(horseData?.component?.siblings);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    let id = encrDecrStr(param?.slug, "decr");
    setHorseId(id);
    getHorseData(id);
  }, []);

  const handleDocumentUpload = (e: any, doc_key: any) => {
    if (
      !isFileValidated(e.target.files[0], allowedFormate, allowedFileSizeInMb)
    ) {
      e.target.value = "";
      return;
    }
    const formData = new FormData();
    formData.append("horse_id", horseId);
    formData.append("doc_key", doc_key);
    formData.append("doc_name", e.target.files[0]);
    setLoading(true);
    uploadDocument(formData)
      .then((response: any) => {
        let responseDoc = response?.component;
        let docs = documents.map((doc: any) =>
          doc.document_key === responseDoc?.doc_key
            ? { ...doc, doc_name: responseDoc?.doc_name }
            : doc
        );
        setDocuments(docs);
      })
      .catch((error: any) => {})
      .finally(() => {
        setLoading(false);
      });
  };

  const getStatusData = async () => {
    try {
      setLoading(true);
      const response: any = await viewStallionStatus({ horse_id: horseId });
      setStatusData({
        ...response?.component,
        status: response?.component?.stallion_status,
      });
    } finally {
      setLoading(false);
    }
  };

  const refreshList = (type: string) => {
    let params = {
      horse_id: horseId,
    };
    if (type === REFRESH.PEDIGREE) {
      setLoading(true);
      getCompletePedigree(params)
        .then((response: any) => {
          setPedigreeData(response?.component?.pedigree_data);
          setLoading(false);
        })
        .catch((error: any) => {})
        .finally(() => {
          setLoading(false);
        });
    } else if (type === REFRESH.HORSE_DETAILS) {
      getHorseData(horseId);
    } else if (type === REFRESH.STALLION_STATUS) {
      getStatusData();
    }
  };

  const toggleBtnValue = [
    { name: t("Yes"), value: "1" },
    { name: t("No"), value: "2" },
  ];

  const handlePreNextReminder = (type: string) => {
    setLoading(true);
    let params = {
      horse_id: horseId,
      type,
    };
    nextPreReminder(params)
      .then((res: any) => {
        if (type === "previous") {
          setAllPreActvity(res?.component);
          setHorseHistoryModalShow(true);
        } else {
          setAllNextActvity(res?.component);
          setHorseNextActivitiesModalShow(true);
        }
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const changeFarmVisiblity = (val: any) => {
    changeFarmVisibalityApi({ id: horseInformation?.id, status: val })
      .then(() => {
        setHorseInformation({
          ...horseInformation,
          farm_directory_visible: val,
        });
      })
      .finally(() => {});
  };

  return (
    <>
      <div className="horse-horse-page">
        <Row className="detail-top-boxes">
          <Col md={12} lg={6}>
            <div className="card--layout mb-24">
              <div className="card--header">
                <p className="tl">{t("Information")}</p>
                <div className="btn-wrapper">
                  <a
                    className="a-ll"
                    onClick={() => setAddEditHorseModalShow(true)}
                  >
                    {t("Edit")}
                  </a>
                </div>
              </div>
              <div className="card--body">
                <div className="info-table">
                  <table>
                    <tbody>
                      <tr>
                        <td>
                          <p className="tm">{t("Horse")}</p>
                        </td>
                        <td>
                          <p className="tm blur-color">
                            {horseInformation?.name}
                          </p>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <p className="tm">{t("Date of birth")}</p>
                        </td>
                        <td>
                          <p className="tm blur-color">
                            {horseInformation?.birthdate
                              ? moment(horseInformation?.birthdate).format(
                                  "DD/MM/YYYY"
                                )
                              : ""}
                          </p>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <p className="tm">{t("Sex")}</p>
                        </td>
                        <td>
                          <p className="tm blur-color">
                            {horseInformation?.sex_value}
                          </p>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <p className="tm">{t("Colour")}</p>
                        </td>
                        <td>
                          <p className="tm blur-color">
                            {horseInformation?.color_value}
                          </p>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <p className="tm">{t("Sire")}</p>
                        </td>
                        <td>
                          <p className="tm blur-color">
                            {horseInformation?.sire}
                          </p>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <p className="tm">{t("Dam")}</p>
                        </td>
                        <td>
                          <p className="tm blur-color">
                            {horseInformation?.dam}
                          </p>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <p className="tm">{t("Breed")}</p>
                        </td>
                        <td>
                          <p className="tm blur-color">
                            {horseInformation?.breed_value}
                          </p>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <p className="tm">{t("Strain")}</p>
                        </td>
                        <td>
                          <p className="tm blur-color">
                            {horseInformation?.strain}
                          </p>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <p className="tm">{t("Registration Number")}</p>
                        </td>
                        <td>
                          <p className="tm blur-color">
                            {horseInformation?.registration_no}
                          </p>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <p className="tm">{t("Microchip Number")}</p>
                        </td>
                        <td>
                          <p className="tm blur-color">
                            {horseInformation?.microchip_number}
                          </p>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <p className="tm">{t("Owner")}</p>
                        </td>
                        <td>
                          <p className="tm blur-color">
                            {horseInformation?.owner}
                          </p>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <p className="tm">{t("Breeder")}</p>
                        </td>
                        <td>
                          <p className="tm blur-color">
                            {horseInformation?.breeder}
                          </p>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <p className="tm">{t("Documents")}</p>
                        </td>
                        <td>
                          <div className="doc-upload-wrp">
                            {documents?.map((document: any) => (
                              <p
                                key={document?.document_key}
                                className="tm"
                              >
                                {document?.doc_name != "" ? (
                                  <a
                                    onClick={() =>
                                      openDocument(document.doc_name)
                                    }
                                  >
                                    <PaperClipIcon />
                                  </a>
                                ) : (
                                  <FileUploadImage
                                    onChange={(e: any) =>
                                      handleDocumentUpload(
                                        e,
                                        document.document_key
                                      )
                                    }
                                  />
                                )}
                                {document?.document_type}
                              </p>
                            ))}
                          </div>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <p className="tm">{t("Location")}</p>
                        </td>
                        <td>
                          <p className="tm blur-color">
                            {horseInformation?.location_value}
                          </p>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <p className="tm">{`${t("Are you the Breeder?")}`}</p>
                        </td>
                        <td>
                          <p className="tm blur-color">
                            {horseInformation?.breeder_status_value}
                          </p>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <p className="tm">{t("Status")}</p>
                        </td>
                        <td>
                          <p className={`bm status-badge ${
                              getSlugNamefromId(
                                metaData?.horse_status,
                                horseInformation?.status
                              ) == "active"
                                ? "active"
                                : "sold"
                            }`}>
                            {horseInformation?.status_value}
                          </p>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>

            <div className="bg-box-dark">
              <div className="info-table">
                <table>
                  <tbody>
                    <tr>
                      <td>
                        <p className="tm">{t("Siblings")}</p>
                      </td>
                      <td>
                        <p className="tm blur-color">{sibling?.siblings}</p>
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <p className="tm">{t("Offspring")}</p>
                      </td>
                      <td>
                        <p className="tm blur-color">{sibling?.offspring}</p>
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <p className="tm">{t("Height")}</p>
                      </td>
                      <td>
                        <p className="tm blur-color">{sibling?.height}</p>
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <p className="tm">{t("Weight")}</p>
                      </td>
                      <td>
                        <p className="tm blur-color">{sibling?.weight}</p>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </Col>
          <Col md={12} lg={6}>
            <div className="card--layout mb-0 h-100">
              <div className="card--header">
                <p className="tl">{t("Profile Picture")}</p>
                <div className="btn-wrapper">
                  <a
                    className="a-ll"
                    onClick={() => setChangeProfileModalShow(true)}
                  >
                    {t("Edit")}
                  </a>
                </div>
              </div>
              <div className="card--body">
                <img src={profilePicture} alt="" />
              </div>
            </div>
          </Col>
        </Row>
        <Row>
          {horseInformation?.type == "male" ? (
            <Col md={12} lg={6} className="mb-24">
              <div className="card--layout h-100">
                <div className="card--header">
                  <p className="tl">{t("Stallion’s Status")}</p>
                  <div className="btn-wrapper">
                    <a
                      className="a-ll"
                      onClick={() => setEditStallionStatusModalShow(true)}
                    >
                      {t("Change")}
                    </a>
                  </div>
                </div>
                <div className="card--body">
                  <div className="info-table">
                    <table>
                      <tbody>
                        <tr>
                          <td>
                            <p className="tm">{t("Status")}</p>
                          </td>
                          <td>
                            <div className="icon-16-text">
                              {/* <img src={openMareIcon} alt="" /> */}
                              <span className="tm blur-color">
                                {statusData?.stallion_status_value}
                              </span>
                            </div>
                          </td>
                        </tr>
                        <tr>
                          <td>
                            <p className="tm">{t("Semen Type")}</p>
                          </td>
                          <td>
                            <p className="tm blur-color">
                              {statusData?.semen_type_value}
                            </p>
                          </td>
                        </tr>
                        <tr>
                          <td>
                            <p className="tm">{t("Stud Fee")}</p>
                          </td>
                          <td>
                            <p className="tm blur-color">
                              {statusData?.stud_fee}{" "}
                              {statusData?.stud_fee
                                ? `${prefrence?.currency_value}`
                                : ""}
                            </p>
                          </td>
                        </tr>
                        <tr>
                          <td>
                            <p className="tm">{t("Responsible Vet/Clinic")}</p>
                          </td>
                          <td>
                            <p className="tm blur-color">
                              {statusData?.vet_clinic}{" "}
                              {`${
                                statusData?.vet_clinic_contact
                                  ? ` (${statusData?.vet_clinic_contact})`
                                  : ""
                              }`}
                            </p>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </Col>
          ) : (
            <Col md={12} lg={6} className="mb-24">
              <div className="card--layout h-100">
                <div className="card--header">
                  <p className="tl">{t("Mare’s Pregnancy Status")}</p>
                </div>
                <div className="card--body">
                  <div className="info-table">
                    <table>
                      <tbody>
                        <tr>
                          <td>
                            <p className="tm">{t("Status")}</p>
                          </td>
                          <td>
                            <div className="icon-16-text">
                              <img
                                src={
                                  statusData?.stallion_status !== "pregnant"
                                    ?  openMareIcon  
                                    : PregnantIconSmall
                                }
                                alt=""
                              />
                              <span className="tm blur-color">
                                {statusData?.stallion_status_value}
                              </span>
                            </div>
                          </td>
                        </tr>
                        <tr>
                          <td>
                            <p className="tm">{t("Stallion")}</p>
                          </td>
                          <td>
                            <p className="tm blur-color">
                              {statusData?.stallion}
                            </p>
                          </td>
                        </tr>
                        <tr>
                          <td>
                            <p className="tm">{t("Breeding Date")}</p>
                          </td>
                          <td>
                            <p className="tm blur-color">
                              {statusData?.breeding_date}{" "}
                              {statusData?.breedings}
                            </p>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </Col>
          )}

          <Col md={12} lg={6} className="mb-24">
            <div className="card--layout h-100">
              <div className="card--header">
                <p className="tl">{t("Insurance Information")}</p>
                <div className="btn-wrapper">
                  <a
                    className="a-ll"
                    onClick={() => setEditInsuranceModalShow(true)}
                  >
                    {t("Edit")}
                  </a>
                </div>
              </div>
              <div className="card--body">
                <div className="info-table">
                  <table>
                    <tbody>
                      <tr>
                        <td>
                          <p className="tm">{t("Insurance Company")}</p>
                        </td>
                        <td>
                          <p className="tm blur-color">
                            {insuranceData?.insurance_company}
                            {insuranceData?.policy_number
                              ? ` - ${insuranceData?.policy_number}`
                              : ""}
                          </p>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <p className="tm">{t("Policy Dates")}</p>
                        </td>
                        <td>
                          <p className="tm blur-color">
                            {insuranceData?.policy_start_date
                              ? moment(insuranceData?.policy_start_date).format(
                                  "DD/MM/YYYY"
                                )
                              : ""}
                            {insuranceData?.policy_end_date
                              ? ` - ${moment(
                                  insuranceData?.policy_end_date
                                ).format("DD/MM/YYYY")}`
                              : ""}
                          </p>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <p className="tm">{t("Contact Details")}</p>
                        </td>
                        <td>
                          <p className="tm blur-color">
                            {insuranceData?.contact_number} -{" "}
                            {insuranceData?.email}
                          </p>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </Col>
        </Row>
        <Row>
          <Col sm={12} md={12} className="mb-24">
            <div className="card--layout">
              <div className="card--header">
                <p className="tl">{t("Pedigree")}</p>
                <div className="btn-wrapper">
                  <a
                    className="a-ll"
                    onClick={() => setEditPedigreeModalShow(true)}
                  >
                    {t("Edit")}
                  </a>
                  <a
                    className="a-ll"
                    onClick={() => setComplatePedigreeModalShow(true)}
                  >
                    {t("Complete Pedigree")}
                  </a>
                </div>
              </div>
              <div className="card--body">
                <div className="overflow-x-auto">
                  <Row className={`pedhi-aambo ${(pedigreeData
              && Object.keys(pedigreeData).length > 2)
                ? "three-layer"
                : "two-layer"
              } `}>
                    <Col>
                      {pedigreeData &&
                        pedigreeData?.span_32?.map(
                          (item: any, index: number) => (
                            <div
                              className={`layer-box ${
                                item.gender === "m" ? "male" : "female"
                              }`}
                              key={index + "index"}
                            >
                              <div className="icon-24-text">
                                <img
                                  src={`${
                                    item.gender === "m" ? MaleIcon : FemaleIcon
                                  }`}
                                  alt=""
                                />
                                <p className="bl">
                                  {item?.horseName}
                                  {item?.year ? ", " + item?.year : ""}
                                </p>
                              </div>
                            </div>
                          )
                        )}
                    </Col>
                    <Col>
                      {pedigreeData &&
                        pedigreeData?.span_16?.map(
                          (item: any, index: number) => (
                            <div
                              className={`layer-box ${
                                item.gender === "m" ? "male" : "female"
                              }`}
                              key={index + "index"}
                            >
                              <div className="icon-24-text">
                                <img
                                  src={`${
                                    item.gender === "m" ? MaleIcon : FemaleIcon
                                  }`}
                                  alt=""
                                />
                                <p className="bl">
                                  {item?.horseName}
                                  {item?.year ? ", " + item?.year : ""}
                                </p>
                              </div>
                            </div>
                          )
                        )}
                    </Col>
                    <Col>
                      {pedigreeData &&
                        pedigreeData?.span_8?.map(
                          (item: any, index: number) => (
                            <div
                              className={`layer-box ${
                                item.gender === "m" ? "male" : "female"
                              }`}
                              key={index + "index"}
                            >
                              <div className="icon-24-text">
                                <img
                                  src={`${
                                    item.gender === "m" ? MaleIcon : FemaleIcon
                                  }`}
                                  alt=""
                                />
                                <p className="bl">
                                  {item?.horseName}
                                  {item?.year ? ", " + item?.year : ""}
                                </p>
                              </div>
                            </div>
                          )
                        )}
                    </Col>
                  </Row>
                </div>
              </div>
            </div>
          </Col>
        </Row>
        <Row>
          <Col md={12} lg={6} className="mb-24">
            <div className="card--layout h-100">
              <div className="card--header">
                <p className="tl">{t("Horse Next Activities")}</p>
                <div className="btn-wrapper">
                  <a
                    className="a-ll"
                    onClick={() => handlePreNextReminder("next")}
                  >
                    {t("View All")}
                  </a>
                </div>
              </div>
              <div className="card--body">
                <div className="vertical-flow small">
                  {nextActivity?.map((items: any, index: number) => (
                    <div className="VF-box" key={index + "HNA"}>
                      <label className="bl">{items?.date}</label>
                      <div className="dots">
                        <div className="green"></div>
                      </div>
                      <div className="box">
                        <p className="bl">
                          {items?.category}{" "}
                          {items?.type ? `(${items?.type})` : ""}
                        </p>
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            </div>
          </Col>
          <Col md={12} lg={6} className="mb-24">
            <div className="card--layout h-100">
              <div className="card--header">
                <p className="tl">{t("Horse History")}</p>
                <div className="btn-wrapper">
                  <a
                    className="a-ll"
                    onClick={() => handlePreNextReminder("previous")}
                  >
                    {t("View All")}
                  </a>
                </div>
              </div>
              <div className="card--body">
                <div className="vertical-flow big">
                  {preActivity?.map((items: any, index: number) => (
                    <div className="VF-box" key={index + "HNA"}>
                      <label className="bl">{items.date}</label>
                      <div className="dots">
                        <div className="yellow"></div>
                      </div>
                      <div className="box">
                        <p className="bl">{items?.category}</p>
                        <p className="bm blur-color">{items?.type}</p>
                        {/* <span className="bm blur-color">{items.time}</span> */}
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            </div>
          </Col>
        </Row>
        <Row>
          <Col sm={12} md={12}>
            <div className="card--layout">
              <div className="card--header">
                <p className="tl">{t("Public Link")}</p>
                <a className="bl link">
                  {/* www.domain.com/Horse_Information/Details-495370 */}
                </a>
                {/* <div className="btn-wrapper">
                  <a
                    className="a-ll"
                    onClick={() => setAddEmailModalShow(true)}
                  >
                    Add
                  </a>
                </div> */}
              </div>
              <div className="card--body">
                <ToggleButton
                  value={toggleBtnValue}
                  label={t("Visible in Farm Directory?")}
                  selected={horseInformation?.farm_directory_visible}
                  setSelected={(val: string) => {
                    changeFarmVisiblity(val);
                  }}
                ></ToggleButton>
                {/* <table className="switch-tbl">
                  {publicLinkData.map((items: any, index: number) => (
                    <tr key={index + "switch-tbl"}>
                      <td>
                        <p className="bl">{items.title}</p>
                      </td>
                      <td>
                        <Switch
                          size="small"
                          checked={items.status}
                          className="ml-auto"
                        />
                      </td>
                    </tr>
                  ))}
                </table> */}
              </div>
            </div>
          </Col>
        </Row>
      </div>

      <AddEditHorsePopup
        show={addEditHorseModalShow}
        onHide={() => setAddEditHorseModalShow(false)}
        isedit={1}
        horsedata={horseInformation}
        setrefreshlist={() => {
          refreshList(REFRESH.HORSE_DETAILS);
        }}
        metaData={metaData}
      />

      <ChangeProfilePopup
        show={changeProfileModalShow}
        onHide={() => setChangeProfileModalShow(false)}
        setprofile={(profile: string) => {
          setProfilePicture(profile);
        }}
        horseid={horseId}
      />

      <EditInsurancePopup
        show={editInsuranceModalShow}
        onHide={() => setEditInsuranceModalShow(false)}
        horseid={horseId}
        data={statusData}
        metaData={metaData}
        insurancedata={insuranceData}
        setinsurancedata={setInsuranceData}
      />

      <AddEmailPopup
        show={addEmailModalShow}
        onHide={() => setAddEmailModalShow(false)}
      />
      <PregnancyStatusPopup
        show={pregnancyStatusModalShow}
        onHide={() => setPregnancyStatusModalShow(false)}
      />
      <EditPedigreePopup
        show={editPedigreeModalShow}
        onHide={() => setEditPedigreeModalShow(false)}
        pedigreedata={pedigreeData}
        horseInformation={horseInformation}
        horseid={horseId}
        refreshList={refreshList}
      />
      <CompletePedigreePopup
        show={complatePedigreeModalShow}
        onHide={() => setComplatePedigreeModalShow(false)}
        pedigreedata={pedigreeData}
      />
      <HorseNextActivitiesPopup
        show={HorseNextActivitiesModalShow}
        data={allnextActivity}
        onHide={() => setHorseNextActivitiesModalShow(false)}
      />
      <HorseHistoryPopup
        show={HorseHistoryModalShow}
        data={allpreActivity}
        onHide={() => setHorseHistoryModalShow(false)}
      />
      <EditStallionStatusPopup
        show={EditStallionStatusModalShow}
        isedit={1}
        editData={statusData}
        onHide={() => setEditStallionStatusModalShow(false)}
        metaData={metaData}
        horseid={horseId}
        setRefreshList={() => {
          refreshList(REFRESH.STALLION_STATUS);
        }}
        refreshMeta={refreshMeta}
      />
    </>
  );
}
