import { globalPostAction } from "../../actions/globalActions";
import { END_POINTS } from "../../Constant/Api";
import { SHOW_MESSAGE } from "../../Constant/General";

export function getAccountPreferenceList(params: any) {
  let apiUrl = END_POINTS.accountPreferenceList;
  return globalPostAction(params, apiUrl);
}

export const setAccountPreference = (params: any) => {
  let apiUrl = END_POINTS.accountPreferenceSet;
  return globalPostAction(params, apiUrl, SHOW_MESSAGE);
};

