import { useEffect, useState } from "react";
import Modal from "react-bootstrap/Modal";
import Datepicker from "../../Common/Datepicker/Datepicker";
import InputGroups from "../../Common/InputGroups/InputGroups";
import type { DatePickerProps } from "antd";
import moment from "moment";
import TextButton from "../../Common/TextButton/TextButton";
import CustomeButton from "../../Common/CustomButton/CustomButton";
import InputSelectGroup from "../../Common/InputSelectGroup/InputSelectGroup";
import InputSelectMix from "../../Common/InputSelectMix/InputSelectMix";
import { t } from "i18next";
import { useLoading } from "../../../context/LoadingContext";
import { changeInvoicePayment } from "../../../Redux/NonRedux/invoice";
import validate from "../../../Utility/Validation";
import { getInstantMeta } from "../../../Redux/globallActions";
import { useSelector } from "react-redux";

export default function RecordPaymentStatusPopup(props: any) {
  const INTIAL_DATA = {
    id: {
      value: "",
    },
    payment_date: {
      value: moment().format("YYYY-MM-DD"),
      validation: [""],
      errors: [""],
    },
    select_payment_method: {
      value: "",
      // validation: ["requiredIf:payment_method_id"],
      errors: [t("Dewormer Active Ingredient is required.")],
    },
    payment_method: {
      value: "",
      validation: [
        "letters",
      ],
      errors: [t("Incorrect format")],
    },
    payment_method_id: {
      value: "",
      validation: [""],
      errors: [t("")],
    },
    amount: {
      value: "",
      validation: ["required"],
      errors: [t("Amount is required.")],
    },
    payment_reference: {
      value: "",
      validation: [""],
      errors: [t("Payment Reference is required.")],
    },
  };

  const prefrence = useSelector(
    (state: any) => state?.masterReducer?.data?.component?.preferance
  );

  const [formData, setFormData] = useState<any>(INTIAL_DATA);
  const { setLoading } = useLoading();
  const [formDataError, setFormDataError] = useState<any>({});
  const [isFormValidated, setIsFormValidated] = useState(false);

  const [paymentMethodToggle, setpaymentMethodToggle] = useState("select");

  const setData = (field: string, value: any) => {
    const tempOb = Object.assign({}, formData);
    tempOb[field].value = value;
    setFormData({ ...tempOb });
  };

  const dateChange: DatePickerProps["onChange"] = (date, dateString) => {
    let dateIs: any = date?.format("YYYY-MM-DD");
    setData("payment_date", dateIs);
  };



  useEffect(() => {
    setFormDataError({ ...validate(formData).errors });
  }, [formData]);

  const submitHandler = () => {
    if (validate(formData).isValidated === true) {
      let params: any = {};
      Object.keys(formData).forEach((element) => {
        params[element] = formData[element].value;
      });

      setLoading(true);
      changeInvoicePayment(params)
        .then((response: any) => {
          props.onHide();
          props.setRefreshList();
        })
        .finally(() => {
          setLoading(false);
        });
    } else {
      setFormDataError({ ...validate(formData)?.errors });
      setIsFormValidated(true);
    }
  };

  const toggleChange = (selectType: string, input: string) => {
    if (selectType === "select") {
      setData(`select_${input}`, `${input}_id`);
      setData(input, "");
    } else {
      setData(`select_${input}`, input);
      setData(`${input}_id`, "");
    }
  };

  const { setRefreshList, paymentStataus, metaData , ...rest } = props;

  useEffect(() => {
    setData("id", paymentStataus?.id);
    setData("amount", paymentStataus?.total);
  }, [paymentStataus]);

  useEffect(() => {
    if (props?.show === false) {
      setFormDataError({});
      setIsFormValidated(false);
      setpaymentMethodToggle("select");
    }
  }, [props?.show]);

  return (
    <Modal {...rest} centered className="record-payment-status-popup modal-416">
      <Modal.Header closeButton>
        <p className="tl">{t("Record Payment")} </p>
      </Modal.Header>
      <Modal.Body>
        <div>
          <p className="tm">{t("Invoice")} {paymentStataus?.invoice_number}</p>
          <Datepicker
            label={`${t("Date")}*`}
            onChange={dateChange}
            value={moment(formData?.payment_date?.value)}
          />
          <InputSelectGroup
            label={`${t("Payment Method")}`}
            selectValue={metaData?.payment_method}
            inputSelectToggle={paymentMethodToggle}
            setInputSelectToggle={(val: any) => {
              setpaymentMethodToggle(val);
              toggleChange(val, "payment_method");
            }}
            dropKey="id"
            dropVal="metadata"
            defaultSelect={formData?.payment_method_id?.value}
            value={formData?.payment_method?.value}
            error={
              isFormValidated &&
              (formDataError["payment_method"] ||
                formDataError["payment_method_id"])
                ? formDataError["payment_method"] ||
                  formDataError["payment_method_id"]
                : null
            }
            onSelectChange={(e: any) => {
              setData("payment_method_id", e);
            }}
            onInputChange={(e: any) => {
              setData("payment_method", e.target.value);
            }}
          />
          <InputGroups
            label={`${t("Payment Reference")}`}
            type="text"
            autoComplete="off"
            value={formData?.payment_reference?.value}
            onChange={(e: any) => setData("payment_reference", e.target.value)}
            error={
              isFormValidated && formDataError["payment_reference"]
                ? formDataError["payment_reference"]
                : null
            }
          />
          <InputSelectMix
            label={`${t("Amount")}*`}
            inputType="number"
            inputReadOnly={true}
            inputValue={formData?.amount?.value || ""}
            inputAutoComplete="off"
            onInputChange={(e: any) => setData("amount", e.target.value)}
            inputError={
              isFormValidated && formDataError["amount"]
                ? formDataError["amount"]
                : null
            }
            inputSecondValue={prefrence?.currency_value}
          />
        </div>
      </Modal.Body>
      <Modal.Footer>
        <div className="footer-2-btn">
          <TextButton onClick={props?.onHide}>  {t("Cancel")}</TextButton>
          <CustomeButton bg="fill" onClick={submitHandler}>
          {t("Add")}  
          </CustomeButton>
        </div>
      </Modal.Footer>
    </Modal>
  );
}
