const OnlyCk = (props: any) => {
  return (
    <label className="onlyck">
      {props.children}
      <input
        type="checkbox"
        value={props.value}
        name={props.name}
        id={props.id}
        defaultChecked={props.defaultChecked}
        checked={props.checked}
        disabled={props.disabled}
        onChange={props.onChange}
        readOnly={props.readOnly}
      />
      <span className="onlyck-checkmark"></span>
    </label>
  );
};

export default OnlyCk;
