import { t } from 'i18next'
import React from 'react'

export default function SomethingWrong() {
  return (
    <>
    <h4 style={{textAlign:"center" , marginTop:"30px"}}>{t("404 Page Not Found")}</h4>
    <div style={{textAlign:"center"}}>{t("Something Went Wrong")}</div>
    </>
    
  )
}
