import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate, useOutletContext, useParams } from "react-router-dom";
import AddPlusIcon from "../../../../Assets/icon/AddPlusIcon";
import binIcon from "../../../../Assets/img/bin.svg";
import PaperClipIcon from "../../../../Assets/img/Common-icon/PaperClipIcon";
import pencilIcon from "../../../../Assets/img/pencil.svg";
import { API_STATUS, END_POINTS } from "../../../../Constant/Api";
import { DATATABLE } from "../../../../Constant/General";
import { useLoading } from "../../../../context/LoadingContext";
import { REMINDER_STATUS_CHANGE_SUCCESS, REMINDER_STATUS_CHANGE_CLEAR } from "../../../../Redux/General/GeneralType";
import { getSingleDentalHealth } from "../../../../Redux/Horse/Health/SingleHealthAction";
import { getListMetaDataApi } from "../../../../Redux/NonRedux/general";
import {
  destroySingleHealthAction,
  getSingleHelthList,
  viewSingleHealthAction,
} from "../../../../Redux/NonRedux/health";
import { encrDecrStr, getDefaultSorting } from "../../../../Utility/General";
import CustomeButton from "../../../Common/CustomButton/CustomButton";
import TableHead from "../../../Common/DataTable/TableHead/TableHead";
import NoDataFound from "../../../Common/NoDataFound/NoDataFound";
import Pagination from "../../../Layouts/Pagination/Pagination";
import AddDentalPopup from "../../../Popups/AddDentalPopup/AddDentalPopup";
import ConfirmPopup from "../../../Popups/ConfirmPopup/ConfirmPopup";

export default function DentalPage() {
  const defaultSorting : any = getDefaultSorting(DATATABLE.HORSRE_HELTH);
  const { t, i18n } = useTranslation();
  const tableHead = [
    {
      label:t("Date"),
      className:"tm",
      key:"date",
    },
    {
      label:t("Vet/Clinic"),
      className:"tm",
      key:"vet_clinic",
    },
    {
      label:t("Comments"),
      className:"tm",
      key:"comments",
    },
    {
      isIcon:true,
      icon:<PaperClipIcon />,
    },
    {
      label:t("Amount"),
      className:"tm",
      key:"amount",
      headClass:"text-right"
    },
    {
      label:t("Action"),
      className:"tm",
      key:"",
      headClass:"text-right"
    },
  ]
  const param = useParams();
  const dispatch = useDispatch();
  const [horseId, setHorseId] = React.useState<any>(0);
  const [refreshList, setRefreshList] = React.useState(false);
  const [confirmPopupShow, setConfirmPopupShow] = React.useState(false);
  const [isEdit, setIsEdit] = React.useState<boolean>(false);
  const [deletableRecords, setDeletableRecords] = React.useState<any>([]);
  const [editData, setEditData] = React.useState<any>({});
  const [recordsTotal, setRecordsTotal] = React.useState(1);
  const [currentPage, setCurrentPage] = React.useState(1);
  const [currentPageSize, setCurrentPageSize] = React.useState(10);
  const { setLoading } = useLoading();
  const [healthList, setHelthList] = useState<any>([]);
  const [AddDentalModalShow, setAddDentalModalShow] = useState(false);
  const [metaData, setMetaData] = useState({});
  const [sortByField, setSortByField] = useState(defaultSorting?.key);
  const [sortType, setSortType] = useState(defaultSorting?.type);
  const [orderList, setOrderList] = useState<any>([]);
  const { setExportParams }: any = useOutletContext();
  const location:any = useLocation();
  const navigate = useNavigate();
  const prefrence = useSelector(
    (state: any) => state?.masterReducer?.data?.component?.preferance
  );

  useEffect(() => {  
    if(location?.state?.reminder_id && location?.state?.path){
      setIsEdit(false);
      setEditData({});
      setAddDentalModalShow(true)
    }
  }, [location?.state]);


  useEffect(() => {
    listApiCall();
  }, [currentPage, currentPageSize, sortByField , sortType]);

  useEffect(() => {
    if (refreshList) {
      listApiCall();
      setRefreshList(false);
    }
  }, [refreshList]);

  const listApiCall = () => {
    let id = encrDecrStr(param?.slug, "decr");
    setHorseId(id);
    let params : any = {
      page: currentPage,
      limit: currentPageSize,
      form_type: "dental",
      horse_id: id,
    };
    if (sortByField && sortType) {
      params["sort"] = {
        column: orderList[sortByField] ? orderList[sortByField] : defaultSorting?.value ,
        type: sortType,
      };
    }
    setExportParams(params);
    setLoading(true);
    getSingleHelthList(params)
      .then((res: any) => {
        setHelthList(res?.component?.listing);
        setRecordsTotal(res?.component?.recordsTotal);
        setOrderList(res?.component?.orderlist);
      })
      .catch(() => {})
      .finally(() => {
        setLoading(false);
      });
  };


  const getMetaData = () => {
    setLoading(true);  
    getListMetaDataApi(END_POINTS.metaHelthDental)
      .then((response: any) => {
        setMetaData(response?.component);
      })
      .catch((error: any) => {})
      .finally(() => {
        setLoading(false);
      });
  };

  useEffect(() => {
    getMetaData();
  },[]);
  

  const editHandler = (id: any) => {
    let params = {
      form_type: "dental",
      id: id,
    };
    viewSingleHealthAction(params)
      .then((res: any) => {
        if (res?.component?.form_type == "dental") {
          setIsEdit(true);
          setEditData(res?.component);
          setAddDentalModalShow(true);
        }
      })
      .catch((err) => {})
      .finally(() => {
        setLoading(false);
      });
  };
  const deleteHandler = () => {
    let params = {
      form_type: "dental",
      id: deletableRecords,
    };
    destroySingleHealthAction(params)
      .then((res: any) => {
        setRefreshList(true);
        setConfirmPopupShow(false);
      })
      .catch((err: any) => {})
      .finally(() => {
        setLoading(false);
      });
  };

  const deleteNow = (id: any) => {
    let ids = deletableRecords;
    ids.push(id);
    setDeletableRecords(ids);
    setConfirmPopupShow(true);
  };

  const openDocument = (doc: any) => {
    window.open(doc, "_blank");
  };

  const handleRedirectBack = () => {
    if(location?.state?.reminder_id && location?.state?.path){
      navigate(location?.state?.path ,{ 
        state: {reminder_id:"" }
       });
    }
  }
  return (
    <>
      <div className="dental-page">
        <div className="data-tbl-wrapper">
          <div className="data-tbl-header">
            <p className="tl">{t("Dental")}</p>
            <div className="btn-wrapper single-btn">
              <CustomeButton
                bg="fill"
                onClick={() => {
                  setEditData({});
                  setIsEdit(false);
                  setAddDentalModalShow(true);
                }}
              >
                <AddPlusIcon />
                {t("Add")}  
              </CustomeButton>
            </div>
          </div>
          <div className="data-tbl">
            <table>
            <TableHead
              data={tableHead}
              orderList={orderList}
              sortByField={sortByField}
              sortType={sortType}
              setSortType={setSortType}
              setSortByField={setSortByField}
              />
              {healthList?.length ? (
                <tbody>
                  {healthList?.map((items: any, index: number) => (
                    <tr key={index + "vaccination-data"}>
                      <td>
                        <p className="bl">{items.dates}</p>
                      </td>
                      <td>
                        <p className="bl">{items.vat_clinic}</p>
                      </td>
                      <td>
                        <p className="bl">{items.comments}</p>
                      </td>
                      <td>
                        {items.document ? (
                          <p
                            className="bl"
                            onClick={() => openDocument(items.document)}
                          >
                            <PaperClipIcon />
                          </p>
                        ) : (
                          ""
                        )}
                      </td>
                      <td>
                        <p className="bl text-right">
                          {items.amount} {prefrence?.currency_value}
                        </p>
                      </td>
                      <td>
                        <div className="icon-wrapper">
                          <button onClick={() => editHandler(items.id)}>
                            <img src={pencilIcon} alt="" />
                          </button>
                          <button onClick={() => deleteNow(items.id)}>
                            <img src={binIcon} alt="" />
                          </button>
                        </div>
                      </td>
                    </tr>
                  ))}
                </tbody>
              ) : null}
            </table>
          </div>
          {healthList?.length ? (
            <Pagination
              results={recordsTotal}
              currentPage={currentPage}
              setCurrentPage={setCurrentPage}
              currentLength={currentPageSize}
              setCurrentPageSize={setCurrentPageSize}
            />
          ) : (
            <NoDataFound />
          )}
        </div>
      </div>

      <AddDentalPopup
        show={AddDentalModalShow}
        horseid={isEdit ? horseId : [horseId]}
        isMultiple={0}
        isedit={isEdit.toString()}
        healthdata={editData}
        setrefreshlist={() => {
          setRefreshList(true);
          getMetaData();
          handleRedirectBack();
        }}
        onHide={() => {
          setAddDentalModalShow(false);
          handleRedirectBack();
        }}
        metaData={metaData}
      />
      <ConfirmPopup
        heading={t("Delete")}
        message={t("Are you sure to delete this record?")}
        show={confirmPopupShow}
        onHide={() => {
          setConfirmPopupShow(false);
          setDeletableRecords([]);
        }}
        confirm={deleteHandler}
      />
    </>
  );
}
