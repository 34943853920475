import type { DatePickerProps } from "antd";
import { t } from "i18next";
import moment from "moment";
import React, { useEffect, useState } from "react";
import Modal from "react-bootstrap/Modal";
import { addEditImageVideo } from "../../../actions/Horse/MyHorseActions";
import { API_STATUS } from "../../../Constant/Api";
import { WATCH, YOUTUBE_EMBED_LINK } from "../../../Constant/General";
import { SUCCESS_RESPONSE_CODE } from "../../../Constant/Status";
import { useLoading } from "../../../context/LoadingContext";
import validate from "../../../Utility/Validation";
import CustomeButton from "../../Common/CustomButton/CustomButton";
import Datepicker from "../../Common/Datepicker/Datepicker";
import InputGroups from "../../Common/InputGroups/InputGroups";
import TextareaGroups from "../../Common/TextareaGroups/TextareaGroups";
import TextButton from "../../Common/TextButton/TextButton";
import ToggleButton from "../../Common/ToggleButton/ToggleButton";

export default function AddVideoPopup(props: any) {
  const INTIAL_VIDEO_DATA = {
    form_type: {
      value: "video",
      validation: ["required"],
      errors: [t("Form Type is required.")],
    },
    id: {
      value: "",
    },
    date: {
      value: moment().format("YYYY-MM-DD"),
      validation: ["required"],
      errors: [t("Date is required.")],
    },
    title: {
      value: "",
      validation: [""],
      errors: [t("Title is required.")],
    },
    photographer: {
      value: "",
      validation: [""],
      errors: [t("Video By is required.")],
    },
    video_link: {
      value: "",
      validation: ["required", "youtube_link"],
      errors: [
        t("Video Link is required."),
        t("Please enter valid Youtube Link"),
      ],
    },
    private: {
      value: "0",
    },
    description: {
      value: "",
      validation: [""],
      errors: [t("Description is required.")],
    },
    type: {
      value: 1,
    },
    is_default: {
      value: 0,
    },
  };

  const dateChange: DatePickerProps["onChange"] = (date, dateString) => {
    let dateIs = date?.format("YYYY-MM-DD");
    setData("date", dateIs);
  };

  const [videoData, setVideoData] = useState<any>(INTIAL_VIDEO_DATA);
  const [isFormValidated, setIsFormValidated] = useState(false);
  const [videoDataError, setVideoDataError] = useState<any>({});
  const [apiStatus, setApiStatus] = useState(API_STATUS.SUCCESS);
  const { setLoading } = useLoading();

  const setData = (field: string, value: any) => {
    const videoInfo = Object.assign({}, videoData);
    videoInfo[field].value = value;
    setVideoData({ ...videoInfo });
  };

  const toggleBtnValue = [
    { name: t("Yes"), value: "0" },
    { name: t("No"), value: "1" },
  ];

  useEffect(() => {
    setVideoDataError({ ...validate(videoData).errors });
  }, [videoData]);

  useEffect(() => {
    if (props.isedit) {
      if (props?.videodata) {
        Object.keys(videoData).forEach((element) => {
          if (props?.videodata?.[element]) {
            setData(element, `${props?.videodata?.[element]}`);
          }
        });
      }
    } else {
      setVideoData(INTIAL_VIDEO_DATA);
    }
  }, [props?.videodata]);

  const submitHandler = () => {
    if (validate(videoData).isValidated === true) {
      let horseId = props.horseid;
      let params: any = {};
      Object.keys(videoData).forEach((element) => {
        if (videoData[element]) {
          params[element] = videoData[element].value;
        }
      });
      let video_link = params.video_link;
      const youtubeRegexp =
        /^.*((youtu.be\/)|(v\/)|(\/u\/\w\/)|(embed\/)|(watch\?))\??v?=?([^#&?]*).*/;
      let match = video_link.match(youtubeRegexp);
      let video_id = match && match[7].length == 11 ? match[7] : false;
      params.video_link = YOUTUBE_EMBED_LINK + video_id;
      params["horse_id"] = horseId;
      if (videoData.id.value && videoData.id.value != "") {
        params.id = videoData.id.value;
      }

      setLoading(true);
      addEditImageVideo(params)
        .then((response: any) => {
          if (response.statusCode === SUCCESS_RESPONSE_CODE) {
            setApiStatus(API_STATUS.SUCCESS);
            props.onHide();
            props.setrefreshlist();
          } else {
            setApiStatus(API_STATUS.FAILED);
          }
        })
        .catch((error) => {
          setApiStatus(API_STATUS.FAILED);
        })
        .finally(() => {
          // setLoading(false);
        });
    } else {
      setVideoDataError({ ...validate(videoData)?.errors });
      setIsFormValidated(true);
    }
  };

  useEffect(() => {
    if (props?.show === false) {
      setVideoDataError({});
      setVideoData(INTIAL_VIDEO_DATA);
      setIsFormValidated(false);
    }
  }, [props?.show]);

  const { setrefreshlist, ...rest } = props;

  return (
    <Modal {...rest} centered className="add-video-popup modal-416">
      <Modal.Header closeButton>
        <p className="tl">
          {" "}
          {props.isedit ? t("Update Video") : t("Add Video")}{" "}
        </p>
      </Modal.Header>
      <Modal.Body>
        <div>
          <Datepicker
            label={`${t("Date")}*`}
            endDate={moment().format("YYYY-MM-DD")}
            onChange={dateChange}
            value={moment(videoData?.date?.value)}
            error={
              isFormValidated && videoDataError["date"]
                ? videoDataError["date"]
                : null
            }
          />
          <InputGroups
            label={`${t("Title")}`}
            type="text"
            autoComplete="off"
            value={videoData?.title?.value}
            onChange={(e: any) => setData("title", e.target.value)}
            error={
              isFormValidated && videoDataError["title"]
                ? videoDataError["title"]
                : null
            }
          />
          <InputGroups
            label={`${t("Link")}*`}
            type="text"
            autoComplete="off"
            value={videoData?.video_link?.value}
            onChange={(e: any) => setData("video_link", e.target.value)}
            error={
              isFormValidated && videoDataError["video_link"]
                ? videoDataError["video_link"]
                : null
            }
          />
          <InputGroups
            label={`${t("Video by")}`}
            type="text"
            autoComplete="off"
            value={videoData?.photographer?.value}
            onChange={(e: any) => setData("photographer", e.target.value)}
            error={
              isFormValidated && videoDataError["photographer"]
                ? videoDataError["photographer"]
                : null
            }
          />
          <ToggleButton
            value={toggleBtnValue}
            label={`${t("Private")}`}
            selected={videoData?.private?.value}
            setSelected={(value: any) => {
              setData("private", value);
            }}
          ></ToggleButton>

          <TextareaGroups
            label={`${t("Description")}`}
            type="text"
            autoComplete="off"
            value={videoData?.description?.value}
            onChange={(e: any) => setData("description", e.target.value)}
            error={
              isFormValidated && videoDataError["description"]
                ? videoDataError["description"]
                : null
            }
          />
        </div>
      </Modal.Body>
      <Modal.Footer>
        <div className="footer-2-btn">
          <TextButton onClick={props.onHide}>{t("Cancel")}</TextButton>
          <CustomeButton bg="fill" onClick={submitHandler}>
            {" "}
            {props.isedit ? t("Update") : t("Add")}
          </CustomeButton>
        </div>
      </Modal.Footer>
    </Modal>
  );
}
