import {
  globalDownloadAction,
  globalGetAction,
  globalPostAction,
} from "../actions/globalActions";
import { END_POINTS, MAX_CALLS } from "../Constant/Api";
import { LANGUAGE } from "../Constant/LocalStorage";
import { SUCCESS_RESPONSE_CODE } from "../Constant/Status";
import api from "../Service/Axios";
import { jsonToFormData, showToast } from "../Utility/General";

const lang = localStorage.getItem(LANGUAGE)
  ? localStorage.getItem(LANGUAGE)
  : "en";

export function globalPostActionWithReducer(
  param: any,
  apiUrl: any,
  REQUEST: any,
  SUCCESS: any,
  LONG: any,
  FAILD: any,
  showMessage: boolean = false
) {
  let recallCount = 0;
  return (dispatch: any) => {
    dispatch({
      type: REQUEST,
      payload: { type: REQUEST },
    });
    function recursiveCall() {
      api
        .post(apiUrl, param)
        .then((result) => {
          if (result.data.statusCode === SUCCESS_RESPONSE_CODE) {
            if (showMessage) {
              showToast("success", result.data.message);
            }

            dispatch({
              type: SUCCESS,
              payload: {
                data: result.data,
                type: SUCCESS,
              },
            });
          } else if (recallCount < MAX_CALLS) {
            recursiveCall();
            recallCount++;
            dispatch({
              type: LONG,
              payload: { data: {}, type: LONG },
            });
          } else {
            showToast("error", result.data.message);
            dispatch({
              type: FAILD,
              payload: { data: {}, type: FAILD },
            });
          }
        })
        .catch((error) => {
          showToast("error", error);
          if (recallCount < MAX_CALLS) {
            recursiveCall();
            recallCount++;
            dispatch({
              type: LONG,
              payload: { data: {}, type: LONG },
            });
          } else {
            dispatch({
              type: FAILD,
              payload: { data: {}, type: FAILD },
            });
          }
        });
    }
    recursiveCall();
  };
}

export function globalGetActionWithReducer(
  apiUrl: any,
  REQUEST: any,
  SUCCESS: any,
  LONG: any,
  FAILD: any
) {
  let recallCount = 0;
  return (dispatch: any) => {
    dispatch({
      type: REQUEST,
      payload: { type: REQUEST },
    });
    function recursiveCall() {
      api
        .get(apiUrl, {})
        .then((result) => {
          if (result.data.statusCode === SUCCESS_RESPONSE_CODE) {
            // showToast("success", result.data.message);
            dispatch({
              type: SUCCESS,
              payload: {
                data: result.data,
                type: SUCCESS,
              },
            });
          } else if (recallCount < MAX_CALLS) {
            recursiveCall();
            recallCount++;
            dispatch({
              type: LONG,
              payload: { data: {}, type: LONG },
            });
          } else {
            showToast("error", result.data.message);
            dispatch({
              type: FAILD,
              payload: { data: {}, type: FAILD },
            });
          }
        })
        .catch((error) => {
          showToast("error", error);
          if (recallCount < MAX_CALLS) {
            recursiveCall();
            recallCount++;
            dispatch({
              type: LONG,
              payload: { data: {}, type: LONG },
            });
          } else {
            dispatch({
              type: FAILD,
              payload: { data: {}, type: FAILD },
            });
          }
        });
    }
    recursiveCall();
  };
}

export function getInstantMeta(params: any) {
  let apiUrl = END_POINTS.metaMain + params.type + "/" + lang;
  return globalGetAction(apiUrl);
}

export function getInstantContact(params: any) {
  let apiUrl = END_POINTS.contacts;
  return globalPostAction(params, apiUrl);
}

export function exportDocument(url: string, params: any, fileName: string) {
  let formData = jsonToFormData(params);
  return globalDownloadAction(formData, url, fileName);
}
