import { Tooltip } from "@mui/material";
import { t } from "i18next";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Link, useOutletContext } from "react-router-dom";
import AddPlusIcon from "../../../Assets/icon/AddPlusIcon";
import FemaleIcon from "../../../Assets/img/Female.svg";
import MaleIcon from "../../../Assets/img/Male.svg";
import PregnantIcon from "../../../Assets/img/Pregnant.svg";
import { END_POINTS } from "../../../Constant/Api";
import { DATATABLE } from "../../../Constant/General";
import { HORSEDETAILS_HEALTH_DENTAL } from "../../../Constant/Route";
import { useLoading } from "../../../context/LoadingContext";
import { getListMetaDataApi } from "../../../Redux/NonRedux/general";
import { getHealthDental } from "../../../Redux/NonRedux/health";
import { encrDecrStr, getDefaultSorting, getListMonthCol } from "../../../Utility/General";
import CustomeButton from "../../Common/CustomButton/CustomButton";
import NoDataFound from "../../Common/NoDataFound/NoDataFound";
import OnlyCk from "../../Common/OnlyCk/OnlyCk";
import SearchBox from "../../Common/SearchBox/SearchBox";
import Pagination from "../../Layouts/Pagination/Pagination";
import Loader from "../../Loader/Loader";
import AddDentalPopup from "../../Popups/AddDentalPopup/AddDentalPopup";
import CheckCircleIcon from "../../../Assets/img/check-circle-2.svg";

export default function DentalHealth() {
  const defaultSorting : any = getDefaultSorting(DATATABLE.HELTH_DENTAL);
  const [AddDentalModalShow, setAddDentalModalShow] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [currentPageSize, setCurrentPageSize] = useState(10);
  const [recordsTotal, setRecordsTotal] = useState(1);
  const [searchText, setSearchText] = useState();
  const [listData, setListData] = useState([]);
  const [listHeaderMonth, setListHeaderMonth] = useState<any>();
  const currentMonth = moment().format("MM");
  const [checkedRec, setCheckedRec] = useState<number[]>([]);
  const [refreshList, setRefreshList] = useState(false);
  const prefrence = useSelector(
    (state: any) => state?.masterReducer?.data?.component?.preferance
  );
  const [sortBy, setSortBy] = React.useState(defaultSorting?.value);
  const [sortByField, setSortByField] = useState(defaultSorting?.key);
  const [sortType, setSortType] = useState(defaultSorting?.type);
  const [orderList, setOrderList] = useState<any>([]);
  const { setLoading } = useLoading();
  const [metaData, setMetaData] = useState({});

  const { setExportParams }: any = useOutletContext();
  const getIconbyType = (type: any) => {
    if (type == "male") {
      return MaleIcon;
    } else if (type == "female") {
      return FemaleIcon;
    } else if (type == "pregnant") {
      return PregnantIcon;
    }
  };

  // const checkSingleRec = (e: any, val: number) => {
  //   var checkedRecData: number[] = Object.assign([], checkedRec);
  //   if (e.target.checked) {
  //     checkedRecData.push(val);
  //   } else {
  //     checkedRecData = checkedRecData.filter((c: number) => c !== val);
  //   }
  //   setCheckedRec(checkedRecData);
  // };

  const horseDetailLink = (id: any) => {
    return HORSEDETAILS_HEALTH_DENTAL + "/" + encrDecrStr(id, "encr");
  };

  const sortrecords = (fieldName: any) => {
    if (orderList?.[fieldName]) {
      let sortByNow = orderList[fieldName];
      if (sortBy == sortByNow) {
        let sortTypeNow = sortType == "asc" ? "desc" : "asc";
        setSortType(sortTypeNow);
      } else {
        setSortBy(sortByNow);
        setSortByField(fieldName);
        setSortType("asc");
      }
    }
  };

  const sortableClass = (fieldName: string): string => {
    if (orderList?.[fieldName]) {
      return "sortable";
    } else {
      return "";
    }
  };

  const sortingTypeClass = (fieldName: string): string => {
    if (sortByField === fieldName) {
      return sortType == "asc" ? "arrow-up" : "arrow-down";
    } else {
      return "";
    }
  };

  const tooltipValue = (tooltipObj: any) => {
    return (
      <div className="info-table tooltip-tbl">
        <table>
          <tbody>
            <tr>
              <td>
                <p className="tm">{t("Date")}</p>
              </td>
              <td>
                <p className="tm blur-color">{tooltipObj.dates}</p>
              </td>
            </tr>
            <tr>
              <td>
                <p className="tm">{t("Vet/Clinic")}</p>
              </td>
              <td>
                <p className="tm blur-color">{tooltipObj.vat_clinic}</p>
              </td>
            </tr>
            <tr>
              <td>
                <p className="tm">{t("Amount")}</p>
              </td>
              <td>
                <p className="tm blur-color">
                  {tooltipObj.amount + " " + prefrence?.currency_value}
                </p>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    );
  };


  const getMetaData = () => {
    setLoading(true);  
    getListMetaDataApi(END_POINTS.metaHelthDental , {type:"multiple"})
      .then((response: any) => {
        setMetaData(response?.component);
      })
      .catch((error: any) => {})
      .finally(() => {
        setLoading(false);
      });
  };

  useEffect(() => {
    getMetaData();
    let headerMonth = getListMonthCol();
    setListHeaderMonth(headerMonth);
  }, []);

  


  useEffect(() => {
    listAPICall();
  }, [currentPage, currentPageSize, sortBy, sortType]);

  useEffect(() => {
    if (searchText != null) {
      const searchTimeOut = setTimeout(() => {
        if (currentPage === 1) {
          listAPICall();
        } else {
          setCurrentPage(1);
        }
      }, 500);
      return () => clearTimeout(searchTimeOut);
    }
  }, [searchText]);

  useEffect(() => {
    if (refreshList) {
      listAPICall();
      setRefreshList(false);
    }
  }, [refreshList]);

  const listAPICall = () => {
    setLoading(true);
    let params: any = {
      page: currentPage,
      search: searchText,
      limit: currentPageSize,
    };
    if (sortBy && sortType) {
      params["sort"] = {
        column: sortBy,
        type: sortType,
      };
    }
    setExportParams(params);
    getHealthDental(params)
      .then((res: any) => {
        setListData(res?.component?.listing);
        setCurrentPage(res?.component?.page);
        setCurrentPageSize(res?.component?.limit);
        setRecordsTotal(res?.component?.recordsFiltered);
        setOrderList(res?.component?.orderlist);
      })
      .catch((err: any) => {})
      .finally(() => {
        setLoading(false);
      });
  };

  return (
    <>
      <div className="health-dental-page search-and-btn">
        <div className="data-wrapper">
          <div className="data-header">
            <SearchBox
              placeholder={t("Search Horse...")}
              onChange={(e: any) => setSearchText(e.target.value)}
              value={searchText}
            />
            <div className="right-section">
              <div className="btn-wrapper fb">
                {/* <CustomeButton>
                  <Filtericon />
                  <p>Filter</p>
                </CustomeButton> */}
                <CustomeButton
                  bg="fill"
                  onClick={() => {
                    setAddDentalModalShow(true);
                  }}
                >
                  <AddPlusIcon />
                  {t("Add")}
                </CustomeButton>
              </div>
            </div>
          </div>
          <div className="data-body">
            <div className="img-responsive-table first-2-column-fix-web">
              <table>
                <thead>
                  <tr>
                    {/* <th className="ck-wrapper"><OnlyCk /></th> */}
                    <th className="first-img-wrapper"></th>
                    <th>
                      <p className={`tm icon-text ${sortableClass(
                            "name"
                          )} ${sortingTypeClass("name")}`} 
                          onClick={() => sortrecords("name")}>{t("Horse")}</p>
                    </th>
                    {listHeaderMonth?.map((items: any, index: number) => (
                      <th key={index + "month"}>
                        <p className="tm MMW-100">
                        {`${moment(items).format("MMM")} ${moment(items).format("YYYY")}`}
                        </p>
                      </th>
                    ))}
                  </tr>
                </thead>
                {listData?.length ? (
                  <tbody>
                    {listData?.map((items: any, index: number) => (
                      <tr key={index + "myhorse-tbl"}>
                        {/* <td className="ck-wrapper">
                          <OnlyCk
                            checked={checkedRec.includes(items.id)}
                            disabled={
                              checkedRec.includes(items.id)
                                ? false
                                : checkedRec?.length
                            }
                            onChange={(e: any) => checkSingleRec(e, items.id)}
                          />
                        </td> */}
                        <td className="first-img-wrapper">
                          <img
                            src={items.profile}
                            alt=""
                            className="horse-img"
                          />
                        </td>
                        <td>
                          <div className="icon-text">
                            <img src={getIconbyType(items?.type_slug)} alt="" />
                            <Link
                              to={horseDetailLink(items.id)}
                              className="a-bl"
                            >
                              {items.horse_name}
                            </Link>
                          </div>
                        </td>
                        {listHeaderMonth?.map((itemsIs: any, index2: number) =>
                          items?.health[moment(itemsIs).format("YYYY-MM")]
                            ?.length ? (
                            <td key={index + index2 + "myhorse-tbltd"}>
                              {items.health[
                                moment(itemsIs).format("YYYY-MM")
                              ]?.map((itemTip: any, index3: number) => (
                                <Tooltip
                                  title={tooltipValue(itemTip)}
                                  arrow={true}
                                  key={index3 + Math.random()}
                                >
                                  <img
                                    src={CheckCircleIcon}
                                    className="icon-24"
                                  />
                                </Tooltip>
                              ))}
                            </td>
                          ) : (
                            <td key={index + index2 + "myhorse-tbltd"}></td>
                          )
                        )}
                      </tr>
                    ))}
                  </tbody>
                ) : (
                  <tbody></tbody>
                )}
              </table>
            </div>
            {listData?.length ? (
              <Pagination
                results={recordsTotal}
                currentPage={currentPage}
                setCurrentPage={setCurrentPage}
                currentLength={currentPageSize}
                setCurrentPageSize={setCurrentPageSize}
              />
            ) : (
              <NoDataFound />
            )}
          </div>
        </div>
      </div>
      <AddDentalPopup
        show={AddDentalModalShow}
        isMultiple={1}
        isedit={"false"}
        setrefreshlist={() => {
          setRefreshList(true);
          getMetaData();
        }}
        onHide={() => setAddDentalModalShow(false)}
        metaData={metaData}
      />
    </>
  );
}
