import React, { useEffect, useState } from "react";
import { Col, Row } from "react-bootstrap";
import CustomeButton from "../../Common/CustomButton/CustomButton";
import AddPlusIcon from "../../../Assets/icon/AddPlusIcon";
import flushIcon from "../../../Assets/img/Flush.svg";
import embryoIcon from "../../../Assets/img/embryoTransfer.svg";

import binIcon from "../../../Assets/img/bin.svg";
import pencilIcon from "../../../Assets/img/pencil.svg";
import OpenIcon from "../../../Assets/img/open.svg";
import PregnantIcon from "../../../Assets/img/pregnant-fill.svg";
import { Link, useParams } from "react-router-dom";
import { useLoading } from "../../../context/LoadingContext";
import { useTranslation } from "react-i18next";
import {
  destroyBreedingService,
  getAllBreedingServiceList,
  getBreedingMeta,
  viewBreedingService,
} from "../../../Redux/NonRedux/breeding";
import {
  encrDecrStr,
  getDefaultSorting,
  getSlugNamefromId,
} from "../../../Utility/General";
import ConfirmPopup from "../../Popups/ConfirmPopup/ConfirmPopup";
import { useDispatch } from "react-redux";
import { HORSEDETAILS_BREEDING_SERVICE_DETAILS } from "../../../Constant/Route";
import Pagination from "../../Layouts/Pagination/Pagination";
import NoDataFound from "../../Common/NoDataFound/NoDataFound";
import { useOutletContext } from "react-router-dom";
import TableHead from "../../Common/DataTable/TableHead/TableHead";
import { DATATABLE } from "../../../Constant/General";
import AddBreedingControlPopup from "../../Popups/AddBreedingControlPopup/AddBreedingControlPopup";
import AddBreedingServiceMarePopup from "../../Popups/AddBreedingServiceMarePopup/AddBreedingServiceMarePopup";
import AddFlushPopup from "../../Popups/AddFlushPopup/AddFlushPopup";
import AddFoalPopup from "../../Popups/AddFoalPopup/AddFoalPopup";
import AddTransferPopup from "../../Popups/AddTransferPopup/AddTransferPopup";
import moment from "moment";
import { horseInfoAction } from "../../../Redux/Horse/HorseAction";

export default function BreedingServiceMare() {
  const defaultSorting: any = getDefaultSorting(DATATABLE.HORSE_BREEDING);
  const param = useParams();
  const [horseId, setHorseId] = useState<any>(0);

  const [listData, setListData] = useState<any>([]);
  const [recordsTotal, setRecordsTotal] = React.useState(1);
  const [currentPage, setCurrentPage] = React.useState(1);
  const [currentPageSize, setCurrentPageSize] = React.useState(10);
  const { setLoading } = useLoading();
  const [mainData, setMainData] = useState<any>([]);
  const { t } = useTranslation();
  const [breedingServiceDeletableRecords, setBreedingServiceDeletableRecords] =
    useState<any>([]);
  const [confirmPopupShow, setConfirmPopupShow] = useState(false);
  const [sortByField, setSortByField] = useState(defaultSorting?.key);
  const [sortType, setSortType] = useState(defaultSorting?.type);
  const [orderList, setOrderList] = useState<any>([]);
  // can add service (if horse age is less than something horse can not breed)
  const [canAddService, setCanAddService] = useState(true);

  const [currentServiceDetails, setCurrentServiceDetails] = useState<any>({});
  const [bereedingServiceEdit, setBereedingServiceEdit] =
    useState<boolean>(false);
  const [breedingServiceEditData, setBreedingServiceEditData] = useState<any>(
    {}
  );
  const [AddFlushModalShow, setAddFlushModalShow] = useState(false);
  const [AddTransferModalShow, setAddTransferModalShow] = useState(false);
  const [AddFoalModalShow, setAddFoalModalShow] = useState(false);
  const [metaData, setMetaData] = useState<any>([]);
  const [refreshMeta, setRefreshMeta] = useState(false);
  const [refreshList, setRefreshList] = useState(false);
  const [AddBreedingServiceMareModalShow, setAddBreedingServiceMareModalShow] =
    useState(false);
  const [AddBreedingControlModalShow, setAddBreedingControlModalShow] =
    useState(false);
    const dispatch = useDispatch();
  const {setExportParams}: any  = useOutletContext();
  const tableHead = [
    {
      label: t("Breeding Date"),
      className: "tm",
      key: "breeding_date",
    },
    {
      label: t("Mare"),
      className: "tm",
      key: "mare",
    },
    {
      label: t("Stallion"),
      className: "tm",
      key: "stallion",
    },
    {
      label: t("Status"),
      className: "tm",
      key: "status",
    },

    {
      label: t("Pregnancy Check"),
      className: "tm",
      key: "last_checked",
    },
    {
      label: t("Expected Foaling Date"),
      className: "tm",
      key: "expected_foaling_date",
    },
    {
      label: t("Foal"),
      className: "tm",
      key: "foal",
    },
    {
      label: t("Action"),
      className: "tm",
      key: "",
      headClass: "text-right",
    },
  ];

  const getMeta = () => {
    setLoading(true);
    let horse_id = encrDecrStr(param?.slug, "decr");
    let params = {
      horse_id: horse_id,
    };
    getBreedingMeta(params)
      .then((res: any) => {
        setMetaData(res?.component);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  useEffect(() => {
    if (refreshMeta) {
      getMeta();
      setRefreshMeta(false);
    }
  }, [refreshMeta]);

  useEffect(() => {
    getMeta();
    let id = encrDecrStr(param?.slug, "decr");
    setHorseId(id);
  }, []);

  const listApiCall = () => {
    let horse_id = encrDecrStr(param?.slug, "decr");
    let params: any = {
      horse_id: horse_id,
      page: currentPage,
      limit: currentPageSize,
    };
    setExportParams(params);
    if (sortByField && sortType) {
      params["sort"] = {
        column: orderList[sortByField]
          ? orderList[sortByField]
          : defaultSorting?.value,
        type: sortType,
      };
    }
    setLoading(true);
    getAllBreedingServiceList(params)
      .then((response: any) => {
        setListData(response?.component?.listing);
        setRecordsTotal(response?.component?.recordsFiltered);
        setMainData(response?.component?.mainData);
        setOrderList(response?.component?.orderlist);
        setCanAddService(response?.component?.canAddService);
      })
      .catch((error: any) => {})
      .finally(() => {
        setLoading(false);
      });
  };

  useEffect(() => {
    listApiCall();
  }, [currentPage, currentPageSize, sortByField, sortType]);

  useEffect(() => {
    if (refreshList) {
      listApiCall();
      
      horseInfoAction({ id: horseId });
      setRefreshList(false);
    }
  }, [refreshList]);

  const editBreedingServiceHandler = (id: number) => {
    let params = {
      horse_id: horseId,
      id: id,
    };

    setLoading(true);
    viewBreedingService(params)
      .then((response: any) => {
        setBereedingServiceEdit(true);
        setBreedingServiceEditData(response?.component);
        setAddBreedingServiceMareModalShow(true);
      })
      .catch((error) => {})
      .finally(() => {
        setLoading(false);
      });
  };

  const deleteBreedingServiceHandler = (id: number) => {
    let params = {
      id: breedingServiceDeletableRecords,
    };

    setLoading(true);
    destroyBreedingService(params)
      .then((response: any) => {
        setConfirmPopupShow(false);
        setBreedingServiceDeletableRecords([]);
        setRefreshList(true);
        setRefreshMeta(true);
      })
      .catch((error) => {})
      .finally(() => {
        setLoading(false);
      });
  };

  const deleteNowBreedingService = (id: number) => {
    let ids = breedingServiceDeletableRecords;
    ids.push(id);
    setBreedingServiceDeletableRecords(ids);
    setConfirmPopupShow(true);
  };

  const setCurrentBredingServiceValues = (items: any) => {
    let dateFormated = items.dates
      ? moment(items.dates, "DD/MM/YYYY").format("YYYY-MM-DD")
      : null;
    setCurrentServiceDetails({
      id: items?.id,
      sire: items?.stallion,
      dam: items?.mare,
      start_date: dateFormated,
    });
  };

  return (
    <>
      <div className="breeding-horse-page">
        <div className="summary-head">
          <Row>
            <Col sm="12" md="6" lg="4">
              <div className="summary-box">
                <p className="tl blur-color">{t("Pregnancy Status")}</p>
                <div className="right-details">
                  <h5>{mainData?.pregnant_status}</h5>
                  <p className="tm"></p>
                </div>
              </div>
            </Col>
            <Col sm="12" md="6" lg="4">
              <div className="summary-box">
                <p className="tl blur-color">{t("No. of Offspring")}</p>
                <div className="right-details">
                  <h5>{mainData?.number_offspring}</h5>
                  <p className="tm"></p>
                </div>
              </div>
            </Col>
            <Col sm="12" md="6" lg="4">
              <div className="summary-box">
                <p className="tl blur-color">{t("Last Foal")}</p>
                <div className="right-details">
                  <h5>{mainData?.last_foal_date}</h5>
                  <p className="tm">{mainData?.foal_horse_name}</p>
                </div>
              </div>
            </Col>
          </Row>
        </div>

        <div className="data-tbl-wrapper">
          <div className="data-tbl-header">
            <p className="tl">{t("Breeding Services")}</p>
            <div className="btn-wrapper">
              <CustomeButton
                bg="fill"
                disabled={!metaData?.activeService ? true : false}
                onClick={() => {
                  setAddBreedingControlModalShow(true);
                }}
              >
                <AddPlusIcon />
                {t("Add Control")}
              </CustomeButton>

              <CustomeButton
                bg="fill"
                disabled={
                  !(!metaData?.activeService && canAddService) ? true : false
                }
                onClick={() => {
                  setBereedingServiceEdit(false);
                  setBreedingServiceEditData({});
                  setAddBreedingServiceMareModalShow(true);
                }}
              >
                <AddPlusIcon />
                {t("Add Service")}
              </CustomeButton>
            </div>
          </div>
          <div className="data-tbl">
            <table>
              <TableHead
                data={tableHead}
                orderList={orderList}
                sortByField={sortByField}
                sortType={sortType}
                setSortType={setSortType}
                setSortByField={setSortByField}
              />
              <tbody>
                {listData.map((items: any, index: number) => (
                  <tr key={index + "breeding-service"}>
                    <td>
                      <Link
                        to={{
                          pathname:
                            HORSEDETAILS_BREEDING_SERVICE_DETAILS +
                            "/" +
                            param?.slug,
                          search: `?service_id=${encrDecrStr(
                            items?.id,
                            "encr"
                          )}`,
                        }}
                        state={{ service_id: items?.id }}
                        className="a-bl"
                      >
                        {items?.dates}
                      </Link>
                    </td>
                    <td>
                      <p className="bl">{items?.mare}</p>
                    </td>
                    <td>
                      <p className="bl">{items?.stallion}</p>
                    </td>
                    <td>
                      <p className="bl icon-text">
                        <img
                          src={
                            items?.pregnant_status === "pregnant"
                              ? PregnantIcon
                              : OpenIcon
                          }
                          alt=""
                        />{" "}
                        {items?.pregnant_status_value}
                      </p>
                    </td>
                    <td>
                      <p className="bl">{items?.next_check_controll}</p>
                    </td>
                    <td>
                      <p className="bl">{items?.expected_foaling_date}</p>
                    </td>
                    <td>
                      {items?.can_foal && items?.can_foal_button ? (
                        <span
                          className="a-bl"
                          onClick={() => {
                            setCurrentBredingServiceValues(items);
                            setAddFoalModalShow(true);
                          }}
                        >
                          {items?.foal}
                        </span>
                      ) : (
                        <p className="bl">{items?.foal}</p>
                      )}
                    </td>
                    <td>
                      <div className="icon-wrapper">
                        {metaData?.activeService == items?.id &&
                          items?.can_flush && (
                            <a
                              onClick={() => {
                                setCurrentBredingServiceValues(items);
                                setAddFlushModalShow(true);
                              }}
                            >
                              <img src={flushIcon} alt="" />
                            </a>
                          )}

                        {metaData?.activeService == items?.id &&
                          items?.can_embryoTransfer && (
                            <a
                              onClick={() => {
                                setCurrentBredingServiceValues(items);
                                setAddTransferModalShow(true);
                              }}
                            >
                              <img src={embryoIcon} alt="" />
                            </a>
                          )}

                        {(metaData?.activeService == items?.id ||
                          items?.can_edit_service) && (
                          <>
                            <a>
                              <img
                                src={pencilIcon}
                                onClick={() =>
                                  editBreedingServiceHandler(items?.id)
                                }
                                alt=""
                              />
                            </a>
                          </>
                        )}

                        <a>
                          <img
                            src={binIcon}
                            onClick={() => deleteNowBreedingService(items?.id)}
                            alt=""
                          />
                        </a>
                      </div>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
          {listData?.length ? (
            <Pagination
              results={recordsTotal}
              currentPage={currentPage}
              setCurrentPage={setCurrentPage}
              currentLength={currentPageSize}
              setCurrentPageSize={setCurrentPageSize}
            />
          ) : (
            <NoDataFound />
          )}
        </div>
      </div>

      <ConfirmPopup
        heading={t("Delete")}
        message={t("Are you sure to delete this record?")}
        show={confirmPopupShow}
        onHide={() => {
          setConfirmPopupShow(false);
          setBreedingServiceDeletableRecords([]);
        }}
        confirm={deleteBreedingServiceHandler}
      />

      <AddBreedingServiceMarePopup
        show={AddBreedingServiceMareModalShow}
        onHide={() => {
          setAddBreedingServiceMareModalShow(false);
        }}
        isedit={bereedingServiceEdit}
        setRefreshList={() => {
          setRefreshList(true);
          setRefreshMeta(true);
        }}
        horseId={horseId}
        editData={breedingServiceEditData}
        metaData={metaData}
      />

      <AddBreedingControlPopup
        show={AddBreedingControlModalShow}
        breedingServiceDate={metaData?.breading_date}
        onHide={() => setAddBreedingControlModalShow(false)}
        isedit={false}
        setRefreshList={() => {
          setRefreshMeta(true);
          setRefreshList(true);
        }}
        editData={{}}
        serviceId={metaData?.activeService}
        metaData={metaData}
        isEmbryoTransferCase={false}
      />

      <AddFlushPopup
        show={AddFlushModalShow}
        breedingServiceDate={currentServiceDetails?.start_date}
        onHide={() => setAddFlushModalShow(false)}
        isedit={false}
        setRefreshList={() => {
          setRefreshMeta(true);
          setRefreshList(true);
        }}
        editData={{}}
        metaData={metaData}
        serviceId={metaData?.activeService}
      />

      <AddTransferPopup
        show={AddTransferModalShow}
        breedingServiceDate={currentServiceDetails?.start_date}
        onHide={() => setAddTransferModalShow(false)}
        isedit={false}
        setRefreshList={() => {
          setRefreshMeta(true);
          setRefreshList(true);
        }}
        editData={{}}
        metaData={metaData}
        serviceId={currentServiceDetails?.id}
      />

      {AddFoalModalShow && (
        <AddFoalPopup
          show={AddFoalModalShow}
          onHide={() => setAddFoalModalShow(false)}
          isedit={false}
          breedingServiceDate={currentServiceDetails?.start_date}
          setRefreshList={() => {
            setRefreshList(true);
            setRefreshMeta(true);
          }}
          horseId={horseId}
          editData={{}}
          metaData={metaData}
          serviceId={currentServiceDetails?.id}
          sire={currentServiceDetails?.sire}
          dam={currentServiceDetails?.dam}
        />
      )}
    </>
  );
}
