import React, { useEffect, useState } from "react";
import AddPlusIcon from "../../../Assets/icon/AddPlusIcon";
import binIcon from "../../../Assets/img/bin.svg";
import PaperClipIcon from "../../../Assets/img/Common-icon/PaperClipIcon";
import pencilIcon from "../../../Assets/img/pencil.svg";
import CustomeButton from "../../Common/CustomButton/CustomButton";
import AddFarrierPopup from "../../Popups/AddFarrierPopup/AddFarrierPopup";
import ScissorsIcon from "../../../Assets/img/Scissors.svg";
import CompleteShoeingIcon from "../../../Assets/img/CompleteShoeing.svg";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate, useOutletContext, useParams } from "react-router-dom";
import { API_STATUS, END_POINTS } from "../../../Constant/Api";
import ConfirmPopup from "../../Popups/ConfirmPopup/ConfirmPopup";
import {
  destoryFarrierRecordAction,
  getFarrierRecordsAction,
  viewFarrierRecordAction,
} from "../../../Redux/Horse/Farrier/FarrierAction";
import {
  VIEW_FARRIER_RECORD_CLEAR,
  DESTROY_FARRIER_RECORD_CLEAR,
  DESTROY_FARRIER_RECORD_REQUEST,
  GET_FARRIER_RECORD_LIST_CLEAR,
  DESTROY_FARRIER_RECORD_FAILD,
  DESTROY_FARRIER_RECORD_LONG,
  DESTROY_FARRIER_RECORD_SUCCESS,
  GET_FARRIER_RECORD_LIST_FAILD,
  GET_FARRIER_RECORD_LIST_LONG,
  GET_FARRIER_RECORD_LIST_REQUEST,
  GET_FARRIER_RECORD_LIST_SUCCESS,
  VIEW_FARRIER_RECORD_SUCCESS,
  VIEW_FARRIER_RECORD_FAILD,
  VIEW_FARRIER_RECORD_REQUEST,
  VIEW_FARRIER_RECORD_LONG,
} from "../../../Redux/Horse/Farrier/FarrierTypes";
import { SUCCESS_RESPONSE_CODE } from "../../../Constant/Status";
import { encrDecrStr, getDefaultSorting, openDocument } from "../../../Utility/General";
import {
  FARRIER_CONTACT_CLEAR,
  REMINDER_STATUS_CHANGE_CLEAR,
  REMINDER_STATUS_CHANGE_REQUEST,
  REMINDER_STATUS_CHANGE_SUCCESS,
} from "../../../Redux/General/GeneralType";
import Loader from "../../Loader/Loader";
import NoDataFound from "../../Common/NoDataFound/NoDataFound";
import { useTranslation } from "react-i18next";
import Pagination from "../../Layouts/Pagination/Pagination";
import { useLoading } from "../../../context/LoadingContext";
import { DATATABLE, REMINDER_REDIRECT } from "../../../Constant/General";
import { get } from "http";
import { getListMetaDataApi } from "../../../Redux/NonRedux/general";

export default function FarrierPage() {
  const defaultSorting : any = getDefaultSorting(DATATABLE.FARRIER);
  const { t, i18n } = useTranslation();
  const { setExportParams }: any = useOutletContext();
  const dispatch = useDispatch();
  const param = useParams();
  const [horseId, setHorseId] = useState<any>(0);
  const [apiStatus, setApiStatus] = useState(API_STATUS.SUCCESS);
  const [farrierRecordList, setFarrierRecordList] = useState([]);
  const [deletableRecords, setDeletableRecords] = useState<any>([]);
  const [confirmPopupShow, setConfirmPopupShow] = useState(false);
  const [orderList, setOrderList] = useState<any>([]);
  const [editData, setEditData] = useState<any>({});
  const [isEdit, setIsEdit] = useState<boolean>(false);
  const [refreshList, setRefreshList] = useState(false);
  const [AddFarrierModalShow, setAddFarrierModalShow] = useState(false);
  const { setLoading } = useLoading();
  const [recordsTotal, setRecordsTotal] = React.useState(1);
  const [currentPage, setCurrentPage] = React.useState(1);
  const [currentPageSize, setCurrentPageSize] = React.useState(10);
  const [sortByField, setSortByField] = React.useState(defaultSorting?.key);
  const [sortBy, setSortBy] = React.useState(defaultSorting?.value);
  const [sortType, setSortType] = React.useState(defaultSorting?.type);
  const [metaData, setMetaData] = useState({});
  const location:any = useLocation();
  const navigate = useNavigate();
  
  const farrierRecordListResoponse = useSelector(
    (state: any) => state?.farrierRecordListReducer
  );
  const farrierEdit = useSelector(
    (state: any) => state?.viewFarrierRecordReducer
  );
  const deleteDietResponse = useSelector(
    (state: any) => state?.destroyFarrierRecordReducer
  );
  const prefrence = useSelector(
    (state: any) => state?.masterReducer?.data?.component?.preferance
  );

  useEffect(() => {  
    if(location?.state?.reminder_id && location?.state?.path){
      setIsEdit(false);
      setEditData({});
      setAddFarrierModalShow(true)
    }
  }, [location?.state]);

  const listApiCall = () => {
    let id = encrDecrStr(param?.slug, "decr");
    setHorseId(id);
    let params: any = {
      form_type: "farrier",
      horse_id: id,
      page: currentPage,
      limit: currentPageSize,
    };
    if (sortBy && sortType) {
      params["sort"] = {
        column: sortBy,
        type: sortType,
      };
    }
    setExportParams(params);
    getFarrierRecordsAction(params);
  };

  const getMetaData = () => {
    setLoading(true);  
    getListMetaDataApi(END_POINTS.metaFarrier)
      .then((response: any) => {
        setMetaData(response?.component);
      })
      .catch((error: any) => {})
      .finally(() => {
        setLoading(false);
      });
  };


  useEffect(() => {
    getMetaData();
    return () => {
      dispatch({
        type: GET_FARRIER_RECORD_LIST_CLEAR,
        data: {
          type: GET_FARRIER_RECORD_LIST_CLEAR,
        },
      });
      dispatch({
        type: VIEW_FARRIER_RECORD_CLEAR,
        data: {
          type: VIEW_FARRIER_RECORD_CLEAR,
        },
      });
      dispatch({
        type: DESTROY_FARRIER_RECORD_CLEAR,
        data: {
          type: DESTROY_FARRIER_RECORD_CLEAR,
        },
      });
    };
  }, []);

  useEffect(() => {
    listApiCall();
  }, [currentPage, currentPageSize, sortBy, sortType]);

  useEffect(() => {
    if (refreshList) {
      listApiCall();
      setRefreshList(false);
    }
  }, [refreshList]);

  useEffect(() => {
    if (deleteDietResponse?.type === DESTROY_FARRIER_RECORD_REQUEST) {
      if (apiStatus !== API_STATUS.LOADING) {
        setLoading(true);
        setApiStatus(API_STATUS.LOADING);
      }
    } else if (
      deleteDietResponse?.data?.statusCode === SUCCESS_RESPONSE_CODE &&
      deleteDietResponse?.type === DESTROY_FARRIER_RECORD_SUCCESS
    ) {
      if (apiStatus !== API_STATUS.SUCCESS) {
        setApiStatus(API_STATUS.SUCCESS);
        setLoading(false);
        setRefreshList(true);
        setDeletableRecords([]);
        setConfirmPopupShow(false);
        // setFarrierRecordList(farrierRecordListResoponse?.data?.component);
        // setOrderList(farrierRecordListResoponse?.data?.component?.orderlist);
      }
    } else if (deleteDietResponse?.type === DESTROY_FARRIER_RECORD_LONG) {
      if (apiStatus !== API_STATUS.LONG) {
        setLoading(false);
        setApiStatus(API_STATUS.LONG);
      }
    } else if (deleteDietResponse?.type === DESTROY_FARRIER_RECORD_FAILD) {
      if (apiStatus !== API_STATUS.FAILED) {
        setLoading(false);
        setApiStatus(API_STATUS.FAILED);
      }
    }
  }, [deleteDietResponse]);

  useEffect(() => {
    // listApiCall();
    if (farrierEdit?.type == VIEW_FARRIER_RECORD_REQUEST) {
      setLoading(true);
    } else if (
      farrierEdit?.type === VIEW_FARRIER_RECORD_SUCCESS &&
      farrierEdit?.data?.statusCode === SUCCESS_RESPONSE_CODE
    ) {
      setLoading(false);
      setEditData(farrierEdit?.data?.component);
      setRecordsTotal(farrierEdit?.data?.recordsFiltered);
      setIsEdit(true);
      setAddFarrierModalShow(true);
    } else if (farrierEdit?.type == VIEW_FARRIER_RECORD_LONG) {
      setLoading(false);
    } else if (farrierEdit?.type == VIEW_FARRIER_RECORD_FAILD) {
      setLoading(false);
    }
  }, [farrierEdit]);

  const deleteHandler = () => {
    let params = {
      id: deletableRecords,
    };
    destoryFarrierRecordAction(params);
  };

  const editHandler = (id: number) => {
    let params = {
      id: id,
    };
    viewFarrierRecordAction(params);
  };

  const deleteNow = (id: number) => {
    let ids = deletableRecords;
    ids.push(id);
    setDeletableRecords(ids);
    setConfirmPopupShow(true);
  };

  useEffect(() => {
    if (farrierEdit?.healthData?.form_type === "diet") {
      setIsEdit(true);
      setEditData(farrierEdit);
      setAddFarrierModalShow(true);
    }
  }, [farrierEdit]);

  useEffect(() => {
    if (farrierRecordListResoponse?.type === GET_FARRIER_RECORD_LIST_REQUEST) {
      if (apiStatus !== API_STATUS.LOADING) {
        setApiStatus(API_STATUS.LOADING);
        setLoading(true);
      }
    } else if (
      farrierRecordListResoponse?.data?.statusCode === SUCCESS_RESPONSE_CODE &&
      farrierRecordListResoponse?.type === GET_FARRIER_RECORD_LIST_SUCCESS
    ) {
      if (apiStatus !== API_STATUS.SUCCESS) {
        setApiStatus(API_STATUS.SUCCESS);
        setLoading(false);
        setFarrierRecordList(
          farrierRecordListResoponse?.data?.component?.listing
        );
        setRecordsTotal(
          farrierRecordListResoponse?.data?.component?.recordsFiltered
        );
        setOrderList(farrierRecordListResoponse?.data?.component?.orderlist);
      }
    } else if (
      farrierRecordListResoponse?.type === GET_FARRIER_RECORD_LIST_LONG
    ) {
      setLoading(false);
      if (apiStatus !== API_STATUS.LONG) {
        setApiStatus(API_STATUS.LONG);
      }
    } else if (
      farrierRecordListResoponse?.type === GET_FARRIER_RECORD_LIST_FAILD
    ) {
      setLoading(false);
      if (apiStatus !== API_STATUS.FAILED) {
        setApiStatus(API_STATUS.FAILED);
      }
    }
  }, [farrierRecordListResoponse]);

  const sortrecords = (fieldName: any) => {
    if (orderList[fieldName]) {
      let sortByNow = orderList[fieldName];
      if (sortBy == sortByNow) {
        let sortTypeNow = sortType == "asc" ? "desc" : "asc";
        setSortType(sortTypeNow);
      } else {
        setSortBy(sortByNow);
        setSortByField(fieldName);
        setSortType("asc");
      }
    }
  };

  const sortableClass = (fieldName: string): string => {
    if (orderList[fieldName]) {
      return "sortable";
    } else {
      return "";
    }
  };

  const sortingTypeClass = (fieldName: string): string => {
    if (sortByField === fieldName) {
      return sortType == "asc" ? "arrow-up" : "arrow-down";
    } else {
      return "";
    }
  };

  const handleRedirectBack = () => {
    if(location?.state?.reminder_id && location?.state?.path){
      navigate(location?.state?.path ,{ 
        state: {reminder_id:"" }
       });
    }
  }

  return (
    <>
      <div className="farrier-horse-page">
        <div className="data-tbl-wrapper">
          <div className="data-tbl-header">
            <p className="tl">{`${t("Farrier Records")}`}</p>
            <div className="btn-wrapper single-btn">
              <CustomeButton
                bg="fill"
                onClick={() => {
                  setIsEdit(false);
                  setEditData({});
                  setAddFarrierModalShow(true);
                }}
              >
                <AddPlusIcon />
                {`${t("Add")}`}
              </CustomeButton>
            </div>
          </div>
          <div className="data-tbl">
            <table>
              <thead>
                <tr>
                  <th>
                    <p
                      className={`tm ${sortableClass(
                        "date"
                      )} ${sortingTypeClass("date")}`}
                      onClick={() => sortrecords("date")}
                    >
                     {`${t("Date")}`} 
                    </p>
                  </th>
                  <th>
                    <p  className={`tm ${sortableClass(
                        "farrier"
                      )} ${sortingTypeClass("farrier")}`}
                      onClick={() => sortrecords("farrier")}>{`${t("Farrier")}`}</p>
                  </th>
                  <th>
                    <p  className={`tm  icon-text ${sortableClass(
                        "shoeing_type"
                      )} ${sortingTypeClass("shoeing_type")}`}
                      onClick={() => sortrecords("shoeing_type")}  >{`${t("Shoeing Type")}`}</p>
                  </th>
                  <th>
                    <PaperClipIcon />
                  </th>
                  <th className="text-right">
                    <p
                      className={`tm  ${sortableClass(
                        "amount"
                      )} ${sortingTypeClass("amount")}`}
                      onClick={() => sortrecords("amount")}
                    >
                      {`${t("Amount")}`}
                    </p>
                  </th>
                  <th className="text-right">
                    <p className="tm">{`${t("Action")}`}</p>
                  </th>
                </tr>
              </thead>
              <tbody>
                {farrierRecordList &&
                  farrierRecordList.length > 0 &&
                  farrierRecordList.map((items: any, index: number) => (
                    <tr key={index + "vaccination-data"}>
                      <td>
                        <p className="bl">{items?.dates}</p>
                      </td>
                      <td>
                        <p className="bl">{items?.farrier}</p>
                      </td>
                      <td>
                        <p className="bl icon-text">
                          <img
                            src={
                              items?.shoeing_value.toLowerCase() == "trimming"
                                ? ScissorsIcon
                                : CompleteShoeingIcon
                            }
                            alt=""
                          />{" "}
                          {items?.shoeing_value}
                        </p>
                      </td>
                      <td>
                        {items.document ? (
                          <p
                            className="bl"
                            onClick={() => openDocument(items.document)}
                          >
                            <PaperClipIcon />
                          </p>
                        ) : (
                          ""
                        )}
                      </td>
                      <td>
                        <p className="bl text-right">
                          {items?.amount} {prefrence?.currency_value}
                        </p>
                      </td>
                      <td>
                        <div className="icon-wrapper">
                          <a>
                            <img
                              src={pencilIcon}
                              onClick={() => editHandler(items?.id)}
                              alt=""
                            />
                          </a>
                          <a>
                            <img
                              src={binIcon}
                              onClick={() => deleteNow(items?.id)}
                              alt=""
                            />
                          </a>
                        </div>
                      </td>
                    </tr>
                  ))}
              </tbody>
            </table>
          </div>
          {farrierRecordList?.length ? (
            <Pagination
              results={recordsTotal}
              currentPage={currentPage}
              setCurrentPage={setCurrentPage}
              currentLength={currentPageSize}
              setCurrentPageSize={setCurrentPageSize}
            />
          ) : (
            <NoDataFound />
          )}
        </div>
      </div>

      <AddFarrierPopup
        show={AddFarrierModalShow}
        onHide={() => {
          setAddFarrierModalShow(false);
          handleRedirectBack();
        }}
        horseid={horseId}
        isedit={isEdit ? 1 : 0}
        farrierdata={editData}
        setrefreshlist={() => {
          setRefreshList(true);
          getMetaData();
        }}
        metaData={metaData}
      />

      <ConfirmPopup
        heading={t("Delete")}
        message={t("Are you sure to delete this record?")}
        show={confirmPopupShow}
        onHide={() => {
          setConfirmPopupShow(false);
          setDeletableRecords([]);
        }}
        confirm={deleteHandler}
      />
    </>
  );
}
