import { END_POINTS } from "../../Constant/Api";
import { globalPostActionWithReducer } from "../globallActions";
import { BOARDING_PLAN_LIST_REQUEST, BOARDING_PLAN_LIST_SUCCESS, BOARDING_PLAN_LIST_LONG, BOARDING_PLAN_LIST_FAILD } from "./BoardingType";

export function getBoardingPlanList(params: any) {
    let apiUrl = END_POINTS.boardingPlanList;
    return globalPostActionWithReducer(
      params,
      apiUrl,
      BOARDING_PLAN_LIST_REQUEST,
      BOARDING_PLAN_LIST_SUCCESS,
      BOARDING_PLAN_LIST_LONG,
      BOARDING_PLAN_LIST_FAILD
    );
  }