import { globalDownloadAction, globalPostAction } from "../../actions/globalActions";
import { END_POINTS } from "../../Constant/Api";
import { SHOW_MESSAGE } from "../../Constant/General";
import { jsonToFormData } from "../../Utility/General";

// horse wise breeding list 

export function getBreedingListHorseWise(params: any = {}) {
    let apiUrl = END_POINTS.breedingListHorseWise;
    return globalPostAction(params, apiUrl);
}

// service for mare 


export function getBreedingMeta(params: any) {
    let apiUrl = END_POINTS.getBreedingMeta;
    return globalPostAction(params, apiUrl);
}


export function addEditBreedingService(params: any) {
    let apiUrl = END_POINTS.addEditBreedingService;
    return globalPostAction(params, apiUrl , SHOW_MESSAGE);
}

export function destroyBreedingService(params: any) {
    let apiUrl = END_POINTS.destroyBreedingService;
    return globalPostAction(params, apiUrl , SHOW_MESSAGE);
}

export function viewBreedingService(params: any) {
    let apiUrl = END_POINTS.viewBreedingService;
    return globalPostAction(params, apiUrl );
}

export function getAllBreedingServiceList(params: any) {
    let apiUrl = END_POINTS.getAllBreedingServiceList;
    return globalPostAction(params, apiUrl );
}

// breeding for stallion horse

export function addEditBreedingStallion(params: any) {
    let apiUrl = END_POINTS.addEditBreedingStallion;
    return globalPostAction(params, apiUrl , SHOW_MESSAGE);
}

export function destroyBreedingStallion(params: any) {
    let apiUrl = END_POINTS.destroyBreedingStallion;
    return globalPostAction(params, apiUrl , SHOW_MESSAGE);
}

export function viewBreedingStallion(params: any) {
    let apiUrl = END_POINTS.viewBreedingStallion;
    return globalPostAction(params, apiUrl );
}

export function getAllBreedingStallionList(params: any) {
    let apiUrl = END_POINTS.getAllBreedingStallionList;
    return globalPostAction(params, apiUrl );
}


// change staillion  status in horse details api 


export function editStallionStatus(params: any) {
    let apiUrl = END_POINTS.editStallion;
    return globalPostAction(params, apiUrl , SHOW_MESSAGE);
}

export function viewStallionStatus(params: any) {
    let apiUrl = END_POINTS.viewStallion;
    return globalPostAction(params, apiUrl );
}


// control 


export function addEditBreedingControl(params: any) {
    let apiUrl = END_POINTS.addEditBreedingControl;
    return globalPostAction(params, apiUrl , SHOW_MESSAGE);
}

export function destroyBreedingControl(params: any) {
    let apiUrl = END_POINTS.destroyBreedingControl;
    return globalPostAction(params, apiUrl , SHOW_MESSAGE);
}

export function viewBreedingControl(params: any) {
    let apiUrl = END_POINTS.viewBreedingControl;
    return globalPostAction(params, apiUrl );
}

export function getBreedigServiceDetails(params: any) {
    let apiUrl = END_POINTS.getBreedingServiceDetails;
    return globalPostAction(params, apiUrl );
}

// flush 

export function addEditBreedingFlush(params: any) {
    let apiUrl = END_POINTS.addEditBreedingFlush;
    return globalPostAction(params, apiUrl , SHOW_MESSAGE);
}

export function destroyBreedingFlush(params: any) {
    let apiUrl = END_POINTS.destroyBreedingFlush;
    return globalPostAction(params, apiUrl , SHOW_MESSAGE);
}

export function viewBreedingFlush(params: any) {
    let apiUrl = END_POINTS.viewBreedingFlush;
    return globalPostAction(params, apiUrl );
}


// foal 

export function addEditBreedingFoal(params: any) {
    let apiUrl = END_POINTS.addEditBreedingFoal;
    return globalPostAction(params, apiUrl , SHOW_MESSAGE);
}

export function destroyBreedingFoal(params: any) {
    let apiUrl = END_POINTS.destroyBreedingFoal;
    return globalPostAction(params, apiUrl , SHOW_MESSAGE);
}

export function viewBreedingFoal(params: any) {
    let apiUrl = END_POINTS.viewBreedingFoal;
    return globalPostAction(params, apiUrl );
}


// embryo Transfer 

export function addEditEmbryoTransfer(params: any) {
    let apiUrl = END_POINTS.addEditEmbryoTransfer;
    return globalPostAction(params, apiUrl , SHOW_MESSAGE);
}

export function destroyEmbryoTransfer(params: any) {
    let apiUrl = END_POINTS.destroyEmbryoTransfer;
    return globalPostAction(params, apiUrl , SHOW_MESSAGE);
}

export function viewEmbryoTransfer(params: any) {
    let apiUrl = END_POINTS.viewEmbryoTransfer;
    return globalPostAction(params, apiUrl );
}

// mare gestation Calculator api 

export function getPedigree(params: any) {
    let apiUrl = END_POINTS.getHorsePedigree;
    return globalPostAction(params, apiUrl );
}


export function dowloadPedigree(params: any) {
    let apiUrl = END_POINTS.downloadHorsePedigree;
    let formData = jsonToFormData(params);
    return globalDownloadAction(formData, apiUrl, "Test Mating");

}
