import { ADD_MEASUREMENTS_REQUEST, ADD_MEASUREMENTS_SUCCESS, ADD_MEASUREMENTS_FAILD, ADD_MEASUREMENTS_LONG, ADD_MEASUREMENTS_CLEAR, GET_MEASUREMENTS_LIST_REQUEST, GET_MEASUREMENTS_LIST_SUCCESS, GET_MEASUREMENTS_LIST_FAILD, GET_MEASUREMENTS_LIST_LONG, GET_MEASUREMENTS_LIST_CLEAR, DESTROY_MEASUREMENTS_REQUEST, DESTROY_MEASUREMENTS_SUCCESS, DESTROY_MEASUREMENTS_FAILD, DESTROY_MEASUREMENTS_LONG, DESTROY_MEASUREMENTS_CLEAR, VIEW_MEASUREMENTS_REQUEST, VIEW_MEASUREMENTS_SUCCESS, VIEW_MEASUREMENTS_FAILD, VIEW_MEASUREMENTS_LONG, VIEW_MEASUREMENTS_CLEAR } from "./MeasurementsTypes";

export function addEditMeasurementsRedux(state = {}, action: any) {
    switch (action.type) {
      case ADD_MEASUREMENTS_REQUEST:
        return { ...action.payload };
      case ADD_MEASUREMENTS_SUCCESS:
        return { ...action.payload };
      case ADD_MEASUREMENTS_FAILD:
        return { ...action.payload };
      case ADD_MEASUREMENTS_LONG:
        return { ...action.payload };
      case ADD_MEASUREMENTS_CLEAR:
        return {};
      default:
        return state;
    }
}

export function measurementsListRedux(state = {}, action: any) {
  switch (action.type) {
    case GET_MEASUREMENTS_LIST_REQUEST:
      return { ...action.payload };
    case GET_MEASUREMENTS_LIST_SUCCESS:
      return { ...action.payload };
    case GET_MEASUREMENTS_LIST_FAILD:
      return { ...action.payload };
    case GET_MEASUREMENTS_LIST_LONG:
      return { ...action.payload };
    case GET_MEASUREMENTS_LIST_CLEAR:
      return {};
    default:
      return state;
  }
}

export function destroyMeasurementsRedux(state = {}, action: any) {
  switch (action.type) {
    case DESTROY_MEASUREMENTS_REQUEST:
      return { ...action.payload };
    case DESTROY_MEASUREMENTS_SUCCESS:
      return { ...action.payload };
    case DESTROY_MEASUREMENTS_FAILD:
      return { ...action.payload };
    case DESTROY_MEASUREMENTS_LONG:
      return { ...action.payload };
    case DESTROY_MEASUREMENTS_CLEAR:
      return {};
    default:
      return state;
  }
}


export function viewMeasurementsRedux(state = {}, action: any) {
  switch (action.type) {
    case VIEW_MEASUREMENTS_REQUEST:
      return { ...action.payload };
    case VIEW_MEASUREMENTS_SUCCESS:
      return { ...action.payload };
    case VIEW_MEASUREMENTS_FAILD:
      return { ...action.payload };
    case VIEW_MEASUREMENTS_LONG:
      return { ...action.payload };
    case VIEW_MEASUREMENTS_CLEAR:
      return {};
    default:
      return state;
  }
}