import React from "react";

export default function GlobeMobileIcon() {
  return (
    <svg
      width="32"
      height="32"
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M16 28C22.6 28 28 22.6 28 16C28 9.4 22.6 4 16 4C9.4 4 4 9.4 4 16C4 22.6 9.4 28 16 28Z"
        stroke="#E8E5E2"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M4 16H28"
        stroke="#E8E5E2"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M16 4C19 7.3 20.7 11.6 20.8 16C20.7 20.4 19 24.7 16 28C13 24.7 11.3 20.4 11.2 16C11.3 11.6 13 7.3 16 4Z"
        stroke="#E8E5E2"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}
