import { globalPostAction } from "../../actions/globalActions";
import { END_POINTS } from "../../Constant/Api";
import { SHOW_MESSAGE } from "../../Constant/General";


export function addEditFarm(params: any) {
    let apiUrl = END_POINTS.addEditFarm;
    return globalPostAction(params, apiUrl , SHOW_MESSAGE);
}

export function destroyFarms(params: any) {
    let apiUrl = END_POINTS.destroyFarm;
    return globalPostAction(params, apiUrl , SHOW_MESSAGE);
}

export function viewSingleFarm(params: any) {
    let apiUrl = END_POINTS.viewFarm;
    return globalPostAction(params, apiUrl );
}

export function getAllFarmList(params: any) {
    let apiUrl = END_POINTS.farmList;
    return globalPostAction(params, apiUrl );
}

export function getStudFarmList(params: any = {}) {
    let apiUrl = END_POINTS.getStudFarmList;
    return globalPostAction(params, apiUrl);
}

export function getFarmDetails(params: any = {}) {
    let apiUrl = END_POINTS.getStudFarmDetails;
    return globalPostAction(params, apiUrl);
}

export function getFarmHorseDetails(params: any = {}) {
    let apiUrl = END_POINTS.studFarmHorsePreview;
    return globalPostAction(params, apiUrl);
}