import { t } from "i18next";
import moment from "moment";
import React, { useEffect, useState } from "react";
import Filtericon from "../../Assets/icon/Filtericon";
import { getTypeFromFilter } from "../../Utility/General";
import CustomeButton from "../Common/CustomButton/CustomButton";
import Datepicker from "../Common/Datepicker/Datepicker";
import InputGroups from "../Common/InputGroups/InputGroups";
import SelectGropus from "../Common/SelectGroups/SelectGroups";

function Filter({ filterList, setFilteredValue, setCurrentPage }: any) {
  const [selectedFilter, setSelectedFilter] = useState<any>("");
  const [currentFilter, setCurrentFilter] = useState<any>({});
  const [filterdValue, setFilterdValue] = useState<string>("");
  const [fromDate, setFromDate] = useState<any>();
  const [toDate, setToDate] = useState<any>();
  const [disableApply, setDisableApply] = useState(true);
  const [isFilterd , setIsFiltered] = useState(false);

  const handleFilterChange = (e: any) => {
    let value = e.target.value;
    setSelectedFilter(value);
    if (value) {
      const filterOb = getTypeFromFilter(value, filterList);
      setCurrentFilter(filterOb);
    }
    else{
      setCurrentFilter({});
    }
   
    setFromDate(null);
    setToDate(null);
    setFilterdValue("");
  };

  const handleFilterValueChange = (e: any) => {
    setFilterdValue(e.target.value);
  };

  const checkDisable = () => {
    let value = true;
    if (currentFilter?.type === "calender" && fromDate && toDate) {
      value = false;
    } else if (currentFilter?.key && filterdValue && filterdValue !== "") {
      value = false;
    }
    setDisableApply(value);
  };

  useEffect(() => {
    checkDisable();
  }, [fromDate, toDate, filterdValue , currentFilter]);

  const applyFilter = () => {
    let value = "";
    if (currentFilter?.type === "calender" && fromDate && toDate) {
      value = fromDate + "_" + toDate;
    } else {
      value = filterdValue;
    }
    setCurrentPage(1);
    setFilteredValue({
      column: currentFilter?.key,
      value: value,
    });
    setIsFiltered(true);
    setShowBody(false);
  };

  const resetFilter = () => {
    setSelectedFilter("Select");
    setFilterdValue("");
    setCurrentFilter({});
    setFromDate(null);
    setToDate(null);
    setFilteredValue({});
    setCurrentPage(1);
    setIsFiltered(false);
    setShowBody(false);
  };

  useEffect(() => {
    if (fromDate && toDate) {
      if (moment(fromDate) > moment(toDate)) {
        setToDate(fromDate);
      }
    }
  }, [fromDate]);

  const [showBody, setShowBody] = useState(false);

  return (
    <>
      <div className="filter-wrapper">
        <CustomeButton
          bg={isFilterd ? "fill" : "outlined"}
          onClick={() => {
            setShowBody(!showBody);
          }}
        >
          <Filtericon />
          {t("Filter")}
        </CustomeButton>
        <div className={`filter-body ${showBody ? "show" : "hide"}`}>
          <SelectGropus
            dropKey="key"
            defaultSelect={selectedFilter}
            dropVal="value"
            value={filterList}
            onChange={handleFilterChange}
          />
          {currentFilter?.type === "dropdown" && (
            <SelectGropus
              dropKey={currentFilter?.drop_key}
              defaultSelect={filterdValue}
              dropVal={currentFilter?.drop_val}
              value={currentFilter?.list}
              onChange={handleFilterValueChange}
            />
          )}
          {currentFilter?.type === "calender" && (
            <>
              <Datepicker
                allowClear={true}
                label={`${t("From")}*`}
                onChange={(date: any) => {
                  let dateIs = date?.format("YYYY-MM-DD");
                  setFromDate(dateIs);
                }}
                value={fromDate ? moment(fromDate) : undefined}
              />
              <Datepicker
                allowClear={true}
                startDate={fromDate ? moment(fromDate) : undefined}
                label={`${t("To")}*`}
                onChange={(date: any) => {
                  let dateIs = date?.format("YYYY-MM-DD");
                  setToDate(dateIs);
                }}
                value={toDate ? moment(toDate) : undefined}
              />
            </>
          )}
          {currentFilter?.type === "text" && (
            <InputGroups
              placeholder={"Search "}
              onChange={handleFilterValueChange}
              value={filterdValue}
            />
          )}

          <div className="filter-body-footer">
            <CustomeButton
              bg="fill"
              disabled={disableApply}
              onClick={applyFilter}
            >
              {t("Apply")}
            </CustomeButton>
          </div>

          <div className="reset-btn-wrapper">
            <CustomeButton onClick={() => resetFilter()}> {t("Reset")}</CustomeButton>
          </div>
        </div>
      </div>
      <div
        className={`filter-backdrop ${showBody ? "show" : "hide"}`}
        onClick={() => {
          setShowBody(!showBody);
        }}
      ></div>
    </>
  );
}

export default Filter;
