import { t } from "i18next";
import React from "react";
import Modal from "react-bootstrap/Modal";

export default function HorseHistoryPopup(props: any) {
  const { data, ...rest } = props;
  return (
    <Modal {...props} centered className="horse-history-popup modal-784">
      <Modal.Header closeButton>
        <p className="tl">{t("Horse History")}</p>
      </Modal.Header>
      <Modal.Body>
        <div className="vertical-flow big">
          {data?.map((items: any, index: number) => (
            <div className="VF-box" key={index + "HNA"}>
              <label className="bl">{items.date}</label>
              <div className="dots">
                <div className="yellow"></div>
              </div>
              <div className="box">
                <p className="bl">{items?.category}</p>
                <p className="bm blur-color">{items?.type}</p>
                {/* <span className="bm blur-color">{items.time}</span> */}
              </div>
            </div>
          ))}

          {!data?.length ? (
            <p className="no-data-wrapper ts blur-color">
              {t("No Data Found")}
            </p>
          ) : null}
        </div>
      </Modal.Body>
    </Modal>
  );
}
