import React from "react";

export default function Trainingicon() {
  return (
    <svg
      width="32"
      height="32"
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M20.2755 14.9C18.7755 19.1 24.1755 19.1 25.0755 21.8C25.3755 21.7 26.6755 21.4 26.9755 20.5C27.0755 20.2 27.0755 19.9 26.9755 19.6C25.5755 17.1 27.0755 15.8 26.1755 13.5C26.0755 13.1 28.5755 12.5 28.5755 12.5L23.8755 10.9C21.2755 10.1 18.9755 10.6 17.1755 12.9C17.0755 13.1 16.5755 13.8 16.3755 14.3C13.7755 22.8 3.47552 20.4 3.47552 20.4"
        stroke="#E8E5E2"
        strokeWidth="1.5"
        strokeMiterlimit="13.3333"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M24.9 15.6C24.8 15.9 24.4 16.1 24.1 16C23.8 15.9 23.6 15.5 23.7 15.2C23.8 14.9 24.2 14.7 24.5 14.8C24.8 14.9 25 15.2 24.9 15.6Z"
        fill="#E8E5E2"
      />
      <path
        d="M11.6989 25C20.0989 27.2 20.4989 18.6 20.5989 18.2"
        stroke="#E8E5E2"
        strokeWidth="1.5"
        strokeMiterlimit="13.3333"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M23 19.7L26.4 18.1"
        stroke="#E8E5E2"
        strokeWidth="1.5"
        strokeMiterlimit="13.3333"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M11.7 11.4C13.0255 11.4 14.1 10.3255 14.1 9.00001C14.1 7.67452 13.0255 6.60001 11.7 6.60001C10.3745 6.60001 9.29999 7.67452 9.29999 9.00001C9.29999 10.3255 10.3745 11.4 11.7 11.4Z"
        stroke="#E8E5E2"
        strokeWidth="1.5"
        strokeMiterlimit="13.3333"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M9.9 10.6C9.9 10.6 5.8 12.5 4.3 16.5C2.8 20.5 10.5 16.5 12 18.7C13.5 20.9 11.7 25 11.7 25"
        stroke="#E8E5E2"
        strokeWidth="1.5"
        strokeMiterlimit="13.3333"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M10.3 11.3L11.3 14.2C11.6 15.3 12.9 15.7 13.8 15.1C13.8 15.1 13.8 15.1 13.9 15.1C16.4 13.4 19.8 13.5 22 15.7L24.9 18.6"
        stroke="#E8E5E2"
        strokeWidth="1.5"
        strokeMiterlimit="13.3333"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}
