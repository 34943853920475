import React from "react";
import Button from "@mui/material/Button";

const SquareButton = (props: any) => {
  return (
    <Button
      data-aos={props.animatioName}
      onClick={props.onClick}
      type={props.type}
      id={props.id}
      value={props.value}
      className={props.bg === "fill" ? "sq-fill-btn" : "sq-border-btn"}
      disabled={props.disabled}
      variant={props.bg === "fill" ? "contained" : "outlined"}
    >
      {props.isLoading ? (
        props.bg === "fill" ? (
          props.bgColor === "white" ? (
            <div className="loader-black-wrapper">
              <span className="loader-black"></span>
            </div>
          ) : (
            <div className="loader-white-wrapper">
              <span className="loader-white"></span>
            </div>
          )
        ) : (
          <div className="loader-black-wrapper">
            <span className="loader-black"></span>
          </div>
        )
      ) : (
        props.children
      )}
    </Button>
  );
};

export default SquareButton;
