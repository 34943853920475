import React from "react";
import Modal from "react-bootstrap/Modal";
import CustomeButton from "../../Common/CustomButton/CustomButton";
import { useNavigate } from "react-router-dom";
import TextButton from "../../Common/TextButton/TextButton";
import { t } from "i18next";

export default function LogoutPopup(props: any) {
  const navigate = useNavigate();
  const logOut = ()=>{
    
    navigate('logout');
  }
  return (
    <Modal {...props} centered className="logout-popup modal-416">
      <Modal.Body>
        <p className="tl">{t("Logout")}</p>
        <span className="bl">{t("Are you sure you want to logout?")}</span>
      </Modal.Body>
      <Modal.Footer>
        <div className="footer-2-btn">
          <TextButton onClick={props.onHide}> {t("Cancel")}</TextButton>
          <CustomeButton onClick={()=>logOut()}  bg="fill">
          {t("Logout")}
          </CustomeButton>
        </div>
      </Modal.Footer>
    </Modal>
  );
}
