import React from "react";

export default function Inventoryicon() {
  return (
    <svg
      width="32"
      height="32"
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clip-path="url(#clip0_1_289)">
        <path
          d="M16.1333 9.06666L14 8.26666L5.20001 12.9333V23.0667L14 27.2L22.8 23.0667V15.2"
          stroke="#E8E5E2"
          strokeWidth="1.5"
          strokeMiterlimit="10"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M5.33334 13.2L14 16.5333L18.4 14.8"
          stroke="#E8E5E2"
          strokeWidth="1.5"
          strokeMiterlimit="10"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M14 16.5333V27.2"
          stroke="#E8E5E2"
          strokeWidth="1.5"
          strokeMiterlimit="10"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M21.6 15.2C24.4719 15.2 26.8 12.8719 26.8 10C26.8 7.12812 24.4719 4.8 21.6 4.8C18.7281 4.8 16.4 7.12812 16.4 10C16.4 12.8719 18.7281 15.2 21.6 15.2Z"
          stroke="#E8E5E2"
          strokeWidth="1.5"
          strokeMiterlimit="10"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M19.6 10.5333L21.2 11.6L23.6 8.8"
          stroke="#E8E5E2"
          strokeWidth="1.5"
          strokeMiterlimit="10"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
      <defs>
        <clipPath id="clip0_1_289">
          <rect width="32" height="32" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
}
