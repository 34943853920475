import React from "react";

export default function Pic() {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M19 3H5C3.9 3 3 3.9 3 5V19C3 20.1 3.9 21 5 21H19C20.1 21 21 20.1 21 19V5C21 3.9 20.1 3 19 3Z"
        stroke="#919EAB"
        strokeWidth="1.5"
      />
      <path
        d="M9 11C10.1 11 11 10.1 11 9C11 7.9 10.1 7 9 7C7.9 7 7 7.9 7 9C7 10.1 7.9 11 9 11Z"
        stroke="#919EAB"
        strokeWidth="1.5"
      />
      <path
        d="M10.5 21C10.5 15.2 15.2 10.5 21 10.5"
        stroke="#919EAB"
        strokeWidth="1.5"
      />
    </svg>
  );
}
