import React from "react";

export default function BanknoteIcon() {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M6.98153 14.311L3.98001 14.9969L5.35192 21L8.35344 20.314L6.98153 14.311Z"
        stroke="#919EAB"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M7.14169 14.8487C7.14169 14.8487 8.16796 14.7554 9.10092 15.1286C9.94059 15.5018 11.9931 14.942 12.5529 14.7554C13.0194 14.6621 14.3255 14.1956 14.5121 16.2481"
        stroke="#919EAB"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M11.8998 17.1811C11.8998 17.1811 14.6054 16.5281 16.2848 15.4085C17.9641 14.1956 18.6172 14.009 18.8971 14.1023C19.0837 14.1956 20.5764 14.8487 17.6842 16.9945C14.792 19.1404 13.5792 19.1404 12.1797 19.4203C10.7803 19.7001 8.72774 20.4465 8.26126 19.6069"
        stroke="#919EAB"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M14.8854 8.22463V12.1431H4.52942V6.54529H13.5792"
        stroke="#919EAB"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M9.75403 6.54536L11.4334 4.39954L16.4714 3.74646H17.8708V7.57163H17.2178L16.3781 9.25097L14.8853 9.90404"
        stroke="#919EAB"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M14.4189 5.79895C14.4189 5.79895 12.5529 7.66489 12.4596 8.03807C12.3663 8.41126 12.7395 8.87774 13.206 8.69115C13.5792 8.50456 14.4189 7.1984 15.3518 7.57159"
        stroke="#919EAB"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M17.8708 3.74637V3H20.0166V8.22462H17.9641L17.8708 7.57154"
        stroke="#919EAB"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M9.75403 10.7437C10.5269 10.7437 11.1535 10.1172 11.1535 9.34428C11.1535 8.57138 10.5269 7.94482 9.75403 7.94482C8.98114 7.94482 8.35458 8.57138 8.35458 9.34428C8.35458 10.1172 8.98114 10.7437 9.75403 10.7437Z"
        fill="#E8E5E2"
      />
      <path
        d="M7.14173 6.54529C7.14173 6.54529 7.70151 9.43749 4.52942 9.34419"
        stroke="#919EAB"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}
