import { createContext, useContext, useState } from "react";

interface LoadingContextType {
  loading:boolean,
  setLoading:any,
} 
const LoadingContext : any = createContext({
    loading:false,
    setLoading:null,
});

export const LoadingProvider = ({children}:any) => {
  const [loading , setLoading] = useState(false);
  const value = {loading , setLoading}
  return (
    <LoadingContext.Provider value={value}>
{children}
    </LoadingContext.Provider>
  )
}

export function useLoading(){
  const context : LoadingContextType = useContext(LoadingContext);
  if (!context) {
    throw new Error("Something went Wrong");
  }
  return context;
}