import { useEffect, useState } from "react";
import backIcon from "../../../Assets/img/back-btn-icon.svg";
import insta from "../../../Assets/img/instagram.svg";
import fb from "../../../Assets/img/facebook.svg";
import global from "../../../Assets/img/globe-2.svg";
import phoneIcon from "../../../Assets/img/fd-phone.svg";
import mailIcon from "../../../Assets/img/fd-mail.svg";
import manIcon from "../../../Assets/img/fd-user.svg";
import horseIcon from "../../../Assets/img/fd-horses.svg";
import mapIcon from "../../../Assets/img/fd-pin.svg";

import { Link, useParams } from "react-router-dom";
import { FARM_DIRECTORY_LISTING } from "../../../Constant/Route";
import FarmDetailPopup from "../../Popups/FarmDetailPopup/FarmDetailPopup";
import { encrDecrStr } from "../../../Utility/General";
import { useLoading } from "../../../context/LoadingContext";
import {
  getFarmDetails,
  getFarmHorseDetails,
} from "../../../Redux/NonRedux/farm";
import { t } from "i18next";
import Loader from "../../Loader/Loader";
import {
  HorseImage,
  StudFarmDetails,
  StudFarmHorseDetails,
} from "../../../interface/FarmDirectory.interface";

export default function FarmDirectoryDetails() {
  const [FarmDetailModalShow, setFarmDetailModalShow] = useState(false);
  const param = useParams();
  const [farmId, setFarmId] = useState<any>();
  const [farmDetails, setFarmDetails] = useState<StudFarmDetails>();
  const { setLoading } = useLoading();
  const [horseDetails, setHorseDetails] = useState<StudFarmHorseDetails>();
  const [isLoading, setIsLoading] = useState(false);

  const getFarmDetailsApi = () => {
    setIsLoading(true);
    getFarmDetails({ id: farmId })
      .then((res: any) => {
        setFarmDetails(res?.component?.listing);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  useEffect(() => {
    if (farmId) {
      getFarmDetailsApi();
    }
  }, [farmId]);

  useEffect(() => {
    let id: any = encrDecrStr(param?.slug, "decr");
    setFarmId(id);
  }, []);

  const handlePreviewHorseDetails = (id: number) => {
    setLoading(true);
    getFarmHorseDetails({ id: id })
      .then((res: any) => {
        setHorseDetails(res?.component);
        setFarmDetailModalShow(true);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  return (
    <>
      {isLoading ? (
        <Loader />
      ) : (
        <>
          <div className="farm-directory-details">
            <div className="page-content">
              <div className="fdd-wrapper">
                <Link to={FARM_DIRECTORY_LISTING} className="back-btn">
                  <img src={backIcon} alt="" />
                  <span className="bm">{t("Farm Directory")}</span>
                </Link>
                <div className="fdd-blog-wrapper">
                  <div className="this-img-wrapper">
                    <div className="this-img">
                      <img src={farmDetails?.profile_image} alt="" />
                    </div>
                  </div>
                  <div className="this-content">
                    <h6>{farmDetails?.farm_name} </h6>
                    {farmDetails?.farm_description && (
                      <p className="bl">{farmDetails?.farm_description}</p>
                    )}
                    <div className="this-social-wrapper">
                      {farmDetails?.instagram && (
                        <a
                          href={farmDetails?.instagram}
                          target="_blank"
                          className="sc-img"
                        >
                          <img src={insta} alt="" />
                        </a>
                      )}
                      {farmDetails?.facebook && (
                        <a
                          href={farmDetails?.facebook}
                          target="_blank"
                          className="sc-img"
                        >
                          <img src={fb} alt="" />
                        </a>
                      )}
                      {farmDetails?.website && (
                        <a
                          href={farmDetails?.website}
                          target="_blank"
                          className="sc-img"
                        >
                          <img src={global} alt="" />
                        </a>
                      )}
                    </div>
                    <div className="contact-address-wrapper">
                      {farmDetails?.farm_manager_phone && (
                        <div className="ca-box">
                          <img src={phoneIcon} alt="" />
                          <a
                            href={`tel:${farmDetails?.farm_manager_phone}`}
                            className="bl"
                          >
                            {farmDetails?.farm_manager_phone}
                          </a>
                        </div>
                      )}
                      {farmDetails?.farm_email && (
                        <div className="ca-box">
                          <img src={mailIcon} alt="" />
                          <a
                            href={`mailto:${farmDetails?.farm_email}`}
                            className="bl"
                          >
                            {farmDetails?.farm_email}
                          </a>
                        </div>
                      )}
                      {farmDetails?.farm_manager_name && (
                        <div className="ca-box">
                          <img src={manIcon} alt="" />
                          <span className="bl">
                            {farmDetails?.farm_manager_name}
                          </span>
                        </div>
                      )}

                      <div className="ca-box">
                        <img src={horseIcon} alt="" />
                        <span className="bl">
                          {farmDetails?.totalHorse} {t("horses")}
                        </span>
                      </div>

                      <div className="ca-box">
                        <img src={mapIcon} alt="" />
                        {farmDetails?.google ? (
                          <a
                            href={farmDetails?.google}
                            target="_blank"
                            className="bl primary"
                          >
                            {`${
                              farmDetails?.farm_address
                                ? `${farmDetails?.farm_address}, `
                                : ""
                            }`}
                            {`${
                              farmDetails?.farm_area
                                ? `${farmDetails?.farm_area}, `
                                : ""
                            }`}
                            {`${
                              farmDetails?.farm_city
                                ? `${farmDetails?.farm_city}, `
                                : ""
                            }`}
                            {`${
                              farmDetails?.farm_country
                                ? `${farmDetails?.farm_country} `
                                : ""
                            }`}
                          </a>
                        ) : (
                          <p className="bl primary">
                            {`${
                              farmDetails?.farm_address
                                ? `${farmDetails?.farm_address}, `
                                : ""
                            }`}
                            {`${
                              farmDetails?.farm_area
                                ? `${farmDetails?.farm_area}, `
                                : ""
                            }`}
                            {`${
                              farmDetails?.farm_city
                                ? `${farmDetails?.farm_city}, `
                                : ""
                            }`}
                            {`${
                              farmDetails?.farm_country
                                ? `${farmDetails?.farm_country} `
                                : ""
                            }`}
                          </p>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
                <div className="horse-links-wrapper">
                  {farmDetails?.images?.length ? <h5>{t("Horses")}</h5> : ""}
                  <div className="h-row">
                    {farmDetails?.images?.map((image: HorseImage) => {
                      return (
                        <div key={image?.id} className="h-column">
                          <a
                            className="img-wrapper"
                            onClick={() => handlePreviewHorseDetails(image?.id)}
                          >
                            <img src={image?.image} alt="" />
                            <p className="tm">{image?.name}</p>
                          </a>
                        </div>
                      );
                    })}
                  </div>
                </div>
              </div>
            </div>
          </div>
          <FarmDetailPopup
            show={FarmDetailModalShow}
            onHide={() => setFarmDetailModalShow(false)}
            horseDetails={horseDetails}
          />
        </>
      )}
    </>
  );
}
