import React from "react";
import { Routes, Route, Outlet } from "react-router-dom";


export default function RoleRoutes() {
  return (
    <>
      <Outlet />
    </>
  );
}
