import type { DatePickerProps } from "antd";
import moment from "moment";
import React, { useEffect, useState } from "react";
import Modal from "react-bootstrap/Modal";
import { addEditNote } from "../../../actions/Horse/MyHorseActions";
import { API_STATUS } from "../../../Constant/Api";
import { SUCCESS_RESPONSE_CODE } from "../../../Constant/Status";
import validate from "../../../Utility/Validation";
import CustomeButton from "../../Common/CustomButton/CustomButton";
import Datepicker from "../../Common/Datepicker/Datepicker";
import FileUpload from "../../Common/FileUpload/FileUpload";
import InputGroups from "../../Common/InputGroups/InputGroups";
import TextareaGroups from "../../Common/TextareaGroups/TextareaGroups";
import TextButton from "../../Common/TextButton/TextButton";
import { useTranslation } from "react-i18next";
import {
  getFileNamefromLink,
  getFileNamefromPath,
  isFileValidated,
} from "../../../Utility/General";
import { useLoading } from "../../../context/LoadingContext";
import {  FORMLABEL } from "../../../Constant/General";

export default function AddNotesPopup(props: any) {
  const { t } = useTranslation();
  const [fileData, setFileData] = useState("");
  const [fileDataName, setFileDataName] = useState<any>(t("Upload Documents"));
  const [isFormValidated, setIsFormValidated] = useState(false);
  const [noteDataError, setNoteDataError] = useState<any>({});
  const [apiStatus, setApiStatus] = useState(API_STATUS.SUCCESS);
 

  const INTIAL_NOTE_DATA = {
    form_type: {
      value: "notes",
      validation: ["required"],
      errors: [t("Form Type is required.")],
    },
    id: {
      value: "",
    },
    date: {
      value: moment().format("YYYY-MM-DD"),
      validation: ["required"],
      errors: [t("Date is required.")],
    },
    title: {
      value: "",
      validation: ["required"],
      errors: [t("Title is required.")],
    },
    details: {
      value: "",
      validation: [""],
      errors: [t("Details is required.")],
    },
  };
  const [noteData, setNoteData] = useState<any>(INTIAL_NOTE_DATA);
  const [removeDoc, setRemoveDoc] = useState<any>(0);
  const [fileDataSelected, setFileDataSelected] = useState("");
  const { setLoading } = useLoading();
  const allowedFormate = ["jpg", "png", "pdf", "docx" , "heic" , "heif", "jpeg"];
  const allowedFileSizeInMb = 4;


  const checkExistFile = () => {
    if (props.isedit) {
      setRemoveDoc(1);
    }
    setFileData("");
    setFileDataSelected("");
  };

  const setData = (field: string, value: any) => {
    const notesObject = Object.assign({}, noteData);
    notesObject[field].value = value;
    setNoteData({ ...notesObject });
  };

  const fileChange = (e: any) => {
    let file = e.target.files[0];
    if(isFileValidated(file , allowedFormate , allowedFileSizeInMb)){
     let fileName = getFileNamefromPath(e.target.value)
      setFileDataSelected(fileName);
      setFileData(e.target.files[0]);
      if (props.isedit) {
        setRemoveDoc(1);
      }
    }
    else{
      e.target.value = "";
    }
  };

  useEffect(() => {
      setNoteDataError({ ...validate(noteData).errors });
  }, [noteData]);

  useEffect(() => {
    if (props.isedit) {
      if (props?.notedata) {
        setData("date", props?.notedata?.dates);
        setData("id", props?.notedata?.id);
        setData("title", props?.notedata?.title);
        setData("details", props?.notedata?.details);
        if (props?.notedata?.document) {
          let document = props?.notedata?.document;
          document = getFileNamefromLink(document);
          setFileDataSelected(document);
        }
      }
    } else {
      setNoteData(INTIAL_NOTE_DATA);
      setRemoveDoc(0);
      setFileDataSelected("");
      setFileData("");
    }
  }, [props?.notedata]);

  useEffect(() => {
    if (props?.show === false) {
      setNoteDataError({});
      setNoteData(INTIAL_NOTE_DATA);
      setIsFormValidated(false);
      setRemoveDoc(0);
      setFileDataSelected("");
      setFileData("");
    }
  }, [props?.show]);


  const dateChange: DatePickerProps["onChange"] = (date, dateString) => {
    let dateIs = date?.format("YYYY-MM-DD");
    setData("date", dateIs);
  };
  
  const submitHandler = () => {
    if (validate(noteData).isValidated === true) {
      let horseId = props.horseid;
      const formData: any = new FormData();
      Object.keys(noteData).forEach((element) => {
        if (noteData[element].value) {
          formData.append(element, noteData[element].value);
        }
      });

      if (fileData && fileData != "") {
        formData.append("document", fileData);
      }
      formData.append("horse_id", horseId);
      formData.append("remove_doc", removeDoc);
      setLoading(true);
      addEditNote(formData)
        .then((response: any) => {
          if (response.statusCode === SUCCESS_RESPONSE_CODE) {
            setApiStatus(API_STATUS.SUCCESS);
            props.onHide();
            props.setrefreshlist();
          } else {
            setApiStatus(API_STATUS.FAILED);
          }
        })
        .catch((error) => {
          setApiStatus(API_STATUS.FAILED);
        })
        .finally(() => {
          setLoading(false);
        });
    } else {
      setNoteDataError({ ...validate(noteData)?.errors });
      setIsFormValidated(true);
    }
  };

  const { setrefreshlist , ...rest } = props;
  return (
    <Modal {...rest} centered className="add-notes-popup modal-416">
      <Modal.Header closeButton>
        <p className="tl">
          {props.isedit ? t("Update") : t("Add")} {t("Note")}
        </p>
      </Modal.Header>
      <Modal.Body>
        <div>
          <div>
            <Datepicker
              endDate={moment().format("YYYY-MM-DD")}
              label={`${t("Date")}*`}
              onChange={dateChange}
              value={moment(noteData?.date?.value)}
              error={
                isFormValidated && noteDataError["date"]
                  ? noteDataError["date"]
                  : null
              }
            />
            <InputGroups
              label={`${t("Title")}*`}
              type="text"
              autoComplete="off"
              value={noteData?.title?.value}
              onChange={(e: any) => setData("title", e.target.value)}
              error={
                isFormValidated && noteDataError["title"]
                  ? noteDataError["title"]
                  : null
              }
            />
            <TextareaGroups
              label={t("Details")}
              type="text"
              autoComplete="off"
              value={noteData?.details?.value}
              onChange={(e: any) => setData("details", e.target.value)}
              error={
                isFormValidated && noteDataError["details"]
                  ? noteDataError["details"]
                  : null
              }
            />
          </div>
          <FileUpload
            onChange={(e: any) => {
              fileChange(e);
            }}
            value={fileDataName}
            removeFile={() => checkExistFile()}
            selectedValue={fileDataSelected}
            fileType={t(FORMLABEL.USE_FILE_TYPE)}
          />
        </div>
      </Modal.Body>
      <Modal.Footer>
        <div className="footer-2-btn">
          <TextButton onClick={props.onHide}>{t("Cancel")}</TextButton>
          <CustomeButton bg="fill" onClick={submitHandler}>
            {" "}
            {props.isedit ? t("Update") : t("Add")}{" "}
          </CustomeButton>
        </div>
      </Modal.Footer>
    </Modal>
  );
}
