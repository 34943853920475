import React from "react";

export default function Diet() {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M4.86171 21C4.86171 21 5.54096 18.6226 3.39001 15.2264C3.39001 15.2264 6.78624 14.7736 7.57869 21C7.57869 21 8.37115 14.7736 5.20134 9.67925C5.20134 9.67925 9.72964 8.77358 10.7485 21C10.7485 21 8.82398 6.62264 15.7296 3C15.7296 3 13.0127 7.30189 13.5787 21C13.5787 21 11.7674 13.4151 18.4466 9.33962C18.4466 9.33962 15.6164 14.5472 15.8428 21C15.8428 21 16.8617 15.566 20.5976 14.3208C20.5976 14.3208 17.8806 17.8302 18.673 21"
        stroke="#919EAB"
        strokeWidth="1.5"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}
