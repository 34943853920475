import { Switch } from "antd";
import React, { useEffect, useState } from "react";
import Modal from "react-bootstrap/Modal";
import { useTranslation } from "react-i18next";
import { ERROR } from "../../../Constant/General";
import { SUCCESS_RESPONSE_CODE } from "../../../Constant/Status";
import { useLoading } from "../../../context/LoadingContext";
import {
  getReminderPreferenceList,
  savePreference,
} from "../../../Redux/NonRedux/reminderApi";
import { showToast } from "../../../Utility/General";
import CheckboxLabel from "../../Common/CheckboxLabel/CheckboxLabel";
import CustomeButton from "../../Common/CustomButton/CustomButton";
import TextButton from "../../Common/TextButton/TextButton";
import useDidMountEffect from "../../../hooks/useDidMountEffect";

export default function EditPreferencePopup(props: any) {
  const { t } = useTranslation();

  const [confirmPopupShow, setConfirmPopupShow] = useState(false);
  const [deletableRecords, setDeletableRecords] = useState<any>([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [horseId, setHorseId] = useState<any>();
  const [currentPageSize, setCurrentPageSize] = useState(10);
  const [recordsTotal, setRecordsTotal] = useState(1);
  const [searchText, setSearchText] = useState("");
  const [listData, setListData] = useState<any>([]);
  const [checkedRec, setCheckedRec] = useState<number[]>([]);
  const [refreshList, setRefreshList] = useState(false);
  const [isEdit, setIsEdit] = useState<boolean>(false);
  const [editData, setEditData] = useState<any>({});
  const { setLoading } = useLoading();
  const [isFormValidated, setIsFormValidated] = useState(false);
  const [preferenceData, setPrefrenceData] = useState<any>([]);
  const [isError, setIsError] = useState<boolean>(false);
  const [error, setError] = useState<any>({});
  const [inputVal, setInputVal] = useState("");

  useEffect(() => {
    listAPICall();
  }, []);

  useDidMountEffect(() => {
    listAPICall();
  }, [currentPage, searchText]);

  useEffect(() => {
    if (refreshList) {
      listAPICall();
      setRefreshList(false);
    }
  }, [refreshList]);

  const listAPICall = () => {
    setLoading(true);
    getReminderPreferenceList({ type: props.reminderType, single: 1 })
      .then((res: any) => {
        setListData(res?.component);
        setPrefrenceData(JSON.parse(JSON.stringify(res?.component)));
      })
      .catch((err: any) => {})
      .finally(() => {
        setLoading(false);
      });
  };

  const handleSubmit = () => {
    setIsFormValidated(true);
    if (isError) {
      showToast(ERROR, "Please enter Valid values");
      return;
    }

    setLoading(true);
    const items: any = [];

    listData.map((item2: any) => {
      items.push(item2);
    });

    let params: any = {
      type: props?.reminderType,
      preferance: items,
    };

    savePreference(params)
      .then((response: any) => {
        if (response.statusCode === SUCCESS_RESPONSE_CODE) {
          let data = JSON.parse(JSON.stringify(listData));
          setPrefrenceData(data);
          props.onHide();
        }
      })
      .catch((error) => {})
      .finally(() => {
        setLoading(false);
      });
  };

  useEffect(() => {
    let data: any = Object.assign([], listData);
    let errorOb: any = {};
    let isError = false;
    listData.map((item2: any, index1: number) => {
      if (item2?.status === 1) {
        item2?.["reminder"].map((reminderVal: any, index2: number) => {
          let preVal = item2?.["reminder"][index2 - 1];
          let currentVal = item2?.["reminder"][index2];
          preVal = preVal && parseInt(preVal);
          currentVal = currentVal && parseInt(currentVal);
          if (
            !reminderVal ||
            (index2 > 0 && preVal >= currentVal) ||
            reminderVal <= 0
          ) {
            errorOb[index1 + "_" + index2] = "1";
            isError = true;
          }
        });
      }
    });

    setIsError(isError);
    setError(errorOb);
  }, [listData]);

  const handleChange = (
    sectionType: number,
    index1: number,
    index2: number,
    value: any
  ) => {
    let data: any = Object.assign([], listData);
    data[index1]["reminder"][index2] = value;
    setListData(data);
  };

  const changeStatus = (
    sectionType: number,
    index1: number,
    status: boolean
  ) => {
    let data: any = Object.assign([], listData);
    data[index1]["status"] = status ? 1 : 0;
    setListData(data);
  };

  useEffect(() => {
    if (props?.show) {
     
      const data = JSON.parse(JSON.stringify(preferenceData));
      setListData(data);
      setIsFormValidated(false);
    }
  }, [props?.show]);

  const { reminderType, ...rest } = props;

  return (
    <Modal {...rest} centered className="edit-preference-popup modal-784">
      <Modal.Header closeButton>
        <p className="tl">{t("Edit Preferences")}</p>
      </Modal.Header>
      <Modal.Body>
        <div className="input-switch-box mb-in-24">
          {listData?.map((items: any, index: number) => (
            <div
              className="input-switch-wrapper"
              key={index + "input-switch-box"}
            >
              <div className="switch-label">
                <Switch
                  size="small"
                  defaultChecked={items.status}
                  checked={items.status}
                  onChange={(checked: boolean) =>
                    changeStatus(0, index, checked)
                  }
                />
                <p className="bl">{items.value}</p>
              </div>
              <div className="input-last-value">
                {items?.reminder.map((value: any, index2: number) => (
                  <input
                    type="number"
                    className={`${
                      isFormValidated && error?.[index + "_" + index2]
                        ? "error"
                        : ""
                    } input`}
                    value={value}
                    onChange={(e) =>
                      handleChange(0, index, index2, e.target.value)
                    }
                    key={index2 + index + "inputs"}
                  />
                ))}
                <p className="bm">{t("Days")}</p>
              </div>
            </div>
          ))}
        </div>
        {/* {listData[1] && (
          <>
            <hr />
            <p className="tm">{t("Vaccination")}</p>
            <div className="input-switch-box mb-in-16">
              {listData[1]?.map((items: any, index: number) => (
                <div
                  className="input-switch-wrapper"
                  key={index + "input-switch-box-2"}
                >
                  <div className="switch-label">
                    <Switch
                      size="small"
                      defaultChecked={items.status}
                      onChange={(checked: boolean) =>
                        changeStatus(1, index, checked)
                      }
                    />
                    <p className="bl">{items.value}</p>
                  </div>
                  <div className="input-last-value">
                    {items?.reminder.map((value: any, index2: number) => (
                      <input
                        type="number"
                        className={`${
                          isFormValidated &&
                          error?.["1_" + index + "_" + index2]
                            ? "error"
                            : ""
                        } input`}
                        value={value}
                        key={index2 + index + "inputs-2"}
                        onChange={(e) =>
                          handleChange(1, index, index2, e.target.value)
                        }
                      />
                    ))}
                    <p className="bm">{t("Days")}</p>
                  </div>
                </div>
              ))}
            </div>
          </>
        )} */}
      </Modal.Body>
      <Modal.Footer>
        <div className="footer-2-btn">
          <TextButton onClick={props.onHide}> {t("Cancel")}</TextButton>
          <CustomeButton bg="fill" onClick={handleSubmit}>
            {t("Save")}
          </CustomeButton>
        </div>
      </Modal.Footer>
    </Modal>
  );
}
