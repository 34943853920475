import type { DatePickerProps } from "antd";
import moment from "moment";
import React, { useState, useEffect } from "react";
import Modal from "react-bootstrap/Modal";
import CustomeButton from "../../Common/CustomButton/CustomButton";
import Datepicker from "../../Common/Datepicker/Datepicker";
import InputCalcuGroups from "../../Common/InputCalcuGroups/InputCalcuGroups";
import SelectGropus from "../../Common/SelectGroups/SelectGroups";
import TextButton from "../../Common/TextButton/TextButton";
import TooltipContent from "../../Common/TooltipContent/TooltipContent";
import { useDispatch, useSelector } from "react-redux";
import { API_STATUS } from "../../../Constant/Api";
import {
  ADD_MEASUREMENTS_FAILD,
  ADD_MEASUREMENTS_REQUEST,
  ADD_MEASUREMENTS_SUCCESS,
} from "../../../Redux/Horse/Measurements/MeasurementsTypes";
import { SUCCESS_RESPONSE_CODE } from "../../../Constant/Status";
import validate from "../../../Utility/Validation";
import { addEditMeasurementsAction } from "../../../Redux/Horse/Measurements/MeasurementsAction";
import { calculateHandFromHeight } from "../../../Utility/General";
import { t } from "i18next";
import { useLocation, useNavigate } from "react-router-dom";
import { useLoading } from "../../../context/LoadingContext";

export default function AddMeasurementPopup(props: any) {
  const INTIAL_MEASUREMENTS_DATA = {
    form_type: {
      value: "measurements",
      validation: ["required"],
      errors: [t("Form Type is required.")],
    },
    id: {
      value: "",
    },
    dates: {
      value: moment().format("YYYY-MM-DD"),
      validation: ["required"],
      errors: [t("Date is required.")],
    },
    weight: {
      value: "",
      validation: ["required"],
      errors: [t("Weight is required.")],
    },
    height: {
      value: "",
      validation: ["required"],
      errors: [t("Height is required.")],
    },
    height_inhand: {
      value: "",
    },
    body_condition: {
      value: "",
      validation: [""],
      errors: [t("Body Condition Index is required.")],
    },
  };

  const dispatch = useDispatch();
  const [measurementsData, setMeasurementsData] = useState<any>(
    INTIAL_MEASUREMENTS_DATA
  );
  const reminderStatus = useSelector(
    (state: any) => state?.reminderStatusReducer
  );
  const [measurementsError, setMeasurementsError] = useState<any>({});
  const [apiStatus, setApiStatus] = useState(API_STATUS.SUCCESS);
  const [isFormValidated, setIsFormValidated] = useState(false);
  const [bodyIndexList, setBodyIndexList] = useState([]);
  const location:any = useLocation();
  const navigate = useNavigate();
  const { loading, setLoading } = useLoading();
  const addEditMeasurementsResponse = useSelector(
    (state: any) => state?.addEditMeasurementsReducer
  );

  const dateChange: DatePickerProps["onChange"] = (date, dateString) => {
    let dateIs = date?.format("YYYY-MM-DD");
    setData("dates", dateIs);
  };

  const prefrence = useSelector(
    (state: any) => state?.masterReducer?.data?.component?.preferance
  );

  const horseInfo = useSelector(
    (state: any) => state?.horseInfoReducer?.data?.component
  );

  const setData = (field: string, value: any) => {
    let measuremtntsDetails: any = Object.assign({}, measurementsData);
    measuremtntsDetails[field].value = value;
    setMeasurementsData({ ...measuremtntsDetails });
  };

  const submitHandler = () => {
    if (validate(measurementsData).isValidated === true) {
      let params: any = {
        dates: measurementsData?.dates?.value,
        weight: measurementsData?.weight?.value,
        height: measurementsData?.height?.value,
        height_inhand: measurementsData?.height_inhand.value,
        body_condition: measurementsData?.body_condition.value,
        horse_id: props?.horseid,
      };
      if (props?.isedit) {
        params.id = measurementsData?.id?.value;
      }

      addEditMeasurementsAction(params);
    } else {
      setIsFormValidated(true);
      setMeasurementsError({ ...validate(measurementsData).errors });
    }
  };

  useEffect(() => {
    setMeasurementsError({ ...validate(measurementsData).errors });
  }, [measurementsData]);

  useEffect(() => {
    if (measurementsData?.height?.value) {
      setData(
        "height_inhand",
        calculateHandFromHeight(measurementsData?.height?.value, prefrence)
      );
    }
  }, [measurementsData?.height?.value]);

  useEffect(() => {
    if (addEditMeasurementsResponse?.type === ADD_MEASUREMENTS_REQUEST) {
      if (apiStatus !== API_STATUS.LOADING) {
        setLoading(true);
        setApiStatus(API_STATUS.LOADING);
      }
    } else if (addEditMeasurementsResponse?.type === ADD_MEASUREMENTS_FAILD) {
      setLoading(false);
      if (apiStatus !== API_STATUS.FAILED) {
        setApiStatus(API_STATUS.FAILED);
      }
    } else if (
      addEditMeasurementsResponse?.type === ADD_MEASUREMENTS_SUCCESS &&
      addEditMeasurementsResponse?.data.statusCode === SUCCESS_RESPONSE_CODE
    ) {
      setLoading(false);
      if (apiStatus !== API_STATUS.SUCCESS) {
        setApiStatus(API_STATUS.SUCCESS);
        if (location?.state?.reminder_id && location?.state?.path) {
          let reminder_id = location?.state?.reminder_id;
          navigate(location?.state?.path, {
            state: { reminder_id: reminder_id, status: "1" },
          });
        } else {
          props.onHide();
          props.setrefreshlist();
        }
      }
    }
  }, [addEditMeasurementsResponse]);

  useEffect(() => {
    if (props.isedit) {
      if (props?.measurementdata) {
        setData("id", props?.measurementdata?.id);
        setData("dates", props?.measurementdata?.dates);
        setData("height", props?.measurementdata?.height);
        setData("weight", props?.measurementdata?.weight);
        setData("height_inhand", props?.measurementdata?.height_inhand);
        setData("body_condition", props?.measurementdata?.body_condition);
      }
    } else {
      setMeasurementsData(INTIAL_MEASUREMENTS_DATA);
    }
  }, [props?.measurementdata]);

  useEffect(() => {
    if (props?.show === false) {
      setMeasurementsError({});
      setMeasurementsData(INTIAL_MEASUREMENTS_DATA);
      setIsFormValidated(false);
    }
  }, [props?.show]);

  useEffect(() => {
    if (props?.bodyindexlist && props?.bodyindexlist?.length > 0) {
      setBodyIndexList(props?.bodyindexlist);
    }
  }, [props?.bodyindexlist]);

  useEffect(() => {
    if (props?.calculatedWeight) {
      setData("weight", props?.calculatedWeight);
    }
  }, [props?.calculatedWeight]);

  useEffect(() => {
    if (props?.calculatedHeight) {
      setData("height", props?.calculatedHeight);
    }
  }, [props?.calculatedHeight]);

  const WeightFunction = () => {
    props.setBodyWeightCalcuModalShow(true);
  };

  const HeightFunction = () => {
    props.setHeightCalcuModalShow(true);
  };

  const {
    horseid,
    measurementdata,
    isedit,
    setrefreshlist,
    bodyindexlist,
    metaData,
    calculatedWeight,
    calculatedHeight,
    setBodyWeightCalcuModalShow,
    setHeightCalcuModalShow,
    ...rest
  } = props;

  return (
    <Modal {...rest} centered className="add-measurement-popup modal-416">
      <Modal.Header closeButton>
        <p className="tl">
          {props.isedit ? t("Update Measurements") : t("Add Measurements")}{" "}
        </p>
      </Modal.Header>
      <Modal.Body>
        <div>
          <Datepicker
            startDate={moment(horseInfo?.birthdateWithoutFormat).format(
              "YYYY-MM-DD"
            )}
            label={`${t("Date")}*`}
            endDate={moment().format("YYYY-MM-DD")}
            onChange={dateChange}
            value={moment(measurementsData?.dates?.value)}
            error={
              isFormValidated && measurementsError["dates"]
                ? measurementsError["dates"]
                : null
            }
          />
          <InputCalcuGroups
            type="number"
            min="0"
            label={`${t("Weight")} (${prefrence?.weightUnit_value})*`}
            value={measurementsData?.weight?.value}
            onChange={(e: any) => setData("weight", e.target.value)}
            onBtnClick={() => {
              WeightFunction();
            }}
            error={
              isFormValidated && measurementsError["weight"]
                ? measurementsError["weight"]
                : null
            }
          />
          <InputCalcuGroups
            type="number"
            min="0"
            label={`${t("Height")} (${prefrence?.heightUnit_value})*`}
            value={measurementsData?.height?.value}
            onChange={(e: any) => {
              setData("height", e.target.value);
            }}
            onBtnClick={() => {
              HeightFunction();
            }}
            error={
              isFormValidated && measurementsError["height"]
                ? measurementsError["height"]
                : null
            }
          />
          <SelectGropus
            value={metaData?.bodyIndex}
            dropKey="key"
            dropVal="value"
            defaultSelect={measurementsData?.body_condition.value}
            onChange={(e: any) => setData("body_condition", e.target.value)}
            error={
              isFormValidated && measurementsError["body_condition"]
                ? measurementsError["body_condition"]
                : null
            }
          >
            <TooltipContent
              title={`${t("Body Condition Index")}`}
            />
          </SelectGropus>
        </div>
      </Modal.Body>
      <Modal.Footer>
        <div className="footer-2-btn">
          <TextButton onClick={props.onHide}> {t("Cancel")}</TextButton>
          <CustomeButton bg="fill" onClick={submitHandler}>
            {props.isedit ? t("Update") : t("Add")}
          </CustomeButton>
        </div>
      </Modal.Footer>
    </Modal>
  );
}
