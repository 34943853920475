import { globalPostAction } from "../../actions/globalActions";
import { END_POINTS } from "../../Constant/Api";
import { SHOW_MESSAGE } from "../../Constant/General";

export function getTrainingList(params:any){
    let apiUrl = END_POINTS.trainingList;
  return globalPostAction(params, apiUrl);
}

export function addTraining(params:any){
    let apiUrl = END_POINTS.addTraining;
  return globalPostAction(params, apiUrl , SHOW_MESSAGE);
}

export function editTraining(params:any){
  let apiUrl = END_POINTS.editTraining;
return globalPostAction(params, apiUrl , SHOW_MESSAGE);
}

export function destroyTraining(params:any){
    let apiUrl = END_POINTS.destroyTraining;
  return globalPostAction(params, apiUrl , SHOW_MESSAGE);
}

export function getSingleTraining(params:any){
    let apiUrl = END_POINTS.viewTraining;
    return globalPostAction(params, apiUrl);
}

export function getTrainingSchedule(params:any){
  let apiUrl = END_POINTS.trainingSchedule;
  return globalPostAction(params, apiUrl);
}

export function endTraining(params:any){
  let apiUrl = END_POINTS.endTraining;
  return globalPostAction(params, apiUrl , SHOW_MESSAGE);
}